import {
  Card,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../utility/TextField";
import Styles from "./styles/UserAccessTable.module.css";
import {
  GetColor,
  SearchFilter,
} from "../../../utility/constants/functions/functions";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { CurvedRedButton } from "../../../utility/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  GETUPDATESETIONISOPENORNOT,
  GETUSERLISTACCESSMGT,
  GETUSERLISTACCESSMGTUPDATE,
} from "../../../utility/ReduxConstants/UserAcessReducerConstant";
import {
  GetUserAccessList,
  UpdateUserAcccessStatus,
} from "../../../actions/UserAccess/UserAccessZoneAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserAccessTable = ({ Filter, FilterText, Loading }) => {
  const BgColor = GetColor("--color-light-background");
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const GetUserList = async () => {
    let param = {
      zone_id: Filter?.zoneId,
      state_id: Filter?.stateId,
      sc_id: Filter?.scId,
      role_id: 3,
    };
    const UserList = await dispatch(GetUserAccessList(Axios, param));
    if (UserList?.code === 200) {
      dispatch({
        type: GETUSERLISTACCESSMGT,
        payload: UserList?.body,
      });
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    GetUserList();
  }, [Filter?.zoneId, Filter?.stateId, Filter?.scId]);

  const store = useSelector((state) => state.Access);
  const [list, setList] = useState([]);

  console.log("accessStore", store);
  useEffect(() => {
    let List = store?.userList ?? [];
    setList(List);
  }, [store?.userList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const column = [
    "state_name",
    "zone_name",
    "mobile_no",
    "name",
    "super_category_name",
  ];

  return (
    <Stack sx={{ backgroundColor: `${BgColor} !important`, padding: "10px" }}>
      <TableContainer
        sx={{
          minHeight: 496,
          maxHeight: 496,
          minWidth: "100%",
          backgroundColor: BgColor,
          xs: { minWidth: 100 },
          sm: { minWidth: 300 },
          md: { minWidth: 300 },
        }}
        component={Paper}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={Styles.head}
            sx={{ backgroundColor: "#1B1D23" }}
            size="small"
          >
            <TableRow
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23" }}
              size="small"
            >
              <StyledTableCell align="center" size="small">
                State
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Zone
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Super Categpry
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Name
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Mobile No.
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Access
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Loading ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : list?.length !== 0 ? (
              SearchFilter(list, column, FilterText)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => <Row Access={item} />)
            ) : (
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={SearchFilter(list, column, FilterText)?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default UserAccessTable;

const Row = ({ Access }) => {
  const [open, setOpen] = useState(false);
  const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    setAccess(Access);
  }, [Access]);

  const Edit = () => {
    setOpen(true);
    dispatch({
      type: GETUSERLISTACCESSMGTUPDATE,
      payload: Access,
    });

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });

    dispatch({
      type: GETUPDATESETIONISOPENORNOT,
      payload: true,
    });
  };

  const handleChecked = async (e) => {
    console.log(e.target.checked, "aCessssfdlf");
    setAccess({
      ...access,
      status: e.target.checked,
    });
    console.log(access, "aCessssfdlf");

    let param = {
      status: e.target.checked,
      asct_id: access?.asct_id,
      zone_id: access?.zone_id
    };

    const data = await dispatch(UpdateUserAcccessStatus(Axios, param));
    if (data.code === 200) {
      toast.success("Status Changed Successfully");
    } else {
      toast.error(data?.message);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.state_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.super_category_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.mobile_no}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <IOSSwitch
            checked={access?.status ? true : false}
            onChange={handleChecked}
          />
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <CurvedRedButton onClick={Edit}>Details</CurvedRedButton>
        </TableCell>
      </TableRow>
    </>
  );
};
