import React from "react";
import { Marker } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import {
  GETASSIGNDELIVERYBOYALLCHECKDATA,
  GETMAPUSERDATAWITHLATANDLONGLIST,
} from "../../../utility/ReduxConstants/MapReducerConstant";
import {
  MapMarkersList,
  MySelfBlackIcon,
  MySelfBlueIcon,
  MySelfYellowIcon,
  assign_delivery_boy_all_check_URL,
} from "./MapUtilityFile";
import { Get_assign_delivery_boy_all_check_Action } from "../../../actions/Map/MapAction";
import { toast } from "react-toastify";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import { Stack } from "@mui/material";

const MarkerFNC = ({ Position, ORDER, ORDERS, HandleSideDrawerOrder }) => {
  console.log(Position, ORDERS, HandleSideDrawerOrder);
  const dispatch = useDispatch();
  const MapData = useSelector((state) => state.MapReducer);

  const LambdaAxios = useAxiosLambda();

  let coord = {
    lat: parseFloat(Position.lat),
    lng: parseFloat(Position.lng),
  };

  const GetAssignAllDeliveryBoyData = async (orderlist) => {
    let OrderListId = [];
    for (let i = 0; i < orderlist?.length; i++) {
      OrderListId.push(orderlist[i]?.order_id);
    }
    let param = {
      order_id: OrderListId,
    };
    const Data = await dispatch(
      Get_assign_delivery_boy_all_check_Action(
        LambdaAxios,
        assign_delivery_boy_all_check_URL,
        param
      )
    );
    console.log(Data, "ssrjnsjnfsjenfjse");
    if (Data?.code === 200) {
      dispatch({
        type: GETASSIGNDELIVERYBOYALLCHECKDATA,
        payload: Data,
      });
    } else {
      toast.warning(toast.warning("Failed to fetch AssignCheckBox"));
    }
  };

  const HandleOrderDetails = async (orderlist) => {
    console.log(orderlist, "orderlistorderlist");
    GetAssignAllDeliveryBoyData(orderlist);
    HandleSideDrawerOrder();
    dispatch({
      type: GETMAPUSERDATAWITHLATANDLONGLIST,
      payload: ORDERS,
    });
  };

  const GETTING_DB_ICON = (ID, ORDER, ORDERS, INDEX) => {
    let DBLIST = MapData?.db_list;
    for (let i = 0; i < DBLIST?.length; i++) {
      if (parseFloat(ORDER.db_id) === parseFloat(DBLIST[i].db_id)) {
        return MapMarkersList[i]?.icon;
      }
    }
  };

  const IconRetreiver = (ID, ORDER, ORDERS, INDEX) => {
    if (ID === 0) {
      return ORDERS?.length > 1
        ? MySelfBlackIcon
        : ORDER.trade_order_type === "pickup"
        ? MySelfYellowIcon
        : MySelfBlueIcon;
    } else if (ID === -1) {
      return ORDERS?.length > 1
        ? ORDER.db_id === null
          ? MySelfBlackIcon
          : GETTING_DB_ICON(ID, ORDER, ORDERS, INDEX)
        : ORDER.db_id === null
        ? ORDER.trade_order_type === "pickup"
          ? MySelfYellowIcon
          : MySelfBlueIcon
        : GETTING_DB_ICON(ID, ORDER, ORDERS, INDEX);
    } else {
      return MapMarkersList[INDEX - 1]?.icon;
    }
  };

  // IconRetreiver(MapData.id, ORDER, ORDERS, MapData.index)
  // console.log(MapData, MapMarkersList);
  return (
    <div>
      <Marker
        icon={IconRetreiver(MapData.id, ORDER, ORDERS, MapData.index)}
        position={coord}
        onClick={() => HandleOrderDetails(ORDERS)}
        options={{
          label: {
            text: `${ORDER?.sku_name} | ${ORDER?.trade_order_type}  | Orders - ${ORDERS.length}`,
            className: "Marker",
          },
        }}
        title="hi"
        // onClick={() => handleSubmit(location)}
      >
        
        {/* <Stack>Hi Hello</Stack> */}
      </Marker>
    </div>
  );
};

export default MarkerFNC;
