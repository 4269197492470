import { Authaxios } from "../../axios/axios";
import {
  GETCATEGORYDATA,
  GETCATEGORYDETAILS,
  UPDATECATEGORYDATAINLIST,
  REMOVECATEGORYDATAINLIST,
  GETSCDATA,
  SELECTEDSUPERCATEGORY
} from "../../utility/ReduxConstants/CategoryReducerConstant";

// var Obj = JSON.parse(localStorage.getItem('Login'));
// const {id} = Obj

const id = 1;

// export const GetCategoryPageData = (param) => async (dispatch) => {
//   // try {
//   //       param.user_id=parseInt(id);
//   //        const {data} = await Authaxios.post('ItemdataByUser',param);
//   //          console.log("data",data)
//   //        if(data.code===200){
//   //            dispatch({type:GETCATEGORYDATA,payload:data})
//   //        }

//   // } catch (error) {
//   //     console.log(error)
//   // }

//   try {
//     const { data } = await Authaxios.post("skuCategoryList", param); //skuCategoryList
//     if (data.code === 200) {
//       dispatch({ type: GETCATEGORYDATA, payload: data.list });
//     }
//   } catch (error) {
//     console.log(error);
//   }

// };

export const UpdateCategoryData =(Axios,param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("updateItems", param);

      return data
    } catch (error) {
      console.log(error);
      return error?.response?.data
    }
  };

export const GetCategoryDetailsId = (Axios,param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("itemDetails", param);
      
      if (data.code === 200) {
        dispatch({ type: GETCATEGORYDETAILS, payload: data.body?.item_details });
      }
    } catch (error) {
      console.log(error);
    }
};

export const GetCategoryListBySCId = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("skuCategoryList", param);
    if (data.code === 200) {
      
      dispatch({ type: GETCATEGORYDATA, payload: data.list });
    }
  } catch (error) {
    return error?.response?.data
  }
};

export const SelectSuperCategory = (Axios,param) => async (dispatch) => {
  dispatch({ type: SELECTEDSUPERCATEGORY, payload: param });
}

export const AddCategoryData =
  (Axios,param, setAddsuccess, failed, setFailed, scId) => async (dispatch) => {
    try {
      const { data } = await Axios.post("additionItems", param);
      return data
    } catch (error) {
      console.log(error);
      return error?.response?.data
    }
  };

export const RemoveCategoryData =
  (Axios,param, setAddsuccessrm, failedrm, setFailedrm,setRemove) => async (dispatch) => {
    try {
      const { data } = await Axios.post("removeItems", param);


      if (data.code === 200) {
        setRemove(false)
        setAddsuccessrm(true);
        let paramTwo = {
          //   type: "category",
          //   user_id: id,
          sc_id:param.sc_id,
        };
        dispatch(GetCategoryListBySCId(Axios,paramTwo));
        // dispatch(GetCategoryPageData(paramTwo));
      } else {
        setRemove(false)
        setFailedrm({
          ...failedrm,
          addFailed: true,
          addFailedMessage:
            "Removing Category Details Failed!! Please Try Again",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const GetAllSuperCategoryList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("superCategoryList");
    if (data.code === 200) {
      dispatch({ type: GETSCDATA, payload: data });
    }
  } catch (error) {
    console.log(error);
  }
};
