import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DateTextFieldCalender,
  StyledTableCell,
  StyledTableCellWallet,
} from "../../../../utility/TextField";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../../UserAccess/styles/UserAccessTable.module.css";
import { GetColor } from "../../../../utility/functions/functions";
import {
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
  DeleteHolidayCalender,
  UpdateHolidayCalender,
} from "../../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { SearchFilter } from "../../../../utility/constants/functions/functions";
import { IOSSwitch } from "../../../../utility/IosSwitch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GETEDITEDVALUE,
  GETFORMSTATUS,
  GETHOLIDAYID,
} from "../../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PopUpModal from "../../../../utility/Backdrop";
import ConfirmationDialog from "../../HolidayCalender/ConfirmationDialog";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";
import { WalletTransactionDetails } from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";

const PartnerWalletTransactionTable = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const walletTransDetails = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletTransDetails
  );

  console.log(walletTransDetails, "walletTransDetails");
  const holidayListByStateAndYear = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByStateAndYear
  );
  const allValueDetails = useSelector(
    (store) => store.HolidayCalenderReducer.AllValueDetails
  );
  const walletChangedValueDetails = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletChangedValueDetails
  );

  useEffect(() => {
    let param = {
      start_date: walletChangedValueDetails?.start_date,
      end_date: walletChangedValueDetails?.end_date,
    };
    dispatch(WalletTransactionDetails(Axios, param));
  }, [walletChangedValueDetails]);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCellWallet align="center" size="small">
                  S.No
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Transfer From
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Zone
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Transaction type
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Amount
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Date & Time
                </StyledTableCellWallet>
              </TableRow>
            </TableHead>

            <TableBody>
              {walletTransDetails ? (
                walletTransDetails?.map((item, index) => {
                  // Given date string
                  var dateString = item?.created_at;

                  // Create a Date object from the string
                  var dateObject = new Date(dateString);

                  // Format the date
                  var formattedDate = dateObject.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });

                  // Format the time
                  var formattedTime = dateObject.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  });

                  // Combine date and time
                  var formattedDate = formattedDate;
                  var formattedTime = formattedTime;

                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      formattedDate={formattedDate}
                      formattedTime={formattedTime}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Send Coins</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default PartnerWalletTransactionTable;

const Row = ({
  Access,
  index,
  formattedDate,
  formattedTime,
  holidayId,
  stateId,
  handleDelete,
  handleEdit,
  handleConfirmRemove,
  dayName,
  currentDate,
  currentHolidayName,
}) => {
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const [editIconStatus, setEditIconStatus] = useState(true);
  console.log(editIconStatus);
  const [editedEvent, setEditedEvent] = useState(Access.holiday_name);
  console.log(currentHolidayName, "stateList");

  console.log(editedEvent, "editedEvent");

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "editedValue");
  const [changedDate, setChangedDate] = useState(null);

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYID,
      payload: { holiday_id: holidayId, state_id: Access.state_id },
    });
  }, []);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmRemoveClick = () => {
    handleConfirmRemove(holidayId, stateId);
    setIsConfirmDialogOpen(false);
  };
  const handleUpdateClick = () => {
    setEditIconStatus(false);
  };

  const handleSave = () => {
    setEditIconStatus(true);

    handleEdit(
      holidayId,
      stateId,
      currentDate,
      currentHolidayName,
      formattedDate
    );
  };

  const [inputValue, setInputValue] = useState(
    editedEvent || currentHolidayName
  );

  const handleEditEvent = (e, value) => {
    console.log(e.target.value);
    setEditedEvent(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: changedDate, edited_event: editedEvent },
    });
  }, [changedDate, editedEvent]);

  const Edit = () => {
    // setOpen(true);

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleStartDateChange = (newValue) => {
    const originalDate = new Date(newValue);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const date = originalDate.getDate();

    // Create a formatted date string (YYYY-MM-DD)
    const formattedDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;
    setChangedDate(formattedDateString);

    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: formattedDateString, edited_event: editedEvent },
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {index}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          Vitaran Live
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          N/A
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access?.trans_type}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.4rem",
            }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={GoldLogo} />
                </Stack>
                <Typography sx={{ fontSize: "0.9rem", color: "#FFFFFF" }}>
                  {Access?.gold_coin}
                </Typography>
              </Stack>
              {Access.silver_coin_free && (
                <Stack
                  sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
                >
                  {" "}
                  <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                    <img src={SilverLogo} />
                  </Stack>
                  <Typography sx={{ fontSize: "0.9rem", fontWeight: 100 }}>
                    {Access?.silver_coin}(Free)
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={SilverLogo} />
                </Stack>
                {Access?.silver_coin}(GST)
              </Stack>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}
          >
            <Stack>{formattedDate}</Stack>
            <Stack>{formattedTime}</Stack>
          </Stack>
        </TableCell>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmRemoveClick}
          holidayId={holidayId}
          stateId={stateId}
        />
      </TableRow>
    </>
  );
};
