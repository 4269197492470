import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import {
  GetKycCountByStateAndZone,
  GetKycDetailsByStateAndZoneByKycId,
  GetKycListByStateAndZone,
} from "../../../actions/kyc/kycAction";
import { defaultFirmImg } from "../../../utility/BaseUrl";
import Radio from "@mui/material/Radio";
import KycCarousel from "./KycCarousel";
import KycImageViewer from "./KycImageViewer";
import KycUserCard from "./kycUserCard";
import KycVerificationSection from "./KycVerificationSection";
import Styles from "./styles/KycVerification.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MakeKycVerified, RejectKyc } from "../../../actions/kyc/kycAction";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapperSmall } from "../CardWrapper";
import { AutocompleteTextField } from "../../../utility/TextField";
import { GETVIEWDETAILSUSERLIST } from "../../../utility/ReduxConstants/KycReducerConstant";

console.log("KycVerification");

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const DisabledButton = styled(Button)(({ theme }) => ({
  color: "Black",
  backgroundColor: "#F0F0F0",
  border: "2px solid #F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
}));
const RedRadio = styled(Radio)(({ theme }) => ({
  color: "white",
  "&.Mui-checked": {
    color: "#DC1D13",
  },
}));
const KycVerification = ({ show, filter }) => {
  const Axios = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [getDocument, setDocument] = useState(defaultFirmImg);
  const [remarks, setRemarks] = useState("");
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Kyc);

  console.log(store, "storeofkyc");
  const [error, setError] = useState({});
  const [radio, setRadio] = useState("aadhar_front_image");
  const [kycStatus, setKycStatus] = useState("");

  //let OBJ = "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/landscapePanback.jpeg";
  //let OBJ = "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/panback.jpeg";
  //let OBJ = "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/New+Doc+07-21-2022+12.02.pdf";
  let TYPE =
    getDocument !== undefined &&
    getDocument !== null &&
    getDocument.length !== 0
      ? getDocument.match(/[^.]+$/)
      : defaultFirmImg.match(/[^.]+$/);

  const handleClick = () => {
    // console.log("entereddd")
    // navigate('/kyc')
    // // setShow(!show)
    show();
  };

  console.log(store.kycDetails, "kycwauoh");

  const localUserId=localStorage.getItem('local_user_id')

  useEffect(() => {
    const param = {
      // user_id: store?.kycViewDetails?.user_id,
      user_id: localUserId
      // zone_name: store?.kycViewDetails?.zone_name,
      // state_id: store?.kycViewDetails?.state_id,
    };

    console.log(JSON.stringify(param) + "vvvvvvvvv");
    dispatch(GetKycDetailsByStateAndZoneByKycId(Axios, param));
  }, []);


  console.log(store.kycViewDetails,"rujmfioeofjdkds")

  const IsValid = () => {
    let error = {};
    let success = true;

    if (!remarks.trim()) {
      error.remarks = "**Please Add a Remark!!**";
      success = false;
    }

    setError(error);
    return success;
  };

  const handleAccept = async () => {
    if (IsValid()) {
      let param = {
        user_id: store?.kycDetail?.user_id,
        zone_name: store?.kycDetail?.zone_name,
        kyc_remarks: remarks,
        kyc_id: store?.kycDetail?.kyc_id,
      };
      console.log(param, "handleAccept");
      const data = await dispatch(MakeKycVerified(Axios, param));
      if (data?.state) {
        setRemarks("");
        const kycDetails = store.kycViewDetails;
        dispatch({
          type: GETVIEWDETAILSUSERLIST,
          payload: { ...kycDetails, kyc_status: "verified" },
        });
        toast.success(`${data?.message}`);
      } else {
        toast.error(`${data?.message}`);
      }
      // dispatch(MakeKycVerified(param))
    }
  };

  const handleReject = async () => {
    if (IsValid()) {
      let param = {
        user_id: store?.kycDetail?.user_id,
        zone_name: store?.kycDetail?.zone_name,
        kyc_remarks: remarks,
        kyc_id: store?.kycDetail?.kyc_id,
      };

      const data = await dispatch(RejectKyc(Axios, param));

      if (data?.state) {
        setRemarks("");
        const kycDetails = store.kycViewDetails;
        dispatch({
          type: GETVIEWDETAILSUSERLIST,
          payload: { ...kycDetails, kyc_status: "rejected" },
        });
        toast.success(`${data?.message}`);
      } else {
        toast.error(`${data?.message}`);
      }
    }
  };

  let Disable = false;

  if (filter?.filterName === "login_completed") {
    Disable = true;
  } else if (filter?.filterName === "users") {
    Disable = true;
  } else if (filter?.filterName === "signup_completed") {
    Disable = true;
  } else {
    Disable = false;
  }

  console.log(Disable, "pdf");

  const handleRadio = (e) => {
    setRadio(e.target.value);
    if (e.target.value === "aadhar_front_image") {
      setDocument(
        store?.kycDetail?.aadhar_front_image !== undefined &&
          store?.kycDetail?.aadhar_front_image !== null &&
          store?.kycDetail?.aadhar_front_image?.length !== 0
          ? store?.kycDetail?.aadhar_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "aadhar_back_image") {
      setDocument(
        store?.kycDetail?.aadhar_back_image !== undefined &&
          store?.kycDetail?.aadhar_back_image !== null &&
          store?.kycDetail?.aadhar_back_image?.length !== 0
          ? store?.kycDetail?.aadhar_back_image
          : defaultFirmImg
      );
      // setDocument(
      //   "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/New+Doc+07-21-2022+12.02.pdf"
      // );
    } else if (e.target.value === "pan_front_image") {
      setDocument(
        store?.kycDetail?.pan_front_image !== undefined &&
          store?.kycDetail?.pan_front_image !== null &&
          store?.kycDetail?.pan_front_image?.length !== 0
          ? store?.kycDetail?.pan_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "pan_back_image") {
      setDocument(
        store?.kycDetail?.pan_back_image !== undefined &&
          store?.kycDetail?.pan_back_image !== null &&
          store?.kycDetail?.pan_back_image?.length !== 0
          ? store?.kycDetail?.pan_back_image
          : defaultFirmImg
      );
    } else if (e.target.value === "dl_front_image") {
      setDocument(
        store?.kycDetail?.dl_front_image !== undefined &&
          store?.kycDetail?.dl_front_image !== null &&
          store?.kycDetail?.dl_front_image?.length !== 0
          ? store?.kycDetail?.dl_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "dl_back_image") {
      setDocument(
        store?.kycDetail?.dl_back_image !== undefined &&
          store?.kycDetail?.dl_back_image !== null &&
          store?.kycDetail?.dl_back_image?.length !== 0
          ? store?.kycDetail?.dl_back_image
          : defaultFirmImg
      );
    } else if (e.target.value === "user_picture") {
      setDocument(
        store?.kycDetail?.user_picture !== undefined &&
          store?.kycDetail?.user_picture !== null &&
          store?.kycDetail?.user_picture?.length !== 0
          ? store?.kycDetail?.user_picture
          : defaultFirmImg
      );
    }
  };

  useEffect(() => {
    if (store?.kycViewDetails !== undefined && store?.kycViewDetails !== null) {
      let statusKyc = store?.kycViewDetails;

      setKycStatus({
        kyc_status:
          statusKyc?.kyc_status !== undefined &&
          statusKyc?.kyc_status !== null &&
          statusKyc?.kyc_status?.length !== 0
            ? statusKyc?.kyc_status
            : "",
      });
    }
  }, [store.kycViewDetails]);

  const handleDownload = (link, type) => {
    let url = link;
    let a = document.createElement("a");
    a.href = url;
    a.download = `file.${type}`;
    a.target = "_blank";
    a.click();
  };

  const handleNavigate = async () => {
    let param = {
      state_id: 0,
      sc_id: 0,
      zone_name: "All",
    };
    await dispatch(GetKycCountByStateAndZone(Axios, param));

    await navigate("/home/kyc");
  };

  console.log(JSON.stringify(store.not_applied_count) + "kycbackbutton");

  return (
    <>
      <Stack sx={{ color: "white" }}>
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Stack>
            <Button onClick={handleNavigate}>
              <ArrowBackIcon sx={{ color: "white" }} />
            </Button>
          </Stack>
          <Typography>KYC DASHBOARD</Typography>
        </Stack>
        <CardWrapperSmall>
          <Stack direction={{ xs: "column", lg: "row" }} gap={1}>
            <Stack
              flex={1}
              backgroundColor="#1F2232"
              borderRadius="5px"
              padding={1}
            >
              <KycUserCard setDocument={setDocument} filter={filter} />
            </Stack>
            <Stack
              flex={1.5}
              backgroundColor="#1F2232"
              borderRadius="5px"
              padding={2}
              color="white"
              gap={2}
            >
              <Typography>KYC Documents</Typography>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "gray",
                    },
                  }}
                  onClick={() => handleDownload(getDocument, TYPE[0])}
                >
                  <BrowserUpdatedIcon
                    sx={{
                      color: "black",
                    }}
                  />
                </IconButton>
              </Stack>
              <Stack direction={{ xs: "column", lg: "row" }}>
                <Stack flex={1}>
                  <RadioGroup
                    size="small"
                    value={radio}
                    onChange={handleRadio}
                    className={Styles.RadioGroupContainer}
                  >
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          size="small"
                          label="Aadhar Front"
                          value="aadhar_front_image"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          label="Aadhar Back"
                          value="aadhar_back_image"
                          size="small"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          label="Pan Front"
                          size="small"
                          value="pan_front_image"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.CardActive}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          label="Pan Back"
                          size="small"
                          value="pan_back_image"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          value="dl_front_image"
                          label="DL Front"
                          size="small"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          value="dl_back_image"
                          label="DL Back"
                          size="small"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className={Styles.Card}>
                      <div className={Styles.RadioContainer}>
                        <FormControlLabel
                          value="user_picture"
                          label="User Picture"
                          size="small"
                          control={
                            <RedRadio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "white",
                                },
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </Stack>
                <Stack flex={2}>
                  {/* {
                    TYPE[0] === "pdf" ? (<KycVerificationSection OBJ={getDocument} filter={filter} />) : (<KycImageViewer OBJ={getDocument} TYPE={TYPE} filter={filter} />)
                  } */}
                  <KycImageViewer
                    OBJ={getDocument}
                    TYPE={TYPE}
                    filter={filter}
                  />
                </Stack>
              </Stack>
              {Disable ? null : (
                <div>
                  <AutocompleteTextField
                    multiline
                    rows={2}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    label="Remarks"
                    sx={{ width: "100%" }}
                  />
                  {error?.remarks && (
                    <span className={Styles.Error}>{error.remarks}</span>
                  )}
                </div>
              )}

              {kycStatus.kyc_status === "verification_pending" && (
                <Stack direction="row" justifyContent="flex-end" gap={1}>
                  {Disable ? null : (
                    <RedButton
                      variant="contained"
                      size="small"
                      onClick={handleAccept}
                      sx={{ borderRadius: "18px" }}
                    >
                      Accept
                    </RedButton>
                  )}

                  {Disable ? null : (
                    <RedButton
                      variant="contained"
                      size="small"
                      onClick={handleReject}
                      sx={{ borderRadius: "18px" }}
                    >
                      Reject
                    </RedButton>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardWrapperSmall>
      </Stack>
    </>
  );
};

export default KycVerification;
