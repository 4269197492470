import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { baseUrl } from "../utility/BaseUrl";
import { NEWACCESSTOKENLAMDA } from "../utility/ReduxConstants/LoginLamdaReducerConstant";
import { AxiosPrivate } from "../axios/axios";
import { toast } from "react-toastify";

const useRefreshLamda = () => {
  const store = useSelector((state) => state.Login);
  let refreshTOKen = localStorage.getItem("LambdaRefreshToken");
  console.log(refreshTOKen, "refreshTOOKen");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${refreshTOKen}`,
    },
  });

  const refresh = async () => {
    try {
      const { data } = await Axios.post("lambdaNewToken");
      console.log(data,"dfjdslfjlk")
      if (data) {
        console.log(JSON.stringify(data) + "kfsjdkjfkdsjf");
        dispatch({ type: NEWACCESSTOKENLAMDA, payload: data });
        console.log(data.access_token + "data jsoon");
        return data?.access_token;
      } else {
        navigate("/Login");
      }
    } catch (error) {
      console.log("error response", error);
      // navigate("/Login");
      // toast.error("Something Went Wrong!!");
    }
  };

  // const Axios = axios.create({
  //   baseURL: baseUrl,
  //   headers: {
  //     Authorization: `Bearer ${refreshTOKen}`,
  //   },
  // });

  // const refresh = async () => {
  //   try {
  //     // Check if a token exists in local storage
  //     const existingToken = localStorage.getItem("LambdaRefreshToken");

  //     if (!existingToken) {
  //       console.log("No existing token found. Navigating to login.");
  //       navigate("/Login");
  //       return null;
  //     }

  //     const { data } = await Axios.post("lambdaNewToken");
  //     console.log(JSON.stringify(data) + "kfsjdkjfkdsjf");
  //     dispatch({ type: NEWACCESSTOKENLAMDA, payload: data });
  //     console.log(data.access_token + "data jsoon");
  //     return data?.access_token;
  //   } catch (error) {
  //     console.error("Error refreshing token:", error);

  //     if (error.response && error.response.status === 401) {
  //       console.log("Navigating to login due to unauthorized access");
  //       navigate("/Login");
  //     } else {
  //       console.error("Unexpected error:", error);
  //       console.error("Unexpected error. Navigating to login.");
  //       navigate("/Login");
  //     }

  //     // Optionally, you can uncomment the following line to display an error toast
  //     // toast.error("Something Went Wrong!!");
  //   }
  // };

  return refresh;
};

export default useRefreshLamda;
