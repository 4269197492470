import {
  ADDGETCATEGORYLIST,
  ADDGETSKUNAMELIST,
  ADDGETSUPERCATEGORYLIST,
} from "../../utility/ReduxConstants/RunTheDealsConstant";

import {
  RUNTHEDEALSTATEAUTOCOMPLETE,
  RUNTHEDEALZONEAUTOCOMPLETE,
  RUNTHEDEALSCAUTOCOMPLETE,
  RUNTHEDEALCATEGORYAUTOCOMPLETE,
} from "../../utility/ReduxConstants/RunTheDealsAutoComplete";

const initialState = {
  addsupercategorylist: [],
  addcategorylist: [],
  addskunamelist: [],
  statelist: [],
  zonelist: [],
  sclist: [],
  categorylist: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADDGETSUPERCATEGORYLIST: {
      return {
        ...state,
        addsupercategorylist: action.payload ?? [],
      };
    }
    case ADDGETCATEGORYLIST: {
      return {
        ...state,
        addcategorylist: action.payload ?? [],
      };
    }
    case ADDGETSKUNAMELIST: {
      return {
        ...state,
        addskunamelist: action.payload ?? [],
      };
    }
    case RUNTHEDEALSTATEAUTOCOMPLETE: {
      return {
        ...state,
        statelist: action.payload ?? [],
      };
    }
    case RUNTHEDEALZONEAUTOCOMPLETE: {
      return {
        ...state,
        zonelist: action.payload ?? [],
      };
    }
    case RUNTHEDEALSCAUTOCOMPLETE: {
      return {
        ...state,
        sclist: action.payload ?? [],
      };
    }
    case RUNTHEDEALCATEGORYAUTOCOMPLETE: {
      return {
        ...state,
        categorylist: action.payload ?? [],
      };
    }
    default:
      return state;
  }
};
