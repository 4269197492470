import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AxiosPrivate } from "../axios/axios";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const store = useSelector((state) => state.Login);
  const refresh = useRefreshToken();
  useEffect(() => {
    const requestInterceptors = AxiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer {store?.token}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseInterceptors = AxiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const preRequest = error?.config;

        if (error?.response?.status === 403 || !preRequest) {
          console.log("entered");
          preRequest.sent = true;
          const newAccessToken = await refresh();
          // console.log("newAccessToken",newAccessToken)
          preRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return AxiosPrivate(preRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      AxiosPrivate.interceptors.request.eject(requestInterceptors);
      AxiosPrivate.interceptors.response.eject(responseInterceptors);
    };
  }, [store, refresh]);

  return AxiosPrivate;
};

export default useAxiosPrivate;
