import { Autocomplete, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import { CurvedRedButton } from "../../../utility/Button";
import {
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
  CalenderStateList,
} from "../../../actions/HolidayCalender/HolidayCalenderAction";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  GETALLVALUEDETAILS,
  GETFORMSTATUS,
  GETHOLIDAYCALENDERDETAILS,
  GETSTATELISTNAME,
} from "../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import { YEARLIST } from "../../../utility/constants/constants";
import AddHoliday from "./AddHoliday";

const CalenderDropDown = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const stateList = useSelector(
    (store) => store.HolidayCalenderReducer.stateList
  );
  const stateListNames = useSelector(
    (store) => store.HolidayCalenderReducer.stateListName
  );

  const holidayListByState = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByState
  );

  const holidayListByStateAndYear = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByStateAndYear
  );
  const holidayStateIndex = useSelector(
    (store) => store.HolidayCalenderReducer.holidayCalenderDetails
  );

  console.log(holidayListByStateAndYear, "stateList");

  const formStatus = useSelector(
    (store) => store.HolidayCalenderReducer.formStatus
  );

  console.log(formStatus, "stateList");
  console.log(
    holidayStateIndex?.state_index,
    holidayStateIndex?.year,
    "stateList"
  );

  const [obj, setObj] = useState({
    stateName: "",
    stateId: 0,
    year: 2023,
    state_index: 0,
  });

  useEffect(() => {
    dispatch({ type: GETALLVALUEDETAILS, payload: obj });
  }, [obj]);

  useEffect(() => {
    dispatch(CalenderStateList(Axios));
  }, []);

  useEffect(() => {
    let Arr = [];
    for (let i = 0; i < stateList.length; i++) {
      Arr.push(stateList[i]?.state_name);
    }

    // setStateAllName(Arr);
    dispatch({ type: GETSTATELISTNAME, payload: Arr });
    setObj({
      ...obj,
      stateName: stateList[0]?.state_name,
      stateId: stateList[0]?.state_id,
      state_index: stateList[0]?.state_id,
    });
    // setObj({
    //   ...obj,
    //   stateName: "",
    //   stateId: 0,
    // });
  }, [stateList]);

  useEffect(() => {
    let param = {
      state_id: holidayStateIndex?.state_current_index,
    };
    dispatch(CalenderHolidayListByState(Axios, param));
  }, [holidayStateIndex?.state_current_index]);

  const handleState = (e, value) => {
    let Arr = [];
    let stateIndex = 0;
    for (let i = 0; i < stateList.length; i++) {
      Arr.push(stateList[i]?.state_name);
      if (value === stateList[i]?.state_name) {
        stateIndex = stateList[i]?.state_id;
      }
    }

    setObj({ ...obj, state_index: stateIndex, stateName: value });

    dispatch({ type: GETSTATELISTNAME, payload: Arr });
    // let param1 = {
    //   state_id: stateIndex,
    // };
    // dispatch(CalenderHolidayListByState(Axios, param1));
  };

  console.log(obj, "stateList");

  const handleYear = (e, value) => {
    console.log(value, "stateList");
    setObj({ ...obj, year: value });
    
  };

  useEffect(()=>{
    let param={
      state_id:obj?.state_index,
      holiday_date:obj?.year
    }
     dispatch(CalenderHolidayListByStateAndYear(Axios,param))
  },[obj?.state_index,obj?.year])

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYCALENDERDETAILS,
      payload: {
        state_current_index: obj.state_index,
        state_default_id: obj.stateId,
        state_name: obj.stateName,
        year: obj.year,
      },
    });
  }, [obj]);

  const handleOpenAddForm = () => {
    dispatch({ type: GETFORMSTATUS, payload: true });
  };
  return (
    <>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Stack
          flex={1}
          direction={{ xs: "column", sm: "row" }}
          spacing={0.5}
          padding={1}
          gap={1}
          sx={{ display: "flex" }}
        >
          <Autocomplete
            sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
            value={obj.stateName}
            options={stateListNames}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleState}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "100%",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="State"
                placeholder="Select State.."
              />
            )}
          />

          <Autocomplete
            sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
            value={obj.year}
            options={YEARLIST}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleYear}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "100%",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="Year"
                placeholder="Select Year.."
              />
            )}
          />
        </Stack>
        <Stack sx={{ width: "20%", justifyContent: "center" }}>
          <CurvedRedButton onClick={handleOpenAddForm}>
            Add Holiday
          </CurvedRedButton>
        </Stack>
      </Stack>
      {formStatus && (
        <Stack
          sx={{ position: "absolute", marginTop: "10rem", marginLeft: "25rem" }}
        >
          <AddHoliday />
        </Stack>
      )}
    </>
  );
};

export default CalenderDropDown;
