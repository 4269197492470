import {
  GETSUBCATCATEGORYLIST,
  GETSUBCATEGORYDETAILS,
  GETSUBCATEGORYLIST,
  GETSUBCATSELECTEDCATEGORY,
  GETSUBCATSELECTEDSC,
  GETSUBCATSUPERCATEGORYLIST,
} from "../../utility/ReduxConstants/SubCategory.Reducer.Constant";

const initialState = {
  sc_list: [],
  sc: {},
  category: {},
  category_list: [],
  category_list_update: [],
  sub_category_list: [],
  sub_category_detail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSUBCATSUPERCATEGORYLIST:
      return {
        ...state,
        sc_list: action.payload,
      };
    case GETSUBCATCATEGORYLIST:
      return {
        ...state,
        category_list: action.payload,
      };
    case GETSUBCATEGORYLIST:
      return {
        ...state,
        sub_category_list: action.payload,
        sub_category_detail:
          action.payload && action?.payload?.length !== 0
            ? action.payload[0]
            : {},
      };
    case GETSUBCATSELECTEDSC:
      return {
        ...state,
        sc: action.payload,
      };
    case GETSUBCATSELECTEDCATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case GETSUBCATEGORYDETAILS:
      return {
        ...state,
        sub_category_detail: action.payload,
      };
    default:
      return state;
  }
};
