import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Styles from "./styles/SkuByZoneItem.module.css";
import {
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { UpdateSkuExceptionBySpId } from "../../../actions/Exception/SkuException";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { defaultImg } from "../../../utility/BaseUrl";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { AutocompleteTextField } from "../../../utility/TextField";
import CardMedia from "@mui/material/CardMedia";
import { Scale } from "@mui/icons-material";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));
const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
    color: "white",
  },
}));

const SkuByZoneItem = () => {
  const Axios = useAxiosPrivate();

  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Exception);
  const [obj, setObj] = useState({
    sp_id: 0,
    sku_name: "",
    category_id: 0,
    sku_id: 0,
    sku_mrp: 0,
    incentive: "",
    state_name: "",
    zone_name: "",
    status: false,
    created_at: "2022-05-22T18:30:00.000Z",
    ptw: 0,
    ptr: 0,
    lot_size: 0,
    segment: "popular",
    company_name: "",
    lot_size_delivery: 0,
    lot_size_pickup: 0,
    premium_delivery_market_price: 0,
    premium_pickup_market_price: 0,
    premium_delivery_lot_size: 0,
    premium_pickup_lot_size: 0,
  });
  const [segment, setSegment] = useState("other");

  console.log(store.exceptionDetail, "store.exceptionDetail");

  React.useEffect(() => {
    if (store.exceptionDetail !== null && store.exceptionDetail !== undefined) {
      setObj({
        ...obj,
        sp_id:
          store.exceptionDetail.sp_id !== undefined &&
          store.exceptionDetail.sp_id !== null &&
          store.exceptionDetail.sp_id !== 0
            ? store.exceptionDetail.sp_id
            : 0,
        sku_name:
          store.exceptionDetail.sku_name !== undefined &&
          store.exceptionDetail.sku_name !== null &&
          store.exceptionDetail.sku_name.length !== 0
            ? store.exceptionDetail.sku_name
            : "",
        sku_image:
          store.exceptionDetail.sku_image !== undefined &&
          store.exceptionDetail.sku_image !== null &&
          store.exceptionDetail.sku_image.length !== 0
            ? store.exceptionDetail.sku_image
            : defaultImg,
        category_id:
          store.exceptionDetail.category_id !== undefined &&
          store.exceptionDetail.category_id !== null &&
          store.exceptionDetail.category_id !== 0
            ? store.exceptionDetail.category_id
            : 0,
        sku_id:
          store.exceptionDetail.sku_id !== undefined &&
          store.exceptionDetail.sku_id !== null &&
          store.exceptionDetail.sku_id !== 0
            ? store.exceptionDetail.sku_id
            : 0,
        sku_mrp:
          store.exceptionDetail.sku_mrp !== undefined &&
          store.exceptionDetail.sku_mrp !== null &&
          store.exceptionDetail.sku_mrp !== 0
            ? store.exceptionDetail.sku_mrp
            : 0,
        incentive:
          store.exceptionDetail.incentive !== undefined &&
          store.exceptionDetail.incentive !== null &&
          store.exceptionDetail.incentive.length !== 0
            ? store.exceptionDetail.incentive
            : "",
        state_name:
          store.exceptionDetail.state_name !== undefined &&
          store.exceptionDetail.state_name !== null &&
          store.exceptionDetail.state_name.length !== 0
            ? store.exceptionDetail.state_name
            : "",
        zone_name:
          store.exceptionDetail.zone_name !== undefined &&
          store.exceptionDetail.zone_name !== null &&
          store.exceptionDetail.zone_name.length !== 0
            ? store.exceptionDetail.zone_name
            : "",
        status:
          store.exceptionDetail.status !== undefined &&
          store.exceptionDetail.status !== null &&
          store.exceptionDetail.status
            ? true
            : false,
        created_at: "2022-05-22T18:30:00.000Z",
        ptw:
          store.exceptionDetail.ptw !== undefined &&
          store.exceptionDetail.ptw !== null &&
          store.exceptionDetail.ptw !== 0
            ? store.exceptionDetail.ptw
            : 0,
        ptr:
          store.exceptionDetail.ptr !== undefined &&
          store.exceptionDetail.ptr !== null &&
          store.exceptionDetail.ptr !== 0
            ? store.exceptionDetail.ptr
            : 0,
        lot_size:
          store.exceptionDetail.lot_size !== undefined &&
          store.exceptionDetail.lot_size !== null &&
          store.exceptionDetail.lot_size !== 0
            ? store.exceptionDetail.lot_size
            : 0,
        lot_size_delivery:
          store.exceptionDetail.lot_size_delivery !== undefined &&
          store.exceptionDetail.lot_size_delivery !== null &&
          store.exceptionDetail.lot_size_delivery !== 0
            ? store.exceptionDetail.lot_size_delivery
            : 0,
        lot_size_pickup:
          store.exceptionDetail.lot_size_pickup !== undefined &&
          store.exceptionDetail.lot_size_pickup !== null &&
          store.exceptionDetail.lot_size_pickup !== 0
            ? store.exceptionDetail.lot_size_pickup
            : 0,
        company_name:
          store.exceptionDetail.company_name !== undefined &&
          store.exceptionDetail.company_name !== null &&
          store.exceptionDetail.company_name.length !== 0
            ? store.exceptionDetail.company_name
            : "",
        premium_delivery_market_price:
          store.exceptionDetail.lock_price_d !== undefined &&
          store.exceptionDetail.lock_price_d !== null &&
          store.exceptionDetail.lock_price_d.length !== 0
            ? store.exceptionDetail.lock_price_d
            : "",
        premium_pickup_market_price:
          store.exceptionDetail.lock_price_p !== undefined &&
          store.exceptionDetail.lock_price_p !== null &&
          store.exceptionDetail.lock_price_p.length !== 0
            ? store.exceptionDetail.lock_price_p
            : "",

        premium_delivery_lot_size:
          store.exceptionDetail.lock_lot_d !== undefined &&
          store.exceptionDetail.lock_lot_d !== null &&
          store.exceptionDetail.lock_lot_d.length !== 0
            ? store.exceptionDetail.lock_lot_d
            : "",

        premium_pickup_lot_size:
          store.exceptionDetail.lock_lot_p !== undefined &&
          store.exceptionDetail.lock_lot_p !== null &&
          store.exceptionDetail.lock_lot_p.length !== 0
            ? store.exceptionDetail.lock_lot_p
            : "",
      });

      setSegment(
        store.exceptionDetail.segment !== undefined &&
          store.exceptionDetail.segment !== null &&
          store.exceptionDetail.segment.length !== 0
          ? store.exceptionDetail.segment
          : ""
      );
    }
  }, [store.exceptionDetail]);

  useEffect(() => {
    setObj({
      ...obj,
      premium_delivery_lot_size:
        Math.floor(obj.premium_delivery_market_price / (obj?.ptw * obj?.lot_size_delivery)),
      premium_pickup_lot_size:
        Math.floor(obj.premium_pickup_market_price / (obj?.ptw * obj?.lot_size_pickup)),
    });
  }, [
    obj.premium_delivery_market_price,
    obj.ptw,
    obj.lot_size_delivery,
    obj?.lot_size_pickup,
    obj?.premium_pickup_market_price,
  ]);

  const handleChange = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };

  const IsValid = () => {
    let success = true;
    let error = {};

    if (!/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g.test(obj.ptr)) {
      error.ptr = "*please enter values as number or decimal*";
      success = false;
    }

    if (!/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g.test(obj.ptw)) {
      error.ptw = "*please enter values as number or decimal*";
      success = false;
    }
    if (!/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g.test(obj.sku_mrp)) {
      error.sku_mrp = "*please enter values as number or decimal*";
      success = false;
    }

    if (!/^(0|[1-9]\d*)$/.test(obj.lot_size)) {
      error.lot_size = "*please enter only numbers*";
      success = false;
    }
    if (!/^(0|[1-9]\d*)$/.test(obj.lot_size_pickup)) {
      error.lot_size_pickup = "*please enter only numbers*";
      success = false;
    }

    if (!/^(0|[1-9]\d*)$/.test(obj.lot_size_delivery)) {
      error.lot_size_delivery = "*please enter only numbers*";
      success = false;
    }

    if (!obj.incentive.trim()) {
      error.incentive = "*Scheme Cannot be Empty*";
      success = false;
    }

    if (!segment.trim()) {
      error.segment = "*Please Choose a Segment*";
      success = false;
    }

    // if (obj.premium_pickup_lot_size < 1) {
    //   const reqPickupPrice = obj.ptw * obj.lot_size_pickup;
    //   error.ppmp =
    //     "*Please enter atleast " + reqPickupPrice + " price to continue*";
    // }

    // if (obj.premium_delivery_lot_size < 1) {
    //   const reqDeliveryPrice = obj.ptw * obj.lot_size_delivery;
    //   error.pdmp =
    //     "*Please enter atleast " + reqDeliveryPrice + " price to continue*";
    // }

    setError(error);
    return success;
  };

  const handleUpdate = async () => {
    if (IsValid()) {
      let param = {
        sp_id: obj.sp_id,
        sku_mrp: parseFloat(obj.sku_mrp),
        incentive: obj.incentive,
        ptw: parseFloat(obj.ptw),
        ptr: parseFloat(obj.ptr),
        lot_size: parseFloat(obj.lot_size),
        lot_size_pickup: parseFloat(obj.lot_size_pickup),
        lot_size_delivery: parseFloat(obj.lot_size_delivery),
        segment: segment,
        lock_price_d: parseFloat(obj.premium_delivery_market_price),
        lock_price_p: parseFloat(obj.premium_pickup_market_price),
        lock_lot_d: parseInt(obj.premium_delivery_lot_size),
        lock_lot_p: parseInt(obj.premium_pickup_lot_size),
      };
      let result = await dispatch(UpdateSkuExceptionBySpId(Axios, param));

      if (result) {
        toast.success("Updated Exception SuccessFully");
      } else {
        toast.error("Failed To Update!! Please Try Again");
      }
    }
  };

  const handleSegment = (e) => {
    setSegment(e.target.value);
  };

  const handleCancel = () => {
    if (store.exceptionDetail !== null && store.exceptionDetail !== undefined) {
      setObj({
        ...obj,
        sp_id:
          store.exceptionDetail.sp_id !== undefined &&
          store.exceptionDetail.sp_id !== null &&
          store.exceptionDetail.sp_id !== 0
            ? store.exceptionDetail.sp_id
            : 0,
        sku_name:
          store.exceptionDetail.sku_name !== undefined &&
          store.exceptionDetail.sku_name !== null &&
          store.exceptionDetail.sku_name.length !== 0
            ? store.exceptionDetail.sku_name
            : "",
        sku_image:
          store.exceptionDetail.sku_image !== undefined &&
          store.exceptionDetail.sku_image !== null &&
          store.exceptionDetail.sku_image.length !== 0
            ? store.exceptionDetail.sku_image
            : defaultImg,
        category_id:
          store.exceptionDetail.category_id !== undefined &&
          store.exceptionDetail.category_id !== null &&
          store.exceptionDetail.category_id !== 0
            ? store.exceptionDetail.category_id
            : 0,
        sku_id:
          store.exceptionDetail.sku_id !== undefined &&
          store.exceptionDetail.sku_id !== null &&
          store.exceptionDetail.sku_id !== 0
            ? store.exceptionDetail.sku_id
            : 0,
        sku_mrp:
          store.exceptionDetail.sku_mrp !== undefined &&
          store.exceptionDetail.sku_mrp !== null &&
          store.exceptionDetail.sku_mrp !== 0
            ? store.exceptionDetail.sku_mrp
            : 0,
        incentive:
          store.exceptionDetail.incentive !== undefined &&
          store.exceptionDetail.incentive !== null &&
          store.exceptionDetail.incentive.length !== 0
            ? store.exceptionDetail.incentive
            : "",
        state_name:
          store.exceptionDetail.state_name !== undefined &&
          store.exceptionDetail.state_name !== null &&
          store.exceptionDetail.state_name.length !== 0
            ? store.exceptionDetail.state_name
            : "",
        zone_name:
          store.exceptionDetail.zone_name !== undefined &&
          store.exceptionDetail.zone_name !== null &&
          store.exceptionDetail.zone_name.length !== 0
            ? store.exceptionDetail.zone_name
            : "",
        status:
          store.exceptionDetail.status !== undefined &&
          store.exceptionDetail.status !== null &&
          store.exceptionDetail.status
            ? true
            : false,
        created_at: "2022-05-22T18:30:00.000Z",
        ptw:
          store.exceptionDetail.ptw !== undefined &&
          store.exceptionDetail.ptw !== null &&
          store.exceptionDetail.ptw !== 0
            ? store.exceptionDetail.ptw
            : 0,
        ptr:
          store.exceptionDetail.ptr !== undefined &&
          store.exceptionDetail.ptr !== null &&
          store.exceptionDetail.ptr !== 0
            ? store.exceptionDetail.ptr
            : 0,
        lot_size:
          store.exceptionDetail.lot_size !== undefined &&
          store.exceptionDetail.lot_size !== null &&
          store.exceptionDetail.lot_size !== 0
            ? store.exceptionDetail.lot_size
            : 0,
        lot_size_delivery:
          store.exceptionDetail.lot_size_delivery !== undefined &&
          store.exceptionDetail.lot_size_delivery !== null &&
          store.exceptionDetail.lot_size_delivery !== 0
            ? store.exceptionDetail.lot_size_delivery
            : 0,
        lot_size_pickup:
          store.exceptionDetail.lot_size_pickup !== undefined &&
          store.exceptionDetail.lot_size_pickup !== null &&
          store.exceptionDetail.lot_size_pickup !== 0
            ? store.exceptionDetail.lot_size_pickup
            : 0,

        company_name:
          store.exceptionDetail.company_name !== undefined &&
          store.exceptionDetail.company_name !== null &&
          store.exceptionDetail.company_name.length !== 0
            ? store.exceptionDetail.company_name
            : "",

        lock_price_d:
          store.exceptionDetail.lock_price_d !== undefined &&
          store.exceptionDetail.lock_price_d !== null &&
          store.exceptionDetail.lock_price_d.length !== 0
            ? store.exceptionDetail.lock_price_d
            : "",
        lock_price_p:
          store.exceptionDetail.lock_price_p !== undefined &&
          store.exceptionDetail.lock_price_p !== null &&
          store.exceptionDetail.lock_price_p.length !== 0
            ? store.exceptionDetail.lock_price_p
            : "",

        lock_lot_d:
          store.exceptionDetail.lock_lot_d !== undefined &&
          store.exceptionDetail.lock_lot_d !== null &&
          store.exceptionDetail.lock_lot_d.length !== 0
            ? store.exceptionDetail.lock_lot_d
            : "",

        lock_lot_p:
          store.exceptionDetail.lock_lot_p !== undefined &&
          store.exceptionDetail.lock_lot_p !== null &&
          store.exceptionDetail.lock_lot_p.length !== 0
            ? store.exceptionDetail.lock_lot_p
            : "",
      });

      setSegment(
        store.exceptionDetail.segment !== undefined &&
          store.exceptionDetail.segment !== null &&
          store.exceptionDetail.segment.length !== 0
          ? store.exceptionDetail.segment
          : ""
      );
    }
  };

  const fontColor = {
    style: {
      color: "rgb(255, 255, 255)",
      border: "2px solid rgb(255, 255, 255)",
    },
  };

  console.log(obj?.lock_lot_p, "exceptionDetails");

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        id="SkuByZoneItemVp"
        flex={1}
        sx={{
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{ backgroundColor: "#DC1D13" }}
          direction="row"
          justifyContent="center"
          padding={0.8}
        >
          <Typography>Detailed View</Typography>
        </Stack>
        <Stack
          padding={2}
          sx={{
            boxShadow: "inset 8px 8px 24px 5px rgba(0, 0, 0, 0.32)",
          }}
          direction={{ xs: "column", sm: "row" }}
        >
          <Stack flex={0.5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <CardMedia
                component="img"
                width="100"
                height="100"
                image={obj?.sku_image}
                style={{
                  objectFit: "contain",
                  transform: "scale(1.2)",
                }}
                alt="Paella dish"
              />
            </div>
          </Stack>
          <Stack>
            <Stack direction={{ xs: "column", md: "row" }}>
              <Stack flex={1} gap={1}>
                <Stack>
                  <Typography variant="caption">SKU Name</Typography>
                  <Typography>{obj?.sku_name ?? ""}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="caption">Company</Typography>
                  <Typography>{obj?.company_name ?? ""}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Stack>
                <Typography variant="caption">Scheme</Typography>
                <AutocompleteTextField
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  value={obj.incentive}
                  name="incentive"
                  onChange={handleChange}
                />
                {error.incentive && (
                  <label className={Styles.error}>{error.incentive}</label>
                )}
              </Stack>
              <Stack direction={{ xs: "column", lg: "row", xl: "row" }} gap={2}>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography variant="caption" sx={{ minWidth: "105px" }}>
                    Lot Size Pickup
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={obj.lot_size_pickup}
                    name="lot_size_pickup"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography variant="caption" sx={{ minWidth: "105px" }}>
                    Lot Size Delivery
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={obj.lot_size_delivery}
                    name="lot_size_delivery"
                    onChange={handleChange}
                  />
                </Stack>
              </Stack>
              <Stack direction={{ xs: "column", lg: "row", xl: "row" }} gap={2}>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography variant="caption" sx={{ minWidth: "105px" }}>
                    PTW
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={obj.ptw}
                    name="ptw"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography variant="caption" sx={{ minWidth: "105px" }}>
                    PTR
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={obj.ptr}
                    name="ptr"
                    onChange={handleChange}
                  />
                </Stack>
              </Stack>

              <Stack direction={{ xs: "column", lg: "row", xl: "row" }} gap={2}>
                <Stack
                  sx={{ display: "flex" }}
                  gap={1}
                  justifyContent="flex-start"
                >
                  <Stack direction="row">
                    <Typography
                      variant="caption"
                      sx={{ minWidth: "100px", maxWidth: "113px" }}
                    >
                      Premium Delivery Market Price{" "}
                    </Typography>
                    <AutocompleteTextField
                      size="small"
                      value={obj.premium_delivery_market_price}
                      name="premium_delivery_market_price"
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack>
                    {error.pdmp && (
                      <label className={Styles.error}>{error.pdmp}</label>
                    )}
                  </Stack>
                </Stack>

                <Stack sx={{ display: "flex" }} gap={1}>
                  <Stack direction="row">
                    <Typography
                      variant="caption"
                      sx={{ minWidth: "100x", maxWidth: "113px" }}
                    >
                      Premium Pickup Market Price
                    </Typography>
                    <AutocompleteTextField
                      size="small"
                      value={obj.premium_pickup_market_price}
                      name="premium_pickup_market_price"
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack>
                    {error.ppmp && (
                      <label className={Styles.error}>{error.ppmp}</label>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={{ xs: "column", lg: "row", xl: "row" }} gap={2}>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography
                    variant="caption"
                    sx={{ minWidth: "100px", maxWidth: "105px" }}
                  >
                    Premium Delivery Lot Size{" "}
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={
                      obj?.premium_delivery_lot_size
                        ? obj?.premium_delivery_lot_size
                        : 0
                    }
                    name="premium_delivery_lot_size"
                    onChange={handleChange}
                  />
                </Stack>
                <Stack direction="row" gap={1} justifyContent="flex-start">
                  <Typography
                    variant="caption"
                    sx={{ minWidth: "100x", maxWidth: "105px" }}
                  >
                    Premium Pickup Lot Size
                  </Typography>
                  <AutocompleteTextField
                    size="small"
                    value={obj?.premium_pickup_lot_size?obj?.premium_pickup_lot_size:0}
                    name="premium_pickup_lot_size"
                    onChange={handleChange}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap={1} justifyContent="flex-start">
                <Typography variant="caption" sx={{ minWidth: "105px" }}>
                  MRP
                </Typography>
                <AutocompleteTextField
                  size="small"
                  value={obj.sku_mrp}
                  name="sku_mrp"
                  onChange={handleChange}
                />
              </Stack>
              <FormControl component="fieldset">
                <RadioGroup row value={segment} onChange={handleSegment}>
                  <FormControlLabel
                    name="popular"
                    value="popular"
                    control={<Radio />}
                    label="Popular"
                  />
                  <FormControlLabel
                    name="new"
                    value="new"
                    control={<Radio />}
                    label="New"
                  />
                  <FormControlLabel
                    name="recommended"
                    value="recommended"
                    control={<Radio />}
                    label="Recommended"
                  />
                </RadioGroup>
              </FormControl>
              {error.segment && (
                <label className={Styles.error}>{error.segment}</label>
              )}

              <div className={Styles.buttonContainer}>
                <RedButton
                  variant="contained"
                  onClick={handleUpdate}
                  sx={{ borderRadius: "18px" }}
                >
                  Update
                </RedButton>
                {/* <RedBorderButton
                  variant="contained"
                  onClick={handleCancel}
                  sx={{ borderRadius: "18px" }}
                >
                  Cancel
                </RedBorderButton> */}
              </div>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SkuByZoneItem;
