import {
    GETLAUNCHDISTRICTLIST,
  GETLAUNCHKYCLIST,
  GETLAUNCHLIST,
  GETLAUNCHSCLIST,
  GETLAUNCHSTATELIST,
  GETLAUNCHUSERCOUNTLIST,
  GETLAUNCHZONECOUNT,
  GETLAUNCHZONELIST,
  UPDATELAUNCHIDSTATUS,
} from "../../utility/ReduxConstants/LaunchReducerConstant";
import { StyledTableCell } from "../../utility/TextField";

const initialState = {
  sc_list: [],
  state_list: [],
  zone_list: [],
  launch_list: [],
  launch_user_list: [],
  launched: 0,
  not_launched: 0,
  kyc_list: [],
  district_list: [],
};
export default (state = initialState, actions) => {
  switch (actions.type) {
    case GETLAUNCHSCLIST:
      return {
        ...state,
        sc_list: actions.payload ?? [],
      };

    case GETLAUNCHDISTRICTLIST:
      return {
        ...state,
        district_list: actions.payload ?? [],
      };
    case GETLAUNCHSTATELIST:
      return {
        ...state,
        state_list: actions.payload ?? [],
      };
    case GETLAUNCHZONELIST:
      return {
        ...state,
        zone_list: actions.payload ?? [],
      };
    case GETLAUNCHLIST:
      return {
        ...state,
        launch_list: actions?.payload ?? [],
      };
    case GETLAUNCHUSERCOUNTLIST:
      return {
        ...state,
        launch_user_list: actions?.payload ?? [],
      };

    case GETLAUNCHZONECOUNT:
      return {
        ...state,
        launched: actions?.payload[0]?.launched ?? 0,
        not_launched: actions?.payload[0]?.not_launched ?? 0,
      };
    case GETLAUNCHKYCLIST:
      return {
        ...state,
        kyc_list: actions?.payload ?? [],
      };
    case UPDATELAUNCHIDSTATUS:
      return {
        ...state,
        launch_list: state.launch_list.map((item) =>
          item?.launch_id === actions.payload?.launch_id
            ? actions.payload
            : item
        ),
      };

    default:
      return state;
  }
};
