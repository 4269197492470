export const SearchFilter =(data,columns,q)=>{
    if(data!==undefined && data!==null && data.length!==0){
        return data?.filter((row)=>
    columns.some(column=>row[column] && row[column]!==null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column]?.toString()?.toLowerCase()?.indexOf(q?.toLowerCase()) > -1) : ('') ))
    }
}



export const RoundOff =(value, decimals)=>{
        let newValue = Number(Math.round(parseFloat(value ?? 0)+'e'+decimals)+'e-'+decimals);

        return newValue.toFixed(2)
}


export const GetColor =(text)=>{
    const Sheet = document.querySelector(':root');
    const ComputedSheet = getComputedStyle(Sheet)
    const Color= ComputedSheet.getPropertyValue(text)
    return Color

}