export const GETSKUPAGEDATACONSTANT = "GETSKUPAGEDATACONSTANT";
export const GETSKUBYIDDETAILS = "GETSKUBYIDDETAILS";
export const GETSKUCATEGORYLIST = "GETSKUCATEGORYLIST";

export const GETSKUCOMPANYLIST = "GETSKUCOMPANYLIST";
export const GETSKUPRODUCTLIST = "GETSKUPRODUCTLIST";

export const GETSKUCOMPANYLISTUPDATE = "GETSKUCOMPANYLISTUPDATE";
export const GETSKUPRODUCTLISTUPDATE = "GETSKUPRODUCTLISTUPDATE";

export const UPDATEPARTICULARSKUBYID = "UPDATEPARTICULARSKUBYID";

export const GETALLSTATELISTSKU = "GETALLSTATELISTSKU";
export const GETALLZONELISTSKU = "GETALLZONELISTSKU";

export const GETALLZONELISTSKUUPDATE = "GETALLZONELISTSKUUPDATE";

export const GETSKUCATEGORYLISTUPDATE = "GETSKUCATEGORYLISTUPDATE";

export const GETSKULISTUPDATEBYPRODUCTID = "GETSKULISTUPDATEBYPRODUCTID";
export const GETSKUCOMPANYLISTUPDATEBYPRODUCTID = "GETSKUCOMPANYLISTUPDATEBYPRODUCTID";

export const UPDATEPRICEEXCEPTIONBYSPID = "UPDATEPRICEEXCEPTIONBYSPID";
export const REMOVEPRICEEXCEPTIONBYSPID = "REMOVEPRICEEXCEPTIONBYSPID";
// export const REMOVEPRICEEXCEPTIONBYSPID = "REMOVEPRICEEXCEPTIONBYSPID";

export const GETPRICELISTBYSKUID = "GETPRICELISTBYSKUID";

export const ADDTOPRICEEXCEPTIONS = "ADDTOPRICEEXCEPTIONS"