import React from "react";
import { Stack, Typography } from "@mui/material";
import GoldLogo from "../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../assets/Wallet/silverCoin.png";

const WalletCoinDetails = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "3rem",
        marginTop: "1rem",
      }}
    >
      <Stack
        sx={{
          border: "0.1rem solid #706D6D",
          padding: "0.9rem",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          gap: "0.6rem",
        }}
      >
        <Typography sx={{ color: "white", marginTop: "-0.5rem" ,fontSize:"0.8rem"}}>
          Total Coins Given
        </Typography>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.1rem",
              
            }}
          >
            <Stack sx={{ width: "1rem",marginTop:"0.2rem"}}>
              <img src={GoldLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              45,000,00
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.2rem"
            }}
          >
            <Stack sx={{ width: "1rem" }}>
              <img src={SilverLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              10,000
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.2rem"
            }}
          >
            <Stack sx={{ width: "1rem", gap: "0.4rem" }}>
              <img src={SilverLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              10,080(Free)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          border: "0.1rem solid #706D6D",
          padding: "1rem",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          gap: "0.6rem",
        }}
      >
        <Typography sx={{ color: "white", marginTop: "-0.5rem",fontSize:"0.8rem" }}>
          Total Coins Consumed / Withdrawn
        </Typography>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.1rem",
              
            }}
          >
            <Stack sx={{ width: "1rem",marginTop:"0.2rem"}}>
              <img src={GoldLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              45,000,00
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.2rem"
            }}
          >
            <Stack sx={{ width: "1rem" }}>
              <img src={SilverLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              10,000
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.2rem"
            }}
          >
            <Stack sx={{ width: "1rem", gap: "0.4rem" }}>
              <img src={SilverLogo} />
            </Stack>
            <Typography sx={{ color: "#FF7070", fontSize: "0.8rem" }}>
              10,080(Free)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WalletCoinDetails;
