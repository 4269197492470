import {  GETSTATEZONELIST, GETZONELIST, LISTSTART, LOGINDATA } from "../../utility/ReduxConstants/LoginReducerConstant"

const initialState={
      List:[],
      ZoneList:[],
      token : "",
      user: {},
      date : new Date()

}

export default (state=initialState,action)=>{
      switch(action.type){
          case LISTSTART:
               return {
                   ...state,
                   List: action.payload!==undefined && action.payload!==null && action.payload.length!==0 ? (action.payload) : ([])
               }
          case GETZONELIST:
              return {
                  ...state,
                  ZoneList: action.payload!==undefined && action.payload!==null && action.payload.length!==0 ? (action.payload) : ([])
              }
        
           case LOGINDATA:
            return {
                ...state,
                token : action.payload!==undefined && action.payload!==null ? (action.payload?.access_token) : (''),
                user : action.payload!==undefined && action.payload!==null ? (action.payload?.user_data) : (''),

            }

          default:
          return state
      }
}