import { Authaxios } from '../../axios/axios';
import { GETCOMPANYDATA, GETCOMPANYDETAILS, UPDATECOMPANYDATAINLIST } from '../../utility/ReduxConstants/CompanyReducerConstant';

// var Obj = JSON.parse(localStorage.getItem('Login'));
// const {id} = Obj

const id=1;


export const GetCompanyPageData=(Axios,param)=>async(dispatch)=>{
    try {
          param.user_id=parseInt(id);
           const {data} = await Axios.post('ItemdataByUser',param);
            // console.log(data)
           if(data.code===200){
               dispatch({type:GETCOMPANYDATA,payload:data})
           }
            
    } catch (error) {
        console.log(error)
    }
}


export const UpdateCompanyData=(Axios,param)=>async(dispatch)=>{
    try {
        const {data} = await Axios.post('updateItems',param);
            return data
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const GetCompanyDetailsId=(Axios,param)=>async(dispatch)=>{
    try {
        const {data} = await Axios.post('itemDetails',param);
            // console.log(data)
            if (data.code ===200) {
                dispatch({type:GETCOMPANYDETAILS,payload:data.body})
            }
          
    } catch (error) {
        console.log(error)
    }
}


export const AddCompanyData=(Axios,param)=>async(dispatch)=>{
    try {
        const {data} = await Axios.post('additionItems',param);
      
            return data
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const RemoveCompanyData=(Axios,param)=>async(dispatch)=>{
    
    try {
        const {data} = await Axios.post('removeItems',param);
            
           return data
    } catch (error) {
        console.log(error);
        return error?.response?.data
    }
}