import React, { useEffect, useState } from "react";
import Styles from "./styles/index.module.css";
import "./styles/index.scss";
import AddUserAccessZone from "./AddUserAccessZone";
// import { AllStateListUserAccess,GetAllSuperCategoryList } from "../../../actions/UserAccess/UserAccessZoneAction";
import {
  AllStateListUserAccess,
  AllZoneListUserAccess,
  AllZoneListSearchUserAccess,
  GetUserAccessList,
  GetUserAccessListBySearch,
  GetAllSuperCategoryList,
  AddUserAcccess,
  GetRoleListAccess,
} from "../../../actions/UserAccess/UserAccessZoneAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  GETALLSTATELISTUSERACCESSCONST,
  GETALLSTATELISTUSERACCESSCONSTADD,
  GETALLSUPERCATEGORYLIST,
  GETALLSUPERCATEGORYLISTADD,
  GETROLEDATAACCESSMGT,
  GETROLEDATAACCESSMGTADD,
} from "../../../utility/ReduxConstants/UserAcessReducerConstant";
import UserSearchAccess from "./UserSearchAccess";
import { Stack } from "@mui/system";
import UserAccessUpdate from "./UserAccessUpdate";
import Wrapper from "../Wrapper";
import { Card } from "@mui/material";
import { CardWrapper } from "../CardWrapper";
import UserSectionWrapper from "./UserSectionWrapper";
import UserSearchAccessBif from "./UserSearchAccessBif";

const UserZoneMgt = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Access);
  const [text, setText] = useState("");

  useEffect(() => {
    (async () => {
      const StateData = await dispatch(AllStateListUserAccess(Axios));
      console.log(StateData, "StateData");
      if (StateData.code === 200) {
        dispatch({
          type: GETALLSTATELISTUSERACCESSCONSTADD,
          payload: StateData?.stateList,
        });
      }

      const ScData = await dispatch(GetAllSuperCategoryList(Axios));
      console.log(ScData, "ScData");
      if (ScData.code === 200) {
        dispatch({
          type: GETALLSUPERCATEGORYLISTADD,
          payload: ScData.body,
        });
      }
      const RoleData = await dispatch(GetRoleListAccess(Axios));
      if (RoleData.code === 200) {
        dispatch({
          type: GETROLEDATAACCESSMGTADD,
          payload: RoleData.role_list,
        });
      }
    })();
  }, []);

  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const handleUpdateOpen = (bol) => {
    setUpdateOpen(bol);
  };
  const handleFormOpen = (bol) => {
    setFormOpen(bol);
  };

  return (
    <>
      <div className="RootUserAccessManagementContainer">
        <CardWrapper
          sx={{
            padding: { xs: "10px", sm: "15px", md: "20px" },
            borderRadius: "8px",
          }}
        >
          <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
            <Stack flex={1}>
              <AddUserAccessZone
                handleUpdateOpen={handleUpdateOpen}
                handleFormOpen={handleFormOpen}
                UpdateOpen={UpdateOpen}
                formOpen={formOpen}
              />
            </Stack>
            <Stack flex={2.3}>
              <UserSectionWrapper
                formOpen={formOpen}
                text={text}
                setText={setText}
              />
            </Stack>
          </Stack>
          {!formOpen && <UserSearchAccessBif text={text} setText={setText} />}
          {!formOpen && <UserAccessUpdate />}
        </CardWrapper>
      </div>
    </>
  );
};

export default UserZoneMgt;
