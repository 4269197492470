import React from "react";
import PartnerWalletCoinDetails from "./PartnerWalletCoinDetails";
import PartnerWalletDropdown from "./PartnerWalletDropdown";
import PartnerWalletTable from "./PartnerWalletTable";

const PartnerWalletManagement = () => {
  return (
    <>
      <PartnerWalletCoinDetails />
      <PartnerWalletDropdown />
      <PartnerWalletTable />
    </>
  );
};

export default PartnerWalletManagement;
