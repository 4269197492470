import {
  GETLOCATIONICONCOLORWITHDBNAME,
  GETMAPALLORDERLIST,
  GETMAPASSIGNPARTNERDATA,
  GETMAPFILTERID,
  GETMAPMYSELFDATALIST,
  GETMAPPARTNERLOCATIONLIST,
  GETMAPUSERDATAWITHLATANDLONGLIST,
  GETMAPFILTERINDEX,
  GETASSIGNDELIVERYBOYALLCHECKDATA,
} from "../../utility/ReduxConstants/MapReducerConstant";

const initState = {
  id: -1,
  index: 0,
  orders: [],
  total_order_count: 0,
  db_list: [],
  db_name_list: [],
  MapUserListViaLatAndLong: [],
  MapAssignPartnerDataList: [],
  GetLocationIconColorWithDbNameList: [],
  AssignCheckBoxData: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case GETMAPALLORDERLIST: {
      const { body, count, db_list, db_name_list } = action.payload;
      return {
        ...state,
        orders: body ?? [],
        total_order_count: count ?? 0,
        db_list: db_list ?? [],
        db_name_list: db_name_list ?? [],
      };
    }
    case GETMAPUSERDATAWITHLATANDLONGLIST: {
      return {
        ...state,
        MapUserListViaLatAndLong: action.payload,
      };
    }
    case GETMAPPARTNERLOCATIONLIST: {
      const { body, count, db_list, db_name_list } = action.payload;
      return {
        ...state,
        orders: body ?? [],
        total_order_count: count ?? 0,
        db_list: db_list ?? [],
        db_name_list: db_name_list ?? [],
      };
    }
    case GETMAPASSIGNPARTNERDATA: {
      const { body, count, db_list, db_name_list } = action.payload;
      return {
        ...state,
        orders: body ?? [],
        total_order_count: count ?? 0,
        db_list: db_list ?? [],
        db_name_list: db_name_list ?? [],
      };
    }
    case GETMAPMYSELFDATALIST: {
      const { body, count, db_list, db_name_list } = action.payload;
      return {
        ...state,
        orders: body ?? [],
        total_order_count: count ?? 0,
        db_list: db_list ?? [],
        db_name_list: db_name_list ?? [],
      };
    }
    case GETLOCATIONICONCOLORWITHDBNAME: {
      return {
        ...state,
        GetLocationIconColorWithDbNameList: action.payload,
      };
    }
    case GETMAPFILTERID: {
      return {
        ...state,
        id: action.payload,
      };
    }
    case GETMAPFILTERINDEX: {
      return {
        ...state,
        index: action.payload,
      };
    }
    case GETASSIGNDELIVERYBOYALLCHECKDATA: {
      return {
        ...state,
        AssignCheckBoxData: action.payload,
      };
    }
    default:
      return state;
  }
};
