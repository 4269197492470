import {
  Autocomplete,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import { RedButton } from "../../../utility/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  RunTheDealAddCutOffPartner,
  RunTheDealsGetCategoryList,
  RunTheDealsGetScCategoryList,
  RunTheDealsGetSkuNameList,
} from "../../../actions/RunTheDeals/RunTheDeals.Action";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  ADDGETCATEGORYLIST,
  ADDGETSKUNAMELIST,
  ADDGETSUPERCATEGORYLIST,
} from "../../../utility/ReduxConstants/RunTheDealsConstant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RunTheDealsAdd = () => {
  const Store = useSelector((state) => state.RunTheDealsReducer);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [sc, setSc] = useState({
    list: [],
    value: "",
    id: 0,
  });
  const [category, setCategory] = useState({
    list: [],
    value: "",
    id: 0,
  });
  const [sku, setSku] = useState({
    list: [],
    value: "",
    id: 0,
  });

  const [cfp, setCfp] = useState("");
  const [cfov, setCfov] = useState("");
  const [err, setErr] = useState({});

  const GetScData = async () => {
    const Data = await dispatch(RunTheDealsGetScCategoryList(Axios));
    console.log(Data);
    if (Data.code === 200) {
      dispatch({
        type: ADDGETSUPERCATEGORYLIST,
        payload: Data?.body,
      });
    } else {
      toast.warning("Failed to fetch Sc List");
    }
  };

  const GetCategoryData = async (param) => {
    const Data = await dispatch(RunTheDealsGetCategoryList(Axios, param));
    if (Data.code === 200) {
      dispatch({
        type: ADDGETCATEGORYLIST,
        payload: Data?.list,
      });
    } else {
      toast.warning("Failed to fetch Sc List");
    }
  };
  const GetSkuData = async (param) => {
    const Data = await dispatch(RunTheDealsGetSkuNameList(Axios, param));
    console.log(Data, "skuData");
    if (Data.code === 200) {
      dispatch({
        type: ADDGETSKUNAMELIST,
        payload: Data?.list,
      });
    } else {
      toast.warning("Failed to fetch Skc List");
    }
  };
  const GetAddData = async (param) => {
    const Data = await dispatch(RunTheDealAddCutOffPartner(Axios, param));
    if (Data.code === 200) {
      setSku({
        ...sku,
        id: 0,
        value: "",
      });
      setCategory({
        ...category,
        id: 0,
        value: "",
      });
      setSc({
        ...sc,
        id: 0,
        value: "",
      });
      setCfp("");
      setCfov("");

      toast.success(Data.message);
    } else {
      toast.warning(Data.message);
    }
  };

  useEffect(() => {
    const NameList = Store.addsupercategorylist?.map((item) => item.name);
    setSc({
      ...sc,
      list: NameList,
    });
  }, [Store.addsupercategorylist]);

  useEffect(() => {
    const NameList = Store.addcategorylist?.map((item) => item.category_name);
    setCategory({
      ...category,
      list: NameList,
    });
  }, [Store.addcategorylist]);

  useEffect(() => {
    const NameList = Store.addskunamelist?.map((item) => item.sku_name);
    setSku({
      ...sku,
      list: NameList,
    });
  }, [Store.addskunamelist]);

  useEffect(() => {
    GetScData();
  }, []);

  const handleSc = async (e, value) => {
    const list = Store.addsupercategorylist?.filter(
      (item) => value === item.name
    );
    const { sc_id, name } =
      list.length !== 0 ? list[0] : { sc_id: 0, name: "" };
    setSc({
      ...sc,
      id: sc_id,
      value: name,
    });
    let param = {
      sc_id: sc_id,
    };
    GetCategoryData(param);
  };

  const handleCategory = async (e, value) => {
    const list = Store.addcategorylist.filter(
      (item) => value === item.category_name
    );

    const { category_id, category_name } =
      list.length !== 0
        ? list[0]
        : {
            category_id: 0,
            category_name: "",
          };

    setCategory({
      ...category,
      id: category_id,
      value: category_name,
    });

    let param = {
      category_id: category_id,
      sc_id: sc.id,
    };

    GetSkuData(param);
  };
  const handleSku = (e, value) => {
    console.log(value);
    const list = Store.addskunamelist.filter((item) => value === item.sku_name);

    const { sku_id, sku_name } =
      list.length !== 0 ? list[0] : { sku_id: 0, sku_name: "" };

    setSku({
      ...sku,
      id: sku_id,
      value: sku_name,
    });
  };

  const handleCfp = (e) => {
    setCfp(e.target.value);
  };
  const handleCfov = (e) => {
    setCfov(e.target.value);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const addDetails = {
      cut_off_price: cfp,
      cut_off_order_value: cfov,
      sku_id: sku.id,
      sc_id: sc.id,
    };
    GetAddData(addDetails);
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleAdd}>
        <Stack
          sx={{
            backgroundColor: "#3B405E",
            padding: "1rem 2rem",
            borderRadius: "5px",
          }}
        >
          <Stack>
            <Typography>Add Cut Off Difference for SKU</Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flex: "1 0 110px",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
            gap={2}
          >
            <Autocomplete
              // className={styles.AutoComplete}
              value={sc?.value ?? ""}
              options={sc?.list ?? [""]}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleSc}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    width: "12rem",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Super Category"
                  placeholder="Select Super Category.."
                />
              )}
            />
            {/* {err?.sc?.value && <Typography>{err.sc.value}</Typography>} */}

            <Autocomplete
              // className={styles.AutoComplete}
              value={category?.value ?? ""}
              options={category?.list ?? [""]}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleCategory}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    backgroundColor: "#25293C",

                    width: "12rem",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Category"
                  placeholder="Select Category.."
                />
              )}
            />
            {/* {err?.category?.value && (
              <Typography>{err.category.value}</Typography>
            )} */}

            <Autocomplete
              // className={styles.AutoComplete}
              value={sku?.value ?? ""}
              options={sku?.list ?? [""]}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleSku}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    width: "12rem",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  variant="outlined"
                  label="SKU Name"
                  placeholder="Select SKU Name.."
                />
              )}
            />
            {/* {err?.sku?.value && <Typography>{err.sku.value}</Typography>} */}

            <Stack
              direction={{ xl: "row", md: "column" }}
              gap={0.5}
              alignItems="center"
            >
              <Typography variant="caption">Cut Off Price</Typography>
              <TextField
                required
                value={cfp}
                onChange={handleCfp}
                size="small"
                type="number"
                sx={{
                  width: { xs: "12rem", lg: "6rem" },
                  borderRadius: "5px",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
              />
            </Stack>
            <Stack
              direction={{ xl: "row", md: "column" }}
              gap={0.5}
              alignItems="center"
            >
              <Typography variant="caption">Cut Off Order Value</Typography>
              <TextField
                type="number"
                required
                size="small"
                value={cfov}
                onClick={handleCfov}
                sx={{
                  width: "12rem",
                  borderRadius: "5px",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
              />
            </Stack>
          </Stack>
          <Stack sx={{ width: "8rem", margin: "auto", marginTop: "1rem" }}>
            <RedButton type="submit">Add</RedButton>
          </Stack>
        </Stack>
      </form>
    </div>
  );
};

export default RunTheDealsAdd;
