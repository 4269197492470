import { Avatar, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { GetMapUserData } from "../../../actions/Map/MapAction";
import {
  GETMAPALLORDERLIST,
  GETMAPFILTERID,
  GETMAPFILTERINDEX,
} from "../../../utility/ReduxConstants/MapReducerConstant";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MAPURL, MapMarkersList } from "./MapUtilityFile";
import { GetLambdaTokenFNC } from "../../../actions/Lambda/Lambda";
import { GETLAMBDATOKEN } from "../../../utility/ReduxConstants/LoginLamdaReducerConstant";
import Styles from "./Map.module.css";

const DeliveryBoyList = () => {
  const MapData = useSelector((state) => state.MapReducer);
  const LambdaAxios = useAxiosLambda();
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const GetUsersData = async (id, index) => {
    let param = {
      db_id: id,
    };
    dispatch({
      type: GETMAPFILTERID,
      payload: id,
    });
    dispatch({
      type: GETMAPFILTERINDEX,
      payload: index,
    });
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param));
    console.log(Data?.data, "GetUsersData");
    if (Data?.data?.code === 200) {
      dispatch({
        type: GETMAPALLORDERLIST,
        payload: Data?.data,
      });
    } else {
      toast.warning("Failed to UsersData List");
    }

    if (
      Data?.body === undefined ||
      Data?.body === null ||
      // Data?.body === [] ||
      Data?.body?.length === 0
    ) {
      setIsMounted(true);
    }
  };

  const GETLAMBDATOKEN_FNC = async () => {
    const Data = await dispatch(GetLambdaTokenFNC(Axios));
    if (Data?.code === 200) {
      dispatch({
        type: GETLAMBDATOKEN,
        payload: Data,
      });
      const refresh_token = Data?.refresh_token;
      localStorage.setItem("LambdaRefreshToken", refresh_token);
      GetUsersData(-1, -1);
    } else {
      toast.warning("Failed to fetch LambdaToken");
    }
  };

  useEffect(() => {
    GETLAMBDATOKEN_FNC();
  }, []);

  // const handleClick = (item, index) => {
  //   setSelectedItem(index);
  //   setSelectedPartner(item === "Assign Partner" || item === "Myself");
  //   for (let i = 0; i < MapData?.db_list?.length; i++) {
  //     if (MapData.db_list[i].name === item) {
  //       GetUsersData(MapData?.db_list[i]?.db_id, index);
  //     }
  //   }
  //   if (MapData?.orders?.length === 0) {
  //     toast.warning(
  //       `No Orders Available for ${MapData?.db_list[index - 1]?.name}`
  //     );
  //   }
  // };

  const handleClick = (item, index) => {
    setSelectedItem(index);
    setSelectedPartner(item === "Assign Partner" || item === "Myself");
    for (let i = 0; i < MapData?.db_list?.length; i++) {
      if (MapData.db_list[i].name === item) {
        GetUsersData(MapData?.db_list[i]?.db_id, index);
      }
    }
    // if (MapData?.orders?.length === 0) {
    //   // Clear any existing toasts before showing a new one
    //   toast.dismiss();
    //   toast.warning(
    //     `No Orders Available for ${MapData?.db_list[index - 1]?.name}`
    //   );
    // }
  };

  useEffect(() => {
    if (
      isMounted && // Check if the component is still mounted
      (MapData?.orders?.length === 0 ||
        MapData?.orders === undefined ||
        MapData?.orders === null)
    ) {
      toast.warning(`No Orders Available`);
    }

    return () => {
      setIsMounted(false); // Set the component as unmounted when it's being cleaned up
    };
  }, [isMounted, MapData?.db_name_list]);

  // const GetMarkerData = (item, index, color, icon) => {
  //   let obj = { item, index, color, icon };
  //   console.log(obj);
  //   dispatch({
  //     type: GETLOCATIONICONCOLORWITHDBNAME,
  //     payload: obj,
  //   });
  // };

  let Markers = MapMarkersList;
  console.log(MapMarkersList);
  console.log(MapData, "MapData");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        direction={"row"}
        sx={{
          width: "90%",
          // justifyContent: "space-between",
          overflow: "auto",
          position: "absolute",
          // maxWidth: "80%",
          bottom: "0px",
          color: "white",
          marginBottom: "1rem",
        }}
        gap={1}
        maxWidth={{ xs: "100%", lg: "80%" }}
      >
        <Stack
          sx={{
            color: "white",
            backgroundColor: "#595C9B",
            border: parseInt(MapData.id) === -1 ? "2px solid black" : "none",
            padding: "5px 2rem",
            borderRadius: "5px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() =>  GetUsersData(-1, -1)}
        >
          Assign Partner
        </Stack>
        {MapData.db_name_list?.map((item, index) =>
          item === "Myself" ? (
            <MySelfContainer
              selectedPartner={selectedPartner}
              GetMapMyselfData={GetUsersData}
            />
          ) : (
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#595C9B",
                padding: "5px 2rem",
                borderRadius: "5px",
                "&:hover": {
                  cursor: "pointer",
                },
                border: [0, -1].includes(parseInt(MapData.id))
                  ? "none"
                  : index === selectedItem
                  ? "2px solid black"
                  : "none",
              }}
              gap={1}
              onClick={() => handleClick(item, index)}
            >
              <Stack textAlign={"center"} alignItems={"center"}>
                <Typography>{item}</Typography>
              </Stack>
              <Stack>
                {/* <Stack
                // className={Styles.Circle}
                // sx={{ backgroundColor: Markers[index - 1].color }}
                > */}
                <Avatar
                  sx={{ width: 24, height: 24, textAlign: "center" }}
                  alt="Icon"
                  src={Markers[index - 1].icon}
                />
                {/* </Stack> */}
              </Stack>
            </Stack>
          )
        )}
      </Stack>
    </>
  );
};

export default DeliveryBoyList;

const MySelfContainer = ({ GetMapMyselfData, selectedPartner }) => {
  const MapData = useSelector((state) => state.MapReducer);
  return (
    <Stack
      direction={"row"}
      sx={{
        color: "white",
        backgroundColor: "#595C9B",
        padding: "5px 15px",
        borderRadius: "5px",
        justifyContent: "space-between",
        border: parseInt(MapData.id) === 0 ? "2px solid black" : "none",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      gap={1}
      onClick={() => GetMapMyselfData(0, -1)}
    >
      MySelf
      <Stack direction={"row"} gap={1}>
        <Stack className={Styles.Circle}></Stack>
        <Stack className={Styles.Circle1}></Stack>
        <Stack className={Styles.Circle2}></Stack>
      </Stack>
    </Stack>
  );
};
