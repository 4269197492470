import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetMapUserData,
  Get_assign_delivery_boy_Action,
  Get_remove_delivery_boy_from_order_Action,
} from "../../../actions/Map/MapAction";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import {
  MAPURL,
  assign_delivery_boy_URL,
  remove_delivery_boy_from_order,
} from "./MapUtilityFile";
import { ToastContainer, toast } from "react-toastify";
import { GETMAPALLORDERLIST } from "../../../utility/ReduxConstants/MapReducerConstant";

const DataDrawerDdetails = ({ NAME, Checked, item, Db_Id_data, setOpen }) => {
  const MapUserList = useSelector((state) => state.MapReducer);
  // const [db_list, setDb_list] = useState([]);
  const [DB_NAME, setDB_NAME] = useState("");
  const dispatch = useDispatch();
  const LambdaAxios = useAxiosLambda();
  console.log(item, "itemInddd");

  const GetRefrshMapdata = async () => {
    const id = MapUserList?.id;
    let param = {
      db_id: id,
    };
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param));
    console.log(Data, "GetRefrshMapdata");
  };

  useEffect(() => {
    GetRefrshMapdata();
  }, []);

  console.log(MapUserList, "MapUserListInitialized");

  const MappedDb_Id = Db_Id_data.map((item) => item);

  console.log(MappedDb_Id[0]?.name, "MappedDb_Id");

  useEffect(() => {
    if (
      item?.delivery_boy_name === undefined ||
      item?.delivery_boy_name === null ||
      item?.db_id === undefined ||
      item?.db_id === null
    ) {
      setDB_NAME(Db_Id_data[0]?.name);
    } else {
      setDB_NAME(item?.delivery_boy_name);
    }
    console.log(item?.delivery_boy_name, "delivery_boy_name");
  }, [item?.delivery_boy_name]);

  useEffect(() => {
    if (Checked) {
      setDB_NAME(NAME);
    }
  }, [Checked, NAME]);

  const handleAssignDeliveryBoy = async (event) => {
    const value = event.target.value;
    setDB_NAME(value);
    console.log(value, "value");
  };

  const handleGetdb_id = async (e, order) => {
    if (e?.name === "Myself") {
      let param = {
        order_id: order,
      };
      const Data = await dispatch(
        Get_remove_delivery_boy_from_order_Action(
          LambdaAxios,
          remove_delivery_boy_from_order,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_remove_delivery_boy_from_order_Action(
            LambdaAxios,
            remove_delivery_boy_from_order,
            param
          )
        );
      } else {
        toast.error(Data?.message);
      }
      console.log(Data, "inhandleGetdb_id");
    } else {
      let param = {
        order_id: item?.order_id,
        db_id: e?.db_id,
      };
      console.log(param, "inpparam");
      const Data = await dispatch(
        Get_assign_delivery_boy_Action(
          LambdaAxios,
          assign_delivery_boy_URL,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_assign_delivery_boy_Action(
            LambdaAxios,
            assign_delivery_boy_URL,
            param
          )
        );
      } else {
        toast.warning(`failed to fetch Assign Delivery Partner`);
      }
      console.log(Data, "FetchingDataInDataDrawrDdetaills Partner");
    }
    const id = MapUserList?.id;
    let param = {
      db_id: id,
    };
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param));
    if (Data?.data?.code === 200) {
      dispatch({
        type: GETMAPALLORDERLIST,
        payload: Data?.data,
      });
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }

    setOpen(false);
  };

  // const formatDate = (dateString) => {
  //   // const options = { hour: "numeric", minute: "numeric" };
  //   // const date = new Date(dateString);
  //   // return date.toLocaleTimeString([], options);
  //   const options = {
  //     day: "numeric",
  //     month: "long",
  //     year: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   };
  //   const date = new Date(dateString);
  //   return date.toLocaleString([], options);
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${day} ${month} ${year}, ${time}`;
  };

  // const Replacer = ({ textData }) => {
  //   const text = textData; // Set default value to an empty string if textData is undefined
  //   return text.replace(/_/g, " ");
  // };
  return (
    <Stack>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1E1F31",
          padding: "5px 10px",
          color: "white",
          marginTop: "1rem",
          borderRadius: "6px",
        }}
      >
        <Stack
          sx={{
            marginTop: "1rem",
          }}
        >
          <Typography>Order ID :- {item?.order_code}</Typography>
        </Stack>
        <Stack
          sx={{
            marginTop: "1rem",
          }}
        >
          <Typography>{item?.sku_name}</Typography>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            backgroundColor:
              item?.trade_order_type === "pickup" ? "#F3D848" : "#70B0FB",
            borderRadius: "16px",
            padding: "5px 20px",
            width: "15rem",
            justifyContent: "space-around",
            alignItems: "center",
            color: "black",
            marginTop: "1rem",
          }}
        >
          <Stack>
            <Typography>{item?.lots} Lots</Typography>
          </Stack>
          <Stack>|</Stack>
          <Stack>
            <Typography>
              {(item?.seller_order_status).replace(/_/g, " ")}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            backgroundColor: "#595C9B",
            color: "white",
            justifyContent: "space-between",
            marginTop: "1rem",
            padding: "5px 10px",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography>
              {item?.trade_order_type === "pickup" ? "Pickup" : "Delivery"}{" "}
              Partner
            </Typography>
          </Stack>
          <Stack>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={DB_NAME}
                label="Select"
                onChange={handleAssignDeliveryBoy}
                disabled={Checked === true ? true : false}
                sx={{
                  width: "8rem",
                  backgroundColor: "#595C9B",
                  borderRadius: "0",
                  borderColor: "white",
                }}
                size="small"
              >
                {Db_Id_data?.map((e) => {
                  return (
                    <MenuItem
                      onClick={() => handleGetdb_id(e, item?.order_id)}
                      value={e?.name}
                    >
                      {e?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "1rem" }}>
          <Typography>
            {item?.trade_order_type === "pickup" ? "Pickup" : "Delivery"}{" "}
            Address
          </Typography>
        </Stack>
        <Stack sx={{ marginTop: "1rem" }}>
          <Typography>{item.address}</Typography>
        </Stack>
        <Stack sx={{ marginTop: "2rem", textAlign: "center" }}>
          <Typography>
            {item?.trade_order_type === "pickup"
              ? "Pickup by :-  "
              : "Deliver by :-  "}
            {formatDate(item.order_schedule_datetime)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DataDrawerDdetails;
