import {
  TextField,
  Autocomplete,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Styles from "./styles/index.module.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./styles/index.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme } from "@mui/material/styles";
import { SearchFilter } from "../../../utility/functions/functions";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  getWalletTransactionDetails,
  getStateListTransMgt,
  getZoneListTrnsMgt,
  getSuperCategoryTransMgt,
  getHoldBalance,
  getSuperCategoryAccessTransMgt,
  getDistrictListTrnsMgt,
} from "../../../actions/TransationMgt/transMgtAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapper, TransactionBalanceCard } from "../CardWrapper";
import {
  AutocompleteTextFieldCurved,
  DateTextField,
  SearchInputField,
} from "../../../utility/TextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#323546",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row({ item, list }) {
  const handleChange = () => {};

  return (
    <>
      <TableRow sx={{ backgroundColor: "#323546" }}>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.first_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.mobile_no}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.trans_id}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {TableFormatDate(item.created_at)}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.debit_price}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.credit_price}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.balance}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {parseFloat(item.hold_balance).toFixed(2)}
        </TableCell>
      </TableRow>
    </>
  );
}

const TransactionMgt = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.TransationMgt);
  const [stateOptions, setStateOptions] = useState([]);
  const [scList, setScList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateListLoader, setStateListLoader] = useState(false);
  const [zoneListLoader, setZoneListLoader] = useState(false);
  const [districtListLoader, setDistrictListLoader] = useState(false);
  const [scListLoader, setScListLoader] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const column = ["first_name", "mobile_no"];
  const [text, setText] = useState("");
  // const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [endDateValue, setEndDateValue] = React.useState(new Date());
  const [list, setList] = useState([]);
  console.log(store, "TransactionMgt");

  const [obj, setObj] = React.useState({
    state_id: "",
    state_name: "",
    zone_name: "",
    zone_id: "",
    sc_id: "",
    sc_name: "",
    start: new Date(),
    end: new Date().setDate(new Date().getDate - 1),
    last_trans: true,
    district_id: "",
    district_name: "",
  });

  console.log(obj, "");

  useEffect(() => {
    var todayDateObj = new Date();
    var yesDateObj = new Date();
    yesDateObj.setDate(date.getDate() - 1);

    setObj({
      ...obj,
      start: getDateMonthYearFromTimeStamp(yesDateObj),
      end: getDateMonthYearFromTimeStamp(todayDateObj),
    });

    setStartDateValue(yesDateObj);
    setEndDateValue(todayDateObj);
    console.log("today date", todayDateObj);
    console.log("yester date", yesDateObj);

    dispatch(getStateListTransMgt(Axios));
    let param = {
      sc_id: 0,
    };

    dispatch(getSuperCategoryTransMgt(Axios, param));
    // dispatch(getSuperCategoryAccessTransMgt(Axios));
  }, []);

  useEffect(() => {
    setList(store?.wallet_trans_list ?? []);
  }, [store?.wallet_trans_list]);

  console.log(store?.wallet_trans_list, "wallet_trans_list");

  useEffect(() => {
    let arr = [];
    if (store?.state_list.length > 0) {
      setStateListLoader(true);

      setTimeout(() => {
        setStateListLoader(false);
        for (let i = 0; i < store?.state_list.length; i++) {
          arr.push(store?.state_list[i].state_name);
        }

        let param = {
          state_id: store?.state_list[0].state_id,
        };
        setObj({
          ...obj,
          state_id: store?.state_list[0].state_id,
          state_name: store?.state_list[0].state_name,
        });

        dispatch(getDistrictListTrnsMgt(Axios, param));
      }, [500]);
    }
    setStateOptions(arr);
  }, [store?.state_list]);

  useEffect(() => {
    let arr = [];
    if (store?.zone_list.length > 0) {
      setZoneListLoader(false);
      for (let i = 0; i < store?.zone_list.length; i++) {
        arr.push(store?.zone_list[i].zone_name);
      }
      setObj({
        ...obj,
        zone_name: store?.zone_list[0].zone_name,
        zone_id: store?.zone_list[0].zone_id,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: store?.zone_list[0].zone_name,
        sc_id: obj.sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "input param of zone change");
      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
    setZoneList(arr);
  }, [store?.zone_list]);

  useEffect(() => {
    let arr = [];
    if (store?.district_list?.length > 0) {
      setZoneListLoader(false);
      for (let i = 0; i < store?.district_list?.length; i++) {
        arr.push(store?.district_list[i]?.district_name);
      }
      setObj({
        ...obj,
        district_name: store?.district_list[0]?.district_name,
        district_id: store?.district_list[0]?.district_id,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: store?.zone_list[0]?.zone_name,
        sc_id: obj.sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      let param = {
        district_id: store?.district_list[0].district_id,
      };
      console.log(inputParam, "input param of zone change");
      dispatch(getZoneListTrnsMgt(Axios, param));
      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
    setDistrictList(arr);
  }, [store?.district_list]);

  useEffect(() => {
    dispatch(getHoldBalance(Axios));
    dispatch(getWalletTransactionDetails(Axios));
  }, []);

  useEffect(() => {
    setScListLoader(true);
    setTimeout(() => {
      setScListLoader(false);
      let arr = [];
      if (store?.sc_list.length > 0) {
        for (let i = 0; i < store?.sc_list.length; i++) {
          arr.push(store?.sc_list[i].name);
        }

        setObj({
          ...obj,
          sc_id: store?.sc_list[0].sc_id,
          sc_name: store?.sc_list.length > 0 ? store?.sc_list[0].name : "",
        });
      }

      setScList(arr);
    }, [500]);
  }, [store?.sc_list]);

  const handleStartDateChange = (newValue) => {
    setStartDateValue(newValue);
    setObj({
      ...obj,
      start: getDateMonthYearFromTimeStamp(newValue),
    });
    console.log(obj);
    // setObj({
    //   ...obj,
    //   zone_name: store?.zone_list[index].zone_name,
    //   zone_id: store?.zone_list[index].zone_id,
    // });

    let inputParam = {
      state_name: obj.state_name,
      zone_name: obj.zone_name,
      sc_id: obj.sc_id,
      start: getDateMonthYearFromTimeStamp(newValue),
      end: obj.end,
      last_trans: true,
    };
    console.log(inputParam, "inputParamStartDate");
    dispatch(getWalletTransactionDetails(Axios, inputParam));
  };

  const handleEndDateChange = (newValue) => {
    setEndDateValue(newValue);
    setObj({
      ...obj,
      end: getDateMonthYearFromTimeStamp(newValue),
    });

    let inputParam = {
      state_name: obj.state_name,
      zone_name: obj.zone_name,
      sc_id: obj.sc_id,
      start: obj.start,
      end: getDateMonthYearFromTimeStamp(newValue),
      last_trans: true,
    };
    console.log(inputParam, "inputParamEndDate");
    dispatch(getWalletTransactionDetails(Axios, inputParam));
  };

  const handleStateChange = (newValue) => {
    console.log(newValue + "newValue");
    let index = 0;
    if (store?.state_list.length > 0) {
      for (let i = 0; i < store?.state_list.length; i++) {
        if (newValue === store?.state_list[i].state_name) {
          console.log(newValue, store.state_list[i].state_name, "renlsjfkds");
          index = i;
          break;
        }
      }

      console.log(index, "renlsjfkds");

      setObj({
        ...obj,
        zone_id: "",
        zone_name: "",
        state_id: store?.state_list[index].state_id,
        state_name: store?.state_list[index].state_name,
      });

      let param = {
        state_id: store?.state_list[index].state_id,
      };
      console.log(JSON.stringify(store), "renlsjfkds");
      console.log(obj, "obj before sending request for zone");
      setDistrictListLoader(true);
      dispatch(getDistrictListTrnsMgt(Axios, param));
    }
  };

  const handleZoneChange = (newValue) => {
    let index = 0;
    if (store?.zone_list.length > 0) {
      for (let i = 0; i < store?.zone_list.length; i++) {
        if (newValue === store?.zone_list[i].zone_name) {
          index = i;
          break;
        }
      }

      setObj({
        ...obj,
        zone_name: store?.zone_list[index].zone_name,
        zone_id: store?.zone_list[index].zone_id,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: store?.zone_list[index].zone_name,
        sc_id: obj.sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "inputParam");

      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
  };

  const handleDistrictChange = (newValue) => {
    let index = 0;
    if (store?.district_list.length > 0) {
      for (let i = 0; i < store?.district_list.length; i++) {
        if (newValue === store?.district_list[i].district_name) {
          index = i;
          break;
        }
      }

      setObj({
        ...obj,
        district_name: store?.district_list[index]?.district_name,
        district_id: store?.district_list[index]?.district_id,
      });

      let param = {
        district_id: store?.district_list[index]?.district_id,
      };

      dispatch(getZoneListTrnsMgt(Axios, param));

      // let inputParam = {
      //   state_name: obj.state_name,
      //   zone_name: store?.zone_list[index]?.zone_name,
      //   sc_id: obj.sc_id,
      //   start: obj.start,
      //   end: obj.end,
      //   last_trans: true,
      // };
      // console.log(inputParam, "inputParam");

      // dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
  };

  const handleSCChange = (newValue) => {
    let index = 0;
    if (store?.sc_list.length > 0) {
      for (let i = 0; i < store?.sc_list.length; i++) {
        if (newValue === store?.sc_list[i].name) {
          index = i;
          break;
        }
      }

      setObj({
        ...obj,
        sc_id: store?.sc_list[index].sc_id,
        sc_name: store?.sc_list[index].name,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: obj.zone_name,
        sc_id: store?.sc_list[index].sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "inputParam");

      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = createTheme({
    palette: {
      // primary: {
      //   main: purple[500],
      // },
      // secondary: {
      //   main: green[500],
      // },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        ipm: 768,

        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  // console.log(new Date().getDate-1,"yesterday")
  var date = new Date();

  console.log(date, "today"); //# => Fri Apr 01 2011 11:14:50 GMT+0200 (CEST)

  date.setDate(date.getDate() - 1);

  console.log(date, "yesterday");

  // var dateObj = new Date();
  // console.log(getDateMonthYearFromTimeStamp(dateObj),"date-month-year");
  // var month = dateObj.getUTCMonth() + 1; //months from 1-12
  // var day = dateObj.getUTCDate();
  // var year = dateObj.getUTCFullYear();

  // var newdate = year + "/" + month + "/" + day;
  // var newdate2 = day + "-" + month + "-" + year;
  // console.log(newdate, "Date");
  // console.log(newdate2, "Date");

  const getDateMonthYearFromTimeStamp = (dateObj) => {
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let DateStr = day + "-" + month + "-" + year;
    return DateStr;
  };

  console.log(obj, "transObj");
  return (
    <div className="transactionContainer">
      <CardWrapper>
        <Stack sx={{ color: "white" }} gap={2.5}>
          <Stack gap={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Balance
            </Typography>
            <Stack direction={{ xs: "column", md: "row" }} gap={3}>
              <TransactionBalanceCard
                sx={{
                  minWidth: { xs: "200px", md: "300px", lg: "400px" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  color="white"
                >
                  <Typography>Available Balance</Typography>
                  <Typography>{`₹ ${
                    store?.available_balance ?? 0
                  }`}</Typography>
                </Stack>
              </TransactionBalanceCard>
              <TransactionBalanceCard
                sx={{
                  minWidth: { xs: "200px", md: "300px", lg: "400px" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  color="white"
                >
                  <Typography>Hold Balance</Typography>
                  <Typography>{`₹ ${
                    store?.hold_trans_balance ?? 0
                  }`}</Typography>
                </Stack>
              </TransactionBalanceCard>
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: "column",xl:"row" }}
            gap={{xs:"1rem",sm:"2rem"}}
            marginTop={1}
            // sx={{alignItems:{xs:"",md:"flex-start"}}}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{gap:{xs:"1rem",sm:"4rem",lg:"1rem"}}}
            >
              <Stack sx={{ display: "flex", gap: "1rem" }} direction={{xs:"column",lg:"row",xl:"column"}}>
                <Autocomplete
                  value={obj.sc_name}
                  onChange={(event, newValue) => {
                    handleSCChange(newValue);
                  }}
                  options={scList}
                  className={Styles.autocomplete}
                  sx={{
                    minWidth: {
                      xs: "100%",
                      sm: "250px",
                     md:"310px",
                      lg: "200px",
                      xl:"250px"
                    },
                  }}
                  loading={scListLoader}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {scListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="Super Category"
                    />
                  )}
                />
                <Autocomplete
                  value={obj.state_name}
                  onChange={(event, newValue) => {
                    handleStateChange(newValue);
                  }}
                  loading={stateListLoader}
                  sx={{
                    minWidth: {
                      xs: "100%",
                      sm: "250px",
                      md:"310px",
                      lg: "200px",
                      xl:"250px"
                    },
                  }}
                  options={stateOptions}
                  className={Styles.autocomplete}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {stateListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="State"
                    />
                  )}
                />
              </Stack>

              <Stack sx={{ display: "flex", gap: "1rem" }} direction={{xs:"column",lg:"row",xl:"column"}}>
                <Autocomplete
                  value={obj.district_name}
                  onChange={(event, newValue) => {
                    handleDistrictChange(newValue);
                  }}
                  options={districtList}
                  className={Styles.autocomplete}
                  loading={districtListLoader}
                  sx={{
                    minWidth: {
                      xs: "100%",
                      sm: "250px",
                      md:"310px",
                      lg: "200px",
                      xl:"250px"
                    },
                  }}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {zoneListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="District"
                    />
                  )}
                />

                <Autocomplete
                  value={obj.zone_name}
                  onChange={(event, newValue) => {
                    handleZoneChange(newValue);
                  }}
                  options={zoneList}
                  className={Styles.autocomplete}
                  loading={zoneListLoader}
                  sx={{
                    minWidth: {
                      xs: "100%",
                      sm: "250px",
                      md:"310px",
                      lg: "200px",
                      xl:"250px"
                    },
                  }}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {zoneListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="Zone"
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack sx={{width:{xs:"100%",sm:"50%"}}}>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                sx={{ border: "1px solid white", borderRadius: "20px" }}
                gap={1}
              >
                <IconButton
                  // className={Styles.Icon}
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "gray",
                      transition: "0.2s linear",
                    },
                  }}
                >
                  <SearchIcon sx={{ color: "black !important" }} />
                </IconButton>
                <SearchInputField
                  sx={{
                    flex: { xs: 1, md: 1 },
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    color: "white",
                  }}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Search username,mobile number..."
                />
                <IconButton
                  // className={Styles.Icon}
                  onClick={() => setText("")}
                >
                  <CloseIcon sx={{ color: "white !important" }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", gap: 1 }}
            padding={2}
            spacing={2}
          >
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: "white", minWidth: "50px" }}>
                From :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={startDateValue}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <DateTextField
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: "white", minWidth: "50px" }}>
                To :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={endDateValue}
                  onChange={handleEndDateChange}
                  renderInput={(params) => (
                    <DateTextField
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>

          <Stack>
            <TableContainer
              sx={{ minHeight: 440, minWidth: "100%", marginTop: "5px" }}
              component={Paper}
              className={Styles.TableContainer}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  className={Styles.head}
                  sx={{
                    backgroundColor: "#1B1D23",
                  }}
                  size="small"
                >
                  <TableRow
                    className={Styles.head}
                    sx={{
                      backgroundColor: "#1B1D23",
                      overflowX: "auto",
                      overflowY: "auto",
                    }}
                    size="small"
                  >
                    <StyledTableCell align="center" size="small">
                      Zone
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      User Name
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Mobile
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Transaction ID
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Time
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Debit
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Credit
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Balance
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Security
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    minHeight: "400px",
                    backgroundColor: "#323546 !important",
                  }}
                >
                  {list.length !== 0 ? (
                    <>
                      {SearchFilter(list, column, text)
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <Row
                            item={item}
                            list={list}
                            key={`${item.zone_name} ${index}`}
                          />
                        ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={14} align="center">
                        *No Rules*
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {console.log(list.length, "length")}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Stack>
      </CardWrapper>
    </div>
  );
};

export default TransactionMgt;

//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "8217847430",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },
// {
//   zone_name: "Bangalore",
//   first_name: "Vijay",
//   last_name: "Vijay",
//   mobile_no: "00000000",
//   trans_id: "HDFC22jgse",
//   created_at: "2022-11-22",
//   debit_price: 200,
//   credit_price: 300,
//   balance: 2000,
//   hold_balance: 2000,
// },

const TableFormatDate = (Datestr) => {
  // let  = "2022-12-09T00:36:31.000Z";
  const formateDate = new Date(Datestr);
  const MonthArr = [
    "jan",
    "feb",
    "march",
    "april",
    "may",
    "june",
    "july",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
  ];
  var month = formateDate.getUTCMonth(); //months from 1-12
  var day =
    formateDate.getUTCDate() < 10
      ? "0" + formateDate.getUTCDate()
      : formateDate.getUTCDate();
  var year = formateDate.getUTCFullYear();
  var hours = formateDate.getUTCHours();
  var minutes = formateDate.getUTCMinutes();
  var am_pm = formateDate.getHours() >= 12 ? "PM" : "AM";
  return (
    day +
    "-" +
    MonthArr[month] +
    "-" +
    year +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    am_pm
  );
};
