import { Autocomplete, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  VideoAnalyticsAllDistrictList,
  VideoAnalyticsAllStateList,
  VideoAnalyticsAllZoneList,
  VideoAnalyticsDistrictByStateId,
  VideoAnalyticsVideoDetailsByZone,
  VideoAnalyticsVideoDetailsByZoneAndVideo,
  VideoAnalyticsVideoDetailsByZoneVideoAndWatchCount,
  VideoAnalyticsZoneByDistrictIdAndStateId,
  videoAnalyticsAllWatchCountByVideoName,
  videoAnalyticsWatchCountByVideoName,
} from "../../../actions/VideoAnalytics/VideoAnalyticsAction";
import {
  GETALLNAMEIDVALUE,
  GETDISTRICTNAMEBYSTATEID,
  GETSPECIFICWATCHCOUNT,
  GETVIDEOANALYTICSDISTRICTNAME,
  GETVIDEOANALYTICSSTATENAME,
  GETVIDEOANALYTICSZONENAME,
  GETVIDEONAMEBYZONE,
  GETWATCHCOUNTBYVIDEOTITLE,
  GETZONENAMEBYDISTRICTID,
} from "../../../utility/ReduxConstants/VideoAnalyticsReducerConstant";
import { set } from "date-fns";

const VideoAnalyticsDropdown = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const [obj, setObj] = useState({
    state_name: null,
    state_id: null,
    district_id: null,
    zone_id: null,
    selected_zoneName: null,
    video_id: null,
    video_name: null,
    max_watch_count: null,
    max_watch_count_list: null,
    selected_watchCount: null,
    flag: true,
  });

  useEffect(() => {
    if (obj?.flag === true) {
      setObj({ ...obj, selected_zoneName: "All", flag: false });
    }
  }, [obj?.selected_zoneName]);

  useEffect(() => {
    dispatch({ type: GETALLNAMEIDVALUE, payload: obj });
  }, [obj]);

  const AllNameIdValue = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsAllNameIdDetails
  );
  console.log(AllNameIdValue, "districtByStateId");

  const AllStateListWithDetails = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllStateList
  );

  const AllStateNameList = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllStateName
  );

  const AllDistrictListWithDetails = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllDistrictList
  );

  const AllDistrictNameList = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllDistrictName
  );

  const AllZoneListWithDetails = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllZoneList
  );



  const AllZoneNameList = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsAllZoneName
  );
  
  const WatchCountByVideoName = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsWatchCountByVideoName
  );

  const AllWatchCountByVideoName = useSelector(
    (store) =>
      store.videoAnalyticsReducer?.videoAnalyticsAllWatchCountByVideoName
  );

  const WatchCountList = useSelector(
    (store) => store.videoAnalyticsReducer?.videoAnalyticsSpecificWatchCount
  );

  const videoByZoneAndVideo = useSelector(
    (store) =>
      store.videoAnalyticsReducer?.videoAnalyticsUserVideoDetailsByZoneAndVideo
  );

  const videoByZoneVideoAndWatchCount = useSelector(
    (store) =>
      store.videoAnalyticsReducer
        ?.videoAnalyticsUserVideoDetailsByZoneVideoAndWatchCount
  );

  useEffect(() => {
    let param = {
      zone_name: obj?.selected_zoneName,
      video_id: obj?.video_id,
    };
    dispatch(VideoAnalyticsVideoDetailsByZoneAndVideo(Axios, param));
  }, [obj]);
  useEffect(() => {
    let param = {
      zone_name: obj?.selected_zoneName,
      video_id: obj?.video_id,
      watch_count: obj?.selected_watchCount,
    };
    dispatch(VideoAnalyticsVideoDetailsByZoneVideoAndWatchCount(Axios, param));
  }, [obj]);

  console.log(videoByZoneVideoAndWatchCount, "stateList");
  console.log(videoByZoneAndVideo, "stateList");

  useEffect(() => {
    dispatch(VideoAnalyticsAllStateList(Axios));
    let param1={
      state_id:0
    }
    dispatch(VideoAnalyticsAllDistrictList(Axios,param1));
    let param = {
      district_id: 0,
    };
    dispatch(VideoAnalyticsAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    let Arr = [];

    for (let i = 0; i < AllStateListWithDetails?.length; i++) {
      Arr.push(AllStateListWithDetails[i]?.state_name);
    }

    dispatch({ type: GETVIDEOANALYTICSSTATENAME, payload: Arr });
  }, [AllStateListWithDetails]);

  useEffect(() => {
    let Arr = [];

    for (let i = 0; i < AllDistrictListWithDetails?.length; i++) {
      Arr.push(AllDistrictListWithDetails[i]?.district_name);
    }

    dispatch({ type: GETVIDEOANALYTICSDISTRICTNAME, payload: Arr });
  }, [AllDistrictListWithDetails]);

  useEffect(() => {
    let Arr = ["All"];

    for (let i = 0; i < AllZoneListWithDetails?.length; i++) {
      Arr.push(AllZoneListWithDetails[i]?.zone_name);
    }

    dispatch({ type: GETVIDEOANALYTICSZONENAME, payload: Arr });
  }, [AllZoneListWithDetails]);

  useEffect(() => {
    let Arr = [];

    for (let i = 0; i < AllStateListWithDetails?.length; i++) {
      Arr.push(AllStateListWithDetails[i]?.state_name);
    }

    dispatch({ type: GETVIDEOANALYTICSSTATENAME, payload: Arr });
  }, [AllStateListWithDetails]);

  const districtByStateId = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsdistrictListByStateId
  );

  const handleState = async (e, value) => {
    let stateId = 0;
    for (let i = 0; i < AllStateListWithDetails?.length; i++) {
      if (value == AllStateListWithDetails[i]?.state_name) {
        stateId = AllStateListWithDetails[i]?.state_id;
      }
    }

    setObj({ ...obj, state_id: stateId, state_name: value });
  };

  useEffect(() => {
    let param = {
      state_id: obj.state_id,
    };

    dispatch(VideoAnalyticsDistrictByStateId(Axios, param));
  }, [obj.state_id]);

  const districtNameByStateId = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsDistrictNameByStateId
  );

  const zoneListByDistrictId = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsZoneListByDistrictId
  );

  console.log(AllDistrictListWithDetails,"AllZoneListWithDetails")

  const zoneNameByDistrictId = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsZoneNameByDistrictId
  );

  const videoDetailsByZone = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsVideoDetailsByZone
  );

  const videoNameByZone = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsVideoNameByZone
  );
  console.log(videoDetailsByZone, "stateList");

  useEffect(() => {
    let Arr = [];
    for (let i = 0; i < districtByStateId?.length; i++) {
      Arr.push(districtByStateId[i]?.district_name);
    }
    dispatch({ type: GETDISTRICTNAMEBYSTATEID, payload: Arr });
  }, [districtByStateId]);

  const handleDistrict = (e, value) => {
    let districtId = 0;
    for (let i = 0; i < AllDistrictListWithDetails?.length; i++) {
      if (value === AllDistrictListWithDetails[i]?.district_name) {
        districtId = AllDistrictListWithDetails[i]?.district_id;
      }
    }
console.log(districtId,"AllZoneListWithDetails")
    if (obj?.flag === true) {
      setObj({
        ...obj,
        selected_zoneName: "All",
        flag: false,
        district_id: districtId,
        video_name: "All",
      });
    } else {
      setObj({ ...obj, district_id: districtId });
    }

    let param = {
      district_id: districtId,
    };
    dispatch(VideoAnalyticsZoneByDistrictIdAndStateId(Axios, param));

    // if(obj?.flag===true){
    //   setObj({...obj,selected_zoneName:"All",flag: false})}
    // // if (videoNameByZone?.length > 0) {
    // //   setObj({ ...obj, district_id: districtId, selected_zoneName: "All" });
    // // } else {
    //   setObj({ ...obj, district_id: districtId });
    // // }
  };

  console.log(zoneNameByDistrictId?.length, "stateList");

  useEffect(() => {
    let param = {
      district_id: obj?.district_id,
    };
    dispatch(VideoAnalyticsZoneByDistrictIdAndStateId(Axios, param));
  }, [obj?.district_id]);

  useEffect(() => {
    let Arr = ["All"];

    for (let i = 0; i < zoneListByDistrictId?.length; i++) {
      Arr.push(zoneListByDistrictId[i].zone_name);
    }

    if (Arr?.length === 1) {
      Arr.shift();
    }

    dispatch({ type: GETZONENAMEBYDISTRICTID, payload: Arr });
  }, [zoneListByDistrictId]);

  const handleZone = (e, value) => {
    console.log(value, "districtByStateId");
    setObj({ ...obj, selected_zoneName: value });
    let param = {
      zone_name: value,
    };
    console.log(param, "districtByStateId");

    dispatch(VideoAnalyticsVideoDetailsByZone(Axios, param));
  };

  console.log(obj, "districtByStateId");

  useEffect(() => {
    let Arr = ["All"];
    for (let i = 0; i < videoDetailsByZone?.length; i++) {
      Arr.push(videoDetailsByZone[i]?.video_name);
    }

    if (Arr?.length === 1) {
      Arr.shift();
    }
    dispatch({ type: GETVIDEONAMEBYZONE, payload: Arr });
  }, [videoDetailsByZone]);

  useEffect(() => {
    dispatch(videoAnalyticsAllWatchCountByVideoName(Axios));
  }, []);

  const handleVideo = (e, value) => {
    let videoId = 0;
    for (let i = 0; i < AllWatchCountByVideoName?.length; i++) {
      if (value === AllWatchCountByVideoName[i]?.video_name) {
        videoId = AllWatchCountByVideoName[i]?.video_id;
      }
    }
    setObj({ ...obj, video_id: videoId, video_name: value });
  };

  useEffect(() => {
    let param = { video_id: obj.video_id };
    dispatch(videoAnalyticsWatchCountByVideoName(Axios, param));
  }, [obj]);

  useEffect(() => {
    let Arr = [];
    for (let i = 0; i < WatchCountByVideoName?.length; i++) {
      Arr.push(WatchCountByVideoName[i]?.watch_count);
    }

    dispatch({ type: GETSPECIFICWATCHCOUNT, payload: Arr });
  }, [WatchCountByVideoName]);

  const [maxWatchCount, setMaxWatchCount] = useState(null);
  useEffect(() => {
    let maxValue = Number.MIN_SAFE_INTEGER;
    for (let i = 0; i < videoDetailsByZone?.length; i++) {
      if (maxValue < videoDetailsByZone[i]?.watch_count) {
        maxValue = videoDetailsByZone[i]?.watch_count;
      }
    }

    setObj({ ...obj, max_watch_count: maxValue });
  }, [videoDetailsByZone]);

  useEffect(() => {
    let Arr = [];
    for (let i = 1; i <= obj?.max_watch_count; i++) {
      Arr.push(i);
    }
    setObj({ ...obj, max_watch_count_list: Arr });
  }, [obj?.max_watch_count]);

  console.log(obj, "stateList");

  // useEffect(() => {
  //   setObj({ ...obj, state_name: AllStateListWithDetails?.[0]?.state_name });
  // }, []);

  const handleWatchCount = (e, value) => {
    console.log(value, "stateList");
    setObj({ ...obj, selected_watchCount: value });
  };

  return (
    <>
      <Stack
        flex={1}
        direction={{ xs: "column", sm: "row" }}
        spacing={0.5}
        padding={1}
        gap={1}
        sx={{ display: "flex" }}
      >
        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.state_name}
          options={AllStateNameList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleState}
          renderInput={(params) => (
            <AutocompleteTextFieldCurved
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              variant="outlined"
              label="State"
              placeholder="Select State.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.year}
          options={districtNameByStateId}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleDistrict}
          renderInput={(params) => (
            <AutocompleteTextFieldCurved
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              variant="outlined"
              label="District"
              placeholder="Select Year.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.selected_zoneName}
          options={zoneNameByDistrictId}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleZone}
          renderInput={(params) => (
            <AutocompleteTextFieldCurved
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              variant="outlined"
              label="Zone"
              placeholder="Select State.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.video_name}
          options={videoNameByZone}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, value) => option === value}
          size="small"
          onChange={handleVideo}
          renderInput={(params) => (
            <AutocompleteTextFieldCurved
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              variant="outlined"
              label="Video Title"
              placeholder="Select State.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.stateName}
          options={obj?.max_watch_count_list}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleWatchCount}
          renderInput={(params) => (
            <AutocompleteTextFieldCurved
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              variant="outlined"
              label="Watch Count"
              placeholder="Select State.."
            />
          )}
        />
      </Stack>
    </>
  );
};

export default VideoAnalyticsDropdown;
