import { Backdrop, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'
import React, { Children, Fragment } from 'react'
import ReactDOM from 'react-dom';
import Alert from '@mui/material/Alert';


const BackDropFnc = (props) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props?.open}
            onClick={props?.handleClose}
        >
            {props.children}
        </Backdrop>
    )
}
const ModelFnc = (props) => {

    const { open, handleClose, Content, Title, Img, handleAgree } = props
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`${Title} ?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction="column" alignItems={"center"} justifyContent={"center"} gap={1}>
                            {
                                Img && Img?.length !== 0 ?
                                    <Stack>
                                        <img src={Img} style={{ width: "100px", height: "100px", objectFit: "contain" }} />
                                    </Stack>
                                    :
                                    null}
                            <Stack>
                                <Typography variant="body2">{Content}</Typography>
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleAgree} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const OVERLAY = document.getElementById('overlay')

const PopUpModal = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(<BackDropFnc open={props.open} handleClose={props.handleClose} />, OVERLAY)}
            {ReactDOM.createPortal(<ModelFnc {...props}>{props.children}</ModelFnc>, OVERLAY)}
            {/* {ReactDOM.createPortal(props.children, OVERLAY)} */}
        </Fragment>

    )
}

export default PopUpModal


export const ImagePopUpModal = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <>
               
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props?.open}
                        onClick={props?.handleClose}
                    ></Backdrop>
                    {props.children}
                </>
                , OVERLAY)}
          
        </Fragment>

    )
}



export const PopUpforApi = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <>
                  <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props?.open}
                    ></Backdrop>
                    {props.children}
                </>
                , OVERLAY)}
          
        </Fragment>

    )
}