import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  AutocompleteTextFieldNormal,
  DateTextFieldCalenderWallet,
} from "../../../../utility/TextField";
import React, { useEffect, useState } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import {
  PartnerWalletAllDistrictList,
  PartnerWalletAllStateList,
  PartnerWalletAllZoneList,
} from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";
import { GETWALLETOBJCHANGEDVALUE } from "../../../../utility/ReduxConstants/PartnerWalletManagementReducerConstant";

const PartnerWalletTransactionCoinsDetails = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const [stateList, setStateList] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const current_date = new Date();
  const twoDaysAgo = new Date(current_date);
  twoDaysAgo.setDate(current_date.getDate() - 2);
  const [obj, setObj] = useState({
    state_id: null,
    state_default_name: null,
    zone_default_name: null,
    district_default_name: null,
    sc_default_name: null,
    status: null,
    start_date: twoDaysAgo,
    end_date: current_date,
  });
  const allStateList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllStateList
  );

  const allDistrictList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllDistrictList
  );
  const allZoneList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllZoneList
  );

  const allUserList = useSelector(
    (store) => store.PartnerWalletManagementReducer.vitaranUserDetails
  );
  const walletChangedValueDetails = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletChangedValueDetails
  );
  
  useEffect(() => {
    dispatch(PartnerWalletAllStateList(Axios));
  }, []);

  useEffect(() => {
    dispatch({ type: GETWALLETOBJCHANGEDVALUE, payload: obj });
  }, [obj]);

  useEffect(() => {
    let param = {
      district_id: 0,
    };
    dispatch(PartnerWalletAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    let param = { state_id: 0 };
    dispatch(PartnerWalletAllDistrictList(Axios, param));
  }, []);

  useEffect(() => {
    let state = [];

    for (let i = 0; i < allStateList?.length; i++) {
      state.push(allStateList[i]?.state_name);
    }

    setStateList(state);
    // setObj({
    //   ...obj,
    //   state_list: state,
    // });
  }, [allStateList]);

  useEffect(() => {
    let district = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      district.push(allDistrictList[i]?.district_name);
    }
    setDistrictList(district);
    // setObj({
    //   ...obj,

    //   district_list: district,
    // });
  }, [allDistrictList]);

  useEffect(() => {
    let zone = [];
    for (let i = 0; i < allZoneList?.length; i++) {
      zone.push(allZoneList[i]?.zone_name);
    }

    setZoneList(zone);

    // setObj({
    //   ...obj,

    //   zone_list: zone,
    // });
  }, [allZoneList]);

  const handleState = (e, value) => {
    let stateId = [];
    for (let i = 0; i < allStateList?.length; i++) {
      if (value === allStateList[i].state_name) {
        stateId = allStateList[i].state_id;
        break;
      }
    }

    setObj({
      ...obj,
      state_id: stateId,
    });

    let param = { state_id: stateId };

    dispatch(PartnerWalletAllDistrictList(Axios, param));
  };

  const handleDistrict = (e, value) => {
    let districtId = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      if (value === allDistrictList[i]?.district_name) {
        districtId = allDistrictList[i]?.district_id;
        break;
      }
    }

    setObj({
      ...obj,
      district_id: districtId,
    });

    let param = { district_id: districtId };

    dispatch(PartnerWalletAllZoneList(Axios, param));
  };

  const handleZone = (e, value) => {
    setObj({ ...obj, zone_name: value });
  };

  const handleStartDateChange = (newValue) => {
    setObj((prevObj) => ({
      ...prevObj,
      start_date: newValue,
    }));
  };
  

  const handleEndDateChange = (newValue) => {
    setObj((prevObj) => ({
      ...prevObj,
      end_date: newValue,
    }));
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "3rem",
        }}
      >
        {/* <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            flex={1}
            direction={{ xs: "column", sm: "row" }}
            padding={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "35rem",
            }}
          >
            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.stateName}
              options={stateList}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleState}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "85%",
                    backgroundColor: "#25293C",

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="State"
                  placeholder="Select State.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.year}
              options={districtList}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleDistrict}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "85%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="District"
                  placeholder="Select District.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.year}
              options={zoneList}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleZone}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "85%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Zone"
                  placeholder="Select Zone.."
                />
              )}
            />
          </Stack>
        </Stack> */}
        <Stack
          sx={{
            dispaly: "flex",
            flexDirection: "row",

            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "white" }}>Date Range: </Typography>
          <Typography sx={{ color: "white" }}>From: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              value={obj?.start_date}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Typography sx={{ color: "white" }}>To: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              value={obj?.end_date}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default PartnerWalletTransactionCoinsDetails;
