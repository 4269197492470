import { Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Styles from './styles/AddProduct.module.css';
import images from '../../../../utility/constants/images';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddProductData } from '../../../../actions/product/productAction';

const viewImgPath = `${baseImgPath}/product/`;

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));


const RedBorderButton = styled(Button)(({ theme }) => ({
    color: "#DC1D13",
    backgroundColor: "#323546",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#fff",
    },
}));

const AddProduct = () => {
    const [file, setFile] = useState('');
    const [img, setImg] = useState(defaultImg);
    const [addsuccess, setAddsuccess] = useState(false)
    const [failed, setFailed] = useState({
        addFailed: false,
        addFailedMessage: ""
    });
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        "item_name": "",
        "item_desc": "",
        "item_image": ""
    });
    const [error, setError] = useState({})

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleCancel = () => {
        setForm({
            ...form,
            "item_name": "",
            "item_desc": "",
            "item_image": ""
        })
    }

    const IsValid = () => {
        let success = true;
        let err = {};

        if (!form.item_name.trim()) {
            err.productname = '**Product Name Cannot Be Empty**'
            success = false;
        }
        else if(!(/^[a-z0-9A-Z]([\w -]*[a-z0-9A-Z])?$/).test(form.item_name)){
            err.productname = '*No Special Characters Allowed! Please Enter Valid Name*'
            success = false
        }

        if (!form.item_image.trim()) {
            err.productimage = '**Please Choose a Image**'
            success = false;
        }

        if (!form.item_desc.trim()) {
            err.description = '**Product Description Cannot Be Empty**'
            success = false;
        }

        setError(err)
        return success
    }

    const handleSubmit = () => {

        if (IsValid()) {
            let param = {
                "type": "Product",
                "item_name": `${form.item_name}`,
                "item_desc": `${form.item_desc}`,
                "item_image": `${viewImgPath}${form.item_image}`
            }

            dispatch(AddProductData(param, setAddsuccess, failed, setFailed))
        }

    }

    const imgUpload = async (formData) => {
        await axios.post(`${baseImgUploadUrl}image_upload_product`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(res => {
            console.log(res)
            if (res.data.code === 200) {
                toast.success("Uploaded SuccessFully")
                setImg(`${res.data.full_path}`);
                setForm({
                    ...form,
                    item_image: res.data.name
                })
            }
            else {
                toast.error("Img Not Uploaded")
            }

        })
            .catch(err => console.log(err.message))
    }
    const imgSelect = (event) => {
        setFile(event.target.files[0]);

    }

    React.useEffect(() => {
        if (addsuccess) {
            toast.success("Added Product Details SuccessFully");
            setForm({
                ...form,
                "item_name": "",
                "item_desc": "",
                "item_image": ""
            })
            setImg(defaultImg);
            setAddsuccess(false);
        }
    }, [addsuccess])

    React.useEffect(() => {

        if (failed.addFailed) {
            let text = failed.addFailedMessage;
            toast.error(`${text}`)

            setFailed({
                ...failed,
                addFailed: false,
                addFailedMessage: ''
            })
        }

    }, [failed])

    React.useEffect(() => {
        const formData = new FormData();
        formData.append('file', file);
        imgUpload(formData)
    }, [file]);



    return (
        <>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={Styles.Container}>
                <div className={Styles.TopContainer}>
                    <div className={Styles.ImageContainer}>
                        <img src={img} alt="cigarete" />
                    </div>
                    <input type="file" accept="image/*" name="image-upload" id={`ProductImgUploadAdd`} className={Styles.imginp} onChange={imgSelect} />
                    <div className={Styles.ImageUploadContainer}>

                        <label className={Styles.ImageUploadButton} htmlFor={`ProductImgUploadAdd`} >
                            UPLOAD
                        </label>
                    </div>
                    {error.productimage && <span className={Styles.error}>{error.productimage}</span>}
                </div>

                <div className={Styles.MidContainer}>
                    <div className={Styles.List}>
                        <div className={Styles.SkuContainer}>
                            <label className={Styles.SkuText}>Product Name :</label>
                        </div>
                        <div className={Styles.InputContainer}>
                            <input className={Styles.Input} value={form.item_name} name="item_name" autoComplete='off' onChange={handleChange} />
                            {error.productname && <span className={Styles.error}>{error.productname}</span>}
                        </div>
                    </div>

                    <div className={Styles.List}>
                        <div className={Styles.SkuContainer}>
                            <label className={Styles.SkuText}>Description :</label>
                        </div>
                        <div className={Styles.InputContainer}>
                            <textarea className={Styles.Input} rows="3" name="item_desc" autoComplete='off' value={form.item_desc} onChange={handleChange} />
                            {error.description && <span className={Styles.error}>{error.description}</span>}
                        </div>
                    </div>
                </div>


                <div className={Styles.ButtonContainer}>
                    <RedButton onClick={handleSubmit}>Add</RedButton>
                    <RedBorderButton onClick={handleCancel}>Cancel</RedBorderButton>
                </div>
            </div>
        </>
    )
}

export default AddProduct