import React from "react";
import VideoAnalyticsDropdown from "./VideoAnalyticsDropdown";
import VideoAnalyticsTable from "./VideoAnalyticsTable";

const VideoAnalytics = () => {
  return (
    <>
      <VideoAnalyticsDropdown />
      <VideoAnalyticsTable />
    </>
  );
};

export default VideoAnalytics;
