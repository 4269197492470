import {
  GETWALLETALLDISTRICTLIST,
  GETWALLETALLSTATELIST,
  GETWALLETALLZONELIST,
  GETWALLETTABLEDETAILS,
  GETWALLETTRANSTABLEDETAILS,
} from "../../utility/ReduxConstants/WalletManagementReducerConstant";

export const WalletAllStateList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");

    if (data.code === 200) {
      dispatch({ type: GETWALLETALLSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletAllDistrictList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AlldistrictList", param);

    if (data.code === 200) {
      dispatch({ type: GETWALLETALLDISTRICTLIST, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletAllZoneList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllzoneList", param);
    console.log(data, "walletmngrtrr");

    if (data.code === 200) {
      dispatch({ type: GETWALLETALLZONELIST, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletTableDetails = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("vitaranPartnerList", param);
    console.log(data, "walletmngrtrr");

    if (data.code === 200) {
      dispatch({ type: GETWALLETTABLEDETAILS, payload: data.vp_list });
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletSendCoinsConfirm = (Axios, param) => async (dispatch) => {
  console.log(param, "walletManagement");
  try {
    const { data } = await Axios.post("transferCoin", param);
    console.log(data, "walletManagement");

    if (data.code === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletTransTableDetails = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("walletTransHistory", param);
    console.log(data, "walletmngrtrr");

    if (data.code === 200) {
      dispatch({ type: GETWALLETTRANSTABLEDETAILS, payload: data.trans_list });
    }
  } catch (error) {
    console.log(error);
  }
};

