import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  useFormControl,
} from "@mui/material";
import Styles from "./styles/index.module.css";
import SkuByZoneTable from "./SkuByZoneTable";
import SkuByZoneItem from "./SkuByZoneItem";
import { useDispatch, useSelector } from "react-redux";
import {
  ExceptionAccessDistrictList,
  ExceptionAccessStateList,
  ExceptionAccessZoneList,
  ExceptionDistrictList,
  ExceptionStateList,
  ExceptionZoneList,
  GetCategoryListForException,
  GetSkuExceptionListByZoneNew,
} from "../../../actions/Exception/SkuException";
import "./styles/index.scss";
import {
  GETEXCEPTIONSUPERCATEGORYLIST,
  GETRETAINVALUEFROMLOCAL,
} from "../../../utility/ReduxConstants/SkuExceptionReducerConstant";
import {
  GetAccessSuperCategoryList,
  GetSuperCategoryList,
} from "../../../actions/newSkuAction/newSkuAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { ROLES } from "../../../utility/UserAllowedRoles";
import { CardWrapper } from "../CardWrapper";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import SearchBarComponent from "../../SearchBarComponent";
import { CheckSmallError } from "../../../utility/ErrorCheck";
const AccessSkuByZone = () => {
  const [stateListLoader, setStateListLoader] = useState(true);
  const [districtListLoader, setDistrictListLoader] = useState(true);
  const [zoneListLoader, setZoneListLoader] = useState(true);
  const [scListLoader, setScListLoader] = useState(false);
  const [categoryListLoader, setCategoryListLoader] = useState(true);
  const [selectedState, setSelectedState] = useState(null);
  const [text, setText] = useState("");
  const LoginStore = useSelector((state) => state.Login);

  let Allowed = ROLES.SKU_EXCEPTION_MGT_FILTER.includes(
    parseInt(LoginStore?.user?.admin_role_code)
  );
  let superCategoryAdminAllowed = ROLES.SKU_EXCEPTION_CATEGORY_ADMIN.includes(
    parseInt(LoginStore?.user?.admin_role_code)
  );
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();
  const store = useSelector((state) => state.Exception);
  console.log(store, "NewParam");
  console.log(store, "StoreException");
  const [state, setState] = useState({
    accessStateList: [],
    state: {},
  });

  const [district, setDistrict] = useState({
    accessDistrictList: [],
    district: {},
  });

  const [zone, setZone] = useState({
    accessZoneList: [],
    zone: {},
  });

  console.log(district, zone, "745747445");
  const [obj, setObj] = useState({
    categoryList: [],
    category: {},
  });
  const [superCategory, setSuperCategory] = useState({
    List: [],
    sc: {},
  });

  console.log(zone, "zoneabcbciecebe");

  useEffect(() => {
    dispatch(ExceptionAccessStateList(Axios));
  }, []);

  console.log(store, "avsvsdvsvsvSDVsdvvsd");

  useEffect(() => {
    if (
      store.accessStateList !== undefined &&
      store.accessStateList !== null &&
      store.accessStateList?.length !== 0
    ) {
      setStateListLoader(true);
      let Arr = [];
      Arr.push({ state_id: 0, state_name: "All" });
      Arr = [...store?.accessStateList];

      let FinalArr = Arr.flat();
      console.log(FinalArr, "FinalArr");
      let param = {
        state_id: Arr[0]?.state_id,
      };
      setDistrictListLoader(true);

      dispatch(ExceptionAccessDistrictList(Axios, param));

      setState({
        ...state,
        accessStateList: FinalArr,
        state: Arr[0],
      });
      setStateListLoader(false);
    }
  }, [store.accessStateList]);

  // useEffect(()=>{
  //   let param = {
  //     state_id: storedRetainState
  //   };

  //   dispatch(ExceptionDistrictList(Axios, param));
  //   let Arr = [];

  //   Arr = [...store?.districtList];
  //   setDistrict((prev)=>({
  //     ...prev,
  //     districtList:Arr
  //   }))
  // },[storedRetainState])

  console.log(district, "StoreException");

  useEffect(() => {
    if (
      store.accessDistrictList !== undefined &&
      store.accessDistrictList !== null
    ) {
      setDistrictListLoader(true);
      setZoneListLoader(true);
      setTimeout(() => {
        if (Allowed) {
          let Arr = [];
          Arr.push({ district_id: 0, district_name: "All" });

          Arr = [...Arr, store?.accessDistrictList];
          let FinalArr = Arr.flat();
          console.log(FinalArr, "setZoneListLoader");
          setDistrict({
            ...district,
            accessDistrictList: FinalArr,
            district: FinalArr[0],
          });
          setDistrictListLoader(false);
          setZoneListLoader(false);
        } else {
          let Arr = [];
          Arr = [...Arr, store.accessDistrictList];
          let FinalArr = Arr.flat();

          setDistrict({
            ...district,
            accessDistrictList: FinalArr,
            district: FinalArr[0] ?? { district_id: 0, district_name: "" },
          });
          setDistrictListLoader(false);
          // (async () => {
          //   setZoneListLoader(true);
          //   let param = {
          //     zone_id: FinalArr[0]?.zone_id ?? { zone_id: 0, zone_name: "" },
          //   };
          //   const data = await dispatch(GetSuperCategoryList(Axios, param));
          //   if (data.code === 200) {
          //     dispatch({
          //       type: GETEXCEPTIONSUPERCATEGORYLIST,
          //       payload: data.body,
          //     });
          //     if (
          //       data.body !== undefined &&
          //       data.body !== null &&
          //       data.body.length !== 0
          //     ) {
          //       let Arr = data.body;
          //       let param = {
          //         sc_id: Arr[0].sc_id,
          //       };
          //       dispatch(GetCategoryListForException(Axios, param));
          //     }

          //     setScListLoader(false);
          //   } else {
          //     console.log("failed to fetch sc list");
          //     setZoneListLoader(false);
          //     setScListLoader(false);
          //   }
          // })();
        }
      }, [500]);
    }
  }, [store.accessDistrictList]);

  const [storedRetainState, setStoredRetainState] = useState(null);
  const [storedRetainDistrict, setStoredRetainDistrict] = useState(null);
  const [storedRetainZone, setStoredRetainZone] = useState(null);
  const [storedRetainCategory, setStoredRetainCategory] = useState(null);
  const [storedRetainSuperCategory, setStoredRetainSuperCategory] =
    useState(null);

  useEffect(() => {
    const storedState = JSON.parse(localStorage.getItem("selectedStateLocal"));
    const storedDistrict = JSON.parse(
      localStorage.getItem("selectedDistrictLocal")
    );
    const storedZone = JSON.parse(localStorage.getItem("selectedZoneLocal"));
    const storedCategory = JSON.parse(
      localStorage.getItem("selectedCategoryLocal")
    );
    const storedSuperCategory = JSON.parse(
      localStorage.getItem("selectedSuperCategoryLocal")
    );

    console.log(storedState, "Target Name:");

    setTimeout(() => {
      setStoredRetainState(storedState);
      setStoredRetainDistrict(storedDistrict);
      setStoredRetainZone(storedZone);
      setStoredRetainCategory(storedCategory);
      setStoredRetainSuperCategory(storedSuperCategory);
    }, 300);

    // ... repeat for other dropdowns ...
  }, []);

  console.log(storedRetainState, "Target Name:");

  useEffect(() => {
    if (store.accessZoneList !== undefined && store.accessZoneList !== null) {
      setZoneListLoader(true);
      setScListLoader(true);
      setTimeout(() => {
        if (Allowed) {
          let Arr = [];
          Arr.push({ zone_id: 0, zone_name: "All" });

          Arr = [...Arr, store?.accessZoneList];
          let FinalArr = Arr.flat();
          console.log(FinalArr, "setZoneListLoader");
          setZone({
            ...zone,
            accessZoneList: FinalArr,
            zone: FinalArr[0],
          });
          setZoneListLoader(false);
          setScListLoader(false);
        } else {
          let Arr = [];
          Arr = [...Arr, store.accessZoneList];
          let FinalArr = Arr.flat();

          setZone({
            ...zone,
            accessZoneList: FinalArr,
            zone: FinalArr[0] ?? { zone_id: 0, zone_name: "" },
          });
          setZoneListLoader(false);
          (async () => {
            setScListLoader(true);
            let param = {
              zone_id: FinalArr[0]?.zone_id ?? { zone_id: 0, zone_name: "" },
            };
            const data = await dispatch(
              GetAccessSuperCategoryList(Axios, param)
            );
            if (data.code === 200) {
              dispatch({
                type: GETEXCEPTIONSUPERCATEGORYLIST,
                payload: data.body,
              });
              if (
                data.body !== undefined &&
                data.body !== null &&
                data.body?.length !== 0
              ) {
                let Arr = data.body;
                let param = {
                  sc_id: Arr[0].sc_id,
                };
                dispatch(GetCategoryListForException(Axios, param));
              }

              setScListLoader(false);
            } else {
              console.log("failed to fetch sc list");
              setZoneListLoader(false);
              setScListLoader(false);
            }
          })();
        }
      }, [500]);
    }
  }, [store.accessZoneList]);

  useEffect(() => {
    if (store.categoryList !== undefined && store.categoryList !== null) {
      setCategoryListLoader(false);
      if (Allowed) {
        let Arr = [];
        Arr.push({ category_id: 0, category_name: "All" });

        Arr = [...Arr, store.categoryList];
        let FinalArr = Arr.flat();

        setObj({
          ...obj,
          categoryList: FinalArr,
          category: FinalArr[0],
        });
      } else {
        let Arr = [];
        Arr.push({ category_id: 0, category_name: "All" });
        Arr = [...Arr, store.categoryList];
        let FinalArr = Arr.flat();

        setObj({
          ...obj,
          categoryList: FinalArr,
          category: FinalArr[0] ?? { category_id: 0, category_name: "" },
        });
      }
    }
  }, [store.categoryList]);

  // useEffect(() => {
  //   if (
  //     store.superCategoryList !== undefined &&
  //     store.superCategoryList !== null
  //   ) {
  //     if (Allowed) {
  //       let Arr = [];
  //       Arr.push({ sc_id: 0, name: "All" });

  //       Arr = [...Arr, store.superCategoryList];
  //       let FinalArr = Arr.flat();

  //       setSuperCategory({
  //         ...superCategory,
  //         List: FinalArr,
  //         sc: FinalArr[0],
  //       });
  //     } else {
  //       let Arr = [];
  //       Arr = [...Arr, store.superCategoryList];
  //       let FinalArr = Arr.flat();

  //       setSuperCategory({
  //         ...superCategory,
  //         List: FinalArr,
  //         sc: FinalArr[0] ?? { sc_id: 0, sc_name: "" },
  //       });
  //     }
  //   }
  // }, [store.superCategoryList]);

  useEffect(() => {
    if (
      store.accessSuperCategoryList !== undefined &&
      store.accessSuperCategoryList !== null
    ) {
      if (Allowed) {
        let Arr = [];
        Arr.push({ sc_id: 0, name: "All" });

        Arr = [...Arr, store.accessSuperCategoryList];

        console.log(store.accessSuperCategoryList, "accessSuperCategoryList");
        let FinalArr = Arr.flat();

        setSuperCategory({
          ...superCategory,
          List: FinalArr,
          sc: FinalArr[0],
        });
      } else {
        let Arr = [];
        Arr = [...Arr, store.accessSuperCategoryList];
        let FinalArr = Arr.flat();

        setSuperCategory({
          ...superCategory,
          List: FinalArr,
          sc: FinalArr[0] ?? { sc_id: 0, sc_name: "" },
        });
      }
    }
  }, [store.accessSuperCategoryList]);

  // useEffect(() => {
  //   let paramTwo = {
  //     zone_name: zone?.zone?.zone_name ?? "",
  //     category_id: obj?.category?.category_id ?? 0,
  //     state_id: state?.state?.state_id ?? 0,
  //     sc_id: superCategory?.sc?.sc_id ?? 0,
  //   };

  //   dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
  // }, [state, zone, obj]);

  useEffect(() => {
    let paramTwo = {
      // Echno: Debugging flickering
      // zone_name: storedRetainZone?.zone_name ?? "",
      // category_id: storedRetainCategory?.category_id ?? 0,
      // state_id: storedRetainState?.state_id ?? 0,
      // sc_id: storedRetainSuperCategory?.sc_id ?? 0,

      zone_name: storedRetainZone?.zone_name ?? "",
      category_id: storedRetainCategory?.category_id ?? 0,
      state_id: storedRetainState?.state_id ?? 0,
      sc_id: storedRetainSuperCategory?.sc_id ?? 0,
    };

    console.log(paramTwo, "Target Name:");

    dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
  }, [
    storedRetainZone,
    storedRetainState,
    storedRetainCategory,
    storedRetainSuperCategory,
    storedRetainDistrict,
  ]);

  // useEffect(() => {
  //   let paramTwo = {
  //     zone_name: storedRetainZone?.zone_name,
  //     category_id: storedRetainCategory?.category_id,
  //     state_id: storedRetainState?.state_id,
  //     sc_id: storedRetainSuperCategory?.sc_id,
  //   };

  //   console.log(paramTwo, "Target Name:");

  //   dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
  // }, []);

  console.log(storedRetainState, "74587");

  const handleState = (e, value) => {
    localStorage.setItem("selectedStateLocal", JSON.stringify(value));
    const storedState = JSON.parse(localStorage.getItem("selectedStateLocal"));
    setStoredRetainState(storedState);
    localStorage.removeItem("selectedDistrictLocal");
    localStorage.removeItem("selectedZoneLocal");
    if (storedState?.state_name !== value) {
      setStoredRetainDistrict((prevState) => ({
        ...prevState,
        district_name: "",
      }));
      setStoredRetainZone((prevState) => ({
        ...prevState,
        zone_name: "",
      }));
      let param = {
        state_id: storedState?.state_id,
      };
      setDistrictListLoader(true);
      dispatch(ExceptionAccessDistrictList(Axios, param));
    }
    if (!CheckSmallError(value) && value !== "") {
      setState({
        ...state,
        state: value,
      });
      let param = {
        state_id: value?.state_id,
      };
      setDistrictListLoader(true);
      dispatch(ExceptionAccessDistrictList(Axios, param));

      // let paramTwo = {
      //   zone_name: zone?.zone?.zone_name ?? "",
      //   category_id: obj?.category?.category_id ?? 0,
      //   state_id: state?.state?.state_id ?? 0,
      //   sc_id: superCategory?.sc?.sc_id ?? 0,
      // };

      let paramTwo = {
        zone_name: storedRetainZone?.zone_name,
        category_id: storedRetainCategory?.category_id,
        state_id: storedRetainState?.state_id,
        sc_id: storedRetainSuperCategory?.sc_id,
      };

      dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
    }
  };

  useEffect(() => {
    dispatch(GetAccessSuperCategoryList(Axios));
  }, []);

  const handleZone = async (e, value) => {
    localStorage.setItem("selectedZoneLocal", JSON.stringify(value));
    const storedZone = JSON.parse(localStorage.getItem("selectedZoneLocal"));
    setStoredRetainZone(storedZone);
    if (!CheckSmallError(value) && value !== "") {
      setZone({
        ...zone,
        zone: value,
      });

      console.log(value, "handleZone");

      // let param = {
      //   zone_name: value?.zone_name,
      //   category_id: obj?.category?.category_id,
      //   state_id: state.state?.state_id,
      //   sc_id: superCategory?.sc?.sc_id,
      // };

      let param = {
        zone_name: storedRetainZone?.zone_name,
        category_id: storedRetainCategory?.category_id,
        state_id: storedRetainState?.state_id,
        sc_id: storedRetainSuperCategory?.sc_id,
      };

      dispatch(GetSkuExceptionListByZoneNew(Axios, param));
      // setScListLoader(false)
      (async () => {
        setScListLoader(true);
        let param = {
          zone_id: value?.zone_id ?? { zone_id: 0, zone_name: "" },
        };
        const data = await dispatch(GetAccessSuperCategoryList(Axios, param));
        if (data.code === 200) {
          dispatch({
            type: GETEXCEPTIONSUPERCATEGORYLIST,
            payload: data.body,
          });
          if (
            data.body !== undefined &&
            data.body !== null &&
            data.body?.length !== 0
          ) {
            let Arr = data.body;
            let param = {
              sc_id: Arr[0].sc_id,
            };
            dispatch(GetCategoryListForException(Axios, param));

            setScListLoader(false);
          }
        } else {
          console.log("failed to fetch sc list");
          setScListLoader(false);
        }
      })();
    }
  };

  const handleCategory = (e, value) => {
    localStorage.setItem("selectedCategoryLocal", JSON.stringify(value));
    const storedCategory = JSON.parse(
      localStorage.getItem("selectedCategoryLocal")
    );
    setStoredRetainCategory(storedCategory);
    if (!CheckSmallError(value) && value !== "") {
      setObj({
        ...obj,
        category: value,
      });

      // let param = {
      //   zone_name: zone?.zone?.zone_name,
      //   category_id: value?.category_id,
      //   state_id: state?.state?.state_id,
      //   sc_id: superCategory?.sc?.sc_id,
      // };

      let param = {
        zone_name: storedRetainZone?.zone_name,
        category_id: storedRetainCategory?.category_id,
        state_id: storedRetainState?.state_id,
        sc_id: storedRetainSuperCategory?.sc_id,
      };

      dispatch(GetSkuExceptionListByZoneNew(Axios, param));
    }
  };

  const handleSuperCategory = (e, value) => {
    localStorage.setItem("selectedSuperCategoryLocal", JSON.stringify(value));
    const storedSuperCategory = JSON.parse(
      localStorage.getItem("selectedSuperCategoryLocal")
    );
    setStoredRetainSuperCategory(storedSuperCategory);
    if (!CheckSmallError(value) && value !== "") {
      console.log(value);
      const { sc_id, name } = value;

      if (Allowed) {
        setObj({
          ...obj,
          category: {
            category_id: 0,
            category_name: "All",
          },
        });
      }

      setSuperCategory({
        ...superCategory,
        sc: {
          sc_id,
          name,
        },
      });
      let param = {
        sc_id: sc_id,
      };
      setCategoryListLoader(true);
      dispatch(GetCategoryListForException(Axios, param));
      // let paramTwi = {
      //   zone_name: zone?.zone?.zone_name,
      //   category_id: value?.category_id,
      //   state_id: state.state?.state_id,
      //   sc_id: sc_id,
      // };
      let paramTwo = {
        zone_name: storedRetainZone?.zone_name,
        category_id: storedRetainCategory?.category_id,
        state_id: storedRetainState?.state_id,
        sc_id: storedRetainSuperCategory?.sc_id,
      };

      dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
    }
  };

  const handleCallback = (text) => {
    setText(text);
  };

  const handleClearState = () => {
    setSelectedState(null);
  };

  const handleDistrict = (e, value) => {
    localStorage.setItem("selectedDistrictLocal", JSON.stringify(value));
    const storedDistrict = JSON.parse(
      localStorage.getItem("selectedDistrictLocal")
    );
    setStoredRetainDistrict(storedDistrict);
    if (storedDistrict?.district_name !== value) {
      // setStoredRetainZone(prevState => ({
      //   ...prevState,
      //   [zone_name]: "All",
      // }));
      // setStoredRetainZone({})
      let param = {
        district_id: value?.district_id,
      };
      setZoneListLoader(true);
      dispatch(ExceptionAccessZoneList(Axios, param));
    }
    console.log(value, "StoreException");
    if (!CheckSmallError(value) && value !== "") {
      setDistrict({
        ...district,
        district: value,
      });
      let param = {
        district_id: value?.district_id,
      };
      setZoneListLoader(true);
      dispatch(ExceptionAccessZoneList(Axios, param));

      // let paramTwo = {
      //   zone_name: zone?.zone?.zone_name ?? "",
      //   category_id: obj?.category?.category_id ?? 0,
      //   state_id: state?.state?.state_id ?? 0,
      //   sc_id: superCategory?.sc?.sc_id ?? 0,
      // };

      let paramTwo = {
        zone_name: storedRetainZone?.zone_name,
        category_id: storedRetainCategory?.category_id,
        state_id: storedRetainState?.state_id,
        sc_id: storedRetainSuperCategory?.sc_id,
      };

      dispatch(GetSkuExceptionListByZoneNew(Axios, paramTwo));
    }
  };

  console.log(zone);
  return (
    <div className="RootSkuExceptionNewSku">
      <CardWrapper>
        <Stack gap={2}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={1.5}
            flexWrap="wrap"
          >
            <Autocomplete
              loading={stateListLoader}
              className={Styles.AutoComplete}
              // value={!stateListLoader ? state?.state : ""}
              value={storedRetainState}
              options={!stateListLoader ? state?.accessStateList : [""] ?? [""]}
              getOptionLabel={(option) => option?.state_name ?? [""]}
              size="small"
              onChange={handleState}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    backgroundColor: "#25293C !important",
                    minWidth: { xs: "100%", md: "220px", lg: "25%" },
                    // marginLeft:{xs:"0rem",sm:"0rem",md:"0rem",lg:"2rem",xl:"0rem"},
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  variant="outlined"
                  label="State"
                  placeholder="Select State.."
                />
              )}
            />
            <Autocomplete
              loading={districtListLoader}
              className={Styles.AutoComplete}
              // value={!districtListLoader ? district?.district : ""}
              value={storedRetainDistrict}
              options={
                !districtListLoader
                  ? district?.accessDistrictList
                  : [""] ?? [""]
              }
              getOptionLabel={(option) => option?.district_name}
              size="small"
              onChange={handleDistrict}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    backgroundColor: "#25293C !important",
                    minWidth: { xs: "100%", md: "220px", lg: "25%" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  variant="outlined"
                  label="District"
                  placeholder="Select District.."
                />
              )}
            />
            <Autocomplete
              loading={zoneListLoader}
              className={Styles.AutoComplete}
              // value={zoneListLoader ? "" : zone.zone}
              value={storedRetainZone}
              options={zoneListLoader ? [""] : zone.accessZoneList ?? [""]}
              getOptionLabel={(option) => option.zone_name}
              size="small"
              onChange={handleZone}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    backgroundColor: "#25293C !important",
                    minWidth: { xs: "100%", md: "220px", lg: "25%" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {zoneListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  variant="outlined"
                  label="Zone"
                  placeholder="Select Zone.."
                />
              )}
            />
            <Autocomplete
              loading={scListLoader}
              className={Styles.AutoComplete}
              // value={superCategory.sc}
              value={storedRetainSuperCategory}
              options={superCategory.List}
              getOptionLabel={(option) => option.name}
              size="small"
              onChange={handleSuperCategory}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    backgroundColor: "#25293C !important",
                    minWidth: { xs: "100%", md: "220px", lg: "25%" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {scListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  variant="outlined"
                  label="Super Category"
                  placeholder="Select Super Category.."
                />
              )}
            />
            <Autocomplete
              loading={categoryListLoader}
              className={Styles.AutoComplete}
              // value={obj.category}
              value={storedRetainCategory}
              options={obj.categoryList}
              getOptionLabel={(option) => option.category_name}
              size="small"
              onChange={handleCategory}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    backgroundColor: "#25293C !important",
                    minWidth: { xs: "100%", md: "220px", lg: "25%" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {categoryListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  variant="outlined"
                  label="Category"
                  placeholder="Select Category.."
                />
              )}
            />
            
              <SearchBarComponent
                Placeholder="Search Sku"
                TextCallback={handleCallback}
              />
          </Stack>

          <Stack direction={{ xs: "column", lg: "column" }} gap={2}>
            <SkuByZoneTable text={text} />
            <Stack sx={{ overflow: "auto" }} flex={1}>
              <SkuByZoneItem obj={obj} />
            </Stack>
          </Stack>
        </Stack>
      </CardWrapper>
    </div>
  );
};

export default AccessSkuByZone;

let State = ["Maharastra"];
let Zone = ["Mumbai"];
