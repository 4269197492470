import {
  Autocomplete,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IOSSwitch } from "../../../utility/IosSwitch";

import { styled } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";

import { Search as SearchIcon } from "@mui/icons-material";
import RunTheDealsAdd from "./RunTheDealsAdd";
import RunTheDealsAutocomplete from "./RunTheDealsAutocomplete";
import RunTheDeailsTable from "./RunTheDeailsTable";
import RunTheDeailsDetails from "./RunTheDeailsDetails";
import { Navigate, useNavigate } from "react-router";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#232738",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#3B405E",

  // hide last border
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const handleDetails = () => {
  var elmntToView = document.getElementById("RunTheDeailsDetails");
  elmntToView.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "end",
  });
};

const rows = [
  createData(
    "Rajasthani Steel",
    "Steel",
    "Construction Material",
    "₹0.8",
    "₹5500",
    "03/02/23 ; 6:38 PM",
    "₹5500"
  ),
  createData("Rajasthani Steel", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const RunTheDeals = () => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
    console.log("edit Clicked", edit);
  };
  return (
    <div style={{ color: "white" }}>
      <RunTheDealsAdd />
      <Stack sx={{ marginTop: "3rem" }}>
        <Typography>RUN CUT OFF PRICES</Typography>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#3B405E",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <RunTheDealsAutocomplete />
        <Stack
          sx={{
            backgroundColor: "#3B405E",
            padding: "1rem",
            boxShadow: "none",
          }}
        >
          <RunTheDeailsTable
            handleEdit={handleEdit}
            edit={edit}
            handleDetails={handleDetails}
          />
        </Stack>
      </Stack>
      <Stack
        boxShadow={"rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"}
        sx={{
          padding: "1rem",
          backgroundColor: "#3B405E",
          borderRadius: "5px",
        }}
      >
        {edit ? (
          <Stack
            sx={{
              color: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
            gap={5}
            direction={{ xl: "row", md: "column" }}
          >
            <SubmitButton handleEdit={handleEdit} />
          </Stack>
        ) : (
          <Stack
            sx={{
              color: "white",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            gap={5}
            direction={{ xl: "row", md: "column" }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              sx={{
                backgroundColor: "#4B5177",
                borderRadius: "10px",
              }}
              padding={{ xs: "5px", md: "5px 2rem" }}
              gap={2}
            >
              <Typography>View</Typography>

              <FormControl sx={{ marginTop: "7px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="All SKUs"
                    control={
                      <Radio
                        color="default"
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    label="All SKUs"
                  />
                  <FormControlLabel
                    value="Selected SKUs"
                    control={
                      <Radio
                        color="default"
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    label="Selected SKUs"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack direction={"row"} sx={{ alignItems: "center" }} gap={2}>
              <Stack>
                <Typography> Karnataka | Bangalore</Typography>
              </Stack>
              <Stack>
                <Button
                  sx={{
                    width: "7rem",
                    color: "white",
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                  onClick={() => navigate("/home/runthedeals/details")}
                >
                  RUN
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      <RunTheDeailsDetails />
    </div>
  );
};

export default RunTheDeals;

const SubmitButton = ({ handleEdit }) => {
  return (
    <Button
      sx={{
        width: "7rem",
        color: "white",
        backgroundColor: "Red",
        "&:hover": {
          backgroundColor: "Red",
        },
      }}
      onClick={handleEdit}
    >
      Submit
    </Button>
  );
};
