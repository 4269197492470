import { combineReducers } from "redux";
import login from "./login/loginReducer";
import zone from "./zone/zoneReducer";
import company from "./Company/companyReducer";
import category from "./Category/categoryReducer";
import product from "./Product/productReducer";
import sku from "./Sku/skuReducer";
import access from "./UserAccessZone/UserAccessZoneActionReducer";
import newSku from "./NewSku/NewSkuReducer";
import exception from "./Exception/SkuExceptionReducer";
import kyc from "./kyc/kycReducer";
import dashboard from "./Dashboard/dashboard.reducer";
import ticket from "./Ticket/ticketReducer";
import superCategory from "./SuperCategory/SuperCategoryReducer";
import imageBank from "./ImageBank/ImageBankReducer";
import transationmgt from "./Transation/TransationMgtReduser";
import launchMgt from "./Launch/LaunchReducer";
import RunTheDealsReducer from "./RunTheDeals/RunTheDealsReducer";
import loginLamdaReducer from "./LoginLamdaReducer/loginLamdaReducer";
import mapReducer from "./Map/MapReducer";
import subCategoryReducer from "./SubCategory/SubCategory.Reducer";
import YourDealsReducer from "./YourDeals/YourDealsReducer";
import holidayCalenderReducer from "./HolidayCalender/holidayCalenderReducer";
import VideoAnalyticsReducer from "./VideoAnalytics/VideoAnalyticsReducer";
import WalletManagementReducer from "./WalletManagement/WalletManagementReducer";
import PartnerWalletManagementReducer from "./PartnerWalletManagement/PartnerWalletManagementReducer";

export const reducer = combineReducers({
  Login: login,
  Zone: zone,
  Company: company,
  Category: category,
  Product: product,
  Sku: sku,
  Access: access,
  NewSku: newSku,
  Exception: exception,
  Kyc: kyc,
  Dashboard: dashboard,
  Ticket: ticket,
  SuperCategory: superCategory,
  TransationMgt: transationmgt,
  ImageBank: imageBank,
  Launch: launchMgt,
  RunTheDealsReducer: RunTheDealsReducer,
  LoginLambdaReducer: loginLamdaReducer,
  MapReducer: mapReducer,
  SubCategoryReducer: subCategoryReducer,
  YourDealsReducer: YourDealsReducer,
  HolidayCalenderReducer: holidayCalenderReducer,
  videoAnalyticsReducer: VideoAnalyticsReducer,
  WalletManagementReducer: WalletManagementReducer,
  PartnerWalletManagementReducer: PartnerWalletManagementReducer,
});
