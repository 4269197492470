import { GETALLDISTRICTTICKET, GETALLSTATETICKET, GETALLZONETICKET, GETSTATEZONETICKETLISTCONSTANT, GETTICKETDETAIL, GETZONETICKETS, UPDATETICKETREDUX } from "../../utility/ReduxConstants/TicketReducerConstant"

const initialState = {
    stateZoneTicketList: [],
    stateList: [],
    zoneList: [],
    districtList: [],
    total_open: "",
    total_resolved: "",
    total_tickets: "",
    tickets: [],
    ticket:{}
}


export default (state = initialState, actions) => {

    switch (actions.type) {
        case GETSTATEZONETICKETLISTCONSTANT:
            return {
                ...state,
                stateZoneTicketList: actions.payload
            }
        case GETALLSTATETICKET:
            return {
                ...state,
                stateList: actions.payload
            }
            case GETALLDISTRICTTICKET:
                return {
                    ...state,
                    districtList: actions.payload
                }
        case GETALLZONETICKET:
            return {
                ...state,
                zoneList: actions.payload
            }

        case GETZONETICKETS:
            return {
                ...state,
                total_open: actions.payload.total_open,
                total_resolved: actions.payload.total_resolved,
                total_tickets: actions.payload.total_tickets,
                tickets: actions.payload.tickets,
                ticket : actions.payload.tickets && actions.payload.tickets?.length!== 0? actions.payload.tickets[0] : {}
            }
        case GETTICKETDETAIL:
            return {
                ...state,
                ticket: actions.payload
            }

        case UPDATETICKETREDUX : 
        return {
            ...state,
            tickets : state.tickets.map(item=>item.t_id === actions.payload.t_id ? actions.payload : item),
            ticket : actions.payload
        }

        default:
            return state
    }
}