import { getSuperCategoryAccessTransMgt } from "../../actions/TransationMgt/transMgtAction";
import {
  GETWALLETTRANSACTIONDETAILS,
  GETSTATELISTTRANSMGT,
  GETZONELISTTRANSMGT,
  GETSUPERCATEGORYTRANSMGT,
  GETHOLDBALANCE,
  GETACCESSSUPERCATEGORYTRANSMGT,
  GETSTATELISTACCESSTRANSMGT,
  GETZONELISTACCESSTRANSMGT,
  GETDISTRICTLISTTRANSMGT,
  GETDISTRICTLISTACCESSTRANSMGT,
} from "../../utility/ReduxConstants/TransationMgtReducerConstant";

const initialState = {
  wallet_trans_list: [],
  state_list: [],
  zone_list: [],
  district_list:[],
  sc_list: [],
  available_balance: 0,
  hold_balance: 0,
  hold_trans_balance: 0,
  access_sc_list:[],
  access_state_list: [],
  access_zone_list:[],
  access_district_list:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETWALLETTRANSACTIONDETAILS:
      return {
        ...state,
        wallet_trans_list:
          action.payload.body !== undefined &&
          action.payload.body !== null &&
          action.payload.body.length !== 0
            ? action.payload.body
            : [],
        available_balance: action.payload.available_balance ?? 0,
        hold_balance: action.payload.hold_balance ?? 0,
      };

    case GETSTATELISTTRANSMGT:
      return {
        ...state,
        state_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETZONELISTTRANSMGT:
      return {
        ...state,
        zone_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case GETDISTRICTLISTTRANSMGT:
      return {
        ...state,
        district_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSUPERCATEGORYTRANSMGT:
      return {
        ...state,
        sc_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case GETACCESSSUPERCATEGORYTRANSMGT:
      return {
        ...state,
        access_sc_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case GETSTATELISTACCESSTRANSMGT:
      return {
        ...state,
        access_state_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case GETZONELISTACCESSTRANSMGT:
      return {
        ...state,
        access_zone_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case GETDISTRICTLISTACCESSTRANSMGT:
      return {
        ...state,
        access_district_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

     

    case GETHOLDBALANCE:
      return {
        ...state,
        wallet_trans_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload.body
            : [],
        hold_trans_balance: action.payload?.result?.hold_balance ?? 0,
      };
    default:
      return state;
  }
};
