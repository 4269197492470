import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCellWallet } from "../../../../utility/TextField";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../../UserAccess/styles/UserAccessTable.module.css";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import {
  GETEDITEDVALUE,
  GETHOLIDAYID,
} from "../../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import ConfirmationDialog from "./ConfirmationDialog";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";
import WalletSendCoins from ".././WalletSendCoins";
import WalletData from "../../../../data.json";
import { Link } from "react-router-dom";
import { GETSENDCOINPOPUPSTATUS } from "../../../../utility/ReduxConstants/WalletManagementReducerConstant";
import {
  PartnerWalletApprovalList,
  PartnerWalletApproveConfirm,
  PartnerWalletDeclineConfirm,
} from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";
import MinimizeIcon from "@mui/icons-material/Minimize";

const PartnerPendingTable = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const popUpStatus = useSelector(
    (store) => store.WalletManagementReducer.sendCoinPopupStatus
  );
  const walletApprovalList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletApprovalList
  );
  const walletAllDropdownList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllDropdownList
  );
  console.log(walletApprovalList, "partnerWallet1000");

  // useEffect(() => {
  //   let param = {
  //     zone_name: "Bengaluru",
  //     sc_id: 45,
  //     request_status: "pending",
  //   };
  //   dispatch(PartnerWalletApprovalList(Axios, param));
  // }, []);

  const handleApprove = async (ucr_id) => {
    console.log(ucr_id, "ucr_id");

    try {
      let param1 = { ucr_id: ucr_id };
      const data = await dispatch(PartnerWalletApproveConfirm(Axios, param1));
      console.log(data, "ucr_id");

      if (data.code === 200) {
        toast.success("SuccessFully Approved!!");
      } else {
        toast.error("Failed to Approve!!!");
      }
      let param = {
        zone_name: walletAllDropdownList?.zone_name,
        sc_id: walletAllDropdownList?.sc_id,
        request_status: walletAllDropdownList?.status,
      };
      console.log(param, "walletManagement");
      dispatch(PartnerWalletApprovalList(Axios, param));
    } catch (error) {
      console.error("Error while Approving:", error);
    }
  };
  const handleDecline = async (ucr_id) => {
    console.log(ucr_id, "ucr_id");

    try {
      let param1 = { ucr_id: ucr_id };
      const data = await dispatch(PartnerWalletDeclineConfirm(Axios, param1));
      console.log(data, "ucr_id");

      if (data.code === 200) {
        toast.success("SuccessFully Delined!!");
      } else {
        toast.error("Failed to Decline!!!");
      }
      let param = {
        zone_name: walletAllDropdownList?.zone_name,
        sc_id: walletAllDropdownList?.sc_id,
        request_status: walletAllDropdownList?.status,
      };
      console.log(param, "walletManagement");
      dispatch(PartnerWalletApprovalList(Axios, param));
    } catch (error) {
      console.error("Error while Approving:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCellWallet align="center" size="small">
                  S.No
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  User Details
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Zone
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Request Amount
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Date & Time
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Action
                </StyledTableCellWallet>
              </TableRow>
            </TableHead>

            <TableBody>
              {walletApprovalList ? (
                walletApprovalList &&
                walletApprovalList?.map((item, index) => {
                  const eventDate = new Date(item.requested_at);
                  const dayName = eventDate.toLocaleDateString("en-US", {
                    weekday: "long",
                  });
                  const dateObject = new Date(item.requested_at);

                  // Extract year, month, and day
                  const year = dateObject.getFullYear();
                  const month = dateObject.getMonth() + 1; // Note: Month is zero-indexed, so we add 1
                  const day = dateObject.getDate();

                  // Create a new string in the format 'YYYY-MM-DD'
                  const formattedYearDate = `${year}-${
                    month < 10 ? "0" : ""
                  }${month}-${day < 10 ? "0" : ""}${day}`;

                  // Create a new string in the format '1 Jan'
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const formattedDate = `${day} ${monthNames[month - 1]} ${year}`;
                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      ucr_id={item.ucr_id}
                      handleApprove={handleApprove}
                      handleDecline={handleDecline}
                      request_status={item.request_status}
                      formattedDate={formattedDate}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ color: "white" }}>No data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {popUpStatus && (
          <Stack
            sx={{
              top: "30%",
              left: "45%",
              // transform: "translate(-50%,-50%)",
              position: "absolute",
              position: "fixed",
              zIndex: 10,
            }}
          >
            <WalletSendCoins />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default PartnerPendingTable;

const Row = ({
  Access,
  ucr_id,
  handleApprove,
  handleDecline,
  request_status,
  formattedDate,
  index,
  holidayId,
  stateId,
  handleDelete,
  handleEdit,
  handleConfirmRemove,
  dayName,
  currentDate,
  currentHolidayName,
}) => {
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const [editIconStatus, setEditIconStatus] = useState(true);
  console.log(editIconStatus);
  const [editedEvent, setEditedEvent] = useState(Access.holiday_name);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  console.log(ucr_id, "walletManagement");
  const [approveStatus, setApproveStatus] = useState(false);
  const [type, setType] = useState(0);
  const walletApprovalList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletApprovalList
  );

  const handleApproveClick = () => {
    setIsConfirmDialogOpen(true);
    setType(1);
  };
  const handleDeclineClick = () => {
    setIsConfirmDialogOpen(true);
    setType(2);
  };
  const handleConfirmApproveClick = () => {
    handleApprove(ucr_id);
    setIsConfirmDialogOpen(false);
  };
  const handleConfirmDeclineClick = () => {
    handleDecline(ucr_id);
    setIsConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "editedValue");
  const [changedDate, setChangedDate] = useState(null);

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYID,
      payload: { holiday_id: holidayId, state_id: Access.state_id },
    });
  }, []);

  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmRemoveClick = () => {
    handleConfirmRemove(holidayId, stateId);
    setIsConfirmDialogOpen(false);
  };
  const handleUpdateClick = () => {
    setEditIconStatus(false);
  };

  const handleSave = () => {
    setEditIconStatus(true);

    handleEdit(
      holidayId,
      stateId,
      currentDate,
      currentHolidayName,
      formattedDate
    );
  };

  const [inputValue, setInputValue] = useState(
    editedEvent || currentHolidayName
  );

  const handleEditEvent = (e, value) => {
    console.log(e.target.value);
    setEditedEvent(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: changedDate, edited_event: editedEvent },
    });
  }, [changedDate, editedEvent]);

  const Edit = () => {
    // setOpen(true);

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleStartDateChange = (newValue) => {
    const originalDate = new Date(newValue);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const date = originalDate.getDate();

    // Create a formatted date string (YYYY-MM-DD)
    const formattedDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;
    setChangedDate(formattedDateString);

    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: formattedDateString, edited_event: editedEvent },
    });
  };

  const popUpStatus = useSelector(
    (store) => store.WalletManagementReducer.sendCoinPopupStatus
  );

  console.log(popUpStatus);

  const handleSendCoins = () => {
    dispatch({
      type: GETSENDCOINPOPUPSTATUS,
      payload: true,
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {index}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"0.5rem"}}>
            {Access?.full_name}
            <Typography sx={{color:"#A4A4A4",fontWeight:300,fontSize:"0.8rem"}}>{Access?.mobile_no}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#FFFFF7" }}>
          {Access?.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#FFFFF7" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.4rem",
            }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={GoldLogo} />
                </Stack>
                <Typography sx={{ fontSize: "0.9rem", color: "#FFFFFF" }}>
                  {Access?.gold_coin}
                </Typography>
              </Stack>
              {Access.silver_coin_free && (
                <Stack
                  sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
                >
                  {" "}
                  <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                    <img src={SilverLogo} />
                  </Stack>
                  <Typography sx={{ fontSize: "0.9rem", fontWeight: 100 }}>
                    {Access?.silver_coin}(Free)
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={SilverLogo} />
                </Stack>
                {Access?.silver_coin}(GST)
              </Stack>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              gap: "1.8rem",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack>{formattedDate}</Stack>
          </Stack>
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {request_status === "pending" ? (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "#DC1D13",
                  padding: "0.5rem",
                  color: "#FFFFFF",
                  border: "0.1rem solid #DC1D13",
                  cursor: "pointer",
                }}
                onClick={handleApproveClick}
              >
                Approve
              </button>

              <button
                style={{
                  background: "#262934",
                  padding: "0.5rem",
                  width: "5rem",
                  color: "#DC1D13",
                  border: "0.1rem solid #DC1D13",
                  cursor: "pointer",
                }}
                onClick={handleDeclineClick}
              >
                Decline
              </button>
            </Stack>
          ) : (
            <MinimizeIcon />
          )}
        </TableCell>
        {type === 1 ? (
          <ConfirmationDialog
            open={isConfirmDialogOpen}
            onClose={handleConfirmDialogClose}
            onConfirm={handleConfirmApproveClick}
            ucr_id={ucr_id}
            type={type}
          />
        ) : (
          <ConfirmationDialog
            open={isConfirmDialogOpen}
            onClose={handleConfirmDialogClose}
            onConfirm={handleConfirmDeclineClick}
            ucr_id={ucr_id}
            type={type}
          />
        )}
      </TableRow>
    </>
  );
};
