import { createContext, useContext, useState } from "react";

export const LaunchContext = createContext()


export const LaunchContextFn=({children})=>{
     const [showUserTable,setShowUserTable] = useState(false);
     const [showKyc,setShowKyc] = useState(false);

     const handleShowKyc=(val)=>{
        setShowKyc(val)
     }

    const handleShowUserTable=(val)=>{
            setShowUserTable(val)
    }
     return (
        <LaunchContext.Provider value={{handleShowUserTable,showUserTable,handleShowKyc,showKyc}}>
            {children}
        </LaunchContext.Provider>
     )
}

export const LaunchUseContext = ()=>{
    return useContext(LaunchContext)
}