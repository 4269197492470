import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

// import { makeStyles } from "@mui/styles";
import { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link as RouterLink } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   textfield_input: {
//     color: `#000000 !important`,
//     backgroundColor: "white",
//   },
// }));

const VitranPartnerForm = () => {
  // const classes = useStyles();
  const url = `https://3zvs1zjf1d.execute-api.ap-south-1.amazonaws.com/vitaran-live/live-contact-us`;
  const token = `G8r37$BloY61B@Du09LOw#cnxy45Q`;
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobile: "",
    state: "",
    district: "",
    find_from: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  console.log(formData, "formData");
  const handleChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleSelectChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      find_from: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Access the form data stored in the `data` state
    console.log(data, "SubmitData");
    if (!validateForm()) {
      return;
    }
    setLoading(true); // Set loading state to true

    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response.data, "SubmitDataform");
        setFormData(response.data);

        if (response.data?.code === 200) {
          toast.success(response.data?.message);

          setData({
            name: "",
            mobile: "",
            state: "",
            district: "",
            status: false,
            find_from: "",
            message: "",
          });
        } else {
          toast.warning(response.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error, "error submitting");
        setLoading(false);
      });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!data.name || !/^[a-zA-Z\s]+$/.test(data.name)) {
      newErrors.name = "Please enter a valid name (only text characters).";
      isValid = false;
    }

    if (!data.mobile || !/^\d{10}$/.test(data.mobile)) {
      newErrors.mobile = "Please enter a valid mobile number (10 digits only).";
      isValid = false;
    }

    // if (!data.state || !/^[a-zA-Z\s]+$/.test(data.state)) {
    //   newErrors.state = "Please enter a valid state (only text characters).";
    //   isValid = false;
    // }

    // if (!data.district || !/^[a-zA-Z\s]+$/.test(data.district)) {
    //   newErrors.district =
    //     "Please enter a valid district (only text characters).";
    //   isValid = false;
    // }

    if (!data.message || !/^[\s\S]{0,2000}$/.test(data.message)) {
      newErrors.message =
        "Please enter a valid message (up to 2000 characters).";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack sx={{ backgroundColor: "#EDEDF3" }}>
        <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
          <NavBar />
        </Stack>
        <Stack
          sx={{ display: { xs: "flex", lg: "none" } }}
          mt={{ xs: "2rem", lg: "0" }}
          ml={{ xs: "8px", lg: "0" }}
        >
          <RouterLink
            style={{ textDecoration: "none", color: "black" }}
            to={"/"}
          >
            <KeyboardArrowLeftIcon />
          </RouterLink>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack
            // height={"80vh"}
            width={{ xs: "90%", lg: "80%" }}
            m={"auto"}
            mt={{ xs: "2rem", lg: "8rem" }}
          >
            <Stack>
              <Typography
                fontWeight={"bold"}
                fontSize={{ xs: "20px", lg: "40px" }}
                color={"#424063"}
              >
                Vitaran Partner Application Form
              </Typography>
            </Stack>
            <Stack>
              <Typography color={"#313131"}>
                Fill out the form below to become a Certified Vitaran Partner,
                and we shall contact you soon!
              </Typography>
            </Stack>
            <Stack m={{ xs: "auto", lg: "0" }}>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                gap={5}
                mt={"5rem"}
              >
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">Your Name*</InputLabel>
                  <FormControl error={!!errors.name} fullWidth>
                    <TextField
                      // inputProps={{ className: classes.textfield_input }}
                      type="text"
                      variant="outlined"
                      value={data.name}
                      name="name"
                      onChange={handleChange}
                      // sx={{ backgroundColor: "white" }}
                      sx={{
                        // input: {
                        //   background:
                        //     "linear-gradient(97.87deg, #000000 , #000000 93.4%)",
                        //   _webkitBackgroundClip: "text",
                        //   _webkitTextFillColor: "transparent",
                        //   backgroundClip: "text",
                        //   textFillColor: "transparent",
                        // },
                        // "$ .MuiInput-root": {
                        //   color: "black !important",
                        // },

                        // input: { color: "red" },
                        // backgroundColor: "white",
                        // "& .MuiOutlinedInput-input": {
                        //   color: "black",
                        // },
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.name && (
                      <FormHelperText error>{errors.name}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">Mobile No.*</InputLabel>
                  <FormControl error={!!errors.mobile} fullWidth>
                    <TextField
                      type="number"
                      variant="outlined"
                      value={data.mobile}
                      name="mobile"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.mobile && (
                      <FormHelperText error>{errors.mobile}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                sx={{
                  borderRadius: "5px",
                }}
                gap={5}
                mt={"2rem"}
              >
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">State</InputLabel>
                  <FormControl error={!!errors.state} fullWidth>
                    <TextField
                      type="text"
                      variant="outlined"
                      value={data.state}
                      name="state"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.state && (
                      <FormHelperText error>{errors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">District</InputLabel>
                  <FormControl error={!!errors.district} fullWidth>
                    <TextField
                      variant="outlined"
                      value={data.district}
                      name="district"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.district && (
                      <FormHelperText error>{errors.district}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>

              <Stack>
                <Stack
                  direction={"column"}
                  gap={1}
                  mt={{ xs: "1rem", lg: "1rem" }}
                >
                  <Stack>
                    <Typography>Where did you find out about us?</Typography>
                  </Stack>
                  <Stack width={{ xs: "20rem", lg: "25rem" }}>
                    <FormControl fullWidth>
                      <InputLabel
                        className="label-text"
                        id="demo-simple-select-label"
                      >
                        Select
                      </InputLabel>
                      <Select
                        variant="outlined"
                        value={data.find_from}
                        name="find_from"
                        onChange={handleSelectChange}
                        // Pass the name and value to handleChange
                        sx={{
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            color: "black !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                        }}
                        // style={{ color: "black" }}
                      >
                        <MenuItem value="Search Engine">Search Engine</MenuItem>
                        <MenuItem value="Social Media">Social Media</MenuItem>
                        <MenuItem value="Advertisement">Advertisement</MenuItem>
                        <MenuItem value="Word Of Mouth">Word Of Mouth</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel className="label-text">Select</InputLabel>
                <Select
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={age}
                  label="Age"
                  onChange={handleChangehandle}
                  sx={{ backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem
                    className="MenuItemSelect"
                    sx={{ color: "black" }}
                    value={10}
                  >
                    Ten
                  </MenuItem>
                  <MenuItem className="MenuItemSelect" value={20}>
                    Twenty
                  </MenuItem>
                  <MenuItem className="MenuItemSelect" value={30}>
                    Thirty
                  </MenuItem>
                </Select>
              </FormControl> */}
                  </Stack>
                </Stack>
              </Stack>
              <Stack mt={"1rem"}>
                <Stack width={{ xs: "20rem", lg: "25rem" }}>
                  <InputLabel className="label-text">Message*</InputLabel>
                  <FormControl error={!!errors.message} fullWidth>
                    <TextField
                      variant="outlined"
                      value={data.message}
                      multiline
                      rows={4}
                      name="message"
                      onChange={handleChange}
                      className="input-text"
                      sx={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.message && (
                      <FormHelperText error>{errors.message}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "#424063",
                  borderRadius: "6px",
                  color: "white",
                  padding: "5px 10px",
                }}
                width={"15rem"}
                mt={{ xs: "1rem", lg: "5rem" }}
                mb={{ xs: "1rem", lg: "5rem" }}
              >
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "#424063",
                    "&:hover": {
                      backgroundColor: "#424063", // Keep the same color on hover
                      boxShadow: "none", // Remove the box shadow on hover
                    },
                  }}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                  ) : (
                    "SUBMIT YOUR APPLICATION"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>

        <Stack>
          <Footer />
        </Stack>
      </Stack>
    </>
  );
};

export default VitranPartnerForm;
