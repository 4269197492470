import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router';
import UnAuthorized from '../components/UnAuthorized/UnAuthorized';

const UseRoleAuthentication = ({allowedRoles}) => {
    const store = useSelector(state=>state.Login);
    
    const location  = useLocation()
    const user = store?.user ?? {}
    console.log(user,"allowedRoles")

    // console.log(allowedRoles,"allowedRoles")
    
  return (

     store?.token ?
      (<>
        {
            allowedRoles.includes(user?.admin_role_code) ? <Outlet/> : <Navigate to="/unAuthorized" state={{ from: location }} replace />
        }
      </>) : <Outlet/>
  )
}

export default UseRoleAuthentication