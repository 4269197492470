import {
  GETCATEGORYDATA,
  GETCATEGORYDETAILS,
  UPDATECATEGORYDATAINLIST,
  REMOVECATEGORYDATAINLIST,
  GETSCDATA,
  SELECTEDSUPERCATEGORY
} from "../../utility/ReduxConstants/CategoryReducerConstant";

const initialState = {
  category_list: [],
  s_category_list:[],
  category_detail: {},
  selected_s_category:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETCATEGORYDATA:
      return {
        ...state,
        category_list:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
           

        // category_detail:
        //   action.payload.item_carousel !== undefined &&
        //   action.payload.item_carousel !== null &&
        //   action.payload.item_carousel.length !== 0 &&
        //   action.payload.first_item_details !== undefined &&
        //   action.payload.first_item_details !== null
        //     ? action.payload.first_item_details
        //     : {},
      };
      case SELECTEDSUPERCATEGORY:
        return{
          ...state,
          selected_s_category:  action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            :{}
        };

    case GETCATEGORYDETAILS:
      return {
        ...state,
        category_detail:
          action.payload !== undefined &&
          action.payload !== null
            ? action.payload
            : {}
      };
    case UPDATECATEGORYDATAINLIST:
      return {
        // ...state,
        // category_list: state.category_list.map((item) =>
        //   item.item_id === parseInt(action.payload.item_id)
        //     ? action.payload
        //     : item
        // ),
      };

    case REMOVECATEGORYDATAINLIST:
      return {
        // ...state,
        // category_list: state.category_list.filter(
        //   (item) => item.item_id !== parseInt(action.payload.item_id)
        // ),
      };

      case GETSCDATA:
        return {
          ...state,
          s_category_list:
            action.payload.body !== undefined &&
            action.payload.body !== null &&
            action.payload.body.length !== 0
              ? action.payload.body
              : []
        };

    default:
      return state;
  }
};
