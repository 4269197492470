import { Button, Card, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/AddSCategory.module.css";
import images from "../../../../utility/constants/images";
import { styled } from "@mui/material/styles";
import axios from "axios";
import {
  baseImgUploadUrl,
  baseImgPath,
  defaultImg,
} from "../../../../utility/BaseUrl";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddScCategoryData, GetSuperCategoryPageData } from "../../../../actions/SCategory/sCategoryAction";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import { AutocompleteTextField } from "../../../../utility/TextField";

const viewImgPath = `${baseImgPath}/category/`;

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const AddSCategory = () => {
  const [loading, setLoading] = useState(false)

  const Axios = useAxiosPrivate()
  const [error, setError] = useState({});
  const [img, setImg] = useState(defaultImg);
  const [file, setFile] = useState("");
  const [addsuccess, setAddsuccess] = useState(false)
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: ""
  });

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    name: "",
    image: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancel = () => {
    console.log("cancel is clicked!!");

    setForm({
      ...form,
      name: "",
      image: "",
    });
  };

  const IsValid = () => {
    let success = true;
    let err = {};
    const chars = form.name?.match(/[^a-zA-Z0-9' ,&-](?= |$)/g)

    if (!form.name.trim()) {
      err.scategoryname = "** Super category Name Cannot Be Empty**";
      success = false;
    } else if (chars && chars?.length!==0) {
      err.scategoryname =
        "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
      success = false;
    }


    if (!form.image.trim()) {
      err.categoryimage = "**Please Choose a Image**";
      success = false;
    }

    setError(err);
    return success;
  };


  React.useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    imgUpload(formData);
  }, [file]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (IsValid()) {
      let param = {
        // "type": "Category",
        name: `${form.name}`,
        description: "",
        image: `${viewImgPath}${form.image}`,
      };

      const Data = await dispatch(AddScCategoryData(Axios, param));
      if (Data.code === 200) {
        dispatch(GetSuperCategoryPageData(Axios));
        toast.success("Super category added successfully")
        
        setForm({
          ...form,
          "name": "",
          "image": ""
        })
        setImg(defaultImg)
      } else {
        toast.error(Data.message)
      }

    }
  };

  const imgUpload = async (formData) => {
    await axios
      .post(`${baseImgUploadUrl}image_upload_category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setLoading(false)
          toast.success("Uploaded SuccessFully");
          setImg(`${res.data.full_path}`);
          setForm({
            ...form,
            image: res.data.name,
          });
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const imgSelect = (event) => {
    setLoading(true)
    setFile(event.target.files[0]);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit}>

        <Stack direction="column" gap={3}>
          <Stack gap={.5}>
            <Card component={Paper} sx={{ borderRadius: "16px" }}>
              <Stack sx={{ height: "180px" }} direction="row" justifyContent={"center"} alignItems="center">
                {!loading && <img src={img} alt="company" loading="lazy" className={Styles.Img} />}
                {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                {
                  loading ?
                    <Stack direction="column" position="absolute" justifyContent={"center"} alignItems={"center"}>
                      <CircularProgress />
                      <Typography>uploading...</Typography>
                    </Stack> :
                    null
                }
                <input type="file" accept="image/*" name="image-upload" id={`SCategoryImgUploadAdd`} className={Styles.imginp} onChange={imgSelect} />
              </Stack>


            </Card>
            <div className={Styles.ImageUploadContainer}>

              <label className={Styles.ImageUploadButton} htmlFor={`SCategoryImgUploadAdd`} >
                UPLOAD
              </label>
            </div>

          </Stack>
          <Stack gap={1.5}>
            <Stack>
              <AutocompleteTextField size="small" label="Super Category Name" value={form.name}
                name="name"
                onChange={handleChange} />
              {error.scategoryname && <Typography sx={{ color: "red" }} variant="caption" className={Styles.error}>{error.scategoryname}</Typography>}
            </Stack>
          </Stack>

          <Stack direction="row" gap={1.5}>
            <RedButton sx={{ flex: 1, borderRadius: "18px" }} type="submit">Add</RedButton>
            <RedBorderButton sx={{ flex: 1, borderRadius: "18px", backgroundColor: "white" }} onClick={handleCancel}>Cancel</RedBorderButton>
          </Stack>

        </Stack>

      </form>
    </>
  );
};

export default AddSCategory;
