import { Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { GetColor } from '../../../utility/functions/functions';
import { StyledTableCell } from '../../../utility/TextField';
import { IOSSwitch } from '../../../utility/IosSwitch';
import { TableCellMui } from '../../../utility/Table';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { LaunchUseContext } from '../../Context/LaunchContext';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate';
import { UpdateLaunchStatusBySc } from '../../../actions/Launch/LaunchAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LaunchKycTable = () => {
  const store = useSelector(state => state.Launch)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const BgColor = GetColor('--color-light-background');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Card component={Paper} sx={{ backgroundColor: BgColor }}>
        <Stack padding={1}>
          <TableContainer sx={{ maxHeight: 496, minWidth: "100%", backgroundColor: BgColor, xs: { minWidth: 100 }, sm: { minWidth: 300 }, md: { minWidth: 300 } }} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead size='small' sx={{
                '& .MuiTableCell-head': {
                  backgroundColor: "#1B1D23 !important",
                  color: "white !important"
                }
              }
              }>
                <TableRow sx={{ backgroundColor: "#1B1D23" }} size='small'>
                  <StyledTableCell align='center' size="small">Name</StyledTableCell>
                  <StyledTableCell align='center' size='small'>Mobile No</StyledTableCell>
                  <StyledTableCell align='center' size='small'>Kyc Zone</StyledTableCell>
                  <StyledTableCell align='center' size='small'>Current Active Zone</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  store?.kyc_list?.map(item =>
                    <Row item={item} />
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              color: "white !important",
              '& .MuiSvgIcon-root': {
                color: 'white'
              },
            }}
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={store?.kyc_list?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Card>
    </>
  )
}

export default LaunchKycTable


const Row = ({ item }) => {
  const Axios = useAxiosPrivate()
  const dispatch = useDispatch()
  const { handleShowUserTable } = LaunchUseContext()
  const [Obj, setObj] = useState(item)


  return (
    <>
      <TableRow >
        <TableCellMui align="center">{Obj?.first_name+" "+Obj.last_name}</TableCellMui>
        <TableCellMui align="center">{Obj?.mobile_no}</TableCellMui>
        <TableCellMui align="center">{Obj?.kyc_zone}</TableCellMui>
        <TableCellMui align="center">{Obj?.active_zone}</TableCellMui>
      </TableRow>
    </>
  )
}