import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useLocalStorage from "./useLocalStorage";
import { useSelector } from "react-redux";

const PersistLogin = () => {
  const navigate = useNavigate();
  const Location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const [persist] = useLocalStorage("persist", false);
  const store = useSelector((state) => state.Login);
  console.log(persist, "persist");
  console.log(store, "storeLogin");

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        // console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    // Avoids unwanted call to verifyRefreshToken
    if (!store?.token && persist) {
      verifyRefreshToken();
    } else {
      setIsLoading(false);
      // navigate('/Login')
    }

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    console.log(`isLoading: ${isLoading}`);
    console.log(`aT: ${JSON.stringify(store?.accessToken)}`);
  }, [isLoading]);

  // console.log(!persist ? "<Outlet />11" : isLoading ? "<p>Loading...</p>" : "<Outlet />");
  // console.log(Location)
  return (
    <>{!persist ? <Outlet /> : isLoading ? <p>Loading...</p> : <Outlet />}</>
  );
};

export default PersistLogin;
