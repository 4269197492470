import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { ChangePasswordAction } from "../../actions/Login/login";
import { LoginButton } from "../../utility/Button";
import { GETCOLOR } from "../../utility/GetColorForJs";
import { LOGINDATA } from "../../utility/ReduxConstants/LoginReducerConstant";
import { CssTextField } from "../../utility/TextField";
import { ROLES } from "../../utility/UserAllowedRoles";

const ChangePassword = ({ mobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ERRORCOLOR = GETCOLOR("--color-login-error-text");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [Error, setError] = useState({});
  const IsPasswordValid = () => {
    let success = true;
    let error = {};
    if (!password.trim()) {
      error.password = "**please enter a password**";
      success = false;
    }
    setError(error);
    return success;
  };

  const handleChangePassword = async () => {
    setLoader(true);
    if (IsPasswordValid()) {
      let param = {
        mobile_no: mobile,
        password: password,
      };
      
      const Data = await dispatch(ChangePasswordAction(param));
      if (Data.code === 200) {
        dispatch({ type: LOGINDATA, payload: Data });
        let REFRESH_TOKEN = Data.refresh_token;
        localStorage.setItem("refresh_token", REFRESH_TOKEN);
        setLoader(false);
        Swal.fire({
          title: "Success",
          text: "Password changed successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          ROLES.LOGIN_MGT.includes(Data?.user_data?.admin_role_code)
            ? navigate("/home/zonemgt")
            : navigate("/home/category");
        }, [2000]);
      } else {
        setError({
          alert: Data.message,
        });
      }
    } else {
      setLoader(false);
    }
  };
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100% !important" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding={3}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>
          Change Password
        </Typography>
      </Stack>
      <Stack>
        <Stack sx={{ padding: "20px 20px" }}>
          <CssTextField
            label="Please Enter You New Password"
            sx={{ minWidth: 300 }}
            type="text"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {Error?.password && (
            <Typography
              variant="caption"
              sx={{ color: ERRORCOLOR, marginLeft: "20px" }}
            >
              {Error?.password}
            </Typography>
          )}
        </Stack>
        <Stack sx={{ padding: "10px 20px" }}>
          <LoginButton
            onClick={handleChangePassword}
            endIcon={
              loader ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Submit Password
          </LoginButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChangePassword;
