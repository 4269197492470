import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Styles from "./Styles/NewUpdateSku.module.css";
// import Bidi from "../../../assets/Appu_Bidi.png";
import PopUp from "./PopUp";
import { useDispatch, useSelector } from "react-redux";
import { defaultImg } from "../../../utility/BaseUrl";
import {
  GETSKUNAMELISTONLY,
  REFVALUELIST,
  // GETSKUDETAILSNEW,
  RESETNEWSUDETAIL,
  UPDATESKUDETAILS,
} from "../../../utility/ReduxConstants/NewSkuReducerConstant";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ChangeSkuStatus,
  GetAllCategoryNewSku,
  GetAllSubCategoryNewSku,
  // GetAllSubCategoryNewSku,
  GetSkuDetailsById,
  UpdateNewSku,
  UpdateStatusOfSettings,
} from "../../../actions/newSkuAction/newSkuAction";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { DarkCardWrapper } from "../CardWrapper";
// import SearchBarComponent from "../../SearchBarComponent";
import {
  AutocompleteTextField,
  AutocompleteTextFieldCurved,
} from "../../../utility/TextField";
// import SearchIcon from "@mui/icons-material/Search";
import { FiEdit3 } from "react-icons/fi";
import { SecondaryButton } from "../../../utility/Button";
import { CheckSmallError } from "../../../utility/ErrorCheck";
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  padding: "7px 20px",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

// const RedBorderButton = styled(Button)(({ theme }) => ({
//   color: "#DC1D13",
//   backgroundColor: "#323546",
//   border: "2px solid #DC1D13",
//   "&:hover": {
//     backgroundColor: "#DC1D13",
//     color: "white",
//   },
// }));

const NewUpdateSku = () => {
  const store = useSelector((state) => state.NewSku);
  const prem_del_market_price = useRef(0);

  const ptwValue = useRef(store.skuDetail.ptw ? store.skuDetail.ptw : 0);
  console.log(ptwValue, "prem_del_market_price");
  const lotSizeDelivery = useRef(
    store.skuDetail.lot_size_delivery ? store.skuDetail.lot_size_delivery : 0
  );
  const [categoryLoader, setCategoryLoader] = useState(false);
  const Axios = useAxiosPrivate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);

  const Role = useSelector((state) => state?.Login);
  const [error, setError] = useState({});
  const [deliveryMarketPrice, setDeliveryMarketPrice] = useState(0);
  const AdvancedOptions = Role?.user;
  const skuOnlyNameList = useSelector((store) => store.NewSku.skuNameList);

  console.log(AdvancedOptions, "AdvancedOptions");

  // admin_role_name: "super_category_admin"

  // useEffect(() => {
  //   dispatch({
  //     type: REFVALUELIST,
  //     payload: {
  //       premium_delivery_market_price: obj?.premium_delivery_market_price,
  //     },
  //   });
  // }, [store.refValueList]);

  console.log(
    store.refValueList?.premium_delivery_market_price,
    "refValueListqw"
  );

  const [calculatedValue, setCalculatedValue] = useState(null);

  // const getValue = () => {
  //   const value =
  //     store.refValueList?.lot_size_delivery_value * store.refValueList?.ptw;
  //   const data = store.refValueList?.premium_delivery_market_price / value;
  //   setCalculatedValue(data);
  // };

  // useEffect(() => {
  //   getValue();
  // }, [store.refValueList]);

  // console.log(calculatedValue, "refValueListqw");

  // const divValue=store.refValueList?.lot_size_delivery_value*store.refValueList?.ptw;
  // console.log(deliveryMarketPrice, "refValueListqw");
  // console.log(store.refValueList?.premium_delivery_market_price/divValue,"refValueListqw")

  const [obj, setObj] = useState({
    checked: false,
    skuList: [],
    companyList: [
      {
        item_id: 0,
        item_name: "",
      },
    ],
    categoryList: [
      {
        category_id: 0,
        category_name: "",
      },
    ],
    superCategoryList: [
      {
        sc_id: 0,
        name: "",
      },
    ],
    subCategoryList: [
      {
        s_cat_id: 0,
        s_category_name: "",
      },
    ],
    sku: {},
    category: {
      category_id: 0,
      category_name: "",
    },
    company: {
      item_id: 0,
      item_name: "",
    },
    scCategory: {
      sc_id: 0,
      name: "",
    },
    subCategory: {
      s_cat_id: 0,
      s_category_name: "",
    },
    sku_name: "",
    sku_desc: "",
    sku_image: "",
    sku_id: 0,
    status: false,
    ptr: 0,
    ptw: 0,
    mrp: 0,
    scheme: "",
    lot_size_delivery: 0,
    lot_size_pickup: 0,
    premium_delivery_market_price: 0,
    premium_delivery_lot_size: 0,
    premium_pickup_market_price: 0,
    premium_pickup_lot_size: 0,
  });

  console.log(obj,"Target Name:")
  const [sku, setSku] = useState({});

  console.log(obj, "ObjSubCategory");
  console.log(skuOnlyNameList, "ObjSubCategory");

  useEffect(() => {
    dispatch({ type: RESETNEWSUDETAIL });
  }, [location]);

  useEffect(() => {
    let skuArr = [];

    if (
      store.skuList !== undefined &&
      store.skuList !== null &&
      store.skuList.length !== 0
    ) {
      skuArr = store.skuList;
    }

    setObj({
      ...obj,
      skuList: skuArr,
    });

    setSku({
      sku_id: 0,
      sku_name: "",
      sku_desc: "",
      sku_image: "",
      category_id: 0,
      company_id: 0,
      status: false,
    });
  }, [store.skuList]);

  useEffect(() => {
    let Arr = [];
    for (let i = 0; i < store.skuList?.length; i++) {
      Arr.push(store.skuList[i]?.sku_name);
    }

    dispatch({ type: GETSKUNAMELISTONLY, payload: { sku_name: Arr } });
  }, [store?.skuList]);

  useEffect(() => {
    let comArr = [];
    if (store.companyList !== undefined && store.companyList !== null) {
      comArr = store.companyList;
      // comArr.push({
      //   item_id: 0,
      //   item_name: "",
      // });
    }
    setObj({
      ...obj,
      companyList: comArr,
    });
  }, [store.companyList]);

  useEffect(() => {
    let scArr = [];
    if (
      store.superCategoryList !== undefined &&
      store.superCategoryList !== null
    ) {
      scArr = store.superCategoryList;
      // scArr.push({
      //   sc_id: 0,
      //   name: "",
      // });
    }
    setObj({
      ...obj,
      superCategoryList: scArr,
    });
  }, [store.superCategoryList]);

  useEffect(() => {
    setCategoryLoader(true);
    let catArr = [];
    if (store.categoryList !== undefined && store.categoryList !== null) {
      // catArr.push({category_id:0,category_name:''});
      catArr = store.categoryList;
      // catArr.push({
      //   category_id: 0,
      //   category_name: "",
      // });
    }
    setObj({
      ...obj,
      categoryList: catArr,
    });
    setCategoryLoader(false);
  }, [store.categoryList]);

  useEffect(() => {
    let SubcatArr = [];
    if (store.subCategoryList !== undefined && store.subCategoryList !== null) {
      // catArr.push({category_id:0,category_name:''});
      SubcatArr = store.subCategoryList;
      // SubcatArr.push({
      //   s_cat_id: 0,
      //   s_category_name: "",
      // });
    }
    setObj({
      ...obj,
      subCategoryList: SubcatArr,
    });
  }, [store.subCategoryList]);

  useEffect(() => {
    (async () => {
      if (
        store.skuDetail !== undefined &&
        store.skuDetail !== null &&
        store.skuDetail?.sku_name
      ) {
        console.log(store.skuDetail, "imnskudetails");
        let skuObj = store.skuDetail;
        let category = {};
        let company = {};
        let scCategory = {};
        let subCategory = {};

        for (let i = 0; i < store.superCategoryList.length; i++) {
          if (store.superCategoryList[i].sc_id === skuObj.sc_id) {
            scCategory = store.superCategoryList[i];
          }
        }
        let paramTwo = {
          sc_id: scCategory?.sc_id,
        };

        let param3 = {
          sc_id: skuObj?.sc_id,
          category_id: skuObj?.category_id,
        };

        console.log(category, "param3");

        setCategoryLoader(true);

        const data = await dispatch(GetAllCategoryNewSku(Axios, paramTwo));

        const subCateListData = await dispatch(
          GetAllSubCategoryNewSku(Axios, param3)
        );

        console.log(subCateListData, "dataInUpdate");

        for (let i = 0; i < store.companyList.length; i++) {
          if (store.companyList[i].item_id === skuObj.company_id) {
            company = store.companyList[i];
          }
        }

        for (let i = 0; i < data.list.length; i++) {
          if (data.list[i].category_id === skuObj.category_id) {
            category = data.list[i];
          }
        }

        for (let i = 0; i < subCateListData.list.length; i++) {
          if (subCateListData.list[i].s_cat_id === skuObj.s_cat_id) {
            subCategory = subCateListData.list[i];
          }
        }
        console.log(subCategory, "SubcategoryInUpdate");

        setCategoryLoader(false);
        console.log("categoryInUpdate", category);

        setObj({
          ...obj,
          sku_name:
            store.skuDetail.sku_name !== undefined &&
            store.skuDetail.sku_name !== null &&
            store.skuDetail.sku_name.length !== 0
              ? store.skuDetail.sku_name
              : "",
          sku_desc:
            store.skuDetail.sku_desc !== undefined &&
            store.skuDetail.sku_desc !== null &&
            store.skuDetail.sku_desc.length !== 0
              ? store.skuDetail.sku_desc
              : "",
          sku_image:
            store.skuDetail.sku_image !== undefined &&
            store.skuDetail.sku_image !== null &&
            store.skuDetail.sku_image.length !== 0
              ? store.skuDetail.sku_image
              : "",
          sku_id:
            store.skuDetail.sku_id !== undefined &&
            store.skuDetail.sku_id !== null &&
            store.skuDetail.sku_id.length !== 0
              ? store.skuDetail.sku_id
              : 0,
          status:
            store.skuDetail.status !== undefined &&
            store.skuDetail.status !== null &&
            store.skuDetail.status
              ? true
              : false,
          category,
          company,
          scCategory,
          subCategory,
          categoryList: data.list,
          // subCategoryList: data2.list,
          ptr: store.skuDetail.ptr ?? 0,
          ptw: store.skuDetail.ptw ?? 0,
          mrp: store.skuDetail.mrp ?? 0,
          scheme: store.skuDetail.scheme ?? "",
          lot_size_delivery: store.skuDetail.lot_size_delivery ?? 0,
          lot_size_pickup: store.skuDetail.lot_size_pickup ?? 0,
          checked: store?.skuDetail?.checked ?? false,
          subCategoryList: subCateListData.list,
          premium_delivery_market_price: store.skuDetail.lock_price_d ?? 0,
          premium_pickup_market_price: store.skuDetail.lock_price_p ?? 0,

          // premium_delivery_lot_size:
          //   (store.skuDetail.ptw * store.skuDetail.lot_size_delivery) /
          //   parseInt(prem_del_market_price.current.value,10),
        });
      }
    })();
  }, [store.skuDetail]);

  console.log(store.skuDetail, "fdsrjdslkjfds");

  // useEffect(() => {
  //   setObj({
  //     ...obj,
  //     premium_delivery_lot_size:
  //       obj.premium_delivery_market_price / (obj?.ptw * obj?.lot_size_delivery),
  //     premium_pickup_lot_size:
  //       obj.premium_pickup_market_price / (obj?.ptw * obj?.lot_size_pickup),
  //   });
  // }, [
  //   obj.premium_delivery_market_price,
  //   obj.ptw,
  //   obj.lot_size_delivery,
  //   obj?.lot_size_pickup,
  //   obj?.premium_pickup_market_price,
  // ]);

  useEffect(() => {
    setObj({
      ...obj,
      premium_delivery_lot_size: Math.floor(
        obj.premium_delivery_market_price / (obj?.ptw * obj?.lot_size_delivery)
      ),
      premium_pickup_lot_size: Math.floor(
        obj.premium_pickup_market_price / (obj?.ptw * obj?.lot_size_pickup)
      ),
    });
  }, [
    obj.premium_delivery_market_price,
    obj.ptw,
    obj.lot_size_delivery,
    obj?.lot_size_pickup,
    obj?.premium_pickup_market_price,
  ]);

  console.log(obj, "lot_size_delivery_value");

  const handleCompany = (e, value) => {
    setSku({
      ...sku,
      company_id: value.item_id,
    });
    setObj({
      ...obj,
      company: value,
    });
  };

  const handleCategory = async (e, value) => {
    console.log(value, "handleCompanyValue");

    let param = {
      sc_id: value?.sc_id,
      category_id: value.category_id,
    };

    const data = await dispatch(GetAllSubCategoryNewSku(Axios, param));
    setSku({
      ...sku,
      category_id: value.category_id,
    });
    setObj({
      ...obj,
      category: value,
      subCategoryList: [
        {
          s_cat_id: 0,
          s_category_name: "",
        },
        ...data.list,
      ],
      subCategory: {
        s_cat_id: 0,
        s_category_name: "",
      },
    });

    console.log(value, "handleCategory");

    // API call to get sub category
  };
  const handleSubCategory = (e, value) => {
    console.log(value, "handleSubCatValue");

    setSku({
      ...sku,
      s_cat_id: value.s_cat_id,
    });
    setObj({
      ...obj,
      subCategory: value,
    });
  };

  const ChooseImg = (item) => {
    console.log(item);
    setShow(false);
    handleChanges(item);
  };

  // const handleSearch = () => {};

  const handleSuperCategory = async (e, value) => {
    setCategoryLoader(true);
    const { sc_id, name } = value;
    let param = {
      sc_id: sc_id,
    };
    const data = await dispatch(GetAllCategoryNewSku(Axios, param));
    setCategoryLoader(false);
    setObj({
      ...obj,
      sc_id: sc_id,
      scCategory: value,
      categoryList: [
        {
          category_id: 0,
          category_name: "",
        },
        ...data.list,
      ],
      category: {
        category_id: 0,
        category_name: "",
      },
      subCategory: {
        s_cat_id: 0,
        s_category_name: "",
      },
    });
  };

  const handleSku = (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      setSku(value);
      console.log(value, "handleSku");
      setUpdate(true);
      let param = {
        sku_id: value.sku_id,
      };
      dispatch(GetSkuDetailsById(Axios, param));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value
    setObj({
      ...obj,
      [name]: value,
    });
  };

  console.log(obj, "deliveryMarketPricejjl");

  const handleChanges = async (item) => {
    const { sc_id, name, image_name, image_path, category_id } = item;
    const SC = store.superCategoryList?.filter((i) => i?.sc_id == item?.sc_id);
    let param = {
      sc_id: sc_id,
    };
    const CatData = await dispatch(GetAllCategoryNewSku(Axios, param));
    console.log(category_id, item, "category_id,item", CatData);
    if (CatData?.code === 200) {
      const CategoryData = CatData?.list?.filter(
        (i) => i?.category_id == category_id
      );
      console.log(CategoryData, "CategoryData");
      setObj({
        ...obj,
        // sku_name: image_name,
        // sc_id: sc_id,
        // scCategory: {
        //   sc_id: sc_id,
        //   name: SC[0]?.name,
        // },
        sku_image: image_path,
        // category: {
        //   category_id: CategoryData[0]?.category_id,
        //   category_name: CategoryData[0]?.category_name,
        // },
      });
    }
  };

  const handleChecked = async (e) => {
    setObj({
      ...obj,
      status: e.target.checked,
    });

    let param = {
      sku_id: obj.sku_id,
      status: e.target.checked,
    };

    let result = await dispatch(ChangeSkuStatus(Axios, param));

    if (result) {
      let NewObj = {
        sku_name: e.target.value,
        sku_desc: obj.sku_desc,
        sku_image: obj.sku_image,
        sku_id: obj.sku_id,
        category_id: obj.category.category_id,
        company_id: obj.company.item_id,
        status: e.target.checked,
      };
      dispatch({ type: UPDATESKUDETAILS, payload: NewObj });
      toast.success(`Status Changed SuccessFully For ${obj.sku_name}`);
    } else {
      toast.error("Failed To Update");
    }
  };

  const IsValid = () => {
    const regex = /[^a-zA-Z0-9',&-](?= |$)/g;
    const Chars = obj.sku_name?.match(regex);
    let success = true;
    let error = {};

    if (!obj.sku_name.trim()) {
      error.sku_name = "*Sku Name Cannot be Empty*";
      success = false;
    } else if (Chars && Chars?.length !== 0) {
      error.sku_name = `*Only ' and - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*`;
      success = false;
    }

    if (obj.category.category_id === 0 || obj.category.category_id === "") {
      error.category = "*Please choose a category*";
      success = false;
    }
    if (obj.company.company_id === 0 || obj.company.company_id === "") {
      error.company = "*Please choose a category*";
      success = false;
    }
    if (obj.scCategory.sc_id === 0 || obj.scCategory.sc_id === "") {
      error.sc = "*Please choose a Super category*";
      success = false;
    }
    // if (!obj.sku_desc.trim()) {
    //     error.sku_desc = '*Sku Description Cannot be Empty*';
    //     success = false
    // }
    if (!obj.sku_image.trim()) {
      error.sku_image = "*Please Choose a Image*";
      success = false;
    }

    if (
      obj.mrp === 0 ||
      obj.ptr === 0 ||
      obj.ptw === 0 ||
      obj.lot_size_delivery === 0 ||
      obj.lot_size_pickup === 0
    ) {
      if (obj.mrp === 0) {
        error.mrp = "*MRP should be greater than 0*";
      }
      if (obj.ptr === 0) {
        error.ptr = "*PTR should be greater than 0*";
      }
      if (obj.ptw === 0) {
        error.ptw = "*PTW should be greater than 0*";
      }
      if (obj.lot_size_delivery === 0) {
        error.lot_size_delivery =
          "*Lot size for delivery should be greater than 0*";
      }
      if (obj.lot_size_pickup === 0) {
        error.lot_size_pickup =
          "*Lot size for pickup should be greater than 0*";
      }

      success = false;
    }

    // if (obj.premium_pickup_lot_size < 1) {
    //   const reqPickupPrice = obj.ptw * obj.lot_size_pickup;
    //   error.ppls =
    //     "*Please enter atleast " + reqPickupPrice + " price to continue*";
    // }

    // if (obj.premium_delivery_lot_size < 1) {
    //   const reqDeliveryPrice = obj.ptw * obj.lot_size_delivery;
    //   error.pdls =
    //     "*Please enter atleast " + reqDeliveryPrice + " price to continue*";
    // }

    setError(error);
    return success;
  };

  // Handle Update For Updating The Previous Data

  const handleUpdate = async () => {
    if (IsValid()) {
      let NewObj = {
        sku_id: obj.sku_id,
        sku_name: obj.sku_name,
        sku_desc: obj.sku_desc,
        company_id: obj.company.item_id,
        category_id: obj.category.category_id,
        s_cat_id: obj.subCategory.s_cat_id,
        sku_image: obj.sku_image,
        sc_id: obj.scCategory.sc_id,
        ptr: obj.ptr,
        ptw: obj.ptw,
        mrp: obj.mrp,
        scheme: obj.scheme ?? "",
        lot_size_delivery: obj.lot_size_delivery,
        lot_size_pickup: obj.lot_size_pickup,
        checked: obj?.checked ?? false,
        lock_price_d: obj.premium_delivery_market_price,
        lock_price_p: obj.premium_pickup_market_price,
        lock_lot_d: obj.premium_delivery_lot_size,
        lock_lot_p: obj.premium_pickup_lot_size,
      };

      const response = await dispatch(UpdateNewSku(Axios, NewObj));

      if (response.code === 200) {
        toast.success("Updated sku successFully");
      } else {
        toast.error(response.message);
      }
    }
  };
  const handleCancel = () => {
    if (store.skuDetail !== undefined && store.skuDetail !== null) {
      let skuObj = store.skuDetail;
      let category = {};
      let company = {};
      let subCategory = {};

      for (let i = 0; i < obj.categoryList.length; i++) {
        if (obj.categoryList[i].category_id === skuObj.category_id) {
          category = obj.categoryList[i];
        }
      }

      for (let i = 0; i < obj.companyList.length; i++) {
        if (obj.companyList[i].item_id === skuObj.company_id) {
          company = obj.companyList[i];
        }
      }
      for (let i = 0; i < obj.subCategoryList.length; i++) {
        if (obj.subCategoryList[i].item_id === skuObj.s_cat_id) {
          subCategory = obj.subCategoryList[i];
        }
      }

      setObj({
        ...obj,
        sku_name:
          store.skuDetail.sku_name !== undefined &&
          store.skuDetail.sku_name !== null &&
          store.skuDetail.sku_name.length !== 0
            ? store.skuDetail.sku_name
            : "",
        sku_desc:
          store.skuDetail.sku_desc !== undefined &&
          store.skuDetail.sku_desc !== null &&
          store.skuDetail.sku_desc.length !== 0
            ? store.skuDetail.sku_desc
            : "",
        sku_image:
          store.skuDetail.sku_image !== undefined &&
          store.skuDetail.sku_image !== null &&
          store.skuDetail.sku_image.length !== 0
            ? store.skuDetail.sku_image
            : "",
        sku_id:
          store.skuDetail.sku_id !== undefined &&
          store.skuDetail.sku_id !== null &&
          store.skuDetail.sku_id.length !== 0
            ? store.skuDetail.sku_id
            : 0,
        status:
          store.skuDetail.status !== undefined &&
          store.skuDetail.status !== null &&
          store.skuDetail.status
            ? true
            : false,
        category: category,
        company: company,
        subCategory: subCategory,
      });
    }
  };

  const handleReset = () => {
    setUpdate(false);
    setObj({
      ...obj,
      category: {
        category_id: 0,
        category_name: "",
      },
      company: {
        item_id: 0,
        item_name: "",
      },
      subCategory: {
        s_cat_id: 0,
        s_category_name: "",
      },
      sku_name: "",
      sku_desc: "",
      sku_image: defaultImg,
      sku_id: 0,
      status: false,
    });
  };

  const handleIosSwitch = async (e) => {
    setObj({
      ...obj,
      checked: e.target.checked,
    });

    let NewObj = {
      sku_id: obj.sku_id,
      sku_name: obj.sku_name,
      sku_desc: obj.sku_desc,
      company_id: obj.company.item_id,
      category_id: obj.category.category_id,
      s_cat_id: obj.subCategory.s_cat_id,
      sku_image: obj.sku_image,
      sc_id: obj.scCategory.sc_id,
      ptr: obj.ptr ?? 0,
      ptw: obj.ptw ?? 0,
      mrp: obj.mrp ?? 0,
      scheme: obj.scheme ?? "",
      lot_size_delivery: obj.lot_size_delivery ?? 0,
      lot_size_pickup: obj.lot_size_pickup ?? 0,
      checked: e.target.checked,
      lock_price_d: obj.premium_delivery_market_price,
      lock_price_p: obj.premium_pickup_market_price,
      lock_lot_d: obj.premium_delivery_lot_size,
      lock_lot_p: obj.premium_pickup_lot_size,
    };

    const Data = await dispatch(UpdateStatusOfSettings(Axios, NewObj));

    if (Data?.code === 200) {
      toast.success(Data?.message);
    } else {
      toast.error(Data?.message);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DarkCardWrapper
        color="white"
        sx={{
          color: "white",
          padding: "10px",
          marginTop: "20px",
          minHeight: update && "68vh",
        }}
      >
        <Stack gap={2}>
          <Typography>Update Sku</Typography>
          <Autocomplete
            sx={{ minWidth: "300px" }}
            value={sku}
            options={obj?.skuList}
            getOptionLabel={(option) => option.sku_name}
            size="small"
            onChange={handleSku}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                name="sku"
                variant="outlined"
                label="Search Sku"
                placeholder="sku.."
              />
            )}
          />

          {update && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              marginTop={10}
            >
              <Stack flex={1} gap={2}>
                <Stack>
                  <Card component={Paper}>
                    <img
                      src={
                        obj.sku_image !== undefined &&
                        obj.sku_image !== null &&
                        obj.sku_image.length !== 0
                          ? obj.sku_image
                          : defaultImg
                      }
                      alt="sku"
                      style={{
                        width: "100%",
                        height: "100%",
                        aspectRatio: "1/1",
                        objectFit: "contain",
                      }}
                    />
                  </Card>
                  {error.sku_image && (
                    <label className={Styles.error}>{error.sku_image}</label>
                  )}
                </Stack>
                <Stack direction="row" justifyContent="center">
                  <Stack
                    direction="row"
                    gap={2}
                    sx={{
                      width: "150px",
                      borderBottom: "1px solid white",
                      cursor: "pointer",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => setShow(!show)}
                  >
                    <FiEdit3 size="20px" />
                    <Typography>Change Image</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack flex={3} gap={2}>
                <Stack marginTop={2}>
                  <AutocompleteTextField
                    sx={{ width: "100%" }}
                    size="small"
                    name="sku_name"
                    variant="outlined"
                    label="Sku Name"
                    placeholder="sku.."
                    value={obj.sku_name}
                    onChange={handleChange}
                  />
                  {error.sku_name && (
                    <label className={Styles.error}>{error.sku_name}</label>
                  )}
                </Stack>
                <Stack>
                  <Autocomplete
                    value={obj.company}
                    options={obj.companyList}
                    getOptionLabel={(option) => option.item_name}
                    size="small"
                    onChange={handleCompany}
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        name="company"
                        variant="outlined"
                        label="Company"
                        placeholder="company.."
                      />
                    )}
                  />
                  {error.company && (
                    <label className={Styles.error}>{error.company}</label>
                  )}
                </Stack>

                <Stack>
                  <Autocomplete
                    value={obj.scCategory}
                    options={obj.superCategoryList}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    onChange={handleSuperCategory}
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        name="super category"
                        variant="outlined"
                        label="Super Category"
                        placeholder="Super Category.."
                      />
                    )}
                  />
                  {error.sc && (
                    <label className={Styles.error}>{error.sc}</label>
                  )}
                </Stack>
                <Stack>
                  <Autocomplete
                    loading={categoryLoader}
                    value={obj.category}
                    options={obj.categoryList}
                    getOptionLabel={(option) => option.category_name}
                    size="small"
                    onChange={handleCategory}
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {categoryLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        name="category"
                        variant="outlined"
                        label="Category"
                        placeholder="category.."
                      />
                    )}
                  />
                  {error.category && (
                    <label className={Styles.error}>{error.category}</label>
                  )}
                </Stack>
                <Stack>
                  <Autocomplete
                    loading={categoryLoader}
                    value={obj.subCategory}
                    options={obj.subCategoryList}
                    getOptionLabel={(option) =>
                      option.s_category_name == undefined ||
                      option.s_category_name == null
                        ? "Select"
                        : option.s_category_name
                    }
                    size="small"
                    onChange={handleSubCategory}
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {categoryLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        name="Sub_category"
                        variant="outlined"
                        label="Sub Category"
                        placeholder="Sub category.."
                      />
                    )}
                  />
                  {error.category && (
                    <label className={Styles.error}>{error.category}</label>
                  )}
                </Stack>

                <Stack gap={1} direction="row" width="100%">
                  <Stack>
                    <AutocompleteTextField
                      label="PTR"
                      size="small"
                      autoComplete="off"
                      value={obj?.ptr}
                      name="ptr"
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                    />
                    {error.ptr && (
                      <label className={Styles.error}>{error.ptr}</label>
                    )}
                  </Stack>
                  <Stack>
                    <AutocompleteTextField
                      label="PTW"
                      size="small"
                      autoComplete="off"
                      value={obj?.ptw}
                      name="ptw"
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                    />
                    {error.ptw && (
                      <label className={Styles.error}>{error.ptw}</label>
                    )}
                  </Stack>
                </Stack>
                <Stack gap={1} direction="row" width="100%">
                  <Stack>
                    <AutocompleteTextField
                      label="Lot Size Delivery"
                      size="small"
                      autoComplete="off"
                      value={obj?.lot_size_delivery}
                      name="lot_size_delivery"
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                    />
                    {error.lot_size_delivery && (
                      <label className={Styles.error}>
                        {error.lot_size_delivery}
                      </label>
                    )}
                  </Stack>
                  <Stack>
                    <AutocompleteTextField
                      label="Lot Size Pickup"
                      size="small"
                      autoComplete="off"
                      value={obj?.lot_size_pickup}
                      name="lot_size_pickup"
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                    />
                    {error.lot_size_pickup && (
                      <label className={Styles.error}>
                        {error.lot_size_pickup}
                      </label>
                    )}
                  </Stack>
                </Stack>
                <Stack>
                  <AutocompleteTextField
                    label="MRP"
                    size="small"
                    autoComplete="off"
                    name="mrp"
                    value={obj?.mrp}
                    onChange={handleChange}
                  />
                  {error.mrp && (
                    <label className={Styles.error}>{error.mrp}</label>
                  )}
                </Stack>
                <AutocompleteTextField
                  label="Scheme"
                  multiline
                  rows="2"
                  size="small"
                  autoComplete="off"
                  value={obj?.scheme}
                  name="scheme"
                  onChange={handleChange}
                />

                <Stack
                  sx={{ display: "flex", gap: "1rem", flexDirection: "row" }}
                >
                  <Stack
                    gap={1}
                    direction="column"
                    width="50% "
                    sx={{
                      background: "#579CFD",
                      padding: "1rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <Stack>
                      <AutocompleteTextField
                        label="Premium Delivery Market Price"
                        size="small"
                        autoComplete="off"
                        value={obj?.premium_delivery_market_price}
                        name="premium_delivery_market_price"
                        onChange={handleChange}
                        sx={{ flex: 1 }}
                        disabled={!obj.ptw && !obj.lot_size_delivery}
                      />
                      {error.pdls && (
                        <label className={Styles.error}>{error.pdls}</label>
                      )}
                    </Stack>
                    <AutocompleteTextField
                      label="Premium Delivery Lot size"
                      size="small"
                      autoComplete="off"
                      value={
                        obj?.premium_delivery_lot_size
                          ? obj?.premium_delivery_lot_size
                          : 0
                      }
                      name="premium_delivery_lot_size"
                      onChange={handleChange}
                      sx={{ flex: 1 }}
                      disabled="true"
                    />
                  </Stack>
                  <Stack
                    gap={1}
                    direction="column"
                    width="50%"
                    sx={{
                      background: "#8B8000",
                      padding: "1rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <Stack>
                      <AutocompleteTextField
                        label="Premium Pickup Market Price"
                        size="small"
                        autoComplete="off"
                        value={obj?.premium_pickup_market_price}
                        name="premium_pickup_market_price"
                        onChange={handleChange}
                        sx={{ flex: 1 }}
                        disabled={!obj.ptw && !obj.lot_size_pickup}
                      />
                      {error.ppls && (
                        <label className={Styles.error}>{error.ppls}</label>
                      )}
                    </Stack>
                    <AutocompleteTextField
                      label="Premium Pickup Lot size"
                      size="small"
                      autoComplete="off"
                      value={
                        obj?.premium_pickup_lot_size
                          ? obj?.premium_pickup_lot_size
                          : 0
                      }
                      name="premium_pickup_lot_size"
                      onChange={handleChange}
                      disabled="true"
                    />
                  </Stack>
                </Stack>
                {AdvancedOptions?.admin_role_code !== 3917 ||
                AdvancedOptions?.admin_role_name !== "super_category_admin" ? (
                  <Stack
                    direction={{ xs: "column", sm: "column", lg: "row" }}
                    gap={2}
                  >
                    <Typography variant="caption">
                      Advanced Options - Update Same Details For All Zones?
                    </Typography>
                    <IOSSwitch
                      checked={obj.checked}
                      onChange={handleIosSwitch}
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          )}

          {update && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={1}
              justifyContent={"center"}
            >
              <SecondaryButton
                size="small"
                variant="contained"
                onClick={handleReset}
              >
                Cancel
              </SecondaryButton>
              {/* <SecondaryButton
                size="small"
                variant="contained"
                onClick={handleCancel}
                sx={{ marginRight: "15px" }}
              >
                Cancel
              </SecondaryButton> */}
              <RedButton
                size="small"
                variant="contained"
                onClick={handleUpdate}
                sx={{ borderRadius: "18px" }}
              >
                Update
              </RedButton>
            </Stack>
          )}
        </Stack>
      </DarkCardWrapper>

      {show ? (
        <PopUp setShow={setShow} ChooseImg={ChooseImg} show={show} />
      ) : null}
    </>
  );
};

export default NewUpdateSku;
