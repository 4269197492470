import {
  GETALLSTATELISTUSERACCESSCONST,
  GETALLSUPERCATEGORYLIST,
  GETALLUSERLISTUSERACCESSCONST,
  GETALLUSERLISTUSERACCESSCONSTSEARCH,
  GETALLZONELISTUSERACCESSCONST,
  GETALLZONELISTSEARCHUSERACCESSCONST,
  UPDATEALLUSERLISTUSERACCESSCONST,
  GETSEARCHUSERLISTUSERACCESSCONST,
  GETROLEDATAACCESSMGT,
  GETALLSTATELISTUSERACCESSCONSTADD,
  GETALLZONELISTUSERACCESSCONSTADD,
  GETALLSUPERCATEGORYLISTADD,
  GETROLEDATAACCESSMGTADD,
  GETALLZONELISTUSERACCESSCONSTFILTER,
  GETUSERLISTACCESSMGT,
  GETUSERLISTACCESSMGTUPDATE,
  GETUPDATESETIONISOPENORNOT,
  GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
} from "../../utility/ReduxConstants/UserAcessReducerConstant";

const initialState = {
  stateList: [],
  zoneList: [],
  zoneListSearch: [],
  userList: [],
  superCategoryList: [],
  roleList: [],

  stateListAdd: [],
  zoneListAdd: [],
  superCategoryListAdd: [],
  roleListAdd: [],
  zoneListFilter: [],
  userDetails: {},
  districtListAdd:[],
  updateUserIsOpenOrNot: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETALLSTATELISTUSERACCESSCONSTADD:
      return {
        ...state,
        stateListAdd: action.payload ?? [],
      };
    case GETALLZONELISTUSERACCESSCONSTADD:
      return {
        ...state,
        zoneListAdd: action.payload ?? [],
      };
      // case GETALLZONELISTUSERACCESSCONSTADD:
      //   return {
      //     ...state,
      //     districtListAdd: action.payload ?? [],
      //   };
    case GETALLZONELISTUSERACCESSCONSTFILTER:
      return {
        ...state,
        zoneListFilter: action.payload ?? [],
      };
      case GETALLDISTRICTLISTUSERACCESSCONSTFILTER:
      return {
        ...state,
        districtListAdd: action.payload ?? [],
      };
    case GETALLSUPERCATEGORYLISTADD:
      return {
        ...state,
        superCategoryListAdd: action.payload ?? [],
      };
    case GETROLEDATAACCESSMGTADD:
      return {
        ...state,
        roleListAdd: action.payload ?? [],
      };

    case GETUSERLISTACCESSMGT:
      return {
        ...state,
        userList: action.payload ?? [],
      };

    case GETUSERLISTACCESSMGTUPDATE:
      return {
        ...state,
        userDetails: action.payload ?? {},
      };
    case GETUPDATESETIONISOPENORNOT:
      return {
        ...state,
        updateUserIsOpenOrNot: action.payload,
      };

    default:
      return state;
  }
};
