import { Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import UserSearchAccess from "./UserSearchAccess";
import UserAccessUpdate from "./UserAccessUpdate";

const UserSectionWrapper = ({ formOpen, text, setText }) => {
  return (
    <Stack sx={{ color: "white" }} gap={1}>
      <Stack gap={1} padding="20px 0px">
        <Typography variant="h6">Search User</Typography>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ border: "1px solid white", borderRadius: "20px" }}
          gap={1}
        >
          <IconButton
            // className={Styles.Icon}
            sx={{
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "gray",
                transition: "0.2s linear",
              },
            }}
          >
            <SearchIcon sx={{ color: "black !important" }} />
          </IconButton>
          <input
            style={{
              flex: 1,
              border: "none",
              outline: "none",
              background: "transparent",
              color: "white",
            }}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <IconButton
            // className={Styles.Icon}
            onClick={() => setText("")}
          >
            <CloseIcon sx={{ color: "white !important" }} />
          </IconButton>
        </Stack>
      </Stack>

      {formOpen && <UserSearchAccess text={text} setText={setText} />}

      {formOpen && <UserAccessUpdate />}
    </Stack>
  );
};

export default UserSectionWrapper;
