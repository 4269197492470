import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';




export const ColouredCheckBox =styled(Checkbox)({
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
});