import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import VitaranLogo from "../../../assets/landing_page_images/one.png";
import two from "../../../assets/landing_page_images/two.png";
import three from "../../../assets/landing_page_images/three.png";
import Four from "../../../assets/landing_page_images/four.png";
import five from "../../../assets/landing_page_images/five.png";
import six from "../../../assets/landing_page_images/six.png";
import seven from "../../../assets/landing_page_images/seven.png";
import eight from "../../../assets/landing_page_images/eight.png";
import nine from "../../../assets/landing_page_images/nine.png";
import ten from "../../../assets/landing_page_images/ten.png";
import eleven from "../../../assets/landing_page_images/eleven.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import FooterHero from "./FooterHero";
import Footer from "./Footer";
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import useLocalStorage from "../../../PrivateHooks/useLocalStorage";

const LandingPage = () => {
  const [open, setOpen] = useState(false);

  const store = useSelector((state) => state.Login);
  console.log(store, "LandingPage");

  const [persist] = useLocalStorage("persist", false);
  console.log(persist, "LandingPage2");

  const handleDetails = () => {
    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };
  return (
    <>
      {store?.token.length > 0 && persist ? (
        store?.user.admin_role_code === 9999 ||
        store?.user.admin_role_code === 1946 ? (
          <Navigate to={{ pathname: "/home/zonemgmt" }} />
        ) : (
          <Navigate to={{ pathname: "/home/category" }} />
        )
      ) : (
        <Stack sx={{ backgroundColor: "white" }}>
          <Stack sx={{ backgroundColor: "#424063" }} height={{ lg: "100vh" }}>
            <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
              <NavBar open={open} />
            </Stack>
            <Stack
              sx={{ display: { xs: "flex", lg: "none" } }}
              mt={"1rem"}
              ml={"1rem"}
              mr={"1rem"}
              direction={"row"}
              alignItems={"center"}
              gap={5}
            >
              <Stack onClick={() => setOpen(true)}>
                <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
              </Stack>
              <Stack width={"70%"}>
                <img src={ten} alt="logo" />
              </Stack>
            </Stack>
            <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
              <NavDrawer open={open} setOpen={setOpen} />
            </Stack>
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "90%",
                margin: "auto",
                alignItems: "center",
                display: { xs: "none", lg: "flex" },
              }}
            >
              <Stack sx={{ color: "white" }}>
                <Stack sx={{ width: "50%" }}>
                  <img src={ten} alt="Vitaran Live" />
                </Stack>
                <Stack>
                  <Typography>
                    lets you reimagine trading commodities and master the supply
                    chain! Our products are aimed to bring you the best deals by
                    breaking the logistics barrier and expanding your trading
                    network for better choices.
                  </Typography>
                </Stack>
                <Stack sx={{ color: "#55B7F8", marginTop: "1rem" }}>
                  <Typography>
                    Sign up with us to have a hassle free and fruitful trading
                    experience!
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "40%",
                  marginLeft: "5rem",
                }}
              >
                <img src={VitaranLogo} alt="img" />
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              sx={{
                width: "90%",
                margin: "auto",
                alignItems: "center",
                display: { xs: "flex", lg: "none" },
              }}
            >
              <Stack>
                <img style={{ width: "28rem" }} src={VitaranLogo} alt="img" />
              </Stack>
              <Stack sx={{ color: "white" }}>
                <Stack>
                  <Typography>
                    lets you reimagine trading commodities and master the supply
                    chain! Our products are aimed to bring you the best deals by
                    breaking the logistics barrier and expanding your trading
                    network for better choices.
                  </Typography>
                </Stack>
                <Stack sx={{ color: "#55B7F8", marginTop: "1rem" }}>
                  <Typography>
                    Sign up with us to have a hassle free and fruitful trading
                    experience!
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                backgroundColor: "White",
                padding: "5px 2rem",
                borderRadius: "6px",
                width: "8rem",
                display: { xs: "flex", lg: "none" },
              }}
              mt={"1rem"}
              ml={"1.2rem"}
              mb={"2rem"}
            >
              <RouterLink style={{ textDecoration: "none" }} to={"/login"}>
                <Button sx={{ color: "black" }}>Login</Button>
              </RouterLink>
            </Stack>

            <Stack
              sx={{ color: "white", margin: "auto" }}
              onClick={handleDetails}
              style={{ cursor: "pointer" }}
            >
              <Stack>
                <Typography>Explore</Typography>
              </Stack>
              <Stack
                mb={"2rem"}
                sx={{ paddingLeft: "15px" }}
                onClick={handleDetails}
              >
                <KeyboardDoubleArrowDownIcon />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              color: "black",
              backgroundColor: "white",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack sx={{ width: "15%", margin: "auto", marginTop: "5rem" }}>
              <img src={eleven} alt="logo" />
            </Stack>
            <Stack>
              <Typography
                fontFamily={"-moz-initial"}
                sx={{
                  fontWeight: "bold",
                  margin: "auto",
                  color: "#565656",
                  marginTop: "1rem",
                }}
                fontSize={"25px"}
              >
                Buy, sell, make trade, at LOWEST prices & HIGHEST Profits
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: "40%",
                margin: "auto",
                textAlign: "center",
                color: "#565656",
                marginTop: "1rem",
              }}
            >
              <Typography>
                This platform lets you trade commodities, seamlessly within your
                zone, for the best possible prices being offered by buyers and
                sellers in your zone.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              color: "black",
              backgroundColor: "white",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              sx={{
                width: "65%",
                margin: "auto",
                marginTop: "5rem",
                display: { xs: "flex", lg: "none" },
              }}
            >
              <img src={eleven} alt="logo" />
            </Stack>
            <Stack>
              <Typography
                fontFamily={"-moz-initial"}
                sx={{
                  fontWeight: "bold",
                  margin: "auto",
                  color: "#565656",
                  marginTop: "1rem",
                }}
                width={"90%"}
                fontSize={"22px"}
                textAlign={"center"}
              >
                Buy, sell, make trade, at LOWEST prices & HIGHEST Profits
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: "80%",
                margin: "auto",
                textAlign: "center",
                color: "#565656",
                marginTop: "1rem",
              }}
            >
              <Typography>
                This platform lets you trade commodities, seamlessly within your
                zone, for the best possible prices being offered by buyers and
                sellers in your zone.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
            id="ScrollView"
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Custom Price Quotes
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Make you own price quote open to the market to find an
                    appropriate trader for the prices that suit you.
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={two} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
            id="ScrollView"
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Custom Price Quotes
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={two} alt="img" />
              </Stack>
              <Stack mt={5} textAlign={"center"}>
                <Typography>
                  Make you own price quote open to the market to find an
                  appropriate trader for the prices that suit you.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={three} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Top Sellers and Buyers List
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    At any given time and zone, Vitaran LIVE provides you a
                    comprehensive list of buyers/sellers with the best trading
                    offers for a commodity.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "19px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Top Sellers and Buyers List
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={three} alt="img" />
              </Stack>
              <Stack>
                <Stack marginTop={5} textAlign={"center"}>
                  <Typography>
                    At any given time and zone, Vitaran LIVE provides you a
                    comprehensive list of buyers/sellers with the best trading
                    offers for a commodity.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Category Based Catalogue
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Get a meticulously categorized view of all the products that
                    you want to trade, for clarity and ease of operation.
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={Four} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Category Based Catalogue
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={Four} alt="img" />
              </Stack>
              <Stack marginTop={5} textAlign={"center"}>
                <Typography>
                  Get a meticulously categorized view of all the products that
                  you want to trade, for clarity and ease of operation.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={five} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "28px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Delivery & Pickup Management
                  </Typography>
                </Stack>
                <Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>MAP VIEW</Typography>{" "}
                      allows you to make note of all the addresses from where
                      you have traded to figure out the pickup and delivery
                      logistics.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        DELIVERY PARTNER APP
                      </Typography>{" "}
                      helps selected delivery partners to define the daily route
                      and tasks wrt deliveries and pick up. Partners are always
                      connected digitally.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Delivery & Pickup Management
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={five} alt="img" />
              </Stack>
              <Stack mt={5} textAlign={"center"}>
                <Stack>
                  <Stack>
                    <Typography>
                      <Typography fontWeight={"bold"}>MAP VIEW</Typography>{" "}
                      allows you to make note of all the addresses from where
                      you have traded to figure out the pickup and delivery
                      logistics.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        DELIVERY PARTNER APP
                      </Typography>{" "}
                      helps selected delivery partners to define the daily route
                      and tasks wrt deliveries and pick up. Partners are always
                      connected digitally.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Historic data in graphs
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Charts and Graphs set the context to understand the market
                    trend could be related to products, comparision and Users
                    profiling. 
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={six} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "23px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Historic data in graphs
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} marginLeft={{ xs: 0, lg: "20rem" }}>
                <img src={six} alt="img" />
              </Stack>
              <Stack mt={1} textAlign={"center"}>
                <Typography>
                  Charts and Graphs set the context to understand the market
                  trend could be related to products, comparision and Users
                  profiling. 
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={seven} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Schemes & Incentives
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Get customized and personalized schemes and offers on the
                    trade you make and make the process even more rewarding.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "23px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Schemes & Incentives
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={seven} alt="img" />
              </Stack>
              <Stack>
                <Stack mt={5} textAlign={"center"}>
                  <Typography>
                    Get customized and personalized schemes and offers on the
                    trade you make and make the process even more rewarding.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Trade Management and Ratings
                  </Typography>
                </Stack>
                <Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        Update Multiple Prices at ONCE
                      </Typography>{" "}
                      by having all trades made in a single view and be able to
                      update the prices for multiple products as per need.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>Ratings</Typography>
                      lets you know a trader’s background record and helps you
                      make an informed decision!
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={eight} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Trade Management and Ratings
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={eight} alt="img" />
              </Stack>
              <Stack textAlign={"center"}>
                <Stack mt={5}>
                  <Typography>
                    <Typography fontWeight={"bold"}>
                      Update Multiple Prices at ONCE
                    </Typography>{" "}
                    by having all trades made in a single view and be able to
                    update the prices for multiple products as per need.
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    <Typography fontWeight={"bold"}>Ratings</Typography>
                    lets you know a trader’s background record and helps you
                    make an informed decision!
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <FooterHero />
          <Footer />
        </Stack>
      )}
    </>
  );
};

export default LandingPage;
