import React, { useState, useEffect, useCallback } from "react";
import Styles from "./styles/login.module.css";
import GroupOne from "../../assets/loginGroupOne.png";
import GroupTwo from "../../assets/loginGroupTwo.png";
import logo from "../../assets/liveLogo.png";
import { flexbox } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { CssTextField } from "../../utility/TextField";
import { LoginButton } from "../../utility/Button";
import { FcCancel } from "react-icons/fc";
import { ColouredCheckBox } from "../../utility/CheckBox";
import { Alert, Stack, Typography } from "@mui/material";
import { AuthenticateUser, UserLogin } from "../../actions/Login/login";
import { useDispatch } from "react-redux";
import { GETCOLOR } from "../../utility/GetColorForJs";
import { LOGINDATA } from "../../utility/ReduxConstants/LoginReducerConstant";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import useToggle from "../../PrivateHooks/useToggle";
import { ROLES } from "../../utility/UserAllowedRoles";



const Login = ({ handleShowForget }) => {
 

  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const [toggle, setToggle] = useToggle("persist", false);
  const dispatch = useDispatch();
  const ERRORCOLOR = GETCOLOR("--color-login-error-text");
  const [Error, setError] = useState({});
  const [obj, setObj] = useState({
    mobile_no: "",
    password: "",
  });
  const handleChange = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };


  

  const IsValid = () => {
    let success = true;
    let error = {};

    if (!obj.mobile_no.trim()) {
      error.mobile = "**Please Enter mobile no**";
      success = false;
    } else if (obj.mobile_no?.length !== 0) {
      let mob = obj.mobile_no;
      let Arr = mob.split("");
      if (Arr.length !== 10) {
        error.mobile = "**Please Enter a valid mobile no**";
        success = false;
      }
    }
    if (!obj.password.trim()) {
      error.password = "**Please Enter a Password**";
      success = false;
    }
    setError(error);
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (IsValid()) {
      let param = {
        mobile_no: obj.mobile_no,
        password: obj.password,
      };
      const data = await dispatch(UserLogin(param));
      console.log(JSON.stringify(data),"allowedRoles");
      

      if (data.code === 200) {
        dispatch({ type: LOGINDATA, payload: data });

        let REFRESH_TOKEN = data.refresh_token;
        localStorage.setItem("refresh_token", REFRESH_TOKEN);
        Swal.fire({
          title: "Success",
          text: "Logged In Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          ROLES.LOGIN_MGT.includes(data?.user_data?.admin_role_code)
            ? navigate("/home/zonemgt")
            : navigate("/home/category");
        }, [2000]);
      } else {
        if (counter > 4) {
          setError({
            alert: "Forgot Password ? Please change password!!",
          });
        } else {
          setCounter(counter + 1);
          setError({
            alert: data.message,
          });
        }
      }
    }
  };

  

  
 
  

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={Styles.TitleContainer}>
          <label className={Styles.Title1}>Sign In To</label>
          <label className={Styles.Title2}>Get Started</label>
        </div>

        <div className={Styles.FormContainer}>
          {Error?.alert && <Alert severity="error">{Error?.alert}</Alert>}
          <Stack>
            <CssTextField
              label="MobileNo"
              sx={{ minWidth: 300 }}
              value={obj.mobile_no}
              name="mobile_no"
              onChange={handleChange}
            />
            {Error?.mobile && (
              <Typography
                variant="caption"
                sx={{ color: ERRORCOLOR, marginLeft: "20px" }}
              >
                {Error?.mobile}
              </Typography>
            )}
          </Stack>

          <Stack>
            <CssTextField
              label="Password"
              sx={{ minWidth: 300 }}
              // type="password"
              value={obj.password}
              name="password"
              onChange={handleChange}
            />
            {Error?.password && (
              <Typography
                variant="caption"
                sx={{ color: ERRORCOLOR, marginLeft: "20px" }}
              >
                {Error?.password}
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "flex-end",
                padding: "3px 10px 3px 0px",
                cursor: "pointer",
                "&": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => handleShowForget(true)}
            >
              Forgot password?
            </Typography>
          </Stack>
        </div>
        <div className={Styles.RememberContainer}>
          <ColouredCheckBox checked={toggle} onChange={setToggle} />
          <label className={Styles.RememberTitle}>Remember Me</label>
        </div>

        <div className={Styles.PolicyContainer}>
          <label className={Styles.Policy}>
            By continuing you are agreeing to our Terms and conditions
          </label>
        </div>

        <div className={Styles.PolicyContainer}>
          <LoginButton size="small" type="submit">
            Continue
          </LoginButton>
        </div>
      </form>
    </>
  );
};

export default Login;

//home
//contact
//aboutus

// Category Admin
// about us

//root
