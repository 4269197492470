import axios from "axios";
import { baseUrl } from "../utility/BaseUrl";

export const Authaxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

export const AxiosPrivate = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

export const AxiosLamda = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
