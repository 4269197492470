import {
  GETYOURDEALSBUYERDATALISTVIATABS,
  // GETYOURDEALSDATALIST,
  GETYOURDEALSDATALISTBUYER,
  GETYOURDEALSDATALISTSELLER,
  GETYOURDEALSSELLERDATALISTVIATABS,
} from "../../utility/ReduxConstants/YourDealsReducerConstant";

const initState = {
  yourDealsDataList: [],
  yourDealsDataListSeller: [],
  getBuyerDataList: [],
  getSellerDataList: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case GETYOURDEALSDATALISTBUYER: {
      return {
        ...state,
        yourDealsDataList: action.payload,
      };
    }
    case GETYOURDEALSDATALISTSELLER: {
      return {
        ...state,
        yourDealsDataListSeller: action.payload
        
      };
      
    }
    case GETYOURDEALSBUYERDATALISTVIATABS: {
      return {
        ...state,
        getBuyerDataList: action.payload,
      };
    }
    case GETYOURDEALSSELLERDATALISTVIATABS: {
      return {
        ...state,
        getSellerDataList: action.payload,
      };
    }
    default:
      return state;

      
  }
};


