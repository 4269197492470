import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import yearsToMonths from "date-fns/fp/yearsToMonths/index";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RedButton } from "../../../utility/Button";
import { SearchFilter } from "../../../utility/functions/functions";
import Styles from "./styles/table.module.css";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#323546",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row({ item, handleShow }) {
  return (
    <>
      <TableRow sx={{ backgroundColor: "#323546" }}>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.state_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.total_tickets}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.total_open}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.total_urgent}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          <RedButton size="small" onClick={() => handleShow(item)}>
            Details
          </RedButton>
        </TableCell>
      </TableRow>
    </>
  );
}

const TicketMgtTable1 = ({ From, To, handleShow }) => {
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.Ticket);

  const rs = document.querySelector(":root");
  const R = getComputedStyle(rs);
  const bgColor = R.getPropertyValue("--color-light-background");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const column = ["name", "mobile"];
  const [text, setText] = useState("");

  const [list, setList] = useState([
    { state: "Maharastra", zone: "Mumbai", total: 30, open: 23, urgent: 1 },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(store?.stateZoneTicketList,"Ticketopklm")

  return (
    <div>
      <TableContainer
        sx={{
          minHeight: 440,
          minWidth: "100%",
          marginTop: "5px",
          backgroundColor: bgColor,
        }}
        component={Paper}
        className={Styles.TableContainer}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={Styles.head}
            sx={{
              backgroundColor: "#1B1D23",
            }}
            size="small"
          >
            <TableRow
              className={Styles.head}
              sx={{
                backgroundColor: "#1B1D23",
                overflowX: "auto",
                overflowY: "auto",
              }}
              size="small"
            >
              <StyledTableCell align="center" size="small">
                State
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Zone
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Total
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Open
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Urgent
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{ minHeight: "400px", backgroundColor: "#323546 !important" }}
          >
            {store?.stateZoneTicketList?.length !== 0 ? (
              <>
                {store?.stateZoneTicketList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <Row item={item} handleShow={handleShow} />
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  *No Rules*
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={store?.stateZoneTicketList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TicketMgtTable1;
