import { Alert, Button, Card, CircularProgress, Dialog, Divider, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GetColor } from '../../../utility/functions/functions';
import { StyledTableCell } from '../../../utility/TextField';
import { IOSSwitch } from '../../../utility/IosSwitch';
import { TableCellMui } from '../../../utility/Table';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { LaunchUseContext } from '../../Context/LaunchContext';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate';
import { GetLaunchZoneCount, LaunchKycList, UpdateLaunchStatusBySc } from '../../../actions/Launch/LaunchAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GETLAUNCHKYCLIST, GETLAUNCHZONECOUNT, UPDATELAUNCHIDSTATUS } from '../../../utility/ReduxConstants/LaunchReducerConstant';
import { useNavigate, useParams } from 'react-router';
import { GetLocalStorageByKey, GetParams, StoreInLocalStorage } from './launchUtility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ImagePopUpModal, PopUpforApi } from '../../../utility/Backdrop';
import { CardWrapperSmall } from '../CardWrapper';
import { BsCheckCircleFill } from 'react-icons/bs';
import { IoIosCloseCircle } from 'react-icons/io';
import Styles from './launch.module.css'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';


const LaunchedListTable = () => {
  const [popUp, setPopup] = useState(false)
  const store = useSelector(state => state.Launch)
  const [list, setList] = useState([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const BgColor = GetColor('--color-light-background');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setList(store?.launch_list ?? [])
  }, [store?.launch_list])

  console.log(list,"consoledList")

  const [alert, setAlert] = useState({
    success: false,
    message: '',
    loading: true
  })
  let [Pincode, setPincode] = useState({
    pincode: false,
    pincodeLoading: true
  });

  let [Category, setCategory] = useState({
    category: false,
    categoryLoading: true
  });

  let [Sku, setSku] = useState({
    sku: false,
    skuLoading: true
  });

  let [ppdp, setPpdp] = useState({
    ppdp: false,
    ppdpLoading: true
  })

  const HandlePopup = (val) => {
    if (!val) {
      setAlert({
        ...Alert,
        loading: true,
        success: false,
        message: ''
      })
      setPincode({
        pincode: false,
        pincodeLoading: true
      })
      setCategory({
        category: false,
        categoryLoading: true
      })
      setSku({
        sku: false,
        skuLoading: true
      })
      setPpdp({
        ppdp: false,
        ppdpLoading: true
      })
    }
    setPopup(val)
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Stack padding={1}>
        <TableContainer sx={{
          maxHeight: 496, minWidth: "100%", backgroundColor: BgColor,
          xs: { minWidth: 100 }, sm: { minWidth: 300 }, md: { minWidth: 300 }
        }} component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead size='small' sx={{
              '& .MuiTableCell-head': {
                backgroundColor: "#1B1D23 !important",
                color: "white !important"
              }
            }
            }>
              <TableRow sx={{ backgroundColor: "#1B1D23" }} size='small'>
                <StyledTableCell align='center' size="small">State</StyledTableCell>
                <StyledTableCell align='center' size='small' >Zone</StyledTableCell>
                <StyledTableCell align='center' size='small' >Kyc</StyledTableCell>
                <StyledTableCell align='center' size='small' >Super Categpry</StyledTableCell>
                <StyledTableCell align='center' size='small' >Active Users</StyledTableCell>
                <StyledTableCell align='center' size='small'>No. Of Quotes</StyledTableCell>
                <StyledTableCell align='center' size='small'>No. Of Users Quoted</StyledTableCell>
                <StyledTableCell align='center' size='small'>Launch</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                list?.map(item =>
                  <Row
                    item={item}
                    handlePopup={HandlePopup}
                    Alert={alert}
                    setAlert={setAlert}
                    Pincode={Pincode}
                    setPincode={setPincode}
                    Category={Category}
                    setCategory={setCategory}
                    Sku={Sku}
                    setSku={setSku}
                    ppdp={ppdp}
                    setPpdp={setPpdp}
                  />
                )
              }


            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            color: "white !important",
            '& .MuiSvgIcon-root': {
              color: 'white'
            },
          }}
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={store?.launch_list?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>

      <PopUpforApi open={popUp}>

        <Dialog
          fullScreen={fullScreen}
          open={popUp}
          onClose={() => HandlePopup(false)}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              backgroundColor: BgColor,
              color: "white",
              minWidth: "500px",
              minHeight: "300px",
              direction: "column",
              alignItems: "space-between",
              justifyContent: "space-between"
            },
          }}

          sx={{}}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end"  zIndex={999}>
              <IconButton onClick={()=>HandlePopup(false)}>
                <IoIosCloseCircle className={Styles.animate} size={30} style={{ color: "white" }} />
              </IconButton>
            </Stack>
            <Stack padding="10px 50px 20px 50px">
              <Stack direction="column" >
                <Stack flex={1} gap={1.5}>
                  <Stack flex={1}>
                    <Typography variant="caption">Checking the requirements to launch</Typography>
                  </Stack>

                  <Stack gap={0.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1} >
                      <Typography variant="caption">Pincode</Typography>
                      <IconDisplayer Loading={Pincode?.pincodeLoading} Success={Pincode?.pincode} Error={!Pincode?.pincode} />
                    </Stack>
                    <Divider sx={{ borderColor: "white" }} />
                  </Stack>

                  <Stack gap={0.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
                      <Typography variant="caption">Category</Typography>
                      <IconDisplayer Loading={Category?.categoryLoading} Success={Category?.category} Error={!Category?.category} />
                    </Stack>
                    <Divider sx={{ borderColor: "white" }} />
                  </Stack>

                  <Stack gap={0.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
                      <Typography variant="caption">SKU</Typography>
                      <IconDisplayer Loading={Sku?.skuLoading} Success={Sku?.sku} Error={!Sku?.sku} />
                    </Stack>
                    <Divider sx={{ borderColor: "white" }} />
                  </Stack>

                  <Stack gap={0.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
                      <Typography variant="caption">PTR, PTW, Delivery and Pickup Lots</Typography>
                      <IconDisplayer Loading={ppdp?.ppdpLoading} Success={ppdp?.ppdp} Error={!ppdp?.ppdp} />
                    </Stack>
                    <Divider sx={{ borderColor: "white" }} />
                  </Stack>
                </Stack>

              </Stack>
            </Stack>
          </Stack>

          {!alert?.loading && <Alert severity={alert?.success ? "success" : "error"}>{alert?.message}</Alert>}
        </Dialog>

      </PopUpforApi>
    </>
  )
}

export default LaunchedListTable


const Row = (props) => {
  const {
    item,
    handlePopup,
    Alert,
    setAlert,
    Pincode,
    setPincode,
    Category,
    setCategory,
    Sku,
    setSku,
    ppdp,
    setPpdp
  } = props
  const localData = GetLocalStorageByKey('launch_param')
  const Axios = useAxiosPrivate()
  const dispatch = useDispatch()
  const { handleShowUserTable, handleShowKyc } = LaunchUseContext()
  const [Obj, setObj] = useState({})

  useEffect(() => {
    setObj(item)
  }, [item])
  const handleChecked = async (e) => {
    handlePopup(e.target.checked)
    let param = {
      launch_id: Obj?.launch_id ?? 0,
      zone_name: Obj?.zone_name ?? '',
      sc_id: Obj?.sc_id ?? 0,
      status: e.target.checked,
      state_id: localData?.state_id,
      zone_id: localData?.zone_id
    }
    const data = await dispatch(UpdateLaunchStatusBySc(Axios, param))
    console.log(data, param);

    // let data = {
    //   code : 200,
    //   message : "sss"
    // }
    console.log(!e.target.checked ? "true" : "false", "stattus", e.target.checked)

    if (!e.target.checked) {
      if (data?.code === 200) {
        const Data = await dispatch(GetLaunchZoneCount(Axios, param))
        if (Data.code === 200) {
          dispatch({
            type: GETLAUNCHZONECOUNT,
            payload: Data.data
          })

          setTimeout(() => {
            setAlert({
              ...Alert,
              loading: false,
              success: true,
              message: data?.message
            })

          }, [5000])
          setTimeout(() => {
            handlePopup(false);
          }, [6500])
          setTimeout(() => {
            setPincode({
              pincode: true,
              pincodeLoading: false
            })
          }, [1000])
          setTimeout(() => {
            setCategory({
              category: true,
              categoryLoading: false
            })
          }, [2000])
          setTimeout(() => {
            setSku({
              sku: true,
              skuLoading: false
            })
          }, [3000])
          setTimeout(() => {
            setPpdp({
              ppdp: true,
              ppdpLoading: false
            })
          }, [4000])
          setObj({
            ...Obj,
            launch_status: !e.target.checked
          })
        }
      } else {
        if (data?.code === 400) {
          let { pincode, category, sku, ppdp } = data;

          setObj({
            ...Obj,
            launch_status: false
          })
          if (!pincode) {
            setTimeout(() => {
              setPincode({
                pincode: false,
                pincodeLoading: false
              })
              setAlert({
                ...Alert,
                loading: false,
                success: false,
                message: data?.err
              })
            }, [1000])
       }
          else {
            if (!category) {
              setTimeout(() => {
                setPincode({
                  pincode: true,
                  pincodeLoading: false
                })
              }, [1000])
              setTimeout(() => {
                setCategory({
                  category: false,
                  categoryLoading: false
                })
                setAlert({
                  ...Alert,
                  loading: false,
                  success: false,
                  message: data?.err
                })
              }, [2000])
            }
            else {
              if (!sku) {
                setTimeout(() => {
                  setPincode({
                    pincode: true,
                    pincodeLoading: false
                  })
                }, [1000])
                setTimeout(() => {
                  setCategory({
                    category: true,
                    categoryLoading: false
                  })
                }, [2000])
                setTimeout(() => {
                  setSku({
                    sku: false,
                    skuLoading: false
                  })
                  setAlert({
                    ...Alert,
                    loading: false,
                    success: false,
                    message: data?.err
                  })
                }, [3000])
              }
              else {
                if (!ppdp) {
                  setTimeout(() => {
                    setPincode({
                      pincode: true,
                      pincodeLoading: false
                    })
                  }, [1000])
                  setTimeout(() => {
                    setCategory({
                      category: true,
                      categoryLoading: false
                    })
                  }, [2000])
                  setTimeout(() => {
                    setSku({
                      sku: true,
                      skuLoading: false
                    })
                  }, [3000])
                  setTimeout(() => {
                    setPpdp({
                      ppdp: false,
                      ppdpLoading: false
                    })
                    setAlert({
                      ...Alert,
                      loading: false,
                      success: false,
                      message: data?.err
                    })
                  }, [4000])
                }
              }
            }
          }}
      }
    } else {
      if (data?.code === 200) {

        toast.success("Updated Status Successfully for the supercategory");
        setObj({
          ...Obj,
          launch_status: !e.target.checked
        })
      } else {
        toast.error("Failed To Update Status for the supercategory")
      }
    }

  }



  const handleKyc = async () => {

    let param = {
      zone_name: Obj?.zone_name ?? '',
      sc_id: Obj?.sc_id ?? 0
    }
    console.log(param,"panmrjlkdjf")
    const Data = await dispatch(LaunchKycList(Axios, param))

    if (Data.code === 200) {
      dispatch({ type: GETLAUNCHKYCLIST, payload: Data?.data })
    } else {
      console.log("No list kyc")
    }
    localData['changed'] = true;
    StoreInLocalStorage(localData, 'launch_param')
    setTimeout(() => {
      handleShowKyc(true);
    }, [300])
  }

  const handleUserTable = () => {
    localData['changed'] = true;
    StoreInLocalStorage(localData, 'launch_param')
    handleShowUserTable(true)
  }

  return (
    <>
      <TableRow >
        <TableCellMui align="center">{Obj?.state_name}</TableCellMui>
        <TableCellMui align="center">{Obj?.zone_name}</TableCellMui>
        <TableCellMui align="center" onClick={handleKyc}
          sx={{
            '&: hover': {
              cursor: "pointer"
            }
          }}
        >
          <Card component={Paper} sx={{ backgroundColor: "#323546", color: "white", padding: "5px" }}>
            <Stack direction="row" justifyContent="center" alignItems='center'>
              {Obj?.kyc_count} <KeyboardArrowRightIcon />
            </Stack>
          </Card>
        </TableCellMui>
        <TableCellMui align="center">{Obj?.super_category_name}</TableCellMui>
        <TableCellMui align="center">{Obj?.active_users}</TableCellMui>
        <TableCellMui align="center">{Obj?.no_of_quotes}</TableCellMui>
        <TableCellMui
          align="center"
          onClick={handleUserTable} sx={{
            '&: hover': {
              cursor: "pointer"
            }
          }}>
          <Stack direction="row" justifyContent="center">
            <Card component={Paper} sx={{ backgroundColor: "#323546", color: "white", padding: "5px" }}>
              <Stack direction="row" justifyContent="center" alignItems='center'>
                {Obj?.no_of_users}<KeyboardArrowRightIcon />
              </Stack>
            </Card>
          </Stack>

        </TableCellMui>
        <TableCellMui align="center"><IOSSwitch checked={Obj?.launch_status ? true : false} onChange={handleChecked} /></TableCellMui>
      </TableRow>
    </>
  )
}


const IconDisplayer = (props) => {
  let { Loading, Success, Error } = props
  return (
    <>
      {
        Loading ? <CircularProgress size={20} />
          :
          <>
            {
              Success && <BsCheckCircleFill className={Styles.animate} size={23} style={{ color: "greenyellow" }} />
            }{
              Error && <IoIosCloseCircle className={Styles.animate} size={23} style={{ color: "red" }} />
            }
          </>
      }
    </>

  )
}