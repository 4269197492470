// export const baseUrl = "http://localhost:4001/vtrnliveweb/";
// export const defaultImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/company/1651214958987-5771.jpg";
// export const defaultFirmImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/default/default.jpg";
// export const baseImgUploadUrl = "http://localhost:4001/vtrnliveweb/";
// export const baseImgPath = "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com";
// export const AppBaseUrl =
//   "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/";

// //////////////dev///////////////////////

export const baseUrl = "http://vitaranlive.com:4001/vtrnliveweb/";
export const defaultFirmImg =
  "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/default/default.jpg";
// export const defaultFirmImg = "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/New+Doc+07-21-2022+12.02.pdf";
export const defaultImg =
  "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/company/1651214958987-5771.jpg";
export const baseImgUploadUrl = "http://vitaranlive.com:4001/vtrnliveweb/";
export const baseImgPath = "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com";
export const AppBaseUrl =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/";

////////////////prod///////////////////////
// export const baseUrl = "http://15.206.185.75:4002/vtrnliveweb/";
// export const defaultFirmImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/default/default.jpg";s
// export const defaultImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/company/1651214958987-5771.jpg";
// export const baseImgUploadUrl = "http://15.206.185.75:4002/vtrnliveweb/";
// export const baseImgPath = "https://vtrn-live-prod.s3.ap-south-1.amazonaws.com";
// export const AppBaseUrl =
//   "https://7db97mgvq5.execute-api.ap-south-1.amazonaws.com/vtrn_live_prod/";

// ////////////////abhishek local server///////////////////////
// export const baseUrl = "http://192.168.1.36:4001/vtrnliveweb/";
// export const defaultFirmImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/default/default.jpg";
// export const defaultImg =
//   "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/company/1651214958987-5771.jpg";
// export const baseImgUploadUrl = "http://192.168.1.36:4001/vtrnliveweb/";
// export const baseImgPath = "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com";
// export const AppBaseUrl =
//   "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/";
