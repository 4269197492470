import { Checkbox, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderCountMap from "./OrderCountMap";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import DeliveryBoyList from "./DeliveryBoyList";
import { useSelector } from "react-redux";
import OrderMarker from "./OrderMarker";
import MenuIcon from "@mui/icons-material/Menu";
import DataDrawer from "./DataDrawer";
import { GetData, MapMarkersList } from "./MapUtilityFile";

const Map = () => {
  const [open, setOpen] = useState(false);
  const [map, setMap] = useState(null);
  const MapData = useSelector((state) => state.MapReducer);
  const [center, setCenter] = useState({
    lat: 20.593684,
    lng: 78.96288,
  });
  const key = `${process.env.REACT_APP_MAP_KEY}`;
  const containerStyle = {
    width: "100%",
    height: "75.7vh",
  };

  const HandleSideDrawerOrder = () => {
    setOpen(!open);
  };

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (MapData?.orders?.length !== 0) {
        MapData?.orders?.forEach((item) => {
          let NewLat = {
            lat: parseFloat(item?.lat),
            lng: parseFloat(item?.long),
          };
          bounds.extend(NewLat);
        });
        map.fitBounds(bounds);
      }
    }
  }, [map, MapData?.orders]);

  return (
    <Stack>
      
      <OrderCountMap />

      <Stack sx={{ marginTop: "1rem" }}>
        <LoadScript
          googleMapsApiKey={key || process.env.REACT_GOOGLE_MAP_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5}
            onLoad={onLoad}
          >
            <OrderMarker HandleSideDrawerOrder={HandleSideDrawerOrder} />
          </GoogleMap>
        </LoadScript>
      </Stack>

      <DeliveryBoyList />
      <DataDrawer open={open} setOpen={setOpen} MapData={MapData}  />
    </Stack>
  );
};

export default Map;
