import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import YourDealsBuyerHeader from "./YourDealsBuyerHeader";
import YourDealsSellerHeader from "./YourDealsSellerHeader";
import YourDealsBuyerCard from "./YourDealsBuyerCard";
import YourDealsSellerCard from "./YourDealsSellerCard";
import { useDispatch, useSelector } from "react-redux";
import {
  GETYOURDEALSBUYERDATALISTVIATABS,
  GETYOURDEALSSELLERDATALISTVIATABS,
} from "../../../utility/ReduxConstants/YourDealsReducerConstant";
import { SnackbarProvider } from "notistack";
import TodayCounter from "./TodayCounter";
import {
  GetYourDealsTabsCountBuyer,
  GetYourDealsTabsCountSeller,
} from "../../../actions/yourDeals/YourDealsAction";
import { YourDealsTabsCountUrl } from "./YourDealsUtilityFile";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";

const YourDeals = () => {
  const [tabsCountBuyer, seTabsCountBuyer] = useState([]);
  const [tabsCountSeller, seTabsCountSeller] = useState([]);
  const [tabsCountBuyerHeader, seTabsCountBuyerHeader] = useState(null);
  const [tabsCountSellerHeader, seTabsCountSellerHeader] = useState(null);

  const LambdaAxios = useAxiosLambda();
  const dispatch = useDispatch();

  const handleTabsCountBuyer = async () => {
    const Data = await dispatch(
      GetYourDealsTabsCountBuyer(LambdaAxios, YourDealsTabsCountUrl)
    );

    console.log(JSON.stringify(Data) + "wodsjfjdsf")
    if (Data?.status === 200 && Data?.data?.body?.buyer) {
      seTabsCountBuyer(Data?.data?.body?.buyer);
      seTabsCountBuyerHeader(Data?.data?.body?.buyer?.total_order);
    }
  };

  const handleTabsCountSeller = async () => {
    const Data = await dispatch(
      GetYourDealsTabsCountSeller(LambdaAxios, YourDealsTabsCountUrl)
    );

    if (Data?.status === 200 && Data?.data?.body?.seller) {
      seTabsCountSeller(Data?.data?.body?.seller);
      seTabsCountSellerHeader(Data?.data?.body?.seller?.total_order);
    }
  };
  useEffect(() => {
    handleTabsCountBuyer();
  }, []);
  useEffect(() => {
    handleTabsCountSeller();
  }, []);

  const [typeBuyer, setTypeBuyer] = useState({
    count: tabsCountBuyer?.today,
    filterName: "today",
    name: "TODAY",
  });
  const [typeSeller, setTypeSeller] = useState({
    count: tabsCountSeller?.today,
    filterName: "today",
    name: "TODAY",
  });

  const [listTypeBuyer, setListTypeBuyer] = useState([
    { count: tabsCountBuyer?.today, filterName: "today", name: "TODAY" },
    {
      count: tabsCountBuyer?.confirmed,
      filterName: "confirmed",
      name: "CONFIRMED",
    },
    {
      count: tabsCountBuyer?.on_the_way,
      filterName: "on_the_way",
      name: "ON_THE_WAY",
    },
    {
      count: tabsCountBuyer?.completed,
      filterName: "completed",
      name: "COMPLETED",
    },
    {
      count: tabsCountBuyer?.cancelled,
      filterName: "cancelled",
      name: "CANCELLED",
    },
  ]);

  const [listTypeSeller, setListTypeSeller] = useState([
    { count: tabsCountSeller?.today, filterName: "today", name: "TODAY" },
    {
      count: tabsCountSeller?.confirmed,
      filterName: "confirmed",
      name: "CONFIRMED",
    },
    {
      count: tabsCountSeller?.on_the_way,
      filterName: "on_the_way",
      name: "ON_THE_WAY",
    },
    {
      count: tabsCountSeller?.completed,
      filterName: "completed",
      name: "COMPLETED",
    },
    {
      count: tabsCountSeller?.cancelled,
      filterName: "cancelled",
      name: "CANCELLED",
    },
  ]);
  console.log(listTypeBuyer, "handleTabsCountBuyer3");
  console.log(listTypeBuyer?.count, "handleTabsCountBuyer3");
  console.log(tabsCountBuyer?.today, "handleTabsCountBuyer3");

  const handleTypeBuyer = (e) => {
    setTypeBuyer(e);

    console.log(e, "handleTypeBuyer");

    dispatch({
      type: GETYOURDEALSBUYERDATALISTVIATABS,
      payload: e,
    });
  };

  useEffect(() => {
    dispatch({
      type: GETYOURDEALSBUYERDATALISTVIATABS,
      payload: typeBuyer,
    });
  }, []);

  const handleTypeSeller = (e) => {
    setTypeSeller(e);
    console.log(e, "handleTypeSeller");
    dispatch({
      type: GETYOURDEALSSELLERDATALISTVIATABS,
      payload: e,
    });
  };

  useEffect(() => {
    dispatch({
      type: GETYOURDEALSSELLERDATALISTVIATABS,
      payload: typeSeller,
    });
  }, []);

  useEffect(() => {
    setListTypeBuyer([
      { count: tabsCountBuyer?.today, filterName: "today", name: "TODAY" },
      {
        count: tabsCountBuyer?.confirmed,
        filterName: "confirmed",
        name: "CONFIRMED",
      },
      {
        count: tabsCountBuyer?.on_the_way,
        filterName: "on_the_way",
        name: "ON THE WAY",
      },
      {
        count: tabsCountBuyer?.completed,
        filterName: "completed",
        name: "COMPLETED",
      },
      {
        count: tabsCountBuyer?.cancelled,
        filterName: "cancelled",
        name: "CANCELLED",
      },
    ]);
  }, [tabsCountBuyer]);

  useEffect(() => {
    setListTypeSeller([
      { count: tabsCountSeller?.today, filterName: "today", name: "TODAY" },
      {
        count: tabsCountSeller?.confirmed,
        filterName: "confirmed",
        name: "CONFIRMED",
      },
      {
        count: tabsCountSeller?.on_the_way,
        filterName: "on_the_way",
        name: "ON THE WAY",
      },
      {
        count: tabsCountSeller?.completed,
        filterName: "completed",
        name: "COMPLETED",
      },
      {
        count: tabsCountSeller?.cancelled,
        filterName: "cancelled",
        name: "CANCELLED",
      },
    ]);
  }, [tabsCountSeller]);

  return (
    <>
      {/* <TodayCounter /> */}
      <Stack
        direction={{ xs: "column", lg: "row" }}
        sx={{ color: "white" }}
        gap={3}
        mt={"1rem"}
      >
        <Stack
          id="Buyer"
          sx={{ backgroundColor: "#3B405E", borderRadius: "6px" }}
          // maxWidth={{ lg: "38rem" }}
          minWidth={{ md: "33rem", lg: "38rem" }}
        >
          <Stack id="Buyerheader">
            <SnackbarProvider>
              <YourDealsBuyerHeader
                tabsCountBuyerHeader={tabsCountBuyerHeader}
              />
            </SnackbarProvider>
          </Stack>
          <Stack id="Buyertabs">
            <Stack
              sx={{
                backgroundColor: "#3B405E",
                borderRadius: "5px",
                overflow: "hidden",
                padding: "0px 20px 20px 20px",
              }}
              gap={2}
            >
              <Stack
                direction="row"
                sx={{ paddingTop: "1rem" }}
                overflow={{ xs: "scroll", lg: "hidden" }}
                justifyContent={"space-between"}
              >
                {listTypeBuyer?.map((item, index) =>
                  item?.name === typeBuyer?.name ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        backgroundColor: "#525980",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Stack
                        direction="column"
                        gap={1}
                        padding={2}
                        sx={{
                          borderBottom: "3px solid red",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1">
                          {item?.count ?? 0}
                        </Typography>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleTypeBuyer(item)}
                    >
                      <Stack
                        direction="column"
                        gap={1}
                        padding={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1">{item?.count}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack id="Buyercard" padding={"5px"}>
            <Stack>
              <YourDealsBuyerCard />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          id="Seller"
          sx={{ backgroundColor: "#3B405E", borderRadius: "6px" }}
          // maxWidth={{ lg: "38rem" }}
          minWidth={{ md: "33rem", lg: "38rem" }}
        >
          <Stack id="Sellerheader">
            <YourDealsSellerHeader
              tabsCountSellerHeader={tabsCountSellerHeader}
            />
          </Stack>
          <Stack id="Sellerrtabs">
            <Stack
              sx={{
                backgroundColor: "#3B405E",
                borderRadius: "5px",
                overflow: "hidden",
                padding: "0px 20px 20px 20px",
              }}
              gap={2}
            >
              <Stack
                direction="row"
                sx={{ paddingTop: "1rem" }}
                overflow={{ xs: "scroll", lg: "hidden" }}
                justifyContent={"space-between"}
              >
                {listTypeSeller?.map((item, index) =>
                  item?.name === typeSeller?.name ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        backgroundColor: "#525980",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Stack
                        direction="column"
                        gap={1}
                        padding={2}
                        sx={{
                          borderBottom: "3px solid red",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1">
                          {item?.count ?? 0}
                        </Typography>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleTypeSeller(item)}
                    >
                      <Stack
                        direction="column"
                        gap={1}
                        padding={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1">
                          {item?.count ?? 0}
                        </Typography>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack id="Sellercard" padding={"5px"}>
            <YourDealsSellerCard />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default YourDeals;
