import { Button, TextField } from '@mui/material';
import React,{useState,useEffect} from 'react';
import Styles from './styles/UpdateProduct.module.css';
import images from '../../../../utility/constants/images';
import { styled } from '@mui/material/styles';
import { useSelector,useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseImgUploadUrl,baseImgPath } from '../../../../utility/BaseUrl';
import { UpdateProductData,RemoveProductData } from '../../../../actions/product/productAction';


const viewImgPath = `${baseImgPath}/product/`;

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));


const RedBorderButton = styled(Button)(({ theme }) => ({
    color: "#DC1D13",
    backgroundColor: "#323546",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#fff",
    },
}));

const UpdateProduct = () => {
   const store  = useSelector(state=>state.Product);
   const [file, setFile] = useState('');
   const [img,setImg] = useState('');
   const [addsuccess, setAddsuccess] = useState(false)
   const [failed, setFailed] = useState({
     addFailed: false,
     addFailedMessage: ""
    });
const [error,setError]=useState({})
    const [addsuccessrm, setAddsuccessrm] = useState(false)
    const [failedrm, setFailedrm] = useState({
      addFailed: false,
      addFailedMessage: ""
     });
    const dispatch=useDispatch();
   

  const [form,setForm] = useState({
    item_id: 1,
    item_name: "",
    description: "",
    item_image: ""
  })

useEffect(()=>{
    if(store.product_detail!==undefined && store.product_detail!==null){
        const {item_id,item_name,description,item_image} = store.product_detail;
     
        setForm({
            ...form,
            item_id: item_id!==undefined && item_id!==null && item_id!==0 ? (parseInt(item_id)):(0),
            item_name: item_name!==undefined && item_name!==null && item_name.length!==0 ? (item_name):(""),
            description: description!==undefined && description!==null && description.length!==0 ? (description):(""),
            item_image: item_image!==undefined && item_image!==null && item_image.length!==0 ? (item_image):("")
        })
        const image = item_image!==undefined && item_image!==null && item_image.length!==0 ? (item_image):("")
        setImg(`${image}`)
    }
},[store.product_detail])

const handleChange=(e)=>{
    setForm({
        ...form,
        [e.target.name]:e.target.value
    })
}



const Authaxios = axios.create({
    baseURL:`${baseImgUploadUrl}`,
    headers: {
        "Content-type": "multipart/form-data"
      }
})

const imgUpload = async (formData) => {
 
    await axios.post(`${baseImgUploadUrl}image_upload_product`,formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }})
        .then(res => {
            console.log('res',res)
            if (res.data.code === 200) {
                toast.success("Uploaded SuccessFully")
                setImg(`${res.data.full_path}`);
            setForm({
                ...form,
                item_image: res.data.name
            })
            }
            else{
                 toast.error("Img Not Uploaded")
            }

        })
        .catch(err => console.log(err.message))
}

const ChooseImg =(event)=> {
    setFile(event.target.files[0]);

}

React.useEffect(() => {
    const formData = new FormData();
    formData.append('file', file);
    // formData.append("document", JSON.stringify({type:"company"}));
    imgUpload(formData)
}, [file]);

React.useEffect(()=>{
    if(addsuccess){
        toast.success("Updated Product Details SuccessFully");
        
        setAddsuccess(false);
    }
},[addsuccess])

React.useEffect(()=>{

if (failed.addFailed) {
    let text = failed.addFailedMessage;
    toast.error(`${text}`)

    setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: ''
    })
}

},[failed])

React.useEffect(()=>{
    if(addsuccessrm){
        toast.success("Removed Product Details SuccessFully");
        
        setAddsuccessrm(false);
    }
},[addsuccessrm])

React.useEffect(()=>{

if (failedrm.addFailed) {
    let text = failedrm.addFailedMessage;
    toast.error(`${text}`)

    setFailedrm({
        ...failedrm,
        addFailed: false,
        addFailedMessage: ''
    })
}

},[failedrm])

const IsValid = () => {
    let success = true;
    let err = {};

    if (!form.item_name.trim()) {
        err.productname = '**Product Name Cannot Be Empty**'
        success = false;
    }
    else if(!(/^[a-z0-9A-Z]([\w -]*[a-z0-9A-Z])?$/).test(form.item_name)){
        err.productname = '*No Special Characters Allowed! Please Enter Valid Name*'
        success = false
    }

    if (!img.trim()) {
        err.productimage = '**Please Choose a Image**'
        success = false;
    }

    if (!form.description.trim()) {
        err.description = '**Product Description Cannot Be Empty**'
        success = false;
    }

    setError(err)
    return success
}


const handleSubmit=()=>{
    if (IsValid()) {
    let param = {
        "type": "Product",
        "item_id":`${form.item_id}`,
        "item_name": `${form.item_name}`,
        "item_desc": `${form.description}`,
        "item_image":img
    }

     dispatch(UpdateProductData(param,setAddsuccess,failed, setFailed))
}
  
}

const handleRemove=()=>{
    console.log('here')
    let param={
        
            "type": "product",
            "item_id": `${form.item_id}`,
            "status": false
        
    }
    dispatch(RemoveProductData(param,setAddsuccessrm,failedrm, setFailedrm))
}
    console.log(form)
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
     
        <div className={Styles.Container}>
            <div className={Styles.TopContainer}>
                <div className={Styles.ImageContainer}>
                    <img src={img} alt="cigarete" />
                </div>
                <input type="file" accept="image/*" name="image-upload" id={`ProductImgUploadUpdate`} className={Styles.imginp}   onChange={ChooseImg}/>
                    <div className={Styles.ImageUploadContainer}>

                        <label className={Styles.ImageUploadButton} htmlFor={`ProductImgUploadUpdate`}>
                            UPLOAD
                        </label>
                    </div>
                    {error.productimage && <span className={Styles.error}>{error.productimage}</span>}
            </div>


            <div className={Styles.MidContainer}>
                 <div className={Styles.List}>
                     <div className={Styles.SkuContainer}>
                         <label className={Styles.SkuText}>Product Name :</label>
                     </div>
                     <div className={Styles.InputContainer}>
                         <input  className={Styles.Input} value={form.item_name} name="item_name" onChange={handleChange}/>
                         {error.productname && <span className={Styles.error}>{error.productname}</span>}
                     </div>
                 </div> 

                 <div className={Styles.List}>
                     <div className={Styles.SkuContainer}>
                         <label className={Styles.SkuText}>Description :</label>
                     </div>
                     <div className={Styles.InputContainer}>
                         <textarea   className={Styles.Input} rows="3" value={form.description} name="description" onChange={handleChange}/>
                         {error.description && <span className={Styles.error}>{error.description}</span>}
                     </div>
                 </div>
            </div>

            <div className={Styles.ButtonContainer}>
                <RedButton onClick={handleSubmit}>Update</RedButton>
                <RedBorderButton onClick={handleRemove}>Remove</RedBorderButton>
            </div>
        </div>
        </>
    )
}

export default UpdateProduct