export const GETSTATELIST = "GETSTATELIST";
export const GETSTATELISTNAME = "GETSTATELISTNAME";
export const GETHOLIDAYCALENDERDETAILS = "GETHOLIDAYCALENDERDETAILS";
export const GETFORMSTATUS = "GETFORMSTATUS";
export const GETHOLIDAYCALENDERDATE = "GETHOLIDAYCALENDERDATE";
export const GETALLHOLIDAYLIST = "GETALLHOLIDAYLIST";
export const GETHOLIDAYID = "GETHOLIDAYID";
export const GETEDITEDVALUE="GETEDITEDVALUE"
export const GETHOLIDAYBYSTATEID="GETHOLIDAYBYSTATEID"
export const GETALLVALUEDETAILS="GETALLVALUEDETAILS"
export const GETHOLIDAYBYSTATEIDANDYEAR="GETHOLIDAYBYSTATEIDANDYEAR"

