import {
  GETACTIVEUSERS,
  GETDISTRICTLISTDASHBOARD,
  GETORDERCOUNTDELIVERY,
  GETORDERCOUNTPICKUP,
  GETSCDASHBOARDDATA,
  GETSKUANALYTICSLISTDASHBOARD,
  GETSKUCATEGORYLISTDASHBOARD,
  GETSKUDISTRICTLISTDASHBOARD,
  GETSKUSCLISTDASHBOARD,
  GETSKUZONELISTDASHBOARD,
  GETSTATELISTDASHBOARD,
  GETTRANSACTIONDATA,
  GETZONELISTDASHBOARD,
} from "../../utility/ReduxConstants/DashboardReducerConstant";

const initialState = {
  stateList: [],
  zoneList: [],
  deliveryOrderCount: {},
  pickupOrderCount: {},
  activeUsers: {},
  transactionData: {},
  skuList: [],
  skuZoneList: [],
  skuScList: [],
  skuCategoryList: [],
  scDashboardList: [],
  districtList: [],
  skuDistrictList:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSTATELISTDASHBOARD:
      return {
        ...state,
        stateList: action.payload,
      };

    case GETZONELISTDASHBOARD:
      return {
        ...state,
        zoneList: action.payload,
      };

      case GETDISTRICTLISTDASHBOARD:
      return {
        ...state,
        districtList: action.payload,
      };

    case GETORDERCOUNTDELIVERY:
      return {
        ...state,
        deliveryOrderCount: action.payload,
      };

    case GETORDERCOUNTPICKUP:
      return {
        ...state,
        pickupOrderCount: action.payload,
      };

    case GETACTIVEUSERS:
      return {
        ...state,
        activeUsers: action.payload,
      };

    case GETTRANSACTIONDATA:
      return {
        ...state,
        transactionData: action.payload,
      };
    case GETSKUZONELISTDASHBOARD:
      return {
        ...state,
        skuZoneList: action.payload,
      };
      case GETSKUDISTRICTLISTDASHBOARD:
      return {
        ...state,
        skuDistrictList: action.payload,
      };
    case GETSKUSCLISTDASHBOARD:
      return {
        ...state,
        skuScList: action.payload,
      };
    case GETSKUCATEGORYLISTDASHBOARD:
      return {
        ...state,
        skuCategoryList: action.payload,
      };
    case GETSKUANALYTICSLISTDASHBOARD:
      return {
        ...state,
        skuList: action.payload,
      };
    case GETSCDASHBOARDDATA: {
      return {
        ...state,
        scDashboardList: action.payload,
      };
    }
    default:
      return state;
  }
};
