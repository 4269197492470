import { Card, Paper } from "@mui/material";
import React from "react";
import { GetColor } from "../../utility/functions/functions";
import { TableCell, TextField, tableCellClasses } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const BgColor = GetColor("--color-light-background");
const DarkBgColor = GetColor("--color-dark-background");
const TransactionBgColor = GetColor("--color-transaction-balance");

export const CardWrapper = styled(Card)({
  backgroundColor: BgColor,
  minHeight: "86vh",
  padding: "10px 20px",
});
export const CardWrapperSmall = styled(Card)({
  backgroundColor: BgColor,
  padding: "10px 20px",
});
export const CardWrapperImg = styled(Card)({
  padding: "5px",
  backgroundColor: DarkBgColor,
});

export const DarkCardWrapper = styled(Card)({
  backgroundColor: DarkBgColor,
});
export const TransactionBalanceCard = styled(Card)({
  backgroundColor: TransactionBgColor,
  padding: "10px",
});
