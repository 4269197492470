import { Box, Card, Collapse, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GetColor } from '../../../utility/functions/functions';
import { StyledTableCell } from '../../../utility/TextField';
import { IOSSwitch } from '../../../utility/IosSwitch';
import { TableCellMui } from '../../../utility/Table';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { LaunchUseContext } from '../../Context/LaunchContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { GetLaunchUserList } from '../../../actions/Launch/LaunchAction';
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate';
import { GETLAUNCHUSERCOUNTLIST } from '../../../utility/ReduxConstants/LaunchReducerConstant';
import styles from './table.module.css'
import { GetLocalStorageByKey } from './launchUtility';

const LaunchUserTable = () => {
  const localData= GetLocalStorageByKey('launch_param')
    const store = useSelector(state => state.Launch)
    const Axios = useAxiosPrivate()
    const dispatch = useDispatch()
    const { state_id, zone_id, zone_name, sc_id,changed } = useParams()
    const { handleShowUserTable } = LaunchUseContext()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const BgColor = GetColor('--color-light-background');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const GetUserList = async (param) => {
        const data = await dispatch(GetLaunchUserList(Axios, param))

        console.log(data, "GetUserList,GetLaunchUserList")

        if (data?.code === 200) {
            dispatch({ type: GETLAUNCHUSERCOUNTLIST, payload: data?.data })
        }
    }

    useEffect(() => {
        let param = {
            "sc_id": localData?.sc_id ?? 0,
            "zone_name": localData?.zone_name ?? ''

        }
        GetUserList(param)
    }, [sc_id, zone_name])

    useEffect(() => {
        let param = {
            "sc_id": localData?.sc_id ?? 0,
            "zone_name": localData?.zone_name ?? ''

        }
        GetUserList(param)
    }, [])
    return (
        <Card component={Paper} sx={{ backgroundColor: BgColor }}>
            <Stack padding={1}>
                <TableContainer sx={{ maxHeight: 496, minWidth: "100%", backgroundColor: BgColor, xs: { minWidth: 100 }, sm: { minWidth: 300 }, md: { minWidth: 300 } }} component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead size='small' sx={{
                            '& .MuiTableCell-head': {
                                backgroundColor: "#1B1D23 !important",
                                color: "white !important"
                            }
                        }
                        }>
                            <TableRow sx={{ backgroundColor: "#1B1D23" }} size='small'>
                                <StyledTableCell align='center' size="small"></StyledTableCell>
                                <StyledTableCell align='center' size="small">Name</StyledTableCell>
                                <StyledTableCell align='center' size='small' >Mobile</StyledTableCell>
                                <StyledTableCell align='center' size='small' >Super Categpry</StyledTableCell>
                                <StyledTableCell align='center' size='small'>Zone</StyledTableCell>
                                <StyledTableCell align='center' size='small'>No. Of Quotes</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                store?.launch_user_list?.map(item =>
                                    <Row item={item} />
                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        color: "white !important",
                        '& .MuiSvgIcon-root': {
                            color: 'white'
                        },
                    }}
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={store?.launch_user_list?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Stack>
        </Card>
    )
}

export default LaunchUserTable


const Row = ({ item }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <TableRow >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCellMui align="center">{item?.first_name + ' ' + item?.last_name}</TableCellMui>
                <TableCellMui align="center">{item?.mobile_no ?? ''}</TableCellMui>
                <TableCellMui align="center">{item?.zone_name}</TableCellMui>
                <TableCellMui align="center">{item?.super_category_name}</TableCellMui>
                <TableCellMui align="center">{item?.no_of_quotes}</TableCellMui>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Stack sx={{ color: "white" }} gap={1}>
                                <Stack direction="row" sx={{ backgroundColor: "black" }}>
                                    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                        <Typography>Sku Id</Typography>
                                    </Stack>
                                    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                        <Typography>Sku name</Typography>
                                    </Stack>
                                    <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                        <Typography>Created At</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} gap={1}>
                                {
                                            item?.quoted_list?.map(elem =>
                                    <Stack direction="row">
                                        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                            <Typography>{elem?.sku_id ?? 0}</Typography>
                                        </Stack>
                                        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                            <Typography>{elem?.sku_name ?? ''}</Typography>
                                        </Stack>
                                        <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                                            <Typography>{elem?.created_at ?? ''}</Typography>
                                        </Stack>
                                    </Stack>)
}
                                </Stack>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>

            </TableRow>
        </>

    )
}