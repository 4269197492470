import { GETIMAGEBANKCATEGORYLIST, GETIMAGEBANKLIST, GETIMAGEBANKSUPERCATEGORYLIST,IMAGEBANKRESET } from "../../utility/ReduxConstants/ImageBankReducerConstant"

const initialState={
    superCategoryList : [],
    categoryList : [],
    imageList : []
}

export default(state=initialState,action)=>{
    switch(action.type){
        case GETIMAGEBANKSUPERCATEGORYLIST:
            return {
                ...state,
                superCategoryList : action.payload!==undefined && action.payload!==null ? action.payload : []
            }
        case GETIMAGEBANKCATEGORYLIST:
            return {
                ...state,
                categoryList : action.payload!==undefined && action.payload!==null ? action.payload : []
            }
        case GETIMAGEBANKLIST:
            return {
                ...state,
                imageList : action.payload!==undefined && action.payload!==null ? action.payload : []
            }
        case IMAGEBANKRESET : 
            return {
                ...state,
                imageList : []
            }
        default :
        return state
    }
}