import React from "react";
import PartnerWalletUserCoinDetails from "./PartnerWalletUserCoinDetails";
import PartnerWalletUserTransactioinDropdown from "./PartnerWalletUserTransactioinDropdown";
import PartnerWalletUserTransactionTable from "./PartnerWalletUserTransactionTable";

const PartnerWalletUserTransactionHistory = () => {
  return (
    <>
      <PartnerWalletUserCoinDetails />
      <PartnerWalletUserTransactioinDropdown />
      <PartnerWalletUserTransactionTable />
    </>
  );
};

export default PartnerWalletUserTransactionHistory;
