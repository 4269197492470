import {
  GETCATEGORYLISTFOREXCEPTION,
  GETEXCEPTIONACCESSDISTRICTLIST,
  GETEXCEPTIONACCESSSCLIST,
  GETEXCEPTIONACCESSSTATELIST,
  GETEXCEPTIONACCESSZONELIST,
  GETEXCEPTIONDISTRCTLIST,
  GETEXCEPTIONSTATELIST,
  GETEXCEPTIONSUPERCATEGORYLIST,
  GETEXCEPTIONZONELIST,
  GETNEWEXCEPTIONDETAILBYZONE,
  GETNEWEXCEPTIONLISTBYZONE,
  GETRETAINVALUEFROMLOCAL,
  UPDATENEWSKUEXCEPTIONLIST,
} from "../../utility/ReduxConstants/SkuExceptionReducerConstant";

const initialState = {
  stateList: [],
  zoneList: [],
  accessStateList:[],
  accessZoneList:[],
  accessDistrictList:[],
  categoryList: [],
  exceptionList: [],
  exceptionDetail: {},
  superCategoryList: [],
  districtList: [],
  retainValueFromLocal:null,
  accessSuperCategoryList:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETEXCEPTIONSTATELIST:
      return {
        ...state,
        stateList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETEXCEPTIONDISTRCTLIST:
      return {
        ...state,
        districtList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETEXCEPTIONZONELIST:
      return {
        ...state,
        zoneList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETCATEGORYLISTFOREXCEPTION:
      return {
        ...state,
        categoryList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETNEWEXCEPTIONLISTBYZONE:
      return {
        ...state,
        exceptionList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
        exceptionDetail:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload[0]
            : {},
      };
    case GETNEWEXCEPTIONDETAILBYZONE:
      return {
        ...state,
        exceptionDetail:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload[0]
            : {},
      };

    case UPDATENEWSKUEXCEPTIONLIST:
      return {
        ...state,
        exceptionList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? state.exceptionList.map((item) =>
                item.sp_id === action.payload[0].sp_id
                  ? action.payload[0]
                  : item
              )
            : [],
      };
    case GETEXCEPTIONSUPERCATEGORYLIST:
      return {
        ...state,
        superCategoryList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
      case GETEXCEPTIONACCESSSCLIST:
        return {
          ...state,
          accessSuperCategoryList:
            action.payload !== undefined &&
            action.payload !== null &&
            action.payload.length !== 0
              ? action.payload
              : [],
        };
      case GETEXCEPTIONACCESSSTATELIST:
      return {
        ...state,
        accessStateList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
      case GETEXCEPTIONACCESSDISTRICTLIST:
      return {
        ...state,
        accessDistrictList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
      case GETEXCEPTIONACCESSZONELIST:
      return {
        ...state,
        accessZoneList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
      case GETRETAINVALUEFROMLOCAL:
        return {
          ...state,
          retainValueFromLocal:
            action.payload !== undefined && action.payload !== null
              ? action.payload
              : [],
        };

    default:
      return state;
  }
};
