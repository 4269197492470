export const RunTheDealsGetScCategoryList =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("superCategoryList");
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const RunTheDealsGetCategoryList =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("skuCategoryList", param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const RunTheDealsGetSkuNameList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("skuListByCategorySc", param);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const RunTheDealAddCutOffPartner =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("addCutOffPartner", param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const RunTheDealGetStateList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");
    
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const RunTheDealGetZoneList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllzoneList", param);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const GetStateListRun = (Axios) => async (dispatch) => {
  try {
    const Data = await Axios.post("AllstateList");
    
  } catch (error) {
    console.log(error);
  }
};
export const GetCutOffListAccess = (Axios) => async (dispatch) => {
  try {
    const Data = await Axios.post("skuCutOffList");
    
  } catch (error) {
    console.log(error);
  }
};
