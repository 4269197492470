import React from 'react'
import CalenderDropDown from './CalenderDropDown'
import CalenderTable from './CalenderTable'

const Calender = () => {
  return (
    <>
      <CalenderDropDown/>
      <CalenderTable/>
    </>
  )
}

export default Calender
