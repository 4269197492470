import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ConfirmationDialog = ({ open, onClose, onConfirm, ucr_id,type }) => {
  console.log(type,"walletManagement")
  return (
    <Dialog  open={open} onClose={onClose} PaperProps={{ style: { backgroundColor: '#323546' } }}>
      <DialogTitle sx={{color: 'white' } }>Confirm Approval</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color: 'white' } }>
          {type===1 ?"Are you sure you want to approve the request amount?":"Are you sure you want to decline the request amount?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{color: 'white' } }>Cancel</Button>
        <Button onClick={() => onConfirm(ucr_id) } autoFocus sx={{color: 'white' } }>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
