import { Call } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useDispatch, useSelector } from "react-redux";
import {
  GetYourDealsActionSeller,
  YourDealDb_List,
} from "../../../actions/yourDeals/YourDealsAction";
import { YourDealsUrl } from "./YourDealsUtilityFile";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import { GETYOURDEALSDATALISTSELLER } from "../../../utility/ReduxConstants/YourDealsReducerConstant";
import {
  GetMapUserData,
  Get_assign_delivery_boy_Action,
  Get_remove_delivery_boy_from_order_Action,
} from "../../../actions/Map/MapAction";
import { toast } from "react-toastify";
import {
  MAPURL,
  assign_delivery_boy_URL,
  remove_delivery_boy_from_order,
} from "../GoogleMap/MapUtilityFile";
import { GETMAPALLORDERLIST } from "../../../utility/ReduxConstants/MapReducerConstant";
import { json } from "react-router";

const YourDealsSellerCard = () => {
  const [data, setData] = useState([]);
  const [DB_NAME, setDB_NAME] = useState("");
  const YourDealsDbList = useSelector((state) => state.MapReducer);
  const dispatch = useDispatch();
  const LambdaAxios = useAxiosLambda();
  const YourDealsSellerData = useSelector((state) => state.YourDealsReducer);
  const YourDealsSellerDataList = useSelector(
    (state) => state.YourDealsReducer?.getSellerDataList
  );

  console.log(JSON.stringify(YourDealsSellerDataList) + "YourDealsSellerDataList")
  const Count =
    YourDealsSellerData?.yourDealsDataListSeller?.order_list?.length;

  console.log(Count, "Countinseller");

  const jdjjdbbun = YourDealsSellerData?.yourDealsDataListSeller?.order_list;

  console.log(YourDealsSellerData, "jdjjdbbun");

  const filterBy = YourDealsSellerDataList?.filterName;
  console.log(JSON.stringify(YourDealsSellerDataList)+ "filterBy")

  const GetYourDealsData = async () => {
    console.log(filterBy, "filterBySeller");
    let param = {
      trade_type: "sell",
      for: filterBy,
      
    };
    const Data = await dispatch(
      GetYourDealsActionSeller(LambdaAxios, YourDealsUrl, param)
    );

    console.log(JSON.stringify(Data) + "getYourDealsActionSeller")

    

    if (Data?.status === 200) {
      dispatch({
        type: GETYOURDEALSDATALISTSELLER,
        payload: Data?.data?.body,
      });
    }
  };

  useEffect(() => {
    GetYourDealsData();
  }, [YourDealsSellerData?.getSellerDataList]);

  const handleDirectionsClick = ({ lat, long }) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const url = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${lat},${long}`;
          window.open(url, "_blank");
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      toast.warning("Geolocation is not supported by this browser.");
    }
  };
  const get_Db_Data_For_YD = async () => {
    // let param = {
    //   db_id: id,
    // };
    const Data = await dispatch(YourDealDb_List(LambdaAxios, MAPURL));
    console.log(Data, "get_Db_Data_For_YD");
    if (Data?.status === 200) {
      setData(Data?.data?.db_list);
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }
  };

  useEffect(() => {
    get_Db_Data_For_YD();
  }, []);

  // useEffect(() => {
  //   setData(YourDealsDbList);
  // }, [YourDealsDbList]);

  useEffect(() => {
    setDB_NAME();
  }, []);

  const handleAssignDeliveryBoy = async ({ event, item, index }) => {
    console.log("handleAssignDeliveryBoy", index);
    // const value = event.target.value;
    // setDB_NAME(value);

    // console.log(
    //   "handleAssignDeliveryBoy",
    //   getBuyerDataList?.yourDealsDataList.order_list[index]
    // );

    // GetYourDealsData();
  };

  const deliveryBoy_Id = [
    {
      db_id: "-1",
      name: "MySelf",
    },
    ...data,
  ];

  const Db_Id_data = deliveryBoy_Id?.map((item) => item);
  console.log(Db_Id_data, deliveryBoy_Id, "handleGetdb_idInYourDeals3");

  const handleGetdb_id = async ({ i, e, order }) => {
    console.log(i, e, order, "handleGetdb_idInYourDeals");
    if (e?.name === "MySelf") {
      let param = {
        order_id: order,
      };
      const Data = await dispatch(
        Get_remove_delivery_boy_from_order_Action(
          LambdaAxios,
          remove_delivery_boy_from_order,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_remove_delivery_boy_from_order_Action(
            LambdaAxios,
            remove_delivery_boy_from_order,
            param
          )
        );
        GetYourDealsData();
      } else {
        toast.error(Data?.message);
      }
      console.log(Data, "inhandleGetdb_id");
    } else {
      let param = {
        order_id: order,
        db_id: e?.db_id,
      };
      console.log(param, "inpparam");
      const Data = await dispatch(
        Get_assign_delivery_boy_Action(
          LambdaAxios,
          assign_delivery_boy_URL,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_assign_delivery_boy_Action(
            LambdaAxios,
            assign_delivery_boy_URL,
            param
          )
        );
        GetYourDealsData();
      } else {
        toast.warning(`failed to fetch Assign Delivery Partner`);
      }
      console.log(Data, "FetchingDataInDataDrawrDdetaills Partner");
    }
    const id = YourDealsDbList?.id;
    let param = {
      db_id: id,
    };
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param));
    if (Data?.data?.code === 200) {
      dispatch({
        type: GETMAPALLORDERLIST,
        payload: Data?.data,
      });
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }

    // const filterBy = getBuyerDataList?.getBuyerDataList.filterName;
    // console.log(filterBy, "filterBy");
    // let param3 = {
    //   trade_type: "buy",
    //   for: filterBy,
    // };
    // const Data2 = await dispatch(
    //   GetYourDealsActionBuyer(LambdaAxios, YourDealsUrl, param3)
    // );

    // if (Data2?.status === 200) {
    //   dispatch({
    //     type: GETYOURDEALSDATALISTBUYER,
    //     payload: Data2?.data?.body,
    //   });
    //   console.log(Data2, "GetYourDealsData2");
    // }s
  };

  // useEffect(() => {
  //   setDB_NAME(""); // Reset the value when the component re-renders
  // }, [YourDealsSellerDataList?.getSellerDataList]);

  // for formating 24h time to 12hr time wise
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${day} ${month} ${year}, ${time}`;
  };

  return (
    <>
      {Count === 0 ? (
        <DataCountShowDiv />
      ) : (
        <Stack>
          {YourDealsSellerData?.yourDealsDataListSeller?.order_list?.map(
            (item, index) => {
              return (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  gap={4}
                  sx={{ backgroundColor: "#2B2942" }}
                  padding={"5px 10px"}
                  margin={"auto"}
                  borderRadius={"6px"}
                  mb={"1rem"}
                >
                  <Stack>
                    <Stack sx={{ paddingBottom: "1rem" }}>
                      <Typography sx={{ fontSize: "13px" }}>
                        {item?.order_code}
                      </Typography>
                    </Stack>
                    <Stack sx={{ paddingBottom: "10px" }}>
                      <Typography>{item?.sku_name}</Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Stack>
                        <img
                          style={{
                            width: "95px",
                            paddingRight: "10px",
                            paddingBottom: "10px",
                            height: "92px",
                          }}
                          src={item?.sku_image}
                          alt={item?.sku_name}
                        />
                      </Stack>
                      <Stack direction={"column"}>
                        <Stack
                          sx={{
                            color: "black",
                            borderRadius: "50px",
                            backgroundColor:
                              item?.trade_order_type === "pickup"
                                ? "#F3D848"
                                : "#70B0FB",
                            width: "240px",
                            height: "20px",
                            alignItems: "center",
                          }}
                          gap={5}
                        >
                          <Typography
                            textAlign={"center"}
                            alignItems={"center"}
                            sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                            direction={"row"}
                          >
                            <Stack direction={"row"} gap={1}>
                              <Stack>
                                <Typography
                                  sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                                >
                                  {item?.lots} Lots |{" "}
                                </Typography>
                              </Stack>
                              <Stack>
                                <Typography
                                  sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                                  textTransform={"capitalize"}
                                >
                                  {item?.trade_order_type} |
                                </Typography>
                              </Stack>
                              <Stack>
                                <Typography
                                  sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                                >
                                  {item?.lot_contain} pkt per lot
                                </Typography>
                              </Stack>
                            </Stack>
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          sx={{
                            justifyContent: "space-between",
                            marginTop: "7px",
                          }}
                        >
                          <Stack sx={{ paddingRight: "1rem" }}>
                            <Stack>
                              <Typography
                                sx={{ fontSize: "13px", textAlign: "center" }}
                              >
                                Price per packet:
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                sx={{ fontSize: "13px", textAlign: "center" }}
                              >
                                {`₹ ${item?.trade_price}`}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            sx={{
                              borderLeft: "1px solid white",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "13px" }}
                              >
                                Price per Lot:
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "13px" }}
                              >
                                {`₹ ${item?.lot_price}`}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        backgroundColor: "#68669E",
                        padding: "10px 10px",
                        borderRadius: "5px",
                        marginTop: "1rem",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{
                          justifyContent: "space-between",
                          borderBottom: "1px solid white",
                        }}
                        mb={"3px"}
                      >
                        <Stack>
                          <Typography sx={{ fontSize: "13px" }}>
                            Total Quantity
                          </Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <Stack>
                            <Typography sx={{ fontSize: "13px" }}>
                              {/* 100 pkts*5 lotes */}
                              {`${item?.lot_contain} Pkt*${item?.lots} = `}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                paddingLeft: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {`${item?.lot_contain * item?.lots} Pkt`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Stack
                          direction={"row"}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Stack>
                            <Typography sx={{ fontSize: "13px" }}>
                              Total Order Amount
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography sx={{ fontSize: "13px" }}>
                              {`₹ ${item?.total_order_price}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        color: "#C8C3FF",
                        fontSize: "13px",
                      }}
                    >
                      {filterBy === "completed"
                        ? `Completed on ${formatDate(item?.order_completed_at)}`
                        : filterBy === "cancelled"
                        ? `Cancelled on ${formatDate(
                            item?.order_cancelled_at
                          )} `
                        : item?.order_status === "completed"
                        ? `Completed on ${formatDate(item?.order_completed_at)}`
                        : item?.order_status === "cancelled"
                        ? `Cancelled on ${formatDate(item?.order_cancelled_at)}`
                        : `To be Completed by ${formatDate(
                            item?.order_schedule_datetime
                          )}`}
                    </Stack>
                    <Stack
                      sx={{
                        display: filterBy === "today" ? "flex" : "none",
                        marginBottom: "1rem",
                        color: "#C8C3FF",
                        fontSize: "13px",
                      }}
                    >
                      <Stack>
                        Order Status :- {"  "}
                        {filterBy === "today" ? item?.order_status : ""}
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        marginBottom: "1rem",
                        color: "#C8C3FF",
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      {(filterBy === "today" &&
                        item?.order_status === "cancelled") ||
                      filterBy === "cancelled"
                        ? ` Cancel Reason : ${item?.cancel_reason}`
                        : ""}
                    </Stack>
                  </Stack>
                  <Stack flex={0.5}>
                    <Stack
                      direction={"row"}
                      sx={{
                        backgroundColor: "#46446C",
                        padding: "5px",
                        borderRadius: "6px",
                        justifyContent: "space-between",
                      }}
                      gap={2}
                    >
                      <Stack>
                        <img
                          style={{
                            width: "38px",
                            height: "38px",
                            borderRadius: "100px",
                          }}
                          src={item?.profile_image}
                          alt="img"
                        />
                      </Stack>
                      <Stack>
                        <Stack>
                          <Typography sx={{ fontSize: "13px" }}>
                            {item?.sb_name}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Box
                            sx={{
                              "& > legend": { mt: 2 },
                            }}
                          >
                            <Rating
                              name="disabled"
                              value={item?.avg_rating}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack justifyContent={"flex-end"}>
                        <IconButton
                          sx={{
                            backgroundColor: "#312F4B",
                            "&:hover": {
                              backgroundColor: "#312F4B",
                            },
                          }}
                          onClick={() => {
                            const phoneNumber =
                              item?.trade_order_type === "delivery"
                                ? item?.db_id === null
                                  ? item?.call
                                  : item?.db_call
                                : item?.trade_order_type === "pickup"
                                ? item?.call
                                : "";
                            if (phoneNumber) {
                              navigator.clipboard.writeText(phoneNumber);
                              toast.success(
                                "Phone number copied to clipboard",
                                {
                                  variant: "success",
                                }
                              );
                            } else {
                              toast.warning("Phone number not available", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          <Tooltip
                            title={
                              item?.trade_order_type === "delivery"
                                ? item?.db_id === null
                                  ? item?.call
                                  : item?.db_call
                                : item?.trade_order_type === "pickup"
                                ? item?.call
                                : ""
                            }
                          >
                            <Call sx={{ color: "white" }} />
                          </Tooltip>
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Stack sx={{ paddingTop: "1rem" }}>
                      <Typography sx={{ fontSize: "13px" }}>
                        Scheme <br />{" "}
                        {item?.scheme.length === 0
                          ? "No scheme available"
                          : item?.scheme}
                      </Typography>
                    </Stack>
                    <Stack direction={"column"} sx={{ paddingTop: "1rem" }}>
                      <Stack>
                        <Typography sx={{ color: "#F3D848", fontSize: "15px" }}>
                          {item?.trade_order_type === "delivery"
                            ? "Delivery Address "
                            : "Pick Up Address "}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Stack>
                          <Typography
                            sx={{ fontSize: { xs: "15px", lg: "11px" } }}
                          >
                            {item?.address}
                          </Typography>
                        </Stack>
                        <Stack sx={{ cursor: "pointer" }}>
                          <DirectionsIcon
                            sx={{ fontSize: { xs: "30px", lg: "20px" } }}
                            onClick={() =>
                              handleDirectionsClick({
                                lat: item?.lat,
                                long: item?.long,
                              })
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{ paddingTop: "1rem" }}
                      gap={5}
                    >
                      {item?.trade_order_type === "pickup" ? (
                        ""
                      ) : (
                        <Stack sx={{ fontSize: "13px" }}>
                          {item?.trade_order_type === "pickup"
                            ? "Pickup"
                            : "Delivery"}{" "}
                          Partner
                        </Stack>
                      )}

                      <Stack>
                        {item?.trade_order_type === "pickup" ? (
                          ""
                        ) : (
                          <Stack>
                            <FormControl fullWidth>
                              {/* <InputLabel id="demo-simple-select-label">
                                Select
                              </InputLabel> */}
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={
                                //   DB_NAME === null ||
                                //   DB_NAME === undefined ||
                                //   DB_NAME === ""
                                //     ? item?.name === null ||
                                //       item?.name === undefined
                                //       ? "MySelf"
                                //       : item?.name
                                //     : DB_NAME
                                // }
                                disabled={
                                  filterBy === "completed" ||
                                  filterBy === "cancelled" ||
                                  (filterBy === "today" &&
                                    item?.order_status === "cancelled") ||
                                  (filterBy === "today" &&
                                    item?.order_status === "completed")
                                    ? true
                                    : false
                                }
                                value={
                                  item?.name === null ||
                                  item?.name === undefined
                                    ? "MySelf"
                                    : item?.name
                                }
                                onChange={(event) =>
                                  handleAssignDeliveryBoy({
                                    event,
                                    item,
                                    index,
                                  })
                                }
                                sx={{
                                  width: "8rem",
                                  backgroundColor: "#595C9B",
                                  borderRadius: "0",
                                  borderColor: "white",
                                }}
                                size="small"
                              >
                                {Db_Id_data?.map((e) => {
                                  return (
                                    <MenuItem
                                      onClick={(i) =>
                                        handleGetdb_id({
                                          item: i,
                                          e: e,
                                          order: item?.order_id,
                                        })
                                      }
                                      value={e?.name}
                                    >
                                      {e?.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
          )}
        </Stack>
      )}
    </>
  );
};

export default YourDealsSellerCard;

const DataCountShowDiv = () => {
  return <Stack sx={{ color: "white", textAlign: "center" }}>No Orders</Stack>;
};
