import {
  Button,
  Card,
  CardMedia,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/KycImageViewer.module.css";
import { styled } from "@mui/material/styles";
import { MakeKycVerified, RejectKyc } from "../../../actions/kyc/kycAction";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const DisabledButton = styled(Button)(({ theme }) => ({
  color: "Black",
  backgroundColor: "#F0F0F0",
  border: "2px solid #F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
}));

const KycImageViewer = ({ OBJ, TYPE, filter }) => {
  const Axios = useAxiosPrivate();
  const [remarks, setRemarks] = useState("");
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Kyc);

  let textfield = {
    style: { backgroundColor: "rgb(50, 50, 50)" },
  };
  const [error, setError] = useState({});

  const IsValid = () => {
    let error = {};
    let success = true;

    if (!remarks.trim()) {
      error.remarks = "**Please Add a Remark!!**";
      success = false;
    }

    setError(error);
    return success;
  };

  const handleAccept = async () => {
    if (IsValid()) {
      let param = {
        user_id: store?.kycDetail?.user_id,
        zone_name: store?.kycDetail?.zone_name,
        kyc_remarks: remarks,
        kyc_id: store?.kycDetail?.kyc_id,
      };

      const data = await dispatch(MakeKycVerified(Axios, param));

      if (data?.state) {
        setRemarks("");
        toast.success(`${data?.message}`);
      } else {
        toast.error(`${data?.message}`);
      }
      // dispatch(MakeKycVerified(param))
    }
  };

  const handleReject = async () => {
    if (IsValid()) {
      let param = {
        user_id: store?.kycDetail?.user_id,
        zone_name: store?.kycDetail?.zone_name,
        kyc_remarks: remarks,
        kyc_id: store?.kycDetail?.kyc_id,
      };

      const data = await dispatch(RejectKyc(Axios, param));

      if (data?.state) {
        setRemarks("");
        toast.success(`${data?.message}`);
      } else {
        toast.error(`${data?.message}`);
      }
    }
  };

  let Disable = false;

  if (filter?.filterName === "login_completed") {
    Disable = true;
  } else if (filter?.filterName === "users") {
    Disable = true;
  } else if (filter?.filterName === "signup_completed") {
    Disable = true;
  } else {
    Disable = false;
  }

  console.log(Disable, "pdf");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack gap={1}>
        <Card>
          <CardMedia
            component="img"
            image={OBJ}
            height="310px"
            sx={{
              objectFit: "contain",
              // aspectRatio: "16/9"
            }}
          />
        </Card>
      </Stack>

      {/* <div className={Styles.ButtonContainer}>
            {
                Disable ? null : (
                   <a type="submit" download={`Image.${TYPE}`} target='_blank' href={OBJ} className={Styles.Button} >
                    Download Image
                </a> 
                )
            }
           
                
            </div> */}
    </>
  );
};

export default KycImageViewer;
