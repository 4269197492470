import One from "../../../assets/marker_location/1.png";
import Two from "../../../assets/marker_location/2.png";
import Three from "../../../assets/marker_location/3.png";
import Four from "../../../assets/marker_location/4.png";
import Five from "../../../assets/marker_location/5.png";
import Six from "../../../assets/marker_location/6.png";
import Seven from "../../../assets/marker_location/7.png";
import Eight from "../../../assets/marker_location/8.png";
import Nine from "../../../assets/marker_location/9.png";
import Ten from "../../../assets/marker_location/10.png";
import Eleven from "../../../assets/marker_location/11.png";
import Twelve from "../../../assets/marker_location/12.png";
import Thirteen from "../../../assets/marker_location/13.png";
import Fourteen from "../../../assets/marker_location/14.png";
import Fifteen from "../../../assets/marker_location/15.png";
import Sixteen from "../../../assets/marker_location/16.png";
import Seventeen from "../../../assets/marker_location/17.png";
import Eighteen from "../../../assets/marker_location/18.png";
import Nineteen from "../../../assets/marker_location/19.png";
import Twenty from "../../../assets/marker_location/20.png";

import MySelfBlack from "../../../assets/marker_location/0Myself.png";
import MySelfBlue from "../../../assets/marker_location/delivery.png";
import MySelfYellow from "../../../assets/marker_location/pickup.png";

export let MAPURL =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/map-all-order-list";

export const assign_delivery_boy_all_check_URL =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/assign-delivery-boy-all-check";
export const assign_delivery_boy_URL =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/assign-delivery-boy";

export const assign_delivery_boy_All_Url =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/assign-delivery-boy-all";

export const remove_delivery_boy_from_order =
  "https://gfiqbvi5yg.execute-api.ap-south-1.amazonaws.com/vtrn_live_dev/remove-delivery-boy-from-order";

export let MySelfBlackIcon = MySelfBlack;
export let MySelfBlueIcon = MySelfBlue;
export let MySelfYellowIcon = MySelfYellow;

export const List_Colors = [
  "rgba(148, 0, 211, 1)", //voilet
  "rgba(229, 43, 80, 1)", //Reddish Pink
  "rgba(109, 53, 26, 1)", //brown
  "rgba(191, 148, 228, 1)", //light purple
  "rgba(255, 153, 102, 1)", //skin COlour
  "rgba(255, 61, 0, 1)",
  "rgba(52, 73, 94, 1)",
  "rgba(204, 85, 0, 1)",
  "rgba(138, 51, 36, 1)",
  "rgba(255, 193, 204, 1)",
  "rgba(231, 254, 255, 1)",
  "rgba(222, 184, 135, 1)",
  "rgba(189, 51, 164, 1)",
  "rgba(252, 142, 172, 1)",
  "rgba(139, 0, 139, 1)",
  "rgba(255, 140, 0, 1)",
  "rgba(233, 150, 122, 1)",
  "rgba(255, 183, 197, 1)",
  "rgba(137, 63, 69, 1)",
  "rgba(189, 183, 107, 1)",
];

export const LocationIconList = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty,
];

function Markers() {
  let Arr = [];
  for (let i = 0; i < 20; i++) {
    let obj = {
      icon: LocationIconList[i],
      color: List_Colors[i],
    };
    Arr.push(obj);
  }

  return Arr;
}

export const MapMarkersList = Markers();

const ScatterOrder = (lat, lng) => {
  let newLat = parseFloat(lat);
  let newLng = parseFloat(lng);
  const min = -10;
  const max = 10;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const randomInt = Math.floor(Math.random() * 6);
  if (randomInt === 0) {
    return {
      lat: newLat + randomNumber * 0.000009,
      lng: newLng - randomNumber * 0.000009,
    };
  } else if (randomInt === 1) {
    return {
      lat: newLat - randomNumber * 0.000009,
      lng: newLng + randomNumber * 0.000009,
    };
  } else if (randomInt === 2) {
    return {
      lat: newLat - randomNumber * 0.000009,
      lng: newLng - randomNumber * 0.000009,
    };
  } else {
    return {
      lat: newLat + randomNumber * 0.000009,
      lng: newLng + randomNumber * 0.000009,
    };
  }
};
const CalculateBounds = (markers) => {
  var lngs = markers.map((m) => parseFloat(m.lng));
  var lats = markers.map((m) => parseFloat(m.lat));
  let leftMost = Math.min(...lats);
  let rightMost = Math.max(...lats);
  let topMost = Math.max(...lngs);
  let bottomMost = Math.min(...lngs);
  let bounds = {
    northeast: { rightMost, topMost },
    southwest: { leftMost, bottomMost },
  };
  return bounds;
};
export const GetData = (ORDERS) => {
  let LatLongObj = {};
  for (let item of ORDERS) {
    let LAT = item?.lat;
    let LNG = item?.long;
    let KEY = `${LAT}${LNG}`;
    if (!LatLongObj[KEY]) {
      LatLongObj[KEY] = {
        MyLatLng: {
          lat: parseFloat(LAT),
          lng: parseFloat(LNG),
        },
      };
    }
  }
  let NewLatLong = [];
  for (let item of ORDERS) {
    let LAT = item?.lat;
    let LNG = item?.long;
    let KEY = `${LAT}${LNG}`;
    let USERKEY = `${item?.userID}`;
    if (!LatLongObj[KEY][USERKEY]) {
      let SCAT_LAT = item?.lat;
      let SCAT_LNG = item?.long;
      LatLongObj[KEY][USERKEY] = {};
      let OBJ = LatLongObj[KEY][USERKEY];
      let Position = ScatterOrder(SCAT_LAT, SCAT_LNG);
      NewLatLong.push(Position);
      OBJ.latlng = Position;
      OBJ["orders"] = [];
      OBJ["orders"] = [...OBJ.orders, item];
      LatLongObj[KEY][USERKEY] = OBJ;
    } else {
      let OBJ = LatLongObj[KEY][USERKEY]["orders"];
      LatLongObj[KEY][USERKEY]["orders"] = [...OBJ, item];
    }
  }
  LatLongObj.Bounds = CalculateBounds(NewLatLong);
  return LatLongObj;
};
