import React, { useState } from "react";
import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  AutocompleteTextFieldNormal,
  DateTextFieldCalenderWallet,
} from "../../../../utility/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";

const WalletTransactionDropdown = () => {
  const [stateName,setStateName]=useState(["All State"])
  const [districtName,setDistrictName]=useState(["All District"])
  const [zoneName,setZoneName]=useState(["All Zone"])
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Link to="/home/wallet">
              <Stack>
                <ArrowBackIosIcon sx={{ color: "#FFFFFF" }} />
              </Stack>
            </Link>
            <Stack>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: "1.5rem",
                  font: "roboto",
                }}
              >
                Transaction History
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Stack
            flex={1}
            direction={{ xs: "column", sm: "row" }}
            spacing={0.5}
            padding={1}
            gap={1}
            sx={{ display: "flex" }}
          >
            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={stateName.length>0 ?stateName: []}
              options={stateName}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleState}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="State"
                  placeholder="Select State.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={districtName.length>0 ?districtName: []}
              options={districtName}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleYear}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="District"
                  placeholder="Select District.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={zoneName.length>0 ?zoneName: []}
              options={zoneName}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleYear}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Zone"
                  placeholder="Select Zone.."
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default WalletTransactionDropdown;
