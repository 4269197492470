import React from "react";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";

const PartnerWalletUserCoinDetails = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.8rem",
          marginLeft: "0.8rem",
        }}
      >
        <Link to="/home/access/wallet"><ArrowBackIosIcon sx={{color:"#FFFFFF"}}/></Link>
        <Typography sx={{ color: "#FFFFFF" }}>Ramesh Kumar</Typography>
        <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
        <Typography sx={{ color: "#D4D4D4" }}>+914875554512</Typography>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <Typography sx={{ color: "#D4D4D4" }}>Wallet Balance:</Typography>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={GoldLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
            4,50,000
          </Typography>
          <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={SilverLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
            4,50,000 (GST)
          </Typography>
          <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={SilverLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
            4,50,000(Free)
          </Typography>
        </Stack>

        <Stack>
          <button
            style={{
              background: "#DC1D13",
              padding: "0.5rem",
              color: "#FFFFFF",
              border: "0.1rem solid #DC1D13",
            }}
          >
            Send Coins
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PartnerWalletUserCoinDetails;
