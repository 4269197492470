import { toast } from "react-toastify";
import {
  GETALLHOLIDAYLIST,
  GETHOLIDAYBYSTATEID,
  GETHOLIDAYBYSTATEIDANDYEAR,
  GETSTATELIST,
} from "../../utility/ReduxConstants/HolidayCalenderReducerConstant";

export const CalenderStateList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");
    console.log(data, "stateList");

    if (data.code === 200) {
      dispatch({ type: GETSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const AddHolidayCalenderList = (Axios, param) => async (dispatch) => {
  console.log(param, "stateList");
  try {
    const { data } = await Axios.post("addHoliday", param);
    console.log(data, "zoneList");
    if (data.code === 200) {
      // dispatch({ type: GETSTATELIST, payload: data.stateList });
      return data;
      console.log("success");
    }
  } catch (error) {
    console.log(error);
  }
};

export const CalenderHolidayList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("allHolidayList");
    console.log(data, "stateList");

    if (data.code === 200) {
      dispatch({ type: GETALLHOLIDAYLIST, payload: data.holidayList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const CalenderHolidayListByState =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("holidayByState", param);
      console.log(data, "stateList");

      if (data.code === 200) {
        dispatch({
          type: GETHOLIDAYBYSTATEID,
          payload: data.holidayListByState,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const CalenderHolidayListByStateAndYear =
  (Axios, param) => async (dispatch) => {
    console.log(param)
    try {
      const { data } = await Axios.post("holidayByStateIdAndYear", param);
      console.log(data, "stateList");

      if (data.code === 200) {
        dispatch({
          type: GETHOLIDAYBYSTATEIDANDYEAR,
          payload: data.holidayByStateIdAndYear,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const DeleteHolidayCalender = (Axios, param) => async (dispatch) => {
  console.log(param, "stateList");
  try {
    const { data } = await Axios.post("deleteHoliday", param);

    if (data.code === 200) {
      // dispatch({ type: GETSTATELIST, payload: data.stateList });
      return data;
      console.log("success");
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateHolidayCalender = (Axios, param) => async (dispatch) => {
  console.log(param, "stateList");
  try {
    const { data } = await Axios.post("updateHoliday", param);

    if (data.code === 200) {
      // dispatch({ type: GETSTATELIST, payload: data.stateList });
      return data;
      console.log("success");
    }
  } catch (error) {
    console.log(error);
  }
};
