import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import AddIcon from "@mui/icons-material/Add";

import {
  Autocomplete,
  TextField,
  Button,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableBody,
  Typography,
  Card,
  Paper,
  IconButton,
  Collapse,
} from "@mui/material";
import { Stack } from "@mui/system";
import { GetColor } from "../../../utility/functions/functions";
import { CurvedRedButton } from "../../../utility/Button";
import Styles from "./styles/AddUserAccessZone.module.css";
import {
  AddUserAcccess,
  AllDistrictListUserAccess,
  AllZoneListUserAccess,
} from "../../../actions/UserAccess/UserAccessZoneAction";
import { GETALLDISTRICTLISTUSERACCESSCONSTFILTER, GETALLZONELISTUSERACCESSCONSTADD } from "../../../utility/ReduxConstants/UserAcessReducerConstant";
import { CardWrapper, DarkCardWrapper } from "../CardWrapper";
import { AutocompleteTextField } from "../../../utility/TextField";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UserAccessUpdate from "./UserAccessUpdate";

const AddUserAccessZone = ({
  handleUpdateOpen,
  handleFormOpen,
  UpdateOpen,
  formOpen,
}) => {
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Access);
  console.log(store, "wnswnlfslfjri")
  const Axios = useAxiosPrivate();
  const TitleColor = GetColor("--color-white");
  const AddBgColor = GetColor("--color-bg-add");
  const [obj, setObj] = useState({
    stateListAdd: [],
    zoneList: [],

    roleList: [],
    roleValue: "",
    roleId: 0,

    stateValue: "",
    stateId: "",
    zoneValue: "",
    zoneId: 0,

    superCategoryList: [],
    scValue: "",
    scId: "",

    districtListAdd:[],
    districtList:[],
    districtId: "",
    districtValue:""
  });
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    let List = store?.stateListAdd ?? [];
    let Arr = [];
    List.map((item) => {
      Arr.push(item?.state_name);
    });
    setObj({
      ...obj,
      stateListAdd: Arr,
      stateValue: "",
      stateId: 0,
    });
  }, [store?.stateListAdd]);

  useEffect(() => {
    let List = store?.districtListAdd ?? [];
    let Arr = [];
    List.map((item) => {
      Arr.push(item?.district_name);
    });
    setObj({
      ...obj,
      districtListAdd: Arr,
      districtValue: "",
      districtId: 0,
    });
  }, [store?.districtListAdd]);

  useEffect(() => {
    if (
      store.roleListAdd !== undefined &&
      store.roleListAdd !== null &&
      store.roleListAdd.length !== 0
    ) {
      let arr = [];

      for (let i = 0; i < store.roleListAdd.length; i++) {
        arr.push(store.roleListAdd[i].admin_role_name);
      }

      setObj({
        ...obj,
        roleList: arr,
        roleValue: "",
      });
    }
  }, [store.roleListAdd]);
  const IconColor = GetColor("--color-light-background");

  console.log(store.roleListAdd, "roleListAdd");

  useEffect(() => {
    if (
      store?.superCategoryListAdd !== undefined &&
      store?.superCategoryListAdd !== null &&
      store?.superCategoryListAdd.length !== 0
    ) {
      let arr = [];

      for (let i = 0; i < store?.superCategoryListAdd.length; i++) {
        arr.push(store?.superCategoryListAdd[i].name);
      }

      setObj({
        ...obj,
        superCategoryList: arr,
      });
    } else {
      console.log("something wents wrong in the super category list:- ");
    }
  }, [store?.superCategoryListAdd]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleRoleChange = (event, value) => {
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id;

      for (let i = 0; i < store.roleListAdd.length; i++) {
        if (value === store.roleListAdd[i].admin_role_name) {
          id = store.roleListAdd[i].admin_role_id;
        }
      }

      setObj({
        ...obj,
        roleValue: value,
        roleId: id,
        zoneValue: "",
        zoneId: 0,
        stateValue: "",
        stateId: 0,
        scValue: "",
        scId: 0,
      });
    }
  };

  useEffect(() => {
    if (
      store?.zoneListAdd !== undefined &&
      store?.zoneListAdd !== null &&
      store?.zoneListAdd.length !== 0
    ) {
      let arr = [];

      for (let i = 0; i < store?.zoneListAdd.length; i++) {
        arr.push(store?.zoneListAdd[i].zone_name);
      }

      setObj({
        ...obj,
        zoneList: arr,
        zoneValue: "",
        zoneId: 0,
      });
    }
  }, [store?.zoneListAdd]);

  const handleStateChange = async (event, value) => {
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id;
      for (let i = 0; i < store.stateListAdd.length; i++) {
        if (value === store.stateListAdd[i].state_name) {
          id = store.stateListAdd[i].state_id;
        }
      }

      setObj({
        ...obj,
        stateId: id,
        stateValue: value,
        districtList: [],
        districtValue: "",
        districtId: 0,
      });

      let param = {
        state_id: id,
      };

      const DistrictData = await dispatch(
        AllDistrictListUserAccess(Axios, param)
      );

      console.log(DistrictData, "ZoneData");

      if (DistrictData.code === 200) {
        dispatch({
          type: GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
          payload: DistrictData?.districtList,
        });
      }

      // if (DistrictData.code === 200) {
      //   dispatch({
      //     type: GETALLDISTRICTLISTUSERACCESSCONSTADD,
      //     payload: ZoneData?.zoneList,
      //   });
      // }
    }
  };

  const handleDistrictChange = async (event, value) => {
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id;
      for (let i = 0; i < store.districtListAdd?.length; i++) {
        if (value === store.districtListAdd[i]?.district_name) {
          id = store.districtListAdd[i]?.district_id;
        }
      }

      setObj({
        ...obj,
        districtId: id,
        districtValue: value,
        zoneList: [],
        zoneValue: "",
        zoneId: 0,
      });

      let param = {
        district_id: id,
      };

      const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));

      console.log(ZoneData, "ZoneData");

      if (ZoneData.code === 200) {
        dispatch({
          type: GETALLZONELISTUSERACCESSCONSTADD,
          payload: ZoneData?.zoneList,
        });
      }
    }
  };

  const handleSuperCategoryChange = (event, value) => {
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id;

      for (let i = 0; i < store?.superCategoryListAdd.length; i++) {
        if (value === store?.superCategoryListAdd[i].name) {
          id = store?.superCategoryListAdd[i].sc_id;
        }
      }

      setObj({
        ...obj,
        scValue: value,
        scId: id,
      });
    }
  };

  const handleZoneChange = (event, value) => {
    if (value !== undefined && value !== null && value.length !== 0) {
      let id;
      for (let i = 0; i < store?.zoneListAdd.length; i++) {
        if (value === store?.zoneListAdd[i].zone_name) {
          id = store?.zoneListAdd[i].zone_id;
        }
      }

      setObj({
        ...obj,
        zoneValue: value,
        zoneId: id,
      });

      console.log(value, id, "zone");
    }
  };

  const IsValid = () => {
    let success = true;
    let error = {};
    if (!name.trim()) {
      error.name = "This Field Cannot be empty!!";
      success = false;
    }

    let Mobile = parseInt(mobile) ?? 0;

    if (!Mobile?.toString()?.trim()) {
      error.mobile = "Mobile No.Cannot be empty!!";
      success = false;
    } else if (Mobile?.toString()?.length !== 10) {
      error.mobile = "Enter Valid mobile number!";
      success = false;
    } else if (!typeof Mobile === "number") {
      error.mobile = "Please Enter Only number!";
      success = false;
    }

    if (obj.roleId === 0) {
      toast.warning("Please select a role");
      success = false;
    }

    if (![1, 2].includes(obj.roleId)) {
      if (obj.scId === 0) {
        toast.warning("Please select a Super Category");
        success = false;
      }
      if (obj.stateId === 0) {
        toast.warning("Please select a State");
        success = false;
      }
      if (obj.zoneId === 0) {
        toast.warning("Please select a Zone");
        success = false;
      }
    }

    setError(error);
    return success;
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    let param = {
      name: name,
      mobile_no: mobile,
      state_id: obj?.stateId ?? 0,
      zone_id: obj?.zoneId ?? 0,
      sc_id: obj?.scId ?? 0,
      role_id: obj.roleId,
      district_id: obj?.districtId ?? 0,
    };

    console.log(param, "param");

    if (IsValid()) {
      const data = await dispatch(AddUserAcccess(Axios, param));
      console.log(data, "IsValid");

      if (data?.code === 201) {
        toast.success(`${data?.message}`);
        setObj({
          ...obj,
          roleValue: "",
          roleId: 0,
          zoneValue: "",
          zoneId: 0,
          stateValue: "",
          stateId: 0,
          scValue: "",
          scId: 0,
          districtValue:"",
          districtId:0,
        });
        setName("");
        setMobile("");
      } else {
        toast.error(`${data.message}`);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DarkCardWrapper
        sx={{
          padding: "10px",
          color: "white",
          borderRadius: "17px",
          height: "100%",
        }}
      >
        <Stack
          direction="column"
          sx={{ padding: "20px 10px 20px 10px", height: "100%" }}
          justifyContent={"space-between"}
          gap={2}
        >
          <Stack gap={2}>
            <Typography>ADD USER</Typography>
            <Collapse orientation="vertical" in={formOpen}>
              <Stack direction="column" gap={5}>
                <form onSubmit={handleAddUser}>
                  <Stack gap={2}>
                    <Stack>
                      <Autocomplete
                        value={obj.roleValue}
                        options={obj.roleList}
                        getOptionLabel={(option) =>
                          option?.replace(/_/g, " ")?.toUpperCase()
                        }
                        onChange={handleRoleChange}
                        className={Styles.autocomplete}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <AutocompleteTextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Role"
                          />
                        )}
                      />
                    </Stack>

                    <Stack>
                      {!["super_admin", "root"].includes(obj.roleValue) ? (
                        <Autocomplete
                          value={obj.stateValue}
                          options={obj.stateListAdd}
                          onChange={handleStateChange}
                          className={Styles.autocomplete}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <AutocompleteTextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="State"
                            />
                          )}
                        />
                      ) : null}
                    </Stack>

                    <Stack>
                      {!["super_admin", "root"].includes(obj.roleValue) ? (
                        <Autocomplete
                          value={obj.districtValue}
                          options={obj.districtListAdd}
                          onChange={handleDistrictChange}
                          className={Styles.autocomplete}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <AutocompleteTextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="District"
                            />
                          )}
                        />
                      ) : null}
                    </Stack>

                    <Stack>
                      {!["super_admin", "root"].includes(obj.roleValue) ? (
                        <Autocomplete
                          value={obj.zoneValue}
                          options={obj.zoneList}
                          onChange={handleZoneChange}
                          className={Styles.autocomplete}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <AutocompleteTextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="Zone"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "white",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      ) : null}
                    </Stack>

                    <Stack>
                      {!["super_admin", "root"].includes(obj.roleValue) ? (
                        <Autocomplete
                          value={obj.scValue}
                          options={obj.superCategoryList}
                          onChange={handleSuperCategoryChange}
                          className={Styles.autocomplete}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <AutocompleteTextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="Super Category"
                            />
                          )}
                        />
                      ) : null}
                    </Stack>

                    <Stack>
                      <AutocompleteTextField
                        label="Name"
                        size="small"
                        sx={{
                          minWidth: "250px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                        value={name}
                        autoComplete="off"
                        placeholder="Name"
                        onChange={handleNameChange}
                      />
                      {error.name && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          **{error.name}**
                        </Typography>
                      )}
                    </Stack>

                    <Stack>
                      <AutocompleteTextField
                        label="Mobile No."
                        size="small"
                        value={mobile}
                        sx={{
                          minWidth: "250px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                        autoComplete="off"
                        placeholder="Mobile No."
                        onChange={handleMobileChange}
                      />
                      {error.mobile && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          **{error.mobile}**
                        </Typography>
                      )}
                    </Stack>

                    <Stack sx={{ marginTop: "15px" }}>
                      <CurvedRedButton type="submit">Add</CurvedRedButton>
                    </Stack>
                  </Stack>{" "}
                </form>

                {/* <Stack>
<CardWrapper component={Paper} sx={{ zIndex: 9999 }} >
  <Stack sx={{ color: "white" }} direction="row" padding={1} justifyContent={"space-between"} alignItems="center">
    <Typography>Update User</Typography>
    <IconButton sx={{ color: "white" }} onClick={() => handleUpdateOpen(!UpdateOpen)}>
      {UpdateOpen && <KeyboardArrowRightIcon sx={{ fontSize: "20px", cursor: "pointer" }} />}
      {!UpdateOpen && <KeyboardArrowDownIcon sx={{ fontSize: "20px", cursor: "pointer" }} />}
    </IconButton>
  </Stack>

</CardWrapper>
<Collapse orientation="vertical" in={UpdateOpen}>
  <Stack>
    <UserAccessUpdate />
  </Stack>
</Collapse>
</Stack> */}
              </Stack>
            </Collapse>
          </Stack>

          <Stack justifyContent={"flex-end"}>
            <Stack direction="row" justifyContent={"center"}>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => handleFormOpen(!formOpen)}
              >
                {!formOpen && <KeyboardDoubleArrowDownIcon />}
                {formOpen && <KeyboardDoubleArrowUpIcon />}
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </DarkCardWrapper>
      <Stack
        sx={{
          position: "absolute",
          marginTop: { xs: "-10px", md: "-20px" },
          marginLeft: { xs: "60%", sm: "60%", md: "250px" },
        }}
        direction="row"
        justifyContent="flex-end"
      >
        <IconButton
          size="large"
          sx={{
            backgroundColor: IconColor,
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      </Stack>
    </>
  );
};

export default AddUserAccessZone;
