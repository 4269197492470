import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateTextField,
  DateTextFieldCalender,
} from "../../../utility/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  GETALLHOLIDAYLIST,
  GETFORMSTATUS,
  GETHOLIDAYCALENDERDATE,
  GETHOLIDAYCALENDERDETAILS,
} from "../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import {
  AddHolidayCalenderList,
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
} from "../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../Zone/styles/addZone.module.css";

const AddHoliday = () => {
  const Axios = useAxiosPrivate();
  const [error, setError] = useState({});
  const formStatus = useSelector(
    (store) => store.HolidayCalenderReducer.formStatus
  );

  const holidayDetails = useSelector(
    (store) => store.HolidayCalenderReducer.holidayCalenderDetails
  );
  const holidayDate = useSelector(
    (store) => store.HolidayCalenderReducer.holidayCalenderDate
  );
  const holidayDates = useSelector((store) => store.HolidayCalenderReducer);
  console.log(holidayDetails, "stateList");

  const dispatch = useDispatch();

  const eventRef = useRef(null);
  const [changedDate, setChangedDate] = useState(null);

  const IsValid = () => {
    let success = true;
    let err = {};

    if (holidayDate?.event_name == null || !holidayDate?.event_name.trim()) {
      err.event = "Event name cannot be empty";
      success = false;
    }

    if (holidayDate?.holiday_date == null) {
      err.date = "Please Select Date";
      success = false;
    }

    //   if (id === 0 || id === "" || id === undefined || id === null) {
    //     err.state = "Please Choose a State";
    //     success = false;
    //   }

    setError(err);
    console.log(err);
    console.log("Success:", success);
    return success;
  };

  console.log(error, "stateList");

  const handleStartDateChange = (newValue) => {
    console.log(newValue, "stateList");
    const dateObject = new Date(newValue);

    // Extract year, month, and day
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Note: Month is zero-indexed, so we add 1
    const day = dateObject.getDate();

    // Create a new string in the format 'YYYY-MM-DD'
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    setChangedDate(formattedDate);

    dispatch({
      type: GETHOLIDAYCALENDERDATE,
      payload: {
        holiday_date: formattedDate,
        event_name: eventRef.current.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await dispatch({
      type: GETHOLIDAYCALENDERDATE,
      payload: {
        holiday_date: changedDate,
        event_name: eventRef.current.value,
      },
    });
    if (IsValid()) {
      let param = {
        state_id: holidayDetails?.state_current_index,
        holiday_name: holidayDate?.event_name,
        holiday_date: holidayDate?.holiday_date,
      };

      const data=await dispatch(AddHolidayCalenderList(Axios, param));
      if (data.code === 200) {
        toast.success("SuccessFully Added!!");
      } else {
        toast.error("Failed to Add!!!");
      }

      let param2={
        state_id: holidayDetails?.state_current_index,
        holiday_date: holidayDetails?.year,
      }

      dispatch(CalenderHolidayListByStateAndYear(Axios,param2))

      // let param1 = {
      //   state_id: holidayDetails?.state_current_index,
      // };
      // dispatch(CalenderHolidayListByState(Axios, param1));

      // dispatch(CalenderHolidayList(Axios));
      dispatch({ type: GETFORMSTATUS, payload: false });
    }
    try {
      //   if (data.code === 200) {
      //     toast.success("SuccessFully Added!!");
      //   } else {
      //     toast.error("Failed To Add!!!");
      //   }

      
    } catch (error) {
      console.error("Error updating holiday calendar:", error);
    }
  };

  const handleCancel = () => {
    dispatch({ type: GETFORMSTATUS, payload: false });
  };

  useEffect(()=>{
    
  },[])

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black
          //   pointerEvents: "none",
        }}
      ></div>
      <Stack
        sx={{
          position: "absolute",
          gap: "0.7rem",
          background: "#323546",
          padding: "1.5rem",
          marginLeft:"-3rem",
          marginTop:"-2rem",
          zIndex:10
        }}
      >
        <h3 style={{ color: "white", fontWeight: 400 }}>Add New Holiday</h3>
        <Stack>
          <form onSubmit={handleSubmit}>
            <Stack
              sx={{ display: "flex", flexDirection: "row", gap: "1.1rem" }}
            >
              <Stack>
                <h4 style={{ color: "white", fontWeight: 400 }}>State</h4>
                <input
                  style={{
                    padding: "0.5rem",
                    background: "#323546",

                    borderColor: "#f0f0f0", // Lighter border color
                    color: "#a0a0a0", // Lighter text color
                  }}
                  value={holidayDetails.state_name}
                  type="text"
                  placeholder="Karnataka"
                  disabled="true"
                />
              </Stack>
              <Stack>
                <h4 style={{ color: "white", fontWeight: 400 }}>Year</h4>
                <input
                  style={{
                    padding: "0.5rem",
                    background: "#323546",
                    borderColor: "#f0f0f0", // Lighter border color
                    color: "#b0b0b0", // Lighter text color
                  }}
                  value={holidayDetails.year}
                  type="text"
                  placeholder="2023"
                  disabled="true"
                />
              </Stack>
            </Stack>
            <Stack sx={{ marginTop: "0.7rem" }}>
              <h4 style={{ color: "white", fontWeight: 400 }}>Event</h4>
              <input
                ref={eventRef}
                style={{
                  padding: "0.5rem",
                  background: "#323546",
                  borderColor: "white",
                  color: "white",
                }}
                type="text"
                placeholder="Enter Event name"
              />
              {error.event && (
                <span className={Styles.error}>*{error.event}*</span>
              )}
            </Stack>

            <Stack sx={{ marginTop: "0.7rem" }}>
              <h4 style={{ color: "white", fontWeight: 400 }}>Date</h4>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={holidayDate?.holiday_date || null}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  renderInput={(params) => (
                    <DateTextFieldCalender
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              {error.date && (
                <span className={Styles.error}>*{error.date}*</span>
              )}
            </Stack>
            <Stack
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "row",
                marginLeft: "15rem",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <button
                style={{
                  padding: "0.7rem",
                  background: "#323546",
                  color: "white",
                  borderColor: "white",
                  cursor: "pointer"
                }}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "0.9rem",
                  background: "#DC1D13",
                  color: "white",
                  border: "none",
                  cursor: "pointer"
                }}
                // onClick={handleSave}
                type="submit"
              >
                Save
              </button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </div>
  );
};
export default AddHoliday;
