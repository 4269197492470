import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Authaxios } from '../axios/axios';
import { LOGINDATA, NEWACCESSTOKEN } from '../utility/ReduxConstants/LoginReducerConstant';
import { baseUrl } from '../utility/BaseUrl';
import { Navigate, useNavigate } from 'react-router';
import Swal from 'sweetalert2';


const useRefreshToken = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const GETTOKEN = localStorage.getItem('refresh_token')

  const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
      "Authorization": `Bearer ${GETTOKEN}`
    }
  })

  const refresh = async () => {
    try {
      const { data } = await Axios.post('/getNewAccessToken');
      dispatch({ type: LOGINDATA, payload: data })
      return data?.access_token
    } catch (error) {
        console.log("error response", error)
        navigate('/Login')
    }
  }
  return refresh
}

export default useRefreshToken