import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { LoginButton } from "../../utility/Button";
import { CssTextField } from "../../utility/TextField";
import Swal from "sweetalert2";
import { GETCOLOR } from "../../utility/GetColorForJs";
import { useDispatch } from "react-redux";
import { SendOtpAction, VerifyOtpAction } from "../../actions/Login/login";

const ForgotPassword = ({ handleShowChange, setRootMobile }) => {
  const dispatch = useDispatch();
  const [sendLoader, setSendloader] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [Error, setError] = useState({});
  const [Otp, setOtp] = useState(false);
  const ERRORCOLOR = GETCOLOR("--color-login-error-text");

  const IsMobileValid = () => {
    let success = true;
    let error = {};

    if (!mobile.trim()) {
      error.mobile = "**Please Enter mobile no**";
      success = false;
    } else if (mobile?.length !== 0) {
      let mob = mobile;
      let Arr = mob.split("");
      if (Arr.length !== 10) {
        error.mobile = "**Please Enter a valid mobile no**";
        success = false;
      }
    }
    setError(error);
    return success;
  };
  const handleSendOtp = async () => {
    setSendloader(true);
    if (IsMobileValid()) {
      let param = {
        mobile_no: mobile,
      };
      const Data = await dispatch(SendOtpAction(param));

      if (Data.code === 200) {
        setSendloader(false);
        Swal.fire({
          title: "Success",
          text: "Otp Sent to number",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setOtp(true);
        }, [2000]);
      } else {
        setError({
          alert: Data.message,
        });
      }
    } else {
      setSendloader(false);
    }
  };

  const IsOtpValid = () => {
    let success = true;
    let error = {};

    if (!mobileOtp.trim()) {
      error.Otp = "**Please Enter Otp**";
      success = false;
    } else if (mobileOtp?.length !== 0) {
      let mob = mobileOtp;
      let Arr = mob.split("");
      if (Arr.length !== 6) {
        error.Otp = "**Please Enter a valid Otp**";
        success = false;
      }
    }
    setError(error);
    return success;
  };

  const handleVerifyOtp = async () => {
    setSendloader(true);
    if (IsOtpValid()) {
      let param = {
        mobile_no: mobile,
        otp: mobileOtp,
      };
      
      const Data = await dispatch(VerifyOtpAction(param));
      
      if (Data.code === 200) {
        setSendloader(false);
        Swal.fire({
          title: "Success",
          text: "Otp verified successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          handleShowChange(true);
        }, [2000]);
      } else {
        setError({
          alert: Data.message,
        });
      }
    } else {
      setSendloader(false);
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding={3}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>
          Forgot Password
        </Typography>
      </Stack>

      {Error?.alert && <Alert severity="error">{Error?.alert}</Alert>}
      {Otp ? (
        <>
          <Stack>
            <Stack sx={{ padding: "20px 20px" }}>
              <CssTextField
                label="Please Enter Your Otp"
                sx={{ minWidth: 300 }}
                type="text"
                value={mobileOtp}
                name="mobileOtp"
                onChange={(e) => setMobileOtp(e.target.value)}
              />
              {Error?.Otp && (
                <Typography
                  variant="caption"
                  sx={{ color: ERRORCOLOR, marginLeft: "20px" }}
                >
                  {Error?.Otp}
                </Typography>
              )}
            </Stack>
            <Stack sx={{ padding: "10px 20px" }}>
              <LoginButton
                onClick={handleVerifyOtp}
                endIcon={
                  sendLoader ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              >
                Verify Otp
              </LoginButton>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack>
          <Stack sx={{ padding: "20px 20px" }}>
            <CssTextField
              label="Please Enter You Number"
              sx={{ minWidth: 300 }}
              type="text"
              value={mobile}
              name="password"
              onChange={(e) => {
                setRootMobile(e.target.value);
                setMobile(e.target.value);
              }}
            />
            {Error?.mobile && (
              <Typography
                variant="caption"
                sx={{ color: ERRORCOLOR, marginLeft: "20px" }}
              >
                {Error?.mobile}
              </Typography>
            )}
          </Stack>
          <Stack sx={{ padding: "10px 20px" }}>
            <LoginButton
              onClick={handleSendOtp}
              endIcon={
                sendLoader ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
            >
              Send Otp
            </LoginButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ForgotPassword;
