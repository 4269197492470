//statemap.js

import React, { useEffect, useState } from "react";
import "./Marker.css";
import MarkerFNC from "./Marker";
import { useSelector } from "react-redux";
import { GetData } from "./MapUtilityFile";

const OrderMarker = ({ HandleSideDrawerOrder }) => {
  const MapData = useSelector((state) => state.MapReducer);
  const [processedData, setProcessedData] = useState(null);

  useEffect(() => {
    setProcessedData(GetData(MapData.orders));
  }, [MapData.orders]);

  let ROOT_LAT_LNG = processedData ? Object.keys(processedData) : null;
  ROOT_LAT_LNG && ROOT_LAT_LNG.splice(ROOT_LAT_LNG.indexOf("Bounds"), 1);

  // let ROOT_USER_KEY =
  //   processedData &&
  //   ROOT_LAT_LNG &&
  //   ROOT_LAT_LNG.length !== 0 &&
  //   Object.keys(processedData[ROOT_LAT_LNG[1]]);

  // ROOT_USER_KEY && ROOT_USER_KEY.splice(ROOT_USER_KEY.indexOf("MyLatLng"), 1);
  // console.log(ROOT_USER_KEY, "ROOT_USER_KEY");
  // const DB_ID =
  //   processedData[ROOT_LAT_LNG[1]][ROOT_USER_KEY[0]]["orders"][0]["db_id"];
  // console.log(DB_ID, "DB_ID");

  // ROOT_LAT_LNG &&
  //   ROOT_LAT_LNG.map((position) =>
  //     console.log(Object.keys(processedData[ROOT_LAT_LNG[position]]))
  //   );
  return (
    ROOT_LAT_LNG &&
    ROOT_LAT_LNG?.map((position) => (
      <LatLngProcessor
        ProcessedData={processedData}
        ROOT_LAT_LNG={ROOT_LAT_LNG}
        Postion={position}
        HandleSideDrawerOrder={HandleSideDrawerOrder}
      />
    ))
  );
};

export default OrderMarker;

// ROOT_LAT_LNG && ROOT_LAT_LNG.map(position=>(
//   Object.keys(processedData[ROOT_LAT_LNG[position]])
// ))

const LatLngProcessor = ({
  ProcessedData,
  ROOT_LAT_LNG,
  Postion,
  HandleSideDrawerOrder,
}) => {
  let ROOT_USER_KEY =
    ProcessedData &&
    ROOT_LAT_LNG &&
    ROOT_LAT_LNG.length !== 0 &&
    Object.keys(ProcessedData[`${Postion}`]);

  ROOT_USER_KEY && ROOT_USER_KEY.splice(ROOT_USER_KEY.indexOf("MyLatLng"), 1);

  return (
    ROOT_USER_KEY &&
    ROOT_USER_KEY?.map((user) => (
      <UserLatLngProcessor
        ProcessedData={ProcessedData}
        ROOT_LAT_LNG={ROOT_LAT_LNG}
        Postion={Postion}
        ROOT_USER_KEY={ROOT_USER_KEY}
        User={user}
        HandleSideDrawerOrder={HandleSideDrawerOrder}
      />
    ))
  );
};

const UserLatLngProcessor = ({
  ProcessedData,
  ROOT_LAT_LNG,
  Postion,
  ROOT_USER_KEY,
  User,
  HandleSideDrawerOrder,
}) => {
  const MapData = useSelector((state) => state.MapReducer);
  console.log(ProcessedData, ROOT_LAT_LNG, Postion, ROOT_USER_KEY, User);
  let NEWDATA = ProcessedData[`${Postion}`];
  let ORDERS = NEWDATA[User]["orders"];
  let LATLNG = NEWDATA[User]["latlng"];
  console.log(ORDERS);

  return (
    <>
      {[0, -1].includes(MapData?.id) ? (
        <>
          {ORDERS?.length !== 0 && (
            <MarkerFNC
              Position={LATLNG}
              ORDER={ORDERS?.length !== 0 ? ORDERS[0] : {}}
              ORDERS={ORDERS}
              HandleSideDrawerOrder={HandleSideDrawerOrder}
            />
          )}
        </>
      ) : (
        <>
          {ORDERS?.map((item) => (
            <MarkerFNC
              Position={LATLNG}
              ORDER={item}
              ORDERS={ORDERS}
              HandleSideDrawerOrder={HandleSideDrawerOrder}
            />
          ))}
        </>
      )}
    </>
  );
};
