import { Paper, Stack } from '@mui/material';
import React,{useState,useEffect, memo} from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import './styles/DashboardActiveUsersGraph.scss'
// import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate'
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate';

const DashboardActiveUsersGraph = () => {
    const Axios = useAxiosPrivate()
    const store = useSelector(state=>state.Dashboard);
    
    const [week,setWeek]=useState([])
    const [newUser,setNewUser]=useState([])
    const [users,setUsers]=useState([]);
    let Data ={
        series: [{
          name: "Total Users",
          data: users,
          
        },
        {
            name: "New Users",
            data: newUser,
         }],
        options: {
          chart: {
            foreColor: '#fff',
            toolbar: {
              show: true
            },
          },
          labels: week,
         dataLabels: {
            enabled: true
          },   
          stroke: {
            curve: "smooth"
          } ,
          title: {
            text: 'Active Users',
            align: 'left'
          },  
            xaxis: {
                type: '',
            },
            yaxis: [{
                title: {
                    text: 'Users On Platform',
                },
            }, {
                opposite: true,
                title: {
                    text: 'New Users',
                }
            }]
        }
      }

    React.useEffect(()=>{
             let WeekArr=[];
             WeekArr.push("wk"+ " "+store.activeUsers?.Week6?.weekNumber)
             WeekArr.push("wk"+ " "+store.activeUsers?.Week5?.weekNumber)
             WeekArr.push("wk"+ " "+store.activeUsers?.Week4?.weekNumber)
             WeekArr.push("wk"+ " "+store.activeUsers?.Week3?.weekNumber)
             WeekArr.push("wk"+ " "+store.activeUsers?.Week2?.weekNumber)
             WeekArr.push("wk"+ " "+store.activeUsers?.Week1?.weekNumber);
             setWeek(WeekArr);


             let NewUserArr=[];
             NewUserArr.push(store.activeUsers?.Week6?.UserData?.new ?? 0)
             NewUserArr.push(store.activeUsers?.Week5?.UserData?.new ?? 0)
             NewUserArr.push(store.activeUsers?.Week4?.UserData?.new ?? 0)
             NewUserArr.push(store.activeUsers?.Week3?.UserData?.new ?? 0)
             NewUserArr.push(store.activeUsers?.Week2?.UserData?.new ?? 0)
             NewUserArr.push(store.activeUsers?.Week1?.UserData?.new ?? 0);
             setNewUser(NewUserArr)

             let Users=[];
             Users.push(store.activeUsers?.Week6?.UserData?.users_count ?? 0)
             Users.push(store.activeUsers?.Week5?.UserData?.users_count ?? 0)
             Users.push(store.activeUsers?.Week4?.UserData?.users_count ?? 0)
             Users.push(store.activeUsers?.Week3?.UserData?.users_count ?? 0)
             Users.push(store.activeUsers?.Week2?.UserData?.users_count ?? 0)
             Users.push(store.activeUsers?.Week1?.UserData?.users_count ?? 0);
             setUsers(Users)


    },[store.activeUsers])


    
    


    return (
        <div className='DashboardActiveUsersGraphContainer' style={{width:"100%"}}>
          <Stack padding="0px 5px" flex={1}>
            <Stack
                sx={{
                    backgroundColor: "#25293C",
                    borderRadius: "10px"
                }}
                padding={2}
                flex={1}
            >
                <Chart options={Data.options} series={Data.series} type="line" height={350}  className="DashboardActiveUsersGraph"/>
            </Stack>
          </Stack>
            
        </div>

    )
}

export default memo(DashboardActiveUsersGraph)