import { TextField, Autocomplete, Button, Stack, Paper, Card, Typography } from '@mui/material';
import React, { useState, memo } from 'react';
import Styles from './styles/index.module.css'
import images from '../../utility/constants/images'
import { FaRegBell } from 'react-icons/fa';
import Avatar from '@mui/material/Avatar';
import { HiOutlineMoon } from 'react-icons/hi';
import { AiOutlineMenu } from 'react-icons/ai'
import './styles/index.scss';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { GetColor } from '../../utility/functions/functions';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';




const Navbar = (props) => {
  const [open, setOpen] = React.useState(false);
  const { handleShowSidebar, show } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const store = useSelector(state => state.Login);
  // const { stateList, setStateValue, StateValue, zoneList,setZoneValue,ZoneValue } = props
  // const [stateOptions, setStateOptions] = useState(["Karnataka"]);
  const BgColor = GetColor("--color-light-background")


  const handleLogout = () => {
    localStorage.clear();
    navigate('/Login')
  }

  const handleUserData = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);

  }

  return (
    <>
      <Stack sx={{
        width:"100%",
        padding : {md : "0px 60px"},
        position: "fixed",
        top: 0,
        zIndex: 999
      }}>
        <Card component={Paper} sx={{
          backgroundColor: BgColor,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          height: "60px"
        }}>
          <Stack direction="row" justifyContent="space-between" sx={{ minHeight: "100%" }}>
            <Stack direction="row"
              sx={{
                padding: { xs: "0px 5px",sm: "0px 20px" },
                gap:"5px"
              }}
            >
              <Stack onClick={handleShowSidebar} direction="row" alignItems="center">
                {show ? <CloseIcon size="20px" className={Styles.menuIcon} /> : <AiOutlineMenu size="20px" className={Styles.menuIcon} />}
              </Stack>
              <Stack direction="row" alignItems="center">
                <img src={images.Logo} alt='vitaran logo' className={Styles.Logo} />
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="center">

              <div className={Styles.NotificationContainer}>
                <div className={Styles.UserContainer}>

                  <Avatar src={images.User} className={Styles.Avatar} onClick={handleUserData} />
                  < Popper open={open} anchorEl={anchorEl} placement={"bottom"} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Stack padding={1}>
                             <Stack direction="row">
                              <Typography variant="body1" sx={{fontWeight:"bold"}}>UserName: </Typography>
                              <Typography>{store?.user?.name}</Typography>
                             </Stack>
                             <Stack direction="row">
                              <Typography variant="body1" sx={{fontWeight:"bold"}}>Role: </Typography>
                              <Typography>{store?.user?.admin_role_name}</Typography>
                             </Stack>
                        
                          </Stack>


                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </div>



                <div className={Styles.NotificationBellContainer}>
                  <FaRegBell />
                </div>

                <LogoutIcon className={Styles.MoonIcon} size="20px" onClick={handleLogout} />

              </div>
            </Stack>
          </Stack>

        </Card>
      </Stack>
    </>
  )
}

export default memo(Navbar)