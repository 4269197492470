import { Button, Card, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/UpdateCategory.module.css";
import images from "../../../../utility/constants/images";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseImgUploadUrl, baseImgPath, defaultImg } from "../../../../utility/BaseUrl";
import {
  GetCategoryListBySCId,
  RemoveCategoryData,
  UpdateCategoryData,
} from "../../../../actions/category/categoryActions";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate';
import PopUpModal from '../../../../utility/Backdrop';
import BrokenImg from '../../../../assets/ImageNotValid.png'
import { ValidImage } from '../../../../actions/validImage';
import { ROLES } from '../../../../utility/UserAllowedRoles';
import { DarkCardWrapper } from '../../CardWrapper';
import { AutocompleteTextField } from '../../../../utility/TextField';


const viewImgPath = `${baseImgPath}/category/`;

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const UpdateCategory = () => {
  const [loading, setLoading] = useState(false)
  const LoginStore = useSelector(state => state.Login)
  const Axios = useAxiosPrivate()
  const [remove, setRemove] = useState(false)
  const [error, setError] = useState({});
  const store = useSelector((state) => state.Category);
  const [file, setFile] = useState("");
  const [img, setImg] = useState(defaultImg);
  const [addsuccess, setAddsuccess] = useState(false);

  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const [addsuccessrm, setAddsuccessrm] = useState(false);
  const [failedrm, setFailedrm] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    item_id: 0,
    item_image: "",
    item_name: "",
    sc_id: 0,
    sc_name: ""
  });


  const CheckImage = async (image) => {
    if (![undefined, null].includes(image) && image?.length !== 0) {
      const Result = await ValidImage(image)
      if (Result) {
        setImg(`${image}`)
      } else {
        setImg(BrokenImg)
      }
    }

  }

  useEffect(() => {
    if (store.category_detail !== undefined && store.category_detail !== null) {

      const { item_id, item_image, item_name, sc_id, sc_name } =
        store.category_detail;

      setForm(store.category_detail);

      const image =
        item_image !== undefined &&
          item_image !== null &&
          item_image.length !== 0
          ? item_image
          : "";
      CheckImage(image)
    }
  }, [store.category_detail]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const imgUpload = async (formData) => {
    await axios
      .post(`${baseImgUploadUrl}image_upload_category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 200) {
          setLoading(false)
          toast.success("Uploaded SuccessFully");
          setImg(`${res.data.full_path}`);
          setForm({
            ...form,
            item_image: res.data.name,
          });
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const ChooseImg = (event) => {
    setLoading(true)
    setFile(event.target.files[0]);
  };

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("document", JSON.stringify({type:"company"}));
    imgUpload(formData);
  }, [file]);

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Updated Category Details SuccessFully");

      setAddsuccess(false);
    }
  }, [addsuccess]);

  React.useEffect(() => {
    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failed]);

  React.useEffect(() => {
    if (addsuccessrm) {
      toast.success("Removed Category Details SuccessFully");

      setAddsuccessrm(false);
    }
  }, [addsuccessrm]);

  React.useEffect(() => {
    if (failedrm.addFailed) {
      let text = failedrm.addFailedMessage;
      toast.error(`${text}`);

      setFailedrm({
        ...failedrm,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failedrm]);

  const IsValid = () => {
    let success = true;
    let err = {};
   const chars = form.item_name?.match(/[^a-zA-Z0-9',&-](?= |$)/g)
    if (!form.item_id) {
      err.categoryId = "**category Id Cannot Be Empty**";
      success = false;
    }

    if (!form.item_name.trim()) {
      err.categoryname = "**category Name Cannot Be Empty**";
      success = false;
    } else if (chars && chars?.length!==0) {
      err.categoryname =
        "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
      success = false;
    }

    if (!img.trim()) {
      err.categoryimage = "**Please Choose a Image**";
      success = false;
    }

    // if (!form.description.trim()) {
    //   err.description = "**category Description Cannot Be Empty**";
    //   success = false;
    // }

    setError(err);
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (IsValid()) {

      let param = {
        type: "Category",
        item_id: `${form.item_id}`,
        item_name: `${form.item_name}`,
        item_desc: `${form.description}`,
        item_image: img,
        sc_id: store?.selected_s_category?.sc_id
      };
      let scId = store?.selected_s_category?.sc_id;
      console.log(store?.selected_s_category, param, "param")
      const Data = await dispatch(UpdateCategoryData(Axios, param));
      if (Data.code === 200) {
        let paramTwo = {
          sc_id: scId,
        };
        dispatch(GetCategoryListBySCId(Axios, paramTwo));
        toast.success("Updated Category SuccessFully")
      } else {
        toast.error(Data.message)
      }
    }else{
      // toast.warning("validation error")
    }
  };
  const handleRemove = async () => {
    setRemove(true)
  }

  const handleConfirmRemove = async () => {
    console.log("here");
    if (!form.item_id) {
      let err = {};
      err.categoryId = "**category Id Cannot Be Empty**";
      setError(err);
    } else {
      let param = {
        type: "category",
        item_id: `${form.item_id}`,
        status: false,
        sc_id: store?.selected_s_category?.sc_id
      };
      dispatch(RemoveCategoryData(Axios, param, setAddsuccessrm, failedrm, setFailedrm, setRemove));
    }

  }
  // console.log(form, "update cat");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <form onSubmit={handleSubmit}>
        <Stack sx={{ color: "white" }} gap={1}>

          <Typography>Update Category</Typography>
          <DarkCardWrapper sx={{ padding: "10px 20px" }}>
            <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
              <Stack gap={1} flex={0.7}>
                <Card component={Paper} sx={{ borderRadius: "16px" }}>
                  <Stack sx={{ height: "130px" }} direction="row" justifyContent={"center"} alignItems="center">
                    {!loading && <img src={img} alt="company" loading="lazy"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                      }} />}
                    {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                    {
                      loading ?
                        <Stack direction="column" position="absolute" justifyContent={"center"} alignItems={"center"}>
                          <CircularProgress />
                          <Typography>uploading...</Typography>
                        </Stack> :
                        null
                    }
                    <input type="file" accept="image/*" name="image-upload" id={`CategoryImgUploadUpdate`} className={Styles.imginp} onChange={ChooseImg} />
                  </Stack>


                </Card>
                <Card sx={{ backgroundColor: "#545B87" }} className={Styles.UpdateCompanyWrapper}>
                  <Stack direction="row" justifyContent="center" alignItems="center" padding="5px 0px" sx={{ cursor: "pointer" }}>
                    <label htmlFor={`CategoryImgUploadUpdate`} style={{ fontSize: "12px", margin: 0, color: "white", cursor: "pointer" }}>
                      UPLOAD IMAGE
                    </label>
                  </Stack>
                </Card>

              </Stack>
              <Stack flex={3} gap={1}>
                <Stack>
                  <AutocompleteTextField size="small" label='Super Category Name' disabled="true" value={store?.selected_s_category?.sc_name ?? ''} onChange={handleChange}/>
                </Stack>
                <Stack>
                  <AutocompleteTextField size="small" label='Category Name'  value={form.item_name} name="item_name" onChange={handleChange} />
                  {error?.categoryname && <Typography variant="caption" sx={{ color: "red" }}>{error?.categoryname}</Typography>}
                </Stack>
                <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="flex-end" gap={1}>
                  <RedButton sx={{ borderRadius: "18px", minWidth: { md: "150px" } }} type="submit">Update</RedButton>
                  {ROLES?.REMOVECOMPANY?.includes(LoginStore?.user?.admin_role_code) ?
                    <RedBorderButton sx={{ borderRadius: "18px", minWidth: { md: "150px" } }} onClick={handleRemove}>Remove</RedBorderButton> : null}
                </Stack>
              </Stack>
            </Stack>
          </DarkCardWrapper>

        </Stack>
      </form>

      {
        remove &&
        <PopUpModal
          open={remove}
          handleClose={() => setRemove(false)}
          Title={`Do You want to Remove Category`}
          Content={`${form.item_name}`}
          Img={img}
          handleAgree={handleConfirmRemove}
        />
      }
    </>
  );
};

export default UpdateCategory;
