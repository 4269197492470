import React, { useEffect, useState } from "react";
import { Stack, Autocomplete } from "@mui/material";
import { AutocompleteTextFieldNormal } from "../../../../utility/TextField";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import {
  PartnerWalletAllDistrictList,
  PartnerWalletAllStateList,
  PartnerWalletAllZoneList,
  PartnerWalletUserList,
} from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";
import { GETALLUPDATEDVALUE } from "../../../../utility/ReduxConstants/PartnerWalletManagementReducerConstant";
import { SettingsRemoteOutlined } from "@mui/icons-material";

const PartnerWalletDropdown = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const [stateList, setStateList] = useState(null);
  const [districtList, setDistrictList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [obj, setObj] = useState({
    state_id: null,
    state_name: null,
    zone_name: null,
    district_name: null,
    status: null,
    district_id: null,
    zone_id: null,
  });
  const allStateList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllStateList
  );

  const allDistrictList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllDistrictList
  );
  const allZoneList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllZoneList
  );

  const allUpdatedList = useSelector(
    (store) => store.PartnerWalletManagementReducer.updatedIDList
  );

  console.log(allUpdatedList, allZoneList, "walletAllZoneList");
  useEffect(() => {
    dispatch(PartnerWalletAllStateList(Axios));
  }, []);

 

  useEffect(() => {
    let param = { state_id: 0 };
    dispatch(PartnerWalletAllDistrictList(Axios, param));
  }, []);

  useEffect(() => {
    let param = {
      district_id: 0,
    };
    dispatch(PartnerWalletAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    let state = [];

    for (let i = 0; i < allStateList?.length; i++) {
      state.push(allStateList[i]?.state_name);
    }

    setStateList(state);
    setObj((prevObj) => ({
      ...prevObj,
      state_name: allStateList?.[0]?.state_name,
      state_id: allStateList?.[0]?.state_id,
    }));

    let param = { state_id: allStateList?.[0]?.state_id };

    dispatch(PartnerWalletAllDistrictList(Axios, param));

    // setObj({
    //   ...obj,
    //   state_list: state,
    // });
  }, [allStateList]);

  useEffect(() => {
    setObj((prevObj) => ({
      ...prevObj,
      district_name: allDistrictList?.[0]?.district_name,
      district_id: allDistrictList?.[0]?.district_id,
    }));

    let param = { district_id: allDistrictList?.[0]?.district_id };

    dispatch(PartnerWalletAllZoneList(Axios, param));
  }, [allDistrictList]);

  useEffect(() => {
    let district = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      district.push(allDistrictList[i]?.district_name);
    }
    setDistrictList(district);

    // setObj({
    //   ...obj,

    //   district_list: district,
    // });
  }, [allDistrictList]);

  useEffect(() => {
    let zone = [];
    for (let i = 0; i < allZoneList?.length; i++) {
      zone.push(allZoneList[i]?.zone_name);
    }
    setZoneList(zone);

    let param = {
      zone_id: allZoneList?.[0]?.zone_id,
      zone_name: allZoneList?.[0]?.zone_name,
    };
    dispatch(PartnerWalletUserList(Axios, param));

    setObj((prevObj) => ({
      ...prevObj,
      zone_name: allZoneList?.[0]?.zone_name,
      zone_id: allZoneList?.[0]?.zone_id,
    }));

    // setObj({
    //   ...obj,

    //   zone_list: zone,
    // });
  }, [allZoneList]);

  useEffect(() => {
    dispatch({ type: GETALLUPDATEDVALUE, payload: obj });
  }, [obj]);

  const handleState = (e, value) => {
    let stateId = [];
    for (let i = 0; i < allStateList?.length; i++) {
      if (value === allStateList[i].state_name) {
        stateId = allStateList[i].state_id;
        break;
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      state_id: stateId,
      state_name: value,
    }));

    let param = { state_id: stateId };

    dispatch(PartnerWalletAllDistrictList(Axios, param));
  };

  const handleDistrict = (e, value) => {
    let districtId = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      if (value === allDistrictList[i]?.district_name) {
        districtId = allDistrictList[i]?.district_id;
        break;
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      district_id: districtId,
      district_name: value,
    }));

    let param = { district_id: districtId };

    dispatch(PartnerWalletAllZoneList(Axios, param));
  };

  const handleZone = (e, value) => {
    let zoneId = [];
    for (let i = 0; i < allZoneList?.length; i++) {
      if (value === allZoneList[i]?.zone_name) {
        zoneId = allZoneList[i]?.zone_id;
        break;
      }
    }
    setObj((prevObj) => ({
      ...prevObj,
      zone_id: zoneId,
      zone_name: value,
    }));
    let param = {
      zone_id: zoneId,
      zone_name: value,
    };
    dispatch(PartnerWalletUserList(Axios, param));
  };

  return (
    <Stack
      sx={{
        marginTop: "4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.state_name}
          options={stateList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleState}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="State"
              placeholder="Select State.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.district_name}
          options={districtList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleDistrict}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="District"
              placeholder="Select District.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          value={obj?.zone_name}
          options={zoneList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleZone}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "100%",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="Zone"
              placeholder="Select Zone.."
            />
          )}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Stack
          sx={{
            background: "#505365",
            color: "white",
            padding: "0.65rem",
            cursor: "pointer",
          }}
        >
          <SwapVertIcon />
        </Stack>

        <Stack sx={{ width: "20rem" }}>
          <input
            type="text"
            placeholder="Search By Name or Mobile Number"
            style={{
              padding: "0.7rem",
              background: "#323546",
              border: "0.1rem solid #757575",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PartnerWalletDropdown;
