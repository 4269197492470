//Search Section

import {
  Autocomplete,
  Card,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect, useCallback } from "react";
import { GetColor } from "../../../utility/functions/functions";
import Styles from "./styles/UserSearch.module.css";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UserAccessTable from "./UserAccessTable";
import { useDispatch, useSelector } from "react-redux";
import {
  GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
  GETALLZONELISTUSERACCESSCONSTFILTER,
  GETUSERLISTACCESSMGT,
} from "../../../utility/ReduxConstants/UserAcessReducerConstant";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  AllDistrictListUserAccess,
  AllZoneListUserAccess,
  GetUserAccessList,
} from "../../../actions/UserAccess/UserAccessZoneAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserSearchAccess = ({ text, setText }) => {
  const [stateListLoader, setStateListLoader] = useState(false);
  const [zoneListLoader, setZoneListLoader] = useState(false);
  const [scListLoader, setScListLoader] = useState(false);
  const LocalData = JSON.parse(localStorage.getItem("accessfilter"));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const TitleColor = GetColor("--color-white");
  const BgColor = GetColor("--color-light-background");
  const LightBgColor = GetColor("--color-super-light-background");

  const store = useSelector((state) => state.Access);
  // const [obj, setObj] = useState({
  //   stateList: ["All"],
  //   zoneList: ["All"],
  //   stateValue: "All",
  //   stateId: "0",
  //   zoneValue: "All",
  //   zoneId: 0,
  // });

  const [obj, setObj] = useState({
    stateList: ["All"],
    zoneList: ["All"],
    stateValue: "All",
    stateId: 0,
    zoneValue: "All",
    zoneId: 0,
    districtList: ["All"],
    districtValue: "All",
    districtId: 0,
  });
  const [superCategoryObj, setSuperCategoryObj] = useState({
    superCategoryList: ["All"],
    scValue: "All",
    scId: "0",
  });

  // const []

  // useEffect(() => {
  //     setObj({
  //         ...obj,
  //         stateList: LocalData?.stateList ?? ['All'],
  //         stateValue: LocalData?.stateValue ?? "All",
  //         stateId: LocalData?.stateId ?? 0,
  //     });
  // }, [LocalData])

  // useEffect(() => {
  //   setStateListLoader(true);
  //   setTimeout(() => {
  //     setStateListLoader(false);
  //     let arr = ["All"];
  //     for (let i = 0; i < store?.stateListAdd?.length; i++) {
  //       arr.push(store?.stateListAdd[i]?.state_name);
  //     }
  //     setObj({
  //       ...obj,
  //       stateList: arr,
  //       stateValue: "All",
  //       stateId: 0,
  //     });
  //     let jsonObj = {
  //       ...obj,
  //       stateList: arr,
  //       stateValue: "All",
  //       stateId: 0,
  //     };

  //     let Data = JSON.stringify(jsonObj);
  //     localStorage.setItem("accessfilter", Data);
  //     let param = {
  //       state_id: 0,
  //     };
  //     GetAllZones(param);
  //   }, [500]);
  // }, [store.stateListAdd]);

  const [accessStateList, setAccessStateList] = useState([]);

  useEffect(() => {
    let arr = ["All"];
    for (let i = 0; i < store?.stateListAdd?.length; i++) {
      arr.push(store?.stateListAdd[i]?.state_name);
    }

    setAccessStateList(arr);
    // setObj({
    //   ...obj,
    //   stateList: arr,
    //   stateValue: "All",
    //   stateId: 0,
    // });
    // let jsonObj = {
    //   ...obj,
    //   stateList: arr,
    //   stateValue: "All",
    //   stateId: 0,
    // };
    // let Data = JSON.stringify(jsonObj);
    //   localStorage.setItem("accessfilter", Data);
  }, [store?.stateListAdd]);

  useEffect(() => {
    setStateListLoader(true);
    setTimeout(() => {
      setStateListLoader(false);
      let arr = ["All"];
      for (let i = 0; i < store?.stateListAdd?.length; i++) {
        arr.push(store?.stateListAdd[i]?.state_name);
      }
      // setObj({
      //   ...obj,
      //   stateList: arr,
      //   stateValue: "All",
      //   stateId: 0,
      // });
      // let jsonObj = {
      //   ...obj,
      //   stateList: arr,
      //   stateValue: "All",
      //   stateId: 0,
      // };

      // let Data = JSON.stringify(jsonObj);
      // localStorage.setItem("accessfilter", Data);
      let param = {
        state_id: 0,
      };
      GetAllDistrict(param);
    }, [500]);
  }, [store.stateListAdd]);

  console.log(store.stateListAdd, "slrnvlsjfkljmklnmsaf");

  useEffect(() => {
    setDistrictListLoader(true);
    setTimeout(() => {
      setDistrictListLoader(false);
      let arr = ["All"];
      for (let i = 0; i < store?.districtListAdd?.length; i++) {
        arr.push(store?.districtListAdd[i]?.district_name);
      }
      setObj({
        ...obj,
        districtList: arr,
        districtValue: "All",
        districtId: 0,
      });
      let jsonObj = {
        ...obj,
        districtList: arr,
        districtValue: "All",
        districtId: 0,
      };

      let Data = JSON.stringify(jsonObj);
      localStorage.setItem("accessfilter", Data);
      let param = {
        district_id: 0,
      };
      GetAllZones(param);
    }, [500]);
  }, [store.districtListAdd]);

  const GetAllDistrict = async (param) => {
    setDistrictListLoader(true);
    const DistrictData = await dispatch(
      AllDistrictListUserAccess(Axios, param)
    );
    if (DistrictData.code === 200) {
      setDistrictListLoader(false);
      dispatch({
        type: GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
        payload: DistrictData?.districtList,
      });
    }
  };

  const GetAllZones = async (param) => {
    setZoneListLoader(true);
    const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
    if (ZoneData.code === 200) {
      setZoneListLoader(false);
      dispatch({
        type: GETALLZONELISTUSERACCESSCONSTFILTER,
        payload: ZoneData?.zoneList,
      });
    }
  };

  useEffect(() => {
    if (
      store.zoneListFilter !== undefined &&
      store.zoneListFilter !== null &&
      store.zoneListFilter.length !== 0
    ) {
      let arr = ["All"];
      for (let i = 0; i < store.zoneListFilter.length; i++) {
        arr.push(store.zoneListFilter[i].zone_name);
      }

      setObj({
        ...obj,
        zoneList: arr,
        zoneValue: "All",
        zoneId: 0,
      });
      (async () => {
        let param = {
          zone_id: 0,
          state_id: obj.stateId,
          sc_id: superCategoryObj.scId,
          role_id: 3,
        };
        const UserList = await dispatch(GetUserAccessList(Axios, param));

        console.log(UserList, "UserList", param);
        // setLoading(false)
        if (UserList?.code === 200) {
          dispatch({
            type: GETUSERLISTACCESSMGT,
            payload: UserList?.body,
          });
        } else {
          console.log("something went wrong");
        }
      })();
    }
  }, [store.zoneListFilter]);

  useEffect(() => {
    setScListLoader(true);
    // setTimeout(() => {
    let arr = ["All"];
    for (let i = 0; i < store.superCategoryListAdd.length; i++) {
      arr.push(store.superCategoryListAdd[i].name);
    }
    setScListLoader(false);
    setSuperCategoryObj({
      ...superCategoryObj,
      superCategoryList: arr,
      scId: 0,
      scValue: "All",
    });
    // }, [1500])
  }, [store.superCategoryListAdd]);

  // const handleStateChange = async (event, value) => {
  //   let jsonObj = {};
  //   setZoneListLoader(true);
  //   if (value.length !== 0 && value !== null && value !== undefined) {
  //     if (value !== "All") {
  //       let id;
  //       for (let i = 0; i < store.stateListAdd.length; i++) {
  //         if (value === store.stateListAdd[i].state_name) {
  //           id = store.stateListAdd[i].state_id;
  //         }
  //       }
  //       setObj({
  //         ...obj,
  //         stateId: id,
  //         stateValue: value,
  //         zoneList: [],
  //         zoneValue: "",
  //         zoneId: 0,
  //       });
  //       jsonObj = {
  //         ...obj,
  //         stateId: id,
  //         stateValue: value,
  //         zoneList: [],
  //         zoneValue: "",
  //         zoneId: 0,
  //       };

  //       let param = {
  //         state_id: id,
  //       };

  //       const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
  //       if (ZoneData.code === 200) {
  //         setZoneListLoader(false);
  //         dispatch({
  //           type: GETALLZONELISTUSERACCESSCONSTFILTER,
  //           payload: ZoneData?.zoneList,
  //         });
  //       }
  //     } else {
  //       setObj({
  //         ...obj,
  //         stateId: 0,
  //         stateValue: "All",
  //       });

  //       jsonObj = {
  //         ...obj,
  //         stateId: 0,
  //         stateValue: "All",
  //       };

  //       let param = {
  //         state_id: 0,
  //       };

  //       const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
  //       if (ZoneData.code === 200) {
  //         setZoneListLoader(false);
  //         dispatch({
  //           type: GETALLZONELISTUSERACCESSCONSTFILTER,
  //           payload: ZoneData?.zoneList,
  //         });
  //       }
  //     }
  //   } else {
  //     setObj({
  //       ...obj,
  //       stateId: 0,
  //       stateValue: "All",
  //     });

  //     jsonObj = {
  //       ...obj,
  //       stateId: 0,
  //       stateValue: "All",
  //     };

  //     let param = {
  //       state_id: 0,
  //     };

  //     const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
  //     if (ZoneData.code === 200) {
  //       setZoneListLoader(false);

  //       dispatch({
  //         type: GETALLZONELISTUSERACCESSCONSTFILTER,
  //         payload: ZoneData?.zoneList,
  //       });
  //     }
  //   }
  // };

  const handleStateChange = async (event, value) => {
    // if (!CheckSmallError(value) && value !== "") {
    let jsonObj = {};
    setDistrictListLoader(true);
    if (value.length !== 0 && value !== null && value !== undefined) {
      if (value !== "All") {
        let id;
        for (let i = 0; i < store.stateListAdd.length; i++) {
          if (value === store.stateListAdd[i].state_name) {
            id = store.stateListAdd[i].state_id;
          }
        }
        setObj({
          ...obj,
          stateId: id,
          stateValue: value,
        });
        jsonObj = {
          ...obj,
          stateId: id,
          stateValue: value,
        };

        let param = {
          state_id: id,
        };

        const DistrictData = await dispatch(
          AllDistrictListUserAccess(Axios, param)
        );
        console.log(DistrictData, "showKyc");
        if (DistrictData.code === 200) {
          setDistrictListLoader(false);
          dispatch({
            type: GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
            payload: DistrictData?.districtList,
          });
        }
      } else {
        setObj({
          ...obj,
          stateId: 0,
          stateValue: "All",
        });

        jsonObj = {
          ...obj,
          stateId: 0,
          stateValue: "All",
        };

        let param = {
          state_id: 0,
        };

        const DistrictData = await dispatch(
          AllDistrictListUserAccess(Axios, param)
        );
        if (DistrictData.code === 200) {
          setDistrictListLoader(false);
          dispatch({
            type: GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
            payload: DistrictData?.districtList,
          });
        }
      }
    } else {
      setObj({
        ...obj,
        stateId: 0,
        stateValue: "All",
      });

      jsonObj = {
        ...obj,
        stateId: 0,
        stateValue: "All",
      };

      let param = {
        state_id: 0,
      };

      const DistrictData = await dispatch(
        AllDistrictListUserAccess(Axios, param)
      );
      if (DistrictData.code === 200) {
        setDistrictListLoader(false);
        dispatch({
          type: GETALLDISTRICTLISTUSERACCESSCONSTFILTER,
          payload: DistrictData?.districtList,
        });
      }
    }
  };
  const handleSuperCategoryChange = async (event, value) => {
    let jsonObj = {};
    let id = 0;
    if (value.length !== 0 && value !== null && value !== undefined) {
      if (value === "All") {
        setSuperCategoryObj({
          ...superCategoryObj,
          scValue: "All",
          scId: 0,
        });
        jsonObj = {
          ...superCategoryObj,
          scValue: "All",
          scId: 0,
        };
      } else {
        for (let i = 0; i < store.superCategoryListAdd.length; i++) {
          if (value === store.superCategoryListAdd[i].name) {
            id = store.superCategoryListAdd[i].sc_id;
          }
        }

        setSuperCategoryObj({
          ...superCategoryObj,
          scValue: value,
          scId: id,
        });

        jsonObj = {
          ...obj,
          scValue: value,
          scId: id,
        };
      }
    }
    // let Data = JSON.stringify(jsonObj);
    // localStorage.setItem('accessfilter', Data)
    let param = {
      zone_id: obj?.zoneId,
      state_id: obj.stateId,
      sc_id: id,
      role_id: 3,
    };
    const UserList = await dispatch(GetUserAccessList(Axios, param));
    if (UserList?.code === 200) {
      dispatch({
        type: GETUSERLISTACCESSMGT,
        payload: UserList?.body,
      });
    } else {
      console.log("something went wrong");
    }
  };

  const handleZoneChange = async (event, value) => {
    let jsonObj = {};
    let id = 0;
    if (value !== undefined && value !== null && value.length !== 0) {
      if (value === "All") {
        setObj({
          ...obj,
          zoneValue: "All",
          zoneId: 0,
        });

        jsonObj = {
          ...obj,
          zoneValue: "All",
          zoneId: 0,
        };
      } else {
        for (let i = 0; i < store.zoneListFilter.length; i++) {
          if (value === store.zoneListFilter[i].zone_name) {
            id = store.zoneListFilter[i].zone_id;
          }
        }
        setObj({
          ...obj,
          zoneValue: value,
          zoneId: id,
        });

        jsonObj = {
          ...obj,
          zoneValue: value,
          zoneId: id,
        };
      }
    } else {
      setObj({
        ...obj,
        zoneValue: "All",
        zoneId: 0,
      });
      jsonObj = {
        ...obj,
        zoneValue: "All",
        zoneId: 0,
      };
    }

    // let Data = JSON.stringify(jsonObj);
    // localStorage.setItem('accessfilter', Data)
    let param = {
      zone_id: id,
      state_id: obj.stateId,
      sc_id: superCategoryObj?.scId,
      role_id: 3,
    };
    const UserList = await dispatch(GetUserAccessList(Axios, param));
    if (UserList?.code === 200) {
      dispatch({
        type: GETUSERLISTACCESSMGT,
        payload: UserList?.body,
      });
    } else {
      console.log("something went wrong");
    }
  };

  const GetUserList = async () => {
    let param = {
      zone_id: obj?.zoneId,
      state_id: obj?.stateId,
      sc_id: superCategoryObj?.scId,
      role_id: 3,
    };
    const UserList = await dispatch(GetUserAccessList(Axios, param));

    console.log(UserList, "UserList", param);
    // setLoading(false)
    if (UserList?.code === 200) {
      dispatch({
        type: GETUSERLISTACCESSMGT,
        payload: UserList?.body,
      });
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    GetUserList();
  }, [superCategoryObj]);

  const [districtListLoader, setDistrictListLoader] = useState(false);
  const handleDistrictChange = async (event, value) => {
    let jsonObj = {};
    setZoneListLoader(true);
    if (value.length !== 0 && value !== null && value !== undefined) {
      if (value !== "All") {
        let id;
        for (let i = 0; i < store.districtListAdd.length; i++) {
          if (value === store.districtListAdd[i].district_name) {
            id = store.districtListAdd[i].district_id;
          }
        }
        setObj({
          ...obj,
          districtId: id,
          districtValue: value,
          zoneList: [],
          zoneValue: "",
          zoneId: 0,
        });
        jsonObj = {
          ...obj,
          districtId: id,
          districtValue: value,
          zoneList: [],
          zoneValue: "",
          zoneId: 0,
        };

        let param = {
          district_id: id,
        };

        const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
        if (ZoneData.code === 200) {
          setZoneListLoader(false);
          dispatch({
            type: GETALLZONELISTUSERACCESSCONSTFILTER,
            payload: ZoneData?.zoneList,
          });
        }
      } else {
        setObj({
          ...obj,
          districtId: 0,
          districtValue: "All",
        });

        jsonObj = {
          ...obj,
          districtId: 0,
          districtValue: "All",
        };

        let param = {
          district_id: 0,
        };

        const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
        if (ZoneData.code === 200) {
          setZoneListLoader(false);
          dispatch({
            type: GETALLZONELISTUSERACCESSCONSTFILTER,
            payload: ZoneData?.zoneList,
          });
        }
      }
    } else {
      setObj({
        ...obj,
        districtId: 0,
        districtValue: "All",
      });

      jsonObj = {
        ...obj,
        districtId: 0,
        districtValue: "All",
      };

      let param = {
        district_id: 0,
      };

      const ZoneData = await dispatch(AllZoneListUserAccess(Axios, param));
      if (ZoneData.code === 200) {
        setZoneListLoader(false);

        dispatch({
          type: GETALLZONELISTUSERACCESSCONSTFILTER,
          payload: ZoneData?.zoneList,
        });
      }
    }
  };

  return (
    <>
      <Stack sx={{ color: "white" }} gap={1}>
        <Stack
          flex={1}
          padding={1}
          direction={"row"}
          justifyContent={"space-evenly"}
          gap={0.5}
          sx={{width:"95%"}}
        >
          <Card
            component={Paper}
            sx={{ backgroundColor: "#404459", flex: 1, borderRadius: "18px" }}
          >
            <Stack flex={1}>
              <Autocomplete
                value={obj.stateValue}
                // options={obj.stateList}
                options={accessStateList}
                className={Styles.autocomplete}
                onChange={handleStateChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                   
                  },
                }}
                loading={stateListLoader}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {stateListLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="State"
                  />
                )}
              />
            </Stack>
          </Card>
          <Card
            component={Paper}
            sx={{ backgroundColor: "#404459", flex: 1, borderRadius: "18px" }}
          >
            <Stack flex={1}>
              <Autocomplete
                value={obj.districtValue}
                options={obj.districtList}
                className={Styles.autocomplete}
                onChange={handleDistrictChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    
                  },
                }}
                loading={districtListLoader}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {districtListLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="District"
                  />
                )}
              />
            </Stack>
          </Card>
          <Card
            component={Paper}
            sx={{ backgroundColor: "#404459", flex: 1, borderRadius: "18px" }}
          >
            <Stack flex={1}>
              <Autocomplete
                value={obj.zoneValue}
                options={obj.zoneList}
                className={Styles.autocomplete}
                onChange={handleZoneChange}
                loading={zoneListLoader}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                   
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {zoneListLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder="Zone"
                    // label="Role"
                  />
                )}
              />
            </Stack>
          </Card>
          <Card
            component={Paper}
            sx={{ backgroundColor: "#404459", flex: 1, borderRadius: "18px" }}
          >
            <Stack flex={1}>
              <Autocomplete
                value={superCategoryObj.scValue}
                options={superCategoryObj.superCategoryList}
                className={Styles.autocomplete}
                onChange={handleSuperCategoryChange}
                loading={scListLoader}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {scListLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="Super Category"
                    // label="Role"
                  />
                )}
              />
            </Stack>
          </Card>
        </Stack>
        <Stack gap={1}>
          <Stack>
            <UserAccessTable Filter={obj} FilterText={text} Loading={loading} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default UserSearchAccess;
