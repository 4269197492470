import React, { useState } from 'react';
import Styles from './styles/index.module.css'
import AddCompany from './AddCompany';
import UpdateCompany from './UpdateCompany';
import CarouselCompany from './carousel';
import { GetCompanyPageData } from '../../../../actions/company/companyAction';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import { Stack, Typography } from '@mui/material';
import { CardWrapper } from '../../CardWrapper';
import AddWrapperComponent from '../AddWrapperComponent';
import CompanyList from './CompanyList';
import CompanyListTwo from './CompanyListTwo';


const CompanyMgt = () => {
  const Axios = useAxiosPrivate()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const dispatch = useDispatch();

  React.useEffect(() => {
    let param = {
      "type": "company"
    }
    dispatch(GetCompanyPageData(Axios, param))
  }, [])

  let handleOpen = (state) => {
    setOpen(state)
  }

  return (
    <div className={Styles.container}>
      <CardWrapper>
        <Stack direction="column" gap={2}>
          <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
            <Stack flex={1}>
              <AddWrapperComponent formOpen={open} handleFormOpen={handleOpen} Title={"Add Company"}>
                <AddCompany />
              </AddWrapperComponent>
            </Stack>
            <Stack flex={2.3} sx={{ padding: "20px 5px 10px 5px", color: "white" }}>
              <Stack gap={1} padding="20px 0px">
                {/* <Typography variant="h6">Search User</Typography> */}
                <Stack direction="row" flexWrap="wrap" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={1} >
                  <IconButton
                    // className={Styles.Icon}
                    sx={{
                      backgroundColor: "white",
                      '&:hover': {
                        backgroundColor: "gray",
                        transition: "0.2s linear"
                      }
                    }}>
                    <SearchIcon sx={{ color: "black !important" }} />
                  </IconButton>
                  <input style={{
                    flex: 1,
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    color: "white"
                  }}
                    value={text} onChange={(e) => setText(e.target.value)}
                    placeholder='Search Company Name'
                  />
                  <IconButton
                    // className={Styles.Icon}
                    onClick={() => setText('')}
                  >
                    <CloseIcon sx={{ color: "white !important" }} />
                  </IconButton>
                </Stack>
              </Stack>
              {open && <CompanyList text={text} />}
            </Stack>
          </Stack>
          {!open && <CompanyListTwo text={text} open={open} />}
        </Stack>
      </CardWrapper>
    </div>
  )
}

export default CompanyMgt