import {
  GETSKUBYIDDETAILS,
  GETSKUCATEGORYLIST,
  GETSKUPAGEDATACONSTANT,
  GETSKUCOMPANYLIST,
  GETSKUPRODUCTLIST,
  GETSKUCOMPANYLISTUPDATE,
  GETSKUPRODUCTLISTUPDATE,
  UPDATEPARTICULARSKUBYID,
  GETALLSTATELISTSKU,
  GETALLZONELISTSKU,
  GETALLZONELISTSKUUPDATE,
  UPDATEPRICEEXCEPTIONBYSPID,
  REMOVEPRICEEXCEPTIONBYSPID,
  GETPRICELISTBYSKUID,
  GETSKUCATEGORYLISTUPDATE,
  GETSKUCOMPANYLISTUPDATEBYPRODUCTID,
  GETSKULISTUPDATEBYPRODUCTID,
} from "../../utility/ReduxConstants/SkuReducerConstant";

const initialState = {
  skuList: [],
  categoryList: [],
  companyList: [],
  ProductList: [],
  skuDetail: {},

  exceptions: [],
  exceptionDetail: {},

  companyListUpdate: [],
  productListUpdate: [],

  stateList: [],
  zoneListAdd: [],
  zoneListUpdate: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSKUPAGEDATACONSTANT:
      return {
        ...state,
        skuList:
          action.payload.item_carousel !== undefined &&
          action.payload.item_carousel !== null &&
          action.payload.item_carousel.length !== 0
            ? action.payload.item_carousel
            : [],
        skuDetail:
          action.payload.item_carousel !== undefined &&
          action.payload.item_carousel !== null &&
          action.payload.item_carousel.length !== 0
            ? action.payload.first_item_details
            : {},
        exceptions:
          action.payload.exception_by_zone !== undefined &&
          action.payload.exception_by_zone !== null &&
          action.payload.exception_by_zone.length !== 0
            ? action.payload.exception_by_zone
            : [],
        exceptionDetail:
          action.payload.exception_by_zone !== undefined &&
          action.payload.exception_by_zone !== null &&
          action.payload.exception_by_zone.length !== 0
            ? action.payload.exception_by_zone[0] !== undefined &&
              action.payload.exception_by_zone[0] !== null
              ? action.payload.exception_by_zone[0]
              : {}
            : {},
      };
    case GETSKULISTUPDATEBYPRODUCTID:
      return {
        ...state,
        skuList:
          action.payload.list !== undefined &&
          action.payload.list !== null &&
          action.payload.list.length !== 0
            ? action.payload.list
            : [],
        skuDetail:
          action.payload.list !== undefined &&
          action.payload.list !== null &&
          action.payload.list.length !== 0 &&
          action.payload.list[0] &&
          action.payload.list[0] !== undefined &&
          action.payload.list[0] !== null
            ? action.payload.list[0]
            : {},
        exceptions:
          action.payload.exceptions !== undefined &&
          action.payload.exceptions !== null &&
          action.payload.exceptions.length !== 0
            ? action.payload.exceptions
            : [],
      };

    case GETSKUBYIDDETAILS:
      return {
        ...state,
        skuDetail:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.item_details !== undefined &&
          action.payload.item_details !== null
            ? action.payload.item_details
            : {},
      };

    case GETSKUCATEGORYLIST:
      return {
        ...state,
        categoryList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSKUCOMPANYLIST:
      return {
        ...state,
        companyList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSKUPRODUCTLIST:
      return {
        ...state,
        productList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSKUCOMPANYLISTUPDATE:
      return {
        ...state,
        companyListUpdate:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSKUPRODUCTLISTUPDATE:
      return {
        ...state,
        productListUpdate:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case UPDATEPARTICULARSKUBYID:
      return {
        ...state,
        skuList: state.skuList.map((item) =>
          item.sku_id === action.payload.sku_id ? action.payload : item
        ),
        skuDetail: action.payload,
      };

    case GETALLSTATELISTSKU:
      return {
        ...state,
        stateList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETALLZONELISTSKU:
      return {
        ...state,
        zoneListAdd:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSKUCATEGORYLISTUPDATE:
      return {
        ...state,
        categoryListUpdate:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSKUCOMPANYLISTUPDATEBYPRODUCTID:
      return {
        ...state,
        companyListUpdate:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETALLZONELISTSKUUPDATE:
      return {
        ...state,
        zoneListUpdate:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case UPDATEPRICEEXCEPTIONBYSPID:
      return {
        ...state,
        exceptions: state.exceptions.map((item) =>
          parseInt(item.sp_id) === parseInt(action.payload.sp_id)
            ? action.payload
            : item
        ),
      };

    case REMOVEPRICEEXCEPTIONBYSPID:
      return {
        ...state,
        exceptions: state.exceptions.filter(
          (item) => parseInt(item.sp_id) !== parseInt(action.payload.sp_id)
        ),
      };

    case GETPRICELISTBYSKUID:
      return {
        ...state,
        exceptions:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    default:
      return state;
  }
};
