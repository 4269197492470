export const GETEXCEPTIONSTATELIST = "GETEXCEPTIONSTATELIST";
export const GETEXCEPTIONDISTRCTLIST = "GETEXCEPTIONDISTRCTLIST";
export const GETEXCEPTIONSUPERCATEGORYLIST = "GETEXCEPTIONSUPERCATEGORYLIST";
export const GETEXCEPTIONACCESSSCLIST = "GETEXCEPTIONACCESSSCLIST";
export const GETEXCEPTIONZONELIST = "GETEXCEPTIONZONELIST";
export const GETCATEGORYLISTFOREXCEPTION = "GETCATEGORYLISTFOREXCEPTION";
export const GETNEWEXCEPTIONLISTBYZONE = "GETNEWEXCEPTIONLISTBYZONE";

export const GETNEWEXCEPTIONDETAILBYZONE = "GETNEWEXCEPTIONDETAILBYZONE";
export const UPDATENEWSKUEXCEPTIONLIST = "UPDATENEWSKUEXCEPTIONLIST";

export const GETEXCEPTIONACCESSSTATELIST = "GETEXCEPTIONACCESSSTATELIST";
export const GETEXCEPTIONACCESSDISTRICTLIST = "GETEXCEPTIONACCESSDISTRICTLIST";
export const GETEXCEPTIONACCESSZONELIST = "GETEXCEPTIONACCESSZONELIST";
export const GETRETAINVALUEFROMLOCAL = "GETRETAINVALUEFROMLOCAL";
export const GETEXCEPTIONACCESSSUPERCATEGORYLIST =
  "GETEXCEPTIONACCESSSUPERCATEGORYLIST";
