import { Button, Card, Paper, Stack, TextField } from "@mui/material";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/AddCategory.module.css";
import images from "../../../../utility/constants/images";
import { styled } from "@mui/material/styles";
import { AutocompleteTextField } from "../../../../utility/TextField";

import axios from "axios";
import {
  baseImgUploadUrl,
  baseImgPath,
  defaultImg,
} from "../../../../utility/BaseUrl";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddCategoryData,
  GetCategoryListBySCId,
} from "../../../../actions/category/categoryActions";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import {
  AddSubCategory,
  GetSubCategoryList,
} from "../../../../actions/subcategory/subcategory.action";
import { GETSUBCATEGORYLIST } from "../../../../utility/ReduxConstants/SubCategory.Reducer.Constant";

const viewImgPath = `${baseImgPath}/category/`;

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const AddCategory = () => {
  const Axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [file, setFile] = useState("");
  const store = useSelector((state) => state.SubCategoryReducer);
  const [img, setImg] = useState(defaultImg);
  const [addsuccess, setAddsuccess] = useState(false);
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });
  const StyleSheet = document.querySelector(":root");
  const ComputedStyle = getComputedStyle(StyleSheet);
  const BgColour = ComputedStyle.getPropertyValue("--color-light-background");
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    s_category_image: "",
    s_category_name: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancel = () => {
    setForm({
      ...form,
      s_category_name: "",
      s_category_image: "",
    });

    setImg(defaultImg);
  };

  useEffect(() => {
    setForm({
      ...form,
      sc: store?.sc,
      category: store?.category,
    });
  }, [store.sc, store.category]);

  const IsValid = () => {
    let success = true;
    let err = {};
    const chars = form.s_category_name?.match(/[^a-zA-Z0-9',&-](?= |$)/g);

    if (!form.s_category_name.trim()) {
      err.s_categoryname = "**category Name Cannot Be Empty**";
      success = false;
    } else if (chars && chars?.length !== 0) {
      err.s_categoryname =
        "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
      success = false;
    }

    if (!form.s_category_image.trim()) {
      err.s_categoryimage = "**Please Choose a Image**";
      success = false;
    }

    // if (!form.item_desc.trim()) {
    //     err.description = '**category Description Cannot Be Empty**'
    //     success = false;
    // }

    setError(err);
    return success;
  };

  const GetSubCategoryList_ = async (param) => {
    ////console.log(param,"GetSubCategoryList_")
    const Data = await dispatch(GetSubCategoryList(Axios, param));
    //console.log(Data,"GetSubCategoryList_",param)
    if (Data.code == 200) {
      dispatch({
        type: GETSUBCATEGORYLIST,
        payload: Data?.list,
      });
    } else {
      toast.error("Failed to fetch Sub Category List");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (IsValid()) {
      let param = {
        category_id: form?.category?.category_id,
        sc_id: form?.category?.sc_id,
        s_category_name: form?.s_category_name,
        s_category_image: form?.s_category_image,
      };

      let scId = form.sc.sc_id;
      const Data = await dispatch(AddSubCategory(Axios, param));
      console.log(Data, "addDataSub");
      if (Data.code === 200) {
        let paramTwo = {
          sc_id: form.sc.sc_id,
          category_id: form.category.category_id,
        };
        GetSubCategoryList_(paramTwo);
        toast.success("Added Category Successfully");
        setForm({
          ...form,
          s_category_name: "",
          s_category_image: "",
        });
        setImg(defaultImg);
      } else {
        toast.error(Data.message);
      }
    }
  };

  const imgUpload = async (formData) => {
    await axios
      .post(`${baseImgUploadUrl}image_upload_sub_category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setLoading(false);
          toast.success("Uploaded SuccessFully");
          setImg(`${res.data.full_path}`);
          setForm({
            ...form,
            s_category_image: res.data.full_path,
          });
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) => {
        //console.log(err.message)
      });
  };

  const imgSelect = (event) => {
    setLoading(true);
    setFile(event.target.files[0]);
  };

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Added Category Details SuccessFully");
      setForm({
        ...form,
        s_category_name: "",
        s_category_image: "",
      });
      setImg(defaultImg);
      setAddsuccess(false);
    }
  }, [addsuccess]);

  React.useEffect(() => {
    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failed]);

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    imgUpload(formData);
  }, [file]);

  const fontColor = {
    style: { color: "rgb(50, 50, 50)" },
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit}>
        <Stack direction="column" gap={3}>
          <Stack gap={0.5}>
            <Card component={Paper} sx={{ borderRadius: "16px" }}>
              <Stack
                sx={{ height: "180px" }}
                direction="row"
                justifyContent={"center"}
                alignItems="center"
              >
                {!loading && (
                  <img
                    src={img}
                    alt="company"
                    loading="lazy"
                    className={Styles.Img}
                  />
                )}

                {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                {loading ? (
                  <Stack
                    direction="column"
                    position="absolute"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress />
                    <Typography>uploading...</Typography>
                  </Stack>
                ) : null}
                <input
                  type="file"
                  accept="image/*"
                  name="image-upload"
                  id={`CategoryImgUploadAdd`}
                  className={Styles.imginp}
                  onChange={imgSelect}
                />
              </Stack>
            </Card>
            {error?.s_categoryimage && (
              <Typography
                sx={{ color: "red" }}
                variant="caption"
                className={Styles.error}
              >
                {error.s_categoryimage}
              </Typography>
            )}
            <div className={Styles.ImageUploadContainer}>
              <label
                className={Styles.ImageUploadButton}
                htmlFor={`CategoryImgUploadAdd`}
              >
                UPLOAD
              </label>
            </div>
          </Stack>
          <Stack gap={1.5}>
            <Stack>
              <AutocompleteTextField
                size="small"
                value={form?.sc?.name ?? ""}
                label="Super Category Name"
                name="sc_name"
                readonly
                disabled={true}
                InputLabelProps={{ shrink: true }}
                // autoFocus
                sx={{}}
              />
              {error.companyname && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  className={Styles.error}
                >
                  {error.companyname}
                </Typography>
              )}
            </Stack>
            <Stack>
              <AutocompleteTextField
                size="small"
                value={form?.category?.category_name ?? ""}
                label="Category Name"
                name="sc_name"
                readonly
                InputLabelProps={{ shrink: true }}
                // autoFocus
                disabled={true}
              />
              {error.companyname && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  className={Styles.error}
                >
                  {error.companyname}
                </Typography>
              )}
            </Stack>
            <Stack>
              <AutocompleteTextField
                size="small"
                sx={{ color: "white !important" }}
                label="Sub Category Name"
                name="s_category_name"
                value={form.s_category_name ?? ""}
                onChange={handleChange}
              />
              {error.s_categoryname && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  className={Styles.error}
                >
                  {error.s_categoryname}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" gap={1.5}>
            <RedButton sx={{ flex: 1, borderRadius: "18px" }} type="submit">
              Add
            </RedButton>
            <RedBorderButton
              sx={{ flex: 1, borderRadius: "18px", backgroundColor: "white" }}
              onClick={handleCancel}
            >
              Cancel
            </RedBorderButton>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default AddCategory;
