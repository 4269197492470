import { GETPRODUCTDATA, GETPRODUCTDETAILS, UPDATEPRODUCTDATAINLIST,REMOVEPRODUCTDATAINLIST } from "../../utility/ReduxConstants/ProductReducerConstant"

const initialState={
    product_list:[],
    product_detail:{}
}

export default(state=initialState,action)=>{
    switch(action.type){
        
         case GETPRODUCTDATA :
             return {
                 ...state,
                 product_list: action.payload.item_carousel!==undefined && action.payload.item_carousel!==null && action.payload.item_carousel.length!==0 ? (action.payload.item_carousel) : ([]),
                 product_detail:action.payload.item_carousel!==undefined && action.payload.item_carousel!==null && action.payload.item_carousel.length!==0 && action.payload.first_item_details!==undefined && action.payload.first_item_details!==null ? (action.payload.first_item_details) : ({})
             }

          case GETPRODUCTDETAILS:
              return{
                  ...state,
                  product_detail:action.payload.item_details!==undefined && action.payload.item_details!==null ? (action.payload.item_details):({})
              }
          case UPDATEPRODUCTDATAINLIST:
              return{
                  ...state,
                  product_list: state.product_list.map(item=>item.item_id===parseInt(action.payload.item_id) ? action.payload : item)
              }
          
              case REMOVEPRODUCTDATAINLIST:
                  return{
                      ...state,
                      product_list: state.product_list.filter(item=>item.item_id!==parseInt(action.payload.item_id))
                  }
               
        default:
            return state
    }
}