import React from "react";
import { Stack, Autocomplete } from "@mui/material";
import { AutocompleteTextFieldNormal } from "../../../utility/TextField";
import SwapVertIcon from '@mui/icons-material/SwapVert';

const WalletSearch = () => {
  return (
    <Stack sx={{ marginTop: "4rem",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center" }}>
      <Autocomplete
        sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
        // value={obj.stateName}
        // options={stateListNames}
        getOptionLabel={(option) => option}
        size="small"
        // onChange={handleState}
        renderInput={(params) => (
          <AutocompleteTextFieldNormal
            {...params}
            sx={{
              width: "100%",
              backgroundColor: "#25293C",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  // border: "none",
                  borderColor: "white",
                },
              },
            }}
            variant="outlined"
            label="Vitaran Partners"
            placeholder="Select State.."
          />
        )}
      />
      <Stack sx={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"1rem"}}>
        <Stack sx={{background:"#505365",color:"white",padding:"0.65rem",cursor:"pointer"}}>
        <SwapVertIcon/>
        </Stack>
        
        <Stack sx={{width:"20rem"}}>
        <input type="text" placeholder="Search By Name or Mobile Number" style={{padding:"0.7rem",background:"#323546",border:"0.1rem solid #757575"}}/>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WalletSearch;
