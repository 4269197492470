import React, { useState, useEffect } from "react";
import Styles from "./styles/carousel.module.css";
import { CircularProgress, Stack, Typography } from '@mui/material';
import BrokenImg from '../../../../assets/ImageNotValid.png'
import { ValidImage } from '../../../../actions/validImage';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";
import images from "../../../../utility/constants/images";
import { useSelector, useDispatch } from "react-redux";
import { baseImgPath, defaultImg } from "../../../../utility/BaseUrl";
import {
  GetCategoryDetailsId,
  GetCategoryListBySCId,
  SelectSuperCategory
} from "../../../../actions/category/categoryActions";
import { SearchFilter } from "../../../../utility/functions/functions";
import { Autocomplete, Card, Paper, TextField } from "@mui/material";
import { GETCATEGORYDETAILS, SELECTEDSUPERCATEGORY } from "../../../../utility/ReduxConstants/CategoryReducerConstant";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'


const viewImgPath = `${baseImgPath}/category/`;
const CarouselCategory = () => {
  const Axios = useAxiosPrivate()
  const scrollRef = React.useRef(null);
  const store = useSelector((state) => state.Category);
  const column = ["category_name"];
  const [text, setText] = useState("");
  const [list, setList] = useState([]);
  const [id, setId] = useState("");

  const [scValue, setscValue] = useState("");

  const [scId, setscId] = useState(0);
  const [scList, setscList] = useState([]);

  const dispatch = useDispatch();

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  React.useEffect(() => {
    let arr = [];
    let selected_category = {};
    if (
      store.category_list !== undefined &&
      store.category_list !== null &&
      store.category_list.length !== 0
    ) {
      for (let i = 0; i < store.category_list.length; i++) {
        selected_category = store.category_list[0];
        arr.push(store.category_list[i]);
      }
    }
    setList(arr); //store.s_category_list

    let param = {
      item_id: store?.category_list[0]?.category_id,
      type: "category"
    };

    setId(store?.category_list[0]?.category_id)

    dispatch(GetCategoryDetailsId(Axios,param));
  }, [store.category_list]);

  useEffect(() => {
    let arr = [];

    if (
      store.s_category_list != undefined &&
      store.s_category_list !== null &&
      store.s_category_list.length !== 0
    ) {
      for (let i = 0; i < store.s_category_list.length; i++) {
        arr.push(store.s_category_list[i].name);
      }
    }

    setscList(arr);
    setscValue(arr[0] ?? '');
    let param = {
      sc_id: store?.s_category_list[0]?.sc_id,
      sc_name: store?.s_category_list[0]?.name,
    }
    dispatch({ type: SELECTEDSUPERCATEGORY, payload: param });
    let paramTwo = {
      sc_id: store?.s_category_list[0]?.sc_id,
    };
    dispatch(GetCategoryListBySCId(Axios,paramTwo));
  }, [store.s_category_list]);

  const handleClick = (id) => {
    console.log(id)
    let selected_category = {};
    for (let i = 0; i < list.length; i++) {
      if (id === list[i].category_id) {
        selected_category = list[i];

        console.log(selected_category, "selected_category")
      }
    }

    setId(parseInt(id))


    console.log(selected_category)
    let param = {
      item_id: selected_category.category_id,
      category_image: selected_category.category_image,
      category_name: selected_category.category_name,
      sc_id: selected_category.sc_id,
      "type": "category",
      sc_name: scValue
    };

    dispatch(GetCategoryDetailsId(Axios,param));
  };

  const handleSuperCategoryChange = (event, value) => {
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id = 0;

      for (let i = 0; i < store.s_category_list.length; i++) {
        if (value === store.s_category_list[i].name) {
          id = store.s_category_list[i].sc_id;
          break;
        }
      }

      setscValue(value);
      setscId(id);

      dispatch({
        type: GETCATEGORYDETAILS, payload: {
          description: "",
          item_id: 0,
          item_image: defaultImg,
          item_name: "",
          sc_id: id,
          sc_name: "value"
        }
      })
      let param2 = {
        sc_id: id,
        sc_name: value
      }
      dispatch({ type: SELECTEDSUPERCATEGORY, payload: param2 });
      let param = {
        sc_id: id,
      };
      dispatch(GetCategoryListBySCId(Axios,param));
    }
  };


  console.log(store, "list")

  return (
    <div className={Styles.container}>
      <div className={Styles.SearchContainer}>
        <Autocomplete
          value={scValue}
          options={scList}
          onChange={handleSuperCategoryChange}
          className={Styles.autocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              label="Super Category"
            />
          )}
        />
        {/* <label className={Styles.SearchTitle}>Search</label> */}
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Search Category Name..."
          className={Styles.SearchInput}
        />
      </div>

      <div className={Styles.CarouselContainer}>
        {list.length !== 0 ? (
          <div className={Styles.ImageContainer} ref={scrollRef}>
            {SearchFilter(list, column, text).map((image, index) => (
              <div
                className={Styles.ColumnContainer}
                onClick={() => handleClick(image.category_id)}
              >
                <Card component={Paper} sx={{ backgroundColor: "#4a4f68" }} className={id === parseInt(image.category_id) ? Styles.ImagesCardSelected : Styles.ImagesCard} key={`gallery_image-${index + 1}`}>

                  <Img img={image.category_image}/>

                </Card>
                <label className={Styles.ImageTitle}>
                  {image.category_name}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div className={Styles.NothingContainer}>
            <label className={Styles.Nothing}>**No Categories**</label>
          </div>
        )}
        <div className={Styles.ArrowContainer}>
          <BsArrowLeftShort
            className={Styles.ArrowIcon}
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className={Styles.ArrowIcon}
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};

export default CarouselCategory;


const Img = ({ img }) => {
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState('')
  const CheckImage = async () => {
      const Result = await ValidImage(img)

      if (Result) {
          setObj(img)
          setLoading(false)
      } else {
          setLoading(false)
          setObj(BrokenImg)
      }

  }
  useEffect(() => {
      setLoading(true)
      setTimeout(() => {
          CheckImage(img)
      }, [1000])

  }, [img])

  return (

      loading ?
          <Stack direction="column" justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
              <CircularProgress />
              <Typography>loading...</Typography>
          </Stack> :
          <img src={obj !== undefined && obj !== null && obj.length !== 0 ? (`${obj}`) : (defaultImg)} alt="gallery_image" loading='lazy' />


  )

}