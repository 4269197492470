export const GETCATEGORYDATA = "GETCATEGORYDATA";
export const GETCATEGORYDETAILS = "GETCATEGORYDETAILS";

export const UPDATECATEGORYDATAINLIST = "UPDATECATEGORYDATAINLIST";
export const REMOVECATEGORYDATAINLIST = "REMOVECATEGORYDATAINLIST";
export const SELECTEDSUPERCATEGORY = "SELECTEDSUPERCATEGORY";


export const GETSCDATA = "GETSCDATA";

