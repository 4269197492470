import React from "react";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";

import DrawerAssign from "./DrawerAssign";

const DataDrawer = ({ open, setOpen, MapData }) => {
  const MapUserList = useSelector((state) => state.MapReducer);

  console.log(MapUserList?.AssignCheckBoxData?.status, "isChecked");

  return (
    <Drawer
      sx={{
        width: "90%",
        justifyContent: "space-between",
        marginTop: "-50px",
        overflow: "auto",
        position: "absolute",
        maxWidth: "79.4%",
      }}
      PaperProps={{
        elevation: 8,
        sx: {
          width: "25rem",
          height: "36rem",
          marginTop: "12rem",
          borderRadius: "5px",
          padding: "5px",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DrawerAssign setOpen={setOpen} />
    </Drawer>
  );
};

export default DataDrawer;
