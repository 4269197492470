import {
  GETALLVIDEODETAILS,
  GETALLWATCHCOUNTBYVIDEOTITLE,
  GETDISTRICTBYSTATEID,
  GETUSERVIDEODETAILSBYZONE,
  GETVIDEOANALYTICSDISTRICTLIST,
  GETVIDEOANALYTICSSTATELIST,
  GETVIDEOANALYTICSZONELIST,
  GETVIDEODETAILSBYZONE,
  GETVIDEODETAILSBYZONEANDVIDEO,
  GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT,
  GETWATCHCOUNTBYVIDEOTITLE,
  GETZONEBYDISTRICTID,
} from "../../utility/ReduxConstants/VideoAnalyticsReducerConstant";

export const VideoAnalyticsAllStateList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");
    console.log(data, "stateList");

    if (data.code === 200) {
      dispatch({ type: GETVIDEOANALYTICSSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const VideoAnalyticsAllDistrictList =
  (Axios,param) => async (dispatch) => {
    try {
      // let code = 500
      const { data } = await Axios.post("AlldistrictList",param);

      if (data.code === 200) {
        dispatch({
          type: GETVIDEOANALYTICSDISTRICTLIST,
          payload: data.districtList,
        });
        // setLoading(false);
        return data.districtList;
      }
    } catch (error) {
      return error?.response?.data;
      console.log(error);
    }
  };

export const VideoAnalyticsAllZoneList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllzoneList", param);
    console.log(data, "appppple");
    if (data?.code === 200) {
      dispatch({ type: GETVIDEOANALYTICSZONELIST, payload: data?.zoneList });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const VideoAnalyticsDistrictByStateId =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("DistrictListByStateId", param);
      if (data.code === 200) {
        dispatch({ type: GETDISTRICTBYSTATEID, payload: data.districtList });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

export const VideoAnalyticsZoneByDistrictIdAndStateId =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post(
        "ZoneListByStateIdAndDistrictId",
        param
      );
      if (data.code === 200) {
        dispatch({ type: GETZONEBYDISTRICTID, payload: data.zoneList });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

export const VideoAnalyticsVideoDetailsByZone =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("videoByZone", param);
      if (data.code === 200) {
        dispatch({ type: GETVIDEODETAILSBYZONE, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  export const VideoAnalyticsVideoDetailsByZoneAndVideo =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("videoByZoneAndVideoId", param);
      if (data.code === 200) {
        dispatch({ type: GETVIDEODETAILSBYZONEANDVIDEO, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  export const VideoAnalyticsVideoDetailsByZoneVideoAndWatchCount =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("videoByZoneAndVideoIdAndWatchCount", param);
      if (data.code === 200) {
        dispatch({ type: GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  export const VideoAnalyticsUserVideoDetailsByZone =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("userVideoDetails", param);
      if (data.code === 200) {
        dispatch({ type: GETUSERVIDEODETAILSBYZONE, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };


  export const videoAnalyticsAllVideoDetails =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("userVideoAllDetails", param);
      if (data.code === 200) {
        dispatch({ type: GETALLVIDEODETAILS, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  export const videoAnalyticsWatchCountByVideoName =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("watchCountByVideoTitle", param);
      if (data.code === 200) {
        dispatch({ type: GETWATCHCOUNTBYVIDEOTITLE, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  export const videoAnalyticsAllWatchCountByVideoName =
  (Axios) => async (dispatch) => {
    try {
      const { data } = await Axios.post("allWatchCountByVideoTitle");
      if (data.code === 200) {
        dispatch({ type: GETALLWATCHCOUNTBYVIDEOTITLE, payload: data.body.rows });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };



