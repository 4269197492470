import React from "react";
import WalletDropdown from "./WalletDropdown";
import WalletCoinDetails from "./WalletCoinDetails";
import WalletSearch from "./WalletSearch";
import WalletTable from "./WalletTable";

const WalletManagement = () => {
  return (
    <>
      <WalletDropdown />
      <WalletCoinDetails />
      <WalletSearch />
      <WalletTable />
    </>
  );
};

export default WalletManagement;
