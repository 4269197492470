import React, { useState, useEffect } from "react";
import Styles from "./styles/login.module.css";
import GroupOne from "../../assets/loginGroupOne.png";
import GroupTwo from "../../assets/loginGroupTwo.png";
import logo from "../../assets/liveLogo.png";
import Login from "./Login";
import ForgotPassword from "./forgetPassword";
import ChangePassword from "./ChangePassword";

const LoginMgt = () => {
  const [showForget, setShowForget] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [mobile, setRootMobile] = useState("");

  const handleShowForget = (status) => {
    setShowForget(status);
  };
  const handleShowChange = (status) => {
    setShowChange(status);
  };

  return (
    <div className="LoginContainer">
      <div className={Styles.Container}>
        <div className={Styles.LogoContainer}>
          <img src={logo} />
        </div>

        <div className={Styles.GroupTwo}>
          <img src={GroupTwo} />
        </div>

        <div className={Styles.GroupOne}>
          <img src={GroupOne} />
        </div>

        <div className={Styles.Card}>
          {showForget ? (
            showChange ? (
              <ChangePassword mobile={mobile} />
            ) : (
              <ForgotPassword
                handleShowChange={handleShowChange}
                setRootMobile={setRootMobile}
              />
            )
          ) : (
            <Login handleShowForget={handleShowForget} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginMgt;
