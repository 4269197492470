import {
  GETALLUPDATEDVALUE,
  GETPARTNERDROPDOWNLIST,
  GETPARTNERWALLETALLDISTRICTLIST,
  GETPARTNERWALLETALLSCLIST,
  GETPARTNERWALLETALLSTATELIST,
  GETPARTNERWALLETALLZONELIST,
  GETPARTNERWALLETAPPROVALLIST,
  GETSENDCOINSUSERID,
  GETWALLETOBJCHANGEDVALUE,
  GETWALLETTRANSACTIONDETAILS,
  GETWALLETUSERLIST,
  GETWALLETVPDETAILS,
} from "../../utility/ReduxConstants/PartnerWalletManagementReducerConstant";
import {
  GETSENDCOINPOPUPSTATUS,
  GETWALLETALLDISTRICTLIST,
  GETWALLETALLSTATELIST,
  GETWALLETALLZONELIST,
} from "../../utility/ReduxConstants/WalletManagementReducerConstant";

const initialState = {
  sendCoinPopupStatus: false,
  walletAllStateList: null,
  walletAllDistrictList: null,
  walletAllZoneList: null,
  walletAllSuperCategoryList: null,
  walletApprovalList: null,
  walletAllDropdownList: null,
  walletUserList: null,
  updatedIDList: null,
  walletTransDetails: null,
  walletChangedValueDetails: null,
  walletSendCoinsId: null,
  walletVPDetails: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSENDCOINPOPUPSTATUS:
      return {
        ...state,
        sendCoinPopupStatus: action.payload,
      };
    case GETPARTNERWALLETALLSTATELIST:
      return {
        ...state,
        walletAllStateList: action.payload,
      };
    case GETPARTNERWALLETALLDISTRICTLIST:
      return {
        ...state,
        walletAllDistrictList: action.payload,
      };
    case GETPARTNERWALLETALLZONELIST:
      return {
        ...state,
        walletAllZoneList: action.payload,
      };
    case GETPARTNERWALLETALLSCLIST:
      return {
        ...state,
        walletAllSuperCategoryList: action.payload,
      };
    case GETPARTNERWALLETAPPROVALLIST:
      return {
        ...state,
        walletApprovalList: action.payload,
      };
    case GETPARTNERDROPDOWNLIST:
      return {
        ...state,
        walletAllDropdownList: action.payload,
      };
    case GETWALLETUSERLIST:
      return {
        ...state,
        walletUserList: action.payload,
      };
      case GETWALLETVPDETAILS:
      return {
        ...state,
        walletVPDetails: action.payload,
      };
    case GETALLUPDATEDVALUE:
      return {
        ...state,
        updatedIDList: action.payload,
      };
    case GETWALLETTRANSACTIONDETAILS:
      return {
        ...state,
        walletTransDetails: action.payload,
      };
    case GETWALLETOBJCHANGEDVALUE:
      return {
        ...state,
        walletChangedValueDetails: action.payload,
      };
    case GETSENDCOINSUSERID:
      return {
        ...state,
        walletSendCoinsId: action.payload,
      };

    default:
      return state;
  }
};
