import { Call } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useDispatch, useSelector } from "react-redux";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import {
  GetYourDealsActionBuyer,
  YourDealDb_List,
} from "../../../actions/yourDeals/YourDealsAction";
import { YourDealsUrl } from "./YourDealsUtilityFile";
import { GETYOURDEALSDATALISTBUYER } from "../../../utility/ReduxConstants/YourDealsReducerConstant";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import { ToastContainer, toast } from "react-toastify";
import {
  GetMapUserData,
  Get_assign_delivery_boy_Action,
  Get_remove_delivery_boy_from_order_Action,
} from "../../../actions/Map/MapAction";
import {
  MAPURL,
  assign_delivery_boy_URL,
  remove_delivery_boy_from_order,
} from "../GoogleMap/MapUtilityFile";
import { GETMAPALLORDERLIST } from "../../../utility/ReduxConstants/MapReducerConstant";

const YourDealsBuyerCard = () => {
  const YourDealsDbList = useSelector((state) => state.MapReducer);
  const YourDealsBuyerData = useSelector(
    (state) => state.YourDealsReducer?.yourDealsDataList
  );
  console.log(YourDealsBuyerData, "YourDealsDbList");
  const getBuyerDataList = useSelector((state) => state.YourDealsReducer);
  const [data, setData] = useState([]);
  const [DB_NAME, setDB_NAME] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const LambdaAxios = useAxiosLambda();

  console.log(JSON.stringify(YourDealsBuyerData) + "yourdealsbuyerdata")

  const Count = getBuyerDataList?.yourDealsDataList?.order_list?.length;
  console.log(Count, "Count");

  const filterBy = getBuyerDataList?.getBuyerDataList.filterName;
  const GetYourDealsData = async () => {
    console.log("GetYourDealsData", "called");
    console.log(filterBy, "filterBy");
    let param = {
      trade_type: "buy",
      for: filterBy,
    };
    const Data = await dispatch(
      GetYourDealsActionBuyer(LambdaAxios, YourDealsUrl, param)
    );

    if (Data?.status === 200) {
      dispatch({
        type: GETYOURDEALSDATALISTBUYER,
        payload: Data?.data?.body,
      });
      console.log(Data, "GetYourDealsData2");
    }
  };

  const jdjjdbbun = getBuyerDataList?.yourDealsDataListSeller?.order_list;

  console.log(jdjjdbbun, "jdjjdbbun");

  useEffect(() => {
    GetYourDealsData();
  }, [getBuyerDataList?.getBuyerDataList]);

  const handleDirectionsClick = ({ lat, long }) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const url = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${lat},${long}`;
          window.open(url, "_blank");
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      toast.warning("Geolocation is not supported by this browser.");
    }
  };
  const get_Db_Data_For_YD = async () => {
    // let param = {
    //   db_id: id,
    // };
    const Data = await dispatch(YourDealDb_List(LambdaAxios, MAPURL));
    console.log(Data, "get_Db_Data_For_YD");
    if (Data?.status === 200) {
      setData(Data?.data?.db_list);
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }
  };

  useEffect(() => {
    get_Db_Data_For_YD();
  }, []);

  useEffect(() => {
    setDB_NAME();
  }, []);

  const handleAssignDeliveryBoy = async ({ event, item, index }) => {
    console.log("handleAssignDeliveryBoy", index);
  };

  const deliveryBoy_Id = [
    {
      db_id: "-1",
      name: "MySelf",
    },
    ...data,
  ];

  const Db_Id_data = deliveryBoy_Id?.map((item) => item);
  console.log(Db_Id_data, deliveryBoy_Id, "handleGetdb_idInYourDeals3");

  const handleGetdb_id = async ({ i, e, order }) => {
    console.log(i, e, order, "handleGetdb_idInYourDeals");
    if (e?.name === "MySelf") {
      let param = {
        order_id: order,
      };
      const Data = await dispatch(
        Get_remove_delivery_boy_from_order_Action(
          LambdaAxios,
          remove_delivery_boy_from_order,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_remove_delivery_boy_from_order_Action(
            LambdaAxios,
            remove_delivery_boy_from_order,
            param
          )
        );
        GetYourDealsData();
      } else {
        toast.error(Data?.message);
      }
      console.log(Data, "inhandleGetdb_id");
    } else {
      let param = {
        order_id: order,
        db_id: e?.db_id,
      };
      console.log(param, "inpparam");
      const Data = await dispatch(
        Get_assign_delivery_boy_Action(
          LambdaAxios,
          assign_delivery_boy_URL,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
        await dispatch(
          Get_assign_delivery_boy_Action(
            LambdaAxios,
            assign_delivery_boy_URL,
            param
          )
        );
        GetYourDealsData();
      } else {
        toast.warning(`failed to fetch Assign Delivery Partner`);
      }
      console.log(Data, "FetchingDataInDataDrawrDdetaills Partner");
    }
    const id = YourDealsDbList?.id;
    let param = {
      db_id: id,
    };
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param));
    if (Data?.data?.code === 200) {
      dispatch({
        type: GETMAPALLORDERLIST,
        payload: Data?.data,
      });
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }

    // const filterBy = getBuyerDataList?.getBuyerDataList.filterName;
    // console.log(filterBy, "filterBy");
    // let param3 = {
    //   trade_type: "buy",
    //   for: filterBy,
    // };
    // const Data2 = await dispatch(
    //   GetYourDealsActionBuyer(LambdaAxios, YourDealsUrl, param3)
    // );

    // if (Data2?.status === 200) {
    //   dispatch({
    //     type: GETYOURDEALSDATALISTBUYER,
    //     payload: Data2?.data?.body,
    //   });
    //   console.log(Data2, "GetYourDealsData2");
    // }s
  };

  // for formating 24h time to 12hr time wise
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${day} ${month} ${year}, ${time}`;
  };

  

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {Count === 0 ? (
        <DataCountShowDiv />
      ) : (
        <Stack>
          {YourDealsBuyerData?.order_list?.map((item, index) => {
            return (
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={4}
                sx={{ backgroundColor: "#2B2942" }}
                padding={"5px 10px"}
                margin={"auto"}
                borderRadius={"6px"}
                mb={"1rem"}
              >
                <Stack flex={1}>
                  <Stack sx={{ paddingBottom: "1rem" }}>
                    <Typography sx={{ fontSize: "13px" }}>
                      Order Id : {item?.order_code}
                    </Typography>
                  </Stack>
                  <Stack sx={{ paddingBottom: "10px" }}>
                    <Typography>{item?.sku_name}</Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Stack>
                      <img
                        style={{
                          width: "95px",
                          paddingRight: "10px",
                          paddingBottom: "10px",
                          height: "92px",
                        }}
                        src={item?.sku_image}
                        alt={item?.sku_name}
                      />
                    </Stack>
                    <Stack direction={"column"}>
                      <Stack
                        sx={{
                          color: "black",
                          borderRadius: "50px",
                          backgroundColor:
                            item?.trade_order_type === "pickup"
                              ? "#F3D848"
                              : "#70B0FB",
                          width: "240px",
                          height: "20px",
                          alignItems: "center",
                        }}
                        gap={5}
                      >
                        <Typography
                          textAlign={"center"}
                          alignItems={"center"}
                          sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                        >
                          <Stack direction={"row"} gap={1}>
                            <Stack>
                              <Typography
                                sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                              >
                                {item?.lots} Lots |{" "}
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                                textTransform={"capitalize"}
                              >
                                {item?.trade_order_type} |
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                sx={{ fontSize: { xs: "15px", lg: "13px" } }}
                              >
                                {item?.lot_contain} pkt per lot
                              </Typography>
                            </Stack>
                          </Stack>
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{
                          justifyContent: "space-between",
                          marginTop: "7px",
                        }}
                      >
                        <Stack sx={{ paddingRight: "1rem" }}>
                          <Stack>
                            <Typography
                              sx={{ fontSize: "13px", textAlign: "center" }}
                            >
                              Price per packet:
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{ fontSize: "13px", textAlign: "center" }}
                            >
                              {`₹ ${item?.trade_price}`}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            borderLeft: "1px solid white",
                            paddingLeft: "1rem",
                          }}
                        >
                          <Stack>
                            <Typography
                              sx={{ textAlign: "center", fontSize: "13px" }}
                            >
                              Price per Lot:
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography
                              sx={{ textAlign: "center", fontSize: "13px" }}
                            >
                              {`₹ ${item?.lot_price}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      backgroundColor: "#68669E",
                      padding: "10px 10px",
                      borderRadius: "5px",
                      marginTop: "1rem",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        justifyContent: "space-between",
                        borderBottom: "1px solid white",
                      }}
                      mb={"3px"}
                    >
                      <Stack>
                        <Typography sx={{ fontSize: "13px" }}>
                          Total Quantity
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Stack>
                          <Typography sx={{ fontSize: "13px" }}>
                            {/* 100 pkts*5 lotes */}
                            {`${item?.lot_contain} Pkt * ${item?.lots} = `}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              paddingLeft: "5px",
                              fontSize: "13px",
                            }}
                          >
                            {`${item?.lot_contain * item?.lots} Pkt`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Stack>
                          <Typography sx={{ fontSize: "13px" }}>
                            Total Order Amount
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography sx={{ fontSize: "13px" }}>
                            {`₹ ${item?.total_order_price}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      color: "#C8C3FF",
                      fontSize: "13px",
                    }}
                  >
                    {filterBy === "completed"
                      ? `Completed on ${formatDate(item?.order_completed_at)}`
                      : filterBy === "cancelled"
                      ? `Cancelled on ${formatDate(item?.order_cancelled_at)} `
                      : item?.order_status === "completed"
                      ? `Completed on ${formatDate(item?.order_completed_at)}`
                      : item?.order_status === "cancelled"
                      ? `Cancelled on ${formatDate(item?.order_cancelled_at)}`
                      : `To be Completed by ${formatDate(
                          item?.order_schedule_datetime
                        )}`}
                  </Stack>
                  <Stack
                    sx={{
                      display: filterBy === "today" ? "flex" : "none",
                      marginBottom: "1rem",
                      color: "#C8C3FF",
                      fontSize: "13px",
                    }}
                  >
                    <Stack>
                      Order Status :- {"  "}
                      {filterBy === "today"
                        ? (item?.order_status).replace(/_/g, " ")
                        : ""}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      marginBottom: "1rem",
                      color: "#C8C3FF",
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    {(filterBy === "today" &&
                      item?.order_status === "cancelled") ||
                    filterBy === "cancelled"
                      ? ` Cancel Reason : ${item?.cancel_reason}`
                      : ""}
                  </Stack>
                </Stack>
                <Stack flex={1}>
                  <Stack
                    direction={"row"}
                    sx={{
                      backgroundColor: "#46446C",
                      padding: "5px",
                      borderRadius: "6px",
                      justifyContent: "space-between",
                    }}
                    gap={2}
                  >
                    <Stack>
                      <img
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "100px",
                        }}
                        src={item?.profile_image}
                        alt="img"
                      />
                    </Stack>
                    <Stack>
                      <Stack>
                        <Typography sx={{ fontSize: "13px" }}>
                          {item?.sb_name}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Box
                          sx={{
                            "& > legend": { mt: 2 },
                          }}
                        >
                          <Rating
                            name="disabled"
                            value={item?.avg_rating}
                            precision={0.5}
                            readOnly
                            size="small"
                          />
                        </Box>
                      </Stack>
                    </Stack>
                    <Stack justifyContent={"flex-end"}>
                      <IconButton
                        sx={{
                          backgroundColor: "#312F4B",
                          "&:hover": {
                            backgroundColor: "#312F4B",
                          },
                        }}
                        onClick={() => 
                          {
                            try {
                              const phoneNumber =
                                item?.trade_order_type === "delivery"
                                  ? item?.db_id === null
                                    ? item?.call
                                    : item?.db_call
                                  : item?.trade_order_type === "pickup"
                                  ? item?.call
                                  : "";
                        
                              if (phoneNumber) {
                                const textField = document.createElement('textarea');
                                textField.innerText = phoneNumber;
                                document.body.appendChild(textField);
                                textField.select();
                                document.execCommand('copy');
                                textField.remove();
                                
                                toast.success("Phone number copied to clipboard", {
                                  variant: "success",
                                });
                              } else {
                                toast.warning("Phone number not available", {
                                  variant: "error",
                                });
                              }
                            } catch (error) {
                              console.error("Clipboard copying error:", error);
                              toast.error("An error occurred while copying to clipboard", {
                                variant: "error",
                              });
                            }}
                          }
                      >
                        <Tooltip
                          title={
                            item?.trade_order_type === "delivery"
                              ? item?.db_id === null
                                ? item?.call
                                : item?.db_call
                              : item?.trade_order_type === "pickup"
                              ? item?.call
                              : ""
                          }
                        >
                          <Call sx={{ color: "white" }} />
                        </Tooltip>
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Stack sx={{ paddingTop: "1rem" }}>
                    <Typography sx={{ fontSize: "13px" }}>
                      Scheme <br />{" "}
                      {item?.scheme.length === 0
                        ? "No scheme available"
                        : item?.scheme}
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} sx={{ paddingTop: "1rem" }}>
                    <Stack>
                      <Typography sx={{ color: "#F3D848", fontSize: "15px" }}>
                        {item?.trade_order_type === "delivery"
                          ? "Delivery Address "
                          : "Pick Up Address "}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Stack>
                        <Typography
                          sx={{ fontSize: { xs: "15px", lg: "11px" } }}
                        >
                          {item?.address}
                        </Typography>
                      </Stack>
                      <Stack sx={{ cursor: "pointer" }}>
                        <DirectionsIcon
                          sx={{ fontSize: { xs: "30px", lg: "20px" } }}
                          onClick={() =>
                            handleDirectionsClick({
                              lat: item?.lat,
                              long: item?.long,
                            })
                          }
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ paddingTop: "1rem" }}
                    gap={5}
                  >
                    {item?.trade_order_type === "delivery" ? (
                      ""
                    ) : (
                      <Stack sx={{ fontSize: "13px" }}>
                        {item?.trade_order_type === "pickup"
                          ? "Pickup"
                          : "Delivery"}{" "}
                        Partner
                      </Stack>
                    )}

                    <Stack>
                      {item?.trade_order_type === "delivery" ? (
                        ""
                      ) : (
                        <Stack>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <FormControl fullWidth>
                              {/* <InputLabel id="demo-simple-select-label">
                                Select
                              </InputLabel> */}
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disabled={
                                  filterBy === "completed" ||
                                  filterBy === "cancelled" ||
                                  (filterBy === "today" &&
                                    item?.order_status === "cancelled") ||
                                  (filterBy === "today" &&
                                    item?.order_status === "completed")
                                    ? true
                                    : false
                                }
                                // value={
                                //   DB_NAME === null ||
                                //   DB_NAME === undefined ||
                                //   DB_NAME === ""
                                //     ? item?.name === null ||
                                //       item?.name === undefined
                                //       ? "MySelf"
                                //       : item?.name
                                //     : DB_NAME
                                // }
                                value={
                                  item?.name === null ||
                                  item?.name === undefined
                                    ? "MySelf"
                                    : item?.name
                                }
                                onChange={(event) =>
                                  handleAssignDeliveryBoy({
                                    event,
                                    item,
                                    index,
                                  })
                                }
                                sx={{
                                  width: "8rem",
                                  backgroundColor: "#595C9B",
                                  borderRadius: "0",
                                  borderColor: "white",
                                }}
                                size="small"
                              >
                                {Db_Id_data?.map((e) => {
                                  return (
                                    <MenuItem
                                      onClick={(i) =>
                                        handleGetdb_id({
                                          item: i,
                                          e: e,
                                          order: item?.order_id,
                                        })
                                      }
                                      value={e?.name}
                                    >
                                      {e?.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default YourDealsBuyerCard;

const DataCountShowDiv = () => {
  return <Stack sx={{ color: "white", textAlign: "center" }}>No Orders</Stack>;
};
