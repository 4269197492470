import React, { useEffect, useState } from "react";
import Styles from "./Styles/PopUp.module.css";
import {
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Card,
  Stack,
  Grid,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PopUpImage from "./images";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCategoryRepoImages,
  GetAllCategoryNewSku,
  GetRepoImagesByCategoryId,
} from "../../../actions/newSkuAction/newSkuAction";
import axios from "axios";
import { baseUrl } from "../../../utility/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slide from "@mui/material/Slide";

import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { AutocompleteTextField } from "../../../utility/TextField";

const PopUp = ({ setShow, ChooseImg, show, handleImageDetails }) => {
  const theme = useTheme();
  const Sheet = document.querySelector(":root");
  const StyledRef = getComputedStyle(Sheet);
  const BGColor = StyledRef.getPropertyValue("--color-light-background");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [categoryLoader, setCategoryLoader] = useState(false);
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.NewSku);
  const dispatch = useDispatch();
  const [categoryValue, setCategoryValue] = useState("Bidi");
  const [list, setList] = useState([]);
  const [obj, setObj] = useState({
    superCategoryValue: "",
    superCategoryList: [],
  });
  const [catobj, setCatObj] = useState({
    categoryValue: "",
    categoryRootList: [],
    categoryList: [],
  });
  const [subCatobj, setSubCatObj] = useState({
    categoryValue: "",
    categoryRootList: [],
    categoryList: [],
  });

  console.log(store, "storeinsku");
  useEffect(() => {
    let scArr = [];
    if (
      store.superCategoryList !== undefined &&
      store.superCategoryList !== null
    ) {
      scArr = store.superCategoryList.map((item) => item.name);
    }
    setObj({
      ...obj,
      superCategoryList: scArr,
      superCategoryValue: scArr[0] ?? "",
    });
  }, [store.superCategoryList]);

  useEffect(() => {
    (async () => {
      setCategoryLoader(true);
      let sc = store?.superCategoryList?.filter(
        (item) => item.name === obj.superCategoryValue
      );
      let param = {
        sc_id: sc[0]?.sc_id ?? 0,
      };
      const CategoryList = await dispatch(GetAllCategoryNewSku(Axios, param));
      let catArr = CategoryList.list.map((item) => item.category_name);
      // setTimeout(async()=>{
      setCategoryLoader(false);
      setCatObj({
        ...catobj,
        categoryValue: catArr[0] ?? "",
        categoryRootList: CategoryList?.list ?? [],
        categoryList: catArr ?? [],
      });
    })();
  }, [obj.superCategoryValue]);

  const handleChange = async (e, value) => {
    console.log(value, "valuevalue");
    setCatObj({
      ...catobj,
      categoryValue: value ?? "",
    });
  };

  useEffect(() => {
    (async () => {
      let Cat = catobj.categoryRootList.filter(
        (item) => item.category_name === catobj.categoryValue
      );
      let paramTwo = {
        category_id: Cat[0]?.category_id ?? 0,
      };

      console.log(paramTwo, "paramTwo");

      let { body } = await dispatch(GetRepoImagesByCategoryId(Axios, paramTwo));

      console.log(body, "body cat");
      setList(body);
    })();
  }, [catobj.categoryValue]);

  const handleSuperCatChange = async (e, value) => {
    setObj({
      ...obj,
      superCategoryValue: value ?? "",
    });
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  console.log(`${useMediaQuery(theme.breakpoints.down("xs"))} xs`);
  console.log(`${useMediaQuery(theme.breakpoints.down("sm"))},sm`);
  console.log(`${useMediaQuery(theme.breakpoints.down("md"))},md`);
  console.log(`${useMediaQuery(theme.breakpoints.down("lg"))},lg`);
  console.log(`${useMediaQuery(theme.breakpoints.down("xl"))},xl`);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        fullScreen={fullScreen}
        open={show}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setShow(false)}
        PaperProps={{
          style: {
            backgroundColor: BGColor,
            color: "white",
            minWidth: "80vw",
            minHeight: "500px",
          },
        }}
      >
        <Stack gap={2} padding={1}>
          <div className={Styles.AutoCompleteContainer} style={{ gap: "10px" }}>
            <Autocomplete
              value={obj.superCategoryValue}
              options={obj.superCategoryList}
              className={Styles.autocomplete}
              onChange={handleSuperCatChange}
              renderInput={(params) => (
                <AutocompleteTextField
                  {...params}
                  size="small"
                  variant="outlined"
                  label="Super Category"
                />
              )}
            />
            <Autocomplete
              loading={categoryLoader}
              value={catobj.categoryValue}
              options={catobj.categoryList}
              className={Styles.autocomplete}
              onChange={handleChange}
              renderInput={(params) => (
                <AutocompleteTextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {categoryLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  label="Category"
                />
              )}
            />
          </div>
          {list !== undefined && list !== null && list.length !== 0 ? (
            <Grid container gap={1}>
              {list?.map((item, index) => (
                <Grid item xs={5} sm={3} md={2} lg={2} key={index}>
                  <Stack
                    gap={1}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <PopUpImage item={item} ChooseImg={ChooseImg} />

                    <Typography sx={{ color: "white" }} variant="caption">
                      {item?.image_name ?? ""}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={Styles.NoSkuContainer}>
              <label className={Styles.NoSku}>No Sku's!! Please Add</label>
            </div>
          )}

          <div className={Styles.center}>
            <Button variant="contained" onClick={() => setShow(false)}>
              Close
            </Button>
          </div>
        </Stack>
      </Dialog>
    </>
  );
};

export default PopUp;
