import {
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import styles from "./styles/DashboardCount.module.css";
import { useSelector } from "react-redux";
import { RoundOff } from "../../../utility/functions/functions";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const DashboardCount = () => {
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.Dashboard);
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffe6e6",
      color: theme.palette.common.black,
      height: 40,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#70B0FB",
      color: theme.palette.common.black,
      height: 105,
      width: "100%",
      borderRadius: "8px",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableRowThree = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#323546",
      color: "black",
      height: 105,
      width: "100%",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "#323546",
      color: "black",
      height: 105,
      width: "100%",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableRowTwo = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F9DE50",
      color: "black",
      height: 105,
      width: "100%",
      borderRadius: "8px",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCellEmpty = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "transparent",
      color: theme.palette.common.black,
      height: 56,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const DeliveryOrderCount = store?.deliveryOrderCount;
  
  const PickupOrderCount = store?.pickupOrderCount;

  const Head = [
    "Total",
    "Confirmed",
    "On The Way",
    "Completed",
    "Cancelled",
    "Delayed",
  ];
  const Delivery = [
    {
      orders: 26,
      price: 730000000,
    },
    {
      orders: 26,
      price: 730000000,
    },
    {
      orders: 26,
      price: 730000000,
    },
    {
      orders: 26,
      price: 730000000,
    },
    {
      orders: 26,
      price: 730000000,
    },
    {
      orders: 26,
      price: 730000000,
    },
  ];
  const Pickup = [
    {
      orders: 26,
      price: 730000000907,
    },
    {
      orders: 26,
      price: 730000000907,
    },
    {
      orders: 26,
      price: 730000000907,
    },
    {
      orders: 26,
      price: 730000000907,
    },
    {
      orders: 26,
      price: 730000000907,
    },
    {
      orders: 26,
      price: 730000000907,
    },
  ];
  return (
    <>
      <Stack padding="0px 5px" gap={1}>
        <Stack
          sx={{
            backgroundColor: "#25293C",
            borderRadius: "10px",
          }}
          padding={1}
        >
          <Stack sx={{ flex: "1" }} gap={1}>
            <Stack direction="row" gap={1}>
              <Stack flex={0.2}></Stack>
              {Head?.map((item) => (
                <Stack
                  flex={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" sx={{ color: "white" }}>
                    {item}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack
              direction="row"
              padding={1}
              gap={1}
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                backgroundColor: "#70B0FB !important",
                borderRadius: "6px",
              }}
            >
              <Stack
                flex={0.2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <label className={styles.verticalText}>Delivery</label>
              </Stack>

              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.total_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(DeliveryOrderCount?.total_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.confirmed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹{" "}
                  {RoundOff(DeliveryOrderCount?.confirmed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.on_the_way_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹{" "}
                  {RoundOff(DeliveryOrderCount?.on_the_way_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.completed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹{" "}
                  {RoundOff(DeliveryOrderCount?.completed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.cancelled_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹{" "}
                  {RoundOff(DeliveryOrderCount?.cancelled_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {DeliveryOrderCount?.delayed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(DeliveryOrderCount?.delayed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              padding={1}
              gap={1}
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                backgroundColor: "#F9DE50 !important",
                borderRadius: "6px",
              }}
            >
              <Stack
                flex={0.2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <label className={styles.verticalText}>Pickup</label>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.total_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.total_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.confirmed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.confirmed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.on_the_way_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.on_the_way_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.completed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.completed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.cancelled_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.cancelled_order_value, 2) ?? 0}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {PickupOrderCount?.delayed_order ?? 0} Orders
                </Typography>
                <Typography variant="caption">
                  ₹ {RoundOff(PickupOrderCount?.delayed_order_value, 2) ?? 0}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default memo(DashboardCount);
