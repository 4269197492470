import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const OrderCountMap = () => {
  const MapData = useSelector((state) => state.MapReducer);

  let onTheWayCount = 0;
  let confirmedCount = 0;

  for (let i = 0; i < MapData?.orders?.length; i++) {
    if (MapData?.orders[i]?.seller_order_status === "on_the_way") {
      onTheWayCount++;
    } else if (MapData?.orders[i]?.seller_order_status === "confirmed") {
      confirmedCount++;
    }
  }

  console.log(MapData, "inOrerCoungt");
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{
        color: "white",
        justifyContent: "space-between",
        width: "80%",
        margin: "auto",
        backgroundColor: "#525A84",
        padding: "1rem 4rem",
        borderRadius: "6px",
      }}
      gap={2}
    >
      <Stack
        sx={{
          backgroundColor: "#3B405E",
          padding: "5px 2rem",
          borderRadius: "50px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} gap={1}>
          <Stack>
            <Typography>TOTAL</Typography>
          </Stack>
          <Stack>
            <Typography>{MapData?.total_order_count}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#3B405E",
          padding: "5px 2rem",
          borderRadius: "50px",

          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} gap={1}>
          <Stack>
            <Typography>CONFIRMED</Typography>
          </Stack>
          <Stack>
            <Typography>{confirmedCount}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#3B405E",
          padding: "5px 2rem",
          borderRadius: "50px",

          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} gap={1}>
          <Stack>
            <Typography>ON THE WAY</Typography>
          </Stack>
          <Stack>
            <Typography>{onTheWayCount}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderCountMap;
