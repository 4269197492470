import {
  TextField,
  Autocomplete,
  Button,
  Paper,
  Card,
  IconButton,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/addZone.module.css";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDistrictByStateId,
  AddZoneByStateId,
} from "../../../../actions/zone/zoneAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { GetColor } from "../../../../utility/functions/functions";
import AddIcon from "@mui/icons-material/Add";
import { AutocompleteTextField } from "../../../../utility/TextField";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const AddDistrict = () => {
  const [listLoader, setListLoader] = useState(false);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Zone);
  const [StateList, setStateList] = useState([]);
  const [StateValue, setStateValue] = useState("");
  const [StateValueTwo, setStateValueTwo] = useState("");
  const [rootList, setRootList] = useState([]);
  const [districtRootList, setDistrictRootList] = useState([]);
  const [error, setError] = useState({});
  const [districtValue, setDistrictValue] = useState("");
  const [addsuccess, setAddsuccess] = useState(false);
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  let StateId = 0;

  useEffect(() => {
    if (
      store.stateList !== undefined &&
      store.stateList !== null &&
      store.stateList.length !== 0
    ) {
      setListLoader(true);
      // setStateValue(store.stateList[0].state_name);

      setTimeout(() => {
        setListLoader(false);
        setRootList(store.stateList);
        setDistrictRootList(store.districtList);
        let arr = [];
        for (let i = 0; i < store.stateList.length; i++) {
          arr.push(store.stateList[i].state_name);
        }
        setStateList(arr);
      }, [500]);
    }
  }, [store.stateList]);

  const handleStateValueChange = (event, newValue) => {
    setStateValue(newValue);
  };

  const handleDistrictChange = (e) => {
    setDistrictValue(e.target.value);
  };

  const IsValid = (id) => {
    let success = true;
    let err = {};

    if (!districtValue.trim()) {
      err.district = "District name cannot be empty";
      success = false;
    } else if (!/^[a-zA-Z0-9 ]*$/gm.test(districtValue)) {
      err.district = "Only AlphaNumeric and space Allowed!!";
      success = false;
    }

    if (id === 0 || id === "" || id === undefined || id === null) {
      err.state = "Please Choose a State";
      success = false;
    }

    

    setError(err);
    console.log(err);
    console.log("Success:",success)
    return success;
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    console.log("hello");
    let id = 0;

    for (let i = 0; i < rootList.length; i++) {
      if (StateValue === rootList[i].state_name) {
        id = rootList[i].state_id;
      }
    }

    
    if (IsValid(id)) {
      let param = {
        state_id: id,
        district_name: `${districtValue}`,
      };
      dispatch(
        AddDistrictByStateId(Axios, param, setAddsuccess, failed, setFailed)
      );
    }
  };

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Added District SuccessFully");
      setStateValue("");
      setDistrictValue("");
      setAddsuccess(false);
    }

    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [addsuccess, failed]);

  const AddBgColor = GetColor("--color-dark-background");
  const IconColor = GetColor("--color-light-background");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <form onSubmit={handleSubmit}>
        <Card
          component={Paper}
          sx={{
            backgroundColor: AddBgColor,
            height: "100%",
            minHeight: "60vh",
            marginTop: 3,
          }}
        >
          <Stack
            sx={{ color: "white", padding: 2, minHeight: "100%" }}
            direction="column"
            gap={2}
          >
            <Stack
              sx={{
                position: "absolute",
                marginTop: "-35px",
                marginLeft: { xs: "50%", sm: "50%", md: "250px" },
              }}
              direction="row"
              justifyContent="flex-end"
            >
              <IconButton
                size="large"
                sx={{
                  backgroundColor: IconColor,
                  boxShadow:
                    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                }}
              >
                <AddIcon sx={{ color: "white" }} />
              </IconButton>
            </Stack>
            <Stack direction="row">
              <Typography variant="h5">Add District</Typography>
            </Stack>

            <Stack
              direction="column"
              minHeight={"56vh"}
              justifyContent="space-between"
            >
              <Stack direction="column" gap={1.5}>
                <Stack>
                  <Autocomplete
                    loading={listLoader}
                    value={StateValue}
                    onChange={handleStateValueChange}
                    options={StateList}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <AutocompleteTextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="State"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {listLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {error.state && (
                    <span className={Styles.error}>*{error.state}*</span>
                  )}
                </Stack>
                <Stack>
                  <AutocompleteTextField
                    size="small"
                    variant="outlined"
                    label="District Name"
                    value={districtValue}
                    onChange={handleDistrictChange}
                  />
                  {error.zone && (
                    <span className={Styles.error}>*{error.district}*</span>
                  )}
                </Stack>
              </Stack>

              <RedButton
                variant="contained"
                type="submit"
                sx={{ borderRadius: "16px" }}
              >
                ADD
              </RedButton>
            </Stack>
          </Stack>
        </Card>
      </form>
    </>
  );
};

export default AddDistrict;
