import {
  Autocomplete,
  Card,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { GetColor } from "../../../utility/functions/functions";
import {
  AutocompleteTextField,
  AutocompleteTextFieldCurved,
  NormalTextField,
} from "../../../utility/TextField";
import { useSelector, useDispatch } from "react-redux";
import { CheckSmallError } from "../../../utility/ErrorCheck";
import {
  GetLaunchDistrictList,
  GetLaunchList,
  GetLaunchZoneCount,
  GetLaunchZoneList,
} from "../../../actions/Launch/LaunchAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  GETLAUNCHDISTRICTLIST,
  GETLAUNCHLIST,
  GETLAUNCHZONECOUNT,
  GETLAUNCHZONELIST,
} from "../../../utility/ReduxConstants/LaunchReducerConstant";
import { useNavigate, useParams } from "react-router";
import { GetLocalStorageByKey, StoreInLocalStorage } from "./launchUtility";

const LaunchFilter = () => {
  const [stateListLoader, setStateListLoader] = useState(false);
  const [districtListLoader, setDistrictListLoader] = useState(false);
  const [zoneListLoader, setZoneListLoader] = useState(false);
  const [scListLoader, setScListLoader] = useState(false);
  const localData = GetLocalStorageByKey("launch_param");
  let Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const Error = [undefined];
  const CountColorBg = GetColor("--color-launch-count");
  const LaunchCountBgColor = GetColor("--color-launch-count-bg");
  const store = useSelector((state) => state.Launch);
  const [Obj, setObj] = useState({
    scList: [],
    scValue: "",
    scId: 0,
  });
  const [state, setState] = useState({
    stateList: [],
    stateValue: "",
    stateId: 0,
  });
  const [district, setDistrict] = useState({
    districtList: [],
    districtValue: "",
    districtId: 0,
  });
  const [zone, setZone] = useState({
    zoneList: [],
    zoneValue: "",
    zoneId: 0,
  });


//  useEffect(()=>{
//   let param={district_id:0}
//   dispatch(GetLaunchZoneList(Axios,param));
//  },[])

  const GetZoneList = async (id) => {
    console.log(id,"showKyc")
    setZoneListLoader(true);
    let param = {
      district_id: id,
    };
    const data = await dispatch(GetLaunchZoneList(Axios, param));
    if (data?.code === 200) {
      setZoneListLoader(false);
      dispatch({ type: GETLAUNCHZONELIST, payload: data?.zoneList });
    } else {
      // console("faile dto fetch zone list")
    }
  };

  const GetDistrictList = async (id) => {
    setDistrictListLoader(true);
    let param = {
      state_id: id,
    };
    const data = await dispatch(GetLaunchDistrictList(Axios, param));
    if (data?.code === 200) {
      setDistrictListLoader(false);
      dispatch({ type: GETLAUNCHDISTRICTLIST, payload: data?.districtList });
    } else {
      // console("faile dto fetch zone list")
    }
  };

  const GetLaunchZoneCountSub = async (param) => {
    const Data = await dispatch(GetLaunchZoneCount(Axios, param));
    console.log(Data, "GetLaunchZoneCountSub");

    if (Data.code === 200) {
      dispatch({
        type: GETLAUNCHZONECOUNT,
        payload: Data.data,
      });
    }
  };

  const GetLaunchListSub = async (param) => {
    const Data = await dispatch(GetLaunchList(Axios, param));
    console.log(Data, "GetLaunchListSub fetchedData");
    if (Data?.code == 200) {
      dispatch({ type: GETLAUNCHLIST, payload: Data?.data });
      GetLaunchZoneCountSub(param);
    } else {
      console.log("Error in handleScChange");
    }
  };

  useEffect(() => {
    setScListLoader(true);
    setStateListLoader(true);
    if (localData?.changed) {
      setTimeout(() => {
        let ScName = "";
        let ScId = 0;
        let Sclength = store?.sc_list?.length;
        for (let i = 0; i < Sclength; i++) {
          if (
            parseInt(store?.sc_list[i]?.sc_id) === parseInt(localData?.sc_id)
          ) {
            ScName = store?.sc_list[i]?.name;
            ScId = store?.sc_list[i]?.sc_id;
          }
        }

        let Arr = store?.sc_list?.map((item) => item?.name);
        setObj({
          ...Obj,
          scList: Arr,
          scValue: ScName,
          scId: ScId,
        });

        let StateName = "";
        let StateId = 0;
        let Statelength = store?.state_list?.length;
        for (let i = 0; i < Statelength; i++) {
          if (
            parseInt(store?.state_list[i]?.state_id) ===
            parseInt(localData?.state_id)
          ) {
            StateName = store?.state_list[i]?.state_name;
            StateId = store?.state_list[i]?.state_id;
          }
        }

        let StateArr = store?.state_list?.map((item) => item?.state_name);
        setState({
          ...state,
          stateList: StateArr,
          stateValue: StateName,
          stateId: StateId,
        });
        // GetZoneList(StateId);
        GetDistrictList(StateId)

        console.log(ScName, "ScName", StateName);
        setScListLoader(false);
        setZoneListLoader(false);
      });
    } else {
      if (CheckSmallError(store?.sc_list)) {
        console.log("failed to fetch error");
        console("No sc List");
      } else {
        setScListLoader(false);
        let Arr = store?.sc_list?.map((item) => item?.name);
        setObj({
          ...Obj,
          scList: Arr,
          scValue: Arr[0],
          scId: store?.sc_list[0]?.sc_id,
        });
      }

      if (CheckSmallError(store?.state_list)) {
        console.log("failed to fetch error");
        console("No state List");
      } else {
        setStateListLoader(false);
        let Arr = store?.state_list?.map((item) => item?.state_name);
        setState({
          ...state,
          stateList: Arr,
          stateValue: Arr[0],
          stateId: store?.state_list[0]?.state_id ?? 0,
        });
        // GetZoneList(store?.district_list[0]?.district_id);
      }
    }
  }, [store?.sc_list, store?.state_list]);


  useEffect(() => {
    if (localData?.changed) {
      if (localData?.district_name === "All") {
        let DistrictArr = store?.district_list?.map((item) => item?.district_name);
        DistrictArr.unshift("All");
        setDistrict({
          ...district,
          districtList: DistrictArr,
          districtValue: "All",
          districtId: 0,
        });
      } else {
        let DistrictName = "";
        let DistrictId = 0;
        let Districtlength = store?.district_list?.length;
        for (let i = 0; i < Districtlength; i++) {
          if (
            parseInt(store?.district_list[i]?.district_id) ===
            parseInt(localData?.district_id)
          ) {
            DistrictName = store?.district_list[i]?.district_name;
            DistrictId = store?.district_list[i]?.district_id;
          }
        }

        let DistrictArr = store?.district_list?.map((item) => item?.district_name);
        setDistrict({
          ...district,
          districtList: DistrictArr,
          districtValue: DistrictName,
          districtId: DistrictId,
        });
      }

      // GetLaunchListSub(localData);
      // localData["changed"] = false;
      // StoreInLocalStorage(localData);

      console.log("im hereeeeee man");
    } else {
      if (CheckSmallError(store?.zone_list)) {
        console.log("failed to fetch zone list");
        //  console('No state List')
      } else {
        let Arr = store?.district_list?.map((item) => item?.district_name);
        Arr.unshift("All");
        setDistrict({
          ...district,
          districtList: Arr,
          districtValue: Arr[0],
          districtId: Arr[0] === "All" ? 0 : store?.district_list[0]?.district_id ?? 0,
        });

        let param = {
          zone_id: Arr[0] === "All" ? 0 : store?.zone_list[0]?.zone_id ?? 0,
          zone_name: Arr[0],
          state_id: state.stateId,
          sc_id: Obj?.scId ?? 0,
        };
        StoreInLocalStorage(param, "launch_param");
        GetLaunchListSub(param);

        //Uncomment this if zone value is required as all
        // GetZoneList(store?.district_list[0]?.district_id);
      }
    }
  }, [store?.district_list]);

  useEffect(() => {
    if (localData?.changed) {
      if (localData?.zone_name === "All") {
        let ZoneArr = store?.zone_list?.map((item) => item?.zone_name);
        ZoneArr.unshift("All");
        setZone({
          ...zone,
          zoneList: ZoneArr,
          zoneValue: "All",
          zoneId: 0,
        });
      } else {
        let ZoneName = "";
        let ZoneId = 0;
        let Zonelength = store?.zone_list?.length;
        for (let i = 0; i < Zonelength; i++) {
          if (
            parseInt(store?.zone_list[i]?.zone_id) ===
            parseInt(localData?.zone_id)
          ) {
            ZoneName = store?.zone_list[i]?.zone_name;
            ZoneId = store?.zone_list[i]?.zone_id;
          }
        }

        let ZoneArr = store?.zone_list?.map((item) => item?.zone_name);
        setZone({
          ...zone,
          zoneList: ZoneArr,
          zoneValue: ZoneName,
          zoneId: ZoneId,
        });
      }

      GetLaunchListSub(localData);
      localData["changed"] = false;
      StoreInLocalStorage(localData);

      console.log("im hereeeeee man");
    } else {
      if (CheckSmallError(store?.zone_list)) {
        console.log("failed to fetch zone list");
        //  console('No state List')
      } else {
        let Arr = store?.zone_list?.map((item) => item?.zone_name);
        Arr.unshift("All");
        setZone({
          ...zone,
          zoneList: Arr,
          zoneValue: Arr[0],
          zoneId: Arr[0] === "All" ? 0 : store?.zone_list[0]?.zone_id ?? 0,
        });

        let param = {
          zone_id: Arr[0] === "All" ? 0 : store?.zone_list[0]?.zone_id ?? 0,
          zone_name: Arr[0],
          state_id: state.stateId,
          sc_id: Obj?.scId ?? 0,
        };
        StoreInLocalStorage(param, "launch_param");
        GetLaunchListSub(param);
      }
    }
  }, [store?.zone_list]);

  useEffect(() => {
    if (localData?.changed) {
      if (localData?.district_name === "All") {
        let DistrictArr = store?.district_list?.map(
          (item) => item?.district_name
        );
        DistrictArr.unshift("All");
        setDistrict({
          ...district,
          districtList: DistrictArr,
          districtValue: "All",
          districtId: 0,
        });
      } else {
        let DistrictName = "";
        let DistrictId = 0;
        let Districtlength = store?.district_list?.length;
        for (let i = 0; i < Districtlength; i++) {
          if (
            parseInt(store?.district_list[i]?.district_id) ===
            parseInt(localData?.district_id)
          ) {
            DistrictName = store?.district_list[i]?.district_name;
            DistrictId = store?.district_list[i]?.district_id;
          }
        }

        let DistrictArr = store?.district_list?.map(
          (item) => item?.district_name
        );
        setDistrict({
          ...zone,
          districtList: DistrictArr,
          districtValue: DistrictName,
          districtId: DistrictId,
        });
      }

      GetLaunchListSub(localData);
      localData["changed"] = false;
      StoreInLocalStorage(localData);

      console.log("im hereeeeee man");
    } else {
      if (CheckSmallError(store?.zone_list)) {
        console.log("failed to fetch zone list");
        //  console('No state List')
      } else {
        let Arr = store?.zone_list?.map((item) => item?.zone_name);
        Arr.unshift("All");
        setZone({
          ...zone,
          districtList: Arr,
          districtValue: Arr[0],
          districtId:
            Arr[0] === "All" ? 0 : store?.district_list[0]?.district_id ?? 0,
        });

        let param = {
          zone_id: Arr[0] === "All" ? 0 : store?.zone_list[0]?.zone_id ?? 0,
          zone_name: Arr[0],
          state_id: state.stateId,
          sc_id: Obj?.scId ?? 0,
        };
        StoreInLocalStorage(param, "launch_param");
        GetLaunchListSub(param);
      }
    }
  }, [store?.district_list]);

  const handleDistrictChange = (e,value) => {
    if (!CheckSmallError(value) && value !== "") {
      const Found = store?.district_list?.filter(
        (item) => item.district_name === value
      );
      setDistrict({
        ...district,
        districtValue: value,
        districtId: Found[0]?.district_id ?? 0,
      });

      let param = {
        zone_id: zone?.zoneId ?? 0,
        zone_name: zone?.zoneValue ?? "",
        state_id: Found[0]?.state_id ?? 0,
        sc_id: Obj?.scId ?? 0,
      };
      // GetZoneList(state?.stateId);
      GetZoneList(Found[0]?.district_id);
      StoreInLocalStorage(param, "launch_param");
      // GetLaunchListSub(param);
    }
  };

  const handleScChange = async (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      const Found = store?.sc_list?.filter((item) => item.name === value);
      setObj({
        ...Obj,
        scValue: value,
        scId: Found[0]?.sc_id ?? 0,
      });

      let param = {
        zone_id: zone?.zoneId ?? 0,
        zone_name: zone?.zoneValue ?? "",
        state_id: state.stateId,
        sc_id: Found[0]?.sc_id ?? 0,
      };
      StoreInLocalStorage(param, "launch_param");
      GetLaunchListSub(param);
    }
  };

  const handleStateChange = async (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      const Found = store?.state_list?.filter(
        (item) => item.state_name === value
      );
      setState({
        ...state,
        stateValue: value,
        stateId: Found[0]?.state_id ?? 0,
      });

      let param = {
        zone_id: zone?.zoneId ?? 0,
        zone_name: zone?.zoneValue ?? "",
        state_id: Found[0]?.state_id ?? 0,
        sc_id: Obj?.scId ?? 0,
      };
      console.log(param,"handleStateChange")
      // GetZoneList(district?.districtId);
      GetDistrictList(Found[0]?.state_id);
      StoreInLocalStorage(param, "launch_param");
      GetLaunchListSub(param);
    }
  };

  console.log(store.zone_list,"superCategoryList")

  const handleZoneChange = async (e, value) => {
    console.log(value,"superCategoryList")
    if (!CheckSmallError(value) && value !== "") {
      const Found = store?.zone_list?.filter(
        (item) => item.zone_name === value
      );
      setZone({
        ...zone,
        zoneValue: value,
        zoneId: value === "All" ? 0 : Found[0]?.zone_id ?? 0,
      });

      let param = {
        zone_id: value === "All" ? 0 : Found[0]?.zone_id ?? 0,
        zone_name: value,
        state_id: state?.stateId,
        sc_id: Obj?.scId ?? 0,
      };
      StoreInLocalStorage(param, "launch_param");
      GetLaunchListSub(param);
    }
  };

  console.log(store, "launch store", localData);
  return (
    <Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        alignItems={{ md: "center" }}
        gap={1}
      >
        <Stack
          flex={2}
          direction={{ xs: "column" }}
          gap={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
            }}
            gap={5}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <Stack>
                <Autocomplete
                  loading={scListLoader}
                  value={Obj.scValue}
                  options={Obj.scList}
                  sx={{ minWidth: { md: 200, lg: 250 } }}
                  onChange={handleScChange}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      sx={{ backgroundColor: CountColorBg }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {scListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="Super Category"
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Autocomplete
                  loading={districtListLoader}
                  value={district.districtValue}
                  options={district.districtList}
                  sx={{ minWidth: { md: 200, lg: 250 } }}
                  onChange={handleDistrictChange}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      sx={{ backgroundColor: CountColorBg }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {zoneListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="District"
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <Stack>
                <Autocomplete
                  loading={stateListLoader}
                  value={state.stateValue}
                  options={state.stateList}
                  sx={{ minWidth: { md: 200, lg: 250 } }}
                  onChange={handleStateChange}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      sx={{ backgroundColor: CountColorBg }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {stateListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="State"
                    />
                  )}
                />
              </Stack>

              <Stack>
                <Autocomplete
                  loading={zoneListLoader}
                  value={zone.zoneValue}
                  options={zone.zoneList}
                  sx={{ minWidth: { md: 200, lg: 250 } }}
                  onChange={handleZoneChange}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      sx={{ backgroundColor: CountColorBg }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {zoneListLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      label="Zone"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack flex={1} direction="column" gap={1} marginTop={{ xs: "10px" }}>
          <Stack direction="row" justifyContent={"center"}>
            <Typography sx={{ color: "white" }}>Zones</Typography>
          </Stack>
          <Stack direction={{ xs: "row" }} justifyContent={"center"} gap={1}>
            <Stack>
              <Card
                component={Paper}
                sx={{
                  backgroundColor: CountColorBg,
                  padding: "20px 20px",
                  minWidth: "150px",
                }}
              >
                <Stack
                  direction="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ color: "white" }}
                >
                  <Typography variant={{ xs: "body1", sm: "body1", md: "h6" }}>
                    Launched
                  </Typography>
                  <Typography
                    variant={{ xs: "h5", sm: "h5", md: "h4" }}
                    sx={{ fontWeight: "bold" }}
                  >
                    {store?.launched ?? 0}
                  </Typography>
                </Stack>
              </Card>
            </Stack>
            <Stack>
              <Card
                component={Paper}
                sx={{
                  backgroundColor: CountColorBg,
                  padding: "20px 20px",
                  minWidth: "150px",
                }}
              >
                <Stack
                  direction="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ color: "white" }}
                >
                  <Typography variant={{ xs: "body1", sm: "body1", md: "h6" }}>
                    Not Launched
                  </Typography>
                  <Typography
                    variant={{ xs: "h5", sm: "h5", md: "h4" }}
                    sx={{ fontWeight: "bold" }}
                  >
                    {store?.not_launched ?? 0}
                  </Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LaunchFilter;
