import React from "react";
import {
  Stack,
  Divider,
  Autocomplete,
  TextField,
  Paper,
  Typography,
  Button,
  Input,
  InputBase,
  IconButton,
} from "@mui/material";
import "./VitranPartner.css";
import SearchBarComponent from "../../SearchBarComponent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { Search as SearchIcon } from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#232738",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#3B405E",

  // hide last border
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const VitaranPartnerSkuDetails = () => {
  return (
    <div style={{ color: "white" }}>
      <Stack>
        <Stack
          style={{
            backgroundColor: "#3B405E",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ color: "white", paddingBottom: "1rem" }}>
            KYC DASHBOARD
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ backgroundColor: "#3B405E", boxShadow: "none" }}
            gap={5}
            component={Paper}
            padding={1}
            flex={1}
          >
            <Autocomplete
              className="autocomplete"
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 300 }}
              value={top100Films[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Movie"
                  sx={{
                    color: "white",
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              className="autocomplete"
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 300 }}
              value={top100Films[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Movie"
                  sx={{
                    color: "white",
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              className="autocomplete"
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: 300 }}
              value={top100Films[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Movie"
                  sx={{
                    color: "white",
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}
            />
            <Typography sx={{ color: "white" }}>Cut off Price</Typography>
            <TextField
              sx={{
                borderRadius: "5px",
                color: "white",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            ></TextField>
            <Button
              sx={{
                color: "white",
                backgroundColor: "red",
                "&:hover": { backgroundColor: "red" },
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>
        <Stack
          sx={{
            backgroundColor: "#3B405E",
            marginTop: "2rem",
            padding: "1rem",
            borderRadius: "10px",
            boxShadow: "none",
            overflow: "hidden",
          }}
        >
          <Stack>
            <Stack
              sx={{
                backgroundColor: "#3B405E",
                boxShadow: "none",
              }}
              justifyContent={"space-between"}
              gap={5}
              direction={{ xs: "column", sm: "row" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  background: "#25293C !important",
                  borderRadius: "50px",
                  overflow: "hidden",
                  height: "3rem",
                  width: "20rem",
                }}
                gap={1}
              >
                <IconButton
                  sx={{
                    color: "white",
                    backgroundColor: "#3B405E",
                    "&:hover": { backgroundColor: "#3B405E" },
                    fontSize: "5rem",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                  }}
                >
                  <SearchIcon
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                </IconButton>
                <InputBase
                  sx={{
                    color: "white",
                    backgroundColor: "#25293C",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                  }}
                  placeholder="Search By SKU"
                ></InputBase>
                <IconButton
                  sx={{
                    color: "white !important",
                    background: "#25293C !important",
                    "&:hover": { background: "#25293C !important" },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row", sm: "row" }}
                gap={2}
                sx={{ alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "white",
                  }}
                >
                  Filter
                </Typography>
                <Autocomplete
                  className="autocomplete"
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 200 }}
                  value={top100Films[0]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Movie"
                      sx={{
                        color: "white",
                        width: "100%",
                        backgroundColor: "#25293C",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  )}
                />
                <Autocomplete
                  className="autocomplete"
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 200 }}
                  value={top100Films[0]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Movie"
                      sx={{
                        color: "white",
                        width: "100%",
                        backgroundColor: "#25293C",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  )}
                />
                <Autocomplete
                  className="autocomplete"
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{ width: 200 }}
                  value={top100Films[0]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Movie"
                      sx={{
                        color: "white",
                        width: "100%",
                        backgroundColor: "#25293C",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "#3B405E",
              padding: "1rem",
              boxShadow: "none",
              overflow: "hidden",
            }}
          >
            <Stack sx={{ marginTop: "1rem" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead sx={{ border: "none" }}>
                    <TableRow>
                      <StyledTableCell sx={{ border: "none" }}>
                        Super Category
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ border: "none" }}>
                        Category
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ border: "none" }}>
                        SKU Name
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ border: "none" }}>
                        Cut Off Difference (in ₹)
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ border: "none" }}>
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: "red",
                            "&:hover": {
                              backgroundColor: "red",
                            },
                          }}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ color: "white", border: "none" }}
                        >
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "white", border: "none" }}
                        >
                          {row.calories}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "white", border: "none" }}
                        >
                          {row.fat}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "white", border: "none" }}
                        >
                          {row.carbs}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "white", border: "none" }}
                        >
                          <IOSSwitch />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default VitaranPartnerSkuDetails;
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  {
    label:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
