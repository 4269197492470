import { Card, Paper, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllDistrictListTicket,
  GetAllStateListTicket,
  GetStateZoneTicketList,
  GetZoneTickets,
} from "../../../actions/ticket/ticket.action";
import {
  GETALLDISTRICTTICKET,
  GETALLSTATETICKET,
  GETSTATEZONETICKETLISTCONSTANT,
  GETZONETICKETS,
} from "../../../utility/ReduxConstants/TicketReducerConstant";
import { CssTextField, DateTextField } from "../../../utility/TextField";
import "./styles/styles.scss";
import TicketMgtSection from "./TicketMgtSection";
import TicketMgtTable1 from "./TicketMgtTable1";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const TicketMgt = () => {
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.Ticket);
  console.log(store);
  const dispatch = useDispatch();
  const rs = document.querySelector(":root");
  const R = getComputedStyle(rs);
  const bgColor = R.getPropertyValue("--color-light-background");
  const [value, setValue] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [valueTwo, setValueTwo] = useState(new Date());
  const [show, setShow] = useState(false);
  const [state, setState] = useState("");
  const [zone, setZone] = useState("");

  const handleChange = async (newValue) => {
    setValue(newValue);
    let param = {
      from: newValue,
      to: valueTwo,
    };
    const data = await GetStateZoneTicketList(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: GETSTATEZONETICKETLISTCONSTANT, payload: data.body });
    }
  };

  const handleChangeTwo = async (newValue) => {
    setValueTwo(newValue);
    let param = {
      from: value,
      to: newValue,
    };
    const data = await GetStateZoneTicketList(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: GETSTATEZONETICKETLISTCONSTANT, payload: data.body });
    }
  };

  const GetData = async () => {
    let param = {
      from: value,
      to: valueTwo,
    };
    const data = await GetStateZoneTicketList(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: GETSTATEZONETICKETLISTCONSTANT, payload: data.body });
    }

    const state = await GetAllStateListTicket(Axios);

    if (state?.code === 200) {
      dispatch({ type: GETALLSTATETICKET, payload: state?.stateList });
    }

    let param1={
      state_id:0
    }

    const district = await GetAllDistrictListTicket(Axios,param1);

    if (state?.code === 200) {
      dispatch({ type: GETALLDISTRICTTICKET, payload: district?.districtList });
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  const handleShow = async (item) => {
    setShow(true);

    let param = {
      state_id: 1,
      zone_name: item?.zone_name,
      start_date: new Date(value),
      end_date: new Date(valueTwo),
    };

    setState(item?.state_name);
    setZone(item?.zone_name);

    const data = await GetZoneTickets(Axios, param);
    if (data?.code === 200) {
      dispatch({ type: GETZONETICKETS, payload: data?.body });
    } else {
      console.log(data);
    }
  };

  const handleBack = () => {
    setShow(false);
  };

  return (
    <div className="TicketContainer">
      {!show && (
        <Card
          component={Paper}
          sx={{ backgroundColor: bgColor, padding: "10px", marginTop: "5px" }}
        >
          <Stack direction="column">
            <Stack
              direction={{ xs: "column", sm: "row", gap: 1 }}
              padding={2}
              spacing={2}
            >
              <Stack direction="row" alignItems="center">
                <Typography sx={{ color: "white", minWidth: "50px" }}>
                  From :
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    //   className={Styles.picker}
                    size="small"
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <DateTextField
                        size="small"
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack direction="row" alignItems="center">
                <Typography sx={{ color: "white", minWidth: "50px" }}>
                  To :
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    //   className={Styles.picker}
                    size="small"
                    inputFormat="dd/MM/yyyy"
                    value={valueTwo}
                    onChange={handleChangeTwo}
                    renderInput={(params) => (
                      <DateTextField
                        size="small"
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>

            <div>
              <TicketMgtTable1
                From={value}
                To={valueTwo}
                handleShow={handleShow}
              />
            </div>
          </Stack>
        </Card>
      )}

      {show && (
        <TicketMgtSection
          From={value}
          To={valueTwo}
          handleBack={handleBack}
          State={state}
          Zone={zone}
        />
      )}
    </div>
  );
};

export default TicketMgt;
