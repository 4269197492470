import {
  TextField,
  Autocomplete,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Styles from "./styles/index.module.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./styles/index.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme } from "@mui/material/styles";
import { SearchFilter } from "../../../utility/functions/functions";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  getWalletTransactionDetails,
  getStateListTransMgt,
  getZoneListTrnsMgt,
  getSuperCategoryTransMgt,
  getHoldBalance,
  getSuperCategoryAccessTransMgt,
  getStateListAccessTransMgt,
  getZoneListAccessTrnsMgt,
  getDistrictListAccessTrnsMgt,
  getDistrictListTrnsMgt,
} from "../../../actions/TransationMgt/transMgtAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapper, TransactionBalanceCard } from "../CardWrapper";
import {
  AutocompleteTextFieldCurved,
  DateTextField,
  SearchInputField,
} from "../../../utility/TextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#323546",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row({ item, list }) {
  const handleChange = () => {};

  return (
    <>
      <TableRow sx={{ backgroundColor: "#323546" }}>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.first_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.mobile_no}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.trans_id}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {TableFormatDate(item.created_at)}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.debit_price}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.credit_price}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {item.balance}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#000" }}>
          {parseFloat(item.hold_balance).toFixed(2)}
        </TableCell>
      </TableRow>
    </>
  );
}

const AccessTransactionMgt = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.TransationMgt);
  console.log(store,"transactionMgt")
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions,setDistrictOptions]=useState([])
  const [scList, setScList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateListLoader, setStateListLoader] = useState(false);
  const [zoneListLoader, setZoneListLoader] = useState(false);
  const [districtListLoader,setDistrictListLoader]=useState(false)
  const [scListLoader, setScListLoader] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const column = ["first_name", "mobile_no"];
  const [text, setText] = useState("");
  // const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [startDateValue, setStartDateValue] = React.useState(new Date());
  const [endDateValue, setEndDateValue] = React.useState(new Date());
  const [list, setList] = useState([]);
  console.log(store?.access_zone_list, "TransactionMgt");

  const [obj, setObj] = React.useState({
    state_id: "",
    state_name: "",
    district_id:"",
    district_name:"",
    zone_name: "",
    zone_id: "",
    sc_id: "",
    sc_name: "",
    start: new Date(),
    end: new Date().setDate(new Date().getDate - 1),
    last_trans: true,
  });

  

  
 

  useEffect(() => {
    var todayDateObj = new Date();
    var yesDateObj = new Date();
    yesDateObj.setDate(date.getDate() - 1);

    setObj({
      ...obj,
      start: getDateMonthYearFromTimeStamp(yesDateObj),
      end: getDateMonthYearFromTimeStamp(todayDateObj),
    });

    setStartDateValue(yesDateObj);
    setEndDateValue(todayDateObj);
    console.log("today date", todayDateObj);
    console.log("yester date", yesDateObj);

    // let param1={
    //   sc_id:store.access_sc_list[0]?.sc_id
    // }

    // dispatch(getStateListAccessTransMgt(Axios,param1));
    let param = {
      sc_id: 0,
    };

    // dispatch(getSuperCategoryTransMgt(Axios, param));
    dispatch(getSuperCategoryAccessTransMgt(Axios,param));
  }, []);

  useEffect(() => {
    setList(store?.wallet_trans_list ?? []);
  }, [store?.wallet_trans_list]);

  console.log(store?.wallet_trans_list, "wallet_trans_list");

  useEffect(() => {
    let arr = [];
    if (store?.access_state_list.length > 0) {
      setStateListLoader(true);

      setTimeout(() => {
        setStateListLoader(false);
        for (let i = 0; i < store?.access_state_list.length; i++) {
          arr.push(store?.access_state_list[i].state_name);
        }

        let param = {
          state_id: store?.access_state_list[0].state_id,
          
        };
        setObj({
          ...obj,
          state_id: store?.access_state_list[0].state_id,
          state_name: store?.access_state_list[0]?.state_name,
          
        });

        dispatch(getDistrictListAccessTrnsMgt(Axios, param));
      }, [500]);
    }
    setStateOptions(arr);
  }, [store?.access_state_list]);

  useEffect(() => {
    let arr = [];
    if (store?.access_district_list.length > 0) {
      setDistrictListLoader(true);

      setTimeout(() => {
        setDistrictListLoader(false);
        for (let i = 0; i < store?.access_district_list.length; i++) {
          arr.push(store?.access_district_list[i].district_name);
        }

        let param = {
          district_id: store?.access_district_list[0].district_id,
        };
        setObj({
          ...obj,
          district_id: store?.access_district_list[0].district_id,
          district_name: store?.access_district_list[0]?.district_name,
          
        });

        dispatch(getZoneListAccessTrnsMgt(Axios, param));
      }, [500]);
    }
    setDistrictOptions(arr);
  }, [store?.access_district_list]);

 
  useEffect(() => {
    let arr = [];
    if (store?.access_zone_list.length > 0) {
      setZoneListLoader(false);
      for (let i = 0; i < store?.access_zone_list.length; i++) {
        arr.push(store?.access_zone_list[i].zone_name);
      }


      
      // setObj({
      //   ...obj,
      //   zone_name: store?.access_zone_list[0].zone_name,
      //   zone_id: store?.access_zone_list[0].zone_id,
      // });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: store?.access_zone_list[0].zone_name,
        sc_id: obj.sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "input param of zone change");
      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
    setZoneList(arr);
  }, [store?.access_zone_list]);

  console.log(store.access_zone_list,"stateOptionssljf")


  useEffect(() => {
    dispatch(getHoldBalance(Axios));
    dispatch(getWalletTransactionDetails(Axios));
  }, []);


  

  useEffect(() => {
    setScListLoader(true);
    setTimeout(() => {
      setScListLoader(false);
      let arr = [];
      if (store?.access_sc_list.length > 0) {
        for (let i = 0; i < store?.access_sc_list.length; i++) {
          arr.push(store?.access_sc_list[i].name);
        }

        setObj({
          ...obj,
          sc_id: store?.access_sc_list[0].sc_id,
          sc_name: store?.access_sc_list.length > 0 ? store?.access_sc_list[0].name : "",
        });
      }

      let param={
        sc_id: store?.access_sc_list[0]?.sc_id
      }
      dispatch(getStateListAccessTransMgt(Axios,param))
      setScList(arr);
    }, [500]);
    
  }, [store?.access_sc_list]);

  const handleStartDateChange = (newValue) => {
    setStartDateValue(newValue);
    setObj({
      ...obj,
      start: getDateMonthYearFromTimeStamp(newValue),
    });
    console.log(obj);
    // setObj({
    //   ...obj,
    //   zone_name: store?.zone_list[index].zone_name,
    //   zone_id: store?.zone_list[index].zone_id,
    // });

    let inputParam = {
      state_name: obj.state_name,
      zone_name: obj.zone_name,
      sc_id: obj.sc_id,
      start: getDateMonthYearFromTimeStamp(newValue),
      end: obj.end,
      last_trans: true,
    };
    console.log(inputParam, "inputParamStartDate");
    dispatch(getWalletTransactionDetails(Axios, inputParam));
  };

  const handleEndDateChange = (newValue) => {
    setEndDateValue(newValue);
    setObj({
      ...obj,
      end: getDateMonthYearFromTimeStamp(newValue),
    });

    let inputParam = {
      state_name: obj.state_name,
      zone_name: obj.zone_name,
      sc_id: obj.sc_id,
      start: obj.start,
      end: getDateMonthYearFromTimeStamp(newValue),
      last_trans: true,
    };
    console.log(inputParam, "inputParamEndDate");
    dispatch(getWalletTransactionDetails(Axios, inputParam));
  };

  const [scIndex,setScIndex]=useState("")
  console.log(scIndex,"scIndex")

  const [stateIndex,setStateIndex]=useState("");
  const [districtIndex,setDistrictIndex]=useState("")

  const handleStateChange = (newValue) => {
    setSelectedValue("Select Zone")
    console.log(newValue + "newValue");
    let index = 0;
    if (store?.access_state_list.length > 0) {
      for (let i = 0; i < store?.access_state_list.length; i++) {
        if (newValue === store?.access_state_list[i].state_name) {
          console.log(newValue, store.access_state_list[i].state_name, "renlsjfkds");
          index = i;
          setStateIndex(index)
          break;
        }
      }

      console.log(index, "renlsjfkds");

      setObj({
        ...obj,
        district_id: "",
        district_name: "",
        state_id: store?.access_state_list[index].state_id,
        state_name: store?.access_state_list[index].state_name,
      });

      let param = {
        state_id: store?.access_state_list[index].state_id,
      };
      console.log(JSON.stringify(store), "renlsjfkds");
      console.log(obj, "obj before sending request for zone");
      setDistrictListLoader(true);
      // dispatch(getDistrictListAccessTrnsMgt(Axios, param));
      dispatch(getDistrictListAccessTrnsMgt(Axios, param));

      
    }
  };

  const handleDistrictChange = (newValue) => {
    setSelectedValue("Select Zone")
    console.log(newValue + "newValue");
    let index = 0;
    if (store?.access_district_list.length > 0) {
      for (let i = 0; i < store?.access_district_list.length; i++) {
        if (newValue === store?.access_district_list[i].district_name) {
          console.log(newValue, store.access_district_list[i].district_name, "renlsjfkds");
          index = i;
          setDistrictIndex(index)
          break;
        }
      }

      console.log(index, "renlsjfkds");

      setObj({
        ...obj,
        zone_id: "",
        zone_name: "",
        district_id: store?.access_district_list[index].district_id,
        district_name: store?.access_district_list[index].district_name,
      });

      let param = {
        district_id: store?.access_district_list[index].district_id,
      };
      console.log(JSON.stringify(store), "renlsjfkds");
      console.log(obj, "obj before sending request for zone");
      setZoneListLoader(true);
      dispatch(getZoneListAccessTrnsMgt(Axios, param));

      
    }
  };

  const [selectedValue,setSelectedValue]=useState("Select Zone")
  const handleZoneChange = (newValue) => {
    setSelectedValue(newValue)
    let index = 0;
    if (store?.access_zone_list.length > 0) {
      for (let i = 0; i < store?.access_zone_list.length; i++) {
        if (newValue === store?.access_zone_list[i].zone_name) {
          index = i;
          break;
        }
      }

     

      setObj({
        ...obj,
        zone_name: store?.access_zone_list[index].zone_name,
        zone_id: store?.access_zone_list[index].zone_id,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: store?.access_zone_list[index].zone_name,
        sc_id: obj.sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "inputParam");

      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
  };

  
  const handleSCChange = (newValue) => {
    setSelectedValue("Select Zone")
    let index = 0;
    if (store?.access_sc_list.length > 0) {
      for (let i = 0; i < store?.access_sc_list.length; i++) {
        if (newValue === store?.access_sc_list[i].name) {
          index = i;
          setScIndex(index);
          break;
        }
      }

      setObj({
        ...obj,
        sc_id: store?.access_sc_list[index].sc_id,
        sc_name: store?.access_sc_list[index].name,
      });

      let inputParam = {
        state_name: obj.state_name,
        zone_name: obj.zone_name,
        sc_id: store?.access_sc_list[index].sc_id,
        start: obj.start,
        end: obj.end,
        last_trans: true,
      };
      console.log(inputParam, "inputParam");

      let param={
        sc_id:store?.access_sc_list[index].sc_id
      }
      dispatch(getStateListAccessTransMgt(Axios,param))
      dispatch(getWalletTransactionDetails(Axios, inputParam));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = createTheme({
    palette: {
      // primary: {
      //   main: purple[500],
      // },
      // secondary: {
      //   main: green[500],
      // },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        ipm: 768,

        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  // console.log(new Date().getDate-1,"yesterday")
  var date = new Date();

  console.log(date, "today"); //# => Fri Apr 01 2011 11:14:50 GMT+0200 (CEST)

  date.setDate(date.getDate() - 1);

  console.log(date, "yesterday");

  // var dateObj = new Date();
  // console.log(getDateMonthYearFromTimeStamp(dateObj),"date-month-year");
  // var month = dateObj.getUTCMonth() + 1; //months from 1-12
  // var day = dateObj.getUTCDate();
  // var year = dateObj.getUTCFullYear();

  // var newdate = year + "/" + month + "/" + day;
  // var newdate2 = day + "-" + month + "-" + year;
  // console.log(newdate, "Date");
  // console.log(newdate2, "Date");

  const getDateMonthYearFromTimeStamp = (dateObj) => {
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let DateStr = day + "-" + month + "-" + year;
    return DateStr;
  };

  console.log(obj, "transObj");
  return (
    <div className="transactionContainer">
      <CardWrapper>
        <Stack sx={{ color: "white" }} gap={2.5}>
          <Stack gap={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Balance
            </Typography>
            <Stack direction={{ xs: "column", md: "row" }} gap={3}>
              <TransactionBalanceCard
                sx={{
                  minWidth: { xs: "200px", md: "300px", lg: "400px" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  color="white"
                >
                  <Typography>Available Balance</Typography>
                  <Typography>{`₹ ${
                    store?.available_balance ?? 0
                  }`}</Typography>
                </Stack>
              </TransactionBalanceCard>
              <TransactionBalanceCard
                sx={{
                  minWidth: { xs: "200px", md: "300px", lg: "400px" },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  color="white"
                >
                  <Typography>Hold Balance</Typography>
                  <Typography>{`₹ ${
                    store?.hold_trans_balance ?? 0
                  }`}</Typography>
                </Stack>
              </TransactionBalanceCard>
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={1.5}
            marginTop={1}
            flexWrap="wrap"
          >
            <Autocomplete
              value={obj?.sc_name}
              onChange={(event, newValue) => {
                handleSCChange(newValue);
              }}
              options={scList}
              className={Styles.autocomplete}
              sx={{
                minWidth: { xs: "100%", md: "220px", lg: "24%" },
              }}
              loading={scListLoader}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {scListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  label="Super Category"
                />
              )}
            />
            <Autocomplete
              value={obj?.state_name}
              onChange={(event, newValue) => {
                handleStateChange(newValue);
              }}
              loading={stateListLoader}
              sx={{
                minWidth: { xs: "100%", md: "220px",lg: "24%" },
              }}
              options={stateOptions}
              className={Styles.autocomplete}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  label="State"
                />
              )}
            />
            <Autocomplete
              value={obj?.district_name}
              onChange={(event, newValue) => {
                handleDistrictChange(newValue);
              }}
              loading={districtListLoader}
              sx={{
                minWidth: { xs: "100%", md: "220px",lg: "24%" },
              }}
              options={districtOptions}
              className={Styles.autocomplete}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  label="District"
                />
              )}
            />

            <Autocomplete
              // value={obj.zone_name}
              value={selectedValue}
              onChange={(event, newValue) => {
                handleZoneChange(newValue);
              }}
              options={zoneList}
              className={Styles.autocomplete}
              loading={zoneListLoader}
              sx={{
                minWidth: { xs: "100%", md: "220px", lg: "24%" },
              }}
              renderInput={(params) => (
                <AutocompleteTextFieldCurved
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {zoneListLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  label="Zone"
                />
              )}
            />
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              sx={{ border: "1px solid white", borderRadius: "20px" }}
              gap={1}
              width="50%"
            >
              <IconButton
                // className={Styles.Icon}
                sx={{
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "gray",
                    transition: "0.2s linear",
                  },
                }}
              >
                <SearchIcon sx={{ color: "black !important" }} />
              </IconButton>
              <SearchInputField
                sx={{
                  flex: { xs: 1, md: 1 },
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  color: "white",
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Search username,mobile number..."
              />
              <IconButton
                // className={Styles.Icon}
                onClick={() => setText("")}
              >
                <CloseIcon sx={{ color: "white !important" }} />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", gap: 1 }}
            padding={2}
            spacing={2}
          >
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: "white", minWidth: "50px" }}>
                From :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={startDateValue}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <DateTextField
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: "white", minWidth: "50px" }}>
                To :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={endDateValue}
                  onChange={handleEndDateChange}
                  renderInput={(params) => (
                    <DateTextField
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            
          </Stack>
          

          <Stack>
            <TableContainer
              sx={{ minHeight: 440, minWidth: "100%", marginTop: "5px" }}
              component={Paper}
              className={Styles.TableContainer}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  className={Styles.head}
                  sx={{
                    backgroundColor: "#1B1D23",
                  }}
                  size="small"
                >
                  <TableRow
                    className={Styles.head}
                    sx={{
                      backgroundColor: "#1B1D23",
                      overflowX: "auto",
                      overflowY: "auto",
                    }}
                    size="small"
                  >
                    <StyledTableCell align="center" size="small">
                      Zone
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      User Name
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Mobile
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Transaction ID
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Time
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Debit
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Credit
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Balance
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Security
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    minHeight: "400px",
                    backgroundColor: "#323546 !important",
                  }}
                >
                  {list.length !== 0 ? (
                    <>
                      {SearchFilter(list, column, text)
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <Row
                            item={item}
                            list={list}
                            key={`${item.zone_name} ${index}`}
                          />
                        ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={14} align="center">
                        *No Rules*
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {console.log(list.length, "length")}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Stack>
      </CardWrapper>
    </div>
  );
};

export default AccessTransactionMgt;

const TableFormatDate = (Datestr) => {
  // let  = "2022-12-09T00:36:31.000Z";
  const formateDate = new Date(Datestr);
  const MonthArr = [
    "jan",
    "feb",
    "march",
    "april",
    "may",
    "june",
    "july",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
  ];
  var month = formateDate.getUTCMonth(); //months from 1-12
  var day =
    formateDate.getUTCDate() < 10
      ? "0" + formateDate.getUTCDate()
      : formateDate.getUTCDate();
  var year = formateDate.getUTCFullYear();
  var hours = formateDate.getUTCHours();
  var minutes = formateDate.getUTCMinutes();
  var am_pm = formateDate.getHours() >= 12 ? "PM" : "AM";
  return (
    day +
    "-" +
    MonthArr[month] +
    "-" +
    year +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    am_pm
  );
};
