import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Styles from "./styles/zone.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import { IOSSwitch } from "../../../../utility/IosSwitch";
import { ChangeDistrictStatus, ChangeZoneStatus } from "../../../../actions/zone/zoneAction";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row({ item, type }) {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [obj, setObj] = useState({
    district_id: 0,
    state_id: 0,
    district_name: "",
    status: false,
  });

  console.log(item,"setobjfj")
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("Bangalore");
  const [addsuccess, setAddsuccess] = useState(false);
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  React.useEffect(() => {
    if (item !== undefined && item !== null) {
      setObj({
        district_id:
          item.district_id !== undefined &&
          item.district_id !== null &&
          item.district_id !== 0
            ? item.district_id
            : 0,
        state_id:
          item.state_id !== undefined &&
          item.state_id !== null &&
          item.state_id !== 0
            ? item.state_id
            : 0,
            district_name:
          item.district_name !== undefined &&
          item.district_name !== null &&
          item.district_name !== 0
            ? item.district_name
            : "",
            status:
          item.status !== undefined &&
          item.status !== null &&
          item.status !== 0
            ? item.status
            : false,
      });
    }
  }, [item]);

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Changed Status SuccessFully");
      setAddsuccess(false);
    }

    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [addsuccess, failed]);

  const handleToggle = useCallback(
    (e) => {
      setObj({
        ...obj,
        status: e.target.checked,
      });

      let param = {
        status: e.target.checked,
        state_id: obj.state_id,
        district_id:obj.district_id
        
      };

      dispatch(
        ChangeDistrictStatus(Axios, param, type, setAddsuccess, failed, setFailed)
      );
    },
    [obj]
  );

  return (
    <>
      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <input
            value={obj.district_name}
            className={Styles.input}
            disabled={true}
          />
        </TableCell>
        {/* <TableCell align="center" size="small">
          <div className={Styles.mid}>
            <div
              className={Styles.EditContainer}
              onClick={() => setEdit(!edit)}
            >
              <CreateIcon className={Styles.EditIcon} />
            </div>
          </div>
        </TableCell> */}
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <span className={Styles.IosSwitch}>
            <IOSSwitch onChange={handleToggle} checked={obj.status} />
            {obj.zone_status ? "Active" : "Inactive"}
          </span>
        </TableCell>
        <TableCell align="center" size="small">
          <div className={Styles.mid}>
            <div className={Styles.DefaultContainer}>
              <CheckCircleOutlineIcon
                className={
                  obj.status ? Styles.DefaultGreenIcon : Styles.DefaultIcon
                }
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

const DistrictTable = ({ list, type }) => {
    console.log(list,"ldjfljdsf")
  // const [list,setList]=useState([1,2,3,4,5,6])
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <TableContainer
          sx={{ maxHeight: 400, minWidth: "100%", marginTop: "5px" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23" }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCell align="center" size="small">
                  Districts
                </StyledTableCell>
                {/* <StyledTableCell align='center' size='small' >Edit</StyledTableCell> */}
                <StyledTableCell align="center" size="small">
                  Status
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Set as Default
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list.length !== 0 ? (
                <>
                  {list.map((item, index) => (
                    <Row item={item}  key={item.district_name} />
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    *No Rules*
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default DistrictTable;
