import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Card, Paper, Stack } from "@mui/material";

const SearchBarComponent = ({ Placeholder, TextCallback }) => {
  const [text, setText] = useState("");
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        border: "1px solid white",
        background: "#25293C !important",
        borderRadius: "20px",
        overflow: "hidden",
        width:"50%"
      }}
      gap={1}
    >
      <IconButton
        // className={Styles.Icon}
        sx={{
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "gray",
            transition: "0.2s linear",
          },
        }}
      >
        <SearchIcon sx={{ color: "black !important" }} />
      </IconButton>
      <input
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          color: "white",
        }}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          TextCallback(e.target.value);
        }}
        placeholder={Placeholder}
      />
      <IconButton
        // className={Styles.Icon}
        onClick={() => {
          setText("");
          TextCallback("");
        }}
      >
        <CloseIcon sx={{ color: "white !important" }} />
      </IconButton>
    </Stack>
  );
};

export default SearchBarComponent;
