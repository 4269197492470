import {
  TextField,
  Autocomplete,
  Button,
  Stack,
  Card,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddZone from "./AddZone";
import Styles from "./styles/zone.module.css";

import ZoneTable from "./zoneTable";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AllStateListZoneMgt,
  ALLZoneListByStateID,
  AllDistrictListZoneMgt,
  ALLZoneListByStateIDAndDistrictID,
  ALLDistrictListByStateID,
} from "../../../actions/zone/zoneAction";
import {
  GETSDISTRICTLIST,
  GETSLABONEZONELIST,
  GETSLABTWOZONELIST,
  GETSZONELISTBYSTATEANDDISTRICT,
} from "../../../utility/ReduxConstants/ZoneReducerConstant";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { GetColor } from "../../../utility/functions/functions";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Adjust } from "@mui/icons-material";

// import { Button } from '@mui/material';

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const ZoneMgt = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [StateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [StateValue, setStateValue] = useState("");
  const [StateValueTwo, setStateValueTwo] = useState("");
  const store = useSelector((state) => state.Zone);
  const [zoneList, setZoneList] = useState([]);
  const [zoneList2, setZoneList2] = useState([]);
  const [text, setText] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [zoneListByDistAndState, setZoneListByDistAndState] = useState([]);
  const [districtValue, setDistrictValue] = useState([]);
  const [districtId,setDistrictId]=useState(null);
  const [districtUpdatedList,setDistrictUpdatedList]=useState(null)

  useEffect(() => {
    setZoneList(store?.zoneListSlab1);
    setZoneList2(store?.zoneListSlab2);
    setZoneListByDistAndState(store?.zoneListByStateAndDistrict);
    
  }, [
    store?.zoneListSlab1,
    store?.zoneListSlab2,
    store?.zoneListByStateAndDistrict,
    
  ]);

  console.log(zoneListByDistAndState,"fsjfeijdsk")

  useEffect(()=>{
    
      // setStateValue(store.stateList[0].state_name);
      // setStateValueTwo(store.stateList[0].state_name);
      
        let arr = [];
        for (let i = 0; i < store.districtListByState?.length; i++) {
          arr.push(store.districtListByState[i]?.district_name);
        }
        setDistrictUpdatedList(arr);
        setListLoader(false);
     
    
  },[store?.districtListByState])

  console.log(districtUpdatedList,"districtUpdatedList")

  useEffect(() => {
    let param;
    dispatch(AllStateListZoneMgt(Axios));
    setListLoader(true);
    setZoneList([]);
    setZoneList2([]);
  }, []);

  useEffect(() => {
    let param={
      state_id: 0
    }
    dispatch(AllDistrictListZoneMgt(Axios,param));
    setListLoader(true);
  }, []);

  useEffect(() => {
    if (
      store.stateList !== undefined &&
      store.stateList !== null &&
      store.stateList?.length !== 0
    ) {
      // setStateValue(store.stateList[0].state_name);
      // setStateValueTwo(store.stateList[0].state_name);
      setTimeout(() => {
        let arr = [];
        for (let i = 0; i < store.stateList?.length; i++) {
          arr.push(store.stateList[i]?.state_name);
        }
        setStateList(arr);
        setListLoader(false);
      }, [1000]);
    }
  }, [store.stateList]);

  useEffect(() => {
    if (
      store.districtList !== undefined &&
      store.districtList !== null &&
      store.districtList?.length !== 0
    ) {
      // setStateValue(store.stateList[0].state_name);
      // setStateValueTwo(store.stateList[0].state_name);
      setTimeout(() => {
        let arr = [];
        for (let i = 0; i < store.districtList?.length; i++) {
          arr.push(store.districtList[i].district_name);
        }
        setDistrictList(arr);
        setListLoader(false);
      }, [1000]);
    }
  }, [store.districtList]);

  function GetId(value) {
    let id;

    if (
      store.stateList !== undefined &&
      store.stateList !== null &&
      store.stateList?.length !== 0
    ) {
      for (let i = 0; i < store.stateList?.length; i++) {
        if (store.stateList[i].state_name === value) {
          id = store.stateList[i].state_id;
        }
      }
    }

    return id;
  }

  function GetDistrictId(value) {
    let id;

    if (
      store.districtList !== undefined &&
      store.districtList !== null &&
      store.districtList?.length !== 0
    ) {
      for (let i = 0; i < store.districtList?.length; i++) {
        if (store.districtList[i].district_name === value) {
          id = store.districtList[i].district_id;
        }
      }
    }

    return id;
  }

  // const handleStateValueChange = (event, newValue) => {
  //   setStateValue(newValue);
  //   let Id = GetId(newValue);

  //   let param = {
  //     state_id: Id,
  //   };

  //   dispatch(ALLZoneListByStateID(Axios, param, GETSLABONEZONELIST));
  // };

  const [stateId, setStateId] = useState(null);
  console.log(store?.districtListByState,"jdsfjdjr")


  const handleStateValueTwoChange = (event, newValue) => {
    
    setStateValueTwo(newValue);
    let Id = GetId(newValue);
    setStateId(Id);
    let param = {
      state_id: Id,
    };

   

    dispatch(ALLDistrictListByStateID(Axios, param, GETSDISTRICTLIST));
    // console.log(store?.districtListByState,"jdsfjdjr")
    // let Arr=[]
    // for(let i=0;i<store?.districtListByState.length;i++){
    //   Arr.push(store.districtListByState[i].district_name);
    // }
    // (Arr);
    dispatch(ALLZoneListByStateID(Axios, param, GETSLABTWOZONELIST));

    // dispatch(ALLDistrictListByStateID(Axios, param, GETSDISTRICTLIST));
    // setTimeout(()=>{
    //   let Arr=[];
    //   for(let i=0;i<store.districtListByState.length;i++){
    //     Arr.push(store.districtListByState[i].district_name)
    //   }
    //   setDistrictList(Arr);
    // },[500])
    
    
  };

  console.log(districtList,"store.districtListByState")

  

  const handleDistrictListChange = (event, newValue) => {
    setDistrictValue(newValue);
    let Id = GetDistrictId(newValue);
    setDistrictId(Id);
    let param = {
      // state_id: stateId,
      district_id: Id,
    };

    dispatch(
      ALLZoneListByStateIDAndDistrictID(
        Axios,
        param,
        GETSZONELISTBYSTATEANDDISTRICT
      )
    );
  };

  const BgColor = GetColor("--color-light-background");

  return (
    <>
      <div className="RooZoneMgtContainer">
        <Stack sx={{ color: "white" }}>
          <Card component={Paper} sx={{ backgroundColor: BgColor }}>
            <Stack
              sx={{ minHeight: "78vh" }}
              padding={2}
              direction={{ xs: "column", sm: "column", md: "row" }}
              gap={2}
            >
              <Stack flex={1}>
                <AddZone districtList={districtUpdatedList} stateId={stateId}/>
              </Stack>
              <Stack flex={2.5} sx={{ color: "white" }} gap={2}>
                <Stack>
                  <Typography>List Of Zones</Typography>
                </Stack>

                {/* <Stack direction="row" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={0.5} justifyContent="space-between">
                    <IconButton
                      // className={Styles.Icon}
                      sx={{
                        backgroundColor: "white",
                        '&:hover': {
                          backgroundColor: "gray",
                          transition: "0.2s linear"
                        }
                      }}>
                      <FilterAltIcon sx={{ color: "black !important" }} />
                    </IconButton>
                    <input style={{
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "white"
                    }}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <IconButton
                      // className={Styles.Icon}
                      onClick={() => setText('')}
                    >
                      <CloseIcon sx={{ color: "white !important" }} />
                    </IconButton>
                  </Stack> */}
                <Stack>
                  <Autocomplete
                    loading={listLoader}
                    value={StateValueTwo}
                    onChange={handleStateValueTwoChange}
                    options={StateList}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {listLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="outlined"
                        label="State"
                      />
                    )}
                  />
                </Stack>

                <Stack>
                  <Autocomplete
                    loading={listLoader}
                    value={districtValue}
                    onChange={handleDistrictListChange}
                    options={districtUpdatedList?.length>0?districtUpdatedList:[]}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {listLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="outlined"
                        label="District"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Card
                    component={Paper}
                    sx={{
                      backgroundColor: "#323546",
                      padding: "3px",
                      minHeight: "60vh",
                    }}
                  >
                    <ZoneTable
                      list={zoneList2}
                      districtValue={districtValue}
                      zoneListByDistAndState={zoneListByDistAndState}
                      districtId={districtId}
                      type={2}
                    />
                  </Card>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </div>
    </>
  );
};

export default ZoneMgt;
