import { Authaxios } from "../../axios/axios";
import {
  GETALLSTATELISTUSERACCESSCONST,
  GETALLUSERLISTUSERACCESSCONST,
  GETALLUSERLISTUSERACCESSCONSTSEARCH,
  GETALLZONELISTUSERACCESSCONST,
  GETALLZONELISTSEARCHUSERACCESSCONST,
  UPDATEALLUSERLISTUSERACCESSCONST,
  GETALLSUPERCATEGORYLIST,
  GETSEARCHUSERLISTUSERACCESSCONST,
  GETROLEDATAACCESSMGT,
} from "../../utility/ReduxConstants/UserAcessReducerConstant";

export const AllStateListUserAccess = (Axios, param) => async (dispatch) => {
  try {
    // let code = 500
    const { data } = await Axios.post("AllstateList");
    console.log(data,"getEveryData")
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const SCAccessStateListAccess=(Axios,param)=>async(dispatch)=>{
  try{
    const {data}=await Axios.post("SCAccessStateList", param);
    return data;
  }catch (error){
    return error?.response?.data
  }
}

export const GetRoleListAccess = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("getRoleList");

    return data;
  } catch (error) {
    return error?.response.data;
  }
};

export const GetAllSuperCategoryList = (Axios) => async (dispatch) => {
  try {
    // let code = 500
    const { data } = await Axios.post("superCategoryList");
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const AllZoneListUserAccess = (Axios, param) => async (dispatch) => {
  try {
    
    
    const { data } = await Axios.post("AllzoneList", param);
    console.log(data,"rwndhth")
    
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const AllDistrictListUserAccess = (Axios, param) => async (dispatch) => {
  try {
    
    
    const { data } = await Axios.post("AlldistrictList", param);
    
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const AllZoneListSearchUserAccess =
  (Axios, param) => async (dispatch) => {
    try {
      // let code = 500
      const { data } = await Axios.post("AllzoneList", param);
      if (data.code === 200) {
        dispatch({
          type: GETALLZONELISTSEARCHUSERACCESSCONST,
          payload: data.zoneList,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const GetUserAccessList = (Axios, param) => async (dispatch) => {
  try {
    // let code = 500
    //const { data } = await Authaxios.post("userAccessList", param);

    const { data } = await Axios.post("superCategoryUsersList", param);
    if (data?.code === 200) {
    }
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const GetSearchUserAccessList = (Axios, param) => async (dispatch) => {
  try {
    // let code = 500
    const { data } = await Axios.post("userAccessList", param);

    if (data.code === 200) {
      dispatch({ type: GETSEARCHUSERLISTUSERACCESSCONST, payload: data.body });
    }
  } catch (error) {
    console.log(error);
  }
};

export const UserAccessStatusChange =
  (Axios, param, setAddsuccess, failed, setFailed) => async (dispatch) => {
    try {
      const { data } = await Axios.post("userAccessStatus", param);

      if (data.code === 200) {
        setAddsuccess(true);
        dispatch({ type: UPDATEALLUSERLISTUSERACCESSCONST, payload: param });
      } else {
        setFailed({
          ...failed,
          addFailed: true,
          addFailedMessage: "Failed!! Please Try Again",
        });
      }
    } catch (error) {
      console.log(error);
      setFailed({
        ...failed,
        addFailed: true,
        addFailedMessage: "Failed!! Please Try Again",
      });
    }
  };

export const GetUserAccessListBySearch = (Axios, param) => async (dispatch) => {
  try {
   
    const { data } = await Axios.post("searchUserAccess", param);

    if (data.code === 200) {
      dispatch({
        type: GETALLUSERLISTUSERACCESSCONSTSEARCH,
        payload: data.list,
      });
    }
    
  } catch (error) {
    console.log(error);
  }
};

export const AddUserAcccess = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("addUser", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const UpdateUserAcccess = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("UpdateSuperCategoryDetails", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
export const UpdateUserAcccessStatus = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post(
      "UpdateSuperCategoryStatusOfAccess",
      param
    );
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
