import React, { useState, useEffect } from "react";
import Styles from "./styles/KycUserCard.module.css";
import BG from "../../../assets/cardBg.jpg";
import User from "../../../assets/user.jpg";
import {
  Avatar,
  CardMedia,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IoCall } from "react-icons/io5";
import { BiCurrentLocation } from "react-icons/bi";
import { HiMail } from "react-icons/hi";
import { GiWallet, GiFactory } from "react-icons/gi";
import { GrMapLocation } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import { RiBuilding2Fill } from "react-icons/ri";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { pink } from "@mui/material/colors";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { useSelector, useDispatch } from "react-redux";
import { useDatePickerDefaultizedProps } from "@mui/x-date-pickers/DatePicker/shared";
import { defaultFirmImg } from "../../../utility/BaseUrl";
import { MakeVitaranVerified } from "../../../actions/kyc/kycAction";
import { AutoAwesomeMosaicTwoTone } from "@mui/icons-material";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapperSmall } from "../CardWrapper";

const RedRadio = styled(Radio)(({ theme }) => ({
  color: "white",
  "&.Mui-checked": {
    color: "#DC1D13",
  },
}));

const KycUserCard = ({ setDocument, filter }) => {
  console.log("KycUserCard");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Kyc);
 
  const [radio, setRadio] = useState("aadhar_front_image");
  const [userData, setUserData] = useState({
    vitaran_verified: true,
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    firm_name: "",
    wallet_live: 0,
    zone_name: "",
    role_name: "",
    address: "",
    state: "",
    district: "",
    gst_number: "",
    kyc_id: 0,
    user_id: 0,
  });

  // const [userData, setUserData] = useState(() => {
  //   const storedUserData = localStorage.getItem("userData");
  //   return storedUserData
  //     ? JSON.parse(storedUserData)
  //     : {
  //         vitaran_verified: true,
  //         first_name: "",
  //         last_name: "",
  //         mobile_no: "",
  //         email: "",
  //         firm_name: "",
  //         wallet_live: 0,
  //         zone_name: "",
  //         role_name: "",
  //         address: "",
  //         state: "",
  //         gst_number: "",
  //         kyc_id: 0,
  //         user_id: 0,
  //       };
  // });

  // useEffect(() => {
  //   localStorage.setItem("userData", JSON.stringify(userData));
  // }, [userData]);

  const [kycStatus, setKycStatus] = useState("");

  console.log(store, "getkycTabsDetails");

  const TabsPending = store?.getkycTabsDetails;

  const [verified, setVerified] = useState(false);
  const handleToggle = async (e) => {
    let param = {
      user_id: userData.user_id,
      zone_name: userData.zone_name,
      status: e.target.checked,
      kyc_id: userData.kyc_id,
    };
    const data = await dispatch(MakeVitaranVerified(Axios, param));

    if (data?.state) {
      toast.success(`${data?.message}`);
      setVerified(data.vitaran_verified);
    } else {
      toast.error(`${data?.message}`);
    }
  };

  let Disable = false;

  if (filter?.filterName === "login_completed") {
    Disable = true;
  } else if (filter?.filterName === "signup_completed") {
    Disable = true;
  } else {
    Disable = false;
  }

  useEffect(() => {
    console.log(JSON.stringify(store.kycDetail) + "value");
    if (store?.kycDetail !== undefined && store?.kycDetail !== null) {
      let Obj = store?.kycDetail;
      console.log(JSON.stringify(Obj), "kycDetailData");
      setVerified(
        Obj?.vitaran_verified !== undefined &&
          Obj?.vitaran_verified !== null &&
          Obj?.vitaran_verified
          ? true
          : false
      );
      if (radio === "aadhar_front_image") {
        setDocument(
          Obj?.aadhar_front_image !== undefined &&
            Obj?.aadhar_front_image !== null &&
            Obj?.aadhar_front_image?.length !== 0
            ? Obj?.aadhar_front_image
            : defaultFirmImg
        );
      } else if (radio === "aadhar_back_image") {
        setDocument(
          Obj?.aadhar_back_image !== undefined &&
            Obj?.aadhar_back_image !== null &&
            Obj?.aadhar_back_image?.length !== 0
            ? Obj?.aadhar_back_image
            : defaultFirmImg
        );
      } else if (radio === "pan_front_image") {
        setDocument(
          Obj?.pan_front_image !== undefined &&
            Obj?.pan_front_image !== null &&
            Obj?.pan_front_image?.length !== 0
            ? Obj?.pan_front_image
            : defaultFirmImg
        );
      } else if (radio === "pan_back_image") {
        setDocument(
          Obj?.pan_back_image !== undefined &&
            Obj?.pan_back_image !== null &&
            Obj?.pan_back_image?.length !== 0
            ? Obj?.pan_back_image
            : defaultFirmImg
        );
      } else if (radio === "dl_front_image") {
        setDocument(
          Obj?.dl_front_image !== undefined &&
            Obj?.dl_front_image !== null &&
            Obj?.dl_front_image?.length !== 0
            ? Obj?.dl_front_image
            : defaultFirmImg
        );
      } else if (radio === "dl_back_image") {
        setDocument(
          Obj?.dl_back_image !== undefined &&
            Obj?.dl_back_image !== null &&
            Obj?.dl_back_image?.length !== 0
            ? Obj?.dl_back_image
            : defaultFirmImg
        );
      } else if (radio === "user_picture") {
        setDocument(
          Obj?.user_picture !== undefined &&
            Obj?.user_picture !== null &&
            Obj?.user_picture?.length !== 0
            ? Obj?.user_picture
            : defaultFirmImg
        );
      }
      setUserData({
        vitaran_verified:
          Obj?.vitaran_verified !== undefined &&
          Obj?.vitaran_verified !== null &&
          Obj?.vitaran_verified
            ? true
            : false,
        first_name:
          Obj?.first_name !== undefined &&
          Obj?.first_name !== null &&
          Obj?.first_name?.length !== 0
            ? Obj?.first_name
            : "",
        last_name:
          Obj?.last_name !== undefined &&
          Obj?.last_name !== null &&
          Obj?.last_name?.length !== 0
            ? Obj?.last_name
            : "",
        mobile_no:
          Obj?.mobile_no !== undefined &&
          Obj?.mobile_no !== null &&
          Obj?.mobile_no?.length !== 0
            ? Obj?.mobile_no
            : "",
        email:
          Obj?.email !== undefined &&
          Obj?.email !== null &&
          Obj?.email?.length !== 0
            ? Obj?.email
            : "",
        firm_name:
          Obj?.firm_name !== undefined &&
          Obj?.firm_name !== null &&
          Obj?.firm_name?.length !== 0
            ? Obj?.firm_name
            : "",
        wallet_live:
          Obj?.wallet_live !== undefined &&
          Obj?.wallet_live !== null &&
          Obj?.wallet_live !== 0
            ? Obj?.wallet_live
            : 0,
        zone_name:
          Obj?.zone_name !== undefined &&
          Obj?.zone_name !== null &&
          Obj?.zone_name?.length !== 0
            ? Obj?.zone_name
            : "",
        role_name:
          Obj?.role_name !== undefined &&
          Obj?.role_name !== null &&
          Obj?.role_name?.length !== 0
            ? Obj?.role_name
            : "",
        address:
          Obj?.address !== undefined &&
          Obj?.address !== null &&
          Obj?.address?.length !== 0
            ? Obj?.address
            : "",
        state:
          Obj?.state !== undefined &&
          Obj?.state !== null &&
          Obj?.state?.length !== 0
            ? Obj?.state
            : "",

        district:
          Obj?.district !== undefined &&
          Obj?.district !== null &&
          Obj?.district?.length !== 0
            ? Obj?.district
            : "",
        gst_number:
          Obj?.gst_number !== undefined &&
          Obj?.gst_number !== null &&
          Obj?.gst_number?.length !== 0
            ? Obj?.gst_number
            : "",
        billing_name:
          Obj?.billing_name !== null && Obj?.billing_name?.length !== 0
            ? Obj?.billing_name
            : "",
        gst_slab:
          Obj?.gst_slab !== undefined &&
          Obj?.gst_slab !== null &&
          Obj?.gst_slab?.length !== 0
            ? Obj?.gst_slab
            : "",
        billing_address:
          Obj?.billing_address !== undefined &&
          Obj?.billing_address !== null &&
          Obj?.billing_address?.length !== 0
            ? Obj?.billing_address
            : "",
        kyc_id:
          Obj?.kyc_id !== undefined && Obj?.kyc_id !== null && Obj?.kyc_id !== 0
            ? Obj?.kyc_id
            : 0,
        user_id:
          Obj?.user_id !== undefined &&
          Obj?.user_id !== null &&
          Obj?.user_id !== 0
            ? Obj?.user_id
            : 0,
        profile_image:
          Obj?.profile_image !== undefined &&
          Obj?.profile_image !== null &&
          Obj?.profile_image?.length !== 0
            ? Obj?.profile_image
            : "",
        firm_name:
          Obj?.firm_name !== undefined &&
          Obj?.firm_name !== null &&
          Obj?.firm_name?.length !== 0
            ? Obj?.firm_name
            : "",
        firm_image:
          Obj?.firm_image !== undefined &&
          Obj?.firm_image !== null &&
          Obj?.firm_image?.length !== 0
            ? Obj?.firm_image
            : "",
      });
    }
  }, [store.kycDetail]);

  console.log(JSON.stringify(userData) + "kdjfvldskeioj");

  useEffect(() => {
    if (store?.kycViewDetails !== undefined && store?.kycViewDetails !== null) {
      let statusKyc = store?.kycViewDetails;
      setKycStatus({
        kyc_status:
          statusKyc?.kyc_status !== undefined &&
          statusKyc?.kyc_status !== null &&
          statusKyc?.kyc_status?.length !== 0
            ? statusKyc?.kyc_status
            : "",
      });
    }
  }, [store.kycViewDetails]);

  const ammcaopmwpomdd = userData;
  console.log(ammcaopmwpomdd, "ammcaopmwpomdd");

  const handleRadio = (e) => {
    setRadio(e.target.value);
    if (e.target.value === "aadhar_front_image") {
      setDocument(
        store?.kycDetail?.aadhar_front_image !== undefined &&
          store?.kycDetail?.aadhar_front_image !== null &&
          store?.kycDetail?.aadhar_front_image?.length !== 0
          ? store?.kycDetail?.aadhar_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "aadhar_back_image") {
      setDocument(
        store?.kycDetail?.aadhar_back_image !== undefined &&
          store?.kycDetail?.aadhar_back_image !== null &&
          store?.kycDetail?.aadhar_back_image?.length !== 0
          ? store?.kycDetail?.aadhar_back_image
          : defaultFirmImg
      );
    } else if (e.target.value === "pan_front_image") {
      setDocument(
        store?.kycDetail?.pan_front_image !== undefined &&
          store?.kycDetail?.pan_front_image !== null &&
          store?.kycDetail?.pan_front_image?.length !== 0
          ? store?.kycDetail?.pan_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "pan_back_image") {
      setDocument(
        store?.kycDetail?.pan_back_image !== undefined &&
          store?.kycDetail?.pan_back_image !== null &&
          store?.kycDetail?.pan_back_image?.length !== 0
          ? store?.kycDetail?.pan_back_image
          : defaultFirmImg
      );
    } else if (e.target.value === "dl_front_image") {
      setDocument(
        store?.kycDetail?.dl_front_image !== undefined &&
          store?.kycDetail?.dl_front_image !== null &&
          store?.kycDetail?.dl_front_image?.length !== 0
          ? store?.kycDetail?.dl_front_image
          : defaultFirmImg
      );
    } else if (e.target.value === "dl_back_image") {
      setDocument(
        store?.kycDetail?.dl_back_image !== undefined &&
          store?.kycDetail?.dl_back_image !== null &&
          store?.kycDetail?.dl_back_image?.length !== 0
          ? store?.kycDetail?.dl_back_image
          : defaultFirmImg
      );
    } else if (e.target.value === "user_picture") {
      setDocument(
        store?.kycDetail?.user_picture !== undefined &&
          store?.kycDetail?.user_picture !== null &&
          store?.kycDetail?.user_picture?.length !== 0
          ? store?.kycDetail?.user_picture
          : defaultFirmImg
      );
    }
  };

  console.log(userData, "userDATA");

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Stack gap={2} padding="0px 0px 50px 0px">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Avatar
            src={userData.profile_image}
            sx={{
              width: 110,
              height: 110,
              border: "3px solid white",
              marginRight: "-10%",
            }}
          />
          <img
            src={
              userData.firm_image !== undefined &&
              userData.firm_image !== null &&
              userData.firm_image.length !== 0
                ? userData.firm_image
                : defaultFirmImg
            }
            alt="background"
            className={Styles.Bg}
          />
        </Stack>
        <Stack padding="0px 30px">
          <CardWrapperSmall>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                Name :
              </Typography>
              <Typography>
                {userData.first_name} {userData.last_name}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                Mobile No :
              </Typography>
              <Typography>{userData.mobile_no}</Typography>
            </Stack>
          </CardWrapperSmall>
        </Stack>

        <Stack padding="0px 30px">
          <Stack padding="0px 20px">
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                State :
              </Typography>
              <Typography>{userData.state}</Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                District :
              </Typography>
              <Typography>{userData.district.length==0?"N/A":userData.district}</Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                Zone :
              </Typography>
              <Typography>{userData.zone_name}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Divider
            sx={{ color: "white", borderColor: "#C9C9C9", width: "90%" }}
          />
        </Stack>

        <Stack padding="0px 30px">
          <Stack padding="0px 20px">
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                billing name :
              </Typography>
              <Typography>
                {userData?.billing_name?.length === 0 ||
                userData?.billing_name === null ||
                userData?.billing_name === undefined
                  ? "Not Available"
                  : userData?.billing_name}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                gst number :
              </Typography>
              <Typography>
                {userData?.gst_number?.length === 0 ||
                userData?.gst_number === null ||
                userData?.gst_number === undefined
                  ? "Not Available"
                  : userData?.gst_number}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                gst slab :
              </Typography>
              <Typography>
                {userData?.gst_slab?.length === 0 ||
                userData?.gst_slab === null ||
                userData?.gst_slab === undefined
                  ? "Not Available"
                  : userData?.gst_slab}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                billing address :
              </Typography>
              <Typography>
                {userData?.billing_address?.length === 0 ||
                userData?.billing_address === null ||
                userData?.billing_address === undefined
                  ? "Not Available"
                  : userData?.billing_address}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Divider
            sx={{ color: "white", borderColor: "#C9C9C9", width: "90%" }}
          />
        </Stack>

        <Stack padding="0px 30px">
          <Stack padding="0px 20px">
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                ADDRESS:
              </Typography>
              <Typography>{userData.address}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Divider
            sx={{ color: "white", borderColor: "#C9C9C9", width: "90%" }}
          />
        </Stack>

        <Stack padding="0px 30px">
          <Stack padding="0px 20px">
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                VITARAN VERIFIED:
              </Typography>
              <IOSSwitch
                checked={verified}
                onChange={handleToggle}
                disabled={Disable}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Divider
            sx={{ color: "white", borderColor: "#C9C9C9", width: "90%" }}
          />
        </Stack>

        <Stack padding="0px 30px">
          <CardWrapperSmall>
            <Stack direction="row" sx={{ color: "white" }} gap={1}>
              <Typography sx={{ textTransform: "uppercase" }}>
                Status :
              </Typography>
              <Typography>{kycStatus.kyc_status}</Typography>
            </Stack>
          </CardWrapperSmall>
        </Stack>
      </Stack>
    </>
  );
};

export default KycUserCard;
