import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import UnAuthorizedImage from "./images/UnAuthorizedLogo.png"
import styles from "./styles/unauthorized.module.css" 

const UnAuthorized = () => {
  const navigate = useNavigate()
  return (
  <div className = {styles.unAuthorizedWrapper}>
      <div >
        <img className = {styles.unAuthorizedImage} src={UnAuthorizedImage} alt="Not Found"/>
    </div>
    <h3 className = {styles.unAuthorizedWarning}>UnAuthorized Access</h3>
    <h3 className = {styles.unAuthorizedWarning}>This Page is not Publically Available</h3>
    {/* <h3 className = {styles.unAuthorizedWarning}>To Access it Please Login First</h3> */}
    
    <Button variant="contained" onClick={()=>navigate('/')}>Return to Home</Button>
  </div>
  )
}

export default UnAuthorized