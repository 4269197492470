import React, { useEffect, useState } from "react";
import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  AutocompleteTextFieldNormal,
  DateTextFieldCalender,
  DateTextFieldCalenderWallet,
} from "../../../utility/TextField";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  WalletAllDistrictList,
  WalletAllStateList,
  WalletAllZoneList,
  WalletTableDetails,
} from "../../../actions/WalletManagament/WalletManagementAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { GETWALLETALLDETAILS } from "../../../utility/ReduxConstants/WalletManagementReducerConstant";
import { Link } from "react-router-dom";

const WalletDropdown = () => {
  const dispatch = useDispatch();
  const allStateList = useSelector(
    (store) => store.WalletManagementReducer.walletAllStateList
  );
  const allDistrictList = useSelector(
    (store) => store.WalletManagementReducer.walletAllDistrictList
  );
  const allZoneList = useSelector(
    (store) => store.WalletManagementReducer.walletAllZoneList
  );
  const allDetails = useSelector(
    (store) => store.WalletManagementReducer.walletAllDetails
  );

  const [obj, setObj] = useState({
    state_list: null,
    zone_list: null,
    district_list: null,
    state_default_name: null,
    zone_default_name: null,
    district_default_name: null,
    state_id: null,
    district_id: null,
    zone_id: null,
    start_date: null,
    end_date: null,
  });

  useEffect(() => {
    dispatch({ type: GETWALLETALLDETAILS, payload: obj });
  }, [obj]);
  console.log(obj, "walletmngrtrr");
  // useEffect(() => {
  //   setObj({
  //     ...obj,
  //     state_default_name: allStateList?.[0].state_name,
  //   });
  // }, []);

  const Axios = useAxiosPrivate();

  // useEffect(() => {
  //   dispatch(WalletAllStateList(Axios));

  // }, []);

  useEffect( () => {
    // Fetch the wallet all state list
     dispatch(WalletAllStateList(Axios));
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Wait for the first useEffect to complete
  //     await dispatch(WalletAllStateList(Axios));

  //     let state = [];
  //     for (let i = 0; i < allStateList?.length; i++) {
  //       state.push(allStateList[i]?.state_name);
  //     }

  //     setObj((prevObj) => ({
  //       ...prevObj,
  //       state_list: state,
  //       state_default_name: allStateList?.[0]?.state_name,
  //     }));
  //   };

  //   // Call the fetchData function
  //   fetchData();
  // }, [allStateList]);

  useEffect( () => {
    let param1 = {
      state_id: 0,
    };
     dispatch(WalletAllDistrictList(Axios, param1));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let district = [];
      for (let i = 0; i < allDistrictList?.length; i++) {
        district.push(allDistrictList[i]?.district_name);
      }

      setObj((prevObj) => ({
        ...prevObj,
        district_list: district,
        district_default_name: allDistrictList?.[0]?.district_name,
      }));
    }, 500);
  }, [allDistrictList]);

  useEffect(() => {
    let param = {
      district_id: 0,
    };
    dispatch(WalletAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    let state = [];
    for (let i = 0; i < allStateList?.length; i++) {
      state.push(allStateList[i]?.state_name);
    }

    setObj((prevObj) => ({
      ...prevObj,
      state_list: state,
      state_default_name: allStateList?.[0]?.state_name,
    }));
  }, [allStateList]);

  useEffect(() => {
    let zone = [];
    for (let i = 0; i < allZoneList?.length; i++) {
      zone.push(allZoneList[i]?.zone_name);
    }
    setTimeout(() => {
      setObj((prevObj) => ({
        ...prevObj,
        zone_list: zone,
        zone_default_name: allZoneList?.[0].zone_name,
      }));
    }, 500);
  }, [allZoneList]);

  const handleState = (e, value) => {
    // let state = [];
    // for (let i = 0; i < allStateList?.length; i++) {
    //   state.push(allStateList[i]?.state_name);
    // }

    // setObj({
    //   ...obj,
    //   state_list: state,
    // });
    

    let stateId = 0;
    for (let i = 0; i < allStateList.length; i++) {
      if (value === allStateList[i]?.state_name) {
        stateId = allStateList[i]?.state_id;
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      state_id: stateId,
      state_default_name: value,
    }));

    let param1 = {
      state_id: stateId,
    };
    dispatch(WalletAllDistrictList(Axios, param1));
  };

  const handleDistrict = (e, value) => {
    let districtId = 0;
    for (let i = 0; i < allDistrictList.length; i++) {
      if (value === allDistrictList[i].district_name) {
        districtId = allDistrictList[i].district_id;
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      district_id: districtId,
      district_default_name: value,
    }));
    let param = {
      district_id: districtId,
    };
    dispatch(WalletAllZoneList(Axios, param));
  };

  const handleZone = (e, value) => {
    let zoneId = 0;
    for (let i = 0; i < allZoneList.length; i++) {
      if (value === allZoneList[i].zone_name) {
        zoneId = allZoneList[i].zone_id;
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      zone_id: zoneId,
      zone_default_name: value,
    }));

    let param = {
      zone_id: zoneId,
    };

    dispatch(WalletTableDetails(Axios, param));
    // let param = {
    //   district_id: districtId,
    // };
    // dispatch(WalletAllZoneList(Axios, param));
  };

  const handleStartDateChange = (newValue) => {
    console.log(newValue, "walletmngrtrr");

    setObj((prevObj) => ({
      ...prevObj,
      start_date: newValue,
    }));
  };

  const handleEndDateChange = (newValue) => {
    console.log(newValue, "walletmngrtrr");

    setObj((prevObj) => ({
      ...prevObj,
      end_date: newValue,
    }));
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Stack>
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
                fontSize: "1.5rem",
                font: "roboto",
              }}
            >
              Coin Management
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Stack
            flex={1}
            direction={{ xs: "column", sm: "row" }}
            spacing={0.5}
            padding={1}
            gap={1}
            sx={{ display: "flex" }}
          >
            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={obj?.state_default_name}
              options={obj?.state_list}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleState}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="State"
                  placeholder="Select State.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={obj?.district_default_name}
              options={obj?.district_list}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleDistrict}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="District"
                  placeholder="Select District.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              value={obj?.zone_default_name}
              options={obj?.zone_list}
              getOptionLabel={(option) => option}
              size="small"
              onChange={handleZone}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "100%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Zone"
                  placeholder="Select Zone.."
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            dispaly: "flex",
            flexDirection: "row",

            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography sx={{ width: "7rem", color: "white" }}>
            Date Range:
          </Typography>
          <Typography sx={{ color: "white" }}>From: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              // value={
              //   editedValue?.edited_date
              //     ? editedValue?.edited_date
              //     : currentDate
              // }
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Typography sx={{ color: "white" }}>To: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              // value={
              //   editedValue?.edited_date
              //     ? editedValue?.edited_date
              //     : currentDate
              // }
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Link to="/home/wallet/history">
        <Stack>
          <Typography
            sx={{
              color: "white",
              fontWeight: 500,
              textDecoration: "underline",
            }}
            
          >
            View Transaction History
          </Typography>
        </Stack>
        </Link>
      </Stack>
    </>
  );
};

export default WalletDropdown;
