export const KYCSTATELISTREDUXCONSTANT = "KYCSTATELISTREDUXCONSTANT";
export const KYCDISTRICTLISTREDUXCONSTANT = "KYCDISTRICTLISTREDUXCONSTANT";
export const KYCZONELISTREDUXCONSTANT = "KYCZONELISTREDUXCONSTANT";
export const KYCCOUNTBYSTATEZONEREDUXCONSTANT =
  "KYCCOUNTBYSTATEZONEREDUXCONSTANT";
export const KYCLISTBYSTATEZONEREDUXCONSTANT =
  "KYCLISTBYSTATEZONEREDUXCONSTANT";
export const KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT =
  "KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT";
export const GETKYCSUPERCATEGORYDATA = "GETKYCSUPERCATEGORYDATA";
export const GETKYCCATEGORYDATA = "GETKYCCATEGORYDATA";
export const GETVIEWDETAILSUSERLIST = "GETVIEWDETAILSUSERLIST";
export const GETVIEWDETAILSUSERLISTTABSDATA = "GETVIEWDETAILSUSERLISTTABSDATA";
