import React, { useState, useEffect } from "react";
import Styles from "./Styles/PopUp.module.css";
import { baseImgPath, defaultImg } from "../../../utility/BaseUrl";
import { Stack } from "@mui/material";

const PopUpImage = ({ item, ChooseImg }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (item !== null && item !== undefined) {
    }
  }, [item]);

  // const { Key } = item;
  // const Img = `${baseImgPath}/${Key}`;

  const { image_path } = item;
  const Img = `${image_path}`;

  // <ProgressiveImage
  //         preview={defaultImg}
  //         src={Img}
  //         render={(src, style) => <img src={src} style={style} />}
  //       />

  return (
    <Stack
      onClick={() => ChooseImg(item)}
      sx={{ width: "150px", height: "150px" }}
      direction="row"
      justifyContent="center"
    >
      <img
        src={Img}
        alt="img"
        style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
        }}
        loading="lazy"
      />
    </Stack>
  );
};

export default PopUpImage;
