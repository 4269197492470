import React, { useState } from "react";
import { MakeKycVerified, RejectKyc } from "../../../actions/kyc/kycAction";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Styles from "./styles/KycVerificationSection.module.css";
import { styled } from "@mui/material/styles";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core"; // install this library
import Check from "../../../assets/check.pdf";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate'

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const DisabledButton = styled(Button)(({ theme }) => ({
  color: "Black",
  backgroundColor: "#F0F0F0",
  border: "2px solid #F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
}));

export const KycVerificationSection = ({ OBJ, filter }) => {
  const Axios = useAxiosPrivate()
  // const [remarks, setRemarks] = useState('');
  // const dispatch = useDispatch();
  // const store = useSelector(state=>state.Kyc)

  let textfield = {
    style: { backgroundColor: "rgb(50, 50, 50)" },
  };
  // const [error, setError] = useState({});

  // const IsValid = () => {
  //     let error = {};
  //     let success = true;

  //     if (!remarks.trim()) {
  //         error.remarks = "**Please Add a Remark!!**";
  //         success = false
  //     }

  //     setError(error)
  //     return success
  // }

  // const handleAccept = async() => {
  //     if (IsValid()) {

  //         let param = {
  //             "user_id": store?.kycDetail?.user_id,
  //             "zone_name": store?.kycDetail?.zone_name,
  //             "kyc_remarks": remarks,
  //             "kyc_id": store?.kycDetail?.kyc_id
  //         }

  //       const data = await dispatch(MakeKycVerified(param))

  //     if(data?.state){
  //         setRemarks('')
  //         toast.success(`${data?.message}`);
  //     }
  //     else{
  //         toast.error(`${data?.message}`)
  //     }
  //         // dispatch(MakeKycVerified(param))
  //     }
  // }

  // const handleReject = async() => {
  //     if (IsValid()) {
  //         let param = {
  //             "user_id": store?.kycDetail?.user_id,
  //             "zone_name": store?.kycDetail?.zone_name,
  //             "kyc_remarks": remarks,
  //             "kyc_id": store?.kycDetail?.kyc_id
  //         }

  //       const data = await dispatch(RejectKyc(param))

  //     if(data?.state){
  //         setRemarks('')
  //         toast.success(`${data?.message}`);
  //     }
  //     else{
  //         toast.error(`${data?.message}`)
  //     }
  //     }
  // }
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // for onchange event
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");

  // for submit event
  const [viewPdf, setViewPdf] = useState(null);

  // onchange event
  const fileType = ["application/pdf", "image/*"];
  const handlePdfFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // if(selectedFile && fileType.includes(selectedFile.type)){
      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = (e) => {
        setPdfFile(e.target.result);
        setPdfFileError("");
      };
      // }
      // else{
      //   setPdfFile(null);
      //   setPdfFileError('Please select valid pdf file');
      // }
    } else {
      console.log("select your file");
    }
  };

  // form submit
  const handlePdfFileSubmit = (e) => {
    e.preventDefault();
    if (pdfFile !== null) {
      setViewPdf(pdfFile);
    } else {
      setViewPdf(null);
    }
  };

  let PDF =
    "https://vitaranlive.s3.ap-south-1.amazonaws.com/vitaran-live-dev/kyc/New+Doc+07-21-2022+12.02.pdf";

  let Disable = false;

  if (filter.filterName === "login_completed") {
    Disable = true;
  } else if (filter.filterName === "users") {
    Disable = true;
  } else if (filter.filterName === "signup_completed") {
    Disable = true;
  } else {
    Disable = false;
  }

  console.log(Disable, "pdf");

  return (
    <div className="container">
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "670px",
          minHeight: "670px",
          maxHeight: "670px",
        }}
      >
        {/* show pdf conditionally (if we have one)  */}
        {Check && (
          <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <Viewer fileUrl={OBJ} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          </>
        )}

        {/* <a href={PDF} target="_blank" download>Download</a> */}
      </div>

      {/* <div className={Styles.BottomWrapper}>
        {Disable ? null : (
          <div>
            <label className={Styles.RemarksTitle}>Remarks:</label>
            <textarea
              required
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className={Styles.TextArea}
            />
            {error?.remarks && (
              <span className={Styles.Error}>{error.remarks}</span>
            )}
          </div>
        )}
        <div className={Styles.ButtonContainerBottom}>
          {Disable ? null : (
            <RedButton variant="contained" size="small" onClick={handleAccept}>
              Accept
            </RedButton>
          )}

          {Disable ? null : (
            <RedButton variant="contained" size="small" onClick={handleReject}>
              Reject
            </RedButton>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default KycVerificationSection;
