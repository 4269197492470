import { GETCOMPANYDATA, GETCOMPANYDETAILS, UPDATECOMPANYDATAINLIST,REMOVECOMPANYDATAINLIST } from "../../utility/ReduxConstants/CompanyReducerConstant"

const initialState={
    company_list:[],
    company_detail:{}
}

export default(state=initialState,action)=>{
    switch(action.type){
        
         case GETCOMPANYDATA :
             return {
                 ...state,
                 company_list: action.payload.item_carousel!==undefined && action.payload.item_carousel!==null && action.payload.item_carousel.length!==0 ? (action.payload.item_carousel) : ([]),
                 company_detail:action.payload.item_carousel!==undefined && action.payload.item_carousel!==null && action.payload.item_carousel.length!==0 && action.payload.first_item_details!==undefined && action.payload.first_item_details!==null ? (action.payload.first_item_details) : ({})
             }

          case GETCOMPANYDETAILS:
              return{
                  ...state,
                  company_detail:action.payload.item_details!==undefined && action.payload.item_details!==null ? (action.payload.item_details):({})
              }
          case UPDATECOMPANYDATAINLIST:
              return{
                  ...state,
                  company_list: state.company_list.map(item=>parseInt(item.item_id)===parseInt(action.payload.item_id) ? action.payload : item)

              }
          
              case REMOVECOMPANYDATAINLIST:
                  return{
                      ...state,
                      company_list: state.company_list.filter(item=>item.item_id!==parseInt(action.payload.item_id))
                  }
               
        default:
            return state
    }
}