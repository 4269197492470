import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import React, { useState } from "react";
import Styles from "./styles/KycCarousel.module.css";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { pink } from "@mui/material/colors";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const RedRadio = styled(Radio)(({ theme }) => ({
  color: "white",
  "&.Mui-checked": {
    color: "#DC1D13",
  },
}));

const KycCarousel = () => {
  const Axios = useAxiosPrivate();
  const [radio, setRadio] = useState("pan_back_image");
  return (
    <div className={Styles.Container}>
      <RadioGroup
        value={radio}
        className={Styles.MRadioContainer}
        onChange={(e) => console.log(e.target.value)}
      >
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              label="Aadhar Front"
              value="aadhar_front_image"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
        </div>
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              label="Aadhar Back"
              value="aadhar_back_image"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>Aadhar Back</label>
                </div> */}
        </div>
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              label="Pan Front"
              value="pan_front_image"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>Pan Front</label>
                </div> */}
        </div>
        <div className={Styles.CardActive}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              label="Pan Back"
              value="pan_back_image"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>Pan Back</label>
                </div> */}
        </div>
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              value="dl_front_image"
              label="DL Front"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>DL Front</label>
                </div> */}
        </div>
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              value="dl_back_image"
              label="DL Back"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>DL Back</label>
                </div> */}
        </div>
        <div className={Styles.Card}>
          <div className={Styles.RadioContainer}>
            <FormControlLabel
              value="user_picture"
              label="User Picture"
              control={
                <RedRadio
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
            />
          </div>
          {/* <div className={Styles.CardLabelContainer}>
                    <label className={Styles.CardLabel}>User Picture</label>
                </div> */}
        </div>
      </RadioGroup>
    </div>
  );
};

export default KycCarousel;
