import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useRefreshLamda from "./useRefreshLamda";
import { AxiosLamda } from "../axios/axios";

const useAxiosLambda = () => {
  const navigate = useNavigate();
  const store = useSelector((state) => state.LoginLambdaReducer);
  const refresh = useRefreshLamda();
  useEffect(() => {
    const requestInterceptors = AxiosLamda.interceptors.request.use(
      (config) => {
       
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer {store?.access_token}`;
        }
       
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseInterceptors = AxiosLamda.interceptors.response.use(
      (response) => response,
      async (error) => {
        const preRequest = error?.config;

        if (error?.response?.status === 401 || !preRequest) {
          console.log("entered");
          preRequest.sent = true;
          const newAccessToken = await refresh();
          // console.log("newAccessToken",newAccessToken)
          preRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return AxiosLamda(preRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      AxiosLamda.interceptors.request.eject(requestInterceptors);
      AxiosLamda.interceptors.response.eject(responseInterceptors);
    };
  }, [store, refresh]);

  return AxiosLamda;
};

export default useAxiosLambda;
