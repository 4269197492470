import { Authaxios } from "../../axios/axios";
import {
  GETWALLETTRANSACTIONDETAILS,
  GETSTATELISTTRANSMGT,
  GETSUPERCATEGORYTRANSMGT,
  GETZONELISTTRANSMGT,
  GETHOLDBALANCE,
  GETACCESSSUPERCATEGORYTRANSMGT,
  GETSTATELISTACCESSTRANSMGT,
  GETZONELISTACCESSTRANSMGT,
  GETDISTRICTLISTTRANSMGT,
  GETDISTRICTLISTACCESSTRANSMGT,
} from "../../utility/ReduxConstants/TransationMgtReducerConstant";

export const getWalletTransactionDetails =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("getWalletTransactionDetails", param);
      

      if (data.code === 200) {
        dispatch({ type: GETWALLETTRANSACTIONDETAILS, payload: data });
      }
    } catch (error) {
      
      console.log(error);
    }
    
  };

export const getHoldBalance = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("getHoldBalance");
    if (data.code === 200) {
      dispatch({ type: GETHOLDBALANCE, payload: data });
    }
  } catch (error) {
    
    console.log(error);
  }
};

export const getStateListTransMgt = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");
    

    if (data.code === 200) {
      dispatch({ type: GETSTATELISTTRANSMGT, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getStateListAccessTransMgt = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessStateList",param);
    

    if (data.code === 200) {
      dispatch({ type: GETSTATELISTACCESSTRANSMGT, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

// getZoneListTransMgt,
// getSuperCategoryTransMgt

export const getZoneListTrnsMgt = (Axios, param) => async (dispatch) => {
  

  try {
    const { data } = await Axios.post("AllzoneList", param);
    
    if (data.code === 200) {
      dispatch({ type: GETZONELISTTRANSMGT, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};


export const getDistrictListTrnsMgt = (Axios, param) => async (dispatch) => {
  

  try {
    const { data } = await Axios.post("AlldistrictList", param);
    
    if (data.code === 200) {
      dispatch({ type: GETDISTRICTLISTTRANSMGT, payload: data.districtList });
      // dispatch({ type: GETDISTRICTLISTACCESSTRANSMGT, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};


export const getZoneListAccessTrnsMgt = (Axios, param) => async (dispatch) => {
  

  try {
    const { data } = await Axios.post("SCAccessZoneList", param);
    
    if (data.code === 200) {
      dispatch({ type: GETZONELISTACCESSTRANSMGT, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};


export const getDistrictListAccessTrnsMgt = (Axios, param) => async (dispatch) => {
  

  try {
    const { data } = await Axios.post("SCAccessDistrictList", param);
    
    if (data.code === 200) {
      dispatch({ type: GETDISTRICTLISTACCESSTRANSMGT, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSuperCategoryTransMgt = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("superCategoryList");
    

    if (data.code === 200) {
      dispatch({ type: GETSUPERCATEGORYTRANSMGT, payload: data.body });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSuperCategoryAccessTransMgt = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AccesssuperCategoryList");
    

    if (data.code === 200) {
      dispatch({ type: GETACCESSSUPERCATEGORYTRANSMGT, payload: data.body });
    }
  } catch (error) {
    console.log(error);
  }
};
