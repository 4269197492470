import React,{useEffect, useState} from 'react'
import { SearchFilter } from '../../../../utility/functions/functions'
import { CircularProgress, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { CardWrapperImg } from '../../CardWrapper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { GetCategoryDetailsId } from '../../../../actions/category/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate';
import { ValidImage } from '../../../../actions/validImage';
import { defaultImg } from '../../../../utility/BaseUrl';
import BrokenImg from '../../../../assets/ImageNotValid.png';
import UpdateCategory from './UpdateCategory';


const CategoryListTwo = ({text,scValue}) => {
    const [id,setId] = useState(0)
    const Axios = useAxiosPrivate()
    const store = useSelector((state) => state.Category);
    const column = ["category_name"];
    const dispatch = useDispatch()
    const [list, setList] = useState([]);
    const handleClick = (id) => {
        console.log(id)
        let selected_category = {};
        for (let i = 0; i < list.length; i++) {
          if (id === list[i].category_id) {
            selected_category = list[i];
    
            console.log(selected_category, "selected_category")
          }
        }
        setId(parseInt(id))
        let param = {
          item_id: selected_category.category_id,
          category_image: selected_category.category_image,
          category_name: selected_category.category_name,
          sc_id: selected_category.sc_id,
          "type": "category",
          sc_name: scValue
        };
    
        dispatch(GetCategoryDetailsId(Axios,param));
      };

      
    React.useEffect(() => {
        let arr = [];
        let selected_category = {};
        if (
          store.category_list !== undefined &&
          store.category_list !== null &&
          store.category_list.length !== 0
        ) {
          for (let i = 0; i < store.category_list.length; i++) {
            selected_category = store.category_list[0];
            arr.push(store.category_list[i]);
          }
        }
        setList(arr); //store.s_category_list
      }, [store.category_list]);
  return (
   <>
                 <Stack sx={{ maxHeight: "300px", minHeight: "300px", overflow: "auto" }}>
                <Grid container sx={{ width: "100%" }} gap={1} >
                    {
                        list.length !== 0 ? (
                            SearchFilter(list, column, text).map((image, index) => (
                                <Grid xs={12} sm={6} md={2.9}
                                    sx={{
                                        // backgroundColor: `${Color()}`
                                    }}
                                >
                                    <CardWrapperImg component={Paper} sx={{
                                        
                                        border: id === parseInt(image.category_id) ? "2px solid white" : null
                                    }}>
                                        <Stack sx={{ height: "100px" }}>
                                            <Img img={image.category_image} />
                                        </Stack>

                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography variant='caption' sx={{ color: "white" }}>{image?.category_name ?? ''}</Typography>
                                            <IconButton
                                                sx={{ color: "white" }}
                                                onClick={() => handleClick(image?.category_id)}

                                            >
                                                <BorderColorIcon size="small" sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </Stack>
                                    </CardWrapperImg>
                                </Grid>
                            ))) :
                            <Grid xs={12} sm={6} md={2.9}
                            sx={{
                                // backgroundColor: `${Color()}`
                                color:"white"
                            }}
                        >
                            <Typography>**No Category List Yet**</Typography>
                            </Grid>
                    }
                </Grid>
            </Stack>
            <UpdateCategory/>
   </>
  )
}

export default CategoryListTwo


const Img = ({ img }) => {
    const [loading, setLoading] = useState(false)
    const [obj, setObj] = useState('')
    const CheckImage = async () => {
        const Result = await ValidImage(img)

        if (Result) {
            setObj(img)
            setLoading(false)
        } else {
            setLoading(false)
            setObj(BrokenImg)
        }

    }
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            CheckImage(img)
        }, [400])

    }, [img])

    return (

        loading ?
            <Stack direction="column" justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
                <CircularProgress />
                <Typography>loading...</Typography>
            </Stack> :
            <img src={obj !== undefined && obj !== null && obj.length !== 0 ? (`${obj}`) : (defaultImg)} alt="company" loading="lazy"
                style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }} />


    )

}