export const GETWALLETTRANSACTIONDETAILS = "GETWALLETTRANSACTIONDETAILS";
export const GETSTATELISTTRANSMGT = "GETSTATELISTTRANSMGT";
export const GETSUPERCATEGORYTRANSMGT = "GETSUPERCATEGORYTRANSMGT";
export const GETZONELISTTRANSMGT = "GETZONELISTTRANSMGT";
export const GETDISTRICTLISTTRANSMGT = "GETDISTRICTLISTTRANSMGT";
export const GETHOLDBALANCE="GETHOLDBALANCE"
export const GETACCESSSUPERCATEGORYTRANSMGT="GETACCESSSUPERCATEGORYTRANSMGT"
export const GETSTATELISTACCESSTRANSMGT="GETSTATELISTACCESSTRANSMGT"
export const GETZONELISTACCESSTRANSMGT="GETZONELISTACCESSTRANSMGT"
export const GETDISTRICTLISTACCESSTRANSMGT="GETDISTRICTLISTACCESSTRANSMGT"




