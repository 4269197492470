import {
  GETSENDCOINPOPUPSTATUS,
  GETVITARANID,
  GETWALLETALLDETAILS,
  GETWALLETALLDISTRICTLIST,
  GETWALLETALLSTATELIST,
  GETWALLETALLZONELIST,
  GETWALLETTABLEDETAILS,
  GETWALLETTRANSTABLEDETAILS,
} from "../../utility/ReduxConstants/WalletManagementReducerConstant";

const initialState = {
  sendCoinPopupStatus: false,
  walletAllStateList: null,
  walletAllDistrictList: null,
  walletAllZoneList: null,
  walletAllDetails: null,
  walletTableDetails: null,
  vitaranId: null,
  walletTransTableDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSENDCOINPOPUPSTATUS:
      return {
        ...state,
        sendCoinPopupStatus: action.payload,
      };
    case GETWALLETALLSTATELIST:
      return {
        ...state,
        walletAllStateList: action.payload,
      };
    case GETWALLETALLDISTRICTLIST:
      return {
        ...state,
        walletAllDistrictList: action.payload,
      };
    case GETWALLETALLZONELIST:
      return {
        ...state,
        walletAllZoneList: action.payload,
      };
    case GETWALLETALLDETAILS:
      return {
        ...state,
        walletAllDetails: action.payload,
      };
    case GETWALLETTABLEDETAILS:
      return {
        ...state,
        walletTableDetails: action.payload,
      };
    case GETVITARANID:
      return {
        ...state,
        vitaranId: action.payload,
      };
    case GETWALLETTRANSTABLEDETAILS:
      return {
        ...state,
        walletTransTableDetails: action.payload,
      };

    default:
      return state;
  }
};
