// export const GETSENDCOINPOPUPSTATUS = "GETSENDCOINPOPUPSTATUS";
export const GETPARTNERWALLETALLSTATELIST = "GETPARTNERWALLETALLSTATELIST";
export const GETPARTNERWALLETALLDISTRICTLIST =
  "GETPARTNERWALLETALLDISTRICTLIST";
export const GETPARTNERWALLETALLZONELIST = "GETPARTNERWALLETALLZONELIST";
export const GETPARTNERWALLETALLSCLIST = "GETPARTNERWALLETALLSCLIST";
export const GETPARTNERWALLETAPPROVALLIST = "GETPARTNERWALLETAPPROVALLIST";
export const GETPARTNERWALLETAPPROVECONFIRM = "GETPARTNERWALLETAPPROVECONFIRM";
export const GETPARTNERDROPDOWNLIST = "GETPARTNERDROPDOWNLIST";
export const GETWALLETUSERLIST = "GETWALLETUSERLIST";
export const GETALLUPDATEDVALUE = "GETALLUPDATEDVALUE";
export const GETWALLETTRANSACTIONDETAILS = "GETWALLETTRANSACTIONDETAILS";
export const GETWALLETOBJCHANGEDVALUE = "GETWALLETOBJCHANGEDVALUE";
export const GETSENDCOINSUSERID = "GETSENDCOINSUSERID";
export const GETWALLETVPDETAILS = "GETWALLETVPDETAILS";
