import { GETSUPERCATEGORYDATA ,GETSUPERCATEGORYDETAILS, REMOVESCATEGORYDATAINLIST, UPDATESCATEGORYDATAINLIST} from "../../utility/ReduxConstants/SuperCategoryReducerConstant";

const initialState = {
  s_category_list: [],
  super_category_detail:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSUPERCATEGORYDATA:
      return {
        ...state,
        s_category_list:
          action.payload.body !== undefined &&
          action.payload.body !== null &&
          action.payload.body.length !== 0
            ? action.payload.body
            : []
      };

    case GETSUPERCATEGORYDETAILS:
      return {
        ...state,
        super_category_detail:
          action.payload !== undefined &&
          action.payload !== null
            ? action.payload
            : {}
      };

      case UPDATESCATEGORYDATAINLIST:
        return {
          // ...state,
          // category_list: state.category_list.map((item) =>
          //   item.item_id === parseInt(action.payload.item_id)
          //     ? action.payload
          //     : item
          // ),
        };
  
      case REMOVESCATEGORYDATAINLIST:
        return {
          // ...state,
          // category_list: state.category_list.filter(
          //   (item) => item.item_id !== parseInt(action.payload.item_id)
          // ),
        };


    default:
      return state;
  }
};
