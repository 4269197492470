import React, { useEffect, useState } from "react";
import Styles from "./styles/index.module.css";
import Toolbar from "@mui/material/Toolbar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { Items } from "./SidebarData";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetColor } from "../../utility/functions/functions";
import { ExpandMore } from "@mui/icons-material";
import Scrollbar from "react-scrollbars-custom";
import { GETLAMBDATOKEN } from "../../utility/ReduxConstants/LoginLamdaReducerConstant";
import { GetLambdaTokenFNC } from "../../actions/Lambda/Lambda";

const theme = createTheme({
  palette: {
    // primary: {
    //   main: purple[500],
    // },
    // secondary: {
    //   main: green[500],
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ipm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
const Sidebar = () => {
  const store = useSelector((state) => state.Login);
  const fontSize = useMediaQuery(theme.breakpoints.between("md", "lg"))
    ? "14px"
    : "16px";
  const StyledListItem = styled(ListItem)(({ theme }) => ({
    fontSize: fontSize,
    "&:hover": {
      backgroundColor: "#DC1D13",
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      // color:"white"
    },
    "&.Mui-selected": {
      backgroundColor: "#DC1D13",
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
  }));
  const StyledListIcon = styled(ListItemIcon)(({ theme }) => ({
    color: "#fff",
    marginTop: "-5px",
  }));
  const navigate = useNavigate();
  const location = useLocation();
  // selected={location.pathname.toLocaleLowerCase() === items.to.toLocaleLowerCase() ? true : false}

  const handleClick = (to) => {
    navigate(to);
  };
  let CODE = store?.user?.admin_role_code;
  console.log(CODE, "codjfjejrjfj");
  const BgColor = GetColor("--color-black");
  const SideBgColor = GetColor("--color-light-background");
  return (
    <Stack
      sx={{
        backgroundColor: BgColor,
        height: "100%",
        width: "100%",
      }}
    >
      <Scrollbar
        style={{
          height: "calc(100% - 40px)",
          overflowX: "hidden",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        // contentProps={{ style: { paddingRight: "10px" } }}
      >
        <Card
          component={Paper}
          sx={{
            padding: "10px 20px 10px 0px",
            height: "100%",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 10,
            backgroundColor: SideBgColor,
          }}
        >
          <div className={Styles.ListContainer}>
            <List sx={{ gap: "10px !important" }}>
              {Items.map((items, index) =>
                items?.roles?.includes(parseInt(CODE)) ? (
                  items?.dropdown ? (
                    <SubDropDown Parent={items} />
                  ) : location.pathname.toLocaleLowerCase() ===
                    items.to.toLocaleLowerCase() ? (
                    <Card
                      component={Paper}
                      onClick={() => handleClick(items.to, index)}
                      sx={{
                        padding: "10px 20px 10px 0px",
                        height: "100%",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 20,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 20,
                        backgroundColor: "#DC1D13",
                        cursor: "pointer",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        sx={{ marginLeft: "20px" }}
                      >
                        <Typography
                          sx={{ color: "white", fontWeight: 500 }}
                          variant="caption"
                        >
                          {items.text}
                        </Typography>
                      </Stack>
                    </Card>
                  ) : (
                    <Stack
                      onClick={() => handleClick(items.to, index)}
                      sx={{
                        padding: "10px 20px 10px 0px",
                        height: "100%",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 20,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 20,
                        "&:hover": {
                          backgroundColor: "#DC1D13",
                          boxShadow:
                            "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        sx={{ marginLeft: "20px" }}
                      >
                        <Typography
                          sx={{ color: "white", fontWeight: 500 }}
                          variant="caption"
                        >
                          {items.text}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                ) : null
              )}
            </List>
            <label style={{ marginBottom: "2rem" }} className={Styles.Version}>
              v0.0.23 Rc 1
            </label>
          </div>
        </Card>
      </Scrollbar>
    </Stack>
  );
};
export default Sidebar;

const SubDropDown = ({ Parent }) => {
  console.log(Parent, "SubDropDown");
  const HoverEffect = GetColor("--Navbar-bg-color");
  // text: "Vitaran Partner",
  // to: "/home/vitaranparter",
  // roles: ROLES.VITARAN_PARTNER,
  const DropDown = Parent.dropdown;
  const navigate = useNavigate();
  const location = useLocation();
  const [highLight, setHighLight] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  console.log(location, "location");

  const handleExpandClick = () => {
    setSelectedItem("Delivery Management");
    setExpanded(!expanded);
  };

  const HandleSubNavigate = (item) => {
    console.log(item, "rjnfidhrjf");
    setSelectedItem(item.text);
    console.log(selectedItem, "HandleSubNavigate");
    navigate(item.to);
  };

  const handleSelect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setHighLight(
      location?.pathname === Parent.to ||
        location?.pathname === "/home/yourdeals"
    );
    setExpanded(
      location?.pathname === Parent.to ||
        location?.pathname === "/home/yourdeals"
    );
  }, [location?.pathname]);

  useEffect(() => {
    setSelectedItem("Delivery Management");
  }, [expanded]);

  console.log(location?.pathname === Parent.to, "highLight");
  return (
    <>
      {highLight ? (
        <Card
          onClick={() => setSelectedItem("Delivery Management")}
          component={Paper}
          sx={{
            padding: "10px 20px 10px 0px",
            height: "100%",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 20,
            backgroundColor: "#DC1D13",
            cursor: "pointer",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            sx={{ marginLeft: "20px" }}
            onClick={handleExpandClick}
          >
            <Typography
              sx={{ color: "white", fontWeight: 500 }}
              variant="caption"
            >
              {Parent.text}
            </Typography>
            {expanded ? (
              <ExpandMoreIcon sx={{ color: "white" }} />
            ) : (
              <ExpandLessIcon sx={{ color: "white" }} />
            )}
          </Stack>
        </Card>
      ) : (
        <Stack
          onClick={() => handleSelect(Parent?.to)}
          sx={{
            padding: "10px 20px 10px 0px",
            height: "100%",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 20,
            "&:hover": {
              backgroundColor: "#DC1D13",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
              cursor: "pointer",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            sx={{ marginLeft: "20px" }}
          >
            <Typography
              sx={{ color: "white", fontWeight: 500 }}
              variant="caption"
            >
              {Parent.text}
            </Typography>
            {expanded ? (
              <ExpandMoreIcon sx={{ color: "white" }} />
            ) : (
              <ExpandLessIcon sx={{ color: "white" }} />
            )}
          </Stack>
        </Stack>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {DropDown?.map((item, key) => {
          return (
            <Stack
              sx={{
                marginTop: "5px",
                marginBottom: "5px",
                padding: "10px",
                // backgroundColor:
                //   location.pathname.toLocaleLowerCase() ===
                //   Parent.to.toLocaleLowerCase()
                //     ? HoverEffect
                //     : "none",
                backgroundColor:
                  selectedItem === item?.text ? "#DC1D13" : "none",
                "&:hover": {
                  backgroundColor: "#DC1D13",
                  opacity: "0.5",
                  color: "white",
                  cursor: "pointer",
                },
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                color: "white",
              }}
              onClick={() => HandleSubNavigate(item)}
            >
              <Typography sx={{ marginLeft: "1rem" }} variant="caption">
                {item.text}
              </Typography>
            </Stack>
          );
        })}
      </Collapse>
    </>
  );
};
