import axios from "axios"
import { Authaxios } from "../../axios/axios"


export const GetStateZoneTicketList=async(Axios,param)=>{
     try {
           const {data} = await Axios.post('stateZoneTicketList',param)
           return data
     } catch (error) {
           return error?.response?.data
     }
}

export const GetAllStateListTicket=async(Axios,param)=>{
    try {
          const {data} = await Axios.post('AllstateList',param)
          return data
    } catch (error) {
          return error?.response?.data
    }
}

export const GetAllDistrictListTicket=async(Axios,param)=>{
      try {
            const {data} = await Axios.post('AlldistrictList',param)
            console.log(data,"Ticketopklm")
            return data
      } catch (error) {
            return error?.response?.data
      }
  }
export const GetAllZoneListTicket=async(Axios,param)=>{
    try {
          const {data} = await Axios.post('AllzoneList',param)
          return data
    } catch (error) {
          return error?.response?.data
    }
}


export const GetZoneTickets=async(Axios,param)=>{
    try {
          const {data} = await Axios.post('zoneTickets',param)
          return data
    } catch (error) {
          return error?.response?.data
    }
}



export const UpdateTicket=async(Axios,param)=>{
    try {
          const {data} = await Axios.post('updateTicket',param)
          return data
    } catch (error) {
          return error?.response?.data
    }
}