import React from "react";

import PartnerWalletTransactionDropdown from "./PartnerWalletTransactionDropdown";
import PartnerWalletTransactionCoinsDetails from "./PartnerWalletTransactionCoinsDetails";
import PartnerWalletTransactionTable from "./PartnerWalletTransactionTable";

const PartnerWalletTransactionHistory = () => {
  return (
    <>
      <PartnerWalletTransactionDropdown />
      <PartnerWalletTransactionCoinsDetails />
      <PartnerWalletTransactionTable />
    </>
  );
};

export default PartnerWalletTransactionHistory;
