import {
  TextField,
  Autocomplete,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import styles from "./styles/Dashboard.module.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./styles/Dashboard.scss";
import DashboardCount from "./DashboardCount";
import DashboardActiveUsersGraph from "./DashboardActiveUsersGraph";
import {
  GetActiveUsers,
  GetDistrictListDashboard,
  GetOrdersCount,
  GetSkuSuperCategoryListDashboard,
  GetStateListDashboard,
  GetTransactionData,
  GetZoneListDashboard,
  SCDATADASHBOARD,
} from "../../../actions/Dashboard/dashboard.action";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import DashboardSkuAnalyticsTable from "./DashboardSkuAnalyticsTable";
import DashboardSkuAnalytics from "./DashboardSkuAnalytics";
import {
  GETSCDASHBOARDDATA,
  GETSKUSCLISTDASHBOARD,
} from "../../../utility/ReduxConstants/DashboardReducerConstant";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {
  const [zoneLoader, setZoneLoader] = useState(false);
  const [districtLoader, setDistrictLoader] = useState(false);
  const Axios = useAxiosPrivate();

  const store = useSelector((state) => state.Dashboard);
  const store1 = useSelector((state) => state);

  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [districtValue, setDistrictValue] = useState([]);
  const [zoneValue, setZoneValue] = useState("");
  const [timeline, setTimeline] = useState([
    "today",
    "weekly",
    "monthly",
    "range",
  ]);
  const [timelineValue, setTimelineValue] = useState("today");
  const [scData, setScData] = useState([]);
  const [scValue, setScValue] = useState([]);
  const [From, setFrom] = useState(new Date());
  const [To, setTo] = useState(new Date());

  const [obj, setObj] = useState({
    stateValue: "",
    stateList: [],
    zoneValue: "",
    zoneList: [],
    scValue: "",
    scData: [],
    districtValue: "",
    districtList: [],
  });

  const handleState = (e, value) => {
    setObj({
      ...obj,
      stateValue: value,
    });
    setStateValue(value);
    let state_id = 0;
    for (let i = 0; i < store?.stateList?.length; i++) {
      if (value === store?.stateList[i]?.state_name) {
        state_id = store?.stateList[i]?.state_id;
      }
    }
    // setZoneLoader(true);
    setDistrictLoader(true);
    let param = {
      state_id: state_id,
    };

    // z
    dispatch(GetDistrictListDashboard(Axios, param));
  };

  const handleDistrict = (e, value) => {
    setObj({
      ...obj,
      districtValue: value,
    });
    setDistrictValue(value);
    let district_id = 0;
    for (let i = 0; i < store?.districtList?.length; i++) {
      if (value === store?.districtList[i]?.district_name) {
        district_id = store?.districtList[i]?.district_id;
      }
    }
    setZoneLoader(true);
    let param = {
      district_id: district_id,
    };

    // z
    dispatch(GetZoneListDashboard(Axios, param));
  };

  const handleZone = (e, value) => {
    setObj({
      ...obj,
      zoneValue: value,
    });
    setZoneValue(value);

    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    let param = {
      state_id: state_id,
      zone_name: value,
      order_type: "delivery",
      From: From,
      To: To,
      timeline: timelineValue,
    };

    dispatch(GetActiveUsers(Axios, param));
  };
  const handleSc = async (e, value) => {
    setObj({
      ...obj,
      scValue: value,
    });
    setScValue(value);

    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    let sc_id = 0;
    for (let i = 0; i < obj.scData.length; i++) {
      if (value === obj.scData[i].name) {
        sc_id = obj.scData[i].sc_id;
      }
    }

    let param = {
      state_id: state_id,
      zone_name: obj.zoneValue,
      order_type: "delivery",
      From: From,
      To: To,
      timeline: timelineValue,
      sc_id,
    };
    dispatch(GetTransactionData(Axios, param));
  };

  const handleTimeline = async (value) => {
    setTimelineValue(value);

    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    let param = {
      state_id: state_id,
      zone_name: zoneValue,
      order_type: "delivery",
      From: From,
      To: To,
      timeline: value,
    };

    let paramTwo = {
      state_id: state_id,
      zone_name: zoneValue,
      order_type: "pickup",
      From: From,
      To: To,
      timeline: value,
    };
    dispatch(GetOrdersCount(Axios, param, "delivery"));
    dispatch(GetOrdersCount(Axios, paramTwo, "pickup"));
    dispatch(GetActiveUsers(Axios, param));
    dispatch(GetTransactionData(Axios, param));
  };

  useEffect(() => {
    const data = dispatch(GetStateListDashboard(Axios));

    (async () => {
      const StateData = await dispatch(GetSkuSuperCategoryListDashboard(Axios));
      if (StateData?.code === 200) {
        dispatch({
          type: GETSKUSCLISTDASHBOARD,
          payload: StateData?.body ?? [],
        });
      }
      const scData = await dispatch(SCDATADASHBOARD(Axios));

      if (scData.code === 200) {
        dispatch({
          type: GETSCDASHBOARDDATA,
          payload: scData.body ?? [],
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (store?.scDashboardList && store?.scDashboardList !== null) {
      let Arr = ["All"];
      let TempArr = store?.scDashboardList?.map((item) => item?.name);

      Arr = [...Arr, ...TempArr];

      setObj({
        ...obj,
        scValue: Arr[0],
        scData: store?.scDashboardList,
      });

      setScValue(Arr[0]);
      setScData(Arr);
    }
  }, [store?.scDashboardList]);

  useEffect(() => {
    if (store?.stateList && store?.stateList !== null) {
      let Arr = ["All"];
      let TempArr = store?.stateList?.map((item) => item?.state_name);

      Arr = [...Arr, ...TempArr];

      setObj({
        ...obj,
        stateValue: Arr[0],
      });

      setStateValue(Arr[0]);
      setStateList(Arr);
      setDistrictLoader(true);
      let param = {
        state_id: 0,
      };
      dispatch(GetDistrictListDashboard(Axios, param));
    }
  }, [store?.stateList]);


  useEffect(() => {
    if (store?.districtList && store?.districtList !== null) {
      let Arr = ["All"];
      let TempArr = store?.districtList?.map((item) => item?.district_name);

      Arr = [...Arr, ...TempArr];

      setObj({
        ...obj,
        districtValue: Arr[0],
      });

      setDistrictValue(Arr[0]);
      setDistrictList(Arr);
      setZoneLoader(true);
      let param = {
        district_id: 0,
      };
      dispatch(GetZoneListDashboard(Axios, param));
    }
  }, [store?.districtList]);

  useEffect(() => {
    if (store?.zoneList && store?.zoneList !== null) {
      setZoneLoader(false);
      let Arr = ["All"];
      let TempArr = store?.zoneList?.map((item) => item?.zone_name);
      Arr = [...Arr, ...TempArr];
      setObj({
        ...obj,
        zoneValue: Arr[0],
      });
      setZoneValue(Arr[0]);
      setZoneList(Arr);

      let state_id = 0;
      for (let i = 0; i < obj.stateList.length; i++) {
        if (obj.stateValue === obj.stateList[i].state_name) {
          state_id = obj.stateList[i].state_id;
        }
      }

      let param = {
        state_id: state_id,
        zone_name: "All",
        order_type: "delivery",
        From: From,
        To: To,
        timeline: timelineValue,
      };

      let paramTwo = {
        state_id: state_id,
        zone_name: Arr[0],
        order_type: "pickup",
        From: From,
        To: To,
        timeline: timelineValue,
      };
      dispatch(GetOrdersCount(Axios, param, "delivery"));
      dispatch(GetOrdersCount(Axios, paramTwo, "pickup"));
      dispatch(GetActiveUsers(Axios, param));
      dispatch(GetTransactionData(Axios, param));
    }
  }, [store?.zoneList]);

  const handleDateChange = async (value, type) => {
    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }
    if (type === "From") {
      setFrom(value);
      let param = {
        state_id: state_id,
        zone_name: zoneValue,
        order_type: "delivery",
        From: value,
        To: To,
        timeline: timelineValue,
      };

      let paramTwo = {
        state_id: state_id,
        zone_name: zoneValue,
        order_type: "pickup",
        From: value,
        To: To,
        timeline: timelineValue,
      };
      dispatch(GetOrdersCount(Axios, param, "delivery"));
      dispatch(GetOrdersCount(Axios, paramTwo, "pickup"));
      dispatch(GetActiveUsers(Axios, param));
      dispatch(GetTransactionData(Axios, param));
    } else {
      setTo(value);
      let param = {
        state_id: state_id,
        zone_name: zoneValue,
        order_type: "delivery",
        From: From,
        To: value,
        timeline: timeline,
      };

      let paramTwo = {
        state_id: state_id,
        zone_name: zoneValue,
        order_type: "pickup",
        From: From,
        To: value,
        timeline: timeline,
      };
      dispatch(GetOrdersCount(Axios, param, "delivery"));
      dispatch(GetOrdersCount(Axios, paramTwo, "pickup"));
      dispatch(GetActiveUsers(Axios, param));
      dispatch(GetTransactionData(Axios, param));
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="RootDashboard">
        <Stack gap={1}>
          <Stack
            sx={{ backgroundColor: "#323546" }}
            gap={1}
            component={Paper}
            padding={1}
            flex={1}
          >
            <Stack
              flex={1}
              direction={{ xs: "column", sm: "row" }}
              spacing={0.5}
              padding={1}
              gap={1}
            >
              <Autocomplete
                sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
                value={stateValue}
                options={stateList}
                getOptionLabel={(option) => option}
                size="small"
                onChange={handleState}
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      width: "100%",
                      backgroundColor: "#25293C",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    variant="outlined"
                    label="State"
                    placeholder="Select State.."
                  />
                )}
              />

              <Autocomplete
                sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
                value={districtValue}
                options={districtList}
                getOptionLabel={(option) => option}
                size="small"
                onChange={handleDistrict}
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      width: "100%",
                      backgroundColor: "#25293C",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    variant="outlined"
                    label="District"
                    placeholder="Select District.."
                  />
                )}
              />
              <Autocomplete
                sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
                loading={zoneLoader}
                value={zoneValue}
                options={zoneList}
                getOptionLabel={(option) => option}
                size="small"
                //disabled={obj.stateValue === 'All' ? true : false}
                onChange={handleZone}
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      width: "100%",
                      backgroundColor: "#25293C",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {zoneLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    variant="outlined"
                    label="Zone"
                    placeholder="Select Zone.."
                  />
                )}
              />

              <Autocomplete
                sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
                value={timelineValue}
                options={timeline}
                getOptionLabel={(option) => option}
                size="small"
                //disabled={obj.stateValue === 'All' ? true : false}
                onChange={(e, value) => handleTimeline(value)}
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      width: "100%",
                      backgroundColor: "#25293C",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    variant="outlined"
                    label="TimeLine"
                    placeholder="Select Timeline.."
                  />
                )}
              />

              <Autocomplete
                sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
                value={scValue}
                options={scData}
                getOptionLabel={(option) => option}
                size="small"
                onChange={handleSc}
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      width: "100%",
                      backgroundColor: "#25293C",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {zoneLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    variant="outlined"
                    label="Sc"
                    placeholder="Select Sc.."
                  />
                )}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={0.5}
              padding={1}
            >
              {timelineValue === "range" && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="From"
                    inputFormat="dd/MM/yyyy"
                    value={From}
                    size="small"
                    onChange={(value) => handleDateChange(value, "From")}
                    renderInput={(params) => (
                      <AutocompleteTextFieldCurved
                        {...params}
                        sx={{
                          backgroundColor: "#25293C",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                  />

                  <DesktopDatePicker
                    label="To"
                    inputFormat="dd/MM/yyyy"
                    value={To}
                    size="small"
                    onChange={(value) => handleDateChange(value, "To")}
                    renderInput={(params) => (
                      <AutocompleteTextFieldCurved
                        {...params}
                        sx={{
                          backgroundColor: "#25293C",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Stack>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              flexWrap="wrap"
              gap={1}
            >
              <Stack flex={1.3} sx={{ overflow: "auto" }}>
                <DashboardCount />
              </Stack>
              <Stack flex={1}>
                <DashboardActiveUsersGraph />
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <DashboardSkuAnalytics />
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default memo(Dashboard);

const SCDATA = [
  {
    sc_id: 31,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677857685253-4987.jpg",
    name: "CONSTRUCTION  MATERIAL",
  },
  {
    sc_id: 38,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678961607532-5427.jpg",
    name: "ELECTRICAL HOUSEHOLD",
  },
  {
    sc_id: 39,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678961640160-3431.jpg",
    name: "ELECTRICAL INDUSTRIAL",
  },
  {
    sc_id: 29,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677856910036-1015.jpg",
    name: "FMCG",
  },
  {
    sc_id: 33,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678959401689-6558.jpg",
    name: "FMCG KITCHEN INGREDIANT",
  },
  {
    sc_id: 36,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678960990880-5656.jpg",
    name: "HARDWEAR HOUSEHOLD",
  },
  {
    sc_id: 37,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678961052367-8184.jpg",
    name: "HARDWEAR INDUSTRIAL",
  },
  {
    sc_id: 30,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677857282842-1210.jpg",
    name: "MOBILE AND ELECTRONICS",
  },
  {
    sc_id: 28,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677856772322-3264.jpg",
    name: "PAN CORNER",
  },
  {
    sc_id: 34,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678959935611-4244.jpg",
    name: "PLASTIC HOUSEHOLD",
  },
  {
    sc_id: 35,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678960685900-6866.jpg",
    name: "PLASTIC INDUSTRIAL",
  },
  {
    sc_id: 41,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1680246400620-2158.jpg",
    name: "Sky SuperCategory",
  },
  {
    sc_id: 26,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677222939008-1863.jpg",
    name: "STEEL",
  },
  {
    sc_id: 40,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1679292964162-1006.jpg",
    name: "Super Test",
  },
  {
    sc_id: 32,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1678864327695-2825.jpg",
    name: "test1",
  },
  {
    sc_id: 27,
    image:
      "https://vtrn-live-dev.s3.ap-south-1.amazonaws.com/category/1677856439869-1409.jpg",
    name: "TYRE",
  },
];
