import { Authaxios } from "../../axios/axios";
import { GETIMAGEBANKCATEGORYLIST, GETIMAGEBANKLIST, GETIMAGEBANKSUPERCATEGORYLIST } from "../../utility/ReduxConstants/ImageBankReducerConstant";

export const GetSuperCategoryListImageBank=(Axios)=>async(dispatch)=>{
   try {
       const {data} = await Axios.post('superCategoryList')
       dispatch({
        type:GETIMAGEBANKSUPERCATEGORYLIST,
        payload: data.body
       })
   } catch (error) {
        console.log(error)
   }
}
export const GetCategoryListImageBank=(Axios,param)=>async(dispatch)=>{
   try {
   
       const {data} = await Axios.post('skuCategoryList',param)
       
       dispatch({
        type:GETIMAGEBANKCATEGORYLIST,
        payload: data.list
       })
   } catch (error) {
        console.log(error)
   }
}
export const GetImageBank=(Axios,param)=>async(dispatch)=>{
   try {
     
       const {data} = await Axios.post('getAllImagesByCategory',param)
       
       dispatch({
        type:GETIMAGEBANKLIST,
        payload: data.body
       })
   } catch (error) {
        console.log(error)
   }
}
export const MoveProductionImage=(Axios,param)=>async(dispatch)=>{
   try {
       const {data} = await Axios.post('changeSKUImageStatus',param)
        return data
   } catch (error) {
        return error?.response?.data
        console.log(error)
   }
}

export const DeleteImageBankImage=(Axios,param)=>async(dispatch)=>{
    try {
        const {data} = await Axios.post('deleteImageFromImageBank',param)
         return data
    } catch (error) {
         return error?.response?.data
         console.log(error)
    }
}