export const NEWSKUCATEGORYLIST = "NEWSKUCATEGORYLIST";
export const NEWSKUCOMPANYLIST = "NEWSKUCOMPANYLIST";
export const NEWSUBCATEGORYLIST = "NEWSUBCATEGORYLIST";

export const GETALLSKULIST = "GETALLSKULIST";
export const GETSKUDETAILSNEW = "GETSKUDETAILSNEW";

export const UPDATESKUDETAILS = "UPDATESKUDETAILS";
export const SUPERCATEGORYLISTSKUMGT = "SUPERCATEGORYLISTSKUMGT";
export const RESETNEWSUDETAIL = "RESETNEWSUDETAIL";
export const REFVALUELIST="REFVALUELIST";

export const GETSKUNAMELISTONLY="GETSKUNAMELISTONLY";
