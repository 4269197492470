// 9999 - root,
// 1946 - super_admin,
// 3917 - super_category_admin,
// 5107 - user

export const ROLES = {
  LOGIN_MGT: [9999, 1946],
  ZONE_MGT: [9999, 1946],
  ACCESS_MGT: [9999, 1946],
  TRANSACTION_MGT: [9999],
  WALLET_MGT:[9999,1946],
  ACCESS_TRANSACTION_MGT: [1946, 3917],
  WALLET_PARTNER_MGT:[1946,3917],
  COMPANY_MGT: [9999, 1946, 3917],
  REMOVECOMPANY: [9999, 1946],
  SUPER_CATEGORY_MGT: [9999, 1946],
  CATEGORY_MGT: [9999, 1946, 3917],
  
  KYC_MGT: [9999, 1946],
  DASHBOARD: [9999, 1946],
  CALENDER: [9999,1946],
  TICKET_MGT: [9999, 1946],
  MAP_MGT: [9999, 1946, 3917],
  SKU_MGT: [9999, 1946, 3917],
  SKU_EXCEPTION_MGT: [9999, 1946, 3917],
  IMAGE_BANK_PROD: [9999, 1946],
  IMAGE_BANK_DELETE_PROD: [9999, 1946],
  VITARAN_PARTNER: [3917, 5107, 9999, 1946],
  SUB_CATEGORY: [3917, 9999, 1946],
  VIDEO_ANALYTICS:[3917,9999,1946],

  SKU_EXCEPTION_MGT_FILTER: [9999,3917],
  SKU_EXCEPTION_CATEGORY_ADMIN: [3917],
  LAUNCH_MGT: [9999, 1946],
};
