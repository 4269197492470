export const GETSLABONEZONELIST = "GETSLABONEZONELIST";
export const GETSLABTWOZONELIST = "GETSLABTWOZONELIST";

export const ADDZONEBYSTATEBOOL = "ADDZONEBYSTATEBOOL";
export const ADDZONEBYSTATEDEFAULT = "ADDZONEBYSTATEDEFAULT";

export const GETALLSTATELISTZONEMGTCONST = "GETALLSTATELISTZONEMGTCONST";

export const GETSLABONEZONELISTSTATUS = "GETSLABONEZONELISTSTATUS";
export const GETSLABTWOZONELISTSTATUS = "GETSLABTWOZONELISTSTATUS";
export const UPDATESTATESTATUS = "UPDATESTATESTATUS";
export const GETALLDISTRICTLIST = "GETALLDISTRICTLIST";
export const GETSDISTRICTLIST="GETSDISTRICTLIST";
export const GETSZONELISTBYSTATEANDDISTRICT="GETSZONELISTBYSTATEANDDISTRICT"
export const GETSDISTRICTSTATUS="GETSDISTRICTSTATUS"
