import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  listItemAvatarClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataDrawerDdetails from "./DataDrawerDdetails";
import useAxiosLambda from "../../../PrivateHooks/useAxiosLamda";
import {
  GetMapUserData,
  Get_assign_delivery_boy_All_Action,
} from "../../../actions/Map/MapAction";
import { MAPURL, assign_delivery_boy_All_Url } from "./MapUtilityFile";
import { ToastContainer, toast } from "react-toastify";
import { GETMAPALLORDERLIST } from "../../../utility/ReduxConstants/MapReducerConstant";

const DrawerAssign = ({ setOpen }) => {
  const MapUserList = useSelector((state) => state.MapReducer);
  const assignalldata = MapUserList?.AssignCheckBoxData;
  const [isChecked, setChecked] = useState(false);
  const [DB_NAME, setDB_NAME] = useState("");
  const [Order_Id, setOrder_Id] = useState();
  const dispatch = useDispatch();
  const LambdaAxios = useAxiosLambda();

  useEffect(() => {
    const Order_Id = MapUserList?.MapUserListViaLatAndLong?.map(
      (item) => item?.order_id
    );
    setOrder_Id(Order_Id);
  }, [MapUserList?.MapUserListViaLatAndLong]);

  const deliveryBoy_Id = [
    {
      db_id: "-1",
      name: "Myself",
    },
    ...MapUserList.db_list,
  ];
  const Db_Id_data = deliveryBoy_Id?.map((item) => item);

  console.log(assignalldata, "assignalldata");

  useEffect(() => {
    setChecked(MapUserList?.AssignCheckBoxData?.status === true ? true : false);
  }, [MapUserList?.AssignCheckBoxData]);

  useEffect(() => {
    if (assignalldata?.status === true && assignalldata?.code === 200) {
      setDB_NAME(assignalldata?.name);
    }
    if (
      assignalldata?.status === true &&
      assignalldata?.code === 200 &&
      assignalldata?.db_id === 0
    ) {
      setDB_NAME(Db_Id_data[0]?.name);
    }
  }, [MapUserList?.AssignCheckBoxData]);

  const handleAssignDeliveryBoy = (e) => {
    setDB_NAME(e.target.value);
  };

  const handdleChecked = () => {
    setChecked(!isChecked);
  };

  const handleAssignAllPartnerChecked = async (e) => {
    let param = {
      order_id: Order_Id,
      db_id: e?.db_id,
    };
    if (isChecked === true) {
      const Data = await dispatch(
        Get_assign_delivery_boy_All_Action(
          LambdaAxios,
          assign_delivery_boy_All_Url,
          param
        )
      );
      if (Data?.code === 200) {
        toast.success(Data?.message);
      } else {
        toast.warning(`failed to fetch Assign Delivery Parnter`);
      }
      console.log(param, "alistItemAvatarClasseslamsclalkc");
    }

    const id = MapUserList?.id;
    let param2 = {
      db_id: id,
    };
    const Data = await dispatch(GetMapUserData(LambdaAxios, MAPURL, param2));
    if (Data?.data?.code === 200) {
      dispatch({
        type: GETMAPALLORDERLIST,
        payload: Data?.data,
      });
    } else {
      toast.warning("Failed to fetch GetUsersData List");
    }
    setOpen(false);
  };

  const handleUnchekedData = async () => {
    let param = {
      order_id: Order_Id,
      db_id: 0,
    };
    if (isChecked === true) {
      const Data = await dispatch(
        Get_assign_delivery_boy_All_Action(
          LambdaAxios,
          assign_delivery_boy_All_Url,
          param
        )
      );
      console.log(Data, "2222");
      if (Data?.code === 200) {
        toast.success(Data?.message);
      } else {
        toast.warning(`failed to Update Assign Parnter`);
      }
    }
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        direction={"row"}
        sx={{
          backgroundColor: "#303254",
          color: "white",
          justifyContent: "space-between",
          padding: "10px 20px",
        }}
      >
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Stack>
            <Checkbox
              checked={isChecked}
              onChange={handdleChecked}
              onClick={handleUnchekedData}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
              }}
            />
          </Stack>
          <Stack>Reassign All Orders to</Stack>
        </Stack>
        <Stack>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              // value={DB_NAME}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="MySelf"
              value={DB_NAME}
              onChange={handleAssignDeliveryBoy}
              disabled={isChecked === true ? false : true}
              sx={{
                width: "8rem",
                backgroundColor: "#595C9B",
                borderRadius: "0",
              }}
              size="small"
            >
              {Db_Id_data?.map((e) => {
                return (
                  <MenuItem
                    onClick={() => handleAssignAllPartnerChecked(e)}
                    // value={e?.db_id}
                    value={e?.name}
                  >
                    {e?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {MapUserList?.MapUserListViaLatAndLong?.map((item) => (
        <DataDrawerDdetails
          NAME={DB_NAME}
          Checked={isChecked}
          item={item}
          Db_Id_data={Db_Id_data}
          setOpen={setOpen}
        />
      ))}
    </>
  );
};

export default DrawerAssign;
