import { Card, CircularProgress, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import BrokenImg from '../../../../assets/ImageNotValid.png';
import { ValidImage } from '../../../../actions/validImage';
import { CardWrapper, CardWrapperImg } from '../../CardWrapper';
import { SearchFilter } from '../../../../utility/functions/functions';
import { useDispatch, useSelector } from 'react-redux';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { GetCompanyDetailsId } from '../../../../actions/company/companyAction';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import UpdateCompany from './UpdateCompany';

const CompanyListTwo = ({ text }) => {
    const Axios = useAxiosPrivate()
    const store = useSelector(state => state.Company);
    const [list, setList] = useState([]);
    const [id, setId] = useState('');
    const dispatch = useDispatch();
    const StyleSheet = document.querySelector(":root");
    const ComputedStyle = getComputedStyle(StyleSheet);
    const BgColour = ComputedStyle.getPropertyValue("--color-light-background")
    function Color() {
        let ColorString = `#eb${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`
        console.log(ColorString)
        return ColorString
    }
    const column = ['item_name'];

    React.useEffect(() => {
        if (store.company_list !== undefined && store.company_list !== null && store.company_list.length !== 0) {
            setList(store.company_list);
        }

        if (store.company_detail !== undefined && store.company_detail !== null) {

            setId(store.company_detail.item_id !== undefined && store.company_detail.item_id !== null && store.company_detail.item_id !== 0 ? (parseInt(store.company_detail.item_id)) : (""))
        }
    }, [store])



    const handleClick = (id) => {

        let param = {
            "type": "company",
            "item_id": `${id}`
        }

        dispatch(GetCompanyDetailsId(Axios, param))
    }



    return (
        <>
            <Stack sx={{ maxHeight: "300px", minHeight: "300px", overflow: "auto" }}>
                <Grid container sx={{ width: "100%" }} gap={1} >
                    {
                        list.length !== 0 ? (
                            SearchFilter(list, column, text).map((image, index) => (
                                <Grid xs={12} sm={6} md={2.9} lg={2}
                                    sx={{
                                        // backgroundColor: `${Color()}`
                                    }}
                                >
                                    <CardWrapperImg component={Paper} sx={{
                                        
                                        border: id === parseInt(image.item_id) ? "2px solid white" : null
                                    }}>
                                        <Stack sx={{ height: "100px" }}>
                                            <Img img={image.item_image} />
                                        </Stack>

                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography variant='caption' sx={{ color: "white" }}>{image?.item_name ?? ''}</Typography>
                                            <IconButton
                                                sx={{ color: "white" }}
                                                onClick={() => handleClick(image?.item_id)}

                                            >
                                                <BorderColorIcon size="small" sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </Stack>
                                    </CardWrapperImg>
                                </Grid>
                            ))) :
                            <Grid xs={12} sm={6} md={2.9}
                                    sx={{
                                        // backgroundColor: `${Color()}`
                                        color:"white"
                                    }}
                                >
                                    <Typography>**No Company List Yet**</Typography>
                                    </Grid>
                    }         
                      </Grid>
            </Stack>


            <UpdateCompany />

        </>
    )
}

export default CompanyListTwo

const Img = ({ img }) => {
    const [loading, setLoading] = useState(false)
    const [obj, setObj] = useState('')
    const CheckImage = async () => {
        const Result = await ValidImage(img)

        if (Result) {
            setObj(img)
            setLoading(false)
        } else {
            setLoading(false)
            setObj(BrokenImg)
        }

    }
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            CheckImage(img)
        }, [400])

    }, [img])

    return (

        loading ?
            <Stack direction="column" justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
                <CircularProgress />
                <Typography>loading...</Typography>
            </Stack> :
            <img src={obj !== undefined && obj !== null && obj.length !== 0 ? (`${obj}`) : (defaultImg)} alt="company" loading="lazy"
                style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }} />


    )

}