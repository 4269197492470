import { Card, Collapse, IconButton, Paper, Slide, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import LaunchFilter from './LaunchFilter'
import LaunchedListTable from './LaunchedListTable'
import { LaunchUseContext } from '../../Context/LaunchContext'
import LaunchUserTable from './LaunchUserTable'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux'
import { GetLaunchDistrictList, GetLaunchStateList, GetLaunchSuperCategoryList } from '../../../actions/Launch/LaunchAction';
import useAxiosPrivate from '../../../PrivateHooks/useAxiosPrivate'
import { GETLAUNCHDISTRICTLIST, GETLAUNCHSCLIST, GETLAUNCHSTATELIST } from '../../../utility/ReduxConstants/LaunchReducerConstant'
import LaunchKycTable from './LaunchKycTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Route, Routes } from 'react-router'
import { GetColor } from '../../../utility/functions/functions'

// 

const LaunchMgt = () => {
    const BgColor = GetColor('--color-light-background')
    
    const store = useSelector(state => state.Launch)
    const dispatch = useDispatch()
    const Axios = useAxiosPrivate()

    const { showUserTable, handleShowUserTable, showKyc, handleShowKyc } = LaunchUseContext();

    const GetData = async () => {
        if (store?.sc_list?.length === 0) {
            const data = await dispatch(GetLaunchSuperCategoryList(Axios))
            if (data?.code === 200) {
                dispatch({ type: GETLAUNCHSCLIST, payload: data?.body ?? [] })
            } else {

                console.log("Failed to Fetch Sc List" + data?.message)
            }
        }

        if (store?.state_list?.length === 0) {

            const data2 = await dispatch(GetLaunchStateList(Axios))
            if (data2?.code === 200) {
                dispatch({ type: GETLAUNCHSTATELIST, payload: data2?.stateList ?? [] })
            } else {

                console.log("Failed to Fetch State List" + data2?.message)
            }
        }

        // if (store?.district_list?.length === 0) {
        //     let param={
        //         state_id: 0
        //     }
        //     const data3 = await dispatch(GetLaunchDistrictList(Axios,param))
        //     if (data3?.code === 200) {
        //         dispatch({ type: GETLAUNCHDISTRICTLIST, payload: data3?.districtList ?? [] })
        //     } else {

        //         console.log("Failed to Fetch District List" + data3?.message)
        //     }
        // }


    }

    useEffect(() => {
        GetData();
        console.log("executed")
    }, [])


    console.log(store, "showKyc")
    return (
        <>
            <Stack flex={1} minWidth={"100%"}>
                <Routes>
                    <Route />
                </Routes>

                {
                    showUserTable ?

                        <motion.div
                            animate={{
                                x: [200, 0],
                                y: 0,
                                scale: 1,
                                rotate: 0,

                            }}
                        // transition={{delay:1}}
                        >
                            <Stack gap={2}>
                                <Stack direction="row" justifyContent={"flex-start"} sx={{ position: "absolute", zIndex: "999", marginLeft: 3, marginTop: 1.15 }}>
                                    <IconButton
                                        size='small'
                                        // sx={{

                                        //     backgroundColor: "white",
                                        //     color: "black",
                                        //     '&:hover': {
                                        //         backgroundColor: "whitesmoke"
                                        //     }
                                        // }}
                                        onClick={() => handleShowUserTable(false)}
                                    >
                                        {/* <ArrowBackIosNewIcon /> */}
                                        <ArrowBackIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </Stack>
                                <LaunchUserTable />

                            </Stack>
                        </motion.div>

                        :
                        (showKyc ?
                            // 
                            <motion.div
                                animate={{
                                    x: [200, 0],
                                    y: 0,
                                    scale: 1,
                                    rotate: 0,

                                }}
                            // transition={{delay:1}}
                            >
                                <Stack gap={2}>
                                    <Stack direction="row" justifyContent={"flex-start"} sx={{ position: "absolute", zIndex: "999", marginLeft: 3, marginTop: 2.9 }}>
                                        <IconButton
                                            size='small'
                                            onClick={() => handleShowKyc(false)}
                                        >
                                            {/* <ArrowBackIosNewIcon /> */}
                                            <ArrowBackIcon sx={{ color: "white" }} />
                                        </IconButton>
                                    </Stack>
                                    <LaunchKycTable />

                                </Stack>
                            </motion.div>
                            :
                            <motion.div
                                animate={{
                                    x: [-200, 0],
                                    y: 0,
                                    scale: 1,
                                    rotate: 0,
                                }}
                            // transition={{delay:1}}
                            ><Stack gap={2}>
                                  <Card component={Paper} sx={{ backgroundColor: BgColor,padding:"10px" }} >
                                    <Stack gap={2   }>
                                            <Stack sx={{padding:"0px 10px 5px 10px"}}>
                                         <LaunchFilter />
                                    </Stack>
                                   
                                    <LaunchedListTable />
                                    </Stack>
                                
                                    </Card>
                                </Stack>
                            </motion.div>
                        )
                }



            </Stack>



        </>
    )
}

export default LaunchMgt
