import {
  GETALLHOLIDAYLIST,
  GETALLVALUEDETAILS,
  GETEDITEDVALUE,
  GETFORMSTATUS,
  GETHOLIDAYBYSTATEID,
  GETHOLIDAYBYSTATEIDANDYEAR,
  GETHOLIDAYCALENDERDATE,
  GETHOLIDAYCALENDERDETAILS,
  GETHOLIDAYID,
  GETSTATELIST,
  GETSTATELISTNAME,
} from "../../utility/ReduxConstants/HolidayCalenderReducerConstant";

const initialState = {
  stateList: [],
  stateListName: [],
  holidayCalenderDetails: null,
  formStatus: false,
  holidayCalenderDate: null,
  allHolidayList: null,
  holidayId: null,
  editedValue: null,
  holidayByState: null,
  AllValueDetails: null,
  holidayByStateAndYear: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETSTATELIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case GETSTATELISTNAME:
      return {
        ...state,
        stateListName: action.payload,
      };
    case GETHOLIDAYCALENDERDETAILS:
      return {
        ...state,
        holidayCalenderDetails: action.payload,
      };

    case GETHOLIDAYCALENDERDATE:
      return {
        ...state,
        holidayCalenderDate: action.payload,
      };
    case GETFORMSTATUS:
      return {
        ...state,
        formStatus: action.payload,
      };
    case GETALLHOLIDAYLIST:
      return {
        ...state,
        allHolidayList: action.payload,
      };
    case GETHOLIDAYID:
      return {
        ...state,
        holidayId: action.payload,
      };

    case GETEDITEDVALUE:
      return {
        ...state,
        editedValue: action.payload,
      };

    case GETHOLIDAYBYSTATEID:
      return {
        ...state,
        holidayByState: action.payload,
      };
      case GETALLVALUEDETAILS:
        return {
          ...state,
          AllValueDetails: action.payload,
        };
        case GETHOLIDAYBYSTATEIDANDYEAR:
        return {
          ...state,
          holidayByStateAndYear: action.payload,
        };
    default:
      return state;
  }
};
