import React from "react";
import WalletTransactionDropdown from "./WalletTransactionDropdown";
import WalletTransactionCoinsDetails from "./WalletTransactionCoinsDetails";
import WalletTransactionTable from "./WalletTransactionTable";

const WalletTransactionHistory = () => {
  return (
    <>
      <WalletTransactionDropdown />
      <WalletTransactionCoinsDetails />
      <WalletTransactionTable />
    </>
  );
};

export default WalletTransactionHistory;
