import React from 'react'
import CheckOffline from '../../utility/CheckOffline'

const OfflineMgt = () => {

    
  return (
    <div>Offline</div>
  )
}

export default OfflineMgt