import React from "react";
import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  AutocompleteTextFieldNormal,
  DateTextFieldCalenderWallet,
} from "../../../../utility/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";
import { useSelector } from "react-redux";


const PartnerWalletTransactionDropdown = () => {
  const vpCoinDetails= useSelector(
    (store) => store.PartnerWalletManagementReducer.walletVPDetails
  );

  console.log(vpCoinDetails,"partner74545")
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Link to="/home/access/wallet">
              <Stack>
                <ArrowBackIosIcon sx={{ color: "#FFFFFF" }} />
              </Stack>
            </Link>
            <Stack>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: "1.5rem",
                  font: "roboto",
                }}
              >
                Transaction History
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Typography sx={{ color: "#A4A4A4" }}>Wallet Balance:</Typography>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <Stack sx={{ width: "1.5rem" }}>
                <img src={GoldLogo} />
              </Stack>
              <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
                {vpCoinDetails?.vp_gold_balance??0}
              </Typography>
              <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <Stack sx={{ width: "1.5rem" }}>
                <img src={SilverLogo} />
              </Stack>

              <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                {vpCoinDetails?.vp_silver_coin??0}{" "}
                  <Typography sx={{ color: "#A4A4A4", fontSize: "0.8rem" }}>
                    (GST)
                  </Typography>
                </Stack>
              </Typography>

              <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <Stack sx={{ width: "1.5rem" }}>
                <img src={SilverLogo} />
              </Stack>
              <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                {vpCoinDetails?.vp_silver_balance_free??0}{" "}
                  <Typography sx={{ color: "#A4A4A4", fontSize: "0.8rem" }}>
                    (Free)
                  </Typography>
                </Stack>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PartnerWalletTransactionDropdown;
