import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkuByZone from "../SkuByZone";
import AddUpdateSku from "./AddUpdateSku";
import ImageBank from "./Imagebank/ImageBank";
import { CardWrapper, CardWrapperSmall } from "../CardWrapper";
import { ROLES } from "../../../utility/UserAllowedRoles";
import AccessSkuByZone from "../AccessSkuByZone";

const NewSku = () => {
  const dispatch = useDispatch();
  const rs = document.querySelector(":root");
  const R = getComputedStyle(rs);
  const bgColor = R.getPropertyValue("--color-light-background");
  const [tab, setTab] = useState("Add/Update SKU");
  // const [tab, setTab] = useState('SKU by zone')

  const LoginStore = useSelector((state) => state.Login);

  let superCategoryAdminAllowed = ROLES.SKU_EXCEPTION_CATEGORY_ADMIN.includes(
    parseInt(LoginStore?.user?.admin_role_code)
  );

  return (
    <div className="RootNewSku">
      <Stack direction="row" gap={1}>
        <CardWrapperSmall
          sx={{
            color: "white",
            borderBottom:
              tab === "Add/Update SKU"
                ? "3px solid white"
                : `3px solid ${bgColor}`,
            cursor: "pointer",
          }}
          onClick={() => setTab("Add/Update SKU")}
        >
          <Typography>Add/Update SKU</Typography>
        </CardWrapperSmall>
        <CardWrapperSmall
          sx={{
            color: "white",
            borderBottom:
              tab === "SKU by zone"
                ? "3px solid white"
                : `3px solid ${bgColor}`,
            cursor: "pointer",
          }}
          onClick={() => setTab("SKU by zone")}
        >
          <Typography>SKU by zone</Typography>
        </CardWrapperSmall>
        <CardWrapperSmall
          sx={{
            color: "white",
            borderBottom:
              tab === "Image Bank" ? "3px solid white" : `3px solid ${bgColor}`,
            cursor: "pointer",
          }}
          onClick={() => setTab("Image Bank")}
        >
          <Typography>Image Bank</Typography>
        </CardWrapperSmall>
      </Stack>
      <div style={{ marginTop: "20px" }}>
        {tab === "Add/Update SKU" ? (
          <AddUpdateSku />
        ) : tab === "SKU by zone" ? (
          superCategoryAdminAllowed ? (
            <AccessSkuByZone />
          ) : (
            <SkuByZone />
          )
        ) : (
          <ImageBank />
        )}
      </div>
    </div>
  );
};

export default NewSku;
