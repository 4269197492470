import { useParams } from "react-router"

export const GetParams =()=>{
    const { state_id, zone_id, zone_name, sc_id, changed } = useParams()
    return {
        state_id, zone_id, zone_name, sc_id, changed
    }
}


export const GetLocalStorageByKey =(key)=>{
    const ParsedData = JSON.parse(localStorage.getItem(key));
    return ParsedData
}

export const StoreInLocalStorage = (OBJ,key) => {
    let StringifiedData = JSON.stringify(OBJ);
    localStorage.setItem(key, StringifiedData);
}
