import {
  TextField,
  Autocomplete,
  Button,
  Stack,
  Card,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddDistrict from "./AddDistrict";
import Styles from "./styles/zone.module.css";

import DistrictTable from "./DistrictTable";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ALLDistrictListByStateID,
  AllStateListZoneMgt,
  ALLZoneListByStateID,
} from "../../../../actions/zone/zoneAction";
import {
  GETSDISTRICTLIST,
  GETSLABONEZONELIST,
  GETSLABTWOZONELIST,
} from "../../../../utility/ReduxConstants/ZoneReducerConstant";


import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { GetColor } from "../../../../utility/functions/functions";

// import { Button } from '@mui/material';

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const DistrictMgt = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [StateList, setStateList] = useState([]);
  const [StateValue, setStateValue] = useState("");
  const [StateValueTwo, setStateValueTwo] = useState("");
  const store = useSelector((state) => state.Zone);
  const [zoneList, setZoneList] = useState([]);
  const [districtList,setDistrictList]=useState([])
  const [zoneList2, setZoneList2] = useState([]);
  const [text, setText] = useState("");
  const [listLoader, setListLoader] = useState(false);

  useEffect(() => {
    setZoneList(store?.zoneListSlab1);
    setZoneList2(store?.zoneListSlab2);
    
  }, [store?.zoneListSlab1, store?.zoneListSlab2]);

  useEffect(()=>{
    setDistrictList(store?.districtListByState)
  },[store?.districtListByState])

console.log(store?.districtListByState,"setobjfj")
  
  useEffect(() => {
    let param;
    dispatch(AllStateListZoneMgt(Axios));
    setListLoader(true);
    setZoneList([]);
    setZoneList2([]);
  }, []);

  useEffect(() => {
    if (
      store.stateList !== undefined &&
      store.stateList !== null &&
      store.stateList.length !== 0
    ) {
      // setStateValue(store.stateList[0].state_name);
      // setStateValueTwo(store.stateList[0].state_name);
      setTimeout(() => {
        let arr = [];
        for (let i = 0; i < store.stateList.length; i++) {
          arr.push(store.stateList[i].state_name);
        }
        setStateList(arr);
        setListLoader(false);
      }, [1000]);
    }
  }, [store.stateList]);

  function GetId(value) {
    let id;

    if (
      store.stateList !== undefined &&
      store.stateList !== null &&
      store.stateList.length !== 0
    ) {
      for (let i = 0; i < store.stateList.length; i++) {
        if (store.stateList[i].state_name === value) {
          id = store.stateList[i].state_id;
        }
      }
    }

    return id;
  }

  

  const handleStateValueChange = (event, newValue) => {
    setStateValue(newValue);
    let Id = GetId(newValue);

    let param = {
      state_id: Id,
    };

    dispatch(ALLZoneListByStateID(Axios, param, GETSLABONEZONELIST));
  };

  const handleStateValueTwoChange = (event, newValue) => {
    setStateValueTwo(newValue);
    let Id = GetId(newValue);
    let param = {
      state_id: Id,
    };

    // dispatch(ALLDistrictListByStateID(Axios, param, GETSDISTRICTLIST));
    dispatch(ALLDistrictListByStateID(Axios, param, GETSDISTRICTLIST));
  };

  const BgColor = GetColor("--color-light-background");

  return (
    <>
      <div className="RooZoneMgtContainer">
        <Stack sx={{ color: "white" }}>
          <Card component={Paper} sx={{ backgroundColor: BgColor }}>
            <Stack
              sx={{ minHeight: "78vh" }}
              padding={2}
              direction={{ xs: "column", sm: "column", md: "row" }}
              gap={2}
            >
              <Stack flex={1}>
                <AddDistrict />
              </Stack>
              <Stack flex={2.5} sx={{ color: "white" }} gap={2}>
                <Stack>
                  <Typography>List Of Districts</Typography>
                </Stack>

                <Stack>
                  {/* <Stack direction="row" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={0.5} justifyContent="space-between">
                      <IconButton
                        // className={Styles.Icon}
                        sx={{
                          backgroundColor: "white",
                          '&:hover': {
                            backgroundColor: "gray",
                            transition: "0.2s linear"
                          }
                        }}>
                        <FilterAltIcon sx={{ color: "black !important" }} />
                      </IconButton>
                      <input style={{
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        color: "white"
                      }}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                      <IconButton
                        // className={Styles.Icon}
                        onClick={() => setText('')}
                      >
                        <CloseIcon sx={{ color: "white !important" }} />
                      </IconButton>
                    </Stack> */}
                  <Autocomplete
                    loading={listLoader}
                    value={StateValueTwo}
                    onChange={handleStateValueTwoChange}
                    options={StateList}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {listLoader ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        variant="outlined"
                        label="State"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Card
                    component={Paper}
                    sx={{
                      backgroundColor: "#323546",
                      padding: "3px",
                      minHeight: "60vh",
                    }}
                  >
                    <DistrictTable list={districtList} type={2} />
                  </Card>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </div>
    </>
  );
};

export default DistrictMgt;
