import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DateTextFieldCalender,
  StyledTableCell,
  StyledTableCellWallet,
} from "../../../utility/TextField";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../UserAccess/styles/UserAccessTable.module.css";
import { GetColor } from "../../../utility/functions/functions";
import {
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
  DeleteHolidayCalender,
  UpdateHolidayCalender,
} from "../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { SearchFilter } from "../../../utility/constants/functions/functions";
import { IOSSwitch } from "../../../utility/IosSwitch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GETEDITEDVALUE,
  GETFORMSTATUS,
  GETHOLIDAYID,
} from "../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PopUpModal from "../../../utility/Backdrop";
import ConfirmationDialog from "../HolidayCalender/ConfirmationDialog";
import GoldLogo from "../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../assets/Wallet/silverCoin.png";
import WalletSendCoins from "./WalletSendCoins";
import WalletData from "../../../data.json";
import { Link } from "react-router-dom";
import { GETSENDCOINPOPUPSTATUS, GETVITARANID } from "../../../utility/ReduxConstants/WalletManagementReducerConstant";
import { WalletTableDetails } from "../../../actions/WalletManagament/WalletManagementAction";
const WalletTable = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
 
  const allTableDetails = useSelector(
    (store) => store.WalletManagementReducer.walletTableDetails
  );

  const allDetails = useSelector(
    (store) => store.WalletManagementReducer.walletAllDetails
  );

  console.log(allTableDetails, "walletmngrtrr");

  const popUpStatus = useSelector(
    (store) => store.WalletManagementReducer.sendCoinPopupStatus
  );
  const vitaranUserDetails = useSelector(
    (store) => store.WalletManagementReducer.vitaranId
  );
  

  const handleUserCoins = (Access) => {
    dispatch({ type: GETVITARANID, payload: Access });
  };

  useEffect(() => {
    let param = {
      zone_id: allDetails?.zone_id,
    };

    dispatch(WalletTableDetails(Axios, param));
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCellWallet align="center" size="small">
                  S.No
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Partner Details
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Zone
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Latest Transfer
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "18rem",
                        border: "0.1rem solid #505365",
                        background: "#505365",
                        borderBottomRightRadius: "1rem",
                        borderBottomLeftRadius: "1.5rem",
                        marginTop: "-0.3rem",
                        fontSize: "0.7rem",
                        padding: "0.3rem",
                      }}
                    >
                      Wallet Balance
                    </Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2rem",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                          <img src={GoldLogo} />
                        </Stack>

                        <Typography sx={{ fontSize: "0.7rem" }}>
                          Total
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                          <img src={SilverLogo} />
                        </Stack>

                        <Typography sx={{ fontSize: "0.7rem" }}>GST</Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                          <img src={SilverLogo} />
                        </Stack>

                        <Typography sx={{ fontSize: "0.7rem" }}>
                          Free
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                          <img src={SilverLogo} />
                        </Stack>

                        <Typography sx={{ fontSize: "0.7rem" }}>
                          Expired
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Action
                </StyledTableCellWallet>
              </TableRow>
            </TableHead>

            <TableBody>
              {allTableDetails ? (
                allTableDetails?.map((item, index) => {
                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      handleUserCoins={handleUserCoins}
                      vitaran_id={item?.admin_id}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ color: "white" }}>No data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {popUpStatus && (
          <Stack
            sx={{
              top: "30%",
              left: "45%",
              // transform: "translate(-50%,-50%)",
              position: "absolute",
              position: "fixed",
              zIndex: 10,
            }}
          >
            <WalletSendCoins />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default WalletTable;

const Row = ({
  Access,
  index,
  holidayId,
  stateId,
  handleDelete,
  handleEdit,
  handleConfirmRemove,
  dayName,
  formattedDate,
  currentDate,
  currentHolidayName,
  handleUserCoins,
  vitaran_id,
}) => {
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const [editIconStatus, setEditIconStatus] = useState(true);
  console.log(editIconStatus);
  const [editedEvent, setEditedEvent] = useState(Access.holiday_name);
  console.log(currentHolidayName, "stateList");

  console.log(editedEvent, "editedEvent");

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "editedValue");
  const [changedDate, setChangedDate] = useState(null);

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYID,
      payload: { holiday_id: holidayId, state_id: Access.state_id },
    });
  }, []);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmRemoveClick = () => {
    handleConfirmRemove(holidayId, stateId);
    setIsConfirmDialogOpen(false);
  };
  const handleUpdateClick = () => {
    setEditIconStatus(false);
  };

  const handleSave = () => {
    setEditIconStatus(true);

    handleEdit(
      holidayId,
      stateId,
      currentDate,
      currentHolidayName,
      formattedDate
    );
  };

  const [inputValue, setInputValue] = useState(
    editedEvent || currentHolidayName
  );

  const handleEditEvent = (e, value) => {
    console.log(e.target.value);
    setEditedEvent(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: changedDate, edited_event: editedEvent },
    });
  }, [changedDate, editedEvent]);

  const Edit = () => {
    // setOpen(true);

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleStartDateChange = (newValue) => {
    const originalDate = new Date(newValue);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const date = originalDate.getDate();

    // Create a formatted date string (YYYY-MM-DD)
    const formattedDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;
    setChangedDate(formattedDateString);

    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: formattedDateString, edited_event: editedEvent },
    });
  };

  const popUpStatus = useSelector(
    (store) => store.WalletManagementReducer.sendCoinPopupStatus
  );

  console.log(popUpStatus);

  const handleSendCoins = () => {
    dispatch({
      type: GETSENDCOINPOPUPSTATUS,
      payload: true,
    });

    handleUserCoins(Access);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {index}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {Access?.name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {Access.zone ?? "N/A"}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "flex-start",
              // alignItems: "flex-start",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.4rem",
            }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={GoldLogo} />
                </Stack>
                {Access?.last_gold_coin ?? "N/A"}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={SilverLogo} />
                </Stack>
                {Access?.last_silver_coin_free ?? "N/A"}(Free)
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}
              >
                {" "}
                <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                  <img src={SilverLogo} />
                </Stack>
                {Access.last_silver_coin ?? "N/A"}(GST)
              </Stack>
            </Stack>
            <Stack sx={{ display: "flex" }}>{Access.time}</Stack>
          </Stack>
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              gap: "1.8rem",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack sx={{ width: "1.5rem" }}>{Access.gold_coin}</Stack>
            <Stack sx={{ width: "1.5rem" }}>{Access.silver_coin}</Stack>
            <Stack sx={{ width: "1.5rem" }}>{Access.silver_coin_free}</Stack>
            <Stack sx={{ width: "1.5rem" }}>
              {Access.totalSilverExpiredCoins ?? "N/A"}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <button
              style={{
                background: "#DC1D13",
                padding: "0.5rem",
                color: "#FFFFFF",
                border: "0.1rem solid #DC1D13",
                cursor: "pointer",
              }}
              onClick={handleSendCoins}
            >
              Send Coins
            </button>
            <Link to="/home/wallet/user/history">
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 500,
                  textDecoration: "underline",
                  fontSize: "0.8rem",
                }}
              >
                View Transactions
              </Typography>
            </Link>
          </Stack>
        </TableCell>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmRemoveClick}
          holidayId={holidayId}
          stateId={stateId}
        />
      </TableRow>
    </>
  );
};
