import React,{useState,useEffect} from 'react'
import { ValidImage } from '../../../../actions/validImage'
import { CircularProgress, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import BrokenImg from '../../../../assets/ImageNotValid.png';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate';
import { GetSuperCategoryDetailsId } from '../../../../actions/SCategory/sCategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import { CardWrapperImg } from '../../CardWrapper';
import { SearchFilter } from '../../../../utility/functions/functions';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import UpdateSCategory from './UpdateSCategory';



const SCategoryListTwo = ({text}) => {
  const Axios = useAxiosPrivate()
  const store = useSelector((state) => state.SuperCategory);
  const [scList, setscList] = useState([]);
  const column = ['name'];
  const [id, setId] = useState('');

  
  const dispatch = useDispatch();

  useEffect(() => {
    let selected_scategory = {};
    setscList(store.s_category_list);
    if(store.s_category_list !== undefined &&
      store.s_category_list !== null && 
      store.s_category_list.length !== 0
      ){
       selected_scategory = store.s_category_list[0];
      }
    dispatch(GetSuperCategoryDetailsId(Axios,selected_scategory));
  }, [store.s_category_list]);

  const handleClick = (image) => {
    setId(image.sc_id);
    dispatch(GetSuperCategoryDetailsId(Axios,image));
}

  return (
    <>
    <Stack sx={{ maxHeight: "300px", minHeight: "300px", overflow: "auto" }}>
    <Grid container sx={{ width: "100%" }} gap={1} >
        {
            scList.length !== 0 ? (
                SearchFilter(scList, column, text).map((image, index) => (
                    <Grid xs={12} sm={6} md={2.9} lg={2}
                        sx={{
                            // backgroundColor: `${Color()}`
                        }}
                    >
                         <CardWrapperImg component={Paper} sx={{
                                        
                                        border: store?.super_category_detail?.sc_id === parseInt(image.sc_id) ? "2px solid white" : null 
                                    }}>
                                        <Stack sx={{ height: "100px" }}>
                                            <Img img={image.image} />
                                        </Stack>

                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography variant='caption' sx={{ color: "white" }}>{image?.name ?? ''}</Typography>
                                            <IconButton
                                                sx={{ color: "white" }}
                                                onClick={() => handleClick(image)}

                                            >
                                                <BorderColorIcon size="small" sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </Stack>
                                    </CardWrapperImg>
                    </Grid>
                ))) :
                <Grid xs={12} sm={6} md={2.9}
                        sx={{
                            // backgroundColor: `${Color()}`
                            color:"white"
                        }}
                    >
                        <Typography>**No Company List Yet**</Typography>
                        </Grid>
        }         
          </Grid>
</Stack>
<UpdateSCategory/>
</>
  )
}

export default SCategoryListTwo


const Img = ({ img }) => {
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState('')
  const CheckImage = async () => {
      const Result = await ValidImage(img)

      if (Result) {
          setObj(img)
          setLoading(false)
      } else {
          setLoading(false)
          setObj(BrokenImg)
      }

  }
  useEffect(() => {
      setLoading(true)
      setTimeout(() => {
          CheckImage(img)
      }, [400])

  }, [img])

  return (

      loading ?
          <Stack direction="column" justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
              <CircularProgress />
              <Typography>loading...</Typography>
          </Stack> :
          <img src={obj !== undefined && obj !== null && obj.length !== 0 ? (`${obj}`) : (defaultImg)} alt="company" loading="lazy"
              style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%"
              }} />


  )

}