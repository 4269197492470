import React from "react";
import WalletUserCoinDetails from "./WalletUserCoinDetails";
import WalletUserTransactioinDropdown from "./WalletUserTransactioinDropdown";
import WalletUserTransactionTable from "./WalletUserTransactionTable";

const WalletUserTransactionHistory = () => {
  return (
    <>
      <WalletUserCoinDetails />
      <WalletUserTransactioinDropdown />
      <WalletUserTransactionTable />
    </>
  );
};

export default WalletUserTransactionHistory;
