import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, Paper } from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#232738",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#3B405E",

  // hide last border
}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "Rajasthani Steel",
    "Steel",
    "Construction Material",
    "₹0.8",
    "₹5500",
    "03/02/23 ; 6:38 PM",
    "₹5500"
  ),
  createData("Rajasthani Steel", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const RunTheDealRun = () => {
  return (
    <div style={{ color: "white" }}>
      <Stack>
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Button>
            <ArrowBackIcon sx={{ color: "white" }} />
          </Button>
          <Typography>RUN DEALS RESULT | 4 SKUs</Typography>
        </Stack>
        {rows.map(() => (
          <Stack
            sx={{
              background: "#3B405E",
              marginTop: "2rem",
              padding: "1rem",
              borderRadius: "5px",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ justifyContent: "space-between" }}
            >
              <Stack>
                <Typography>RAJASTHANI STEEL</Typography>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={"flex-end"}
              gap={5}
            >
              <Stack
                direction={"column"}
                textAlign={"center"}
                sx={{
                  backgroundColor: "#2D3148",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
              >
                <Stack>
                  <Typography variant="h4">8</Typography>
                </Stack>
                <Stack>x2 Deals </Stack>
              </Stack>
              <Stack
                direction={"column"}
                textAlign={"center"}
                sx={{
                  backgroundColor: "#2D3148",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
              >
                <Stack>
                  <Typography variant="h4">₹78,980 </Typography>
                </Stack>
                <Stack>Order Value </Stack>
              </Stack>
              <Stack
                direction={"column"}
                textAlign={"center"}
                sx={{
                  backgroundColor: "#2D3148",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
              >
                <Stack>
                  {" "}
                  <Typography variant="h4">105</Typography>
                </Stack>
                <Stack>Lots</Stack>
              </Stack>
              <Stack
                direction={"column"}
                textAlign={"center"}
                sx={{
                  backgroundColor: "#2D3148",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
              >
                <Stack>
                  <Typography variant="h4">₹4932</Typography>
                </Stack>
                <Stack>ARPO</Stack>
              </Stack>
              <Stack
                direction={"column"}
                textAlign={"center"}
                sx={{
                  backgroundColor: "#2D3148",
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}
              >
                <Stack>
                  <Typography variant="h4">₹70</Typography>
                </Stack>
                <Stack>APPO</Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                backgroundColor: "#3B405E",
                padding: "1rem",
                boxShadow: "none",
                overflow: "hidden",
              }}
            >
              <Stack sx={{ marginTop: "1rem" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ border: "none" }}>
                      <TableRow>
                        <StyledTableCell sx={{ border: "none" }}>
                          Time stamp
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          No of lots
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          Cut off price
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          Order Value
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          Profit
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          Buyer Name
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ border: "none" }}>
                          Seller Name
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ color: "white", border: "none" }}
                          >
                            02/03/23 ; 6:45 PM
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            45
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            0.7
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            ₹6700
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            ₹70
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            Sneha Thakur
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: "white", border: "none" }}
                          >
                            Sarthak Thakur
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default RunTheDealRun;
