import React, { useState, useEffect } from 'react'
import Styles from './styles/carousel.module.css';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import images from '../../../../utility/constants/images'
import { useSelector, useDispatch } from 'react-redux';
import { InsertEmoticon } from '@mui/icons-material';
import { GetProductDetailsId } from '../../../../actions/product/productAction';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import { SearchFilter } from '../../../../utility/functions/functions';

const viewImgPath = `${baseImgPath}/product/`;

const CarouselProduct = () => {
    const scrollRef = React.useRef(null);
    const store = useSelector(state => state.Product);
    const [list, setList] = useState([]);
    const column = ['item_name'];
    const [text, setText] = useState("")
    const [id, setId] = useState('');
    const dispatch = useDispatch();

    console.log(store)

    const scroll = (direction) => {
        const { current } = scrollRef;

        if (direction === 'left') {
            current.scrollLeft -= 300;
        } else {
            current.scrollLeft += 300;
        }
    };

    React.useEffect(() => {
        if (store.product_list !== undefined && store.product_list !== null && store.product_list.length !== 0) {
            setList(store.product_list);
        }

        if (store.product_detail !== undefined && store.product_detail !== null) {

            setId(store.product_detail.item_id !== undefined && store.product_detail.item_id !== null && store.product_detail.item_id !== 0 ? (parseInt(store.product_detail.item_id)) : (""))
        }
    }, [store])


    const handleClick = (id) => {

        let param = {
            "type": "product",
            "item_id": `${id}`
        }

        dispatch(GetProductDetailsId(param))
    }


    return (
        <div className={Styles.container}>
            <div className={Styles.SearchContainer}>
                <label className={Styles.SearchTitle}>Search</label>
                <input value={text} onChange={(e) => setText(e.target.value)} placeholder="Search Product..." className={Styles.SearchInput} />
            </div>

            <div className={Styles.CarouselContainer}>

                {
                    list.length !== 0 ? (<div className={Styles.ImageContainer} ref={scrollRef}>
                        {SearchFilter(list, column, text).map((image, index) => (
                            <div className={Styles.ColumnContainer} onClick={() => handleClick(image.item_id)}>
                                <div className={id === parseInt(image.item_id) ? Styles.ImagesCardSelected : Styles.ImagesCard} key={`gallery_image-${index + 1}`}>
                                    <img src={image.item_image !== undefined && image.item_image !== null && image.item_image.length !== 0 ? (`${image.item_image}`) : (defaultImg)} alt="gallery_image" />

                                </div>
                                <label className={Styles.ImageTitle}>{image.item_name}</label>
                            </div>
                        ))}
                    </div>) : (
                        <div className={Styles.NothingContainer}>
                            <label className={Styles.Nothing}>**No Product**</label>
                        </div>
                    )
                }

                <div className={Styles.ArrowContainer}>
                    <BsArrowLeftShort className={Styles.ArrowIcon} onClick={() => scroll('left')} />
                    <BsArrowRightShort className={Styles.ArrowIcon} onClick={() => scroll('right')} />
                </div>
            </div>

        </div>
    )
}

export default CarouselProduct