import React, { useState } from "react";
import ZoneMgt from "./zoneMgt";
import Styles from "./styles/RootZoneMgt.module.css";
import "./styles/zone.scss";
import StateMgt from "./StateMgt";
import { useSelector } from "react-redux";
import Wrapper from "../Wrapper";
import { Card, Paper, Stack, Typography } from "@mui/material";
import { GetColor } from "../../../utility/functions/functions";
import DistrictMgt from "./DistrictMgt/DistrictMgt";

const RootMgtZone = () => {
  const [tab, setTab] = useState("State Mgt");
  const tabs = ["State Mgt", "District Mgt", "Zone Mgt"];
  const [state, setState] = useState(false);

  const BgColor = GetColor("--color-light-background");

  return (
    <Stack direction="column" gap={1.5}>
      <Stack direction="row" gap={1}>
        {tabs.map((item, index) =>
          tab === item ? (
            <Card
              component={Paper}
              sx={{
                backgroundColor: BgColor,
                border: "2px solid white",
                transitionTimingFunction: "ease",
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                sx={{
                  color: "white",
                  minWidth: "100px",
                  padding: "10px 35px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "10px" }, textTransform: "uppercase" }}
                >
                  {item}
                </Typography>
              </Stack>
            </Card>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{
                color: "white",
                minWidth: "100px",
                padding: "10px 35px",
                border: "2px solid transparent",
                cursor: "pointer",
                transitionTimingFunction: "ease",
              }}
              onClick={() => setTab(item)}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: "10px" }, textTransform: "uppercase" }}
              >
                {item}
              </Typography>
            </Stack>
          )
        )}
      </Stack>
      <Stack>
        {tab === "State Mgt" ? (
          <StateMgt />
        ) : tab === "Zone Mgt" ? (
          <ZoneMgt />
        ) : (
          <DistrictMgt />
        )}
      </Stack>
    </Stack>
  );
};

export default RootMgtZone;
