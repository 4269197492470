import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Checkbox, Paper, Stack } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetCutOffListAccess } from "../../../actions/RunTheDeals/RunTheDeals.Action";
import { AxiosPrivate } from "../../../axios/axios";
import { GETCUTOFFLISTACCESS } from "../../../utility/ReduxConstants/RunTheDealsConstant";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#232738",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#3B405E",

  // hide last border
}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "Rajasthani Steel",
    "Steel",
    "Construction Material",
    "₹0.8",
    "₹5500",
    "03/02/23 ; 6:38 PM",
    "₹5500"
  ),
  createData("Rajasthani Steel", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const RunTheDeailsTable = ({ handleEdit, edit, handleDetails }) => {
  const Store = useSelector((state) => state.RunTheDealsReducer);
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();

  const GetCutOffList = async () => {
    const param = {
      zone_name: Store.zonelist.zone_name,
      sc_id: Store.sclist.sc_id,
      category_id: Store.categorylist.category_id,
    };

    const CutOffList = await dispatch(GetCutOffListAccess(Axios, param));

    if (CutOffList?.code === 200) {
      dispatch({
        type: GETCUTOFFLISTACCESS,
        payload: CutOffList?.list,
      });
    } else {
      console.log("something went wrong");
    }
  };
  return (
    <div>
      <Stack
        sx={{
          backgroundColor: "#3B405E",
          padding: "1rem",
          boxShadow: "none",
          overflow: "hidden",
        }}
      >
        <Stack sx={{ marginTop: "1rem" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead sx={{ border: "none" }}>
                <TableRow>
                  <StyledTableCell sx={{ border: "none" }}>
                    <Checkbox
                      // {...label}
                      defaultChecked
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }}>
                    SKU Name
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    Category
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    Super Category
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    Cut Off Price
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    Order Value
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    Last Run Time
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    ARPO
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ border: "none" }}>
                    {edit ? (
                      <EditingButton />
                    ) : (
                      <EditButton handleEdit={handleEdit} />
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ color: "white", border: "none" }}
                    >
                      <Checkbox
                        // {...label}
                        defaultChecked
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ color: "white", border: "none" }}
                    >
                      Steel
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      Construction Material
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      ₹0.8
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      ₹5500
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      ₹5500
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      03/02/23 ; 6:38 PM
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      ₹5500
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ color: "white", border: "none" }}
                    >
                      <Button
                        sx={{ backgroundColor: "#58608D", color: "white" }}
                        onClick={handleDetails}
                      >
                        Details
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
};

export default RunTheDeailsTable;

const EditButton = ({ handleEdit }) => {
  return (
    <Button
      sx={{
        width: "7rem",
        color: "white",
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "red",
        },
      }}
      onClick={handleEdit}
    >
      Edit
    </Button>
  );
};

const EditingButton = () => {
  return (
    <Button
      sx={{
        width: "7rem",
        color: "white",
        backgroundColor: "#787878",
        "&:hover": {
          backgroundColor: "#787878",
        },
      }}
    >
      Editing
    </Button>
  );
};
