import React from "react";
import { Outlet } from "react-router";
import Wrapper from "../Wrapper";

const VitaranPartner = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

export default VitaranPartner;
