import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Styles from "./styles/zone.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { ChangeZoneStatus } from "../../../actions/zone/zoneAction";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row({ item, type, districtId, status_zone }) {
  console.log(status_zone, "status_zone");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [obj, setObj] = useState({
    zone_id: 0,
    state_id: 0,
    zone_name: "",
    zone_status: false,
    district_id: 0,
  });
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("Bangalore");
  const [addsuccess, setAddsuccess] = useState(false);
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  console.log(districtId, "districtValue");

  React.useEffect(() => {
    if (item !== undefined && item !== null) {
      setObj({
        zone_id:
          item.zone_id !== undefined &&
          item.zone_id !== null &&
          item.zone_id !== 0
            ? item.zone_id
            : 0,
        state_id:
          item.state_id !== undefined &&
          item.state_id !== null &&
          item.state_id !== 0
            ? item.state_id
            : 0,
        zone_name:
          item.zone_name !== undefined &&
          item.zone_name !== null &&
          item.zone_name !== 0
            ? item.zone_name
            : "",
        zone_status:
          item.zone_status !== undefined &&
          item.zone_status !== null &&
          item.zone_status !== 0
            ? item.zone_status
            : false,
      });
    }
  }, [item]);

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Changed Status SuccessFully");
      setAddsuccess(false);
    }

    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [addsuccess, failed]);

  const handleToggle = useCallback(
    (e) => {
      setObj({
        ...obj,
        zone_status: e.target.checked,
      });

      let param = {
        zone_id: obj.zone_id,
        state_id: obj.state_id,
        zone_name: obj.zone_name,
        zone_status: e.target.checked,
        district_id: districtId,
      };

      dispatch(
        ChangeZoneStatus(Axios, param, type, setAddsuccess, failed, setFailed)
      );
    },
    [obj]
  );

  console.log(obj, "fdrdfdsf");

  return (
    <>
      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <input
            value={obj.zone_name}
            className={Styles.input}
            disabled={true}
          />
        </TableCell>
        {/* <TableCell align="center" size="small">
          <div className={Styles.mid}>
            <div
              className={Styles.EditContainer}
              onClick={() => setEdit(!edit)}
            >
              <CreateIcon className={Styles.EditIcon} />
            </div>
          </div>
        </TableCell> */}
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <span className={Styles.IosSwitch}>
            <IOSSwitch onChange={handleToggle} checked={obj.zone_status} />
            {obj.zone_status ? "Active" : "Inactive"}
          </span>
        </TableCell>
        <TableCell align="center" size="small">
          <div className={Styles.mid}>
            <div className={Styles.DefaultContainer}>
              <CheckCircleOutlineIcon
                className={
                  obj.status ? Styles.DefaultGreenIcon : Styles.DefaultIcon
                }
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

const ZoneTable = ({
  list,
  zoneListByDistAndState,
  districtValue,
  type,
  districtId,
}) => {
  // const [list,setList]=useState([1,2,3,4,5,6])

  console.log(zoneListByDistAndState,"rfvghb")

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <TableContainer
          sx={{ maxHeight: 400, minWidth: "100%", marginTop: "5px" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23" }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCell align="center" size="small">
                  Zones
                </StyledTableCell>
                {/* <StyledTableCell align='center' size='small' >Edit</StyledTableCell> */}
                <StyledTableCell align="center" size="small">
                  Status
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {districtValue?.length > 0 ? (
                zoneListByDistAndState.map((item, index) => {
                  console.log(item.zone_status,"rfvghb")
                  return (
                    <Row
                      item={item}
                      districtId={districtId}
                      key={item.zone_name}
                      
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    *No Rules*
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ZoneTable;
