import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Card, Paper } from "@mui/material";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeletePopUp({ Item, remove, setRemove, handleRemove }) {
  const Axios = useAxiosPrivate();

  const handleClose = () => {
    setRemove(false);
  };

  const handleDelete = () => {
    handleRemove();
  };
  return (
    <div>
      <Dialog
        open={remove}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to delete image?"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card component={Paper} sx={{ width: "50px", height: "50px" }}>
            <img
              src={Item.image_path}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Yes, Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
