import React from "react";
import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTextFieldCalenderWallet } from "../../../../utility/TextField";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";

const WalletTransactionCoinsDetails = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "3rem",
        // padding: "1.5rem",
        alignItems: "center",
      }}
    >
      <Stack sx={{background: "#323546",paddingTop:"2rem",paddingBottom:"2rem",paddingRight:"1.2rem",paddingLeft:"1.2rem"}}>
        <Stack
          sx={{
            dispaly: "flex",
            flexDirection: "column",
            gap: "1.6rem",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {/* <Stack sx={{ marginLeft: "-10rem", marginTop: "1rem" }}> */}
            <Typography sx={{ color: "white" }}>Select Date Range:</Typography>
          {/* </Stack> */}

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              width:"100%"
            }}
          >
            <Typography sx={{ color: "white" }}>From: </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                //   className={Styles.picker}
                size="small"
                inputFormat="dd/MM/yyyy"
                // value={
                //   editedValue?.edited_date
                //     ? editedValue?.edited_date
                //     : currentDate
                // }
                // onChange={handleStartDateChange}
                renderInput={(params) => (
                  <DateTextFieldCalenderWallet
                    size="small"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Typography sx={{ color: "white", marginLeft: "1rem" }}>
              To :{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                //   className={Styles.picker}
                size="small"
                inputFormat="dd/MM/yyyy"
                // value={
                //   editedValue?.edited_date
                //     ? editedValue?.edited_date
                //     : currentDate
                // }
                // onChange={handleStartDateChange}
                renderInput={(params) => (
                  <DateTextFieldCalenderWallet
                    size="small"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack>
            <button
              style={{
                width: "18rem",
                padding: "0.5rem",
                background: "#323546",
                color: "#C4C4C4",
                fontSize: "1rem",
                border: "0.05rem solid #C4C4C4",
              }}
            >
              Generate
            </button>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{background: "#323546",padding: "1rem",paddingTop:"1.8rem",paddingBottom:"1.8rem"}}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            color: "#FFFFFF",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                padding: "0.2rem",
                height: "2.5rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>Coins</Typography>
            </Stack>

            <Stack>
              <Stack sx={{ width: "1rem" }}>
                <img src={GoldLogo} />
              </Stack>
              <Typography sx={{ fontSize: "0.8rem" }}>Total</Typography>
            </Stack>
            <Stack>
              <Stack sx={{ width: "1rem" }}>
                <img src={SilverLogo} />
              </Stack>
              <Typography sx={{ fontSize: "0.8rem" }}>Free</Typography>
            </Stack>
            <Stack>
              <Stack sx={{ width: "1rem" }}>
                <img src={SilverLogo} />
              </Stack>
              <Typography sx={{ fontSize: "0.8rem" }}>GST</Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2.2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                background: "#505365",
                padding: "0.2rem",
                width: "10rem",
                height: "2.5rem",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1.5rem",
              }}
            >
              <Typography sx={{ fontSize: "0.8rem" }}>Given</Typography>
            </Stack>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2.2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                background: "#505365",
                padding: "0.2rem",
                width: "10rem",
                height: "2.5rem",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1.5rem",
              }}
            >
              <Typography sx={{ fontSize: "0.8rem" }}>With Partner</Typography>
            </Stack>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2.2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                background: "#505365",
                padding: "0.2rem",
                width: "10rem",
                height: "2.5rem",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1.5rem",
              }}
            >
              <Typography sx={{ fontSize: "0.8rem" }}>With User</Typography>
            </Stack>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2.2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                background: "#505365",
                padding: "0.2rem",
                width: "10rem",
                height: "2.5rem",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1.5rem",
              }}
            >
              <Typography sx={{ fontSize: "0.8rem" }}>
                Consumed/Withdrawn
              </Typography>
            </Stack>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
            <Typography>Coin</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WalletTransactionCoinsDetails;
