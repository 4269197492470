import {
  Autocomplete,
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllDistrictListTicket,
  GetAllZoneListTicket,
  GetZoneTickets,
  UpdateTicket,
} from "../../../actions/ticket/ticket.action";
import { BackButton, RedButton } from "../../../utility/Button";
import { DateTextField, NormalTextField } from "../../../utility/TextField";
import Styles from "./styles/table.module.css";
import TicketMgtTable2 from "./TicketMgtTable2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GETZONETICKETS,
  UPDATETICKETREDUX,
} from "../../../utility/ReduxConstants/TicketReducerConstant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AiOutlineArrowLeft } from "react-icons/ai";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const TicketMgtSection = ({ From, To, handleBack, State, Zone }) => {
  const Axios = useAxiosPrivate();
  console.log(State, Zone, "Ticketopklm");
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Ticket);
  const rs = document.querySelector(":root");
  const R = getComputedStyle(rs);
  const bgColor = R.getPropertyValue("--color-light-background");
  const [value, setValue] = useState(new Date(From));
  const [valueTwo, setValueTwo] = useState(new Date(To));
  const [stateList, setStateList] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [zoneValue, setZoneValue] = useState("");
  const [ticket, setTicket] = useState({});

  const handleDateChange = async (newValue) => {
    setValue(newValue);

    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === stateValue ? (State_Id = item?.state_id) : null
    );

    let param = {
      state_id: State_Id,
      zone_name: zoneValue,
      start_date: new Date(newValue),
      end_date: new Date(valueTwo),
    };
    const data = await GetZoneTickets(Axios, param);
    if (data?.code === 200) {
      dispatch({ type: GETZONETICKETS, payload: data?.body });
    } else {
      console.log(data);
    }
  };
  const handleChange = (e) => {
    setTicket({
      ...ticket,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeTwo = async (newValue) => {
    setValueTwo(newValue);

    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === stateValue ? (State_Id = item?.state_id) : null
    );

    let param = {
      state_id: State_Id,
      zone_name: zoneValue,
      start_date: new Date(value),
      end_date: new Date(newValue),
    };
    const data = await GetZoneTickets(Axios, param);
    if (data?.code === 200) {
      dispatch({ type: GETZONETICKETS, payload: data?.body });
    } else {
      console.log(data);
    }
  };

  const DistrictList = async () => {
    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === State ? (State_Id = item?.state_id) : null
    );
    let param = {
      state_id: State_Id,
    };
    const data = await GetAllDistrictListTicket(Axios, param);
    if (data?.code === 200) {
      let Arr = [];

      data?.districtList &&
        data?.districtList?.map((item) => Arr.push(item?.district_name));
      setDistrictList(Arr);
      // setZoneValue(Zone);
    }
  };

  const ZoneList = async () => {
    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === State ? (State_Id = item?.state_id) : null
    );
    let param = {
      state_id: State_Id,
    };
    const data = await GetAllZoneListTicket(Axios, param);
    if (data?.code === 200) {
      let Arr = [];

      data?.zoneList &&
        data?.zoneList?.map((item) => Arr.push(item?.zone_name));
      setZoneList(Arr);
      // setZoneValue(Zone);
    }
  };

  console.log(store?.districtList, "Ticketopklm");
  useEffect(() => {
    let Arr = [];
    store?.stateList?.length !== 0 &&
      store?.stateList?.map((item) => {
        Arr.push(item?.state_name);
      });
    setStateList(Arr);
    setStateValue(State);
    DistrictList();
    ZoneList();
  }, []);

  useEffect(() => {
    let Arr = [];
    store?.districtList?.length !== 0 &&
      store?.districtList?.map((item) => {
        Arr.push(item?.district_name);
      });
    setDistrictList(Arr);
    // setDistrictValue(State);
  }, []);

  useEffect(() => {
    setTicket(store?.ticket);
  }, [store]);

  const handleUpdate = async () => {
    let param = {
      t_id: ticket?.t_id,
      priority: ticket?.priority,
      fix_version: ticket?.fix_version,
      status: ticket?.status,
      root_cause: ticket?.root_cause,
    };

    const data = await UpdateTicket(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: UPDATETICKETREDUX, payload: ticket });
      toast.success("Updated Ticket SuccessFully");
    } else {
      toast.error("Updating Ticket Failed");
    }
  };

  const handleStateChange = async (e, newVal) => {
    setStateValue(newVal);
    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === newVal ? (State_Id = item?.state_id) : null
    );
    let paramTwo = {
      state_id: State_Id,
    };
    let Arr = ["All"];
    const District = await GetAllDistrictListTicket(Axios, paramTwo);
    if (District?.code === 200) {
      District?.districtList &&
        District?.districtList?.map((item) => Arr.push(item?.district_name));
      setDistrictList(Arr);
      setDistrictValue("");
      setZoneValue("");
    }

    let param = {
      state_id: State_Id,
      zone_name: Arr[0],
      start_date: new Date(value),
      end_date: new Date(valueTwo),
    };

    console.log(param);

    const data = await GetZoneTickets(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: GETZONETICKETS, payload: data?.body });
    } else {
      console.log(data);
    }
  };

  const handleDistrictChange = async (e, newVal) => {
    setDistrictValue(newVal);
    let District_Id = 0;
    store?.districtList?.map((item) =>
      item?.district_name === newVal ? (District_Id = item?.district_id) : null
    );
    let paramTwo = {
      district_id: District_Id,
    };
    let Arr = ["All"];
    const Zone = await GetAllZoneListTicket(Axios, paramTwo);
    if (Zone?.code === 200) {
      Zone?.zoneList &&
        Zone?.zoneList?.map((item) => Arr.push(item?.zone_name));
      setZoneList(Arr);
      setZoneValue("");
    }

    // let param = {
    //   state_id: State_Id,
    //   zone_name: Arr[0],
    //   start_date: new Date(value),
    //   end_date: new Date(valueTwo),
    // };

    // console.log(param);

    // const data = await GetZoneTickets(Axios, param);

    // if (data?.code === 200) {
    //   dispatch({ type: GETZONETICKETS, payload: data?.body });
    // } else {
    //   console.log(data);
    // }
  };

  const handleZoneChange = async (e, newVal) => {
    setZoneValue(newVal);
    let State_Id = 0;
    store?.stateList?.map((item) =>
      item?.state_name === stateValue ? (State_Id = item?.state_id) : null
    );

    let param = {
      state_id: State_Id,
      zone_name: newVal,
      start_date: new Date(value),
      end_date: new Date(valueTwo),
    };

    const data = await GetZoneTickets(Axios, param);

    if (data?.code === 200) {
      dispatch({ type: GETZONETICKETS, payload: data?.body });
    } else {
      console.log(data);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Stack spacing={2}>
        <Card
          component={Paper}
          sx={{ backgroundColor: bgColor, padding: "10px", marginTop: "5px" }}
        >
          <Stack direction="row" justifyContent="flex-start">
            <button className={Styles.BackButton} onClick={handleBack}>
              <AiOutlineArrowLeft />
            </button>
          </Stack>
          <Stack spacing={4}>
            <Stack
              direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
            >
              <Stack
                flex={1}
                direction={{ xs: "column", sm: "column", gap: 1 }}
                padding={2}
                spacing={2}
              >
                <Autocomplete
                  value={stateValue}
                  onChange={handleStateChange}
                  options={stateList}
                  className={Styles.autocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="State"
                    />
                  )}
                />
                <Autocomplete
                  value={districtValue}
                  onChange={handleDistrictChange}
                  options={districtList}
                  className={Styles.autocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="District"
                    />
                  )}
                />
                <Autocomplete
                  value={zoneValue}
                  onChange={handleZoneChange}
                  options={zoneList}
                  className={Styles.autocomplete}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Zone"
                    />
                  )}
                />
              </Stack>
              <Stack
                flex={1}
                direction={{ xs: "column", sm: "column", gap: 1 }}
                padding={2}
                spacing={2}
              >
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ color: "white", minWidth: "50px" }}>
                    From :
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      //   className={Styles.picker}
                      size="small"
                      inputFormat="dd/MM/yyyy"
                      value={value}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <DateTextField
                          size="small"
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography sx={{ color: "white", minWidth: "50px" }}>
                    To :
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      //   className={Styles.picker}
                      size="small"
                      inputFormat="dd/MM/yyyy"
                      value={valueTwo}
                      onChange={handleChangeTwo}
                      renderInput={(params) => (
                        <DateTextField
                          size="small"
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Stack>
              <Stack
                flex={2}
                direction={{ xs: "column", sm: "row", md: "row" }}
                justifyContent={{ md: "space-around" }}
                gap={1}
                alignItems={{ md: "center" }}
              >
                <Stack
                  sx={{
                    backgroundColor: "black",
                    padding: "20px 40px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "white",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {store?.total_tickets ?? ""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Total
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: "black",
                    padding: "20px 40px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "white",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {store?.total_open ?? ""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Open
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: "black",
                    padding: "20px 40px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "white",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {store?.total_resolved ?? ""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Resolved
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <TicketMgtTable2 />
            </Stack>
          </Stack>
        </Card>

        <Card
          component={Paper}
          sx={{ backgroundColor: bgColor, padding: "10px", marginTop: "5px" }}
          id="ticket"
        >
          <Stack direction="column" sx={{ display: "flex", flexWrap: "wrap" }}>
            <Stack spacing={2} padding={2}>
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={{ xs: 2, sm: 2, md: 0, lg: 0, xl: 0 }}
                padding={2}
              >
                <Stack direction="row" flex={1} spacing={1}>
                  <Typography
                    sx={{
                      color: "white",
                      minWidth: "90px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    Ticket no:
                  </Typography>
                  <Typography sx={{ color: "white" }}>
                    {ticket?.ticket_no ?? ""}
                  </Typography>
                </Stack>
                <Stack direction="row" flex={1} spacing={1}>
                  <Typography sx={{ color: "white" }}>User Name:</Typography>
                  <Typography sx={{ color: "white" }}>
                    {(ticket?.first_name?.length === 0 &&
                      ticket?.last_name?.length === 0) ||
                    (ticket?.first_name === null &&
                      ticket?.last_name === null) ||
                    (ticket?.first_name === undefined &&
                      ticket?.last_name === undefined)
                      ? "N/A"
                      : `${ticket?.first_name} ${ticket?.last_name}`}
                  </Typography>
                </Stack>
                <Stack direction="row" flex={1} spacing={1}>
                  <Typography sx={{ color: "white" }}>User Mobile:</Typography>
                  <Typography sx={{ color: "white" }}>
                    {ticket?.mobile_no ?? ""}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography
                  sx={{
                    color: "white",
                    minWidth: "90px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Title:
                </Typography>
                <Typography sx={{ color: "white" }}>
                  {ticket?.title ?? ""}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                <Typography
                  sx={{
                    color: "white",
                    minWidth: "90px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Description:
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    maxWidth: "200px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {ticket?.descs ?? ""}
                </Typography>
              </Stack>
            </Stack>

            <Divider sx={{ color: "white" }} />

            <Stack sx={{ padding: "10px", marginTop: "20px" }} spacing={3}>
              <Stack
                direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
                padding={1}
                spacing={4}
              >
                <Stack flex={1.1} direction="column" spacing={3}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        color: "white",
                        minWidth: "90px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Priority:
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={parseInt(ticket?.priority) ?? ""}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "white !important",
                          },
                        }}
                        name="priority"
                        onChange={handleChange}
                      >
                        <MenuItem value={""}></MenuItem>
                        <MenuItem value={1}>Prio 1</MenuItem>
                        <MenuItem value={2}>Prio 2</MenuItem>
                        <MenuItem value={3}>Prio 3</MenuItem>
                        <MenuItem value={4}>Prio 4</MenuItem>
                        <MenuItem value={5}>Prio 5</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        color: "white",
                        minWidth: "90px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Fix Version:
                    </Typography>
                    <NormalTextField
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        width: "100%",
                      }}
                      value={ticket?.fix_version ?? ""}
                      name="fix_version"
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        color: "white",
                        minWidth: "90px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      Status:
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={ticket?.status ?? ""}
                        name="status"
                        onChange={handleChange}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          color: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          ".MuiSvgIcon-root ": {
                            fill: "white !important",
                          },
                        }}
                        //onChange={handleChange}
                      >
                        <MenuItem value={true}>Open</MenuItem>
                        <MenuItem value={false}>Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack flex={2} direction="row">
                  <Typography
                    variant="body1"
                    sx={{
                      minWidth: "100px",
                      display: "flex",
                      alignItems: "flex-start",
                      color: "white",
                    }}
                  >
                    Root Cause:
                  </Typography>
                  <NormalTextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      width: "100%",
                    }}
                    multiline
                    rows={4}
                    value={ticket?.root_cause ?? ""}
                    name="root_cause"
                    onChange={handleChange}
                  />
                </Stack>
              </Stack>

              <Stack direction="row" spacing={3} padding={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      minWidth: "90px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    Image :
                  </Typography>
                  <a href={ticket?.file_path} target="_blank">
                    {ticket?.file_path?.substring(
                      ticket?.file_path?.lastIndexOf("/") + 1
                    ) ?? ""}
                  </a>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" sx={{ color: "white" }}>
                    Image :
                  </Typography>
                  <a href={ticket?.file_path_video} target="_blank">
                    {ticket?.file_path_video?.substring(
                      ticket?.file_path_video?.lastIndexOf("/") + 1
                    ) ?? ""}
                  </a>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="center">
                <RedButton onClick={handleUpdate}>Update</RedButton>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </>
  );
};

export default TicketMgtSection;
