
export const GetSubCatSuperCategoryList = (Axios) => async (dispatch) => {
        try {
          const { data } = await Axios.post("superCategoryList");
          return data
        } catch (error) {
          return error?.response?.data
        }
    };

export const GetSubCatCategoryList = (Axios,param) => async (dispatch) => {
        try {
          const { data } = await Axios.post("skuCategoryList",param);
          return data
        } catch (error) {
          return error?.response?.data
        }
    };
export const GetSubCategoryList = (Axios,param) => async (dispatch) => {
        try {
          const { data } = await Axios.post("subCategoryList",param);
          return data
        } catch (error) {
          return error?.response?.data
        }
    };
export const AddSubCategory = (Axios,param) => async (dispatch) => {
        try {
          const { data } = await Axios.post("addSubCategory",param);
          return data
        } catch (error) {
          return error?.response?.data
        }
    };
export const UpdateSubCategory = (Axios,param) => async (dispatch) => { 
        try {
          const { data } = await Axios.post("updateSubCategory",param);
          return data
        } catch (error) {
          return error?.response?.data
        }
    };
export const RemoveSubCategory = (Axios,param) => async (dispatch) => {
        try {
          const { data } = await Axios.post("removeSubCategory",param);
          
          return data
        } catch (error) {
          return error?.response?.data
        }
    };
