import { Backdrop, Button, Card, CircularProgress, Paper, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Styles from './styles/AddCompany.module.css';
import images from '../../../../utility/constants/images';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddCompanyData, GetCompanyPageData } from '../../../../actions/company/companyAction';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { AutocompleteTextField } from '../../../../utility/TextField';

const viewImgPath = `${baseImgPath}/company/`;

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));


const RedBorderButton = styled(Button)(({ theme }) => ({
    color: "#DC1D13",
    backgroundColor: "#323546",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#fff",
    },
}));

const AddCompany = () => {
    const Axios = useAxiosPrivate()
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState({})
    const [file, setFile] = useState('');
    const [img, setImg] = useState(defaultImg);
    const [addsuccess, setAddsuccess] = useState(false)
    const [failed, setFailed] = useState({
        addFailed: false,
        addFailedMessage: ""
    });
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        "item_name": "",
        "item_desc": "",
        "item_image": ""
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleCancel = () => {
        setForm({
            ...form,
            "item_name": "",
            "item_desc": "",
            "item_image": ""
        })
    }

    const IsValid = () => {
        let success = true;
        let err = {};
        const chars = form.item_name?.match(/[^a-zA-Z0-9' ,&-](?= |$)/g)

        if (!form.item_name.trim()) {
            err.companyname = '**Company Name Cannot Be Empty**'
            success = false;
        }
        else if (chars && chars?.length!==0) {
            err.companyname =
              "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
            success = false;
          }

        if (!form.item_image.trim()) {
            err.companyimage = '**Please Choose a Image**'
            success = false;
        }

        if (!form.item_desc.trim()) {
            err.description = '**Company Description Cannot Be Empty**'
            success = false;
        }

        setError(err)
        return success
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (IsValid()) {
            let param = {
                "type": "Company",
                "item_name": `${form.item_name}`,
                "item_desc": `${form.item_desc}`,
                "item_image": `${viewImgPath}${form.item_image}`
            }

            let Data = await dispatch(AddCompanyData(Axios, param))

            if (Data.code === 200) {
                let paramTwo = {
                    "type": "company",
                    "user_id": 1
                }
                dispatch(GetCompanyPageData(Axios, paramTwo))
                toast.success('Company Added successfully');
                setForm({
                    ...form,
                    "item_name": "",
                    "item_desc": "",
                    "item_image": ""
                })
                setImg(defaultImg)
            } else {
                toast.error(Data.message)
            }
        }
    }

    const imgUpload = async (formData) => {
        await axios.post(`${baseImgUploadUrl}image_upload_company`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(res => {

            if (res.data.code === 200) {
                setLoading(false)
                toast.success("Uploaded SuccessFully")
                setImg(`${res.data.full_path}`);
                setForm({
                    ...form,
                    item_image: res.data.name
                })
            }
            else {
                toast.error("Img Not Uploaded")
            }

        })
            .catch(err => console.log(err.message))
    }
    const imgSelect = (event) => {
        setLoading(true)
        setFile(event.target.files[0]);

    }

    React.useEffect(() => {
        const formData = new FormData();
        formData.append('file', file);
        imgUpload(formData)
    }, [file]);


    const actions = [
        { icon: <FileCopyIcon />, name: 'Copy' },
        { icon: <SaveIcon />, name: 'Save' },
        { icon: <PrintIcon />, name: 'Print' },
        { icon: <ShareIcon />, name: 'Share' },
      ];

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <form onSubmit={handleSubmit}>

                <Stack direction="column" gap={3}>
                    <Stack gap={.5}>
                        <Card component={Paper} sx={{ borderRadius: "16px" }}>
                            <Stack sx={{ height: "180px" }} direction="row" justifyContent={"center"} alignItems="center">
                                {!loading && <img src={img} alt="company" loading="lazy" className={Styles.Img} />}
                                {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                                {
                                    loading ?
                                        <Stack direction="column" position="absolute" justifyContent={"center"} alignItems={"center"}>
                                            <CircularProgress />
                                            <Typography>uploading...</Typography>
                                        </Stack> :
                                        null
                                }
                                <input type="file" accept="image/*" name="image-upload" id={`CompanyImgUploadAdd`} className={Styles.imginp} onChange={imgSelect} />
                            </Stack>


                        </Card>
                        <div className={Styles.ImageUploadContainer}>

                            <label className={Styles.ImageUploadButton} htmlFor={`CompanyImgUploadAdd`} >
                                UPLOAD
                            </label>
                        </div>

                    </Stack>
                    <Stack gap={1.5}>
                        <Stack>
                            <AutocompleteTextField size="small" label="Company Name" value={form.item_name} name="item_name" onChange={handleChange} />
                            {error.companyname && <Typography sx={{ color: "red" }} variant="caption" className={Styles.error}>{error.companyname}</Typography>}
                        </Stack>
                        <Stack>
                            <AutocompleteTextField size="small" multiline rows={4} sx={{ color: "white !important" }} label="Description" name="item_desc" value={form.item_desc} onChange={handleChange} />
                            {error.description && <Typography sx={{ color: "red" }} variant="caption" className={Styles.error}>{error.description}</Typography>}
                        </Stack>
                    </Stack>

                    <Stack direction="row" gap={1.5}>
                        <RedButton sx={{ flex: 1, borderRadius: "18px" }} type="submit">Add</RedButton>
                        <RedBorderButton sx={{ flex: 1, borderRadius: "18px", backgroundColor: "white" }} onClick={handleCancel}>Cancel</RedBorderButton>
                    </Stack>
                  
                </Stack>

            </form>
        </>
    )
}

export default AddCompany