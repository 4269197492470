import { Authaxios } from "../../axios/axios";
import {
  GETCATEGORYLISTFOREXCEPTION,
  GETEXCEPTIONACCESSDISTRICTLIST,
  GETEXCEPTIONACCESSSTATELIST,
  GETEXCEPTIONACCESSZONELIST,
  GETEXCEPTIONDISTRCTLIST,
  GETEXCEPTIONSTATELIST,
  GETEXCEPTIONZONELIST,
  GETNEWEXCEPTIONDETAILBYZONE,
  GETNEWEXCEPTIONLISTBYZONE,
  UPDATENEWSKUEXCEPTIONLIST,
} from "../../utility/ReduxConstants/SkuExceptionReducerConstant";

export const ExceptionStateList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");

   
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const ExceptionAccessStateList = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessStateList",param);

   
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONACCESSSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

// export const ExceptionAccessDistrictList = (Axios) => async (dispatch) => {
//   try {
//     const { data } = await Axios.post("AlldistrictList");

   
//     if (data.code === 200) {
//       dispatch({ type: GETEXCEPTIONACCESSDISTRICTLIST, payload: data.districtList });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const ExceptionAccessZoneList = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessZoneList",param);

   
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONACCESSZONELIST, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const ExceptionDistrictList = (Axios, param) => async (dispatch) => {
  console.log(param,"StoreException")
  try {
    const { data } = await Axios.post("AlldistrictList", param);
    console.log(data,"StoreException")
    
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONDISTRCTLIST, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const ExceptionAccessDistrictList = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessDistrictList",param);

   
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONACCESSDISTRICTLIST, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};


export const ExceptionZoneList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllzoneList", param);
    
    if (data.code === 200) {
      dispatch({ type: GETEXCEPTIONZONELIST, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetCategoryListForException =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("skuCategoryList", param);
      
      if (data.code === 200) {
        dispatch({ type: GETCATEGORYLISTFOREXCEPTION, payload: data.list });
      }
    } catch (error) {
      console.log(error);
    }
  };

  

export const GetSkuExceptionListByZoneNew =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post(
        "skuExceptionListByZoneCategory",
        param
      );
      

      if (data.code === 200) {
        dispatch({ type: GETNEWEXCEPTIONLISTBYZONE, payload: data.list });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const ChangeSkuStatusExceptionBySpId =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("changeStatusSkuException", param);

      return data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };

export const UpdateSkuExceptionBySpId = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("updateSkuException", param);

    if (data.code === 200) {
      let paramTwo = {
        sp_id: param.sp_id,
      };
      let result = await dispatch(SkuExceptionDetailBySpId(Axios, paramTwo));
      dispatch({ type: UPDATENEWSKUEXCEPTIONLIST, payload: result });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const SkuExceptionDetailBySpId = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("skuExceptionDetailBySpId", param);

    if (data.code === 200) {
      dispatch({ type: GETNEWEXCEPTIONDETAILBYZONE, payload: data.detail });
      return data.detail;
    }
  } catch (error) {
    console.log(error);
  }
};
