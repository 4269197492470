import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateTextField,
  DateTextFieldCalender,
} from "../../../utility/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  GETALLHOLIDAYLIST,
  GETFORMSTATUS,
  GETHOLIDAYCALENDERDATE,
  GETHOLIDAYCALENDERDETAILS,
} from "../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import {
  AddHolidayCalenderList,
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
} from "../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../Zone/styles/addZone.module.css";
import { Typography } from "@mui/material";
import GoldLogo from "../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../assets/Wallet/silverCoin.png";
import { GETSENDCOINPOPUPSTATUS } from "../../../utility/ReduxConstants/WalletManagementReducerConstant";
import {
  WalletSendCoinsConfirm,
  WalletTableDetails,
} from "../../../actions/WalletManagament/WalletManagementAction";

const WalletSendCoins = ({ onChildAction }) => {
  const [silverFree, setSilverFree] = useState(0);
  const [silverGST, setSilverGST] = useState(0);
  const [gold, setGold] = useState(0);
  const Axios = useAxiosPrivate();

  const vitaranUserDetails = useSelector(
    (store) => store.WalletManagementReducer.vitaranId
  );

  const allDetails = useSelector(
    (store) => store.WalletManagementReducer.walletAllDetails
  );

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch({ type: GETSENDCOINPOPUPSTATUS, payload: false });
  };

  const popUpStatus = useSelector(
    (store) => store.WalletManagementReducer.sendCoinPopupStatus
  );

  const [error, setError] = useState({});
  const IsValid = () => {
    let error = {};
    let success = true;

    if (silverFree == 0 && silverGST == 0 && gold == 0) {
      error.coin = "**All can't be 0. Please enter value in any one.**";
      success = false;
    }

    setError(error);
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let param = {
      vitaran_partner_id: vitaranUserDetails?.admin_id,

      silver_coin_free: silverFree,

      silver_coin: silverGST,

      gold_coin: gold,
    };
    if (IsValid()) {
      const data = await dispatch(WalletSendCoinsConfirm(Axios, param));
      if (data.code === 200) {
        toast.success(data?.message);
      } else {
        toast.error("Failed to Transfer!!!");
      }
      let param1 = {
        zone_id: allDetails?.zone_id,
      };

      dispatch(WalletTableDetails(Axios, param1));
      dispatch({ type: GETSENDCOINPOPUPSTATUS, payload: false });
    }
  };

  const handleSilverFreeChange = (e) => {
    const newValue = e.target.value.replace(/^0+/, "");
    setSilverFree(newValue);
  };
  console.log(silverFree, "walletmngrtrr");
  const handleSilverGSTChange = (e) => {
    const newValue = e.target.value.replace(/^0+/, "");
    setSilverGST(newValue);
  };
  const handleGoldChange = (e) => {
    const newValue = e.target.value.replace(/^0+/, "");
    setGold(newValue);
  };
  return (
    popUpStatus && (
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black
            //   pointerEvents: "none",
          }}
        ></div>
        <Stack
          sx={{
            position: "absolute",
            gap: "0.7rem",
            background: "#323546",
            padding: "1.5rem",
            marginLeft: "-3rem",
            marginTop: "-2rem",
            zIndex: 10,
          }}
        >
          <h3 style={{ color: "white", fontWeight: 400 }}>Coin Transfer</h3>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <Typography sx={{ color: "#C3C3C3", fontSize: "0.8rem" }}>
              To:
            </Typography>
            <Typography sx={{ color: "#FFFFFF", fontSize: "0.8rem" }}>
              {" "}
              {vitaranUserDetails?.name??"N/A"}
            </Typography>
            <Typography sx={{ color: "#C3C3C3", fontSize: "0.8rem" }}>
              {"("+vitaranUserDetails?.mobile_no+")"??"N/A"}
            </Typography>
          </Stack>

          <Stack>
            <form onSubmit={handleSubmit}>
              <Stack sx={{ gap: "0.2rem", marginTop: "0.5rem" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "white", fontWeight: 400 }}>
                    Silver Coins (Free)
                  </h4>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.4rem",
                    }}
                  >
                    <Typography sx={{ color: "#B9B7B7" }}>
                      Current Balance: {vitaranUserDetails?.silver_coin_free??0}
                    </Typography>
                    <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                      <img src={SilverLogo} />
                    </Stack>
                  </Stack>
                </Stack>

                <input
                  style={{
                    padding: "0.5rem",
                    background: "#0C0C0C",
                    borderColor: "#7F7F7F", // Lighter border color
                    color: "#FFFFFF", // Lighter text color
                  }}
                  value={silverFree}
                  type="Number"
                  onChange={handleSilverFreeChange}
                  // placeholder="2023"
                />
              </Stack>
              <Stack sx={{ gap: "0.2rem", marginTop: "1rem" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "white", fontWeight: 400 }}>
                    Silver Coins with GST
                  </h4>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.4rem",
                    }}
                  >
                    <Typography sx={{ color: "#B9B7B7" }}>
                      Current Balance: {vitaranUserDetails?.silver_coin??0}
                    </Typography>
                    <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                      <img src={SilverLogo} />
                    </Stack>
                  </Stack>
                </Stack>
                <input
                  style={{
                    padding: "0.5rem",
                    background: "#0C0C0C",

                    borderColor: "#7F7F7F", // Lighter border color
                    color: "#FFFFFF", // Lighter text color
                  }}
                  type="Number"
                  value={silverGST}
                  onChange={handleSilverGSTChange}
                  // placeholder="Karnataka"
                />
              </Stack>
              <Stack sx={{ gap: "0.2rem", marginTop: "1rem" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "white", fontWeight: 400 }}>
                    Gold Coins
                  </h4>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.4rem",
                    }}
                  >
                    <Typography sx={{ color: "#B9B7B7" }}>
                      Current Balance: {vitaranUserDetails?.gold_coin??0}
                    </Typography>
                    <Stack sx={{ width: "1rem", marginTop: "0.2rem" }}>
                      <img src={GoldLogo} />
                    </Stack>
                  </Stack>
                </Stack>
                <input
                  style={{
                    padding: "0.5rem",
                    background: "#0C0C0C",

                    borderColor: "#7F7F7F", // Lighter border color
                    color: "#FFFFFF", // Lighter text color
                  }}
                  value={gold}
                  type="Number"
                  onChange={handleGoldChange}
                  // placeholder="0"
                />
              </Stack>
              {error.coin && (
                <Typography
                  sx={{ color: "red" }}
                  variant="caption"
                  className={Styles.error}
                >
                  {error.coin}
                </Typography>
              )}

              <Stack
                sx={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "15rem",
                  marginTop: "1rem",
                  gap: "1rem",
                }}
              >
                <button
                  style={{
                    padding: "0.7rem",
                    background: "#323546",
                    color: "white",
                    borderColor: "white",
                    cursor: "pointer",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    background: "#DC1D13",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  // onClick={handleSave}
                  type="submit"
                >
                  Send Coins
                </button>
              </Stack>
            </form>
          </Stack>
        </Stack>
      </div>
    )
  );
};

export default WalletSendCoins;
