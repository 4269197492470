import React, { useEffect, useState, useCallback } from "react";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Styles from "./styles/SkuByZoneTable.module.css";
import { Button, Stack, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import { IOSSwitch } from "../../../utility/IosSwitch";
import { defaultImg } from "../../../utility/BaseUrl";
import {
  ChangeSkuStatusExceptionBySpId,
  SkuExceptionDetailBySpId,
} from "../../../actions/Exception/SkuException";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchFilter } from "../../../utility/functions/functions";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: "12px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

function Row({ item }) {
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();
  const [obj, setObj] = useState({
    sp_id: 0,
    sku_image: defaultImg,
    sku_name: "",
    lot_size: 0,
    ptw: 0,
    status: false,
    category_name: "",
    zone_name: "",
  });


  // useEffect(() => {
  //   if (item !== undefined && item !== null) {
  //     // ... your existing code to set the 'obj' state ...

  //     // Update Redux state with the new table data
  //     dispatch(setTableData(updatedTableData));
  //   }
  // }, [item, dispatch]);

  useEffect(() => {
    if (item !== undefined && item !== null) {
      setObj({
        ...obj,
        zone_name:
          item.zone_name !== null &&
          item.zone_name !== undefined &&
          item.zone_name.length !== 0
            ? item.zone_name
            : "",
        category_name:
          item.category_name !== null &&
          item.category_name !== undefined &&
          item.category_name.length !== 0
            ? item.category_name
            : "",
        sp_id:
          item.sp_id !== null && item.sp_id !== undefined && item.sp_id !== 0
            ? item.sp_id
            : 0,
        sku_image:
          item.sku_image !== null &&
          item.sku_image !== undefined &&
          item.sku_image.length !== 0
            ? item.sku_image
            : defaultImg,
        sku_name:
          item.sku_name !== null &&
          item.sku_name !== undefined &&
          item.sku_name.length !== 0
            ? item.sku_name
            : "",
        lot_size:
          item.lot_size !== null &&
          item.lot_size !== undefined &&
          item.lot_size !== 0
            ? item.lot_size
            : 0,
        ptw:
          item.ptw !== null && item.ptw !== undefined && item.ptw !== 0
            ? item.ptw
            : 0,
        ptr:
          item.ptr !== null && item.ptr !== undefined && item.ptr !== 0
            ? item.ptr
            : 0,
        lot_size_pickup:
          item.lot_size_pickup !== null &&
          item.lot_size_pickup !== undefined &&
          item.lot_size_pickup !== 0
            ? item.lot_size_pickup
            : 0,
        lot_size_delivery:
          item.lot_size_delivery !== null &&
          item.lot_size_delivery !== undefined &&
          item.lot_size_delivery !== 0
            ? item.lot_size_delivery
            : 0,
        status:
          item.status !== null && item.status !== undefined && item.status
            ? true
            : false,
      });
    }
  }, [item]);

  const handleToggle = async (e) => {
    let param = {
      sp_id: obj.sp_id,
      status: e.target.checked,
    };
    let result = await dispatch(ChangeSkuStatusExceptionBySpId(Axios, param));
    console.log(result, "result handleToggle", param);
    if (result?.code !== 200) {
      toast.error(`${result?.message}`);
      setObj({
        ...obj,
        status: !param?.status,
      });
    } else {
      setObj({
        ...obj,
        status: !e.target.checked,
      });
      toast.success("Status Changed SuccessFully");
    }
  };
  console.log(obj, "skuItem");

  const handleDetail = () => {
    let param = {
      sp_id: obj.sp_id,
    };
    dispatch(SkuExceptionDetailBySpId(Axios, param));
    var elmntToView = document.getElementById("SkuByZoneItemVp");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <img src={obj.sku_image} alt="img" className={Styles.Sku_image} />
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.sku_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.category_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.ptr}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.ptw}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.lot_size_pickup}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj.lot_size_delivery}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <IOSSwitch
            checked={obj.status ? true : false}
            onChange={handleToggle}
          />
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <RedButton variant="contained" onClick={handleDetail} size="small">
            Details
          </RedButton>
        </TableCell>
      </TableRow>
    </>
  );
}

const SkuByZoneTable = ({ setObj, text }) => {
  const Axios = useAxiosPrivate();

  const store = useSelector((state) => state.Exception);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [list, setList] = useState([]);

  console.log(store.exceptionDetail,"exceptionDetails")

  const columns = ["sku_name", "zone_name"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (store.exceptionList !== undefined && store.exceptionList !== null) {
      setList(store.exceptionList);
    }
  }, [store.exceptionList]);



  console.log(list,"stateList");

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack>
        <TableContainer
          sx={{ minHeight: 510, maxHeight: 510 }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23" }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCell align="center" size="small">
                  Sku Image
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Zone
                </StyledTableCell>
                
                <StyledTableCell align="center" size="small">
                  Sku Name
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Category
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  PTR
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  PTW
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Lot Size Pickup
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Lot Size Delivery
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  status
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {list !== undefined && list !== null && list.length !== 0 ? (
                <>
                  {SearchFilter(list, columns, text)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <Row item={item} />
                    ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    *No Exceptions*
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </>
  );
};

export default SkuByZoneTable;
