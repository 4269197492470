import React, { useState, useEffect } from 'react'
import Styles from './styles/carousel.module.css';
import BrokenImg from '../../../../assets/ImageNotValid.png'
import { ValidImage } from '../../../../actions/validImage';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import images from '../../../../utility/constants/images'
import { useSelector, useDispatch } from 'react-redux';
import { InsertEmoticon } from '@mui/icons-material';
import { GetCompanyDetailsId } from '../../../../actions/company/companyAction';
import { baseImgUploadUrl, baseImgPath, defaultImg } from '../../../../utility/BaseUrl';
import { SearchFilter } from '../../../../utility/functions/functions';
import { Card, Paper, Stack, TextField, Typography } from '@mui/material';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import { AutocompleteTextField } from '../../../../utility/TextField';
import { CircularProgress } from '@mui/material';
const viewImgPath = `${baseImgPath}/company/`;


const CarouselCompany = () => {
    const Axios = useAxiosPrivate()
    const scrollRef = React.useRef(null);
    const store = useSelector(state => state.Company);
    const [list, setList] = useState([]);
    const column = ['item_name'];
    const [text, setText] = useState("")
    const [id, setId] = useState('');
    const dispatch = useDispatch();
    const StyleSheet = document.querySelector(":root");
    const ComputedStyle = getComputedStyle(StyleSheet);
    const BgColour = ComputedStyle.getPropertyValue("--color-light-background")

    const scroll = (direction) => {
        const { current } = scrollRef;

        if (direction === 'left') {
            current.scrollLeft -= 300;
        } else {
            current.scrollLeft += 300;
        }
    };

    React.useEffect(() => {
        if (store.company_list !== undefined && store.company_list !== null && store.company_list.length !== 0) {
            setList(store.company_list);
        }

        if (store.company_detail !== undefined && store.company_detail !== null) {

            setId(store.company_detail.item_id !== undefined && store.company_detail.item_id !== null && store.company_detail.item_id !== 0 ? (parseInt(store.company_detail.item_id)) : (""))
        }
    }, [store])


    const handleClick = (id) => {

        let param = {
            "type": "company",
            "item_id": `${id}`
        }

        dispatch(GetCompanyDetailsId(Axios, param))
    }




    return (
        <div className={Styles.container}>
            <div className={Styles.SearchContainer}>
                <label className={Styles.SearchTitle}>Search</label>
                <AutocompleteTextField size="small" value={text} onChange={(e) => setText(e.target.value)} placeholder="Search company..." sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                {/* <input value={text} onChange={(e) => setText(e.target.value)} placeholder="Search company..." className={Styles.SearchInput} /> */}
            </div>
            <div className={Styles.CarouselContainer}>

                {
                    list.length !== 0 ? (
                        <div className={Styles.ImageContainer} ref={scrollRef}>
                            {SearchFilter(list, column, text).map((image, index) => (
                                <div className={Styles.ColumnContainer} onClick={() => handleClick(image.item_id)}>
                                    <Card component={Paper} sx={{ backgroundColor: "#4a4f68" }} className={id === parseInt(image.item_id) ? Styles.ImagesCardSelected : Styles.ImagesCard} key={`gallery_image-${index + 1}`}>

                                        <Img img={image.item_image} />

                                    </Card>

                                    <label className={Styles.ImageTitle}>{image.item_name}</label>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className={Styles.NothingContainer}>

                            <label className={Styles.Nothing}>**No Companies**</label>
                        </div>
                    )}
                <div className={Styles.ArrowContainer}>
                    <BsArrowLeftShort className={Styles.ArrowIcon} onClick={() => scroll('left')} />
                    <BsArrowRightShort className={Styles.ArrowIcon} onClick={() => scroll('right')} />
                </div>
            </div>

        </div>
    )
}

export default CarouselCompany



const Img = ({ img }) => {
    const [loading, setLoading] = useState(false)
    const [obj, setObj] = useState('')
    const CheckImage = async () => {
        const Result = await ValidImage(img)

        if (Result) {
            setObj(img)
            setLoading(false)
        } else {
            setLoading(false)
            setObj(BrokenImg)
        }

    }
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            CheckImage(img)
        }, [1000])

    }, [img])

    return (

        loading ?
            <Stack direction="column" justifyContent={"center"} alignItems={"center"} minHeight={"100px"}>
                <CircularProgress />
                <Typography>loading...</Typography>
            </Stack> :
            <img src={obj !== undefined && obj !== null && obj.length !== 0 ? (`${obj}`) : (defaultImg)} alt="gallery_image" loading='lazy' />


    )

}