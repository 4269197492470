export const GETALLSTATELISTUSERACCESSCONST = "GETALLSTATELISTUSERACCESSCONST";
export const GETALLZONELISTUSERACCESSCONST = "GETALLZONELISTUSERACCESSCONST";

export const GETALLUSERLISTUSERACCESSCONST = "GETALLUSERLISTUSERACCESSCONST";
export const UPDATEALLUSERLISTUSERACCESSCONST =
  "UPDATEALLUSERLISTUSERACCESSCONST";

export const GETALLUSERLISTUSERACCESSCONSTSEARCH =
  "GETALLUSERLISTUSERACCESSCONSTSEARCH";

export const GETALLSUPERCATEGORYLIST = "GETALLSUPERCATEGORYLIST";
export const GETSEARCHUSERLISTUSERACCESSCONST =
  "GETSEARCHUSERLISTUSERACCESSCONST";
export const GETALLZONELISTSEARCHUSERACCESSCONST =
  "GETALLZONELISTSEARCHUSERACCESSCONST";

export const GETROLEDATAACCESSMGT = "GETROLEDATAACCESSMGT";

//////////////Add
export const GETALLSTATELISTUSERACCESSCONSTADD =
  "GETALLSTATELISTUSERACCESSCONSTADD";
export const GETALLZONELISTUSERACCESSCONSTADD =
  "GETALLZONELISTUSERACCESSCONSTADD";
  export const GETALLDISTRICTLISTUSERACCESSCONSTADD =
  "GETALLDISTRICTLISTUSERACCESSCONSTADD";
export const GETALLZONELISTUSERACCESSCONSTFILTER =
  "GETALLZONELISTUSERACCESSCONSTFILTER";
  export const GETALLDISTRICTLISTUSERACCESSCONSTFILTER =
  "GETALLDISTRICTLISTUSERACCESSCONSTFILTER";
export const GETALLSUPERCATEGORYLISTADD = "GETALLSUPERCATEGORYLISTADD";
export const GETROLEDATAACCESSMGTADD = "GETROLEDATAACCESSMGTADD";
export const GETUSERLISTACCESSMGT = "GETUSERLISTACCESSMGT";
export const GETUSERLISTACCESSMGTUPDATE = "GETUSERLISTACCESSMGTUPDATE";

export const GETUPDATESETIONISOPENORNOT = "GETUPDATESETIONISOPENORNOT";
