// export const GETUSERMAPDATA = "GETUSERMAPDATA";
// export const GETDBLISTDATA = "GETDBLISTDATA";

export const GETMAPALLORDERLIST = "GETMAPALLORDERLIST";

export const GETMAPUSERDATAWITHLATANDLONGLIST =
  "GETMAPUSERDATAWITHLATANDLONGLIST";

export const GETMAPPARTNERLOCATIONLIST = "GETMAPPARTNERLOCATIONLIST";

export const GETMAPASSIGNPARTNERDATA = "GETMAPASSIGNPARTNERDATA";

export const GETMAPMYSELFDATALIST = "GETMAPMYSELFDATALIST";

export const GETLOCATIONICONCOLORWITHDBNAME = "GETLOCATIONICONCOLORWITHDBNAME";

export const GETMAPFILTERID = "GETMAPFILTERID";

export const GETMAPFILTERINDEX = "GETMAPFILTERINDEX";

export const GETASSIGNDELIVERYBOYALLCHECKDATA =
  "GETASSIGNDELIVERYBOYALLCHECKDATA";

// assign-delivery-boy-all-check
