import {
  GETALLSKULIST,
  GETSKUDETAILSNEW,
  NEWSKUCATEGORYLIST,
  RESETNEWSUDETAIL,
  NEWSKUCOMPANYLIST,
  SUPERCATEGORYLISTSKUMGT,
  UPDATESKUDETAILS,
  NEWSUBCATEGORYLIST,
  REFVALUELIST,
  GETSKUNAMELISTONLY,
  GETRETAINVALUEFROMLOCAL,
} from "../../utility/ReduxConstants/NewSkuReducerConstant";

const initialState = {
  categoryList: [],
  companyList: [],
  skuList: [],
  skuDetail: {},
  superCategoryList: [],
  subCategoryList: [],
  refValueList: null,
  skuNameList: [],
 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEWSKUCATEGORYLIST:
      return {
        ...state,
        categoryList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case NEWSKUCOMPANYLIST:
      return {
        ...state,
        companyList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case NEWSUBCATEGORYLIST:
      return {
        ...state,
        subCategoryList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETALLSKULIST:
      return {
        ...state,
        skuList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSKUDETAILSNEW:
      return {
        ...state,
        skuDetail:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload[0]
            : {},
      };

    case UPDATESKUDETAILS:
      return {
        ...state,
        skuDetail:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : {},
        skuList: state.skuList.map((item) =>
          item.sku_id === action.payload.sku_id ? action.payload : item
        ),
      };
    case SUPERCATEGORYLISTSKUMGT:
      return {
        ...state,
        superCategoryList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
      };

    case RESETNEWSUDETAIL:
      return {
        ...state,
        skuDetail: {},
      };

    case REFVALUELIST:
      return {
        ...state,
        refValueList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
      };
    case GETSKUNAMELISTONLY:
      return {
        ...state,
        skuNameList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
      };

     

   

    default:
      return state;
  }
};
