import { Authaxios } from "../../axios/axios";
import {
  GETALLSKULIST,
  GETSKUDETAILSNEW,
  NEWSKUCATEGORYLIST,
  NEWSKUCOMPANYLIST,
  NEWSUBCATEGORYLIST,
} from "../../utility/ReduxConstants/NewSkuReducerConstant";
import { GETEXCEPTIONACCESSDISTRICTLIST, GETEXCEPTIONACCESSSCLIST, GETEXCEPTIONSUPERCATEGORYLIST } from "../../utility/ReduxConstants/SkuExceptionReducerConstant";

export const GetAllCategoryNewSku = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("skuCategoryList", param);
    if (data.code === 200) {
      dispatch({ type: NEWSKUCATEGORYLIST, payload: data.list });

      return data;
    }
  } catch (error) {
    return error?.response?.data;
    console.log(error);
  }
};
export const GetAllSubCategoryNewSku = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("subCategoryList", param);
    if (data.code === 200) {
      dispatch({ type: NEWSUBCATEGORYLIST, payload: data.list });

      return data;
    }
  } catch (error) {
    return error?.response?.data;
    console.log(error);
  }
};

export const GetAllSkuList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllSkuList");
    console.log(data, "dfarf");
    if (data.code === 200) {
      dispatch({ type: GETALLSKULIST, payload: data.list });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCompanyNewSku = (Axios) => async (dispatch) => {
  let param = {
    user_id: 10,
    type: "company",
  };
  try {
    const { data } = await Axios.post("ItemdataByUser", param);

    if (data.code === 200) {
      dispatch({ type: NEWSKUCOMPANYLIST, payload: data.item_carousel });
    }
  } catch (error) {
    console.log(error);
  }
};

export const AddNewSku = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("addSkuNew", param);

    if (data.code === 200) {
      dispatch(GetAllSkuList());
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetCategoryRepoImages = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("GetAllImages", param);

    if (data.code === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const ChangeSkuStatus = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("changeStatusSku", param);

    if (data.code === 200) {
      let paramTwo = {
        sku_id: param.sku_id,
      };
      dispatch(GetSkuDetailsById(paramTwo));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GetSkuDetailsById = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("getSkuDetailsById", param);

    if (data.code === 200) {
      dispatch({ type: GETSKUDETAILSNEW, payload: data.list });
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateNewSku = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("updateSkuNew", param);

    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
export const UpdateStatusOfSettings = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("updateSkuSettingsForAllZones", param);

    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetSuperCategoryList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("superCategoryList", param);
    console.log(data, "superCategoryList");

    if (data.code === 200) {
      dispatch({
        type: GETEXCEPTIONSUPERCATEGORYLIST,
        payload: data.body,
      });
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetAccessSuperCategoryList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AccesssuperCategoryList", param);
    console.log(data, "superCategoryList");

    if (data.code === 200) {
      dispatch({
        type: GETEXCEPTIONACCESSSCLIST,
        payload: data.body,
      });
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetRepoImagesByCategoryId = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("getImagesForSKUByCategory", param);
    if (data.code === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
