
import { styled } from '@mui/material/styles';
import {Button} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import LoadingButton from '@mui/lab/LoadingButton';



export const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    padding:"0px 20px",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));

export const RedBorderButton = styled(Button)(({ theme }) => ({
    color: "#DC1D13",
    backgroundColor: "#323546",
    border: "2px solid #DC1D13",
    padding:"0px 20px",
    '&:hover': {
        backgroundColor: "#DC1D13",
        color: "white"
    },
}));
export const SecondaryButton = styled(Button)(({ theme }) => ({
    color: "#D9D9D9",
    backgroundColor: "transparent",
    border: "2px solid #D9D9D9",
    borderRadius:"18px",
    padding:"0px 20px",
    '&:hover': {
        backgroundColor: "#D9D9D9",
        color: "black !important"
    },
}));

export const LoginButton = styled(Button)(({ theme }) => ({
    textTransform:"none",
    fontSize:"16px",
    color: "white",
    padding:"10px 50px",
    backgroundColor: "#73ADA9",
    border: "2px solid #73ADA9",
    '&:hover': {
        backgroundColor: "#73ADA9",
        color: "white"
    },
}));


export const BackButton = styled(Button)(({ theme }) => ({
    fontSize:"16px",
    color: "#000",
    // padding:"10px 50px",
    backgroundColor: "white",
    border: "2px solid white",
    '&:hover': {
        backgroundColor: "white",
        color: "#000"
    },
}));

// export const LoaderButton = styled(LoadingButton)(({ theme }) => ({
//     textTransform:"none",
//     fontSize:"16px",
//     color: "white",
//     padding:"10px 50px",
//     backgroundColor: "#73ADA9",
//     border: "2px solid #73ADA9",
//     '&:hover': {
//         backgroundColor: "#73ADA9",
//         color: "white"
//     },
// }));

export const CurvedRedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
    borderRadius: "18px",
    padding: "7px 50px"
}));
{/* <LoadingButton
  loading
  loadingPosition="start"
  startIcon={<SaveIcon />}
  variant="outlined"
>
  Save
</LoadingButton> */}
export const LoadingButtonImageBank = styled(LoadingButton)(({ theme }) => ({

}));