import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteImageBankImage,
  GetCategoryListImageBank,
  GetImageBank,
  GetSuperCategoryListImageBank,
  MoveProductionImage,
} from "../../../../actions/ImageBank/ImageBankAction";
import {
  baseImgPath,
  baseImgUploadUrl,
  defaultImg,
} from "../../../../utility/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeletePopUp from "./DeletePopUp";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { ROLES } from "../../../../utility/UserAllowedRoles";
import { IMAGEBANKRESET } from "../../../../utility/ReduxConstants/ImageBankReducerConstant";
import { ImagePopUpModal } from "../../../../utility/Backdrop.js";
import {
  AutocompleteTextField,
  AutocompleteTextFieldCurved,
} from "../../../../utility/TextField.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { LoadingButtonImageBank } from "../../../../utility/Button";
import BrokenImg from "../../../../assets/ImageNotValid.png";
import { ValidImage } from "../../../../actions/validImage";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  padding: "5px 20px",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const ImageBank = () => {
  const [categoryLoader, setCategoryLoader] = useState(false);

  const LoginStore = useSelector((state) => state.Login);
  const [ImageName, setImageName] = useState("");
  const [preview, setPreview] = useState();
  const [upload, setUpload] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [Error, setError] = useState({});

  const Axios = useAxiosPrivate();
  const Login = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const Sheet = document.querySelector(":root");
  const StyledRef = getComputedStyle(Sheet);
  const BGColor = StyledRef.getPropertyValue("--color-light-background");
  const Arr = [1, 2, 3, 4, 5, 6, 6];
  const store = useSelector((state) => state.ImageBank);
  const [file, setFile] = useState({});
  const [remove, setRemove] = useState(false);
  const [superCategory, setSuperCategory] = useState({
    name: "",
    id: 0,
    List: [],
  });
  const [category, setCategory] = useState({
    name: "",
    id: 0,
    List: [],
  });
  const [imageList, setImageList] = useState({
    name: "",
    List: [],
  });
  const [removeItem, setRemoveItem] = useState({});

  useEffect(() => {
    dispatch(GetSuperCategoryListImageBank(Axios));
  }, []);

  useEffect(() => {
    if (store.superCategoryList.length !== 0) {
      let Arr = store.superCategoryList?.map((item) => item.name);

      setSuperCategory({
        ...superCategory,
        id: store?.superCategoryList[0]?.sc_id,
        List: Arr,
        name: Arr[0] ?? "",
      });
      if (store.superCategoryList.length !== 0) {
        let param = {
          sc_id: store.superCategoryList[0].sc_id,
        };
        setCategoryLoader(true);
        dispatch(GetCategoryListImageBank(Axios, param));
      }
    }
  }, [store.superCategoryList]);

  useEffect(() => {
    dispatch({ type: IMAGEBANKRESET });
    let Arr = store.categoryList?.map((item) => item.category_name);
    setCategoryLoader(false);
    setCategory({
      ...category,
      List: Arr,
      name: Arr[0] ?? "",
      id: store?.categoryList[0]?.category_id,
    });
    if (store?.categoryList?.length !== 0) {
      let param = {
        sc_id: superCategory.id,
        category_id: store?.categoryList[0]?.category_id,
      };
      dispatch(GetImageBank(Axios, param));
    }
  }, [store.categoryList]);

  useEffect(() => {
    let Arr = store?.imageList?.filter((item, index) => item);
    //console.log(Arr,"ArrArrArr")
    setImageList({
      List: Arr,
    });
  }, [store.imageList]);

  const imgSelect = (event) => {
    setFile(event.target.files[0]);
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview(objectUrl);
  };

  console.log(store?.imageList);
  const handleSuperCategory = (e, value) => {
    let sc_id = 0;
    for (let i = 0; i < store.superCategoryList.length; i++) {
      if (value === store.superCategoryList[i].name) {
        sc_id = store.superCategoryList[i].sc_id;
        setSuperCategory({
          ...superCategory,
          name: value,
          id: store.superCategoryList[i].sc_id,
        });
      }
    }
    let param = {
      sc_id: sc_id,
    };
    setCategoryLoader(true);
    dispatch(GetCategoryListImageBank(Axios, param));
    setImageList({
      ...imageList,
      List: [],
    });
  };

  const handleCategory = (e, value) => {
    let category_id = 0;
    for (let i = 0; i < store.categoryList.length; i++) {
      if (value === store.categoryList[i].category_name) {
        category_id = store.categoryList[i].category_id;
      }
    }
    setCategory({
      ...category,
      name: value,
      id: category_id,
    });
    let param = {
      sc_id: superCategory.id,
      category_id,
    };
    dispatch(GetImageBank(Axios, param));
  };

  const imgUpload = async (formData) => {
    await Axios.post(`${baseImgUploadUrl}uploadInImageBank`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Login?.token}`,
      },
    })
      .then((res) => {
        console.log(res, "uploadInImageBank");

        if (res.data.code === 200) {
          setFile("");
          setPreview("");
          setUpload(false);
          setUploadLoader(false);
          toast.success("Uploaded Image Successfully");
          let param = {
            sc_id: superCategory.id,
            category_id: category.id,
          };
          dispatch(GetImageBank(Axios, param));
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) => {
        //console.log(err.message)
      });
  };

  const handleProduction = async (item) => {
    let param = {
      si_id: item.si_id,
    };
    const data = await dispatch(MoveProductionImage(Axios, param));
    if (data.code === 200) {
      let param = {
        sc_id: superCategory.id,
        category_id: category.id,
      };
      dispatch(GetImageBank(Axios, param));
      toast.success("Changed status successfully");
    } else {
      toast.error("changing status  failed");
    }
  };

  const handleDelete = async (item) => {
    setRemove(true);
    setRemoveItem(item);
  };

  const handleRemove = async () => {
    let param = {
      si_id: removeItem.si_id,
    };

    const data = await dispatch(DeleteImageBankImage(Axios, param));
    if (data.code === 200) {
      let param = {
        sc_id: superCategory.id,
        category_id: category.id,
      };
      dispatch(GetImageBank(Axios, param));
      toast.success("deleted Image successfully");
      setRemove(false);
    } else {
      toast.error("deleting Image failed");
    }
  };

  let Allowed_Prod = ROLES?.IMAGE_BANK_PROD?.includes(
    parseInt(Login?.user?.admin_role_code)
  );

  const handleDisagree = () => {
    setFile("");
    setPreview("");
    setImageName("");
    setUpload(false);
  };

  const IsValid = () => {
    let error = {};
    let success = true;

    if (!ImageName.trim()) {
      success = false;
      error.image = "ImageName Cannot be empty";
    }

    if (
      !file ||
      !preview ||
      ["", undefined, null].includes(file) ||
      ["", undefined, null].includes(preview)
    ) {
      success = false;
      error.file = "**Please select a image**";
    }

    let category_id = 0;
    for (let i = 0; i < store.categoryList.length; i++) {
      if (category.name === store.categoryList[i].category_name) {
        category_id = store.categoryList[i].category_id;
      }
    }

    if (category_id === 0) {
      toast.warning(
        "Please select a category! To upload image OR No category present under the super category!!"
      );
      success = false;
    }

    setError(error);
    return success;
  };

  const handleAgree = (e) => {
    setUploadLoader(true);
    e.preventDefault();
    if (IsValid()) {
      let sc_id = 0;
      for (let i = 0; i < store.superCategoryList.length; i++) {
        if (superCategory.name === store.superCategoryList[i].name) {
          sc_id = store.superCategoryList[i].sc_id;
        }
      }
      let category_id = 0;
      for (let i = 0; i < store.categoryList.length; i++) {
        if (category.name === store.categoryList[i].category_name) {
          category_id = store.categoryList[i].category_id;
        }
      }

      //console.log(category_id, sc_id, "text")
      const formData = new FormData();
      formData.append("file", file);
      formData.append("category_id", category_id);
      formData.append("sc_id", sc_id);
      formData.append("image_name", ImageName);
      imgUpload(formData);
    }
  };

  const handleRemoveFile = () => {
    setFile("");
    setPreview("");
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Stack>
        <input
          type="file"
          id="imagebankupload"
          style={{ display: "none" }}
          onChange={imgSelect}
        />
        <Stack
          sx={{ backgroundColor: BGColor, borderRadius: "5px" }}
          padding={2}
          gap={2}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack direction={{ xs: "column", lg: "row" }} gap={3} padding={3}>
              <Autocomplete
                onChange={handleSuperCategory}
                value={superCategory.name}
                options={superCategory.List}
                getOptionLabel={(option) => option}
                size="small"
                s
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                      backgroundColor: "#25293C !important",
                      minWidth: "300px",
                    }}
                    name="super category"
                    variant="outlined"
                    label="Super Category"
                    placeholder="super category.."
                  />
                )}
              />
              <Autocomplete
                loading={categoryLoader}
                onChange={handleCategory}
                value={category.name}
                options={category.List}
                getOptionLabel={(option) => option}
                size="small"
                renderInput={(params) => (
                  <AutocompleteTextFieldCurved
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                      backgroundColor: "#25293C !important",
                      minWidth: "300px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {categoryLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    name="category"
                    variant="outlined"
                    label="Category"
                    placeholder="category.."
                  />
                )}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "#DC1D13",
                padding: "10px 40px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              gap={1.3}
              onClick={() => {
                setUpload(true);
              }}
            >
              <Typography variant="body2" sx={{ color: "white" }}>
                UPLOAD IMAGES
              </Typography>
            </Stack>
          </Stack>
          <Grid container sx={{ gap: 1 }} alignItems="center">
            {imageList?.List?.length !== 0
              ? imageList?.List?.map((item) => (
                  <Grid item sx={{ bakcgroundColor: "red" }}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack direction="column">
                        <Card
                          component={Paper}
                          sx={{
                            backgroundColor: "#4a4f68",
                            width: "200px",
                            height: "200px",
                            border:
                              item?.status === "prod"
                                ? "2px solid green"
                                : null,
                          }}
                        >
                          <Img img={item.image_path} />
                        </Card>
                        <Stack direction="column" justifyContent={"flex-start"}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            flex={1}
                          >
                            <Typography
                              sx={{ color: "white", maxWidth: "100px" }}
                              variant="caption"
                            >
                              {item?.image_name ?? "not der"}
                            </Typography>

                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {item?.status === "prod" ? (
                                <svg
                                  style={{
                                    color: "white",
                                    borderRadius: "50%",
                                    backgroundColor: "green",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "5px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                  />
                                </svg>
                              ) : null}
                              <a
                                href={item.image_path}
                                target="_blank"
                                download="imag"
                                style={{ cursor: "pointer" }}
                              >
                                <ArrowCircleDownOutlinedIcon
                                  sx={{ color: "white", cursor: "pointer" }}
                                />
                              </a>
                              {ROLES.IMAGE_BANK_DELETE_PROD.includes(
                                LoginStore?.user?.admin_role_code
                              ) ? (
                                <DeleteIcon
                                  onClick={() => handleDelete(item)}
                                  sx={{ color: "red", cursor: "pointer" }}
                                />
                              ) : item?.status === "prod" ? null : (
                                <DeleteIcon
                                  onClick={() => handleDelete(item)}
                                  sx={{ color: "red", cursor: "pointer" }}
                                />
                              )}
                            </Stack>
                          </Stack>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            minHeight="40px"
                          >
                            {item?.status === "prod"
                              ? null
                              : Allowed_Prod && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flexStart"
                                    gap={2}
                                    sx={{
                                      backgroundColor: "green",
                                      color: "white",
                                      padding: "5px 20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleProduction(item)}
                                  >
                                    <Typography variant="caption">
                                      Production
                                    </Typography>
                                    <ArrowCircleRightOutlinedIcon />
                                  </Stack>
                                )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                ))
              : null}
          </Grid>

          {remove && (
            <DeletePopUp
              Item={removeItem}
              remove={remove}
              setRemove={setRemove}
              handleRemove={handleRemove}
            />
          )}
          <ImagePopUpModal
            open={upload}
            handleClose={() => setUpload(false)}
            Error={Error}
          >
            <Dialog
              fullScreen={fullScreen}
              open={upload}
              onClose={() => setUpload(false)}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: {
                  backgroundColor: BGColor,
                  color: "white",
                  minWidth: "600px",
                  minHeight: "400px",
                },
              }}
              sx={{}}
            >
              <form onSubmit={handleAgree}>
                <DialogTitle id="responsive-dialog-title">
                  Please fill the form and submit 😉
                </DialogTitle>
                <DialogContent>
                  <Stack gap={2} padding={2}>
                    <Stack
                      sx={{ minHeight: "200px" }}
                      direction="row"
                      justifyContent="center"
                    >
                      {!file ||
                      !preview ||
                      ["", undefined, null].includes(file) ||
                      ["", undefined, null].includes(preview) ? null : (
                        <Stack direction="column">
                          <Stack position="absolute">
                            <IconButton onClick={handleRemoveFile}>
                              <CancelIcon />
                            </IconButton>
                          </Stack>
                          <Card component={Paper}>
                            <img
                              src={preview}
                              loading="lazy"
                              style={{
                                objectFit: "contain",
                                width: "200px",
                                height: "200px",
                                backgroundColor: "white",
                              }}
                            />
                          </Card>
                        </Stack>
                      )}
                      {uploadLoader && (
                        <Stack
                          direction="row"
                          position="absolute"
                          height="200px"
                          alignItems="center"
                        >
                          <CircularProgress />
                          <label>Uploading...</label>
                        </Stack>
                      )}
                    </Stack>
                    {!file ||
                    !preview ||
                    ["", undefined, null].includes(file) ||
                    ["", undefined, null].includes(preview) ? (
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <label
                          htmlFor="imagebankupload"
                          style={{
                            backgroundColor: "#009DFF",
                            padding: "4px 12px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Select a image
                        </label>
                        {Error?.file && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            ***{Error?.file}**
                          </Typography>
                        )}
                      </Stack>
                    ) : null}

                    <Stack
                      direction="column"
                      justifyContent="center"
                      minWidth={5}
                    >
                      <AutocompleteTextField
                        label="Image Name"
                        size="small"
                        autoComplete="off"
                        value={ImageName}
                        onChange={(e) => setImageName(e.target.value)}
                      />
                      {Error?.image && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          ***{Error?.image}**
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDisagree}>Cancel</Button>

                  <LoadingButtonImageBank
                    type="submit"
                    loading={uploadLoader}
                    loadingPosition="start"
                  >
                    Submit
                  </LoadingButtonImageBank>
                </DialogActions>
              </form>
            </Dialog>
          </ImagePopUpModal>
        </Stack>
      </Stack>
    </>
  );
};

export default ImageBank;

const Img = ({ img }) => {
  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState("");
  const CheckImage = async () => {
    const Result = await ValidImage(img);

    if (Result) {
      setObj(img);
      setLoading(true);
    } else {
      setLoading(false);
      setObj(BrokenImg);
    }
  };
  useEffect(() => {
    setLoading(true);
    CheckImage(img);
  }, [img]);

  console.log(loading);

  return (
    <>
      {loading ? (
        <Skeleton
          sx={{ bgcolor: "#b3b3b3" }}
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"200px"}
        />
      ) : null}
      <img
        src={obj}
        alt="company"
        loading="lazy"
        onLoad={() => {
          setLoading(false);
        }}
        style={{
          display: loading ? "none" : "block",
          objectFit: "contain",
          width: "100%",
          height: "100%",
        }}
      />
    </>
  );
};
