import React, { useState, useEffect } from "react";
import KycTable from "./KycTable";
import KycVerification from "./KycVerification";
import "./styles/index.scss";
import styles from "./styles/index.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  Tabs,
  Tab,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetKycCountByStateAndZone,
  GetKycListByStateAndZone,
  GetKycStateList,
  GetKycZoneList,
  GetKycSCList,
  GetKycCategoryList,
  GetKycDistrictList,
} from "../../../actions/kyc/kycAction";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapperSmall } from "../CardWrapper";
import {
  AutocompleteTextField,
  AutocompleteTextFieldCurved,
  SearchInputField,
} from "../../../utility/TextField";
import SearchBarComponent from "../../SearchBarComponent";
import { CheckSmallError } from "../../../utility/ErrorCheck";
const Kyc = () => {
  console.log("KYC");
  const [text, setText] = useState("");
  const [zoneLoader, setZoneLoader] = useState(false);
  const [districtLoader, setDistrictLoader] = useState(false);
  const Axios = useAxiosPrivate();
  const [tab, setTab] = useState("kyc");
  const Q = document.querySelector(":root");
  const StyleSheet = getComputedStyle(Q);
  const BgColor = StyleSheet.getPropertyValue("--color-light-background");
  let navigate = useNavigate();
  const store = useSelector((state) => state.Kyc);
  console.log(JSON.stringify(store) + "store");

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [obj, setObj] = useState({
    stateValue: "",
    stateList: [],
    zoneValue: "",
    zoneList: [],
    districtList: [],
    districtValue: "",
  });

  const [scId, setscId] = useState(0);
  const [scValue, setscValue] = useState("All");
  const [scList, setscList] = useState([]);

  const [type, setType] = useState({
    count: 0,
    filterName: "total",
    name: "Total",
  });
  const [listType, setListType] = useState([
    { count: 0, filterName: "total", name: "Total" },
    { count: 0, filterName: "not_applied", name: "Not Applied" },
    { count: 0, filterName: "pending", name: "Pending" },
    { count: 0, filterName: "kyc_verified", name: "Kyc Verified" },
    { count: 0, filterName: "vitaran_verified", name: "Vitaran Verified" },
  ]);

  useEffect(() => {
    dispatch(GetKycStateList(Axios));
    dispatch(GetKycZoneList(Axios, { state_id: 0 }));
    dispatch(GetKycSCList(Axios));
  }, []);

  const handleShow = () => {
    setShow(!show);
    if (!show) {
      setTab("details");
    } else {
      setTab("kyc");
    }
  };

  console.log(obj, "typeStatus");

  useEffect(() => {
    if (
      store?.stateList !== undefined &&
      store?.stateList !== null &&
      store?.stateList.length !== 0
    ) {
      let StateArr = [];
      setState("All");
      StateArr.push({
        state_id: 0,
        state_name: "All",
      });

      for (let i = 0; i < store?.stateList?.length; i++) {
        StateArr.push(store?.stateList[i]);
      }

      setObj((prev) => {
        return {
          ...prev,
          stateList: StateArr,
          stateValue: StateArr[0]?.state_name,
        };
      });

      // let param = {
      //   state_id: store?.stateList[0]?.state_id,
      // };
      let param = {
        state_id: 0,
      };
      setDistrictLoader(true);
      dispatch(GetKycDistrictList(Axios, param));
      // setDistrictLoader(false);

      let Arr = ["All"];
      for (let i = 0; i < store?.stateList?.length; i++) {
        Arr.push(store?.stateList[i]?.state_name);
      }

      setStateList(Arr);
    }

    return () => {
      console.log("unmounting......stateList");
    };
  }, [store?.stateList]);

  console.log(obj, "districtLoader");

  const handleDistrict = (e, value) => {
    setDistrict("value");

    setObj({
      ...obj,
      districtValue: value === "All" ? "All" : value,
    });
    console.log(obj, "handleState");
    let district_id = 0;
    for (let i = 0; i < obj.districtList.length; i++) {
      if (value === obj.districtList[i].district_name) {
        district_id = obj.districtList[i].district_id;
      }
    }

    console.log(district_id, "state_id");

    let param = {
      district_id: district_id,
    };
    setZoneLoader(true);

    dispatch(GetKycZoneList(Axios, param));

    if (store?.zoneList?.length !== 0 || store?.zoneList === undefined) {
      setZoneLoader(false);
    }
  };

  useEffect(() => {
    if (
      store?.zoneList !== undefined &&
      store?.zoneList !== null &&
      store?.zoneList.length !== 0
    ) {
      let ZoneArr = [];
      setZoneLoader(false);
      ZoneArr.push({
        zone_id: 0,
        zone_name: "All",
      });

      for (let i = 0; i < store?.zoneList?.length; i++) {
        ZoneArr.push(store?.zoneList[i]);
      }

      setObj((prev) => {
        return { ...prev, zoneList: ZoneArr, zoneValue: ZoneArr[0]?.zone_name };
      });
      // setZoneLoader(true);
      console.log(obj, "ZonneListValue");

      let state_id = 0;
      for (let i = 0; i < obj.stateList.length; i++) {
        if (obj.stateValue === obj.stateList[i].state_name) {
          state_id = obj.stateList[i].state_id;
        }
      }

      let Param = {
        zone_name: "All",
        state_id: state_id,
        sc_id: scId,
      };

      (async () => {
        await dispatch(GetKycCountByStateAndZone(Axios, Param));
      })();

      let paramTwo = {
        zone_name: "All",
        kyc_status: type.filterName,
        state_id: state_id,
        sc_id: scId,
      };

      dispatch(GetKycListByStateAndZone(Axios, paramTwo));

      let Arr = [];
      Arr.push("All");
      for (let i = 0; i < store?.zoneList?.length; i++) {
        Arr.push(store?.zoneList[i]?.zone_name);
      }

      setZoneList(Arr);
      console.log(zoneList, "zoneListklfd");
    }
    return () => {
      console.log("unmounting......zoneList");
    };
  }, [store?.zoneList]);

  console.log(zoneLoader, "districtLoader");

  useEffect(() => {
    if (
      store?.districtList !== undefined &&
      store?.districtList !== null &&
      store?.districtList.length !== 0
    ) {
      let DistrictArr = [];
      setDistrictLoader(false);
      DistrictArr.push({
        district_id: 0,
        district_name: "All",
      });

      for (let i = 0; i < store?.districtList?.length; i++) {
        DistrictArr.push(store?.districtList[i]);
      }

      setObj((prev) => {
        return {
          ...prev,
          districtList: DistrictArr,
          districtValue: DistrictArr[0]?.district_name,
        };
      });

      let param = {
        district_id: store?.districtList[0]?.district_id,
      };
      setZoneLoader(true);
      dispatch(GetKycZoneList(Axios, param));
      // setZoneLoader(false);
      console.log(obj, "ZonneListValue");

      let state_id = 0;
      for (let i = 0; i < obj.stateList.length; i++) {
        if (obj.stateValue === obj.stateList[i].state_name) {
          state_id = obj.stateList[i].state_id;
        }
      }

      let Param = {
        zone_name: "All",
        state_id: state_id,
        sc_id: scId,
      };

      (async () => {
        await dispatch(GetKycCountByStateAndZone(Axios, Param));
      })();

      let paramTwo = {
        zone_name: "All",
        kyc_status: type.filterName,
        state_id: state_id,
        sc_id: scId,
      };

      dispatch(GetKycListByStateAndZone(Axios, paramTwo));

      let Arr = [];
      Arr.push("All");
      for (let i = 0; i < store?.districtList?.length; i++) {
        Arr.push(store?.districtList[i]?.district_name);
      }

      setDistrictList(Arr);
      console.log(zoneList, "zoneListklfd");
    }
    return () => {
      console.log("unmounting......zoneList");
    };
  }, [store?.districtList]);

  useEffect(() => {
    if (
      store.scList != undefined &&
      store.scList !== null &&
      store.scList.length !== 0
    ) {
      let arr = ["All"];

      for (let i = 0; i < store.scList.length; i++) {
        arr.push(store.scList[i].name);
      }

      setscList(arr);
      console.log(scList, "zoneListklfd");
      setscId(0);
      // setscValue(arr[0]);
    }
  }, [store.scList]);

  console.log(JSON.stringify(listType) + "storeofcount");

  useEffect(() => {
    let vitaran_verified_count = store?.vitaran_verified_count
      ? store?.vitaran_verified_count
      : 0;
    let all_user_count = store?.total_count ? store?.total_count : 0;
    let verification_pending_count = store?.pending ? store?.pending : 0;
    let verified_count = store?.kyc_verified_count
      ? store?.kyc_verified_count
      : 0;
    let not_applied = store?.not_applied_count ? store?.not_applied_count : 0;

    setListType([
      { count: all_user_count, filterName: "total", name: "Total" },
      { count: not_applied, filterName: "not_applied", name: "Not Applied" },
      {
        count: verification_pending_count,
        filterName: "pending",
        name: "Pending",
      },
      {
        count: verified_count,
        filterName: "kyc_verified",
        name: "Kyc Verified",
      },
      {
        count: vitaran_verified_count,
        filterName: "vitaran_verified",
        name: "Vitaran Verified",
      },
    ]);
  }, [
    store.all_user_count,
    store.verification_pending_count,
    store.vitaran_verified_count,
    store.verified_count,
  ]);

  const handleState = (e, value) => {
    console.log(value, "handleState");

    setState(value);

    setObj({
      ...obj,
      stateValue: value === "All" ? "All" : value,
    });
    console.log(obj, "handleState");
    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (value === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    console.log(state_id, "state_id");

    let param = {
      state_id: state_id,
    };
    setDistrictLoader(true);

    dispatch(GetKycDistrictList(Axios, param));

    if (
      store?.districtList?.length !== 0 ||
      store?.districtList === undefined
    ) {
      setDistrictLoader(false);
    }

    // if (state_id === 0) {
    //   setObj({
    //     ...obj,
    //     zoneValue: "All",
    //   });
    //   let Param = {
    //     zone_name: "All",
    //     state_id: 0,
    //     sc_id: scId,
    //   };

    //   dispatch(GetKycCountByStateAndZone(Axios, Param));
    // }
    //   let paramTwo = {
    //     zone_name: "All",
    //     kyc_status: type.filterName,
    //     state_id: 0,
    //     sc_id: scId,
    //   };

    //   dispatch(GetKycListByStateAndZone(Axios, paramTwo));
    // }
  };

  const handleZone = (e, value) => {
    console.log(value, "handleState");
    setObj({
      ...obj,
      zoneValue: value,
    });

    console.log(JSON.stringify(obj) + "obj");

    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    let Param = {
      zone_name: value,
      state_id: state_id,
      sc_id: scId,
    };

    dispatch(GetKycCountByStateAndZone(Axios, Param));

    let paramTwo = {
      zone_name: value,
      kyc_status: type.filterName,
      state_id: state_id,
      sc_id: scId,
    };

    dispatch(GetKycListByStateAndZone(Axios, paramTwo));
  };

  const handlesc = (e, value) => {
    console.log(value + "lsjjdslfs");
    if (!CheckSmallError(value) && value !== "") {
      let state_id = 0;
      for (let i = 0; i < obj.stateList.length; i++) {
        if (obj.stateValue === obj.stateList[i].state_name) {
          state_id = obj.stateList[i].state_id;
        }
      }
      if (value.length !== 0 && value !== null && value !== undefined) {
        let id = 0;

        for (let i = 0; i < store.scList.length; i++) {
          if (value === store.scList[i].name) {
            id = store.scList[i].sc_id;
            break;
          }
        }
        let Param = {
          zone_name: obj?.zoneValue,
          state_id: state_id,
          sc_id: id,
        };

        console.log(id, "stateID");

        dispatch(GetKycCountByStateAndZone(Axios, Param));

        let paramTwo = {
          zone_name: obj?.zoneValue,
          kyc_status: type.filterName,
          state_id: state_id,
          sc_id: id,
        };

        dispatch(GetKycListByStateAndZone(Axios, paramTwo));
        setscValue(value);
        setscId(id);
      }
    }
  };

  console.log(listType, "listType");

  const [dataCount, setDataCount] = useState(null);

  // const handleTypeCount = async () => {
  //   let param = {
  //     state_id: 0,
  //     sc_id: 0,
  //     zone_name: "All",
  //   };
  //   const response = await Axios.post(
  //     "http://echnotek.com:4001/vtrnliveweb/kycCountByStatusAndZone",
  //     { param }
  //   );
  //   const data = await response.data;
  //   setDataCount(data)
  //   console.log(JSON.stringify(data.body) + "onsjfosdfn");
  // };
  // useEffect(
  //   () => {
  //     handleTypeCount();
  //   },
  //   []
  // );

  const handleSetType = async () => {
    setListType([
      { count: store.total_count, filterName: "total", name: "Total" },
      {
        count: store.not_applied_count,
        filterName: "not_applied",
        name: "Not Applied",
      },
      {
        count: store.pending,
        filterName: "pending",
        name: "Pending",
      },
      {
        count: store.kyc_verified_count,
        filterName: "kyc_verified",
        name: "Kyc Verified",
      },
      {
        count: store.vitaran_verified_count,
        filterName: "vitaran_verified",
        name: "Vitaran Verified",
      },
    ]);
  };

  const handleType = async (e) => {
    setType(e);

    console.log(JSON.stringify(e) + "jsdkljfjdsf");

    let state_id = 0;
    for (let i = 0; i < obj.stateList.length; i++) {
      if (obj.stateValue === obj.stateList[i].state_name) {
        state_id = obj.stateList[i].state_id;
      }
    }

    let param = {
      zone_name: obj?.zoneValue,
      kyc_status: e.filterName,
      state_id: state_id,
      sc_id: scId,
    };

    await dispatch(GetKycListByStateAndZone(Axios, param));

    // let param1 = {
    //   state_id: 0,
    //   sc_id: 0,
    //   zone_name: "All",
    // };
    // await dispatch(GetKycCountByStateAndZone(Axios, param1));

    await handleSetType();
  };

  console.log(JSON.stringify(listType) + "mlvsajfiea");

  const handleText = (e) => {
    console.log(e);
    setText(e);
  };

  return (
    <div className="RootKyc">
      <Stack>
        <Typography sx={{ color: "white" }}>KYC DASHBOARD</Typography>
        <CardWrapperSmall sx={{ padding: "15px" }}>
          <Stack gap={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ gap: { xs: "0.8rem", sm: "2rem",md:"0.4rem",lg:"2rem",xl:"4rem" },justifyContent:{xs:"",sm:"flex-start"}}}
              
            >
              <Stack sx={{ gap: { xs: "0.8rem", sm: "0.8rem",md:"0.4rem",lg:"3rem" } }}  direction={{ xs: "column",sm: "row" }}>
                <Autocomplete
                  className={styles.AutoComplete}
                  value={state ?? "All"}
                  options={stateList ?? [""]}
                  getOptionLabel={(option) => option}
                  size="small"
                  onChange={handleState}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      sx={{
                        backgroundColor: "#25293C",
                        // width: { xs: "100%", sm: "90%",lg:"80%" },
                        width:{xs:"100%",sm:"150px",md:"160px",lg:"200px",xl:"250px"},
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      variant="outlined"
                      label="State"
                      placeholder="Select State.."
                    />
                  )}
                />
                <Autocomplete
                  loading={districtLoader}
                  className={styles.AutoComplete}
                  value={districtLoader ? "All" : obj?.districtValue}
                  options={districtLoader ? ["All"] : districtList}
                  getOptionLabel={(option) => option}
                  size="small"
                  // disabled={state === 'All' ? true : false}
                  onChange={handleDistrict}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      sx={{
                        backgroundColor: "#25293C",
                        width:{xs:"100%",sm:"150px",md:"160px",lg:"200px",xl:"250px"},
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {zoneLoader ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                      variant="outlined"
                      label="District"
                      placeholder="Select District.."
                    />
                  )}
                />
              </Stack>
              <Stack sx={{ gap: { xs: "0.8rem", sm: "0.8rem",md:"0.4rem",lg:"3rem" } }} direction={{ xs: "column", sm: "row" }}>
                <Autocomplete
                  loading={zoneLoader}
                  className={styles.AutoComplete}
                  value={zoneLoader ? "All" : obj?.zoneValue}
                  options={zoneLoader ? ["All"] : zoneList}
                  getOptionLabel={(option) => option}
                  size="small"
                  // disabled={state === 'All' ? true : false}
                  onChange={handleZone}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      sx={{
                        backgroundColor: "#25293C",
                        width:{xs:"100%",sm:"150px",md:"160px",lg:"200px",xl:"250px"},
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {zoneLoader ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                      variant="outlined"
                      label="Zone"
                      placeholder="Select Zone.."
                    />
                  )}
                />
                <Autocomplete
                  className={styles.AutoComplete}
                  value={scValue ?? "All"}
                  options={scList ?? [""]}
                  getOptionLabel={(option) => option}
                  size="small"
                  // disabled={state === 'All' ? true : false}
                  onChange={handlesc}
                  renderInput={(params) => (
                    <AutocompleteTextFieldCurved
                      {...params}
                      sx={{
                        backgroundColor: "#25293C",
                        width:{xs:"100%",sm:"150px",md:"160px",lg:"200px",xl:"250px"},
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      variant="outlined"
                      label="Super Category"
                      placeholder="Select Super Category.."
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Stack
              sx={{
                backgroundColor: "#25293C",
                height: "100%",
                borderRadius: "5px",
                overflow: "hidden",
                padding: "0px 20px 20px 20px",
              }}
              gap={2}
            >
              <Stack direction="row">
                {listType?.map((item, index) =>
                  item?.name === type?.name ? (
                    <Stack
                      direction="row"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        backgroundColor: "#525980",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        padding={2}
                        sx={{
                          borderBottom: "3px solid red",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                        <Typography variant="body1">
                          {item?.count ?? 0}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleType(item)}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        padding={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          {item?.name ?? ""}
                        </Typography>
                        <Typography variant="body1">
                          {item?.count ?? 0}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )}
              </Stack>
              <Stack
                sx={{
                  border: "1px solid #616A9C",
                  borderRadius: "10px",
                  padding: "10px",
                }}
                gap={1}
              >
                <Stack direction="row" justifyContent="flex-end">
                  <SearchBarComponent
                    Placeholder="Search User..."
                    TextCallback={handleText}
                  />
                </Stack>
                <KycTable show={handleShow} type={type} text={text} />
              </Stack>
            </Stack>
          </Stack>
        </CardWrapperSmall>
      </Stack>
    </div>
  );
};

export default Kyc;
