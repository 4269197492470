import React, { useEffect } from "react";
import {
  GetAllCategoryNewSku,
  GetAllCompanyNewSku,
  GetAllSkuList,
  GetSuperCategoryList,
} from "../../../actions/newSkuAction/newSkuAction";
import NewAddSku from "./NewAddSku";
import NewUpdateSku from "./NewUpdateSku";
import { useDispatch, useSelector } from "react-redux";
import "./Styles/index.scss";
import { SUPERCATEGORYLISTSKUMGT } from "../../../utility/ReduxConstants/NewSkuReducerConstant";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { CardWrapper, CardWrapperSmall } from "../CardWrapper";
import { Stack } from "@mui/material";

const AddUpdateSku = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const styling = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };

  useEffect(() => {
    (async () => {
      dispatch(GetAllSkuList(Axios));
      const data = await dispatch(GetSuperCategoryList(Axios));
      console.log(data, "datasc");
      if (data.code === 200) {
        dispatch({ type: SUPERCATEGORYLISTSKUMGT, payload: data.body });
        if (
          data.body !== undefined &&
          data.body !== null &&
          data.body.length !== 0
        ) {
          let Arr = data.body;
          let param = {
            sc_id: Arr[0].sc_id,
          };
          dispatch(GetAllCategoryNewSku(Axios, param));
        }
      } else {
        console.log("failed to fetch sc list");
      }
      dispatch(GetAllCompanyNewSku(Axios));
      dispatch(GetAllSkuList(Axios));
    })();
  }, []);

  return (
    <>
      <>
        <CardWrapperSmall sx={{ minHeight: "75vh" }}>
          <Stack direction={{ xs: "column", lg: "row" }} gap={2}>
            <Stack flex={1}>
              <NewAddSku />
            </Stack>
            <Stack flex={2.3}>
              <NewUpdateSku />
            </Stack>
          </Stack>
        </CardWrapperSmall>
      </>
    </>
  );
};

export default AddUpdateSku;
