import { Card, CircularProgress, Grid, Paper, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AllStateListZoneMgt,
  ChangeStatusState,
} from "../../../../actions/zone/zoneAction";
import { IOSSwitch } from "../../../../utility/IosSwitch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import { GetColor } from "../../../../utility/functions/functions";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const Arr = [
  {
    state_name: "TestState",
    state_status: false,
  },
  {
    state_name: "Gujarat",
    state_status: false,
  },
  {
    state_name: "Karnataka",
    state_status: false,
  },
  {
    state_name: "Kerala",
    state_status: false,
  },
];

const GridStateCard = ({ item}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const Axios = useAxiosPrivate()
  React.useEffect(() => {
    setState(item);
  }, [item]);

  const handleChange = async (e) => {
    let param = {
      state_id: state?.state_id,
      status: e.target.checked,
      state_name: state?.state_name,
    };

    const data = await dispatch(ChangeStatusState(Axios, param));

    if (data.code === 200) {
      setState({
        ...state,
        state_status: e.target.checked ? true : false,
      });
      toast.success("SuccessFully Updated Status!!");
    } else {
      toast.error("Failed To Change Status!!!");
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Grid item xs={12} sm={6} md={4}>
        <Card
          component={Paper}
          sx={{
            backgroundColor: "#323546",
            padding: "8px 10px",
            display: "flex",
            border: "1px solid white"
          }}
        >
          <label style={{ color: "white", flex: "10" }}>
            {state?.state_name}
          </label>
          <IOSSwitch checked={state?.state_status} onChange={handleChange} />
        </Card>
      </Grid>
    </>
  );
};

const StateMgt = () => {
  const [loading, setLoading] = useState(false)
  const Axios = useAxiosPrivate()
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [text, setText] = useState('')
  const store = useSelector((state) => state.Zone);

  console.log(store.stateList,"setLoadingState")

  useEffect(() => {
    setLoading(true)
    const GetData = async () => {
      const data = await dispatch(AllStateListZoneMgt(Axios, setLoading));
      //  setStateList(data)
    };
    GetData();
  }, []);

  useEffect(() => {
    setStateList(store?.stateList);
  }, [store?.stateList]);
  const BgColor = GetColor("--color-light-background")

  const Search = (data, columns, q) => {
    if (data !== undefined && data !== null && data.length !== 0) {
      return data.filter((row) =>
        columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
    }

  }

  console.log(stateList,"setLoadingState")
  return (

    loading ?
      <Stack direction="row" alignItems={"center"} justifyContent={"center"} minHeight={"100vh"}>
        <CircularProgress />
      </Stack>
      :
      <Card component={Paper} sx={{ backgroundColor: BgColor }}>
        <Stack sx={{ minHeight: "78vh" }} padding={2}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack direction="row" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={1} justifyContent="space-between">
              <IconButton
                // className={Styles.Icon}
                sx={{
                  backgroundColor: "white",
                  '&:hover': {
                    backgroundColor: "gray",
                    transition: "0.2s linear"
                  }
                }}>
                <SearchIcon sx={{ color: "black !important" }} />
              </IconButton>
              <input style={{
                border: "none",
                outline: "none",
                background: "transparent",
                color: "white"
              }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <IconButton
                // className={Styles.Icon}
                onClick={() => setText('')}
              >
                <CloseIcon sx={{ color: "white !important" }} />
              </IconButton>
            </Stack>
          </Stack>
          <Stack>
            <Grid container spacing={2} marginTop={2}>
              {stateList?.length !== 0 ? (
                Search(stateList, ['state_name'], text).map((item, index) => (
                  <GridStateCard
                    item={item}
                    stateList={stateList}
                    setStateList={setStateList}
                  />
                ))
              )
                :
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    component={Paper}
                    sx={{
                      backgroundColor: "#323546",
                      padding: "8px 10px",
                      display: "flex",
                      justifyContent: "center",
                      border: "1px solid white"

                    }}
                  >
                    <label
                      style={{
                        color: "white",
                        flex: "10",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      **No States**
                    </label>
                  </Card>
                </Grid>
              }
            </Grid>
          </Stack>
        </Stack>
      </Card>
  );
};

export default StateMgt;
