import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DateTextFieldCalender,
  StyledTableCell,
  StyledTableCellWallet,
} from "../../../../utility/TextField";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../../UserAccess/styles/UserAccessTable.module.css";
import { GetColor } from "../../../../utility/functions/functions";
import {
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
  DeleteHolidayCalender,
  UpdateHolidayCalender,
} from "../../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { SearchFilter } from "../../../../utility/constants/functions/functions";
import { IOSSwitch } from "../../../../utility/IosSwitch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GETEDITEDVALUE,
  GETFORMSTATUS,
  GETHOLIDAYID,
} from "../../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PopUpModal from "../../../../utility/Backdrop";
import ConfirmationDialog from "../../HolidayCalender/ConfirmationDialog";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";

const WalletUserTransactionTable = () => {
  const holidayListByStateAndYear = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByStateAndYear
  );
  const allValueDetails = useSelector(
    (store) => store.HolidayCalenderReducer.AllValueDetails
  );
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCellWallet align="center" size="small">
                  S.No
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Zone
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Transaction type
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Amount
                </StyledTableCellWallet>
                <StyledTableCellWallet align="center" size="small">
                  Date & Time
                </StyledTableCellWallet>
              </TableRow>
            </TableHead>

            <TableBody>
              {allValueDetails?.year ? (
                holidayListByStateAndYear?.map((item, index) => {
                  return (
                    <Row Access={item} index={index + 1} coins={"Send Coins"} />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Send Coins</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default WalletUserTransactionTable;

const Row = ({
  Access,
  index,
  holidayId,
  stateId,
  handleDelete,
  handleEdit,
  handleConfirmRemove,
  dayName,
  formattedDate,
  currentDate,
  currentHolidayName,
}) => {
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const [editIconStatus, setEditIconStatus] = useState(true);
  console.log(editIconStatus);
  const [editedEvent, setEditedEvent] = useState(Access.holiday_name);
  console.log(currentHolidayName, "stateList");

  console.log(editedEvent, "editedEvent");

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "editedValue");
  const [changedDate, setChangedDate] = useState(null);

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYID,
      payload: { holiday_id: holidayId, state_id: Access.state_id },
    });
  }, []);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmRemoveClick = () => {
    handleConfirmRemove(holidayId, stateId);
    setIsConfirmDialogOpen(false);
  };
  const handleUpdateClick = () => {
    setEditIconStatus(false);
  };

  const handleSave = () => {
    setEditIconStatus(true);

    handleEdit(
      holidayId,
      stateId,
      currentDate,
      currentHolidayName,
      formattedDate
    );
  };

  const [inputValue, setInputValue] = useState(
    editedEvent || currentHolidayName
  );

  const handleEditEvent = (e, value) => {
    console.log(e.target.value);
    setEditedEvent(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: changedDate, edited_event: editedEvent },
    });
  }, [changedDate, editedEvent]);

  const Edit = () => {
    // setOpen(true);

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleStartDateChange = (newValue) => {
    const originalDate = new Date(newValue);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const date = originalDate.getDate();

    // Create a formatted date string (YYYY-MM-DD)
    const formattedDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;
    setChangedDate(formattedDateString);

    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: formattedDateString, edited_event: editedEvent },
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          Send Coins
        </TableCell>
        {editIconStatus ? (
          <>
            <TableCell align="center" size="small" sx={{ color: "#fff" }}>
              {access?.holiday_name}
            </TableCell>
            <TableCell align="center" size="small" sx={{ color: "#fff" }}>
              {formattedDate}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <TextField
                variant="outlined"
                // value={editedEvent?editedEvent:currentHolidayName}
                value={editedEvent}
                onChange={handleEditEvent}
              />
            </TableCell>
            <TableCell>
              {/* <TextField
                  
                  variant="outlined"
                  value={editedDate}
                  onChange={handleEditDate}
                /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={
                    editedValue?.edited_date
                      ? editedValue?.edited_date
                      : currentDate
                  }
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <DateTextFieldCalender
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </TableCell>
          </>
        )}
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {dayName}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.7rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DeleteIcon onClick={handleDeleteClick} />
            {editIconStatus ? (
              <EditIcon onClick={handleUpdateClick} />
            ) : (
              <button
                onClick={handleSave}
                style={{
                  background: "#1B1D23",
                  padding: "0.5rem",
                  marginLeft: "0.4rem",
                  color: "red",
                  borderColor: "red",
                }}
              >
                Save
              </button>
            )}
          </Stack>
        </TableCell>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmRemoveClick}
          holidayId={holidayId}
          stateId={stateId}
        />
      </TableRow>
    </>
  );
};
