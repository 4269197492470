import { Authaxios } from "../../axios/axios";
import {
  ADDZONEBYSTATEBOOL,
  GETALLDISTRICTLIST,
  GETALLSTATELISTZONEMGTCONST,
  GETSDISTRICTSTATUS,
  GETSLABONEZONELISTSTATUS,
  GETSLABTWOZONELIST,
  GETSLABTWOZONELISTSTATUS,
  UPDATESTATESTATUS,
} from "../../utility/ReduxConstants/ZoneReducerConstant";

export const AllStateListZoneMgt = (Axios, setLoading) => async (dispatch) => {
  try {
    // let code = 500
    const { data } = await Axios.post("AllstateList");
    if (data.code === 200) {
      dispatch({ type: GETALLSTATELISTZONEMGTCONST, payload: data.stateList });
      setLoading(false);
      return data.stateList;
    }
  } catch (error) {
    return error?.response?.data;
    console.log(error);
  }
};

export const AllDistrictListZoneMgt =
  (Axios,param, setLoading) => async (dispatch) => {
    try {
      // let code = 500
      const { data } = await Axios.post("AlldistrictList",param);
      console.log(data,"setData")
      
      if (data.code === 200) {
        dispatch({ type: GETALLDISTRICTLIST, payload: data.districtList });
        setLoading(false);
        return data.districtList;
      }
    } catch (error) {
      return error?.response?.data;
      console.log(error);
    }
  };

export const AddZoneByStateId =
  (Axios, param, setAddsuccess, failed, setFailed) => async (dispatch) => {
    try {
      
      const { data } = await Axios.post("addZoneByState",param);
      
      if (data.code === 200) {
        setAddsuccess(true);
      } else {
        setFailed({
          ...failed,
          addFailed: true,
          addFailedMessage: data?.message,
        });
      }
    } catch (error) {
      
      setFailed({
        ...failed,
        addFailed: true,
        addFailedMessage: error?.response?.data?.message,
      });
    }
  };

export const AddDistrictByStateId =
  (Axios, param, setAddsuccess, failed, setFailed) => async (dispatch) => {
    try {
      // let code = 500
      const { data } = await Axios.post("addDistrictByState", param);
      
      if (data.code === 200) {
        setAddsuccess(true);
      } else {
        setFailed({
          ...failed,
          addFailed: true,
          addFailedMessage: data?.message,
        });
      }
    } catch (error) {
      
      setFailed({
        ...failed,
        addFailed: true,
        addFailedMessage: error?.response?.data?.message,
      });
    }
  };

export const ALLZoneListByStateID =
  (Axios, param, GETSLABTWOZONELIST) => async (dispatch) => {
    // return new Promise(async(resolve, reject) => {
    try {
      // let code = 500
      const { data } = await Axios.post("ZoneListByStateId", param);
      if (data.code === 200) {
        dispatch({ type: GETSLABTWOZONELIST, payload: data.zoneList });
        //  resolve(data)
      }
      // else{
      //     reject("Failed To Fetch")
      // }
    } catch (error) {
      console.log(error);
    }
    // })
  };

export const ALLZoneListByStateIDAndDistrictID =
  (Axios, param, GETSZONELISTBYSTATEANDDISTRICT) => async (dispatch) => {
    // return new Promise(async(resolve, reject) => {
    try {
      // let code = 500
      const { data } = await Axios.post(
        "ZoneListByStateIdAndDistrictId",
        param
      );
      if (data.code === 200) {
        dispatch({
          type: GETSZONELISTBYSTATEANDDISTRICT,
          payload: data.zoneList,
        });
        //  resolve(data)
      }
      // else{
      //     reject("Failed To Fetch")
      // }
    } catch (error) {
      console.log(error);
    }
    // })
  };

export const ALLDistrictListByStateID =
  (Axios, param, GETSDISTRICTLIST) => async (dispatch) => {
    // return new Promise(async(resolve, reject) => {
    try {
      // let code = 500
      const { data } = await Axios.post("DistrictListByStateId", param);
      if (data.code === 200) {
        dispatch({ type: GETSDISTRICTLIST, payload: data.districtList });
        //  resolve(data)
      }
      // else{
      //     reject("Failed To Fetch")
      // }
    } catch (error) {
      console.log(error);
    }
    // })
  };

export const ChangeZoneStatus =
  (Axios, param, type, setAddsuccess, failed, setFailed) =>
  async (dispatch) => {
    try {
      const { data } = await Axios.post("changeZoneStatus", param);
      console.log(data);
      if (type === 1) {
        if (data.code === 200) {
          dispatch({ type: GETSLABONEZONELISTSTATUS, payload: param });
          setAddsuccess(true);
        } else {
          setFailed({
            ...failed,
            addFailed: true,
            addFailedMessage: "Changing Status Failed!! Please Try Again",
          });
        }
      } else {
        if (data.code === 200) {
          dispatch({ type: GETSLABTWOZONELISTSTATUS, payload: param });
          setAddsuccess(true);
        } else {
          setFailed({
            ...failed,
            addFailed: true,
            addFailedMessage: "Changing Status Failed!! Please Try Again",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // })
  };

  export const ChangeDistrictStatus =
  (Axios, param, type, setAddsuccess, failed, setFailed) =>
  async (dispatch) => {
    try {
      const { data } = await Axios.post("changeDistrictStatus", param);
      console.log(data);
      if (type === 1) {
        if (data.code === 200) {
          dispatch({ type: GETSDISTRICTSTATUS, payload: param });
          setAddsuccess(true);
        } else {
          setFailed({
            ...failed,
            addFailed: true,
            addFailedMessage: "Changing Status Failed!! Please Try Again",
          });
        }
      } else {
        if (data.code === 200) {
          dispatch({ type: GETSLABTWOZONELISTSTATUS, payload: param });
          setAddsuccess(true);
        } else {
          setFailed({
            ...failed,
            addFailed: true,
            addFailedMessage: "Changing Status Failed!! Please Try Again",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // })
  };

  // export const ChangeDistrictStatus = (Axios, param) => async (dispatch) => {
  //   // return new Promise(async(resolve, reject) => {
  //   try {
  //     // let code = 500
  //     const { data } = await Axios.post("changeDistrictStatus", param);
  //     if (data.code === 200) {
  //       dispatch({ type: GETSDISTRICTSTATUS, payload: param });
  //       return data;
  //     }
  //   } catch (error) {
  //     return error.response.data;
  //     console.log(error);
  //   }
  //   // })
  // };

export const ChangeStatusState = (Axios, param) => async (dispatch) => {
  // return new Promise(async(resolve, reject) => {
  try {
    // let code = 500
    const { data } = await Axios.post("changeStateStatus", param);
    if (data.code === 200) {
      dispatch({ type: UPDATESTATESTATUS, payload: param });
      return data;
    }
  } catch (error) {
    return error.response.data;
    console.log(error);
  }
  // })
};
