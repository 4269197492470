export const GetYourDealsActionBuyer =
  (AXIOS, YourDealsUrl, param) => async (dispatch) => {
    try {
      
      const data = await AXIOS.post(YourDealsUrl, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const GetYourDealsActionSeller =
  (AXIOS, YourDealsUrl, param) => async (dispatch) => {
    try {
      
      const data = await AXIOS.post(YourDealsUrl, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const GetYourDealsTabsCountBuyer =
  (AXIOS, YourDealsUrl, param) => async (dispatch) => {
    try {
      
      const data = await AXIOS.post(YourDealsUrl, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const GetYourDealsTabsCountSeller =
  (AXIOS, YourDealsUrl, param) => async (dispatch) => {
    try {
      
      const data = await AXIOS.post(YourDealsUrl, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const YourDealDb_List = (AXIOS, URL, param) => async (dispatch) => {
  try {
    
    const data = await AXIOS.post(URL, param);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
