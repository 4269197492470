import React, { useState, useEffect } from 'react';
import Styles from './styles/index.module.css'
import AddCategory from './AddCategory';
import UpdateCategory from './UpdateCategory';
import CarouselCategory from './carousel';
import { GetCategoryPageData, GetAllSuperCategoryList, GetCategoryListBySCId, GetCategoryDetailsId } from '../../../../actions/category/categoryActions';
import { useDispatch, useSelector } from 'react-redux';
import './styles/category.scss';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import { Autocomplete, IconButton, Stack } from '@mui/material';
import { CardWrapper } from '../../CardWrapper';
import AddWrapperComponent from '../AddWrapperComponent';
import { GETCATEGORYDETAILS, SELECTEDSUPERCATEGORY } from '../../../../utility/ReduxConstants/CategoryReducerConstant';
import { AutocompleteTextField, AutocompleteTextFieldCurved, SearchInputField } from '../../../../utility/TextField';
import { defaultImg } from '../../../../utility/BaseUrl';
import CategoryListTwo from './CategoryListTwo';
import CategoryList from './categoryList';
import { CheckSmallError } from "../../../../utility/ErrorCheck";

const CategoryMgt = () => {
  const Axios = useAxiosPrivate()
  const [open, setOpen] = useState(false)
  const store = useSelector((state) => state.Category);
  const column = ["category_name"];
  const [text, setText] = useState("");
  const [list, setList] = useState([]);
  const [id, setId] = useState("");

  const [scValue, setscValue] = useState("");

  const [scId, setscId] = useState(0);
  const [scList, setscList] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    let param = {
      "sc_id": 0
    }
    dispatch(GetCategoryListBySCId(Axios, param));
    dispatch(GetAllSuperCategoryList(Axios));
  }, [])

  let handleOpen = (state) => {
    setOpen(state)
  }

  React.useEffect(() => {
    let arr = [];
    let selected_category = {};
    if (
      store.category_list !== undefined &&
      store.category_list !== null &&
      store.category_list.length !== 0
    ) {
      for (let i = 0; i < store.category_list.length; i++) {
        selected_category = store.category_list[0];
        arr.push(store.category_list[i]);
      }
    }
    setList(arr); //store.s_category_list

    let param = {
      item_id: store?.category_list[0]?.category_id,
      type: "category"
    };

    setId(store?.category_list[0]?.category_id)

    dispatch(GetCategoryDetailsId(Axios, param));
  }, [store.category_list]);

  useEffect(() => {
    let arr = [];

    if (
      store.s_category_list != undefined &&
      store.s_category_list !== null &&
      store.s_category_list.length !== 0
    ) {
      for (let i = 0; i < store.s_category_list.length; i++) {
        arr.push(store.s_category_list[i].name);
      }
    }

    setscList(arr);
    setscValue(arr[0] ?? '');
    let param = {
      sc_id: store?.s_category_list[0]?.sc_id,
      sc_name: store?.s_category_list[0]?.name,
    }
    dispatch({ type: SELECTEDSUPERCATEGORY, payload: param });
    let paramTwo = {
      sc_id: store?.s_category_list[0]?.sc_id,
    };
    dispatch(GetCategoryListBySCId(Axios, paramTwo));
  }, [store.s_category_list]);


  const handleSuperCategoryChange = (event, value) => {
    if (!CheckSmallError(value) && value !== ""){
    if (value.length !== 0 && value !== null && value !== undefined) {
      let id = 0;

      for (let i = 0; i < store.s_category_list.length; i++) {
        if (value === store.s_category_list[i].name) {
          id = store.s_category_list[i].sc_id;
          break;
        }
      }
      setscValue(value);
      setscId(id);
      dispatch({
        type: GETCATEGORYDETAILS, payload: {
          description: "",
          item_id: 0,
          item_image: defaultImg,
          item_name: "",
          sc_id: id,
          sc_name: "value"
        }
      })
      let param2 = {
        sc_id: id,
        sc_name: value
      }
      dispatch({ type: SELECTEDSUPERCATEGORY, payload: param2 });
      let param = {
        sc_id: id,
      };
      dispatch(GetCategoryListBySCId(Axios, param));
    }
  }
  };


  return (
    
      <CardWrapper>
        <Stack direction="column" gap={2}>
          <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
            <Stack flex={1}>
              <AddWrapperComponent formOpen={open} handleFormOpen={handleOpen} Title={"Add Category"}>
                    <AddCategory/>
              </AddWrapperComponent>
            </Stack>
            <Stack flex={2.3} sx={{ padding: "20px 5px 10px 5px", color: "white" }}>
              <Stack gap={1} padding="20px 0px">
                {/* <Typography variant="h6">Search User</Typography> */}
                <Stack direction={{xs:"column",md:"row"}} gap={2}>
                  <Autocomplete
                    value={scValue}
                    options={scList}
                    onChange={handleSuperCategoryChange}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <AutocompleteTextFieldCurved
                        {...params}
                        sx={{minWidth:{xs:"200px",md:"250px",lg:"300px"}}}
                        size="small"
                        variant="outlined"
                        label="Super Category"
                      />
                    )}
                  />
                  <Stack direction="row" flexWrap="wrap" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={1} >
                    <IconButton
                      // className={Styles.Icon}
                      sx={{
                        backgroundColor: "white",
                        '&:hover': {
                          backgroundColor: "gray",
                          transition: "0.2s linear"
                        }
                      }}>
                      <SearchIcon sx={{ color: "black !important" }} />
                    </IconButton>
                    <SearchInputField sx={{
                      flex:{xs:1,md:1},
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "white"
                    }}
                      value={text} onChange={(e) => setText(e.target.value)}
                      placeholder='Search Category Name'
                    />
                    <IconButton
                      // className={Styles.Icon}
                      onClick={() => setText('')}
                    >
                      <CloseIcon sx={{ color: "white !important" }} />
                    </IconButton>
                  </Stack>
                </Stack>


              </Stack>
              {open && <CategoryList   text={text} />} 
            </Stack>
          </Stack>
          {!open && <CategoryListTwo text={text} open={open}/>}
         
        </Stack>
      </CardWrapper>
    

  )
}

export default CategoryMgt