import { switchUnstyledClasses } from "@mui/base";
import {
  ADDZONEBYSTATEBOOL,
  ADDZONEBYSTATEDEFAULT,
  GETALLDISTRICTLIST,
  GETALLSTATELISTZONEMGTCONST,
  GETSDISTRICTLIST,
  GETSDISTRICTSTATUS,
  GETSLABONEZONELIST,
  GETSLABONEZONELISTSTATUS,
  GETSLABTWOZONELIST,
  GETSLABTWOZONELISTSTATUS,
  GETSZONELISTBYSTATEANDDISTRICT,
  UPDATESTATESTATUS,
} from "../../utility/ReduxConstants/ZoneReducerConstant";

const initialState = {
  stateList: [],
  zoneListSlab1: [],
  zoneListSlab2: [],
  AddZoneSuccess: false,
  districtList: [],
  districtListByState: [],
  zoneListByStateAndDistrict: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETALLSTATELISTZONEMGTCONST:
      return {
        ...state,
        stateList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSLABONEZONELIST:
      return {
        ...state,
        zoneListSlab1:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSDISTRICTLIST:
      return {
        ...state,
        districtListByState:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case GETSZONELISTBYSTATEANDDISTRICT:
      return {
        ...state,
        zoneListByStateAndDistrict:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSLABTWOZONELIST:
      return {
        ...state,
        zoneListSlab2:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    case GETSLABONEZONELISTSTATUS:
      return {
        ...state,
        zoneListSlab1: state.zoneListSlab1.map((item) =>
          item.zone_id === action.payload.zone_id ? action.payload : item
        ),
      };
    case GETSLABTWOZONELISTSTATUS:
      return {
        ...state,
        zoneListSlab2: state.zoneListSlab2.map((item) =>
          item.zone_id === action.payload.zone_id ? action.payload : item
        ),
      };
      case GETSDISTRICTSTATUS:
      return {
        ...state,
        districtListByState: state.districtListByState.map((item) =>
          item.district_id === action.payload.district_id ? action.payload : item
        ),
      };
    case UPDATESTATESTATUS:
      return {
        ...state,
        stateList: state.stateList.map((item) =>
          item.state_id === action.payload.state_id ? action.payload : item
        ),
      };
    case GETALLDISTRICTLIST:
      return {
        ...state,
        districtList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };
    default:
      return state;
  }
};
