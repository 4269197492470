export const GETSTATELISTDASHBOARD = 'GETSTATELISTDASHBOARD';
export const GETZONELISTDASHBOARD = 'GETZONELISTDASHBOARD';
export const GETDISTRICTLISTDASHBOARD = 'GETDISTRICTLISTDASHBOARD';

export const GETORDERCOUNTDELIVERY = 'GETORDERCOUNTDELIVERY';
export const GETORDERCOUNTPICKUP = 'GETORDERCOUNTPICKUP';

export const GETACTIVEUSERS = 'GETACTIVEUSERS';
export const GETTRANSACTIONDATA = 'GETTRANSACTIONDATA';

export const GETSKUZONELISTDASHBOARD = 'GETSKUZONELISTDASHBOARD';
export const GETSKUDISTRICTLISTDASHBOARD = 'GETSKUDISTRICTLISTDASHBOARD';
export const GETSKUSCLISTDASHBOARD = 'GETSKUSCLISTDASHBOARD';
export const GETSKUCATEGORYLISTDASHBOARD = 'GETSKUCATEGORYLISTDASHBOARD';
export const GETSKUANALYTICSLISTDASHBOARD = 'GETSKUANALYTICSLISTDASHBOARD';


export const GETSCDASHBOARDDATA = "GETSCDASHBOARDDATA";
