import React from 'react'
import { DarkCardWrapper } from '../CardWrapper'
import { Autocomplete, Collapse, IconButton, Stack, Typography } from '@mui/material'
import { AutocompleteTextField } from '../../../utility/TextField';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AddIcon from '@mui/icons-material/Add';
import { GetColor } from '../../../utility/functions/functions';
const IconColor = GetColor("--color-light-background")


const AddWrapperComponent = ({formOpen,handleFormOpen,children,Title}) => {
    return (
        <>
         <DarkCardWrapper sx={{ padding: "0px 10px", color: "white", borderRadius: "17px", height: "100%",marginTop: { xs: "15px", md: "15px" } }} >
            <Stack direction="column" sx={{ padding: "20px 10px 20px 10px", height: "100%" }} justifyContent={"space-between"} gap={0}>
                <Stack gap={2}>
                    <Typography >{Title}</Typography>
                    <Collapse orientation="vertical" in={formOpen}>
                        {children}
                    </Collapse>
                </Stack>

                <Stack justifyContent={"flex-end"}>
                    <Stack direction="row" justifyContent={"center"} >
                        <IconButton sx={{ color: "white" }} onClick={() => handleFormOpen(!formOpen)}>
                            {!formOpen && <KeyboardDoubleArrowDownIcon />}
                            {formOpen && <KeyboardDoubleArrowUpIcon />}
                        </IconButton></Stack>
                </Stack>
            </Stack>
        </DarkCardWrapper>
          <Stack sx={{ position: "absolute", marginTop: { xs: "-6px", md: "-6px" }, marginLeft: { xs: "60%", sm: "60%", md: "250px" } }} direction="row" justifyContent="flex-end">
        <IconButton
          size="large"
          sx={{
            backgroundColor: IconColor,
            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      </Stack>
        </>
       
    )
}

export default AddWrapperComponent
