import { Card, Paper, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { GetColor } from "../../../utility/functions/functions";
import { CurvedRedButton } from "../../../utility/Button";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { UpdateUserAcccess } from "../../../actions/UserAccess/UserAccessZoneAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AutocompleteTextField } from "../../../utility/TextField";

const UserAccessUpdate = () => {
  const TitleColor = GetColor("--color-white");
  const BgColor = GetColor("--color-light-background");
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.Access);
  const [obj, setObj] = useState({
    name: "",
    mobile_no: "",
    admin_id: "",
  });
  const show = store?.updateUserIsOpenOrNot;
  console.log(show, "show");

  useEffect(() => {
    setObj(store?.userDetails ?? {});
  }, [store?.userDetails]);

  const handleChange = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };
  const [error, setError] = useState({});
  const isValid = () => {
    let success = true;
    let error = {};

    if (!obj?.name?.trim()) {
      error.name = "This Field Cannot be empty!!";
      success = false;
    }

    let Mobile = parseInt(obj?.mobile_no) ?? 0;

    if (!Mobile?.toString()?.trim()) {
      error.mobile = "Mobile No.Cannot be empty!!";
      success = false;
    } else if (Mobile?.toString()?.length !== 10) {
      error.mobile = "Enter Valid mobile number!";
      success = false;
    }

    console.log("here");
    setError(error);
    return success;
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (isValid()) {
      let param = {
        name: obj.name,
        mobile_no: obj.mobile_no,
        admin_id: obj.admin_id,
      };

      const data = await dispatch(UpdateUserAcccess(Axios, param));
      if (data?.code === 200) {
        toast.success("Updated details successfully");
        setObj({});
      } else {
        toast.error(data?.message);
      }
    }
  };

  const DarkBgColor = GetColor("--color-dark-background");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <form onSubmit={handleUpdate}>
        <Stack direction="column" gap={2} id="ScrollView">
          {show ? (
            <Stack gap={1}>
              <Stack marginLeft={1} sx={{ color: "white" }}>
                <Typography>Update User</Typography>
              </Stack>
              <Card
                component={Paper}
                sx={{
                  backgroundColor: DarkBgColor,
                  padding: "10px 0px",
                  color: "white",
                }}
              >
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack>
                    <Stack
                      direction={{ xs: "column", sm: "column", md: "row" }}
                      padding={2}
                      gap={2}
                    >
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Stack
                          direction={{ xs: "column", sm: "column", md: "row" }}
                        >
                          <AutocompleteTextField
                            variant="outlined"
                            label="Name"
                            size="small"
                            value={obj.name ?? ""}
                            name="name"
                            onChange={handleChange}
                          />
                          {error.name && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              **{error.name}**
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Stack direction="column">
                          <AutocompleteTextField
                            variant="outlined"
                            label="Mobile No"
                            size="small"
                            value={obj.mobile_no ?? ""}
                            name="mobile_no"
                            onChange={handleChange}
                          />
                          {error.mobile && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              **{error.mobile}**
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack marginRight={2}>
                      <CurvedRedButton type="submit">Update</CurvedRedButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </form>
    </>
  );
};

export default UserAccessUpdate;
