import { Stack } from "@mui/material";
import React from "react";

const Wrapper = ({ children }) => {
  return (
    <Stack
      sx={{
        padding: { xs: "10px", sm: "10px", md: "20px 60px 20px 40px" },
      }}
    >
      {children}
    </Stack>
  );
};

export default Wrapper;
