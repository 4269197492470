import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const YourDealsSellerHeader = ({ tabsCountSellerHeader }) => {
  const getBuyerDataList = useSelector((state) => state.YourDealsReducer);
  return (
    <Stack
      sx={{
        backgroundColor: "#F43E3E",
        width: "20rem",
        textAlign: "center",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        margin: "auto",
        padding: "10px",
        marginTop: "0",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        margin={"auto"}
        alignItems={"center"}
      >
        <Stack>
          <Typography>I AM SELLER</Typography>
        </Stack>
        <Stack
          sx={{
            marginLeft: "1rem",
            borderRadius: "100px",
            backgroundColor: "white",
            padding: "2px 4px",
            color: "green",
          }}
        >
          <Typography>{tabsCountSellerHeader}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default YourDealsSellerHeader;
