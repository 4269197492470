import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./Styles/NewAddSku.module.css";
import PopUp from "./PopUp";
import { styled } from "@mui/material/styles";
import { defaultImg } from "../../../utility/BaseUrl";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddNewSku,
  GetAllCategoryNewSku,
  GetAllSkuList,
  GetAllSubCategoryNewSku,
} from "../../../actions/newSkuAction/newSkuAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { DarkCardWrapper } from "../CardWrapper";
import { GetColor } from "../../../utility/functions/functions";

import AddIcon from "@mui/icons-material/Add";
import { AutocompleteTextField } from "../../../utility/TextField";
import { CheckSmallError } from "../../../utility/ErrorCheck";
const IconColor = GetColor("--color-light-background");
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));
const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
    color: "white",
  },
}));

const NewAddSku = () => {
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [subcategoryLoader, setSubCategoryLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.NewSku);
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [obj, setObj] = useState({
    ptr: 0,
    ptw: 0,
    mrp: 0,
    premium_delivery_market_price: 0,
    premium_delivery_lot_size: 0,
    premium_pickup_market_price: 0,
    premium_pickup_lot_size: 0,
    scheme: "",
    lot_size_delivery: 0,
    lot_size_pickup: 0,
    sku_name: "",
    sku_desc: "",
    company_id: 0,
    category_id: 0,
    s_cat_id: 0,
    sku_image: "",
    sc_id: 0,
    categoryList: [],
    subCategoryList: [],
    companyList: [],
    superCategoryList: [],
    sub_category: { s_cat_id: 0, s_category_name: "" },
    category: { category_id: 0, category_name: "" },
    company: { item_id: 0, item_name: "" },
    scCategory: { sc_id: 0, name: "" },
    premium_delivery_market_price: 0,
    premium_delivery_lot_size: 0,
    premium_pickup_market_price: 0,
    premium_pickup_lot_size: 0,
  });

  console.log(obj.subCategoryList, "inSkustore");

  const handleChange = (e,value) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });

    
    // if (value === "0") {
    //   setObj((prevObj) => ({
    //     ...prevObj,
    //     [e.target.name]: "",
    //   }));
    // } else {
    //   setObj((prevObj) => ({
    //     ...prevObj,
    //     [e.target.name]: e.target.value,
    //   }));
    // }
  };

  useEffect(() => {
    let comArr = [];
    if (store.companyList !== undefined && store.companyList !== null) {
      comArr = store.companyList;
      // comArr.push({
      //   item_id: 0,
      //   item_name: "",
      // });
    }

    let scArr = [];
    if (
      store.superCategoryList !== undefined &&
      store.superCategoryList !== null
    ) {
      scArr = store.superCategoryList;
      // scArr.push({
      //   sc_id: 0,
      //   name: "",
      // });
    }
    setCategoryLoader(true);
    let catArr = [];
    if (store.categoryList !== undefined && store.categoryList !== null) {
      // catArr.push({category_id:0,category_name:''});
      catArr = store.categoryList;
      // catArr.push({
      //   category_id: 0,
      //   category_name: "",
      // });
      setCategoryLoader(false);
    }

    setSubCategoryLoader(true);
    let SubcatArr = [];
    if (store.subCategoryList !== undefined && store.subCategoryList !== null) {
      SubcatArr = store.subCategoryList;
      console.log(SubcatArr, "SubcatArr");
      // SubcatArr.push({
      //   s_cat_id: 0,
      //   s_category_name: "",
      // });
      setSubCategoryLoader(false);
    }
    setObj({
      ...obj,
      categoryList: catArr,
      companyList: comArr,
      superCategoryList: scArr,
      subCategoryList: SubcatArr,
    });

    console.log(obj, "SubcatAsjjncjnrr");
  }, [
    store.companyList,
    store.categoryList,
    store.superCategoryList,
    store.subCategoryList,
  ]);

  const handleCategory = (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      console.log(value, "handleCategory");
      const { category_id, sc_id } = value;
      setObj({
        ...obj,
        category_id: category_id,
        category: value,
        sub_category: { s_cat_id: 0, s_category_name: "" },
      });

      let param = {
        sc_id: sc_id,
        category_id: category_id,
      };
      setSubCategoryLoader(true);
      dispatch(GetAllSubCategoryNewSku(Axios, param));
      // console.log(data, "handlesubCategory");

      // setObj({
      //   ...obj,
      //   categoryList: catArr,
      //   companyList: comArr,
      //   superCategoryList: scArr,
      //   subCategoryList: SubcatArr,
      // });
    }
  };

  const handleSubCategory = (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      console.log(value, "handleSubmit");
      const { s_cat_id } = value;
      setObj({
        ...obj,
        s_cat_id: s_cat_id,
        sub_category: value,
      });
    }
  };

  const handleCompany = (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      const { item_id } = value;
      console.log(item_id, "handleSubmit");
      setObj({
        ...obj,
        company_id: item_id,
        company: value,
      });
    }
  };

  const IsValid = () => {
    let success = true;
    const regex = /[^a-zA-Z0-9',&-](?= |$)/g;
    const Chars = obj.sku_name?.match(regex);
    let error = {};
    console.log(Chars, "Chars");
    if (!obj.sku_name.trim()) {
      error.sku_name = "*Sku Name Cannot be Empty*";
      success = false;
    } else if (Chars && Chars?.length !== 0) {
      error.sku_name = `*Only ',& and - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*`;
      success = false;
    }

    if (
      obj.category_id === 0 ||
      obj.category_id === "" ||
      obj.category_id.length === 0
    ) {
      error.category = "*Please choose a category*";
      success = false;
    }
    if (
      obj.company_id === 0 ||
      obj.company_id === "" ||
      obj.company_id.length === 0
    ) {
      error.company = "*Please choose a category*";
      success = false;
    }
    if (obj.sc_id === 0 || obj.sc_id === "" || obj.sc_id.length === 0) {
      error.sc = "*Please choose a super category*";
      success = false;
    }
    // if((/^[a-zA-Z ]*$/))
    // if (!obj.sku_desc.trim()) {
    //     error.sku_desc = '*Sku Description Cannot be Empty*';
    //     success = false
    // }
    if (!obj.sku_image.trim()) {
      error.sku_image = "*Please Choose a Image*";
      success = false;
    }

    if (
      obj.mrp === 0 ||
      obj.ptr === 0 ||
      obj.ptw === 0 ||
      obj.lot_size_delivery === 0 ||
      obj.lot_size_pickup === 0
    ) {
      if (obj.mrp === 0) {
        error.mrp = "*MRP should be greater than 0*";
      }
      if (obj.ptr === 0) {
        error.ptr = "*PTR should be greater than 0*";
      }
      if (obj.ptw === 0) {
        error.ptw = "*PTW should be greater than 0*";
      }
      if (obj.lot_size_delivery === 0) {
        error.lot_size_delivery =
          "*Lot size for delivery should be greater than 0*";
      }
      if (obj.lot_size_pickup === 0) {
        error.lot_size_pickup =
          "*Lot size for pickup should be greater than 0*";
      }

      success = false;
    }

    console.log(error, success);
    setError(error);
    return success;
  };

  console.log(obj.sub_category.s_cat_id, "handleSubmit");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let param = {
      sku_name: obj.sku_name,
      sku_desc: obj.sku_desc,
      company_id: obj.company_id,
      category_id: obj.category_id,
      s_cat_id: obj.sub_category.s_cat_id,
      sku_image:
        obj.sku_image !== undefined &&
        obj.sku_image !== null &&
        obj.sku_image.length !== 0
          ? obj.sku_image
          : defaultImg,
      sc_id: obj.scCategory.sc_id,
      ptr: obj.ptr ?? 0,
      ptw: obj.ptw ?? 0,
      mrp: obj.mrp ?? 0,
      scheme: obj.scheme ?? "",
      lot_size_delivery: obj.lot_size_delivery ?? 0,
      lot_size_pickup: obj.lot_size_pickup ?? 0,
      lock_price_d: obj.premium_delivery_market_price ?? 0,
      lock_price_p: obj.premium_pickup_market_price ?? 0,
      lock_lot_d: obj.premium_delivery_lot_size ?? 0,
      lock_lot_p: obj.premium_pickup_lot_size ?? 0,
    };

    console.log(param, "handleSubmit");

    if (IsValid()) {
      let code = await dispatch(AddNewSku(Axios, param));
      console.log(code, "IsValidAddNewSku");
      if (code.code === 200) {
        toast.success("Added Sku SuccessFully");
        dispatch(GetAllSkuList(Axios));
        setObj({
          ...obj,
          ptr: 0,
          ptw: 0,
          mrp: 0,
          scheme: "",
          lot_size_delivery: 0,
          lot_size_pickup: 0,
          sku_name: "",
          sku_desc: "",
          company_id: 0,
          category_id: 0,
          s_cat_id: 0,
          sku_image: "",
          sc_id: 0,
          premium_delivery_market_price: 0,
          premium_delivery_lot_size: 0,
          premium_pickup_market_price: 0,
          premium_pickup_lot_size: 0,
          sub_category: { s_cat_id: 0, s_category_name: "" },
          category: { category_id: 0, category_name: "" },
          company: { item_id: 0, item_name: "" },
          scCategory: { sc_id: 0, name: "" },
        });
      } else {
        toast.error(code.message);
      }
    }
  };

  useEffect(() => {
    setObj({
      ...obj,
      premium_delivery_lot_size: Math.floor(
        obj.premium_delivery_market_price / (obj?.ptw * obj?.lot_size_delivery)
      ),
      premium_pickup_lot_size: Math.floor(
        obj.premium_pickup_market_price / (obj?.ptw * obj?.lot_size_pickup)
      ),
    });
  }, [
    obj.premium_delivery_market_price,
    obj.ptw,
    obj.lot_size_delivery,
    obj?.lot_size_pickup,
    obj?.premium_pickup_market_price,
  ]);

  const ChooseImg = async (item) => {
    console.log(item);
    setShow(false);

    handleChanges(item);
  };

  const handleChanges = async (item) => {
    console.log(item, "imgClicked");

    const { sc_id, name, image_name, image_path, category_id } = item;
    const SC = store.superCategoryList?.filter((i) => i?.sc_id == item?.sc_id);
    let param = {
      sc_id: sc_id,
    };
    const CatData = await dispatch(GetAllCategoryNewSku(Axios, param));

    console.log(category_id, item, "category_id,item", CatData);

    if (CatData?.code === 200) {
      const CategoryData = CatData?.list?.filter(
        (i) => i?.category_id == category_id
      );

      let param2 = {
        sc_id: item?.sc_id,
        category_id: item?.category_id,
      };
      const SubCatData = await dispatch(GetAllSubCategoryNewSku(Axios, param2));
      console.log("SubCatData", SubCatData);

      console.log(CategoryData, "CategoryData");
      setObj({
        ...obj,
        sku_name: image_name,
        sc_id: sc_id,
        scCategory: {
          sc_id: sc_id,
          name: SC[0]?.name,
        },
        sku_image: image_path,
        category_id,
        category: {
          category_id: CategoryData[0]?.category_id,
          category_name: CategoryData[0]?.category_name,
        },
        sub_category: {
          s_cat_id: SubCatData.list[0]?.s_cat_id,
          s_category_name: SubCatData.list[0]?.s_category_name,
        },
        subCategoryList: SubCatData.list,
      });

      console.log("newobject", obj);
    }
  };

  const handleSuperCategory = (e, value) => {
    if (!CheckSmallError(value) && value !== "") {
      console.log(value, "handleSuperCategoryhandleSuperCategory");
      const { sc_id, name } = value;
      setObj({
        ...obj,
        sc_id: sc_id,
        scCategory: value,
        sub_category: { s_cat_id: 0, s_category_name: "" },
        category: { category_id: 0, category_name: "" },
      });
      let param = {
        sc_id: sc_id,
      };
      setCategoryLoader(true);
      dispatch(GetAllCategoryNewSku(Axios, param));
    }
  };

  console.log(obj);

  const handleImageDetails = (obj) => {
    console.log(obj);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DarkCardWrapper
        sx={{
          padding: "0px 10px",
          color: "white",
          borderRadius: "17px",
          height: "100%",
          marginTop: { xs: "15px", md: "15px" },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            direction="column"
            sx={{ padding: "20px 10px 20px 10px", height: "100%" }}
            justifyContent={"space-between"}
            gap={2}
          >
            <Stack gap={2}>
              <Typography>ADD SKU</Typography>
            </Stack>
            <Stack>
              <Card
                component={Paper}
                sx={{ borderRadius: "16px" }}
                onClick={() => setShow(!show)}
              >
                <Stack
                  sx={{ height: "180px" }}
                  direction="row"
                  justifyContent={"center"}
                  alignItems="center"
                  className={Styles.CardImageContainer}
                >
                  <img
                    src={
                      obj.sku_image !== undefined &&
                      obj.sku_image !== null &&
                      obj.sku_image.length !== 0
                        ? obj.sku_image
                        : defaultImg
                    }
                    alt="sku"
                    loading="lazy"
                    className={Styles.Image}
                  />
                  {/* <label className={Styles.ImgText} >Select A Image</label> */}
                  <Stack
                    sx={{
                      position: "absolute",
                      background: "red",
                      height: "180px",
                      width: "230px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0,
                      background: "rgba(0, 0, 0, 0.7)",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                    onClick={() => setShow(!show)}
                  >
                    <Typography>Select A Image</Typography>
                  </Stack>
                </Stack>
              </Card>
              {error.sku_image && (
                <label className={Styles.error}>{error.sku_image}</label>
              )}
            </Stack>

            <Stack>
              <AutocompleteTextField
                autoComplete="off"
                label="Sku Name"
                value={obj.sku_name}
                size="small"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                name="sku_name"
                onChange={handleChange}
              />
              {error.sku_name && (
                <label className={Styles.error}>{error.sku_name}</label>
              )}
            </Stack>

            <Stack>
              <Autocomplete
                value={obj.company}
                options={obj.companyList}
                getOptionLabel={(option) => option.item_name}
                size="small"
                onChange={handleCompany}
                renderInput={(params) => (
                  <AutocompleteTextField
                    {...params}
                    name="company"
                    variant="outlined"
                    label="Company"
                    placeholder="company.."
                  />
                )}
              />
              {error.company && (
                <label className={Styles.error}>{error.company}</label>
              )}
            </Stack>

            <Stack>
              <Autocomplete
                value={obj.scCategory}
                options={obj.superCategoryList}
                getOptionLabel={(option) => option.name}
                size="small"
                onChange={handleSuperCategory}
                renderInput={(params) => (
                  <AutocompleteTextField
                    {...params}
                    name="superCategory"
                    variant="outlined"
                    label="Super Category"
                    placeholder="category.."
                  />
                )}
              />
              {error.sc && <label className={Styles.error}>{error.sc}</label>}
            </Stack>
            <Stack>
              <Autocomplete
                loading={categoryLoader}
                value={obj.category}
                options={obj.categoryList}
                getOptionLabel={(option) => option.category_name}
                size="small"
                onChange={handleCategory}
                renderInput={(params) => (
                  <AutocompleteTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {categoryLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    name="category"
                    variant="outlined"
                    label="Category"
                    placeholder="category.."
                  />
                )}
              />
              {error.category && (
                <label className={Styles.error}>{error.category}</label>
              )}
            </Stack>
            <Stack>
              <Autocomplete
                // loading={categoryLoader}
                value={obj?.sub_category}
                options={obj?.subCategoryList}
                defaultValue={obj?.subCategoryList[0]}
                getOptionLabel={(option) =>
                  option.s_category_name == undefined ||
                  option.s_category_name == null
                    ? "Select"
                    : option.s_category_name
                }
                size="small"
                onChange={handleSubCategory}
                renderInput={(params) => (
                  <AutocompleteTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {subcategoryLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    name="sub_category"
                    variant="outlined"
                    label="Sub Category"
                    placeholder="sub_category.."
                    defaultValue="Ultratech Cement"
                  />
                )}
              />
              {/* {error.category && (
                <label className={Styles.error}>{error.category}</label>
              )} */}
            </Stack>

            <Stack gap={1} direction="row">
              <Stack>
                <AutocompleteTextField
                  label="PTR"
                  size="small"
                  autoComplete="off"
                  value={obj?.ptr}
                  name="ptr"
                  onChange={handleChange}
                />
                {error.ptr && (
                  <label className={Styles.error}>{error.ptr}</label>
                )}
              </Stack>
              <Stack>
                <AutocompleteTextField
                  label="PTW"
                  size="small"
                  autoComplete="off"
                  value={obj?.ptw}
                  name="ptw"
                  onChange={handleChange}
                />
                {error.ptw && (
                  <label className={Styles.error}>{error.ptw}</label>
                )}
              </Stack>
            </Stack>
            <Stack gap={1} direction="row">
              <Stack>
                <AutocompleteTextField
                  label="Lot Size Pickup"
                  size="small"
                  autoComplete="off"
                  value={obj?.lot_size_pickup}
                  name="lot_size_pickup"
                  onChange={handleChange}
                />
                {error.lot_size_pickup && (
                  <label className={Styles.error}>
                    {error.lot_size_pickup}
                  </label>
                )}
              </Stack>
              <Stack>
                <AutocompleteTextField
                  label="Lot Size Delivery"
                  size="small"
                  autoComplete="off"
                  value={obj?.lot_size_delivery}
                  name="lot_size_delivery"
                  onChange={handleChange}
                />
                {error.lot_size_delivery && (
                  <label className={Styles.error}>
                    {error.lot_size_delivery}
                  </label>
                )}
              </Stack>
            </Stack>
            <Stack>
              <AutocompleteTextField
                label="MRP"
                size="small"
                autoComplete="off"
                name="mrp"
                value={obj?.mrp}
                onChange={handleChange}
              />
              {error.mrp && <label className={Styles.error}>{error.mrp}</label>}
            </Stack>
            <AutocompleteTextField
              label="Scheme"
              multiline
              rows="2"
              size="small"
              autoComplete="off"
              value={obj?.scheme}
              name="scheme"
              onChange={handleChange}
            />
            <Stack
              sx={{
                background: "#579CFD",
                padding: "1rem",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <AutocompleteTextField
                label="Premium Delivery Market Price"
                size="small"
                autoComplete="off"
                name="premium_delivery_market_price"
                value={obj?.premium_delivery_market_price}
                onChange={handleChange}
                disabled={!obj.ptw && !obj.lot_size_delivery}
              />

              <AutocompleteTextField
                label="Premium Delivery Lot size"
                size="small"
                autoComplete="off"
                name="premium_delivery_lot_size"
                value={
                  obj?.premium_delivery_lot_size
                    ? obj?.premium_delivery_lot_size
                    : 0
                }
                onChange={handleChange}
                disabled="true"
              />
            </Stack>

            <Stack
              sx={{
                background: "#8B8000",
                padding: "1rem",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <AutocompleteTextField
                label="Premium Pickup Market Price"
                size="small"
                autoComplete="off"
                name="premium_pickup_market_price"
                value={obj?.premium_pickup_market_price}
                onChange={handleChange}
                disabled={!obj.ptw && !obj.lot_size_pickup}
              />
              <AutocompleteTextField
                label="Premium Pickup Lot size"
                size="small"
                autoComplete="off"
                name="premium_pickup_lot_size"
                value={
                  obj?.premium_pickup_lot_size
                    ? obj?.premium_pickup_lot_size
                    : 0
                }
                onChange={handleChange}
                disabled="true"
              />
            </Stack>
            <Stack
              gap={1}
              direction={{ xs: "column", md: "row" }}
              marginTop={1}
            >
              <RedButton
                variant="contained"
                type="submit"
                sx={{ flex: 1, borderRadius: "18px" }}
              >
                Add
              </RedButton>
              {/* <RedBorderButton variant="contained" sx={{ flex: 1, borderRadius: "18px" }}>Cancel</RedBorderButton> */}
            </Stack>
          </Stack>
        </form>
      </DarkCardWrapper>
      <Stack
        sx={{
          position: "absolute",
          marginTop: { xs: "-6px", md: "-6px" },
          marginLeft: { xs: "60%", sm: "60%", md: "250px" },
        }}
        direction="row"
        justifyContent="flex-end"
      >
        <IconButton
          size="large"
          sx={{
            backgroundColor: IconColor,
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      </Stack>
      {show ? (
        <PopUp
          setShow={setShow}
          ChooseImg={ChooseImg}
          show={show}
          handleImageDetails={handleImageDetails}
        />
      ) : null}
    </>
  );
};

export default NewAddSku;
