import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DateTextFieldCalender,
  StyledTableCell,
} from "../../../utility/TextField";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Styles from "../UserAccess/styles/UserAccessTable.module.css";
import { GetColor } from "../../../utility/functions/functions";
import {
  CalenderHolidayList,
  CalenderHolidayListByState,
  CalenderHolidayListByStateAndYear,
  DeleteHolidayCalender,
  UpdateHolidayCalender,
} from "../../../actions/HolidayCalender/HolidayCalenderAction";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { SearchFilter } from "../../../utility/constants/functions/functions";
import { IOSSwitch } from "../../../utility/IosSwitch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GETEDITEDVALUE,
  GETFORMSTATUS,
  GETHOLIDAYID,
} from "../../../utility/ReduxConstants/HolidayCalenderReducerConstant";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PopUpModal from "../../../utility/Backdrop";
import ConfirmationDialog from "./ConfirmationDialog";

const CalenderTable = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const holidayList = useSelector(
    (store) => store.HolidayCalenderReducer.allHolidayList
  );

  const holidayListByState = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByState
  );

  const allValueDetails = useSelector(
    (store) => store.HolidayCalenderReducer.AllValueDetails
  );

  const holidayListByStateAndYear = useSelector(
    (store) => store.HolidayCalenderReducer.holidayByStateAndYear
  );
  const holidayDetails = useSelector(
    (store) => store.HolidayCalenderReducer.holidayCalenderDetails
  );

  console.log(allValueDetails, "stateList");

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "stateList");
  useEffect(() => {
    dispatch(CalenderHolidayList(Axios));
  }, []);

  const [obj, setObj] = useState({
    edited_date: null,
    edited_event: null,
    current_holiday_name: null,
    current_holiday_date: null,
  });

  const handleDelete = async (holidayId, stateId) => {
    // Dispatch the delete action with the parameters

    try {
      let param = {
        holiday_id: holidayId,
        state_id: stateId,
      };
      await dispatch(DeleteHolidayCalender(Axios, param));

      dispatch(CalenderHolidayList(Axios));
    } catch (error) {
      console.error("Error updating holiday calendar:", error);
    }
  };

  const handleEdit = async (
    holidayId,
    stateId,
    currentDate,
    currentHolidayName,
    formattedDate
  ) => {
    try {
      let param = {
        holiday_name: editedValue?.edited_event
          ? editedValue?.edited_event
          : currentHolidayName,
        holiday_date: editedValue?.edited_date
          ? editedValue?.edited_date
          : currentDate,
        state_id: stateId,
        holiday_id: holidayId,
      };
      const data = await dispatch(UpdateHolidayCalender(Axios, param));
      if (data.code === 200) {
        toast.success("SuccessFully Updated!!");
      } else {
        toast.error("Failed to Update!!!");
      }
      let param2 = {
        state_id: holidayDetails?.state_current_index,
        holiday_date: holidayDetails?.year,
      };

      dispatch(CalenderHolidayListByStateAndYear(Axios, param2));
      // dispatch(CalenderHolidayList(Axios));
      // let param1 = {
      //   state_id: stateId,
      // };
      // dispatch(CalenderHolidayListByState(Axios, param1));
    } catch (error) {
      console.error("Error updating holiday calendar:", error);
    }
  };

  const column = ["S.No", "Event", "Date", "Day"];
  const BgColor = GetColor("--color-light-background");

  const [remove, setRemove] = useState(false);
  const handleConfirmRemove = async (holidayId, stateId) => {
    setRemove(false);
    let param = {
      holiday_id: holidayId,
      state_id: stateId,
    };

    try {
      const data = await dispatch(DeleteHolidayCalender(Axios, param));

      if (data.code === 200) {
        toast.success("SuccessFully Deleted!!");
      } else {
        toast.error("Failed to delete!!!");
      }

      let param2 = {
        state_id: holidayDetails?.state_current_index,
        holiday_date: holidayDetails?.year,
      };

      dispatch(CalenderHolidayListByStateAndYear(Axios, param2));

      // dispatch(CalenderHolidayList(Axios));
      // let param1 = {
      //   state_id: stateId,
      // };
      // dispatch(CalenderHolidayListByState(Axios, param1));
    } catch (error) {
      console.error("Error updating holiday calendar:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCell align="center" size="small">
                  S.No
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Event
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Date
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Day
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {Loading ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : list?.length !== 0 ? (
              SearchFilter(list, column, FilterText)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => <Row Access={item} />)
            ) : (
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            )} */}

              {allValueDetails?.year ? (
                holidayListByStateAndYear?.map((item, index) => {
                  console.log(item.holiday_name, "stateList");
                  const eventDate = new Date(item.holiday_date);
                  const dayName = eventDate.toLocaleDateString("en-US", {
                    weekday: "long",
                  });
                  const dateObject = new Date(item.holiday_date);

                  // Extract year, month, and day
                  const year = dateObject.getFullYear();
                  const month = dateObject.getMonth() + 1; // Note: Month is zero-indexed, so we add 1
                  const day = dateObject.getDate();

                  // Create a new string in the format 'YYYY-MM-DD'
                  const formattedYearDate = `${year}-${
                    month < 10 ? "0" : ""
                  }${month}-${day < 10 ? "0" : ""}${day}`;

                  // Create a new string in the format '1 Jan'
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const formattedDate = `${day} ${monthNames[month - 1]}`;

                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      key={item.holiday_id}
                      holidayId={item.holiday_id}
                      stateId={item.state_id} // Assuming you have a state_id in your item
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleConfirmRemove={handleConfirmRemove}
                      currentDate={item.holiday_date}
                      currentHolidayName={item.holiday_name}
                      dayName={dayName}
                      formattedDate={formattedDate}
                    />
                  );
                })
              ) : allValueDetails?.stateName !== "All" ? (
                holidayListByState?.map((item, index) => {
                  console.log(item.holiday_date, "stateList");
                  const eventDate = new Date(item.holiday_date);
                  const dayName = eventDate.toLocaleDateString("en-US", {
                    weekday: "long",
                  });
                  const dateObject = new Date(item.holiday_date);

                  // Extract year, month, and day
                  const year = dateObject.getFullYear();
                  const month = dateObject.getMonth() + 1; // Note: Month is zero-indexed, so we add 1
                  const day = dateObject.getDate();

                  // Create a new string in the format 'YYYY-MM-DD'
                  const formattedYearDate = `${year}-${
                    month < 10 ? "0" : ""
                  }${month}-${day < 10 ? "0" : ""}${day}`;

                  // Create a new string in the format '1 Jan'
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const formattedDate = `${day} ${monthNames[month - 1]}`;

                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      key={item.holiday_id}
                      holidayId={item.holiday_id}
                      stateId={item.state_id} // Assuming you have a state_id in your item
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleConfirmRemove={handleConfirmRemove}
                      dayName={dayName}
                      formattedDate={formattedDate}
                      currentDate={item.holiday_date}
                      currentHolidayName={item.holiday_name}
                    />
                  );
                })
              ) : holidayList?.length !== 0 ? (
                holidayList?.map((item, index) => {
                  const eventDate = new Date(item.holiday_date);
                  const dayName = eventDate.toLocaleDateString("en-US", {
                    weekday: "long",
                  });
                  const dateObject = new Date(item.holiday_date);

                  const year = dateObject.getFullYear();
                  const month = dateObject.getMonth() + 1; // Note: Month is zero-indexed, so we add 1
                  const day = dateObject.getDate();

                  // Create a new string in the format 'YYYY-MM-DD'
                  const formattedYearDate = `${year}-${
                    month < 10 ? "0" : ""
                  }${month}-${day < 10 ? "0" : ""}${day}`;

                  // Create a new string in the format '1 Jan'
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const formattedDate = `${day} ${monthNames[month - 1]}`;

                  return (
                    <Row
                      Access={item}
                      index={index + 1}
                      key={item.holiday_id}
                      holidayId={item.holiday_id}
                      stateId={item.state_id} // Assuming you have a state_id in your item
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleConfirmRemove={handleConfirmRemove}
                      dayName={dayName}
                      formattedDate={formattedDate}
                      currentDate={item.holiday_date}
                      currentHolidayName={item.holiday_name}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {remove && (
        <PopUpModal
          open={remove}
          handleClose={() => setRemove(false)}
          Title={"Proceed to Delete"}
          handleAgree={handleConfirmRemove}
          Content={"Please note that You will not be able to undo this once deleted."}
          
        />
      )} */}
        {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={SearchFilter(list, column, FilterText)?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </Stack>
    </>
  );
};

export default CalenderTable;

const Row = ({
  Access,
  index,
  holidayId,
  stateId,
  handleDelete,
  handleEdit,
  handleConfirmRemove,
  dayName,
  formattedDate,
  currentDate,
  currentHolidayName,
}) => {
  //   const [open, setOpen] = useState(false);
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});
  const [editIconStatus, setEditIconStatus] = useState(true);
  console.log(editIconStatus);
  const [isEditing, setIsEditing] = useState(true);
  const [editedEvent, setEditedEvent] = useState(Access.holiday_name);
  const [editedDate, setEditedDate] = useState(Access.holiday_date);
  console.log(currentHolidayName, "stateList");
  // useEffect(()=>{
  //   setEditedEvent(currentHolidayName)
  // },[currentHolidayName])

  console.log(editedEvent, "editedEvent");

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  const editedValue = useSelector(
    (store) => store.HolidayCalenderReducer.editedValue
  );
  console.log(editedValue, "editedValue");
  const [changedDate, setChangedDate] = useState(null);

  useEffect(() => {
    dispatch({
      type: GETHOLIDAYID,
      payload: { holiday_id: holidayId, state_id: Access.state_id },
    });
  }, []);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmRemoveClick = () => {
    handleConfirmRemove(holidayId, stateId);
    setIsConfirmDialogOpen(false);
  };
  const handleUpdateClick = () => {
    setEditIconStatus(false);
  };

  const handleSave = () => {
    setEditIconStatus(true);

    handleEdit(
      holidayId,
      stateId,
      currentDate,
      currentHolidayName,
      formattedDate
    );
  };

  const [inputValue, setInputValue] = useState(
    editedEvent || currentHolidayName
  );

  const handleEditEvent = (e, value) => {
    console.log(e.target.value);
    setEditedEvent(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: changedDate, edited_event: editedEvent },
    });
  }, [changedDate, editedEvent]);

  const Edit = () => {
    // setOpen(true);

    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleStartDateChange = (newValue) => {
    const originalDate = new Date(newValue);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so add 1
    const date = originalDate.getDate();

    // Create a formatted date string (YYYY-MM-DD)
    const formattedDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      date < 10 ? "0" : ""
    }${date}`;
    setChangedDate(formattedDateString);

    dispatch({
      type: GETEDITEDVALUE,
      payload: { edited_date: formattedDateString, edited_event: editedEvent },
    });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {index}
        </TableCell>
        {editIconStatus ? (
          <>
            <TableCell align="center" size="small" sx={{ color: "#fff" }}>
              {access?.holiday_name}
            </TableCell>
            <TableCell align="center" size="small" sx={{ color: "#fff" }}>
              {formattedDate}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <TextField
                variant="outlined"
                // value={editedEvent?editedEvent:currentHolidayName}
                value={editedEvent}
                onChange={handleEditEvent}
              />
            </TableCell>
            <TableCell>
              {/* <TextField
                
                variant="outlined"
                value={editedDate}
                onChange={handleEditDate}
              /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  //   className={Styles.picker}
                  size="small"
                  inputFormat="dd/MM/yyyy"
                  value={
                    editedValue?.edited_date
                      ? editedValue?.edited_date
                      : currentDate
                  }
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <DateTextFieldCalender
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </TableCell>
          </>
        )}
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {dayName}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.7rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DeleteIcon onClick={handleDeleteClick} />
            {editIconStatus ? (
              <EditIcon onClick={handleUpdateClick} />
            ) : (
              <button
                onClick={handleSave}
                style={{
                  background: "#1B1D23",
                  padding: "0.5rem",
                  marginLeft: "0.4rem",
                  color: "red",
                  borderColor: "red",
                }}
              >
                Save
              </button>
            )}
          </Stack>
        </TableCell>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmRemoveClick}
          holidayId={holidayId}
          stateId={stateId}
        />
      </TableRow>
    </>
  );
};
