import {
  Autocomplete,
  IconButton,
  InputBase,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { Search as SearchIcon } from "@mui/icons-material";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import {
  RunTheDealGetStateList,
  RunTheDealGetZoneList,
  RunTheDealsGetCategoryList,
  RunTheDealsGetScCategoryList,
} from "../../../actions/RunTheDeals/RunTheDeals.Action";

import { useDispatch, useSelector } from "react-redux";
import {
  RUNTHEDEALCATEGORYAUTOCOMPLETE,
  RUNTHEDEALSCAUTOCOMPLETE,
  RUNTHEDEALSTATEAUTOCOMPLETE,
  RUNTHEDEALZONEAUTOCOMPLETE,
} from "../../../utility/ReduxConstants/RunTheDealsAutoComplete";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";

const RunTheDealsAutocomplete = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const Store = useSelector((state) => state.RunTheDealsReducer);

  const [state, setState] = useState({
    id: 0,
    value: "",
    list: [],
  });
  const [zone, setZone] = useState({
    id: 0,
    value: "",
    list: [],
  });
  const [sc, setSc] = useState({
    id: 0,
    value: "",
    list: [],
  });
  const [category, setCategory] = useState({
    id: 0,
    value: "",
    list: [],
  });

  const GetStateData = async () => {
    const Data = await dispatch(RunTheDealGetStateList(Axios));
    console.log(Data.stateList, "GetStateData");

    if (Data.code === 200) {
      dispatch({
        type: RUNTHEDEALSTATEAUTOCOMPLETE,
        payload: Data?.stateList,
      });
    } else {
      toast.warning("Failed to fetch State List");
    }
  };

  const GetZoneData = async (param) => {
    const Data = await dispatch(RunTheDealGetZoneList(Axios, param));
    if (Data?.code === 200) {
      dispatch({
        type: RUNTHEDEALZONEAUTOCOMPLETE,
        payload: Data?.zoneList,
      });
    } else {
      toast.warning("Failed to fetch Zone List");
    }
  };
  const GetScData = async (param) => {
    const Data = await dispatch(RunTheDealsGetScCategoryList(Axios, param));
    console.log(Data);
    if (Data?.code === 200) {
      dispatch({
        type: RUNTHEDEALSCAUTOCOMPLETE,
        payload: Data?.body,
      });
    } else {
      toast.warning("Failed to fetch Zone List");
    }
  };
  const GetCategoryData = async (param) => {
    const Data = await dispatch(RunTheDealsGetCategoryList(Axios, param));
    console.log(Data.list, "category Data");
    if (Data?.code === 200) {
      dispatch({
        type: RUNTHEDEALCATEGORYAUTOCOMPLETE,
        payload: Data?.list,
      });
    } else {
      toast.warning("Failed to fetch Zone List");
    }
  };

  useEffect(() => {
    const NameList = Store.statelist?.map((item) => item.state_name);
    setState({
      ...state,
      list: NameList,
    });
  }, [Store.statelist]);

  useEffect(() => {
    const NameList = Store.zonelist?.map((item) => item.zone_name);
    setZone({
      ...zone,
      list: NameList,
    });
  }, [Store.zonelist]);

  useEffect(() => {
    const NameList = Store.sclist?.map((item) => item.name);
    setSc({
      ...sc,
      list: NameList,
    });
  }, [Store.sclist]);

  useEffect(() => {
    const NameList = Store.categorylist?.map((item) => item.category_name);
    setCategory({
      ...category,
      list: NameList,
    });
  }, [Store.categorylist]);

  useEffect(() => {
    GetStateData();
  }, []);

  const handleState = (e, value) => {
    const list = Store.statelist?.filter((item) => value === item.state_name);

    const { state_id, state_name } =
      list.length !== 0 ? list[0] : { state_id: 0, state_name: "" };
    setState({
      ...state,
      id: state_id,
      value: state_name,
    });
    let param = {
      state_id: state_id,
    };

    GetZoneData(param);
  };

  const handleZone = (e, value) => {
    const list = Store.zonelist?.filter((item) => value === item.zone_name);

    const { zone_id, zone_name } =
      list.length !== 0 ? list[0] : { zone_id: 0, zone_name: "" };

    setZone({
      ...zone,
      id: zone_id,
      value: zone_name,
    });

    let param = {};

    GetScData(param);
  };
  const handleSc = (e, value) => {
    const list = Store.sclist?.filter((item) => value === item.name);

    const { sc_id, name } =
      list.length !== 0 ? list[0] : { sc_id: 0, name: "" };
    setSc({
      ...sc,
      id: sc_id,
      value: name,
    });
    let param = {
      sc_id: sc_id,
    };

    GetCategoryData(param);
  };

  const handleCategory = (e, value) => {
    const list = Store.categorylist?.filter(
      (item) => value === item.category_name
    );
    const { category_id, category_name } =
      list.length !== 0 ? list[0] : { category_id: 0, category_name: "" };

    setCategory({
      ...category,
      id: category_id,
      value: category_name,
    });
  };

  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
          flex: "1 0 110px",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Stack
          direction={{ xl: "row", sm: "column" }}
          sx={{
            alignItems: "center",
            flex: "1 0 110px",
            flexDirection: { md: "row" },
            flexWrap: "wrap",
          }}
          gap={2}
        >
          <Stack minWidth={{ xs: "110px", lg: "" }}>
            <Typography>Select</Typography>
          </Stack>
          <Autocomplete
            // className={styles.AutoComplete}
            value={state?.value ?? [""]}
            options={state?.list ?? [""]}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleState}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "12rem",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="State"
                placeholder="Select State.."
              />
            )}
          />
          <Autocomplete
            // className={styles.AutoComplete}
            value={zone?.value ?? [""]}
            options={zone?.list ?? [""]}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleZone}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "12rem",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="Zone"
                placeholder="Select Zone "
              />
            )}
          />
          <Autocomplete
            // className={styles.AutoComplete}
            value={sc?.value ?? [""]}
            options={sc.list ?? [""]}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleSc}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "12rem",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="Super Category"
                placeholder="Select Super Category"
              />
            )}
          />{" "}
          <Autocomplete
            // className={styles.AutoComplete}
            value={category?.value ?? [""]}
            options={category.list ?? [""]}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleCategory}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  width: "12rem",
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                label="Category"
                placeholder="Select Category"
              />
            )}
          />
          <Stack direction={{ xs: "column", md: "row" }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                background: "#25293C !important",
                borderRadius: "50px",
                overflow: "hidden",
                height: "3rem",
                width: "15rem",
              }}
              marginTop={{ xs: "1rem", md: "0" }}
            >
              <IconButton
                sx={{
                  color: "white",
                  backgroundColor: "#3B405E",
                  "&:hover": { backgroundColor: "#3B405E" },
                  fontSize: "5rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              >
                <SearchIcon
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              </IconButton>
              <InputBase
                sx={{
                  color: "white",
                  backgroundColor: "#25293C",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                }}
                placeholder="Search By SKU"
              ></InputBase>
              <IconButton
                sx={{
                  color: "white !important",
                  background: "#25293C !important",
                  "&:hover": { background: "#25293C !important" },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack direction={{ xs: "column", md: "row" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              background: "#25293C !important",
              borderRadius: "50px",
              overflow: "hidden",
              height: "3rem",
              width: "15rem",
            }}
            marginTop={{ xs: "1rem", md: "0" }}
          >
            <IconButton
              sx={{
                color: "white",
                backgroundColor: "#3B405E",
                "&:hover": { backgroundColor: "#3B405E" },
                fontSize: "5rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              }}
            >
              <SearchIcon
                sx={{
                  fontSize: "2rem",
                }}
              />
            </IconButton>
            <InputBase
              sx={{
                color: "white",
                backgroundColor: "#25293C",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
              }}
              placeholder="Search By SKU"
            ></InputBase>
            <IconButton
              sx={{
                color: "white !important",
                background: "#25293C !important",
                "&:hover": { background: "#25293C !important" },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack> */}
      </Stack>
    </div>
  );
};

export default RunTheDealsAutocomplete;
