import { Authaxios } from "../../axios/axios";
import {
  GETACTIVEUSERS,
  GETDISTRICTLISTDASHBOARD,
  GETORDERCOUNTDELIVERY,
  GETORDERCOUNTPICKUP,
  GETSTATELISTDASHBOARD,
  GETTRANSACTIONDATA,
  GETZONELISTDASHBOARD,
} from "../../utility/ReduxConstants/DashboardReducerConstant";

export const GetStateListDashboard = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/AllstateList");
    if (data?.code === 200) {
      dispatch({ type: GETSTATELISTDASHBOARD, payload: data?.stateList });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
// GETSTATELISTDASHBOARD
// export const GetZoneListDashboard = (Axios, param) => async (dispatch) => {
//   try {
//     const { data } = await Axios.post("/AllzoneList",param);
//     console.log(data.zoneList,"zoneListDashboard")

//     if (data?.code === 200) {
//       dispatch({ type: GETZONELISTDASHBOARD, payload: data?.zoneList });
//     } else {
//       return data;
//     }
//   } catch (error) {
//     console.log(error);
//     return error?.response?.data;
//   }
// };

export const GetDistrictListDashboard = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/AlldistrictList", param);

    if (data?.code === 200) {
      dispatch({ type: GETDISTRICTLISTDASHBOARD, payload: data?.districtList });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetZoneListDashboard = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/AllzoneList", param);

    if (data?.code === 200) {
      dispatch({ type: GETZONELISTDASHBOARD, payload: data?.zoneList });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetOrdersCount = (Axios, param, type) => async (dispatch) => {
  try {
    const { data } = await Axios.post(
      "/dashboard_getDeliveryPickupData",
      param
    );
    
    if (data?.code === 200) {
      type === "delivery"
        ? dispatch({ type: GETORDERCOUNTDELIVERY, payload: data?.data[0] })
        : dispatch({ type: GETORDERCOUNTPICKUP, payload: data?.data[0] });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetActiveUsers = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post("/dashboard_getActiveUsers", param);
    
    if (data?.code === 200) {
      dispatch({ type: GETACTIVEUSERS, payload: data?.active_users });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetTransactionData = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post("/dashboard_gettransactiondata", param);
    
    if (data?.code === 200) {
      dispatch({ type: GETTRANSACTIONDATA, payload: data?.body[0] });
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetSkuZoneListDashboard = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/AllzoneList", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetSkuDistrictListDashboard = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/AlldistrictList", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const GetSkuSuperCategoryListDashboard =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("/superCategoryList", param);
      return data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
export const GetSkuCategoryListDashboard =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("/skuCategoryList", param);
      return data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
export const SKULISTDASHBOARD = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/dashboard_skuAnalytics", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export const SCDATADASHBOARD = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("/superCategoryList", param);
    return data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};
