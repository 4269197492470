import { GETLAUNCHZONELIST } from "../../utility/ReduxConstants/LaunchReducerConstant";

export const GetLaunchSuperCategoryList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('superCategoryList',param);
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchSuperCategoryList")
         return error?.response?.data
    }
}
export const GetLaunchStateList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('AllstateList',param);
        
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchStateList")
         return error?.response?.data
    }
}

export const GetLaunchDistrictList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('AlldistrictList',param);
        
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchStateList")
         return error?.response?.data
    }
}
export const GetLaunchZoneList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('AllzoneList',param);
         console.log(data,"superCategoryList")
         if (data?.code === 200) {
            // setZoneListLoader(false);
            dispatch({ type: GETLAUNCHZONELIST, payload: data?.zoneList });
          }
         return data
    } catch (error) {

        console.log(error?.response?.data,"GetLaunchZoneList")

         return error?.response?.data
    }
}
export const GetLaunchList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('launchList',param);
         console.log(data,"superCategoryList")
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchList")

         return error?.response?.data
    }
}
export const GetLaunchUserList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('launchUserList',param);
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchUserList")

         return error?.response?.data
    }
}
export const GetLaunchZoneCount =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('launchListZoneCount',param);
         return data
    } catch (error) {
        console.log(error?.response?.data,"GetLaunchZoneCount")

         return error?.response?.data
    }
}
export const UpdateLaunchStatusBySc =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('launchScByZone',param);
         return data
    } catch (error) {
        console.log(error?.response?.data,"UpdateLaunchStatusBySc")

         return error?.response?.data
    }
}

export const LaunchKycList =(Axios,param)=>async(dispatch)=>{
    try {
         const {data} = await Axios.post('kycUserQuotedList',param);
         return data
    } catch (error) {
        console.log(error?.response?.data,"LaunchKycList")

         return error?.response?.data
    }
}

