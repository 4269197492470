import React, { useState, useEffect } from "react";
import Styles from "./styles/index.module.css";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";
import CarouselCategory from "./carousel";
import {
  GetCategoryPageData,
  GetAllSuperCategoryList,
  GetCategoryListBySCId,
  GetCategoryDetailsId,
} from "../../../../actions/category/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import "./styles/category.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { Autocomplete, IconButton, Stack } from "@mui/material";
import { CardWrapper } from "../../CardWrapper";
import AddWrapperComponent from "../AddWrapperComponent";
import {
  GETCATEGORYDETAILS,
  SELECTEDSUPERCATEGORY,
} from "../../../../utility/ReduxConstants/CategoryReducerConstant";
import {
  AutocompleteTextField,
  AutocompleteTextFieldCurved,
  SearchInputField,
} from "../../../../utility/TextField";
import { defaultImg } from "../../../../utility/BaseUrl";
import CategoryListTwo from "./CategoryListTwo";
import CategoryList from "./categoryList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetSubCatCategoryList,
  GetSubCatSuperCategoryList,
  GetSubCategoryList,
} from "../../../../actions/subcategory/subcategory.action";
import {
  GETSUBCATCATEGORYLIST,
  GETSUBCATEGORYLIST,
  GETSUBCATSELECTEDCATEGORY,
  GETSUBCATSELECTEDSC,
  GETSUBCATSUPERCATEGORYLIST,
} from "../../../../utility/ReduxConstants/SubCategory.Reducer.Constant";
import { CheckSmallError } from "../../../../utility/ErrorCheck";
const SubCategory = () => {
  const Axios = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const store = useSelector((state) => state.SubCategoryReducer);
  const column = ["s_category_name"];
  const [text, setText] = useState("");
  const [sc, setSc] = useState({
    list: [],
    id: 0,
    value: "",
  });
  const [category, setCategory] = useState({
    list: [],
    id: 0,
    value: "",
  });
  const dispatch = useDispatch();

  const SelectedSc = (data) => {
    dispatch({
      type: GETSUBCATSELECTEDSC,
      payload: data,
    });
  };
  const SelectedCategory = (data) => {
    dispatch({
      type: GETSUBCATSELECTEDCATEGORY,
      payload: data,
    });
  };

  const GetSupCategoryList = async () => {
    const Data = await dispatch(GetSubCatSuperCategoryList(Axios));

    if (Data.code == 200) {
      dispatch({
        type: GETSUBCATSUPERCATEGORYLIST,
        payload: Data?.body,
      });
      let param = {
        sc_id:
          Data?.body && Data?.body?.length !== 0 ? Data?.body[0]?.sc_id : 0,
      };
      SelectedSc(Data?.body && Data?.body?.length !== 0 ? Data?.body[0] : {});
      GetCategoryList(param);
    } else {
      toast.error("Failed to fetch Super Category List");
    }
  };

  const GetCategoryList = async (param) => {
    const Data = await dispatch(GetSubCatCategoryList(Axios, param));
    if (Data.code == 200) {
      dispatch({
        type: GETSUBCATCATEGORYLIST,
        payload: Data?.list,
      });
      let paramTwo = {
        sc_id: param.sc_id,
        category_id:
          Data?.list && Data?.list?.length !== 0
            ? Data?.list[0]?.category_id
            : 0,
      };
      SelectedCategory(
        Data?.list && Data?.list?.length !== 0 ? Data?.list[0] : {}
      );
      GetSubCategoryList_(paramTwo);
    } else {
      toast.error("Failed to fetch Category List");
    }
  };
  const GetSubCategoryList_ = async (param) => {
    ////console.log(param,"GetSubCategoryList_")
    const Data = await dispatch(GetSubCategoryList(Axios, param));
    //console.log(Data,"GetSubCategoryList_",param)
    if (Data.code == 200) {
      dispatch({
        type: GETSUBCATEGORYLIST,
        payload: Data?.list,
      });
    } else {
      toast.error("Failed to fetch Sub Category List");
    }
  };

  React.useEffect(() => {
    GetSupCategoryList();
  }, []);

  useEffect(() => {
    let ScList = store?.sc_list?.map((item) => item.name);
    setSc({
      list: ScList,
      id:
        store?.sc_list && store?.sc_list?.length !== 0
          ? store?.sc_list[0]?.sc_id
          : 0,
      value:
        store?.sc_list && store?.sc_list?.length !== 0
          ? store?.sc_list[0]?.name
          : "",
    });
  }, [store?.sc_list]);

  useEffect(() => {
    let categoryList = store?.category_list?.map((item) => item.category_name);
    setCategory({
      list: categoryList,
      id:
        store?.category_list && store?.category_list?.length !== 0
          ? store?.category_list[0]?.category_id
          : 0,
      value:
        store?.category_list && store?.category_list?.length !== 0
          ? store?.category_list[0]?.category_name
          : "",
    });
  }, [store?.category_list]);

  let handleOpen = (state) => {
    setOpen(state);
  };

  const handleSuperCategoryChange = (event, value) => {
    if (!CheckSmallError(value) && value !== "") {
      let SC = {};
      for (let i = 0; i < store.sc_list.length; i++) {
        if (value === store.sc_list[i].name) {
          SC = store.sc_list[i];
          break;
        }
      }
      SelectedSc(SC);
      setSc({
        ...sc,
        id: SC.sc_id,
        value: SC.name,
      });

      let param = {
        sc_id: SC.sc_id,
      };
      GetCategoryList(param);
    }
  };

  const handleCategoryChange = (event, value) => {
    if (!CheckSmallError(value) && value !== "") {
      let Category = {};
      for (let i = 0; i < store.category_list.length; i++) {
        if (value === store.category_list[i].category_name) {
          Category = store.category_list[i];
          break;
        }
      }
      SelectedCategory(Category);
      setCategory({
        ...category,
        id: Category.category_id,
        value: Category.category_name,
      });

      let paramTwo = {
        sc_id: sc.id,
        category_id: Category.category_id,
      };
      GetSubCategoryList_(paramTwo);
    }
  };

  // //console.log(store,sc,category)

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CardWrapper>
        <Stack direction="column" gap={2}>
          <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
            <Stack flex={1}>
              <AddWrapperComponent
                formOpen={open}
                handleFormOpen={handleOpen}
                Title={"Add Sub Category"}
              >
                <AddCategory />
              </AddWrapperComponent>
            </Stack>
            <Stack
              flex={2.3}
              sx={{ padding: "20px 5px 10px 5px", color: "white" }}
            >
              <Stack gap={1} padding="20px 0px">
                {/* <Typography variant="h6">Search User</Typography> */}
                <Stack direction={{ xs: "column", md: "row" }} gap={2}>
                  <Autocomplete
                    value={sc.value}
                    options={sc.list}
                    onChange={handleSuperCategoryChange}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <AutocompleteTextFieldCurved
                        {...params}
                        sx={{
                          minWidth: { xs: "200px", md: "250px", lg: "300px" },
                        }}
                        size="small"
                        variant="outlined"
                        label="Super Category"
                      />
                    )}
                  />
                  <Autocomplete
                    value={category.value}
                    options={category.list}
                    onChange={handleCategoryChange}
                    className={Styles.autocomplete}
                    renderInput={(params) => (
                      <AutocompleteTextFieldCurved
                        {...params}
                        sx={{
                          minWidth: { xs: "200px", md: "250px", lg: "300px" },
                        }}
                        size="small"
                        variant="outlined"
                        label="Category"
                      />
                    )}
                  />
                </Stack>

                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  sx={{
                    border: "1px solid white",
                    borderRadius: "20px",
                    width: { xs: "100%", lg: "78%" },
                  }}
                  gap={1}
                >
                  <IconButton
                    // className={Styles.Icon}
                    sx={{
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "gray",
                        transition: "0.2s linear",
                      },
                    }}
                  >
                    <SearchIcon sx={{ color: "black !important" }} />
                  </IconButton>
                  <SearchInputField
                    sx={{
                      flex: { xs: 1, md: 1 },
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      color: "white",
                    }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Search Sub Category Name"
                  />
                  <IconButton
                    // className={Styles.Icon}
                    onClick={() => setText("")}
                  >
                    <CloseIcon sx={{ color: "white !important" }} />
                  </IconButton>
                </Stack>
              </Stack>
              {open && <CategoryList text={text} />}
            </Stack>
          </Stack>
          {!open && <CategoryListTwo text={text} open={open} />}
        </Stack>
      </CardWrapper>
    </>
  );
};

export default SubCategory;
