import {
  GETALLNAMEIDVALUE,
  GETALLVIDEODETAILS,
  GETALLWATCHCOUNTBYVIDEOTITLE,
  GETDISTRICTBYSTATEID,
  GETDISTRICTNAMEBYSTATEID,
  GETSPECIFICWATCHCOUNT,
  GETUSERVIDEODETAILSBYZONE,
  GETVIDEOANALYTICSDISTRICTLIST,
  GETVIDEOANALYTICSDISTRICTNAME,
  GETVIDEOANALYTICSSTATELIST,
  GETVIDEOANALYTICSSTATENAME,
  GETVIDEOANALYTICSZONELIST,
  GETVIDEOANALYTICSZONENAME,
  GETVIDEODETAILSBYZONE,
  GETVIDEODETAILSBYZONEANDVIDEO,
  GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT,
  GETVIDEONAMEBYZONE,
  GETWATCHCOUNTBYVIDEOTITLE,
  GETZONEBYDISTRICTID,
  GETZONENAMEBYDISTRICTID,
} from "../../utility/ReduxConstants/VideoAnalyticsReducerConstant";

const initialState = {
  videoAnalyticsAllStateList: null,
  videoAnalyticsAllStateName: null,
  videoAnalyticsAllDistrictList: null,
  videoAnalyticsAllDistrictName: null,
  videoAnalyticsAllZoneList: null,
  videoAnalyticsAllZoneName: null,
  videoAnalyticsdistrictListByStateId: null,
  videoAnalyticsDistrictNameByStateId: null,
  videoAnalyticsZoneListByDistrictId: null,
  videoAnalyticsZoneNameByDistrictId: null,
  videoAnalyticsVideoDetailsByZone: null,
  videoAnalyticsVideoNameByZone: null,
  videoAnalyticsUserVideoDetailsByZone: null,
  videoAnalyticsAllNameIdDetails: null,
  videoAnalyticsAllVideoDetails: null,
  videoAnalyticsWatchCountByVideoName: null,
  videoAnalyticsAllWatchCountByVideoName: null,
  videoAnalyticsSpecificWatchCount: null,
  videoAnalyticsUserVideoDetailsByZoneAndVideo: null,
  videoAnalyticsUserVideoDetailsByZoneVideoAndWatchCount: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETVIDEOANALYTICSSTATELIST:
      return {
        ...state,
        videoAnalyticsAllStateList: action.payload,
      };
    case GETVIDEOANALYTICSSTATENAME:
      return {
        ...state,
        videoAnalyticsAllStateName: action.payload,
      };

    case GETVIDEOANALYTICSDISTRICTLIST:
      return {
        ...state,
        videoAnalyticsAllDistrictList: action.payload,
      };

    case GETVIDEOANALYTICSDISTRICTNAME:
      return {
        ...state,
        videoAnalyticsAllDistrictName: action.payload,
      };

    case GETDISTRICTNAMEBYSTATEID:
      return {
        ...state,
        videoAnalyticsDistrictNameByStateId: action.payload,
      };
    case GETZONEBYDISTRICTID:
      return {
        ...state,
        videoAnalyticsZoneListByDistrictId: action.payload,
      };
    case GETDISTRICTBYSTATEID:
      return {
        ...state,
        videoAnalyticsdistrictListByStateId: action.payload,
      };

    case GETVIDEOANALYTICSZONELIST:
      return {
        ...state,
        videoAnalyticsAllZoneList: action.payload,
      };
    case GETVIDEOANALYTICSZONENAME:
      return {
        ...state,
        videoAnalyticsAllZoneName: action.payload,
      };

    case GETZONENAMEBYDISTRICTID:
      return {
        ...state,
        videoAnalyticsZoneNameByDistrictId: action.payload,
      };

    case GETVIDEODETAILSBYZONE:
      return {
        ...state,
        videoAnalyticsVideoDetailsByZone: action.payload,
      };

    case GETUSERVIDEODETAILSBYZONE:
      return {
        ...state,
        videoAnalyticsUserVideoDetailsByZone: action.payload,
      };

    case GETVIDEODETAILSBYZONEANDVIDEO:
      return {
        ...state,
        videoAnalyticsUserVideoDetailsByZoneAndVideo: action.payload,
      };
    case GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT:
      return {
        ...state,
        videoAnalyticsUserVideoDetailsByZoneVideoAndWatchCount: action.payload,
      };
    case GETVIDEONAMEBYZONE:
      return {
        ...state,
        videoAnalyticsVideoNameByZone: action.payload,
      };
    case GETALLNAMEIDVALUE:
      return {
        ...state,
        videoAnalyticsAllNameIdDetails: action.payload,
      };
    case GETALLVIDEODETAILS:
      return {
        ...state,
        videoAnalyticsAllVideoDetails: action.payload,
      };

    case GETWATCHCOUNTBYVIDEOTITLE:
      return {
        ...state,
        videoAnalyticsWatchCountByVideoName: action.payload,
      };

    case GETALLWATCHCOUNTBYVIDEOTITLE:
      return {
        ...state,
        videoAnalyticsAllWatchCountByVideoName: action.payload,
      };
    case GETSPECIFICWATCHCOUNT:
      return {
        ...state,
        videoAnalyticsSpecificWatchCount: action.payload,
      };
    default:
      return state;
  }
};
