export const GetMapData = (AXIOS, URL, param) => async (dispatch) => {
  try {
    
    const data = await AXIOS.post(URL, param);
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const GetMapUserData = (AXIOS, URL, param) => async (dispatch) => {
  try {
    
    const data = await AXIOS.post(URL, param);
    
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const GetMapParterLocationDataList =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const GetMapAssignPartnerData =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const GetMapMyselfDataAction =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const Get_assign_delivery_boy_all_check_Action =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const Get_assign_delivery_boy_Action =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const Get_assign_delivery_boy_All_Action =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
export const Get_remove_delivery_boy_from_order_Action =
  (AXIOS, URL, param) => async (dispatch) => {
    try {
      
      const { data } = await AXIOS.post(URL, param);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
