import { Authaxios, AxiosPrivate } from '../../axios/axios';
import {  LISTSTART, GETZONELIST } from '../../utility/ReduxConstants/LoginReducerConstant';


// var Obj = JSON.parse(localStorage.getItem('Login'));
// const {id} = Obj
// dispatch({type:GETSTATELIST,payload:data.data.body})
// GetStateList

const id=1;

export const GetStateListInitial = () =>async(dispatch)=>{
    //process.env.REACT_APP_USERNAME
    try {
        let param = {
            "admin_id":parseInt(id)
        }

         const data =await Authaxios.post('stateList',param);
        
  
         if(data.data.code && data.data.code===200){
            
             dispatch({type:LISTSTART,payload:data.data.body})
         }

    } catch (error) {
        console.log(error)
    }
}


export const GetZoneList = (param) =>async(dispatch)=>{
      
    try {
        param.admin_id =parseInt(id);
         const data =await Authaxios.post('zoneList',param);
  
         if(data.data.code && data.data.code===200){
            
             dispatch({type:GETZONELIST,payload:data.data.body})
         }

    } catch (error) {
        console.log(error)
    }
}

export const AuthenticateUser =(param)=>async(dispatch)=>{
    try {
         const {data} = await Authaxios.post('vitaranLiveAuthentication',param);
  
         return data

    } catch (error) {
        console.log(error.message);
        return {
            "code": 500,
            "message": "Internal Server Error",
            "error":error
        }
    }
}



export const UserLogin = (param)=>async(dispatch)=>{
    try {
        const {data} = await Authaxios.post('adminLogin',param);
        return data
   } catch (error) {
       console.log(error.message);
       return error?.response?.data
   }
}
export const SendOtpAction = (param)=>async(dispatch)=>{
    try {
        const {data} = await Authaxios.post('forgotPassword',param);
 
        return data

   } catch (error) {
       console.log(error.message);
       return error?.response?.data
   }
}
export const VerifyOtpAction = (param)=>async(dispatch)=>{
    try {
        const {data} = await Authaxios.post('verifyOtp',param);
 
        return data

   } catch (error) {
       console.log(error.message);
       return error?.response?.data
   }
}
export const ChangePasswordAction = (param)=>async(dispatch)=>{
    try {
        const {data} = await Authaxios.post('changePassword',param);
        
        return data

   } catch (error) {
       console.log(error.message);
       return error?.response?.data
   }
}