import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ConfirmationDialog from "../HolidayCalender/ConfirmationDialog";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import { StyledTableCell } from "../../../utility/TextField";
import Styles from "../Zone/styles/addZone.module.css";
import {
  VideoAnalyticsUserVideoDetailsByZone,
  videoAnalyticsAllVideoDetails,
} from "../../../actions/VideoAnalytics/VideoAnalyticsAction";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const VideoAnalyticsTable = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const userVideoDetailsByZone = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsUserVideoDetailsByZone
  );
  const AllNameIdValue = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsAllNameIdDetails
  );
  const AllVideoDetails = useSelector(
    (store) => store.videoAnalyticsReducer.videoAnalyticsAllVideoDetails
  );
  const videoByZoneAndVideo = useSelector(
    (store) =>
      store.videoAnalyticsReducer?.videoAnalyticsUserVideoDetailsByZoneAndVideo
  );

  const videoByZoneVideoAndWatchCount = useSelector(
    (store) =>
      store.videoAnalyticsReducer
        ?.videoAnalyticsUserVideoDetailsByZoneVideoAndWatchCount
  );
  console.log(userVideoDetailsByZone, "districtByStateId");

  useEffect(() => {
    let param = {
      zone_name: AllNameIdValue?.selected_zoneName,
    };
    dispatch(VideoAnalyticsUserVideoDetailsByZone(Axios, param));
  }, [AllNameIdValue?.selected_zoneName]);

  useEffect(() => {
    if (
      AllNameIdValue?.state_id != null &&
      AllNameIdValue?.district_id != null &&
      AllNameIdValue?.selected_zoneName
    ) {
      dispatch(videoAnalyticsAllVideoDetails(Axios));
    }
  }, [
    AllNameIdValue?.state_id,
    AllNameIdValue?.district_id,
    AllNameIdValue?.selected_zoneName,
  ]);

  console.log(userVideoDetailsByZone, "districtList");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#1B1D23 !important",
          padding: "10px",
          marginTop: "1rem",
        }}
      >
        <TableContainer
          sx={{
            minHeight: 496,
            maxHeight: 496,
            minWidth: "100%",
            backgroundColor: "#1B1D23",
            xs: { minWidth: 100 },
            sm: { minWidth: 300 },
            md: { minWidth: 300 },
          }}
          // component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23", width: "100% " }}
              size="small"
            >
              <TableRow
                className={Styles.head}
                sx={{ backgroundColor: "#1B1D23" }}
                size="small"
              >
                <StyledTableCell align="center" size="small">
                  S.No
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Name
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Mobile
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Zone
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Video Title
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Watch Count
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Watch Time
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {Loading ? (
          <TableRow>
            <TableCell colSpan={12} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : list?.length !== 0 ? (
          SearchFilter(list, column, FilterText)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => <Row Access={item} />)
        ) : (
          <TableRow>
            <TableCell>No Data</TableCell>
          </TableRow>
        )} */}

              {/* {AllNameIdValue?.selected_zoneName === "All" ? (
                AllVideoDetails?.map((item, index) => {
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} />
                  );
                })
              ) : userVideoDetailsByZone?.length !== 0 ? (
                userVideoDetailsByZone?.map((item, index) => {
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>No Data</TableCell>
                </TableRow>
              )} */}

              {AllNameIdValue?.selected_zoneName === "All" ? (
                AllVideoDetails?.map((item, index) => {
                  // Given date and time string
                  
                  const formattedDateTimes =item.watch_time.map((watchTime)=>{
                     let dateString = watchTime;

                    // Create a Date object
                    const dateObject = new Date(dateString);
  
                    // Format the date
                    const formattedDate = dateObject.toLocaleString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    });
  
                    // Format the time
                    const formattedTime = dateObject.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "UTC",
                    });
  
                    // Combine date and time
                     return `${formattedDate} ${formattedTime}`;
  
                    // Output the result
                    
  
                    
                  })
                  
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} readableFormat={formattedDateTimes}/>
                  );
                })
              ) : AllNameIdValue?.state_id &&
                AllNameIdValue?.district_id &&
                AllNameIdValue?.selected_zoneName &&
                AllNameIdValue?.video_id &&
                AllNameIdValue?.selected_watchCount ? (
                videoByZoneVideoAndWatchCount?.map((item, index) => {
                  let readableFormat=[]
                  const formattedDateTimes =item.watch_time.map((watchTime)=>{
                     let dateString = watchTime;

                    // Create a Date object
                    const dateObject = new Date(dateString);
  
                    // Format the date
                    const formattedDate = dateObject.toLocaleString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    });
  
                    // Format the time
                    const formattedTime = dateObject.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "UTC",
                    });
  
                    // Combine date and time
                     return `${formattedDate} ${formattedTime}`;
  
                    // Output the result
                    
  
                    
                  })
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} readableFormat={formattedDateTimes}/>
                  );
                })
              ) : AllNameIdValue?.state_id &&
                AllNameIdValue?.district_id &&
                AllNameIdValue?.selected_zoneName &&
                AllNameIdValue?.video_id ? (
                videoByZoneAndVideo?.map((item, index) => {
                  
                  const formattedDateTimes =item.watch_time.map((watchTime)=>{
                     let dateString = watchTime;

                    // Create a Date object
                    const dateObject = new Date(dateString);
  
                    // Format the date
                    const formattedDate = dateObject.toLocaleString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    });
  
                    // Format the time
                    const formattedTime = dateObject.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "UTC",
                    });
  
                    // Combine date and time
                     return `${formattedDate} ${formattedTime}`;
  
                    // Output the result
                    
  
                    
                  })
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} readableFormat={formattedDateTimes}/>
                  );
                })
              ) : userVideoDetailsByZone?.length !== 0 ? (
                userVideoDetailsByZone?.map((item, index) => {
                  
                  const formattedDateTimes =item.watch_time.map((watchTime)=>{
                     let dateString = watchTime;

                    // Create a Date object
                    const dateObject = new Date(dateString);
  
                    // Format the date
                    const formattedDate = dateObject.toLocaleString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    });
  
                    // Format the time
                    const formattedTime = dateObject.toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "UTC",
                    });
  
                    // Combine date and time
                     return `${formattedDate} ${formattedTime}`;
  
                    // Output the result
                    
  
                    
                  })
                  return (
                    <Row Access={item} index={index + 1} key={item.vr_id} readableFormat={formattedDateTimes}/>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {remove && (
    <PopUpModal
      open={remove}
      handleClose={() => setRemove(false)}
      Title={"Proceed to Delete"}
      handleAgree={handleConfirmRemove}
      Content={"Please note that You will not be able to undo this once deleted."}
      
    />
  )} */}
        {/* <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={SearchFilter(list, column, FilterText)?.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  /> */}
      </Stack>
    </>
  );
};

export default VideoAnalyticsTable;

const Row = ({ Access, index, readableFormat, dayName }) => {
  //   const [open, setOpen] = useState(false);
  //   const Axios = useAxiosPrivate();
  const [access, setAccess] = useState({});

  useEffect(() => {
    setAccess(Access);
  }, [Access]);
  const Axios = useAxiosPrivate();

  const dispatch = useDispatch();

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {index}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access.first_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access.mobile_no}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access.video_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access.watch_count}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {access &&
            access.watch_time &&
            access.watch_time[0] !== undefined && (
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "0.5rem",
                }}
              >
                {readableFormat?.[readableFormat.length-1]}
                <Tooltip
                  title={
                    <Stack sx={{ display: "flex", flexDirection: "column" }}>
                      {readableFormat?.map((time, index) => (
                        <span key={index}>{time}</span>
                      ))}
                    </Stack>
                  }
                >
                  <InfoIcon sx={{ fontSize: "1rem", cursor: "pointer" }} />
                </Tooltip>
              </Stack>
            )}
        </TableCell>

        <ConfirmationDialog
        // open={isConfirmDialogOpen}
        // onClose={handleConfirmDialogClose}
        // onConfirm={handleConfirmRemoveClick}
        // holidayId={holidayId}
        // stateId={stateId}
        />
      </TableRow>
    </>
  );
};
