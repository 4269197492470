import { TableCell, TextField, tableCellClasses } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const CssTextField = styled(TextField)({
  "& label": {
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
  },
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableCellWallet = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#323546",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const DateTextField = styled(TextField)({
  backgroundColor: "black",
  "& label": {
    display: "none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
  },
});

export const DateTextFieldCalender = styled(TextField)({
  backgroundColor: "#323546",
  "& label": {
    display: "none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    // borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
  },
});

export const DateTextFieldCalenderWallet = styled(TextField)({
  backgroundColor: "black",
  height:"2.4rem",
  width:"14rem",
  "& label": {
    display: "none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    // borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    ".MuiSvgIcon-root": {
      fill: "white",  /* Change this to the desired color */
    },
  },
});


export const NormalTextField = styled(TextField)({
  backgroundColor: "transparent",
  "& label": {
    display: "none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
  },
});

export const AutocompleteTextField = styled(TextField)({
  backgroundColor: "transparent",
  color: "white",
  "& label": {
    //  display:"none",
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.5,
      marginBottom: "70px",
    },
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    fieldset: {
      borderColor: "white !important",
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius:"25px"
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#ffffff",
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-disabled label": {
      color: "white !important",
    },
    "&label.Mui-disabled": {
      color: "white !important",
    },
    "&.MuiInputLabel-root.Mui-disabled": {
      color: "white !important",
    },
    "&.Mui-disabled": {
      color: "#ffffff !important",
    },
    "&.MuiInputLabel-animated.Mui-disabled": {
      color: "#ffffff !important",
    },
  },
});

export const AutocompleteTextFieldCurved = styled(TextField)({
  
  borderRadius: "18px",
  boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  color: "white",
  "& label": {
    //  display:"none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      borderRadius: "25px",
    },
  },
});

export const AutocompleteTextFieldNormal = styled(TextField)({
  boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  color: "white",
  "& label": {
    //  display:"none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    // borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      // borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "white",
      // borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      // borderRadius: "25px",
    },
  },
});

export const SearchInputField = styled(TextField)({
  backgroundColor: "transparent",
  padding: 0,
  margin: 0,
  color: "white",
  "& label": {
    display: "none",
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      margin: 0,
      padding: 0,
    },
    "&:hover fieldset": {
      border: "none",
      margin: 0,
      padding: 0,
    },
    "&.Mui-focused fieldset": {
      border: "none",
      margin: 0,
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
});
