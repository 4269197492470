import { Authaxios } from "../../axios/axios";
import {
  KYCCOUNTBYSTATEZONEREDUXCONSTANT,
  KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT,
  KYCLISTBYSTATEZONEREDUXCONSTANT,
  KYCSTATELISTREDUXCONSTANT,
  KYCZONELISTREDUXCONSTANT,
  GETKYCSUPERCATEGORYDATA,
  GETKYCCATEGORYDATA,
  KYCDISTRICTLISTREDUXCONSTANT,
} from "../../utility/ReduxConstants/KycReducerConstant";

export const GetKycStateList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AllstateList");
    
    if (data.code === 200) {
      dispatch({ type: KYCSTATELISTREDUXCONSTANT, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetKycDistrictList = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post("AlldistrictList",param);
    
    if (data.code === 200) {
      dispatch({ type: KYCDISTRICTLISTREDUXCONSTANT, payload: data.districtList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetKycZoneList = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post("AllzoneList",param);
    
    if (data.code === 200) {
      dispatch({ type: KYCZONELISTREDUXCONSTANT, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetKycCountByStateAndZone = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("kycCountByStatusAndZone", param);

    console.log(data,"GetkycCountByStateAndZone")
    
    if (data.code === 200) {
      dispatch({
        type: KYCCOUNTBYSTATEZONEREDUXCONSTANT,
        payload: data?.body,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetKycListByStateAndZone = (Axios, param) => async (dispatch) => {
  try {
    
    const { data } = await Axios.post("kycListByStatusAndZone", param);
    console.log(data,"rfdijfejijf")
    

    if (data.code === 200) {
      dispatch({
        type: KYCLISTBYSTATEZONEREDUXCONSTANT,
        payload: data?.list,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const GetKycDetailsByStateAndZoneByKycId =
  (Axios, param) => async (dispatch) => {
    
    try {
      const { data } = await Axios.post("kycDetailsByStatusAndZone", param);
      

      if (data.code === 200) {
        dispatch({
          type: KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT,
          payload:  data?.detail,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const MakeVitaranVerified = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("vitaranVerified", param);

    if (data.code === 200) {
      return {
        state: true,
        message: data.message,
        vitaran_verified: data.vitaran_verified,
      };
    } else {
      return {
        state: false,
        message: data.message,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      state: false,
      message: error,
    };
  }
};

export const MakeKycVerified = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("kycApproval", param);

    if (data.code === 200) {
      return {
        state: true,
        message: data.message,
      };
    } else {
      return {
        state: false,
        message: data.message,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      state: false,
      message: error,
    };
  }
};

export const RejectKyc = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("kycReject", param);

    if (data.code === 200) {
      return {
        state: true,
        message: data.message,
      };
    } else {
      return {
        state: false,
        message: data.message,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      state: false,
      message: error,
    };
  }
};
export const GetKycSCList = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post("superCategoryList");
    if (data.code === 200) {
      dispatch({ type: GETKYCSUPERCATEGORYDATA, payload: data.body });
    }
  } catch (error) {
    console.log(error, "superCategoryList");
  }
};
export const GetKycCategoryList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("skuCategoryList", param);
    if (data.code === 200) {
      dispatch({ type: GETKYCCATEGORYDATA, payload: data.list });
    }
  } catch (error) {
    console.log(error);
  }
};
