import React from "react";
import { Stack, Typography, Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  AutocompleteTextFieldNormal,
  DateTextFieldCalenderWallet,
} from "../../../../utility/TextField";

const WalletUserTransactioinDropdown = () => {
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent:"space-between",
          marginTop:"3rem"
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            flex={1}
            direction={{ xs: "column", sm: "row" }}
            
            padding={1}
            
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width:"40rem"
            }}
          >
            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.stateName}
              // options={stateListNames}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleState}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "90%",
                    backgroundColor: "#25293C",

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="State"
                  placeholder="Select State.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.year}
              // options={YEARLIST}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleYear}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "90%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="District"
                  placeholder="Select District.."
                />
              )}
            />

            <Autocomplete
              sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
              // value={obj.year}
              // options={YEARLIST}
              getOptionLabel={(option) => option}
              size="small"
              // onChange={handleYear}
              renderInput={(params) => (
                <AutocompleteTextFieldNormal
                  {...params}
                  sx={{
                    width: "90%",
                    backgroundColor: "#25293C",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // border: "none",
                        borderColor: "white",
                      },
                    },
                  }}
                  variant="outlined"
                  label="Zone"
                  placeholder="Select Zone.."
                />
              )}
            />

            <Stack>
              <button style={{ background: "#797979",width:"6rem",height:"2.5rem",color:"#FFFFFF" }}>Filters</button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            dispaly: "flex",
            flexDirection: "row",
            
            gap: "1rem",
            alignItems: "center",
          }}
        >
          
          <Typography sx={{ color: "white" }}>From: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              // value={
              //   editedValue?.edited_date
              //     ? editedValue?.edited_date
              //     : currentDate
              // }
              // onChange={handleStartDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Typography sx={{ color: "white" }}>To: </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              //   className={Styles.picker}
              size="small"
              inputFormat="dd/MM/yyyy"
              // value={
              //   editedValue?.edited_date
              //     ? editedValue?.edited_date
              //     : currentDate
              // }
              // onChange={handleStartDateChange}
              renderInput={(params) => (
                <DateTextFieldCalenderWallet
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default WalletUserTransactioinDropdown;
