import React from "react";
import GoldLogo from "../../../../assets/Wallet/goldCoin.png";
import SilverLogo from "../../../../assets/Wallet/silverCoin.png";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const PartnerWalletCoinDetails = () => {
  const vpCoinDetails= useSelector(
    (store) => store.PartnerWalletManagementReducer.walletVPDetails
  );
  console.log(vpCoinDetails, "partner74545");
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.8rem",
          marginLeft: "0.8rem",
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: "1.5rem",
            font: "roboto",
          }}
        >
          Coin Management
        </Typography>
        <Link to="/home/pending">
          {" "}
          <Stack>
            <button
              style={{
                background: "#DC1D13",
                padding: "0.5rem",
                color: "#FFFFFF",
                border: "0.1rem solid #DC1D13",
                cursor: "pointer",
              }}
            >
              Pending Approvals {"("+vpCoinDetails?.coin_request_count+")"??0}
            </button>
          </Stack>
        </Link>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.5rem",
          background: "#323546",
          padding: "1rem",
        }}
      >
        <Typography sx={{ color: "#FFFFFF", fontSize: "0.8rem" }}>
          Wallet Balance:
        </Typography>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={GoldLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
            {vpCoinDetails?.vp_gold_balance??0}
          </Typography>
          <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={SilverLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
          {vpCoinDetails?.vp_silver_coin??0} (GST)
          </Typography>
          <Typography sx={{ color: "#D4D4D4" }}>|</Typography>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Stack sx={{ width: "1.5rem" }}>
            <img src={SilverLogo} />
          </Stack>
          <Typography sx={{ fontSize: "0.8rem", color: "#FFFFFF" }}>
          {vpCoinDetails?.vp_silver_balance_free??0}(Free)
          </Typography>
        </Stack>

        <Link to="/home/transaction/history">
          <Stack>
            <Typography
              sx={{
                color: "white",
                fontWeight: 500,
                textDecoration: "underline",
                fontSize: "",
              }}
            >
              View Transaction History
            </Typography>
          </Stack>
        </Link>
      </Stack>
    </Stack>
  );
};

export default PartnerWalletCoinDetails;
