import React, { useState, useEffect } from "react";
import { Stack, Autocomplete } from "@mui/material";
import { AutocompleteTextFieldNormal } from "../../../../utility/TextField";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import {
  PartnerWalletAllDistrictList,
  PartnerWalletAllSCList,
  PartnerWalletAllStateList,
  PartnerWalletAllZoneList,
  PartnerWalletApprovalList,
} from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";
import { STATUSLIST } from "../../../../utility/constants/constants";
import { GETPARTNERDROPDOWNLIST } from "../../../../utility/ReduxConstants/PartnerWalletManagementReducerConstant";

const PartnerPendingDropdown = () => {
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const [obj, setObj] = useState({
    state_id: null,
    state_default_name: null,
    zone_default_name: null,
    district_default_name: null,
    sc_default_name: null,
    status: null,
  });

  const [stateList, setStateList] = useState(null);
  console.log(stateList, "partnerWallet1000");
  const [districtList, setDistrictList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [scList, setSCList] = useState(null);

  const allStateList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllStateList
  );

  const allDistrictList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllDistrictList
  );
  const allZoneList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllZoneList
  );
  const allSCList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllSuperCategoryList
  );
  const allDropdownList = useSelector(
    (store) => store.PartnerWalletManagementReducer.walletAllDropdownList
  );

  console.log(allDropdownList, "walletManagement");

  useEffect(() => {
    dispatch(PartnerWalletAllStateList(Axios));
  }, []);

  useEffect(() => {
    let param = {
      district_id: 0,
    };
    dispatch(PartnerWalletAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    let param = { state_id: 0 };
    dispatch(PartnerWalletAllDistrictList(Axios,param));
  }, []);

  useEffect(() => {
    dispatch(PartnerWalletAllSCList(Axios));
  }, []);

  useEffect(() => {
    // let param = {
    //   zone_name: "Bengaluru",
    //   sc_id: 45,
    //   request_status: "pending",
    // };

    let param = {
      zone_name: obj?.zone_name,
      sc_id: obj?.sc_id,
      request_status: obj?.status,
    };
    console.log(param, "walletManagement");
    dispatch(PartnerWalletApprovalList(Axios, param));
    dispatch({ type: GETPARTNERDROPDOWNLIST, payload: obj });
  }, [obj]);

  useEffect(() => {
    let state = [];

    for (let i = 0; i < allStateList?.length; i++) {
      state.push(allStateList[i]?.state_name);
    }

    setStateList(state);
    // setObj({
    //   ...obj,
    //   state_list: state,
    // });
  }, [allStateList]);

  useEffect(() => {
    let sc = [];
    for (let i = 0; i < allSCList?.length; i++) {
      sc.push(allSCList[i]?.name);
    }

    setSCList(sc);
    // setObj({
    //   ...obj,
    //   sc_list: sc,
    // });
  }, [allSCList]);

  useEffect(() => {
    let district = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      district.push(allDistrictList[i]?.district_name);
    }
    setDistrictList(district);
    // setObj({
    //   ...obj,

    //   district_list: district,
    // });
  }, [allDistrictList]);

  useEffect(() => {
    let zone = [];
    for (let i = 0; i < allZoneList?.length; i++) {
      zone.push(allZoneList[i]?.zone_name);
    }

    setZoneList(zone);

    // setObj({
    //   ...obj,

    //   zone_list: zone,
    // });
  }, [allZoneList]);

  const handleState = (e, value) => {
    let stateId = [];
    for (let i = 0; i < allStateList?.length; i++) {
      if (value === allStateList[i].state_name) {
        stateId = allStateList[i].state_id;
        break;
      }
    }

    setObj({
      ...obj,
      state_id: stateId,
    });

    let param = { state_id: stateId };

    dispatch(PartnerWalletAllDistrictList(Axios, param));
  };

  const handleDistrict = (e, value) => {
    let districtId = [];
    for (let i = 0; i < allDistrictList?.length; i++) {
      if (value === allDistrictList[i]?.district_name) {
        districtId = allDistrictList[i]?.district_id;
        break;
      }
    }

    setObj({
      ...obj,
      district_id: districtId,
    });

    let param = { district_id: districtId };

    dispatch(PartnerWalletAllZoneList(Axios, param));
  };

  const handleStatus = (e, value) => {
    const changedValue =
      value === "Request"
        ? "pending"
        : value === "Approval"
        ? "approved"
        : value === "Decline"
        ? "rejected"
        : "";
    setObj({ ...obj, status: changedValue });
  };

  const handleZone = (e, value) => {
    setObj({ ...obj, zone_name: value });
  };

  const handleSuperCategory = (e, value) => {
    let SCId = 0;
    for (let i = 0; i < allSCList?.length; i++) {
      if (value === allSCList[i]?.name) {
        SCId = allSCList[i]?.sc_id;
      }
    }

    setObj({ ...obj, sc_id: SCId });
  };

  console.log(obj, "walletManagement");
  return (
    <Stack
      sx={{
        marginTop: "4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          marginLeft: "1rem",
        }}
      >
        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.stateName}
          options={stateList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleState}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "80%",
                backgroundColor: "#25293C",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="State"
              placeholder="Select State.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.year}
          options={districtList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleDistrict}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "80%",
                marginLeft: "-2rem",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="District"
              placeholder="Select District.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.year}
          options={zoneList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleZone}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "80%",
                marginLeft: "-4rem",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="Zone"
              placeholder="Select Zone.."
            />
          )}
        />

        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.year}
          options={scList}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleSuperCategory}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "80%",
                marginLeft: "-6rem",
                backgroundColor: "#25293C",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="SuperCategory"
              placeholder="Select superCategory.."
            />
          )}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "-1.8rem",
        }}
      >
        <Autocomplete
          sx={{ width: { xs: "100%", md: "180px", lg: "200px" } }}
          // value={obj.stateName}
          options={STATUSLIST.map((status) => status)}
          getOptionLabel={(option) => option}
          size="small"
          onChange={handleStatus}
          renderInput={(params) => (
            <AutocompleteTextFieldNormal
              {...params}
              sx={{
                width: "80%",
                backgroundColor: "#25293C",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    // border: "none",
                    borderColor: "white",
                  },
                },
              }}
              variant="outlined"
              label="Status"
              placeholder="Select status.."
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

export default PartnerPendingDropdown;
