import React,{useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import Styles from './styles/index.module.css'
import { GetSuperCategoryPageData } from "../../../../actions/SCategory/sCategoryAction";
import CarouselSuperCategory from "./CarouselSuperCategory";
import UpdateSCategory from "./UpdateSCategory";
import AddSCategory from "./AddSCategory";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate'
import {Stack } from "@mui/material";
import { CardWrapper } from "../../CardWrapper";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SCategoryList from "./SCategoryList";
import SCategoryListTwo from "./SCategoryListTwo";
import AddWrapperComponent from "../AddWrapperComponent";


const SuperCategoryMgt = () => {
  const Axios = useAxiosPrivate()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const dispatch = useDispatch();
  //call api to get all the super category
  useEffect(() => {
    //superCategoryList
    dispatch(GetSuperCategoryPageData(Axios));
  }, []);

  let handleOpen = (state) => {
    setOpen(state)
  }

  return (
    <div className={Styles.container}>
    <CardWrapper>
      <Stack direction="column" gap={2}>
        <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
        <Stack flex={1}>
          <AddWrapperComponent formOpen={open} handleFormOpen={handleOpen} Title={"Add Super Category"}>
            <AddSCategory/>
          </AddWrapperComponent>
        </Stack>
        <Stack flex={2.3} sx={{ padding: "20px 5px 10px 5px",color:"white" }}>
          <Stack gap={1} padding="20px 0px">
            {/* <Typography variant="h6">Search User</Typography> */}
            <Stack direction="row" flexWrap="wrap" alignItems="center" sx={{ border: "1px solid white", borderRadius: "20px" }} gap={1} >
              <IconButton
                // className={Styles.Icon}
                sx={{
                  backgroundColor: "white",
                  '&:hover': {
                    backgroundColor: "gray",
                    transition: "0.2s linear"
                  }
                }}>
                <SearchIcon sx={{ color: "black !important" }} />
              </IconButton>
              <input style={{
                flex: 1,
                border: "none",
                outline: "none",
                background: "transparent",
                color: "white"
              }}
                value={text} onChange={(e) => setText(e.target.value)}
                placeholder='Search Super Category...'
              />
              <IconButton
                // className={Styles.Icon}
                onClick={() => setText('')}
              >
                <CloseIcon sx={{ color: "white !important" }} />
              </IconButton>
            </Stack>
          </Stack>
          {open && <SCategoryList text={text} />} 
        </Stack>
      </Stack>
      {!open && <SCategoryListTwo text={text} open={open}/>}
      
      </Stack>
    </CardWrapper>
  </div>
  );
};

export default SuperCategoryMgt;
