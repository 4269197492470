import {
  KYCCOUNTBYSTATEZONEREDUXCONSTANT,
  KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT,
  KYCLISTBYSTATEZONEREDUXCONSTANT,
  KYCSTATELISTREDUXCONSTANT,
  KYCZONELISTREDUXCONSTANT,
  GETKYCSUPERCATEGORYDATA,
  GETKYCCATEGORYDATA,
  GETVIEWDETAILSUSERLIST,
  GETVIEWDETAILSUSERLISTTABSDATA,
  KYCDISTRICTLISTREDUXCONSTANT,
} from "../../utility/ReduxConstants/KycReducerConstant";

const initialState = {
  stateList: [],
  zoneList: [],
  districtList: [],
  kyc_verified_count: 0,
  not_applied_count: 0,
  pending: 0,
  total_count: 0,
  vitaran_verified_count: 0,
  kycList: [],
  kycDetail: {},
  scList: [],
  categoryList: [],
  kycViewDetails: [],
  getkycTabsDetails: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KYCSTATELISTREDUXCONSTANT:
      return {
        ...state,
        stateList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case KYCZONELISTREDUXCONSTANT:
      return {
        ...state,
        zoneList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

      case KYCDISTRICTLISTREDUXCONSTANT:
        return {
          ...state,
          districtList:
            action.payload !== undefined &&
            action.payload !== null &&
            action.payload.length !== 0
              ? action.payload
              : [],
        };

    case KYCCOUNTBYSTATEZONEREDUXCONSTANT:
      return {
        ...state,
        kyc_verified_count:
          action.payload?.kyc_verified_count !== undefined &&
          action.payload?.kyc_verified_count !== null &&
          action.payload?.kyc_verified_count !== 0
            ? action.payload?.kyc_verified_count
            : 0,
        not_applied_count:
          action.payload?.not_applied_count !== undefined &&
          action.payload?.not_applied_count !== null &&
          action.payload?.not_applied_count !== 0
            ? action.payload?.not_applied_count
            : 0,
        pending:
          action.payload?.pending !== undefined &&
          action.payload?.pending !== null &&
          action.payload?.pending !== 0
            ? action.payload?.pending
            : 0,
        total_count:
          action.payload?.total_count !== undefined &&
          action.payload?.total_count !== null &&
          action.payload?.total_count !== 0
            ? action.payload?.total_count
            : 0,
        vitaran_verified_count:
          action.payload?.vitaran_verified_count !== undefined &&
          action.payload?.vitaran_verified_count !== null &&
          action.payload?.vitaran_verified_count !== 0
            ? action.payload?.vitaran_verified_count
            : 0,
      };

    case KYCLISTBYSTATEZONEREDUXCONSTANT:
      return {
        ...state,
        kycList:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload
            : [],
      };

    case KYCDETAILBYKYCIDSTATEZONEREDUXCONSTANT:
      return {
        ...state,
        kycDetail:
          action.payload !== undefined &&
          action.payload !== null &&
          action.payload.length !== 0
            ? action.payload[0]
            : {},
      };
    case GETKYCSUPERCATEGORYDATA:
      return {
        ...state,
        scList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
      };
    case GETKYCCATEGORYDATA:
      return {
        ...state,
        categoryList:
          action.payload !== undefined && action.payload !== null
            ? action.payload
            : [],
      };

    case GETVIEWDETAILSUSERLIST:
      return {
        ...state,
        kycViewDetails: action.payload,
      };

    case GETVIEWDETAILSUSERLISTTABSDATA:
      return {
        ...state,
        getkycTabsDetails: action.payload,
      };

    default:
      return state;
  }
};
