import React, { useState, useEffect } from "react";
import Styles from "./HomePage.module.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import styled from "styled-components";

import {
  Routes,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams,
  useNavigate,
  Outlet,
} from "react-router-dom";

import RootMgtZone from "../components/Pages/Zone";
import TransactionMgt from "../components/Pages/Transaction";
import { MissingRoute } from "../components/ErrorUtilities/Redirect/index";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

import {
  AuthenticateUser,
  GetStateListInitial,
  GetZoneList,
  UserLogin,
} from "../actions/Login/login";

import axios from "axios";
import { baseUrl } from "../utility/BaseUrl";

import {
  GETZONELIST,
  LISTSTART,
  LOGINUSERDATA,
} from "../utility/ReduxConstants/LoginReducerConstant";

import OfflineMgt from "../components/Offline/offline";
import CheckOffline from "../utility/CheckOffline";
import UserZoneMgt from "../components/Pages/UserAccess/UserZoneMgt";
import NewSku from "../components/Pages/NewSku";
import SkuByZone from "../components/Pages/SkuByZone";
import Kyc from "../components/Pages/kyc";
import KycVerification from "../components/Pages/kyc/KycVerification";
import Login from "../components/Login";
import Dashboard from "../components/Pages/DashBoard/Dashboard";
import TicketMgt from "../components/Pages/Tickets/TicketMgt";
import SuperCategoryMgt from "../components/Pages/Product/SuperCategory";
import ForgotPassword from "../components/Login/forgetPassword";
import { Button, Stack } from "@mui/material";
import Wrapper from "../components/Pages/Wrapper";

const BodyStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
`;

const styledDiv = styled.div`
  ${(props) => props.width && `min-width: calc(100vw - ${props.width});`}
`;

const Main = styled.div.attrs((props) => ({
  // we can define static props
  type: "text",

  // or we can define dynamic ones
  size: props.width || "1em",
}))`
  ${"" /* flex:4.5; */}
  background-color:var(--color-black);
  width: ${(props) => props.width};
`;

const theme = createTheme({
  palette: {
    // primary: {
    //   main: purple[500],
    // },
    // secondary: {
    //   main: green[500],
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ipm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

// const width = "17vw";

const Authaxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

const HomePage = () => {
  const navigate = useNavigate();
  const ipad = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const width = useMediaQuery(theme.breakpoints.between("md", "lg"))
    ? "15vw"
    : "15vw";
  const BodyWidth = useMediaQuery(theme.breakpoints.between("md", "lg"))
    ? "85vw"
    : "85vw";
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Login);

  useEffect(() => {
    return () => {
      console.log("unmounting...");
    };
  }, []);
  const [show, setShow] = useState(false);
  const handleShowSidebar = () => {
    setShow(!show);
  };

  return (
    <>
      <Stack directions="column">
        <Navbar handleShowSidebar={handleShowSidebar} show={show} />
        <Toolbar />
        <Drawer open={show} onClose={() => setShow(false)}>
          <Stack
            direction="row"
            sx={{
              width: "70vw",
              height: "100%",
            }}
          >
            <Sidebar />
          </Stack>
        </Drawer>
        {!ipad && (
          <Stack
            direction="row"
            sx={{
              position: "fixed",
              top: "80px",
              width: "15vw",
              height: "100%",
            }}
          >
            <Sidebar />
          </Stack>
        )}
        <Box
          component="main"
          sx={{
            marginLeft: { xs: "0vw", sm: "0vw", md: ipad ? "0vw" : "15vw" },
            // minHeight: "100vh",

            width: { xs: "100vw", sm: "100vw", md: ipad ? "100vw" : BodyWidth },
          }}
        >
          <Wrapper>
            <Outlet />
          </Wrapper>
        </Box>
      </Stack>
    </>
  );
};

export default HomePage;
