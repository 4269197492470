import React, { useEffect } from "react";
import PartnerPendingCoinsDetail from "./PartnerPendingCoinsDetail";
import PartnerPendingTable from "./PartnerPendingTable";
import PartnerPendingDropdown from "./PartnerPendingDropdown";
import {
  PartnerWalletAllDistrictList,
  PartnerWalletAllSCList,
  PartnerWalletAllStateList,
  PartnerWalletAllZoneList,
  PartnerWalletApprovalList,
} from "../../../../actions/PartnerWalletManagement/PartnerWalletManagementAction";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
const PartnerPendingApproval = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  useEffect(() => {
    let param={sc_id:0}
    dispatch(PartnerWalletAllStateList(Axios,param));
  }, []);

  useEffect(() => {
    let param = {
      district_id: 0,
    };
    dispatch(PartnerWalletAllZoneList(Axios, param));
  }, []);

  useEffect(() => {
    dispatch(PartnerWalletAllDistrictList(Axios));
  }, []);

  useEffect(() => {
    let param = { zone_id: 0 };
    dispatch(PartnerWalletAllSCList(Axios, param));
  }, []);

  // useEffect(() => {
  //   let param = {
  //     zone_name: "Bengaluru",
  //     sc_id: 26,
  //     request_status: "pending",
  //   };
  //   dispatch(PartnerWalletApprovalList(Axios, param));
  // }, []);
  return (
    <>
      <PartnerPendingCoinsDetail />
      <PartnerPendingDropdown />
      <PartnerPendingTable />
    </>
  );
};

export default PartnerPendingApproval;
