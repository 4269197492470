import {
  NEWACCESSTOKENLAMDA,
  GETLAMBDATOKEN,
} from "../../utility/ReduxConstants/LoginLamdaReducerConstant";

const initialState = {
  access_token: "",
  user_data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETLAMBDATOKEN: {
      console.log(action.payload, "in Store");
      return {
        ...state,
        access_token: action.payload.access_token,
        user_data: action?.payload?.user_data[0],
      };
    }

    case NEWACCESSTOKENLAMDA:
      return {
        ...state,
        access_token: action.payload?.access_token,
      };

    default:
      return state;
  }
};
