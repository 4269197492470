import { Authaxios } from "../../axios/axios";
import { GETSUPERCATEGORYDATA, GETSUPERCATEGORYDETAILS, UPDATESCATEGORYDATAINLIST } from "../../utility/ReduxConstants/SuperCategoryReducerConstant";



export const GetSuperCategoryPageData = (Axios) => async (dispatch) => {
  try {
    const { data } = await Axios.post('superCategoryList');
    if (data.code === 200) {
      dispatch({ type: GETSUPERCATEGORYDATA, payload: data })
    }
  } catch (error) {
    console.log(error)
  }
}

export const AddScCategoryData = (Axios,param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("addSuperCategory", param);
    return data
  } catch (error) {
    console.log(error);
    return error?.response?.data
  }
};

export const GetSuperCategoryDetailsId = (Axios,param) => async(dispatch) => {
  try {
   
       const {data} = await Axios.post('getSuperCategoryDetails',param);
       
       if(data.code===200){
          dispatch({ type: GETSUPERCATEGORYDETAILS, payload: data.body[0] });

       }
  } catch (error) { 
     return error?.response?.data
  }
}

export const UpdateSCategoryData =
  (Axios,param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("updateSuperCategory", param);
      return data
    } catch (error) {
      console.log(error);
      return error.response.data
    }
  };

export const RemoveSCategoryData =(Axios,param) => async (dispatch) => {
    
    try {
      const { data } = await Axios.post("removeSuperCategory", param);
      return data
    } catch (error) {
      console.log(error);
      return error?.response?.data
    }
  };