import { ROLES } from "../../utility/UserAllowedRoles";

export const Items = [
  {
    text: "Zone Management",
    to: "/home/zonemgt",
    roles: ROLES.ZONE_MGT,
  },
  {
    text: "Access Management",
    to: "/home/access",
    roles: ROLES.ACCESS_MGT,
  },
  {
    text: "Transaction Management",
    to: "/home/transaction",
    roles: ROLES.TRANSACTION_MGT,
  },
  {
    text: "Transaction Management",
    to: "/home/access/transaction",
    roles: ROLES.ACCESS_TRANSACTION_MGT,
  },
  
  // {

  //     text: "Product",
  //     to:'/home/product'
  // },
  {
    text: "Company",
    to: "/home/company",
    roles: ROLES.COMPANY_MGT,
  },
  {
    text: "Super Category",
    to: "/home/scategory",
    roles: ROLES.SUPER_CATEGORY_MGT,
  },
  {
    text: "Launch Management",
    to: "/home/launch/",
    // to:'/home/launch/state_id:id/zone_id:id/zone_name:id/sc_id:id/changed:id',
    roles: ROLES.LAUNCH_MGT,
  },
  {
    text: "Category",
    to: "/home/category",
    roles: ROLES.CATEGORY_MGT,
  },
  {
    text: "Sub Category",
    to: "/home/subcategory",
    roles: ROLES.SUB_CATEGORY,
  },
  {
    text: "Sku Management",
    to: "/home/newsku",
    roles: ROLES.SKU_MGT,
  },
  {
    text: "KYC",
    to: "/home/kyc",
    roles: ROLES.KYC_MGT,
  },
  {
    text: "Dashboard",
    to: "/home/dashboard",
    roles: ROLES.DASHBOARD,
  },
  {
    text: "Holiday Calender",
    to: "/home/calender",
    roles: ROLES.CALENDER,
  },
  {
    text: "Video Analytics",
    to: "/home/video/analytics",
    roles: ROLES.VIDEO_ANALYTICS,
  },
  {
    text: "Ticket Management",
    to: "/home/ticket",
    roles: ROLES.TICKET_MGT,
  },

  {
    text: "Wallet Management",
    to: "/home/wallet",
    roles: ROLES.WALLET_MGT,
  },

  {
    text: "Wallet Management",
    to: "/home/access/wallet",
    roles: ROLES.WALLET_PARTNER_MGT,
  },

  {
    text: "Run The Deals",
    to: "/home/runthedeals",
    roles: ROLES.MAP_MGT,
  },
  {
    text: "Vitaran Partner",
    to: "/home/map",
    roles: ROLES.VITARAN_PARTNER,
    dropdown: [
      {
        text: "Delivery Management",
        to: "/home/map",
        roles: ROLES.MAP_MGT,
      },
      {
        text: "YourDeals",
        to: "/home/yourdeals",
        roles: ROLES.VITARAN_PARTNER,
      },
    ],
  },
];
