import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useLocalStorage from "../../../PrivateHooks/useLocalStorage";

function MissingRoute() {
  const store = useSelector((state) => state.Login);
  const user = store?.user ?? {}
  
  const [persist] = useLocalStorage("persist", false);

  // if (!store?.token && persist) {
  //   return <Navigate to={{ pathname: "/" }} />;
  // } else {
  //   // navigate('/Login')
  // }

  // return store?.token.length === 0 && persist ? (
  //   <Navigate to={{ pathname: "/" }} />
  // ) : (
  //   <Navigate to={{ pathname: "/home/zonemgt" }} />
  // );

  return <Navigate to={{ pathname: "/home/zonemgt" }} />;
  // return user?.admin_role_code===9999 ?<Navigate to={{ pathname: "/home/zonemgt" }} />:<Navigate to={{ pathname: "/home/category" }} />;
}

export { MissingRoute };
