import { Autocomplete, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


const DashboardSkuAnalyticsTable = () => {
    let Head = ["Sku Image","Sku Name","No. of buyers","No. of sellers","Demanded Lots(to buy)","Available Lots(to sell)"]
    const store = useSelector(state=>state.Dashboard);
    const [list,setList]=useState([])

    useEffect(()=>{ 
        setList(store?.skuList ?? [])
    },[store?.skuList])
    return (
        <Stack>
            <TableContainer>
                <Table>
                    <TableHead>
                        {
                            Head.map(item=>
                                <TableCell align="center">{item}</TableCell>)
                        }
                    </TableHead>
                    <TableBody>
                         {
                            list?.map(item=>
                                <TableRow>
                                     <TableCell align="center"><img src={item?.sku_image} style={{width:"50px",height:"50px"}}/></TableCell>
                                     <TableCell align="center">{item?.sku_name}</TableCell>
                                     <TableCell align="center" sx={{backgroundColor:"rgba(78, 225, 41, 0.45)"}}>{item?.buyers}</TableCell>
                                     <TableCell align="center" sx={{backgroundColor:"rgba(255, 51, 40, 0.43)"}}>{item?.sellers}</TableCell>
                                     <TableCell align="center" sx={{backgroundColor:"rgba(78, 225, 41, 0.45)"}}>{item?.available_lots}</TableCell>
                                     <TableCell align="center" sx={{backgroundColor:"rgba(255, 51, 40, 0.43)"}}>{item?.demanded_lots}</TableCell>
                                </TableRow>)
                         }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default DashboardSkuAnalyticsTable
