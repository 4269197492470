import { Button, Card, CircularProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/UpdateCategory.module.css";
import images from "../../../../utility/constants/images";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseImgUploadUrl, baseImgPath, defaultImg } from "../../../../utility/BaseUrl";
import {
  GetCategoryListBySCId,
  RemoveCategoryData,
  UpdateCategoryData,
} from "../../../../actions/category/categoryActions";
import useAxiosPrivate from '../../../../PrivateHooks/useAxiosPrivate';
import PopUpModal from '../../../../utility/Backdrop';
import BrokenImg from '../../../../assets/ImageNotValid.png'
import { ValidImage } from '../../../../actions/validImage';
import { ROLES } from '../../../../utility/UserAllowedRoles';
import { DarkCardWrapper } from '../../CardWrapper';
import { AutocompleteTextField } from '../../../../utility/TextField';
import { GetSubCategoryList, RemoveSubCategory, UpdateSubCategory } from "../../../../actions/subcategory/subcategory.action";
import { GETSUBCATEGORYLIST } from "../../../../utility/ReduxConstants/SubCategory.Reducer.Constant";


const viewImgPath = `${baseImgPath}/category/`;

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const UpdateCategory = () => {
  const [loading, setLoading] = useState(false)
  const LoginStore = useSelector(state => state.Login)
  const Axios = useAxiosPrivate()
  const [remove, setRemove] = useState(false)
  const [error, setError] = useState({});
  const store = useSelector((state) => state.SubCategoryReducer);
  const [file, setFile] = useState("");
  const [img, setImg] = useState(defaultImg);
  const [addsuccess, setAddsuccess] = useState(false);

  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const [addsuccessrm, setAddsuccessrm] = useState(false);
  const [failedrm, setFailedrm] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const dispatch = useDispatch();

  const [form, setForm] = useState({
            "s_cat_id": 0,
            "category_id": 0,
            "sc_id": 0,
            "s_category_name": "",
            "s_category_image": "",
  });


  const CheckImage = async (image) => {
    console.log(image,"CheckImage")
    if (![undefined, null].includes(image) && image?.length !== 0) {
      const Result = await ValidImage(image)
      if (Result) {
        setImg(`${image}`)
      } else {
        setImg(BrokenImg)
      }
    }else{
      setImg(defaultImg)
    }

  }

  useEffect(() => {
    if (store.sub_category_detail !== undefined && store.sub_category_detail !== null) {

      const { s_category_image } = store.sub_category_detail;
   let Detail = store.sub_category_detail
      setForm({
        ...Detail,
        sc : store?.sc ?? {},
        category : store?.category ?? {}
      });
     CheckImage(s_category_image)
    //  setImg(s_category_image)
    }
  }, [store.sub_category_detail]);
  // useEffect(() => {
  //   const {s_category_image} = store.sub_category_detail;
  //    CheckImage(s_category_image)
  // }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const imgUpload = async (formData) => {
    await axios
      .post(`${baseImgUploadUrl}image_upload_sub_category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log("res", res);
        if (res.data.code === 200) {
          setLoading(false)
          toast.success("Uploaded SuccessFully");
          setImg(`${res.data.full_path}`);
          setForm({
            ...form,
            s_category_image: res.data.full_path,
          });
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) =>{//console.log(err.message)
      });
  };

  const ChooseImg = (event) => {
    setLoading(true)
    setFile(event.target.files[0]);
  };

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("document", JSON.stringify({type:"company"}));
    imgUpload(formData);
  }, [file]);

  React.useEffect(() => {
    if (addsuccess) {
      toast.success("Updated Sub Category Details SuccessFully");

      setAddsuccess(false);
    }
  }, [addsuccess]);

  React.useEffect(() => {
    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failed]);

  const GetSubCategoryList_ =async(param)=>{
    ////console.log(param,"GetSubCategoryList_")
    const Data = await dispatch(GetSubCategoryList(Axios,param));
    //console.log(Data,"GetSubCategoryList_",param)
    if(Data.code==200){
         dispatch({
            type:GETSUBCATEGORYLIST,
            payload : Data?.list
         })

    }else{
        toast.error("Failed to fetch Sub Category List")
    }

}


  const IsValid = () => {
    let success = true;
    let err = {};
   const chars = form.item_name?.match(/[^a-zA-Z0-9',&-](?= |$)/g)
    
    if (!form.s_category_name.trim()) {
      err.s_categoryname = "**sub category Name Cannot Be Empty**";
      success = false;
    } else if (chars && chars?.length!==0) {
      err.s_categoryname =
        "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
      success = false;
    }

    if (!img.trim()) {
      err.categoryimage = "**Please Choose a Image**";
      success = false;
    }

    // if (!form.description.trim()) {
    //   err.description = "**category Description Cannot Be Empty**";
    //   success = false;
    // }

    setError(err);
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (IsValid()) {

      let param = {
        "s_cat_id":form.s_cat_id,
        "category_id":form.category_id,
        "sc_id":form.sc_id,
        "s_category_name":form.s_category_name,
        "s_category_image":form.s_category_image
    }
      const Data = await dispatch(UpdateSubCategory(Axios, param));
      if (Data.code === 200) {
        let paramTwo = {
          sc_id: form?.sc?.sc_id,
          category_id:form?.category?.category_id
        };
        GetSubCategoryList_(paramTwo);
        toast.success("Updated Sub Category SuccessFully")
      } else {
        toast.error(Data.message)
      }
    }else{
      // toast.warning("validation error")
    }
  };
  const handleRemove = async () => {
    setRemove(true)
  }

  const handleConfirmRemove = async () => {

      let param = {
        "s_cat_id": form.s_cat_id
        
      }
      const Data = await dispatch(RemoveSubCategory(Axios, param));
      if(Data.code===200){
        setRemove(false)
        let paramTwo = {
          sc_id: form?.sc?.sc_id,
          category_id:form?.category?.category_id
        };
       GetSubCategoryList_(paramTwo);
        toast.success(Data.message)
      }else{
        toast.error(Data.message)
      }
    

  }
  //console.log(form, "update cat");
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <form onSubmit={handleSubmit}>
        <Stack sx={{ color: "white" }} gap={1}>

          <Typography>Update Category</Typography>
          <DarkCardWrapper sx={{ padding: "10px 20px" }}>
            <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2}>
              <Stack gap={1} flex={0.7}>
                <Card component={Paper} sx={{ borderRadius: "16px" }}>
                  <Stack sx={{ height: "130px" }} direction="row" justifyContent={"center"} alignItems="center">
                    {!loading && <img src={img} alt="company" loading="lazy"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                      }} />}
                    {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                    {
                      loading ?
                        <Stack direction="column" position="absolute" justifyContent={"center"} alignItems={"center"}>
                          <CircularProgress />
                          <Typography>uploading...</Typography>
                        </Stack> :
                        null
                    }
                    <input type="file" accept="image/*" name="image-upload" id={`CategoryImgUploadUpdate`} className={Styles.imginp} onChange={ChooseImg} />
                  </Stack>


                </Card>
                {
                  error?.categoryimage && <Typography variant="caption" sx={{ color: "red" }}>{error?.categoryimage}</Typography>

                }
                <Card sx={{ backgroundColor: "#545B87" }} className={Styles.UpdateCompanyWrapper}>
                  <Stack direction="row" justifyContent="center" alignItems="center" padding="5px 0px" sx={{ cursor: "pointer" }}>
                    <label htmlFor={`CategoryImgUploadUpdate`} style={{ fontSize: "12px", margin: 0, color: "white", cursor: "pointer" }}>
                      UPLOAD IMAGE
                    </label>
                  </Stack>
                </Card>

              </Stack>
              <Stack flex={3} gap={1}>
                <Stack>
                  <AutocompleteTextField size="small" label='Super Category Name' disabled="true"  value={form.sc?.name ?? ''} onChange={handleChange}/>
                </Stack>
                <Stack>
                  <AutocompleteTextField size="small" label='Category Name' disabled="true" value={form?.category?.category_name ?? ''} onChange={handleChange}/>
                </Stack>
                <Stack>
                  <AutocompleteTextField size="small" label='Sub Category Name'  value={form.s_category_name ?? ''} name="s_category_name" onChange={handleChange} />
                  {error?.s_categoryname && <Typography variant="caption" sx={{ color: "red" }}>{error?.s_categoryname}</Typography>}
                </Stack>
                <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="flex-end" gap={1}>
                  <RedButton sx={{ borderRadius: "18px", minWidth: { md: "150px" } }} type="submit">Update</RedButton>
                  {ROLES?.REMOVECOMPANY?.includes(LoginStore?.user?.admin_role_code) ?
                    <RedBorderButton sx={{ borderRadius: "18px", minWidth: { md: "150px" } }} onClick={handleRemove}>Remove</RedBorderButton> : null}
                </Stack>
              </Stack>
            </Stack>
          </DarkCardWrapper>

        </Stack>
      </form>

      {
        remove &&
        <PopUpModal
          open={remove}
          handleClose={() => setRemove(false)}
          Title={`Do You want to Remove Sub Category`}
          Content={`${form.s_category_name}`}
          Img={img}
          handleAgree={handleConfirmRemove}
        />
      }
    </>
  );
};

export default UpdateCategory;
