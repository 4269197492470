export const GETVIDEOANALYTICSSTATELIST = "GETVIDEOANALYTICSSTATELIST";
export const GETVIDEOANALYTICSSTATENAME = "GETVIDEOANALYTICSSTATENAME";
export const GETVIDEOANALYTICSDISTRICTLIST = "GETVIDEOANALYTICSDISTRICTLIST";
export const GETVIDEOANALYTICSDISTRICTNAME = "GETVIDEOANALYTICSDISTRICTNAME";
export const GETVIDEOANALYTICSZONELIST = "GETVIDEOANALYTICSZONELIST";
export const GETVIDEOANALYTICSZONENAME = "GETVIDEOANALYTICSZONENAME";
export const GETDISTRICTBYSTATEID = "GETDISTRICTBYSTATEID";
export const GETDISTRICTNAMEBYSTATEID = "GETDISTRICTNAMEBYSTATEID";
export const GETZONEBYDISTRICTID = "GETZONEBYDISTRICTID";
export const GETZONENAMEBYDISTRICTID = "GETZONENAMEBYDISTRICTID";
export const GETVIDEODETAILSBYZONE = "GETVIDEODETAILSBYZONE";
export const GETVIDEONAMEBYZONE = "GETVIDEONAMEBYZONE";
export const GETUSERVIDEODETAILSBYZONE = "GETUSERVIDEODETAILSBYZONE";
export const GETALLNAMEIDVALUE = "GETALLNAMEIDVALUE";
export const GETALLVIDEODETAILS = "GETALLVIDEODETAILS";
export const GETWATCHCOUNTBYVIDEOTITLE = "GETWATCHCOUNTBYVIDEOTITLE";
export const GETALLWATCHCOUNTBYVIDEOTITLE = "GETALLWATCHCOUNTBYVIDEOTITLE";
export const GETSPECIFICWATCHCOUNT = "GETSPECIFICWATCHCOUNT";
export const GETVIDEODETAILSBYZONEANDVIDEO = "GETVIDEODETAILSBYZONEANDVIDEO";
export const GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT = "GETVIDEODETAILSBYZONEVIDEOANDWATCHCOUNT";