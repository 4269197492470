import {
  GETPARTNERWALLETALLDISTRICTLIST,
  GETPARTNERWALLETALLSCLIST,
  GETPARTNERWALLETALLSTATELIST,
  GETPARTNERWALLETALLZONELIST,
  GETPARTNERWALLETAPPROVALLIST,
  GETPARTNERWALLETAPPROVECONFIRM,
  GETWALLETTRANSACTIONDETAILS,
  GETWALLETUSERLIST,
  GETWALLETVPDETAILS,
} from "../../utility/ReduxConstants/PartnerWalletManagementReducerConstant";

// export const PartnerWalletAllStateList = (Axios) => async (dispatch) => {
//   try {
//     const { data } = await Axios.post("AllstateList");
//     console.log(data,"partnerWallet1000")

//     if (data.code === 200) {
//       dispatch({ type: GETPARTNERWALLETALLSTATELIST, payload: data.stateList });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const PartnerWalletAllStateList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessStateList", param);
    console.log(data, "partnerWallet1000");

    if (data.code === 200) {
      dispatch({ type: GETPARTNERWALLETALLSTATELIST, payload: data.stateList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const PartnerWalletAllDistrictList =
  (Axios, param) => async (dispatch) => {
    try {
      const { data } = await Axios.post("SCAccessDistrictList", param);

      if (data.code === 200) {
        dispatch({
          type: GETPARTNERWALLETALLDISTRICTLIST,
          payload: data.districtList,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

// export const PartnerWalletAllSCList = (Axios, param) => async (dispatch) => {
//   try {
//     const { data } = await Axios.post("superCategoryList");
//     console.log(data,"partnerWallet1000")
//     if (data.code === 200) {
//       dispatch({ type: GETPARTNERWALLETALLSCLIST, payload: data.body });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const PartnerWalletAllSCList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("AccesssuperCategoryList", param);
    console.log(data, "partnerWallet1000");
    if (data.code === 200) {
      dispatch({ type: GETPARTNERWALLETALLSCLIST, payload: data.body });
    }
  } catch (error) {
    console.log(error);
  }
};

// export const PartnerWalletAllZoneList = (Axios, param) => async (dispatch) => {
//   try {
//     const { data } = await Axios.post("AllzoneList", param);

//     if (data.code === 200) {
//       dispatch({ type: GETPARTNERWALLETALLZONELIST, payload: data.zoneList });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const PartnerWalletAllZoneList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("SCAccessZoneList", param);

    if (data.code === 200) {
      dispatch({ type: GETPARTNERWALLETALLZONELIST, payload: data.zoneList });
    }
  } catch (error) {
    console.log(error);
  }
};

export const PartnerWalletApprovalList = (Axios, param) => async (dispatch) => {
  console.log(param, "walletManagement");
  try {
    const { data } = await Axios.post("getCoinApprovalList", param);
    console.log(data, "walletManagement");

    if (data.code === 200) {
      dispatch({
        type: GETPARTNERWALLETAPPROVALLIST,
        payload: data.approval_list,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const PartnerWalletApproveConfirm =
  (Axios, param) => async (dispatch) => {
    console.log(param, "walletManagement");
    try {
      const { data } = await Axios.post("acceptApproval", param);
      console.log(data, "walletManagement");

      if (data.code === 200) {
        dispatch({
          type: GETPARTNERWALLETAPPROVECONFIRM,
          payload: data.message,
        });
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const PartnerWalletDeclineConfirm =
  (Axios, param) => async (dispatch) => {
    console.log(param, "walletManagement");
    try {
      const { data } = await Axios.post("rejectApproval", param);
      console.log(data, "walletManagement");

      if (data.code === 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const PartnerWalletUserList = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("walletUserList", param);

    if (data.code === 200) {
      dispatch({
        type: GETWALLETUSERLIST,
        payload: data.user_list,
      });
      dispatch({
        type: GETWALLETVPDETAILS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const WalletTransactionDetails = (Axios, param) => async (dispatch) => {
  try {
    const { data } = await Axios.post("walletVPTransHistory", param);

    if (data.code === 200) {
      dispatch({ type: GETWALLETTRANSACTIONDETAILS, payload: data.trans_list });
    }
  } catch (error) {
    console.log(error);
  }
};

export const PartnerWalletSendCoinsConfirm =
  (Axios, param) => async (dispatch) => {
    console.log(param, "walletManagement");
    try {
      const { data } = await Axios.post("addUserCoin", param);
      console.log(data, "walletManagement");

      if (data.code === 200) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };
