import { Autocomplete, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteTextFieldCurved } from "../../../utility/TextField";
import DashboardSkuAnalyticsTable from "./DashboardSkuAnalyticsTable";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  GetSkuCategoryListDashboard,
  GetSkuDistrictListDashboard,
  GetSkuZoneListDashboard,
  SKULISTDASHBOARD,
} from "../../../actions/Dashboard/dashboard.action";
import {
  GETSKUANALYTICSLISTDASHBOARD,
  GETSKUCATEGORYLISTDASHBOARD,
  GETSKUDISTRICTLISTDASHBOARD,
  GETSKUZONELISTDASHBOARD,
} from "../../../utility/ReduxConstants/DashboardReducerConstant";

const DashboardSkuAnalytics = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.Dashboard);
  const [state, setState] = useState({
    name: "All",
    id: 0,
    list: ["All"],
    obj: { state_id: 0, state_name: "All" },
  });
  const [district, setDistrict] = useState({
    name: "All",
    id: 0,
    list: ["All"],
    obj: { district_id: 0, district_name: "All" },
  });
  const [zone, setZone] = useState({
    name: "All",
    id: 0,
    list: ["All"],
    obj: { zone_id: 0, zone_name: "All" },
  });
  const [sc, setScState] = useState({
    name: "",
    id: 0,
    list: [],
    obj: {},
  });
  const [category, setCategory] = useState({
    name: "",
    id: 0,
    list: [],
    obj: {},
  });

  const GetCatList = async (param) => {
    const Data = await dispatch(GetSkuCategoryListDashboard(Axios, param));
    if (Data?.code === 200) {
      dispatch({
        type: GETSKUCATEGORYLISTDASHBOARD,
        payload: Data?.list ?? [],
      });
    }
  };
  const GetSkuAnalyticsList = async (Axios, param) => {
    console.log(param, "GetSkuAnalyticsList");
    const Data = await dispatch(SKULISTDASHBOARD(Axios, param));
    if (Data?.code === 200) {
      dispatch({
        type: GETSKUANALYTICSLISTDASHBOARD,
        payload: Data?.list ?? [],
      });
    }
  };

  const GetZoneData = async () => {
    let param = {
      district_id: 0,
    };
    const ZoneData = await dispatch(GetSkuZoneListDashboard(Axios, param));

    if (ZoneData?.code === 200) {
      dispatch({
        type: GETSKUZONELISTDASHBOARD,
        payload: ZoneData?.zoneList ?? [{ zone_id: 0, zone_name: "All" }],
      });
    } else {
      console.log(ZoneData);
      alert("There is a Error in sku zone list dashboard");
    }
  };

  const GetDistrictData = async () => {
    let param = {
      state_id: 0,
    };
    const DistrictData = await dispatch(
      GetSkuDistrictListDashboard(Axios, param)
    );

    if (DistrictData?.code === 200) {
      dispatch({
        type: GETSKUDISTRICTLISTDASHBOARD,
        payload: DistrictData?.districtList ?? [
          { district_id: 0, district_name: "All" },
        ],
      });
    } else {
      console.log(DistrictData);
      alert("There is a Error in sku district list dashboard");
    }
  };

  useEffect(() => {
    if (![null, undefined].includes(store?.stateList)) {
      let Arr = ["All", ...store?.stateList?.map((item) => item?.state_name)];
      setState({
        name: "All",
        id: 0,
        list: Arr,
        obj: { state_id: 0, state_name: "All" },
      });
      GetDistrictData();
    }
  }, [store?.stateList]);

  useEffect(() => {
    if (![null, undefined].includes(store?.districtList)) {
      let Arr = [
        "All",
        ...store?.districtList?.map((item) => item?.district_name),
      ];
      setDistrict({
        name: "All",
        id: 0,
        list: Arr,
        obj: { district_id: 0, district_name: "All" },
      });
      GetZoneData();
    }
  }, [store?.districtList]);
  useEffect(() => {
    if (![null, undefined].includes(store?.skuZoneList)) {
      let Arr = ["All", ...store?.skuZoneList?.map((item) => item?.zone_name)];
      setZone({
        name: "All",
        id: 0,
        list: Arr,
        obj: { zone_id: 0, zone_name: "All" },
      });

      let paramTwo = {
        state_id: state?.obj?.state_id ?? 0,
        zone_name: "All",
        sc_id: sc?.obj?.sc_id ?? 0,
        category_id: category?.obj?.category_id ?? 0,
      };
      GetSkuAnalyticsList(Axios, paramTwo);
    }
  }, [store?.skuZoneList]);

  useEffect(() => {
    if (![null, undefined].includes(store?.skuDistrictList)) {
      let Arr = [
        "All",
        ...store?.skuDistrictList?.map((item) => item?.district_name),
      ];
      setDistrict({
        name: "All",
        id: 0,
        list: Arr,
        obj: { district_id: 0, district_name: "All" },
      });

      let paramTwo = {
        state_id: state?.obj?.state_id ?? 0,
        zone_name: "All",
        sc_id: sc?.obj?.sc_id ?? 0,
        category_id: category?.obj?.category_id ?? 0,
      };
      GetSkuAnalyticsList(Axios, paramTwo);
    }
  }, [store?.skuDistrictList]);

  useEffect(() => {
    if (![null, undefined].includes(store?.skuScList)) {
      let Arr = ["All", ...store?.skuScList?.map((item) => item?.name)];
      setScState({
        name: "All",
        id: 0,
        list: Arr,
        obj: { sc_id: 0, name: "All" },
      });

      let param = {
        sc_id: 0,
      };
      GetCatList(param);
    }
  }, [store?.skuScList]);

  useEffect(() => {
    if (![null, undefined].includes(store?.skuCategoryList)) {
      let Arr = [
        "All",
        ...store?.skuCategoryList?.map((item) => item?.category_name),
      ];
      setCategory({
        name: "All",
        id: 0,
        list: Arr,
        obj: { category_id: 0, category_name: "All" },
      });

      let paramTwo = {
        state_id: state?.obj?.state_id ?? 0,
        zone_name: zone?.obj?.zone_name ?? "All",
        sc_id: sc?.obj?.sc_id ?? 0,
        category_id: 0,
      };
      GetSkuAnalyticsList(Axios, paramTwo);
    }
  }, [store?.skuCategoryList]);

  const handleStateChange = async (e, value) => {
    let Arr = [...store?.stateList, { state_id: 0, state_name: "All" }];
    const [{ state_name, state_id }] = Arr?.filter(
      (item) => item?.state_name === value
    );
    setState({
      ...state,
      name: state_name,
      id: state_id,
      obj: { state_name, state_id },
    });

    let param = {
      state_id: state_id ?? 0,
    };

    const DistrictData = await dispatch(
      GetSkuDistrictListDashboard(Axios, param)
    );

    console.log(param, "districtData");

    if (DistrictData?.code === 200) {
      dispatch({
        type: GETSKUDISTRICTLISTDASHBOARD,
        payload: DistrictData?.districtList ?? [
          { district_id: 0, district_name: "All" },
        ],
      });
    } else {
      console.log(DistrictData);
      alert("There is a Error in sku zone list dashboard");
    }
  };

  const handleDistrictChange = async (e, value) => {
    let Arr = [
      ...store?.districtList,
      { district_id: 0, district_name: "All" },
    ];
    const [{ district_name, district_id }] = Arr?.filter(
      (item) => item?.district_name === value
    );
    setDistrict({
      ...district,
      name: district_name,
      id: district_id,
      obj: { district_name, district_id },
    });

    let param = {
      district_id: district_id ?? 0,
    };

    const ZoneData = await dispatch(GetSkuZoneListDashboard(Axios, param));

    if (ZoneData?.code === 200) {
      dispatch({
        type: GETSKUZONELISTDASHBOARD,
        payload: ZoneData?.zoneList ?? [{ zone_id: 0, zone_name: "All" }],
      });
    } else {
      console.log(ZoneData);
      alert("There is a Error in sku zone list dashboard");
    }
  };

  const handleZoneChange = (e, value) => {
    let Arr = [...store?.skuZoneList, { zone_id: 0, zone_name: "All" }];
    const [{ zone_name, zone_id }] = Arr?.filter(
      (item) => item?.zone_name === value
    );
    setZone({
      ...zone,
      name: zone_name,
      id: zone_id,
      obj: { zone_name, zone_id },
    });
    let param = {
      state_id: state?.obj?.state_id ?? 0,
      zone_name: zone_name ?? "All",
      sc_id: sc?.obj?.sc_id ?? 0,
      category_id: category?.obj?.category_id ?? 0,
    };
    GetSkuAnalyticsList(Axios, param);
  };
  const handleScChange = (e, value) => {
    let Arr = [...store?.skuScList, { sc_id: 0, name: "All" }];
    const [{ name, sc_id }] = Arr?.filter((item) => item?.name === value);
    setScState({
      ...sc,
      name: name,
      id: sc_id,
      obj: { name, sc_id },
    });
    let param = {
      sc_id: sc_id,
    };
    GetCatList(param);
  };
  const handleCategoryChange = (e, value) => {
    let Arr = [
      ...store?.skuCategoryList,
      { category_id: 0, category_name: "All" },
    ];
    const [{ category_id, category_name }] = Arr?.filter(
      (item) => item?.category_name === value
    );
    setCategory({
      ...category,
      name: category_name,
      id: category_id,
      obj: { category_name, category_id },
    });

    let param = {
      state_id: state?.obj?.state_id ?? 0,
      zone_name: zone?.obj?.zone_name ?? "All",
      sc_id: sc?.obj?.sc_id ?? 0,
      category_id: category_id ?? 0,
    };
    GetSkuAnalyticsList(Axios, param);
  };

  console.log(store, category);

  return (
    <Stack
      sx={{ backgroundColor: "#323546" }}
      gap={1}
      component={Paper}
      padding={1}
    >
      <Stack
        gap={1}
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: { lg: "100%" },
        }}
      >
        <Stack flex="1 0 200px">
          <Autocomplete
            // className={styles.AutoComplete}
            value={state?.name}
            options={state?.list}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleStateChange}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  backgroundColor: "#25293C",

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  
                }}
                variant="outlined"
                label="State"
                placeholder="Select State.."
              />
            )}
          />
        </Stack>

        <Stack flex="1 0 200px">
          <Autocomplete
            // className={styles.AutoComplete}
            value={district?.name}
            options={district?.list}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleDistrictChange}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  
                }}
                variant="outlined"
                label="District"
                placeholder="Select District.."
              />
            )}
          />
        </Stack>
        <Stack flex="1 0 200px">
          <Autocomplete
            // className={styles.AutoComplete}
            value={zone?.name}
            options={zone?.list}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleZoneChange}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  
                }}
                variant="outlined"
                label="Zone"
                placeholder="Select Zone.."
              />
            )}
          />
        </Stack>
        <Stack flex="1 0 200px">
          <Autocomplete
            // className={styles.AutoComplete}
            value={sc.name}
            options={sc.list}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleScChange}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                 
                }}
                variant="outlined"
                label="Super Category"
                placeholder="Select Super Category.."
              />
            )}
          />
        </Stack>
        <Stack flex="1 0 200px">
          <Autocomplete
            // className={styles.AutoComplete}
            value={category?.name}
            options={category?.list}
            getOptionLabel={(option) => option}
            size="small"
            onChange={handleCategoryChange}
            renderInput={(params) => (
              <AutocompleteTextFieldCurved
                {...params}
                sx={{
                  backgroundColor: "#25293C",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  
                }}
                variant="outlined"
                label="Category"
                placeholder="Select Category.."
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack>
        <DashboardSkuAnalyticsTable />
      </Stack>
    </Stack>
  );
};

export default DashboardSkuAnalytics;
