export const GetLambdaTokenFNC = (Axios) => async (dispatch) => {
  try {
    // testing comment

    // Merge confciict

    // Additional comment

    const data = await Axios.post("lambdaToken");
    console.log(data+"rrrrrr");
    if (data?.data?.code === 200) {
      return data.data;
    }
  } catch (error) {
    return error.response.data;
  }
};
