import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./styles/KycTable.module.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { SearchFilter } from "../../../utility/functions/functions";
import { GetKycDetailsByStateAndZoneByKycId } from "../../../actions/kyc/kycAction";
import { useLocation, useNavigate } from "react-router";
import useAxiosPrivate from "../../../PrivateHooks/useAxiosPrivate";
import {
  GETVIEWDETAILSUSERLIST,
  GETVIEWDETAILSUSERLISTTABSDATA,
} from "../../../utility/ReduxConstants/KycReducerConstant";
import useAllZoneList from "../../../utility/useAllZoneList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

function Row({ item, handleShow, type, handleView,user_id }) {
  const store = useSelector((state) => state.Kyc);

  const Axios = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [obj, setObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (item !== undefined && item !== null) {
      setObj(item);
    }
    console.log(item, "Itemhasbeen");
  }, [item]);

  const handleDetails = (item, type) => {
    console.log(JSON.stringify(item) + "wowoowow");

    console.log(type, "viewdetails");
    // handleShow();
    // /home/kyc/state:id/zone:id/user:id

    // dispatch({
    //   type: GETVIEWDETAILSUSERLIST,
    //   payload: item,
    // });

    // navigate(
    //   // `/home/kyc/state:${obj.state_id}/zone:${obj.zone_name}/user:${obj.user_id}`
    //   `/home/kyc/kyvcerification`
    // );

    // dispatch({
    //   type: GETVIEWDETAILSUSERLISTTABSDATA,
    //   payload: type,
    // });

    // let param = {
    //   user_id: obj.user_id,
    // };

    // dispatch(GetKycDetailsByStateAndZoneByKycId(Axios, param));

    console.log(store, "kycstore");
  };

  console.log(JSON.stringify(store.kycViewDetails) + "wowoowow");

  let Disable = false;

  if (type?.filterName === "login_completed") {
    Disable = true;
  }
  // else if(type.filterName === "signup_completed"){
  //      Disable = true
  //}
  else {
    Disable = false;
  }

  console.log(type, "last");

  const handleViewClick = () => {
    handleView(item,user_id);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj?.first_name} {obj?.last_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj?.mobile_no}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj?.role_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {obj?.zone_name}
        </TableCell>
        <TableCell align="center" size="small" sx={{ color: "#fff" }}>
          {Disable ? (
            <Button variant="contained" disabled={true} size="small">
              View Details
            </Button>
          ) : (
            <RedButton
              variant="contained"
              // onClick={() => handleDetails(obj, type)}
              onClick={handleViewClick}
              disabled={Disable}
              size="small"
            >
              View Details
            </RedButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const KycTable = ({ show, type, text }) => {
  const Axios = useAxiosPrivate();
  const store = useSelector((state) => state.Kyc);
  const [list, setList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const columns = ["first_name", "last_name", "mobile_no"];
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log(type, "amcawncnwnc");

  useEffect(() => {
    if (
      store?.kycList !== undefined &&
      store?.kycList !== null &&
      store?.kycList?.length !== 0
    ) {
      setList(store?.kycList);
    } else {
      setList([]);
    }
  }, [store.kycList]);

  console.log(list, "kycList");

  const handleView = (item,user_id) => {
    localStorage.setItem("local_user_id",user_id)
    dispatch({
      type: GETVIEWDETAILSUSERLIST,
      payload: item,
    });
    navigate(
      // `/home/kyc/state:${obj.state_id}/zone:${obj.zone_name}/user:${obj.user_id}`
      `/home/kyc/kyvcerification`
    );

    dispatch({
      type: GETVIEWDETAILSUSERLISTTABSDATA,
      payload: type,
    });

    let param = {
      user_id: user_id,
    };

    dispatch(GetKycDetailsByStateAndZoneByKycId(Axios, param));
    
  };

  return (
    <>
      <TableContainer sx={{ minHeight: 496, maxHeight: 496, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={Styles.head}
            sx={{ backgroundColor: "#1B1D23" }}
            size="small"
          >
            <TableRow
              className={Styles.head}
              sx={{ backgroundColor: "#1B1D23" }}
              size="small"
            >
              <StyledTableCell align="center" size="small">
                Name
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Mobile No.
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Role
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Zone
              </StyledTableCell>
              <StyledTableCell align="center" size="small">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list !== undefined && list !== null && list.length !== 0 ? (
              <>
                {SearchFilter(list, columns, text)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <Row
                      item={item}
                      handleShow={show}
                      type={type}
                      handleView={handleView}
                      user_id={item?.user_id}
                    />
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  *No Exceptions*
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default KycTable;
