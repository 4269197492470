import {
  Button,
  Card,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Styles from "./styles/UpdateSCategory.module.css";
import images from "../../../../utility/constants/images";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseImgUploadUrl, baseImgPath } from "../../../../utility/BaseUrl";
import {
  GetSuperCategoryPageData,
  RemoveSCategoryData,
  UpdateSCategoryData,
} from "../../../../actions/SCategory/sCategoryAction";
import useAxiosPrivate from "../../../../PrivateHooks/useAxiosPrivate";
import PopUpModal from "../../../../utility/Backdrop";
import BrokenImg from "../../../../assets/ImageNotValid.png";
import { ValidImage } from "../../../../actions/validImage";
import { ROLES } from "../../../../utility/UserAllowedRoles";
import { DarkCardWrapper } from "../../CardWrapper";
import { AutocompleteTextField } from "../../../../utility/TextField";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const RedBorderButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "#323546",
  border: "2px solid #DC1D13",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const UpdateSCategory = () => {
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const Axios = useAxiosPrivate();
  const LoginStore = useSelector((state) => state.Login);
  const [error, setError] = useState({});
  const store = useSelector((state) => state.SuperCategory);
  const [file, setFile] = useState("");
  const [img, setImg] = useState("");
  const [addsuccess, setAddsuccess] = useState(false);

  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const [addsuccessrm, setAddsuccessrm] = useState(false);

  const [failedrm, setFailedrm] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    sc_id: 1,
    name: "",
    image: "",
  });

  const CheckImage = async (image) => {
    if (![undefined, null].includes(image) && image?.length !== 0) {
      const Result = await ValidImage(image);
      if (Result) {
        setImg(`${image}`);
      } else {
        setImg(BrokenImg);
      }
    }
  };

  useEffect(() => {
    if (
      store.super_category_detail !== undefined &&
      store.super_category_detail !== null
    ) {
      const details_data = store.super_category_detail;

      setForm({
        ...form,
        sc_id:
          details_data.sc_id !== undefined &&
          details_data.sc_id !== null &&
          details_data.sc_id !== 0
            ? parseInt(details_data.sc_id)
            : 0,
        name:
          details_data.name !== undefined &&
          details_data.name !== null &&
          details_data.name.length !== 0
            ? details_data.name
            : "",
        description:
          details_data.description !== undefined &&
          details_data.description !== null &&
          details_data.description.length !== 0
            ? details_data.description
            : "",
        image:
          details_data.image !== undefined &&
          details_data.image !== null &&
          details_data.image.length !== 0
            ? details_data.image
            : "",
      });
      const image =
        details_data.image !== undefined &&
        details_data.image !== null &&
        details_data.image.length !== 0
          ? details_data.image
          : "";
      CheckImage(image);
    }
  }, [store.super_category_detail]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const imgUpload = async (formData) => {
    await axios
      .post(`${baseImgUploadUrl}image_upload_category`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 200) {
          setLoading(false);
          toast.success("Uploaded SuccessFully");
          setImg(`${res.data.full_path}`);
          setForm({
            ...form,
            category_image: res.data.name,
          });
        } else {
          toast.error("Img Not Uploaded");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const ChooseImg = (event) => {
    setLoading(true);
    setFile(event.target.files[0]);
  };

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("document", JSON.stringify({type:"company"}));
    imgUpload(formData);
  }, [file]);

  React.useEffect(() => {
    if (addsuccessrm) {
      toast.success("Removed Category Details SuccessFully");

      setAddsuccessrm(false);
    }
  }, [addsuccessrm]);

  React.useEffect(() => {
    if (failedrm.addFailed) {
      let text = failedrm.addFailedMessage;
      toast.error(`${text}`);

      setFailedrm({
        ...failedrm,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failedrm]);

  const IsValid = () => {
    let success = true;
    let err = {};
    const chars = form.name?.match(/[^a-zA-Z0-9' ,&-](?= |$)/g);

    if (!form.name.trim()) {
      err.scategoryname = "**Super category Name Cannot Be Empty**";
      success = false;
    } else if (chars && chars?.length !== 0) {
      err.scategoryname =
        "*Only ' , & - Allowed! No Other Special Characters Allowed! Please Enter Valid Name*";
      success = false;
    }
    if (!img.trim()) {
      err.categoryimage = "**Please Choose a Image**";
      success = false;
    }

    // if (!form.description.trim()) {
    //     err.description = '**category Description Cannot Be Empty**'
    //     success = false;
    // }

    setError(err);
    return success;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit is clicked!!");
    if (IsValid()) {
      let param = {
        sc_id: `${form.sc_id}`,
        name: `${form.name}`,
        description: `${form.description}`,
        image: img,
      };
      console.log(param);

      const Data = await dispatch(UpdateSCategoryData(Axios, param));
      if (Data.code === 200) {
        toast.success("Updated Super category successfully");
        dispatch(GetSuperCategoryPageData(Axios));
      } else {
        toast.error(Data.message);
      }
    } else {
      alert("not valid");
    }
  };

  const handleConfirmRemove = async () => {
    console.log(form.sc_id, "formSCID");
    if (!form.sc_id) {
      let err = {};
      err.scId = "**super Category Id Cannot Be Empty**";
      setError(err);
    } else {
      let param = {
        sc_id: form.sc_id,
      };
      console.log(JSON.stringify(param) + "sc_id_param");

      const Data = await dispatch(
        RemoveSCategoryData(
          Axios,
          param,
          setAddsuccessrm,
          failedrm,
          setFailedrm
        )
      );

      console.log(JSON.stringify(Data), "REmoveDAta");

      if (Data.code === 200) {
        setRemove(false);
        dispatch(GetSuperCategoryPageData(Axios));
        toast.success("Removed super category successfully");
        
      } else {
        toast.error(Data.message);
      }
    }
  };

  const handleRemove = async () => {
    setRemove(true);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit}>
        <Stack sx={{ color: "white" }} gap={1}>
          <Typography>Update Super Category</Typography>
          <DarkCardWrapper sx={{ padding: "10px 20px" }}>
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              gap={2}
            >
              <Stack gap={1} flex={0.7}>
                <Card component={Paper} sx={{ borderRadius: "16px" }}>
                  <Stack
                    sx={{ height: "130px" }}
                    direction="row"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    {!loading && (
                      <img
                        src={img}
                        alt="super category image"
                        loading="lazy"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                    {/* {loading &&  <img src={img} alt="company" loading="lazy" className={Styles.Img} /> } */}

                    {loading ? (
                      <Stack
                        direction="column"
                        position="absolute"
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress />
                        <Typography>uploading...</Typography>
                      </Stack>
                    ) : null}
                    <input
                      type="file"
                      accept="image/*"
                      name="image-upload"
                      id={`SCategoryImgUploadUpdate`}
                      className={Styles.imginp}
                      onChange={ChooseImg}
                    />
                  </Stack>
                </Card>
                <Card
                  sx={{ backgroundColor: "#545B87" }}
                  className={Styles.UpdateCompanyWrapper}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    padding="5px 0px"
                    sx={{ cursor: "pointer" }}
                  >
                    <label
                      htmlFor={`SCategoryImgUploadUpdate`}
                      style={{
                        fontSize: "12px",
                        margin: 0,
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      UPLOAD IMAGE
                    </label>
                  </Stack>
                </Card>
              </Stack>
              <Stack flex={3} gap={1}>
                <Stack>
                  <AutocompleteTextField
                    size="small"
                    label="Super Category Name"
                    value={form.name}
                    name="name"
                    onChange={handleChange}
                  />
                  {error?.scategoryname && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {error?.scategoryname}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  justifyContent="flex-end"
                  gap={1}
                >
                  <RedButton
                    sx={{ borderRadius: "18px", minWidth: { md: "150px" } }}
                    type="submit"
                  >
                    Update
                  </RedButton>
                  {ROLES?.REMOVECOMPANY?.includes(
                    LoginStore?.user?.admin_role_code
                  ) ? (
                    <RedBorderButton
                      sx={{ borderRadius: "18px", minWidth: { md: "150px" } }}
                      onClick={handleRemove}
                    >
                      Remove
                    </RedBorderButton>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </DarkCardWrapper>
        </Stack>
      </form>
      {remove && (
        <PopUpModal
          open={remove}
          handleClose={() => setRemove(false)}
          Title={`Do You want to Remove Super Category`}
          Content={`${form.name}`}
          Img={img}
          handleAgree={handleConfirmRemove}
        />
      )}
    </>
  );
};

export default UpdateSCategory;
