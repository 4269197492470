import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { MissingRoute } from "./components/ErrorUtilities/Redirect";
import LoginMgt from "./components/Login";
import Login from "./components/Login";
import OfflineMgt from "./components/Offline/offline";
import Dashboard from "./components/Pages/DashBoard/Dashboard";
import Kyc from "./components/Pages/kyc";
import KycVerification from "./components/Pages/kyc/KycVerification";
import NewSku from "./components/Pages/NewSku";
import CategoryMgt from "./components/Pages/Product/category";
import CompanyMgt from "./components/Pages/Product/company";
import ProductMgt from "./components/Pages/Product/product";
import SuperCategoryMgt from "./components/Pages/Product/SuperCategory";
import SkuByZone from "./components/Pages/SkuByZone";
import TicketMgt from "./components/Pages/Tickets/TicketMgt";
// import TransactionMgt from "./components/Pages/Transaction";
// import UserZoneMgt from "./components/Pages/UserAccess/UserZoneMgt";
import RootMgtZone from "./components/Pages/Zone";
import UnAuthorized from "./components/UnAuthorized/UnAuthorized";
import HomePage from "./Home/HomePage";
import PersistLogin from "./PrivateHooks/PersistLogin";
import UseRoleAuthentication from "./PrivateHooks/UseRoleAuthentication";
import { ROLES } from "./utility/UserAllowedRoles";
import LaunchMgt from "./components/Pages/Launch";
import { LaunchContextFn } from "./components/Context/LaunchContext";
import Map from "./components/Pages/GoogleMap/Map";
import VitaranPartner from "./components/Pages/VitaranPartner/VitaranPartner";
import VitaranPartnerSkuDetails from "./components/Pages/VitaranPartner/VitaranPartnerSkuDetails";
import YourDeals from "./components/Pages/VitaranPartner/YourDeals";
import RunTheDeals from "./components/Pages/RunTheDeals/RunTheDeals";
import RunTheDealRun from "./components/Pages/RunTheDeals/RunTheDealRun";
import RunTheDealsIndex from "./components/Pages/RunTheDeals/RunTheDealsIndex";
import SubCategory from "./components/Pages/Product/Subcategory";
import LandingPage from "./components/Pages/HomePage/LandingPage";
import ContactUs from "./components/Pages/HomePage/ContactUs";
import VitranPartnerForm from "./components/Pages/HomePage/VitranPartnerForm";
import KycUserCard from "./components/Pages/kyc/kycUserCard";
import { lastDayOfDecade } from "date-fns";
import AccessTransactionMgt from "./components/Pages/Transaction/AccessTransactionMgt";
import Calender from "./components/Pages/HolidayCalender/Calender";
import VideoAnalytics from "./components/Pages/VideoAnalytics/VideoAnalytics";
import WalletManagement from "./components/Pages/WalletManagement/WalletManagement";
import WalletTransactionHistory from "./components/Pages/WalletManagement/WalletTransactionHistory/WalletTransactionHistory";
import WalletUserTransactionHistory from "./components/Pages/WalletManagement/WalletUserTransactionHistory.js/WalletUserTransactionHistory";
import PartnerWalletManagement from "./components/Pages/WalletManagement/PartnerWalletManagement/PartnerWalletManagement";
import PartnerWalletUserTransactionHistory from "./components/Pages/WalletManagement/PartnerWalletUserTransactionHistory.js copy/PartnerWalletUserTransactionHistory";
import PartnerWalletTransactionHistory from "./components/Pages/WalletManagement/PartnerWalletTransactionHistory/PartnerWalletTransactionHistory";
import PartnerPendingApproval from "./components/Pages/WalletManagement/PartnerPendingApproval/PartnerPendingApproval";

const UserZoneMgt=React.lazy(()=>import("./components/Pages/UserAccess/UserZoneMgt"))
const TransactionMgt=lazy(()=>import ("./components/Pages/Transaction"))

const App = () => {
  const store = useSelector((state) => state.Login);
  const location = useLocation();

  return (
    <div style={{ overflowY: "hidden" }}>
      <Routes>
        {/* <Route exact path="/" element={<LandingPage />} /> */}
        <Route exact path="/vitaranpartner" element={<VitranPartnerForm />} />
        <Route exact path="/contact" element={<ContactUs />} />

        <Route element={<PersistLogin />}>
          <React.Fragment>
            <Route exact path="*" element={<MissingRoute />} />
            <Route exact path="/" element={<LandingPage />} />
          </React.Fragment>
          <Route exact path="/Login" element={<LoginMgt />} />
          <Route path="/unAuthorized" element={<UnAuthorized />} />

          <Route exact path="/home" element={<HomePage />}>
            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.ZONE_MGT} />
                }
              >
                <Route exact path="/home/zonemgt" element={<RootMgtZone />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.CALENDER} />
                }
              >
                <Route exact path="/home/calender" element={<Calender />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.VIDEO_ANALYTICS} />
                }
              >
                <Route exact path="/home/video/analytics" element={<VideoAnalytics />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.TRANSACTION_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/transaction"
                  element={<Suspense><TransactionMgt /></Suspense>}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.ACCESS_TRANSACTION_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/access/transaction"
                  element={<AccessTransactionMgt />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/wallet"
                  element={<WalletManagement />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_PARTNER_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/pending"
                  element={<PartnerPendingApproval />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_PARTNER_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/transaction/history"
                  element={<PartnerWalletTransactionHistory />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_PARTNER_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/access/wallet"
                  element={<PartnerWalletManagement />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_PARTNER_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/wallet/user/history"
                  element={<PartnerWalletUserTransactionHistory />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/wallet/history"
                  element={<WalletTransactionHistory />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.WALLET_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/wallet/user/history"
                  element={<WalletUserTransactionHistory />}
                />
              </Route>
            </React.Fragment>

            {/* <React.Fragment>
              <Route element={<UseRoleAuthentication allowedRoles={ROLES.SKU_MGT} />}>
                <Route exact path="/home/sku" element={<SkuMgt />} />
              </Route>
            </React.Fragment> */}
            {/* <React.Fragment>
              <Route element={<UseRoleAuthentication allowedRoles={ROLES.COMPANY_MGT} />}>
                <Route exact path="/home/product" element={<ProductMgt />} />
              </Route>
            </React.Fragment> */}

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.COMPANY_MGT} />
                }
              >
                <Route exact path="/home/company" element={<CompanyMgt />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.CATEGORY_MGT} />
                }
              >
                <Route exact path="/home/category" element={<CategoryMgt />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication
                    allowedRoles={ROLES.SUPER_CATEGORY_MGT}
                  />
                }
              >
                <Route
                  exact
                  path="/home/scategory"
                  element={<SuperCategoryMgt />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route exact path="/home/offline" element={<OfflineMgt />} />
            </React.Fragment>

            
            <React.Fragment>
            
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.ACCESS_MGT} />
                }
              >
                <Route exact path="/home/access" element={<Suspense><UserZoneMgt /></Suspense>} />
              </Route>
              
            </React.Fragment>
            
            <React.Fragment>
              <Route
                element={<UseRoleAuthentication allowedRoles={ROLES.SKU_MGT} />}
              >
                <Route exact path="/home/newsku" element={<NewSku />} />
              </Route>
            </React.Fragment>


            {/* <React.Fragment>
              <Route
                element={<UseRoleAuthentication allowedRoles={ROLES.SKU_EXCEPTION_CATEGORY_ADMIN} />}
              >
                <Route exact path="/home/newsku" element={<NewSku />} />
              </Route>
            </React.Fragment> */}
            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication
                    allowedRoles={ROLES.SKU_EXCEPTION_MGT}
                  />
                }
              >
                <Route exact path="/home/zonesku" element={<SkuByZone />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.DASHBOARD} />
                }
              >
                <Route exact path="/home/dashboard" element={<Dashboard />} />
              </Route>
            </React.Fragment>
            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.TICKET_MGT} />
                }
              >
                <Route exact path="/home/ticket" element={<TicketMgt />} />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={<UseRoleAuthentication allowedRoles={ROLES.KYC_MGT} />}
              >
                <Route exact path="/home/kyc/*" element={<Kyc />} />
                <Route
                  exact
                  path="/home/kyc/kyvcerification"
                  // path="/home/kyc/state:id/zone:id/user:id"

                  element={<KycVerification />}
                />
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.LAUNCH_MGT} />
                }
              >
                <Route
                  exact
                  path="/home/launch/"
                  element={
                    <LaunchContextFn>
                      <LaunchMgt />
                    </LaunchContextFn>
                  }
                />
              </Route>
            </React.Fragment>
            <React.Fragment>
              <Route
                element={<UseRoleAuthentication allowedRoles={ROLES.MAP_MGT} />}
              >
                <Route exact path="/home/map" element={<Map />} />
              </Route>
            </React.Fragment>
            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.VITARAN_PARTNER} />
                }
              >
                <Route
                  exact
                  path="/home/vitaranparter"
                  element={<VitaranPartner />}
                >
                  <React.Fragment>
                    <Route
                      element={
                        <UseRoleAuthentication
                          allowedRoles={ROLES.VITARAN_PARTNER}
                        />
                      }
                    >
                      <Route
                        exact
                        path="/home/vitaranparter/skuDetails"
                        element={<VitaranPartnerSkuDetails />}
                      />
                    </Route>
                  </React.Fragment>
                </Route>
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.VITARAN_PARTNER} />
                }
              >
                <Route
                  exact
                  path="/home/runthedeals"
                  element={<RunTheDealsIndex />}
                >
                  <React.Fragment>
                    <Route
                      element={
                        <UseRoleAuthentication
                          allowedRoles={ROLES.VITARAN_PARTNER}
                        />
                      }
                    >
                      <Route
                        exact
                        path="/home/runthedeals/"
                        element={<RunTheDeals />}
                      />
                    </Route>
                  </React.Fragment>
                  <React.Fragment>
                    <Route
                      element={
                        <UseRoleAuthentication
                          allowedRoles={ROLES.VITARAN_PARTNER}
                        />
                      }
                    >
                      <Route
                        exact
                        path="/home/runthedeals/details"
                        element={<RunTheDealRun />}
                      />
                    </Route>
                  </React.Fragment>
                </Route>
              </Route>
            </React.Fragment>

            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.SUB_CATEGORY} />
                }
              >
                <Route
                  exact
                  path="/home/subcategory"
                  element={<SubCategory />}
                />
              </Route>
            </React.Fragment>
            <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.VITARAN_PARTNER} />
                }
              >
                <Route exact path="/home/yourdeals" element={<YourDeals />} />
              </Route>
            </React.Fragment>
            {/* <React.Fragment>
              <Route
                element={
                  <UseRoleAuthentication allowedRoles={ROLES.SUB_CATEGORY} />
                }
              >
                <Route
                  exact
                  path="/home/kyc/kycusercard"
                  element={<KycUserCard />}
                />
              </Route>
            </React.Fragment> */}
            {/* <React.Fragment>
              <Route exact path='/kyc/kyc_detail' element={<KycVerification/>} />
            </React.Fragment> */}
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
